<!--  I N T R O   -->


<section id="intro-para">
  <div class="container text-center pt-5">

    <div class="row pt-2">
      <div class="col"> 
        <h2 class="col" *ngIf="this.show">{{ mainHeading }}</h2> 
      </div>
    </div>
    <div class="row pb-1 pl-3 justify-content-center" *ngIf="this.show">
      <div class="col-md-6">
        <img src="assets/img/orange-divider-transparent.png" /> <br><br>
        <p *ngIf="this.subHeading == 'true'"> We'll need some basic information about you and your property so that one of our agents can prepare a quote
          for you. <br><br>
        </p>
      </div>
    </div>

    <div class="row pl-3 justify-content-center" id="dvLiveChat">
      <input type="hidden" id="hfChatLink" [value]="constantsObj.hfChatLink">
      <div id="ciILpZ" style="z-index: 100; position: absolute">
      </div>
      <div id="scILpZ" style="display: inline">
      </div>
      <div id="sdILpZ" style="display: none">
      </div>
      <img hidden [src]="constantsObj.LiveChatImage" alt="live-chat" class="img-responsive livechat-img" />
    </div>
    <div class="popup-box chat-popup" id="qnimate" *ngIf="false">
      <div class="popup-head text-left pl-3">
        <span>Welcome to Online Support!</span>
      </div>
      <div class="popup-messages">
        <form>
          <div class="row text-left text-white pt-2 pl-3 pr-3">
            <div class="col">
              <label for="name">Name</label>
              <input type="text" class="form-control rounded-0">
            </div>
          </div>
    
          <div class="row text-left text-white pt-2 pl-3 pr-3">
            <div class="col">
              <label for="refnumber">Quote Refernce Number</label>
              <input type="text" class="form-control rounded-0">
            </div>
          </div>
    
          <div class="row text-left text-white pt-2 pb-4 pl-3 pr-3">
            <div class="col">
              <label for="Your Question">Your Question</label>
              <textarea class="form-control rounded-0" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="popup-messages-footer">
        <div class="btn-footer">
          <div class="row p-2">
            <div class="col">
              <ul id="mainNav" class="nav d-flex align-items-center">
                <li class="nav-item">
                  <a class="nav-link telNum" href="tel:18888225396"><i class="fas fa-mobile-alt"></i></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link emailUs" href="mailto:Contactus@Westwoodins.com"><i class="fas fa-envelope"></i></a>
                </li>
              </ul>
            </div>
            <div class="col pt-2">
              <a class="btn btn-brand" href="https://messenger.providesupport.com/messenger/1tx8v9rxsa1vm0cq1ur9nctzbv.html"
                target="_blank">Chat »</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</section> 
<!-- //  I N T R O    -->