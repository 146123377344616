import { Component, OnInit, OnDestroy, NgZone, ElementRef, ViewChild, Inject, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { LenderChanges } from '../../model/lenderChanges';
import { Constants } from '../../Utilities/Constants/constants';
import { CommonService } from '../../service/common.service';
import { CustInfoService } from '../../service/cust-info.service';
import { DataService } from '../../service/data.service';
import { LeadChangeService } from '../../service/lead-change.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ModelPopUpLenderChangeMessageComponent } from '../../shared/model-pop-up-lender-change-message/model-pop-up-lender-change-message.component';
import { UploadFile } from '../../model/uploadFiles';

declare function breadcrumbShow(): any;
declare function facIconOne(): any;
declare function facIconTwo(): any;
declare var grecaptcha: any;
declare var $: any;
@Component({
  selector: 'app-lender-changes',
  templateUrl: './lender-changes.component.html',
  styleUrls: ['./lender-changes.component.css']
})
export class LenderChangesComponent implements OnInit, AfterViewInit, AfterViewChecked {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/lenderchangeportalheader.JPG',
    desktopHeaderText: 'Lender Change Notification',
    desktopHeaderSubText: "Need to make a change?  Simply fill out our online form below to update the mortgagee clause listed on your insurance policy.",
    mobileHeaderText: 'Lender Change Notification',
    mobileHeaderSubText: ''
  });
  formErrors: any = {};
  confirmRequesterEmail: string;
  fileErrorMessage = "Please provide a valid file type. Only files with the following extensions are allowed: .tif, .jpg, .pdf, .docx, .doc and .gif";
  requestPolicyChangeForm: FormGroup;
  recaptcha: string;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  badge = 'Bottomright';
  captchaRequired = true;

  noDisplayMsg: string = '';
  invalidFileErrorMessage: string = '';

  lstStates = [
    {
      stateName: ''
    }
  ];
  isLoading: boolean = false;
  showThankyou: boolean = false;
  dataMessage: string = '';

  @ViewChild(ModelPopUpLenderChangeMessageComponent) childLenderRequest: ModelPopUpLenderChangeMessageComponent;
  @ViewChild('mortgageeAddressLine1') mortgageeAddressLine1: ElementRef;
  @ViewChild('mortgageeAddressLine2') mortgageeAddressLine2: ElementRef;
  @ViewChild('mortgageeCites') mortgageeCity: ElementRef;
  @ViewChild('mortgageeStates') mortgageeState: ElementRef;
  @ViewChild('mortgageePostalCodes') mortgageePostalCode: ElementRef;


  @ViewChild('propStreetAddress') propStreetAddress: ElementRef;
  @ViewChild('line2') line: ElementRef;
  @ViewChild('cities') city: ElementRef;
  @ViewChild('states') state: ElementRef;
  @ViewChild('postalCodes') postalCode: ElementRef;

  constructor(private router: Router, public commonService: CommonService,
    public lenderChangeDetails: LenderChanges,
    public fileUpload: UploadFile,
    private route: ActivatedRoute,
    private zone: NgZone,
    private custInfoService: CustInfoService,
    public constantsObj: Constants,
    private lenderInfoService: LeadChangeService,
    public dataService: DataService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string) {
    var isSafari = this.commonService.isSafariBroswer();
    this.captchaRequired = false;
    this.siteKey = siteKeyValueV3;
    // if (isSafari) {
    //   this.captchaRequired = false;
    //   this.siteKey = siteKeyValueV3;
    // }
    // else {
    //   this.siteKey = siteKeyValue;
    // }

    // if (!this.commonService.isSafariBroswer()) {
    //   $('.grecaptcha-badge').remove()
    // }
    // else {
    //   $('.grecaptcha-badge').css('display', 'block !important');
    //   $('.grecaptcha-badge').css('visibility', 'visible !important');
    // }


  }

  //private route: ActivatedRoute,
  fileValidationFocus() {
    if (this.lenderChangeDetails.upload == '1') {
      var extension = this.fileName.substr((this.fileName.lastIndexOf('.') + 1));
      if (extension == "") {
        this.invalidFileErrorMessage = this.fileErrorMessage;
        $("#fileUpload").focus();
        $('html, body').animate({ scrollTop: $('#fileUpload').offset().top - 50 }, 'slow');
        return false;
      }
      else {
        return true;
      }
    }
  }
  onClickManual(value) {

    var x = $("#helpText");
    var helpText = $("#helpText").is(':visible');
    if (value !== 'help') {
      if (value == 'manual')
        x.hide();
      else
        x.show();
    }
    else {
      if (helpText)
        x.hide();
      else
        x.show();
    }
    setTimeout(function () {
      var addressLine = $("#address-line-one").val();
      var mortgageeAddressLine1 = $("#address-line-two").val();
      if (addressLine != '') {
        $("#address-line-search-one").removeClass("far fa-search address-line-search-icon");
      }
      if (mortgageeAddressLine1 != '') {
        $("#address-line-search-two").removeClass("far fa-search address-line-search-icon");
      }
    }, 500);
  }
  fileToUpload: File = null;
  onSubmit(lenderChangeForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(lenderChangeForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(lenderChangeForm);
    if (this.lenderChangeDetails.upload == '1') {
      if (!this.fileValidationFocus())
       return ;
    }
    if (!lenderChangeForm.valid) {

      //if (!(this.lenderChangeDetails.contactWayMobilePhone || this.lenderChangeDetails.contactWayWorkPhone || this.lenderChangeDetails.contactWayEmail)) {
      this.commonService.focusFirstInvalidElement();
    
    }
    // }

    // call api to post form  
    if (lenderChangeForm.valid && !this.captchaRequired) {
      this.isLoading = true;
      // if (this.commonService.isSafariBroswer()) {
        //if (true) {
      this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
        if (token != '') {
          this.lenderChangeDetails.reCaptcha = new ReCaptcha();
          this.lenderChangeDetails.reCaptcha.response = token;
          this.lenderChangeDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
          if (this.lenderChangeDetails.upload == '1') {
            this.fileSave();
          } else {
            this.postData();
          }
        }
      });
      // }
      // else {
      //   if (this.lenderChangeDetails.upload == '1') {
      //     this.fileSave();
      //   } else {
      //     this.postData();
      //   }
      // }

    } else {

    }
  }
  postData() {
    //this.lenderChangeDetails.status = "New";
    this.isLoading = true;
    $("#submit").prop('disabled', true);
    this.lenderInfoService.SaveLenderChange(this.lenderChangeDetails).subscribe((data: any) => {
      this.isLoading = false;
      //disable 
      $("#submit").prop('disabled', false);
      this.noDisplayMsg = '';
      if (data) {
        this.lenderChangeDetails.status = data.status;
        this.lenderChangeDetails.subStatus = data.subStatus;

        //Status – Duplicate and SubStatus = New
        if (data.status === 'Duplicate' && data.subStatus === "New") {
          this.childLenderRequest.displayMessage(data.message, false);
          this.noDisplayMsg = 'We are working hard to process your original request. Thank you for your patience.';
        } else
          //Status – Duplicate and SubStatus = Completed
          if (data.status === 'Duplicate' && data.subStatus === "Completed") {
            this.noDisplayMsg = 'Thank you. There is no need to submit anything further.';
            this.childLenderRequest.displayMessage(data.message, false);
            //this.dataMessage = this.noDisplayMsg;
            //this.showThankyou = true;
            //$('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

          } else
            //Status – Duplicate and SubStatus = InProgress
            if (data.status === 'Duplicate' && data.subStatus === "InProgress") {
              //this.childLenderRequest.displayMessage(data.message, true);
              this.noDisplayMsg = data.message?? 'Thank you. There is no need to submit anything further.';
              this.dataMessage = this.noDisplayMsg;
              this.showThankyou = true;
              $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

            } else
              //Status – Completed.
              if (data.status === 'Completed') {

                //this.childLenderRequest.displayMessage(data.message, true);
                this.noDisplayMsg = data.message ?? 'Thank you. We will process your request as quickly as possible.';
                this.dataMessage = this.noDisplayMsg;
                this.showThankyou = true;
                $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

              } else {
                //this.childLenderRequest.displayMessage(data.message, true);
                this.noDisplayMsg = data.message ?? 'Thank you. There is no need to submit anything further.';
                this.dataMessage = this.noDisplayMsg;
                this.showThankyou = true;
                $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

              }

      } else {
        alert("Something went wrong, please try again!");
      }
      //window.location.reload()
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      $("#submit").prop('disabled', false);
      window.location.reload()
    });
  }
  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  handleSuccess(event) {
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA

  getStates() {
    this.lenderInfoService.getStates().subscribe((data: any) => {
      this.lstStates = data.lstStates;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
    });
  }
  fileName = '';
  fileNameShow = false;
  uploadFileEvt(files: any) {
    this.fileToUpload = files.target.files[0];
    if (files.target.files && files.target.files[0]) {
      this.fileName = '';
      Array.from(files.target.files).forEach((file: any) => {
        this.fileName += file.name;
      });
      var validation = this.validateExtensionAndSize();
      if (!validation) {
        this.fileToUpload = null;
        this.fileName = '';
        return false;
      }
      else {
        this.uploadClick();
      }
      //reader.onload = (e: any) => {
      //  let image = new Image();
      //  image.src = e.target.result;
      //  image.onload = (rs) => {
      //    let imgBase64Path = e.target.result;
      //  };
      //};
      //reader.readAsDataURL(files.target.files[0]);
      // Reset if duplicate image uploaded again
      //this.fileInput.nativeElement.value = '';
    } else {
      this.fileName = '';
    }
  }
  uploadClick() {
    this.fileName = this.fileName;

    if (this.fileName == '') {
      this.invalidFileErrorMessage = this.fileErrorMessage;
      return false;
    }
    else {
      //this.fileSave();
      this.fileNameShow = true;
      $('input[name$="fileUpload"]').attr("disabled", "disabled");
      $('#uploadFile').attr("disabled", "disabled");
    }
  }
  removeFile(docUploadsData: any) {
    this.fileName = '';
    this.fileNameShow = false;;
    $('input[name$="fileUpload"]').val('');
    $('input[name$="fileUpload"]').attr("disabled", false);
    $('#uploadFile').attr("disabled", false);
  }
  validateExtensionAndSize() {
    if (this.fileValidationFocus()) {
      var extension = this.fileName.substr((this.fileName.lastIndexOf('.') + 1));
      extension = '.' + extension.toLowerCase().trim();
      var validExtensions = [".tif", ".jpg", ".pdf", ".docx", '.doc', '.GIF', '.gif'];
      if (validExtensions.includes(extension)) {
        // check size
        if ((this.fileToUpload.size / (1024 * 1024)) > 5) {
          this.invalidFileErrorMessage = "Please provide a valid file within 5MB.";
          this.fileName = '';
          $('input[name$="fileUpload"]').val('');
          return false;
        }
        return true;
      }
      else {
        this.invalidFileErrorMessage = this.fileErrorMessage
        this.fileName = '';
        $('input[name$="fileUpload"]').val('');
        return false;
      }
    }
  }
  //getStates() {
  //  this.lenderInfoService.getStates().subscribe((data: any) => {
  //    this.lstStates = data.lstStates;
  //  }, err => {
  //    this.isLoading = false;
  //    this.dataService.handleError(err);
  //  });
  //}

  changingAddressLineOne() {
    facIconOne();
    facIconTwo();
  }
  SetPropertyAddressDetailsMortgagee(event) {
    this.zone.run(() => {
      if (event.street_number != undefined && event.route != undefined) {
        this.lenderChangeDetails.mortgageeAddressLine1 = event.street_number + ' ' + event.route;
      }
      else if (event.route != undefined) {
        this.lenderChangeDetails.mortgageeAddressLine1 = event.route;
      }
      else if (event.street_number != undefined) {
        this.lenderChangeDetails.mortgageeAddressLine1 = event.street_number;
      }
      if (event.postal_code != undefined) {
        this.lenderChangeDetails.mortgageePostalCode = event.postal_code || '';
        this.mortgageePostalCode.nativeElement.value = this.lenderChangeDetails.mortgageePostalCode;
      }
      this.lenderChangeDetails.mortgageeAddressLine2 = '';
      this.lenderChangeDetails.mortgageeCity = event.locality;
      this.lenderChangeDetails.mortgageeState = event.admin_area_l1;

      this.mortgageeAddressLine1.nativeElement.value = this.lenderChangeDetails.mortgageeAddressLine1;
      this.mortgageeAddressLine2.nativeElement.value = this.lenderChangeDetails.mortgageeAddressLine2;
      this.mortgageeState.nativeElement.value = this.lenderChangeDetails.mortgageeState;
      this.mortgageeCity.nativeElement.value = this.lenderChangeDetails.mortgageeCity;
    });
  }
  SetPropertyAddressDetails(event) {

    this.zone.run(() => {
      if (event.street_number != undefined && event.route != undefined) {
        this.lenderChangeDetails.addressLine1 = event.street_number + ' ' + event.route;
      }
      else if (event.route != undefined) {
        this.lenderChangeDetails.addressLine1 = event.route;
      }
      else if (event.street_number != undefined) {
        this.lenderChangeDetails.addressLine1 = event.street_number;
      }
      if (event.postal_code != undefined) {
        this.lenderChangeDetails.postalCode = event.postal_code || '';
        this.postalCode.nativeElement.value = this.lenderChangeDetails.postalCode;
      }
      this.lenderChangeDetails.addressLine2 = '';
      this.lenderChangeDetails.city = event.locality;
      this.lenderChangeDetails.state = event.admin_area_l1;

      this.propStreetAddress.nativeElement.value = this.lenderChangeDetails.addressLine1;
      this.line.nativeElement.value = this.lenderChangeDetails.addressLine2;
      this.state.nativeElement.value = this.lenderChangeDetails.state;
      this.city.nativeElement.value = this.lenderChangeDetails.city;

    });
  }
  ngOnInit() {
    this.isLoading = true;
    this.lenderChangeDetails = new LenderChanges();
    this.lenderChangeDetails.upload = '';
    this.lenderChangeDetails.requestRCE = false;
    this.lenderChangeDetails.status = "New";
    this.lenderChangeDetails.subStatus = "";
    this.getStates();
    this.route.queryParams
      .subscribe(params => {
        this.lenderChangeDetails.leadID = params.id;
        if (params.trackingid != undefined && params.trackingid!=null)
        this.lenderChangeDetails.trackingid = params.trackingid;
        if (this.lenderChangeDetails.leadID !== undefined && this.lenderChangeDetails.leadID !== null) {
          this.lenderInfoService.getLeadInfoWithTrackingID(this.lenderChangeDetails.leadID, this.lenderChangeDetails.trackingid).subscribe((data: any) => {
            this.lenderChangeDetails = (data as LenderChanges);
            this.lenderChangeDetails.status = "New";
            this.lenderChangeDetails.subStatus = "";
            this.lenderChangeDetails.trackingid = data.trackingid;
            this.lenderChangeDetails.leadID = data.leadId;
            this.isLoading = false;
          }, err => {
            this.isLoading = false;
            this.dataService.handleError(err);
          });
          //var data = this.lenderInfoService.getLeadInfo(this.lenderChangeDetails.leadID);
        } else {
          //for letter print DIRECTLY typing the url
          this.lenderChangeDetails = new LenderChanges();
          this.lenderChangeDetails.status = "New";
          this.lenderChangeDetails.subStatus = "";
          this.isLoading = false;
        }
      });


  }
  ngAfterViewInit() {
    breadcrumbShow();
  }
  ngAfterViewChecked() {
  }

  fileSave() {
    if (this.validateExtensionAndSize()) {
      this.isLoading = true;
      $("#submit").prop('disabled', true);
      this.noDisplayMsg = '';
      var RequesterInfo = {
        policyNumber: this.lenderChangeDetails.policyNumber,
        leadId: this.lenderChangeDetails.leadID,
        uploadedFileName: this.fileToUpload.name,
        requesterFirstName: this.lenderChangeDetails.requesterFirstName,
        requesterLastName: this.lenderChangeDetails.requesterLastName,
        requesterEmail: this.lenderChangeDetails.requesterEmail,
        trackingid: this.lenderChangeDetails.trackingid

      }

      // Store base64 encoded representation of file
      // this.fileUpload.PostedFile = reader.result.toString();
      const formData: FormData = new FormData();
      formData.append('LenderFile', this.fileToUpload, this.fileToUpload.name);
      formData.append('requesterInfo', JSON.stringify(RequesterInfo))
      this.lenderInfoService.FileSave(formData).subscribe((data: any) => {

        $("#submit").prop('disabled', false);
        this.noDisplayMsg = '';
        if (data) {
          this.lenderChangeDetails.status = data.status;
          this.lenderChangeDetails.subStatus = data.subStatus;
          //Status – Completed.
          if (data.status === 'Completed') {
            this.noDisplayMsg = 'Thank you. We will process your request as quickly as possible.';
            //this.childLenderRequest.displayMessage(data.message, true);
            this.dataMessage = this.noDisplayMsg;
            this.showThankyou = true;
            $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');
          } else {
            //this.childLenderRequest.displayMessage(data.message, true);
            this.dataMessage = this.noDisplayMsg;
            this.showThankyou = true;
            $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

          }


        } else {
          alert("Something went wrong, please try again!");
        }
        this.isLoading = false;
        //window.location.reload()
      }, err => {
        this.isLoading = false;
        $("#submit").prop('disabled', false);
        this.dataService.handleError(err);
        alert("Something went wrong, please try again!");
        window.location.reload()
      });
    }
  }
  parentFunYes() {
    this.postData();
    //window.location.reload();
  }
  parentFunNo() {
    //this.childLenderRequest.displayMessage(this.noDisplayMsg, true);
    this.dataMessage = this.noDisplayMsg;
    this.showThankyou = true;
    $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

  }
  parentFunOk() {
    //window.location.reload();
    this.showThankyou = true;
    $('html, body').animate({ scrollTop: $('#breadcrumb').offset().top - 50 }, 'slow');

  }
}
