import { Injectable } from '@angular/core';

@Injectable()
export class TooltipMessage {
  public fireAlarms = "<ul><li>A smoke alarm is a fire-protection device that  automatically detects and gives a warning within the home when smoke is present.</li><li>Smart alarm systems typically allow you to monitor and manage your security systems via a smartphone or other device from any location.</li> <li>Fire alarms monitored by a company connect directly to an outside monitoring service.  Professional monitoring services will often alert both you and emergency services if smoke is detected.</li> <li>Fire alarms monitored by police connect directly to emergency services who can respond if smoke is detected.</li></ul>";

  public fireSprinklers = "<ul><li>Full residential coverage means that automatic fire sprinklers are installed in all areas of the home, including bathrooms, closets, attics and garages.</li><li>Partial residential coverage means that automatic fire sprinklers are installed in all living areas of the home, but are usually omitted from bathrooms, closets, attics and garages.</li><li>Smart systems typically allow you to monitor and manage your sprinkler systems via a smartphone or other device from any location.</li></ul>";

  public burglarAlarms = "<ul><li>A deadbolt or local alarm is a burglary prevention device that provides a signal within the home or is controlled solely at the home.</li><li>Smart alarm systems typically allow you to monitor and manage your security systems via a smartphone or other device from any location.</li><li>Burglar alarms monitored by a company connect directly to an outside monitoring service. Professional monitoring services will often alert both you and emergency services if certain activity is detected.</li><li>Burglar alarms monitored by police connect directly to emergency services who can respond if certain activity is detected.</li></ul>";

  public gatedCommunity = "<ul><li>A 24-hour manned gate typically has a security officer controlling who can pass through the gate 24 hours a day.</li><li>A 24-hour patrolled community typically has a security officer patrol throughout the neighborhood 24 hours a day, keeping an eye out for suspicious activity, regardless of whether the community has a gate.</li><li>A single-entry community forces all vehicles to enter and exit the neighborhood through a single entrance, regardless of whether the community has a gate.</li><li>A passkey community uses automatic, unmanned gates that open to residents holding a key or buzzer.</li></ul>";

  public waterSensor = "<ul><li>A temperature sensor monitors the water temperature in your pipes and alerts you if it gets close to freezing. Some monitors also sense excess humidity which could cause mold.</li><li>A water sensor alerts you if water is detected in a specified area.</li><li>A water sensor with shutoff automatically shuts off the water source if water is detected in a specified area.</li><li>A water flow with shutoff automatically shuts off the water source when the flow is irregular or exceeds a specified level.</li><li>Smart sensors typically alert you via a smartphone or app when water is present.</li></ul>";

  public atv = "<ul><li>Vehicles other than Automobiles which are used for recreational purposes on or off the premises. Examples include but are not limited to ATVs, jet skis, and dirt bikes.</li></ul>";

  public solarPanels = "<ul><li> Select “Other Source” if you use solar panels from a farm or if your home is energy efficient exempting you from state requirements. </li></ul>";

  public squareFootage = "<ul><li>This should not include garage or basement.</li></ul>";

  public dateOfBirth = "<ul><li>We’ll check for discounts that may help you get a lower premium.</li></ul>";

  public businessOnPremises = "<ul><li>Business conducted on premises that involves foot traffic in your home or large quantities of merchandise.</li></ul>";
  public loanPosition = "Some homeowners have multiple loans for a single property. Select ‘1’ if this is your only loan, or lien, on your property.";
  public mortgageeNameLine1 = "The name of your new lender as it appears on provided documentation.";
  public impound_Escrow = "Select ‘Yes’ if your homeowners insurance is paid by your lender.";
  public lender_changes = "You should have received written notification from your original lender and/or your new lender about your lender change. You may upload that document to provide the information required to change the mortgagee clause on your insurance policy. ";
  getPolicyTypeHelpText(policyTypeId) {
    return '<ul><li>HO-3/HOB<br><p class="ml-2">The most common type of policy for single-family homes. Protects the structure of your home on an "open-peril" basis, meaning all losses are covered except those that are expressly excluded in your policy. Your personal property is still covered on a "named-peril" basis.</p></li><li>HO-5<br><p class="ml-2">Protects both your home and personal property on an "open-peril" basis. The broadest form of home insurance available.</p></li><li>HO-8<br><p class="ml-2">Designed for older homes where the replacement cost exceeds the actual cash value of the home.</p></li><li>HO-4/HBT<br><p class="ml-2">The standard renters insurance policy.</p></li><li>HO-6/HB-Con<br><p class="ml-2">The standard condo insurance policy.</p></li><li>DP3/DF<br><p class="ml-2">Designed for non-owner occupied, residential homes. Considered the best insurance policy for rental homes.</p></li><li>HO1, HO2 and DP1<br><p class="ml-2">Provide very limited protection. Due to the narrow coverage, many insurance companies no longer offer these types of policies, or only use them if your home doesn’t qualify for other types of insurance.</p></li></ul>';
    // HO3/HOB, HO3 w/ HO15,HO3FL,HO3LE
    if (policyTypeId === "a8" || policyTypeId === "094E6997B41511D18ACE005500765AC7" || policyTypeId === "7311FF41075C11D18ACE005500765AC7"
      || policyTypeId === "20073F81845B11D2892900104B693038" || policyTypeId === "D5182192D1CF4BA8A8B9417B748BFB32") {
      return '<ul><li>HO3/HOB<br><p class="ml-2">The most common type of policy for single-family homes. Protects the structure of your home on an "open-peril" basis, meaning all losses are covered except those that are expressly excluded in your policy. Your personal property is still covered on a "named-peril" basis.</p></li></ul>';
    }
    // HO5
    if (policyTypeId === "6478FDD03F3444B699EDC727FAF9BDD6") {
      return '<ul><li>HO5<br><p class="ml-2">Protects both your home and personal property on an "open-peril" basis. The broadest form of home insurance available.</p></li></ul>';
    }
    // HO8
    if (policyTypeId === "261EC1921679466E886023AEFDD02FFD") {
      return '<ul><li>HO8<br><p class="ml-2">Designed for older homes where the replacement cost exceeds the actual cash value of the home.</p></li></ul>';
    }
    //	HO-4/HBT: 
    if (policyTypeId === "EF99E1E5CE9611D0AD7F0055007657A3" || policyTypeId === "793F00346CE04B158350ED6568E9C653") {
      return '<ul><li>HO-4/HBT<br><p class="ml-2">The standard renters insurance policy.</p></li></ul>';
    }
    // HO-6/HB-Con,HO6FL: 
    if (policyTypeId === "a9" || policyTypeId === "A0EB765A558C40EE9D68C9F0538370FA" ||
      policyTypeId === "FBE48780E8D211D2A66600105A5E36FB") {
      return '<ul><li>HO-6/HB-Con,HO6FL<br><p class="ml-2">The standard condo insurance policy.</p></li></ul>';
    }
    // DP3 is Missing. DF
    if (policyTypeId === "EF99E1E7CE9611D0AD7F0055007657A3") {
      return '<ul><li>DF<br><p class="ml-2">Designed for non-owner occupied, residential homes. Considered the best insurance policy for rental homes.</p></li></ul>';
    }
    //	HO1, HO2 are missing, only DP1,HOA,HOA+
    if (policyTypeId === "94C60E70AA064E4AA3599B3BD712E056" || policyTypeId == "2980564449104CADA74F58F22B814886"
      || policyTypeId === "9A9CE7712F7B475A8E282747F01A8AC8") {
      return '<ul><li>DP1,HOA,HOA+<br><p class="ml-2">Provide very limited protection. Due to the narrow coverage, many insurance companies no longer offer these types of policies, or only use them if your home doesn’t qualify for other types of insurance.</p></li></ul>';
    }

  }
}