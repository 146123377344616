<!-- F O O T E R  -->

<section id="footer" class="py-4">
	<div class="container-xl">

		<div class="row pb-3 pt-1 ml-0 d-none d-md-block d-lg-block d-xl-block">
			<div class="pl-0 col-md-4" style="margin-left: -15px; padding-left: 0px;">
				<a (click)="navigateToUrl('')" class="cursor-pointer" style="vertical-align: bottom;">
					<img routerLink="/" id="footerLogo" class="img-fluid align-middle"
						style="width: 305px; padding: 30px 30px 30px 5px;"
						src="assets/img/logos/Westwood-Insurance-Logo_White_Black-Logo.webp"
						alt="Westwood Insurance logo">
				</a>
			</div>
			<div class="col-md-5">
				<!-- <img id="footerLogoTwo" class="img-fluid d-md-block"
					src="assets/img/logos/top-100-agency-badge-2022-200x200.png" alt="Westwood Insurance logo"
					style="width: 20%;padding-left: 1rem;"> -->
			</div>
		</div>
		<div class="row pb-2 pt-1 ml-0 d-block d-md-none">
			<div class="pl-0 col-md-4" style="margin-left: -15px; padding-left: 0px;">
				<a (click)="navigateToUrl('')" class="cursor-pointer" style="vertical-align: bottom;">
					<img routerLink="/" id="footerLogo" class="img-fluid align-middle"
						style="width: 250px; padding: 30px 30px 30px 5px;"
						src="assets/img/logos/Westwood-Insurance-Logo_White_Black-Logo.webp"
						alt="Westwood Insurance logo">
				</a>
			</div>
			<div class="col-md-5">
				<!-- <img id="footerLogoTwo" class="img-fluid d-md-block"
					src="assets/img/logos/top-100-agency-badge-2022-200x200.png" alt="Westwood Insurance logo"
					style="width: 20%;padding-left: 1rem;"> -->
			</div>
		</div>
		<div class="row mb-2 pt-4">
			<div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 pt-4">
				<ul id="footerNav" class="nav flex-column">
					<li class="nav-item pb-2">
						<a class="footer-menu-item" (click)="navigateToUrl('about')">About &nbsp;</a>
						<i (click)="collapseSideMenu('about')"
							[ngClass]="selectedMenu === 'about' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'about'">
							<ul class="nav flex-column">
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('about/leadership-team/')">Leadership Team</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item" (click)="navigateToUrl('careers/')">Careers</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('about/resource-center/')">Resource Center</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="footer-menu-item" (click)="navigateToUrl('products')">Products &nbsp;</a>
						<i (click)="collapseSideMenu('products')"
							[ngClass]="selectedMenu === 'products' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'products'">
							<ul class="nav flex-column">
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('products/homeowners-insurance/')">Homeowners</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('products/renters-insurance/')">Renters</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('products/auto-insurance/')">Auto</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('products/small-business-insurance/')">Small Business</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item" (click)="navigateToUrl('products')">All Products</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="footer-menu-item" (click)="selectMenuHead('customers')"
							[ngClass]="selectedMobileMenuFooterCurrent === 'customers' ? 'selected-footer-menu' : 'default-footer-menu-item'">Customers
							&nbsp;</a>
						<i (click)="collapseSideMenu('customers')"
							[ngClass]="selectedMenu === 'customers' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'customers'">
							<ul class="nav flex-column">
								<li class="nav-item">
									<a class="footer-sub-menu-item" (click)="navigateToUrl('claims/')">CLAIMS</a>
								</li>
								<li class="nav-item">
									<a routerLink="/current-customers/make-a-payment"
										(click)="selectSubMenu('make-a-payment')"
										[ngClass]="selectedSubMenu === 'make-a-payment' ? 'footer-sub-menu-item-Selected' : 'footer-sub-menu-item'">MAKE
										A PAYMENT</a>
								</li>
								<li class="nav-item">
									<a routerLink='/current-customers/request-policy-changes'
										(click)="selectSubMenu('request-policy-change')"
										[ngClass]="selectedSubMenu === 'request-policy-change' ? 'footer-sub-menu-item-Selected' : 'footer-sub-menu-item'">REQUEST
										POLICY
										CHANGES</a>
								</li>
								<li class="nav-item">
									<a routerLink="/current-customers/my-policy-documents"
										(click)="selectSubMenu('my-policy-documents')"
										[ngClass]="selectedSubMenu === 'my-policy-documents' ? 'footer-sub-menu-item-Selected' : 'footer-sub-menu-item'">MY
										POLICY DOCUMENTS</a>
								</li>
								<li class="nav-item">
									<a routerLink="/current-customers/lender-requests-changes"
										(click)="selectSubMenu('lender-requests-changes')"
										[ngClass]="selectedSubMenu === 'lender-requests-changes' ? 'footer-sub-menu-item-Selected' : 'footer-sub-menu-item'">LENDER
										REQUESTS/CHANGES</a>
								</li>
								<li class="nav-item">
									<a routerLink="/current-customers/opt-in-for-text-messages"
										(click)="selectSubMenu('opt-in-for-text-messages')"
										[ngClass]="selectedSubMenu === 'opt-in-for-text-messages' ? 'footer-sub-menu-item-Selected' : 'footer-sub-menu-item'">OPT-IN
										FOR TEXT
										MESSAGES</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="footer-menu-item">Partners &nbsp;</a>
						<i (click)="collapseSideMenu('partners')"
							[ngClass]="selectedMenu === 'partners' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'partners'">
							<ul class="nav flex-column">
								<li class="nav-item">
									<a class="footer-sub-menu-item" (click)="navigateToUrl('partners/')">Builders</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item"
										(click)="navigateToUrl('partners-lenders/')">Lenders</a>
								</li>
								<li class="nav-item">
									<a class="footer-sub-menu-item" (click)="navigateToUrl('partners-api/')">API</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="footer-menu-item" (click)="navigateToUrl('news/')">News</a>
					</li>
					<li class="nav-item pb-2">
						<a class="footer-menu-item" (click)="navigateToUrl('contact-us/')">Contact Us</a>
					</li>
				</ul>
			</div>
			<div class="d-lg-none d-xl-none" style="height: 30px;">&nbsp;</div>
			<div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 footer-contactus pt-4">
				<span class="text-uppercase d-block"
					style="margin-bottom: 20px; font-size: 19px; line-height: 19px; font-weight: 600;">Contact Us</span>
				<div>
					<span>Current Customers</span> <br />
					<span style="color: #5bb3cc;">
						<a class="footer-sub-menu-item"
							style="font-size: 16px !important; line-height: 26px !important; font-weight: 600 !important; color: #5BB3CC !important; padding-left: 0px;"
							href="tel:18006765066">800.676.5066</a>
					</span>
				</div>
				<div>
					<span>New Customers</span> <br />
					<span style="color: #5bb3cc;">
						<a class="footer-sub-menu-item"
							style="font-size: 16px !important; line-height: 26px !important; font-weight: 600 !important; color: #5BB3CC !important; padding-left: 0px;"
							href="tel:18885436179">888.543.6179</a>
					</span>
				</div>
				<div>
					<span>Email Us</span> <br />
					<span style="color: #5bb3cc;">
						<a class="footer-sub-menu-item"
							style="font-size: 16px !important; line-height: 26px !important; font-weight: 600 !important; color: #5BB3CC !important; padding-left: 0px;"
							href="mailto:Contactus@Westwoodins.com">contactus@westwoodins.com</a>
					</span>
				</div>
			</div>
			<div class="d-block d-xl-none col-12" style="height: 50px;">&nbsp;</div>
			<div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 pt-4">
				<span class="text-uppercase footer-title d-block pb-3"
					style="font-weight: 600; font-size: 19px; line-height: 19px;">Connect with Us</span> <br />
				<div style="display: flex; flex-direction: row;">
					<div style="width: 25%; min-width: 25%; display: inline">
						<a href="https://www.linkedin.com/company/westwood-insurance-agency/" target="_blank">
							<i class="fab fa-linkedin fa-lg fab-logo footer-icon"
								style="font-size: 44px; line-height: 44px;"></i>
						</a>
					</div>

					<div style="width: 25%; min-width: 25%; display: inline">
						<a href="https://www.youtube.com/@westwoodinsuranceagency" target="_blank">
							<i class="fab fa-youtube fa-lg fab-logo footer-icon"
								style="font-size: 44px; line-height: 44px;"></i>
						</a>
					</div>
					<div style="width: 25%; min-width: 25%; display: inline;">
						<a href="https://www.instagram.com/westwoodinsuranceagency/" target="_blank">
							<i class="fab fa-instagram fa-lg fab-logo footer-icon"
								style="font-size: 44px; line-height: 44px;"></i>
						</a>
					</div>
					<div style="width: 25%; min-width: 25%; display: inline">
						<a href="https://www.facebook.com/WestwoodInsuranceAgency/" target="_blank">
							<i class="fab fa-facebook fa-lg fab-logo footer-icon"
								style="font-size: 44px; line-height: 44px;"></i>
						</a>
					</div>
				</div>
			</div>

		</div>
		<!-- <div class="d-none d-none d-lg-block" style="height: 135px;"></div> -->
		<div style="height: 65px;">&nbsp;</div>
		<div class="text-white" style="font-size: 16px; font-weight: 400; line-height: 26px;">
			<div *ngIf="screenWidth < 450 && screenWidth < screenHeight">
				<div class="row">
					<div class="col-12"
						style="margin-bottom: 0px; font-size: 17px; line-height: 26px; font-weight: 600;">
						<p class="text-left mt-1 mb-2" style="font-size: 16px; font-weight: 400;">
							Licensed in California as Westwood Insurance Agency | Woodland Hills, CA | CA License
							#0401583
						</p>
						<p class="text-left mt-2 mb-1" style="font-size: 16px; font-weight: 400;">
							&copy; Westwood Insurance Agency. All Rights Reserved
						</p>
					</div>
					<div class="col-12">
						<p class="text-left mt-0 mb-2 pt-0" style="font-size: 16px; font-weight: 600;">
							<a href="https://baldwinriskpartners.com/terms-of-use/" target="_blank"
								style="text-decoration: none; cursor: pointer;">Terms of Use</a> <br />
						</p>

						<p class="text-left mt-2 mb-0 pb-0" style="font-size: 16px; font-weight: 600;">
							<a href="https://baldwinriskpartners.com/privacy-policy/" target="_blank"
								style="text-decoration: none; cursor: pointer;">Privacy Policy</a>
						</p>
					</div>
				</div>
			</div>
			<div *ngIf="(screenWidth > 450 && screenWidth < 950) && screenWidth > screenHeight">
				<div class="row">
					<div class="col-9"
						style="margin-bottom: 0px; border-right: 2px solid white; font-size: 17px; line-height: 26px; font-weight: 600;">
						<p class="text-left mt-1 mb-2" style="font-size: 16px; font-weight: 400;">
							Licensed in California as Westwood Insurance Agency | Woodland Hills, CA | CA License
							#0401583
						</p>
						<p class="text-left mt-2 mb-1" style="font-size: 16px; font-weight: 400;">
							&copy; Westwood Insurance Agency. All Rights Reserved
						</p>
					</div>
					<div class="col-3 footer-legal-privacy">
						<div class="ml-3">
							<p class="text-left mt-0 mb-2 pt-0" style="font-size: 16px; font-weight: 600;">
								<a href="https://baldwinriskpartners.com/terms-of-use/" target="_blank"
									style="text-decoration: none; cursor: pointer;">Terms of Use</a> <br />
							</p>

							<p class="text-left mt-2 mb-0 pb-0" style="font-size: 16px; font-weight: 600;">
								<a href="https://baldwinriskpartners.com/privacy-policy/" target="_blank"
									style="text-decoration: none; cursor: pointer;">Privacy Policy</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="text-white d-none d-lg-block" style="font-size: 16px; font-weight: 400; line-height: 26px;">
			<div class="row">
				<div class="col-9"
					style="margin-bottom: 0px; border-right: 2px solid white; font-size: 17px; line-height: 17px; font-weight: 600;">
					<p class="text-left mt-1 mb-2" style="font-size: 16px; font-weight: 400;">
						Licensed in California as Westwood Insurance Agency | Woodland Hills, CA | CA License #0401583
					</p>
					<p class="text-left mt-2 mb-1" style="font-size: 16px; font-weight: 400;">
						&copy; Westwood Insurance Agency. All Rights Reserved
					</p>
				</div>
				<div class="col-3">
					<p class="text-left mt-0 mb-2 pt-0 ml-3" style="font-size: 16px; font-weight: 600;">
						<a href="https://baldwinriskpartners.com/terms-of-use/" target="_blank"
							style="text-decoration: none; cursor: pointer;">Terms of Use</a> <br />
					</p>

					<p class="text-left mt-2 mb-0 pb-0 ml-3" style="font-size: 16px; font-weight: 600;">
						<a href="https://baldwinriskpartners.com/privacy-policy/" target="_blank"
							style="text-decoration: none; cursor: pointer;">Privacy Policy</a>
					</p>
				</div>
			</div>
		</div>
		<div style="height: 35px;"></div>
	</div>
</section>