import { NgxPaginationModule } from "ngx-pagination"; //WSTW-5641 to implement prev and next buttons
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PublicRoutingModule } from "./public-routing.module";
import { ArchwizardModule } from "angular-archwizard";
import { NgbModule, NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxCurrencyModule } from 'ngx-currency';

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ProductsComponent } from "./products/products.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { PublicComponent } from "./public.component";
import { ResourceCenterComponent } from "./resource-center/resource-center.component";
import { InsuranceLicensingComponent } from "./insurance-licensing/insurance-licensing.component";
import { SharedModule } from "../shared/shared.module";
import { HomeownersComponent } from "./homeowners/homeowners.component";
import { CondoComponent } from "./condo/condo.component";
import { RentersComponent } from "./renters/renters.component";
import { AutoComponent } from "./auto/auto.component";
import { FloodComponent } from "./flood/flood.component";
import { EarthquakeComponent } from "./earthquake/earthquake.component";
import { UmbrellaComponent } from "./umbrella/umbrella.component";
import { JewelryAndValuablesComponent } from "./jewelry-and-valuables/jewelry-and-valuables.component";
import { GolfComponent } from "./golf/golf.component";
import { MotorcycleComponent } from "./motorcycle/motorcycle.component";
import { PetInsuranceComponent } from "./pet-insurance/pet-insurance.component";
import { TravelInsuranceComponent } from "./travel-insurance/travel-insurance.component";
import { EventComponent } from "./event/event.component";
import { BicycleComponent } from "./bicycle/bicycle.component";
import { CurrentCustomersComponent } from "./current-customers/current-customers.component";
import { MakeAPaymentComponent } from "./make-apayment/make-apayment.component";
import { RequestPolicyChangesComponent } from "./request-policy-changes/request-policy-changes.component";
import { MyPolicyDocumentsComponent } from "./my-policy-documents/my-policy-documents.component";
import { LenderRequestsChangesComponent } from "./lender-requests-changes/lender-requests-changes.component";
import { OptInForTextMessagesComponent } from "./opt-in-for-text-messages/opt-in-for-text-messages.component";
import { ClaimsComponent } from "./claims/claims.component";
import { ReportAClaimComponent } from "./report-aclaim/report-aclaim.component";
import { ClaimPreventionComponent } from "./claim-prevention/claim-prevention.component";
import { MinimizeYourLossComponent } from "./minimize-your-loss/minimize-your-loss.component";
import { PartnerWithUsComponent } from "./partner-with-us/partner-with-us.component";
import { GetAQuoteComponent } from "./get-aquote/get-aquote.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { EmailValidatorDirective } from "../directive/email-validator.directive";
import { ValidatePhoneTypesDirective } from "../directive/validate-phone-types.directive";
import { TooltipMessage } from "../Utilities/Constants/tooltipMessage";
import {
  ConfigFactory,
  ConfigService,
  API_BASE_URL,
  captchaSiteKey,
  homeURL,
  captchaSiteKeyV3,
  captchaSecretKeyV3,
  gAnalyticsKey,
  iFrameOptInForTextMessages,
  iFrameTravelOnline,
  WEB_BASE_URL
} from "../service/config.service";
import { NgxCaptchaModule } from "ngx-captcha";
import { GoogleAutoCompletePlacesDirective } from "../directive/google-auto-complete-places.directive";
import { DateValidatorDirective } from "../directive/date-validator.directive";
import { TwoDigitDecimaNumberDirective } from "../directive/two-digit-decima-number.directive";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxMaskModule } from "ngx-mask";
import { CreditAuthComponent } from "./credit-auth/credit-auth.component";
import { CustomerInfoComponent } from "./customer-info/customer-info.component";
import { HomeInfoComponent } from "./home-info/home-info.component";
import { CustomMinDirective } from "../directive/custom-min-validator.directive";
import { CustomMaxDirective } from "../directive/custom-max-validator.directive";
import { UnderwritingComponent } from "./underwriting/underwriting.component";
import { OnlyNumberDirective } from "../directive/only-number.directive";
import { QuoteMessageComponent } from "./quote-message/quote-message.component";
import { CustNoticeComponent } from "./cust-notice/cust-notice.component";
import { QuoteResultComponent } from "./quote-result/quote-result.component";
import { PrintFormatComponent } from "./print-format/print-format.component";
import { RcvDetailsComponent } from "./rcv-details/rcv-details.component";
import { NgxPrintModule } from "ngx-print";
import { NoSanitizePipe } from "../directive/no-sanitize-pipe";
import { LoanInfoComponent } from "./loan-info/loan-info.component";
import { QuoteThankYouComponent } from "./quote-thank-you/quote-thank-you.component";
import { ContactMeComponent } from "./contact-me/contact-me.component";
import { LegalComponent } from "./legal/legal.component";
import { UnSubscribeComponent } from "./un-subscribe/un-subscribe.component";
import { OptoutTextMsgesComponent } from "./optout-text-msges/optout-text-msges.component";
import { OptInRentersComponent } from "./opt-in-renters/opt-in-renters.component";
import { ValidateEmailProspectComponent } from "./validate-email-prospect/validate-email-prospect.component";
import { ZipcodeValidatorDirective } from "../directive/zipcode-validator.directive";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { SsnComponent } from "./ssn/ssn.component";
import { CcpaComponent } from "./ccpa/ccpa.component";
import { UrlSerializer } from "@angular/router";
import { LowerCaseUrlSerializer } from "../Utilities/lower-case-url-serializer";
import { AnalyticsService } from "../service/analytics.service";
import { DateValidateForEstCloseDateDirective } from "../directive/date-validate-for-est-close-date.directive";
import { SmallCommercialComponent } from "./small-commercial/small-commercial.component";
import { TravelOnlineQuoteComponent } from "./travel-online-quote/travel-online-quote.component";
import { Alert1InfoComponent } from "./alert1-info/alert1-info.component";
import { Alert2InfoComponent } from "./alert2-info/alert2-info.component";
import { LegalHomeComponent } from "./legal-home/legal-home.component";
import { UnauthorisedAccessComponent } from "./unauthorised-access/unauthorised-access.component";
import { MobilePhoneInsuranceComponent } from "./mobile-phone-insurance/mobile-phone-insurance.component";
import { MyquoteComponent } from "./myquote/myquote.component";
import { NewsArticlesComponent } from "./news-articles/news-articles.component";
import { ArticleDetailComponent } from "./article-detail/article-detail.component";
import { NgbPaginationModule, NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { HighlightDirective } from "../directive/highlight.directive";
import { SiteMapComponent } from "./site-map/site-map.component";
import { ComparePasswordDirective } from "../directive/compare-password.directive";
import { PartnerApiComponent } from "./partner-api/partner-api.component";
import { PartnerLandingComponent } from "./partner-landing/partner-landing.component";
import { PartnerBuilderComponent } from "./partner-builder/partner-builder.component";
import { PartnerLenderComponent } from "./partner-lender/partner-lender.component";
import { LenderChangesComponent } from "./lender-changes/lender-changes.component";

import { LeadershipComponent } from "./leadership/leadership.component";
import { HomeWarrantyServiceAgreementComponent } from "./home-warranty-service-agreement/home-warranty-service-agreement.component";
import { AboutYourselfComponent } from "../public/about-yourself/about-yourself.component";
import { QuoteContainerComponent } from "../public/quote-container/quote-container.component";
import { CustomAdapter } from "../shared/ngb-components/ngb-datepicker-formatter";
import { RatingFailedComponent } from './rating-failed/rating-failed.component';
import { RtqLandingPageComponent } from './rtq-landing-page/rtq-landing-page.component';

@NgModule({
  declarations: [
    HighlightDirective,
    OnlyNumberDirective,
    CustomMinDirective,
    CustomMaxDirective,
    DateValidatorDirective,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    ProductsComponent,
    ContactUsComponent,
    PublicComponent,
    ResourceCenterComponent,
    InsuranceLicensingComponent,
    HomeownersComponent,
    CondoComponent,
    RentersComponent,
    AutoComponent,
    FloodComponent,
    EarthquakeComponent,
    UmbrellaComponent,
    JewelryAndValuablesComponent,
    GolfComponent,
    MotorcycleComponent,
    PetInsuranceComponent,
    TravelInsuranceComponent,
    EventComponent,
    BicycleComponent,
    CurrentCustomersComponent,
    MakeAPaymentComponent,
    RequestPolicyChangesComponent,
    MyPolicyDocumentsComponent,
    LenderRequestsChangesComponent,
    OptInForTextMessagesComponent,
    ClaimsComponent,
    ReportAClaimComponent,
    ClaimPreventionComponent,
    MinimizeYourLossComponent,
    PartnerWithUsComponent,
    GetAQuoteComponent,
    PageNotFoundComponent,
    EmailValidatorDirective,
    ComparePasswordDirective,
    ZipcodeValidatorDirective,
    ValidatePhoneTypesDirective,
    GoogleAutoCompletePlacesDirective,
    CreditAuthComponent,
    CustomerInfoComponent,
    HomeInfoComponent,
    UnderwritingComponent,
    QuoteMessageComponent,
    CustNoticeComponent,
    QuoteResultComponent,
    PrintFormatComponent,
    RcvDetailsComponent,
    NoSanitizePipe,
    LoanInfoComponent,
    QuoteThankYouComponent,
    ContactMeComponent,
    LegalComponent,
    UnSubscribeComponent,
    OptoutTextMsgesComponent,
    OptInRentersComponent,
    ValidateEmailProspectComponent,
    TwoDigitDecimaNumberDirective,
    PrivacyPolicyComponent,
    SsnComponent,
    CcpaComponent,
    DateValidateForEstCloseDateDirective,
    SmallCommercialComponent,
    TravelOnlineQuoteComponent,
    Alert1InfoComponent,
    Alert2InfoComponent,
    LegalHomeComponent,
    UnauthorisedAccessComponent,
    MobilePhoneInsuranceComponent,
    MyquoteComponent,
    NewsArticlesComponent,
    ArticleDetailComponent,
    SiteMapComponent,
    PartnerApiComponent,
    PartnerLandingComponent,
    PartnerBuilderComponent,
    PartnerLenderComponent,
    LenderChangesComponent,

    LeadershipComponent,
    HomeWarrantyServiceAgreementComponent,
    AboutYourselfComponent,
    QuoteContainerComponent,
    RatingFailedComponent,
    RtqLandingPageComponent
  ],

  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule,
    NgxCaptchaModule,
    NgxPaginationModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxPrintModule,
    ArchwizardModule,
    NgbModule,
    NgxCurrencyModule
  ],
  providers: [
    OnlyNumberDirective,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    CustomMinDirective,
    AnalyticsService,
    CustomMaxDirective,
    DateValidatorDirective,
    EmailValidatorDirective,
    ComparePasswordDirective,
    ZipcodeValidatorDirective,
    ValidatePhoneTypesDirective,
    TwoDigitDecimaNumberDirective,
    GoogleAutoCompletePlacesDirective,
    TooltipMessage,
    HighlightDirective,
    {
      provide: API_BASE_URL,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "APIURL-VAR"],
    },
    {
      provide: WEB_BASE_URL,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "WEBURL-VAR"],
    },
    { provide: "CAPTCHASITE-VAR", useValue: "captchaSiteKey" },
    {
      provide: captchaSiteKey,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "CAPTCHASITE-VAR"],
    },
    { provide: "CAPTCHASITEV3-VAR", useValue: "captchaSiteKeyV3" },
    {
      provide: captchaSiteKeyV3,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "CAPTCHASITEV3-VAR"],
    },
    {
      provide: "IFRAMEOPTINFORTEXTMSGURL",
      useValue: "iFrameOptInForTextMessages",
    },
    {
      provide: iFrameOptInForTextMessages,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "IFRAMEOPTINFORTEXTMSGURL"],
    },
    { provide: "IFRAMETRAVELONLINE", useValue: "iFrameTravelOnline" },
    {
      provide: iFrameTravelOnline,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "IFRAMETRAVELONLINE"],
    },
    { provide: "captchaSecretKeyV3-VAR", useValue: "captchaSecretKeyV3" },
    {
      provide: captchaSecretKeyV3,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "captchaSecretKeyV3-VAR"],
    },
    { provide: "gAnalyticsKey-VAR", useValue: "gAnalyticsKey" },
    {
      provide: gAnalyticsKey,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "gAnalyticsKey-VAR"],
    },
    { provide: "homeURL-VAR", useValue: "homeURL" },
    {
      provide: homeURL,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "homeURL-VAR"],
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },

  ],
})
export class PublicModule {}
