<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-auto" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Auto Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none d-none">
    <div class="container text-center pt-3 pb-5">

        <div class="row justify-content-center">
            <div class="col-md-9 col-xs-9">
                <p><span> You depend on your vehicle for so much!</span><br> Let Westwood review the policies offered by
                    the
                    top auto insurance companies in the country to find the best coverage and rate for you. Even better,
                    we are often able to provide a discount if you have both homeowners and auto coverage with Westwood.
                </p>
                <br>
                <span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
                    questions regarding Auto Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
            </div>
        </div>

    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H  Y   A U T O   -->

<section>
    <div class="container  pt-3 pb-5">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center">Why Auto Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/page-body-img/shutterstock_144026338.png" style="width: 100%;"
                    alt="Homeowners Insurance" class="pb-4" />
            </div>
            <div class="col">
                Auto insurance is important protection for your financial well-being. It’s also required in most states!
                Even if you live in a state that doesn’t require coverage, your lender or finance company will require
                coverage if you have an auto lease or loan.
                <br><Br>
                Not only does auto insurance cover the cost of repairing or replacing your vehicle if it is damaged, but
                the liability coverage will help pay for the other party’s medical bills and car damage if you are at
                fault for the accident. Plus, you may also have financial protection for legal fees, up to your policy
                limits, if you are taken to court.
            </div>
        </div>
    </div>
</section>

<!-- //  W H Y   A U T O   -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section id="whats-included">
    <div class="container pt-5 pb-5">

        <div class="row">
            <div class="col">
                <h2 class="col text-center">What's Included in auto insurance?</h2>
            </div>
        </div>

        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="gray line decor" />
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-6 col-xs-12">
                <ul class="pl-0">
                    <li>Vehicle repair or replacement </li>
                    <li>Rental car reimbursement </li>
                    <li>Medical benefits for the drivers and passengers </li>
                </ul>
            </div>
            <div class="col-md-6 col-xs-12">
                <ul class="pl-0">
                    <li>Bodily injury and property damage liability protection </li>
                    <li>Protection if you are hit by an uninsured motorist </li>
                    <li>Various types of deductible reductions or waivers </li>
                </ul>
            </div>
        </div>



        <!-- O N L I N E   Q U O T E -->



        <!-- // O N L I N E   Q U O T E -->

    </div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->





<!--  F U L L   C O V E R A G E   D E T A I L S  -->

<section id="see-full-coverage">
    <div class="container top-border-element pt-5 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">full coverage details</h2>
            </div>
        </div>

        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
                <br><br>
                <span>Auto insurance varies widely based on where you live and the types of optional coverage that you
                    choose. Generally, your policy will have (or you can choose to have) these types of coverage:</span>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-12">
                <nav>
                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav-coll-loss-tab" data-toggle="tab"
                            href="#nav-coll-loss" role="tab" aria-controls="nav-coll-loss"
                            aria-selected="true">Collision Loss</a>
                        <a class="nav-item nav-link" id="nav-comp-loss-tab" data-toggle="tab" href="#nav-comp-loss"
                            role="tab" aria-controls="nav-comp-loss" aria-selected="true">Comprehensive Loss (Other
                            than Collision Loss)</a>
                        <a class="nav-item nav-link" id="nav-rent-rei-tab" data-toggle="tab" href="#nav-rent-rei"
                            role="tab" aria-controls="nav-rent-rei" aria-selected="true">Rental Reimbursement</a>
                        <a class="nav-item nav-link" id="nav-towing-tab" data-toggle="tab" href="#nav-towing" role="tab"
                            aria-controls="nav-towing" aria-selected="true">Roadside Assistance</a>
                        <a class="nav-item nav-link" id="nav-glass-waiv-tab" data-toggle="tab" href="#nav-glass-waiv"
                            role="tab" aria-controls="nav-glass-waiv" aria-selected="true">Glass Waiver</a>
                        <a class="nav-item nav-link" id="nav-uni-motor-tab" data-toggle="tab" href="#nav-uni-motor"
                            role="tab" aria-controls="nav-uni-motor" aria-selected="true">Uninsured Motorist Collision
                            Deductible Waiver</a>
                        <a class="nav-item nav-link" id="nav-med-pay-tab" data-toggle="tab" href="#nav-med-pay"
                            role="tab" aria-controls="nav-med-pay" aria-selected="true">Medical Payments</a>
                        <a class="nav-item nav-link" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
                            aria-controls="nav-home" aria-selected="true">Bodily Injury Liability</a>
                        <a class="nav-item nav-link" id="nav-motorist-tab" data-toggle="tab" href="#nav-motorist"
                            role="tab" aria-controls="nav-motorist" aria-selected="true">Uninsured and Underinsured
                            Motorists
                            Bodily Injury</a>
                        <a class="nav-item nav-link" id="nav-prop-damage-tab" data-toggle="tab" href="#nav-prop-damage"
                            role="tab" aria-controls="nav-prop-damage" aria-selected="true">Property Damage
                            Liability</a>
                        <a class="nav-item nav-link" id="nav-p-inj-tab" data-toggle="tab" href="#nav-p-inj" role="tab"
                            aria-controls="nav-p-inj" aria-selected="true">Personal Injury Protection</a>
                    </div>
                </nav>

                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">

                    <div class="tab-pane fade show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        If you are found liable for an accident, your liability coverage pays for injury to others and
                        may also include certain legal services such as court costs, bail bond premiums and attorney
                        fees. Coverage may be increased for an additional premium. This coverage is required in all
                        states except Florida.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>If you are found liable for an accident, your liability coverage pays for injury to
                                    others, up to the limit(s) shown on your policy.</li>
                                <li>It may also include certain legal services such as court costs, bail bond premiums
                                    and attorney fees, subject to the limitations outlined in the policy.</li>
                                <li>Coverage may be increased for an additional premium. </li>
                                <li> If you see two numbers, the first is per person and the second is total for the
                                    accident.</li>
                            </ul>
                            *This coverage is required in all states except Florida.
                        </div> -->
                    </div>

                    <div class="tab-pane fade show" id="nav-prop-damage" role="tabpanel"
                        aria-labelledby="nav-prop-damage-tab">
                        If you are found liable for an accident, your liability coverage pays for damage to others’
                        property (car, house, yard, etc.). Coverage may be increased for an additional premium. This
                        coverage is required.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li> If you are found liable for an accident, your liability coverage pays for damage
                                    to others’ property (car, house, yard, etc.), up to the limit shown on your policy.
                                </li>
                                <li> Coverage may be increased for an additional premium. </li>
                                <li> This coverage is required.</li>
                            </ul>
                        </div> -->
                    </div>

                    <div class="tab-pane fade show" id="nav-motorist" role="tabpanel"
                        aria-labelledby="nav-motorist-tab">
                        This coverage pays for expenses related to bodily injury to you and other passengers/drivers of
                        your insured vehicle, if an accident is caused by a hit-and-run, uninsured or underinsured
                        driver. Coverage may be increased for an additional premium. This coverage is mandatory in some
                        states.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>If this coverage is present, your policy will cover bodily injury to you and other
                                    passengers/drivers of your insured vehicle, up to the limit shown on your policy,
                                    if an accident is caused by a hit-and-run, uninsured or underinsured driver.</li>
                                <li>Coverage parameters vary by state, and in some states this coverage is mandatory.
                                </li>
                                <li> Coverage may be increased for an additional premium.</li>
                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show" id="nav-med-pay" role="tabpanel" aria-labelledby="nav-med-pay-tab">
                        If you have this coverage, your policy will cover medical payments for you or others injured in
                        your covered vehicle. Coverage may be increased for an additional premium. This coverage is
                        mandatory in some states.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>If you have this coverage, your policy will cover medical payments, up to the limit
                                    shown on your policy, to you or others injured in your covered vehicle.</li>
                                <li>Coverage may be increased for an additional premium. </li>
                                <li> Coverage parameters vary by state and in some states, this coverage is mandatory.
                                </li>

                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show" id="nav-p-inj" role="tabpanel" aria-labelledby="nav-p-inj-tab">
                        Your policy will cover medical payments and other damages such as loss of income, up to the
                        limit shown on your policy, for you or others injured in your covered vehicle. Coverage may be
                        increased for an additional premium. This coverage is mandatory in some states.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>If you have this coverage, your policy will cover medical payments and other
                                    damages such as loss of income, up to the limit shown on your policy, to you or
                                    others injured in your covered vehicle.</li>
                                <li>Coverage may be increased for an additional premium.</li>
                                <li>Coverage parameters vary by state and in some states this coverage is mandatory.
                                </li>
                                <li>This coverage is not present at the same time as Medical Payments.</li>
                                <li>Specifics of this coverage vary by state.</li>
                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show" id="nav-comp-loss" role="tabpanel"
                        aria-labelledby="nav-comp-loss-tab">
                        This coverage pays for damage to your covered vehicle resulting from noncollision incidents
                        including vandalism, hail, flood, fire or falling objects. It also provides coverage if your
                        vehicle is broken into or stolen. This is optional coverage but often required if your vehicle
                        is financed.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>This coverage pays for damage to your covered vehicle resulting from noncollision
                                    incidents including vandalism, hail, flood, fire or falling objects.</li>
                                <li>It also provides coverage if your vehicle is broken into or stolen.</li>
                                <li>This is an optional coverage but often required if your vehicle is financed.</li>
                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show  active" id="nav-coll-loss" role="tabpanel"
                        aria-labelledby="nav-coll-loss-tab">
                        This coverage pays for accidental damage to your covered vehicle resulting from a collision with
                        another vehicle or object. This is optional coverage but often required if your vehicle is
                        financed.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>This coverage pays for accidental damage to your covered vehicle resulting from a
                                    collision with another vehicle or object. </li>
                                <li>This is optional coverage but often required if your vehicle is financed. </li>

                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show " id="nav-uni-motor" role="tabpanel"
                        aria-labelledby="nav-uni-motor-tab">
                        This coverage waives the collision deductible if you are involved in an accident with an
                        uninsured motorist. This coverage is not available in every state.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>This coverage waives the collision deductible stated on your policy if you are
                                    involved in an accident with an uninsured motorist. </li>
                                <li>There may be a separate deductible for this, and it is not available in every
                                    state.</li>

                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show " id="nav-rent-rei" role="tabpanel"
                        aria-labelledby="nav-rent-rei-tab">
                        This coverage reimburses you for a rental car during the time you are unable to use your vehicle
                        because of a covered accident. Your policy will have a limit on the amount of reimbursement, but
                        it can be increased for an additional premium.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>This coverage reimburses you, up to the stated limit shown on your policy, for a
                                    rental car during the time you are unable to use your vehicle because of a covered
                                    accident or other circumstance provided in your policy.</li>
                                <li>Coverage may be increased for an additional premium.</li>
                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show " id="nav-towing" role="tabpanel" aria-labelledby="nav-towing-tab">
                        For specific circumstances, this coverage provides a service that you can call for roadside
                        assistance or reimburses you for towing or roadside assistance.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li>This coverage provides you a service to call for roadside assistance or reimburses
                                    you, up to special limits, for towing or roadside assistance if conditions meet the
                                    terms.</li>

                            </ul>
                        </div> -->

                    </div>
                    <div class="tab-pane fade show " id="nav-glass-waiv" role="tabpanel"
                        aria-labelledby="nav-glass-waiv-tab">
                        This optional coverage lowers or waives your comprehensive deductible for glass-only claims.
                        This is optional coverage, available in some states.
                        <!-- <div id="a-bullet-list2">
                            <ul>
                                <li> This is an optional endorsement in some states that lowers or waives your
                                    comprehensive deductible for glass-only claims.</li>

                            </ul>
                        </div> -->

                    </div>

                </div>

            </div>
        </div>

    </div>
</section>

<!-- //  F U L L   C O V E R A G E  D E T A I L S -->


<!--  F A Q  -->

<section>
    <div class="container bottom-border-element pt-3 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">Frequently Asked Questions</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" />
            </div>
        </div>

        <div class="row text-center pb-5">
            Auto insurance can be confusing and the laws governing it vary widely by state. That’s why your Westwood
            agent and customer service team are so important. If you have a question &mdash; ask us!
            <br><br>
            Here are some of the most frequently-asked questions from our customers. The answers provide
            general information &mdash; each company defines their own specific coverage, terms,
            limitations and
            exclusions.
            Your
            policy includes the specifics.

        </div>

        <div class="row accordion" id="accordionExample">

            <div class="col-md-6 col-xs-12">
                <div class="card">
                    <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn text-left btn-link" type="button" data-toggle="collapse"
                                data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Is it true that all auto insurance policies are about the same if I have “full
                                coverage”?
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body">
                            No, auto insurance policies are not the same. The term "full coverage" is too vague
                            to
                            be used for comparing auto insurance offered by different companies. And the worst
                            time
                            to find out that your “full coverage” policy does not have a critical type of
                            coverage,
                            is after an accident! At Westwood, our auto experts take the time to ensure that you
                            have the coverage, deductibles and limits that are right for you.
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button class="btn btn-link text-left collapsed" type="button" data-toggle="collapse"
                                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Can I just get an estimated auto insurance premium rate?
                            </button>
                        </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body">
                            In some situations, we may be able to provide an estimated quote before we ask you
                            for
                            more information. However, an estimate won't help you compare auto insurance rates,
                            because the details &mdash; who you are, what you drive and information about your
                            claims and
                            driving history &mdash; are what make the difference in your actual rate. We
                            understand
                            the
                            reason many people want an estimate is so that they don't have to provide personal
                            information &mdash; but that's almost always the information reputable insurance
                            companies use
                            to set rates! Completing a quote request will be worth the time and effort when you
                            see
                            what you can potentially save.

                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                            <button class="btn btn-link text-left collapsed" type="button" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                I’m buying my auto insurance through Westwood. How do I pay it?
                            </button>
                        </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            Westwood works with many auto insurance companies and they offer a variety of
                            payment
                            plans and methods for your convenience. We will take your initial payment and
                            ongoing
                            premiums will be paid directly to the insurance company via the payment method you
                            choose.

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-6 col-xs-12">

                <div class="card">
                    <div class="card-header" id="heading4">
                        <h2 class="mb-0">
                            <button aria-controls="collapse4" aria-expanded="false" class="btn btn-link collapsed"
                                data-target="#collapse4" data-toggle="collapse" type="button">
                                What if I get into an accident?
                            </button>
                        </h2>
                    </div>
                    <div aria-labelledby="heading4" class="collapse" data-parent="#accordionExample" id="collapse4">
                        <div class="card-body">
                            Your insurance company has experienced claims professionals to help you in the event
                            of
                            an accident. All auto insurance companies represented by Westwood have 24/7 claim
                            reporting &mdash; either through a toll-free number or online. Your insurance card
                            will
                            have
                            this information.
                            <br><br>
                            While it’s unlikely, if your insurance company is unresponsive after an accident,
                            contact your Westwood agent for help. This extra layer of assistance is another
                            reason
                            why working with Westwood rather than directly with an insurance company, provides
                            peace
                            of mind for you.

                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading5">
                        <h2 class="mb-0">
                            <button aria-controls="collapse5" aria-expanded="false"
                                class="btn btn-link text-left collapsed" data-target="#collapse5" data-toggle="collapse"
                                type="button">
                                What discounts does Westwood offer on auto insurance?
                            </button>
                        </h2>
                    </div>
                    <div aria-labelledby="heading5" class="collapse" data-parent="#accordionExample" id="collapse5">
                        <div class="card-body">
                            There are a variety of discounts to help you lower your rates, such as: multi-car
                            discount, safety device discount, anti-theft discount, mature driver credit, good
                            driver
                            discount, good student credit, air-bag discount, anti-lock brake credit. Talk to
                            your
                            Westwood agent about your options and potential discounts.
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading6">
                        <h2 class="mb-0">
                            <button aria-controls="collapse6" aria-expanded="false"
                                class="btn btn-link  text-left collapsed" data-target="#collapse6"
                                data-toggle="collapse" type="button">
                                My current agent says that I get a discount if I insure my home and auto
                                together.
                                Can Westwood offer that, too?
                            </button>
                        </h2>
                    </div>
                    <div aria-labelledby="heading6" class="collapse" data-parent="#accordionExample" id="collapse6">
                        <div class="card-body">
                            Westwood is an independent agency, so we shop your coverage requirements with many
                            different insurance companies for both homeowners and auto. This allows us to get
                            the
                            best overall rate for you. Often, we find that the best rate and coverage for your
                            auto
                            policy will come from one company while the best rate and coverage for your
                            homeowners
                            policy will come from another company. This provides a “discount” to you &mdash; as
                            well
                            as
                            better tailored insurance protection. In addition, we are frequently able to provide
                            a
                            multiple policy discount to our customers. Please talk to a Westwood agent to
                            discuss
                            your options and discounts that may apply.

                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading7">
                        <h2 class="mb-0">
                            <button aria-controls="collapse7" aria-expanded="false"
                                class="btn btn-link text-left collapsed" data-target="#collapse7" data-toggle="collapse"
                                type="button">
                                Why should I insure my vehicles through Westwood?
                            </button>
                        </h2>
                    </div>
                    <div aria-labelledby="heading7" class="collapse" data-parent="#accordionExample" id="collapse7">
                        <div class="card-body">
                            Westwood is an insurance agency, not an insurance company. We will find the best
                            insurance company for your individual needs. Our auto sales representatives are
                            licensed
                            agents – qualified to answer your questions about auto insurance and provide you
                            with
                            personal service both before and after you become our customer. Many Americans move
                            frequently and your Westwood agent can help you navigate the auto insurance
                            requirements
                            if you’re moving to a new state. Bottom line, we will make it easy and tailor your
                            coverages to ensure that you and your automobile receive the customized protection
                            and
                            service you expect and deserve.

                        </div>
                    </div>
                </div>


            </div>

        </div>

    </div>
</section>

<!-- // F A Q  -->
<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->
<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center [categoryID]="categoryID"></app-knowledge-center>

</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->