<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-make-a-payment" class="sub">
    <div class="container">
        <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Make a payment</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>



<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<!-- <section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a [href]="wpBaseUrl" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section> -->

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->


<!-- // I N T R O   P A R A G R A P H  -->


<!--  P A Y M E N T   L I N K S  -->


<section id="payment-contact-and-website">
    <div class="container pt-5 pb-5">

        <div class="row">
            <div class="col">
                <h2 class="text-center">Find your Insurance Company </h2>
            </div>
        </div>

        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" /><br><br>
                <p class="text-center justify-content-center">Simply find your insurance company below and click to go directly to their payment page.</p>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-9 col-xs-9">

                <div class="row">
                    <input type="text" id="enter-state" onkeyup="myFunction()"
                        placeholder="Enter your insurance company…">
                </div>

                <div class="row">
                    <table id="license-table" class="map-table">
                        <tr class="header">
                            <th class="width-60 text-center"><span>INSURANCE COMPANY</span></th>
                            <th class="width-20 text-center"><span>PAYMENT CONTACT</span></th>
                            <th class="width-20 text-center"><span>WEBSITE</span></th>
                        </tr>

                        <tr *ngFor="let PaymentPageInfo of PaymentPageInfos">
                            <td class="text-left"><span>{{ PaymentPageInfo.bankName }}</span></td>
                            <td>
                                <div class="row text-center">
                                    <div class="col-md-12" *ngIf="PaymentPageInfo.paymentContact"><img
                                            src="assets/img/icons/cell2.png" width="15" style="margin-right: 10px;" /><span><a
                                                href="tel:{{ PaymentPageInfo.paymentContact }}">{{
                                                PaymentPageInfo.paymentContact }}</a> </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <img *ngIf="PaymentPageInfo.paymentUrl"
                                        src="assets/img/icons/payment-page.png" width="15" style="margin-right: 10px;" />
                                    <a *ngIf="PaymentPageInfo.paymentUrl"
                                        class="table-col-link"
                                        (click)="child.importantNotice($event, PaymentPageInfo.paymentUrl)"
                                        href="{{ PaymentPageInfo.paymentUrl }}" target="_blank">Payment Page</a>
                                    
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- //  P A Y M E N T   L I N K S  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section> -->


<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->