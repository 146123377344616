import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { DataService } from '../../service/data.service';
import { Lead } from '../../model/lead';
import { Constants } from '../../Utilities/Constants/constants';
import { ContactMeService } from '../../service/contact-me.service';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.css']
})
export class ContactMeComponent implements OnInit {

  formErrors: any = {};
  isLoading: boolean = false;
  LiveChatImage = '';
  constructor(private router: Router, public commonService: CommonService,
    public dataService: DataService,
    public leadDetails: Lead,
    private route: ActivatedRoute,
    public contactMeService: ContactMeService,
    public constantsObj: Constants) {
      this.LiveChatImage = constantsObj.LiveChatImage;
  }

  ngOnInit() {
    if (this.dataService.leadDetails != null && this.dataService.leadDetails.leadID != '') {
      this.leadDetails = this.dataService.leadDetails;
    }
    //Save details
    this.contactMeService.saveContactMe(this.leadDetails)
        .subscribe((data: Lead) => {
          this.leadDetails = data;
          this.isLoading = false;
        }, err => {
          this.dataService.handleError(err);
        });
  }
 
  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
  }

}
