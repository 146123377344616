<!-- <app-request-an-insurance-quote mainHeading="QUOTE REQUEST" subHeading="false"></app-request-an-insurance-quote> -->
<!-- START OF FORM -->
<form (ngSubmit)="onSubmit(homeForm)" #homeForm="ngForm" autocomplete="off">
    <section class="pb-2">
        <div class="container-xl">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <h2 class="page-header-text">Tell Us About Your Home</h2>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 mb-3">
                    <label class="input-field-label" for="lblHomebuilt">Year Built <span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control home-textbox" id="txtHomeBuilt"
                        [ngModelOptions]="{ updateOn: 'blur' }" required [(ngModel)]="leadDetails.homeInfo.yearBuild"
                        name="yearBuild" #yearBuild="ngModel" maxlength="4" customMin="{{this.minYearBuild}}"
                        customMax="{{this.maxYearBuild}}" OnlyNumber>
                    <label *ngIf="(yearBuild.hasError('required') && yearBuild.touched) "
                        class="error-message text-nowrap">
                        Please provide a valid year built.
                    </label>
                    <label *ngIf="(yearBuild.errors?.customMin && yearBuild.touched && !yearBuild.hasError('required'))"
                        class="error-message text-nowrap">
                        Minimum required value is {{minYearBuild}}.
                    </label>
                    <label *ngIf="(yearBuild.errors?.customMax && yearBuild.touched && !yearBuild.hasError('required'))"
                        class="error-message text-nowrap">
                        Maximum required value is {{maxYearBuild}}.
                    </label>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 mb-3">
                    <label class="input-field-label" for="lblSquareFootage">Square Footage
                        <span class="text-danger">*</span></label>
                    <div class="input-group-append">
                        <input type="text" class="form-control home-textbox" id="txtSquareFootage"
                            [ngModelOptions]="{ updateOn: 'blur' }" required
                            [(ngModel)]="leadDetails.homeInfo.squareFootage" OnlyNumber name="squareFootage"
                            maxlength="4" #squareFootage="ngModel">

                        <i class="fa fa-question-circle help helper-icon helperSquareFootage"></i>

                        <!-- <button class="btn btn-link" type="button"
                                    aria-label="Learn About Square Footage" [ngbPopover]="squareFootagePopover"
                                    [popoverTitle]="squareFootageTitle" container="body"><i
                                    class="fas"><fa-icon style="color: #28445a !important; font-size: 20px;"
                                    icon="question-circle"></fa-icon></i></button>
                                <ng-template #squareFootagePopover>
                                    <div [innerHTML]="toolTipMessage.squareFootage"></div>
                                </ng-template>
                                <ng-template #squareFootageTitle><label style="color: #28445a !important;"><b>Square Footage:</b></label></ng-template> -->
                    </div>
                    <label *ngIf="(squareFootage.hasError('required') && squareFootage.touched) " class="error-message">
                        Please provide a valid Square Footage.</label>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4 mb-3">
                    <label class="input-field-label" for="lblFoundation">Foundation
                        Type <span class="text-danger">*</span></label>
                    <select class="form-control home-width-select" id="selectFoundationType" required
                        [(ngModel)]="leadDetails.homeInfo.foundationType" name="selectFoundationType"
                        #selectFoundationType="ngModel">
                        <option value=""></option>
                        <option *ngFor="let item of leadDetails.homeInfo.lstFoundationType" [ngValue]="item.lookupId">
                            {{item.lookupValue}}
                        </option>
                    </select>
                    <label *ngIf="(selectFoundationType.hasError('required') && selectFoundationType.touched)"
                        class="error-message">Please provide a valid type of foundation.</label>
                </div>
            </div>
        </div>
    </section>


    <section>
        <div class="bg-light pt-4 pb-4"
            [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <div class="container-xl">
                <h3 class="section-header-text">Activity at the Property</h3>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="input-field-label" for="lblHomebuilt">How will the property be used? <span
                                class="text-danger">*</span></label>
                        <select class="form-control home-width-select" id="selectHomeType" required
                            [(ngModel)]="leadDetails.homeInfo.homeType" name="selectHomeType" #selectHomeType="ngModel">
                            <option value=""></option>
                            <option *ngFor="let item of leadDetails.homeInfo.lstHomeType" [ngValue]="item.lookupId">
                                {{item.lookupValue}}
                            </option>
                        </select>
                        <label *ngIf="(selectHomeType.hasError('required') && selectHomeType.touched) "
                            class="error-message">
                            Please provide a valid home type.</label>
                    </div>
                </div>

                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="input-field-label" for="Email">What type of property is the home? <span
                                class="text-danger">*</span></label>
                        <select class="form-control home-width-select" id="selectPropertyType" required
                            [(ngModel)]="leadDetails.homeInfo.propertyType" name="selectPropertyType"
                            #selectPropertyType="ngModel">
                            <option value=""></option>
                            <option *ngFor="let item of leadDetails.homeInfo.lstPropertyType" [ngValue]="item.lookupId">
                                {{item.lookupValue}}
                            </option>
                        </select>
                        <label *ngIf="selectPropertyType.hasError('required') && selectPropertyType.touched"
                            class="error-message">Please provide a valid property type.
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class="mt-4 mb-4">
        <div class="container-xl">
            <h3 class="section-header-text">Property Details</h3>

            <div class="row mb-3">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <label class="input-field-label" for="lblMaterial">What kind
                        of
                        material is the home made of? <span class="text-danger">*</span></label>
                    <select class="form-control home-width-select" id="selectConstructionType" required
                        [(ngModel)]="leadDetails.homeInfo.constructionType" name="selectConstructionType"
                        #selectConstructionType="ngModel">
                        <option value=""></option>
                        <option *ngFor="let item of leadDetails.homeInfo.lstConstructionType" [ngValue]="item.lookupId">
                            {{item.lookupValue}}
                        </option>
                    </select>
                    <label *ngIf="(selectConstructionType.hasError('required') && selectConstructionType.touched) "
                        class="error-message">
                        {{constantObj.requiredErrorMessage}} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-9 col-lg-9">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblNoOfBedrooms">Number of Bedrooms</label>
                                <input type="text" class="form-control home-textbox" id="txtNoOfBedrooms"
                                    [(ngModel)]="leadDetails.homeInfo.noOfBedrooms" name="NoOfBedrooms"
                                    #noOfBedrooms="ngModel" (keypress)="_keyUp($event)"
                                    (blur)="formatBedrooms($event)" />
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblNoOfBathrooms">Number of Bathrooms </label>
                                <input type="text" (keypress)="_keyUp($event)" (blur)="formatBathrooms($event)"
                                    class="form-control home-textbox" id="txtNoOfBathroms"
                                    [ngModelOptions]="{ updateOn: 'blur' }"
                                    [(ngModel)]="leadDetails.homeInfo.noOfBathRooms" name="noOfBathRooms"
                                    #noOfBathRooms="ngModel">
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblNoOfStories">Number
                                    of Stories <span class="text-danger">*</span></label>
                                <input type="text" class="form-control home-textbox" id="txtNoOfStories"
                                    [ngModelOptions]="{ updateOn: 'blur' }" required
                                    [(ngModel)]="leadDetails.homeInfo.noOfStories" name="noOfStories" maxlength="10"
                                    #noOfStories="ngModel" OnlyNumber>
                                <label *ngIf="(noOfStories.hasError('required') && noOfStories.touched) "
                                    class="error-message">{{constantObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblNoOfFire">Number
                                    of Fireplaces</label>
                                <input type="text" class="form-control home-textbox" id="txtNoOffire"
                                    [ngModelOptions]="{ updateOn: 'blur' }"
                                    [(ngModel)]="leadDetails.homeInfo.noOfFirePlaces" name="noOfFirePlaces"
                                    #noOfFirePlaces="ngModel" maxlength="5" OnlyNumber>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblNoOfUnits">Number of Units</label>
                                <input type="number" [min]="1" [max]="9" class="form-control home-textbox"
                                    id="txtUnitNumber" (input)="onNumberInput($event)" OnlyNumber
                                    [(ngModel)]="leadDetails.homeInfo.unitNumber" name="txtUnitNumber"
                                    #txtUnitNumber="ngModel">
                                <div *ngIf="(txtUnitNumber.invalid && txtUnitNumber.touched)">
                                    <span class="required-span">Valid values are 1 through 9 only.</span>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblGaPgeType">Garage Type</label>
                                <select class="form-control home-width-select" id="selectGaragetype"
                                    [(ngModel)]="leadDetails.homeInfo.garageType" name="selectGaragetype"
                                    #selectGaragetype="ngModel">
                                    <option value=""></option>
                                    <option *ngFor="let item of leadDetails.homeInfo.lstGarageType"
                                        [ngValue]="item.lookupId">
                                        {{item.lookupValue}}
                                    </option>
                                </select>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblGarageSize">Garage
                                    Size </label>
                                <select class="form-control home-width-select" id="selectGaragesize"
                                    [(ngModel)]="leadDetails.homeInfo.garageSize"
                                    [disabled]="leadDetails.homeInfo.garageType == constantObj.GarageType_None"
                                    name="selectGaragesize" #selectGaragesize="ngModel">
                                    <option [value]=""></option>
                                    <option
                                        *ngFor="let item of leadDetails.homeInfo.lstGarageSize; trackBy: trackByGarageSizeFn"
                                        [value]="item.lookupId">
                                        {{item.lookupValue}}
                                    </option>
                                </select>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblRoofType">Roof
                                    Type<span class="text-danger"> *</span></label>
                                <select class="form-control home-width-select" id="selectRoofType" required
                                    [(ngModel)]="leadDetails.homeInfo.roofType" name="selectRoofType"
                                    #selectRoofType="ngModel">
                                    <option value=""></option>
                                    <option *ngFor="let item of leadDetails.homeInfo.lstRoofType"
                                        [ngValue]="item.lookupId">
                                        {{item.lookupValue}}
                                    </option>
                                </select>
                                <label *ngIf="(selectRoofType.hasError('required') && selectRoofType.touched) "
                                    class="error-message">{{constantObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblRoofShape">Roof Shape</label>
                                <select class="form-control home-width-select" id="selectRoofShape"
                                    [(ngModel)]="leadDetails.homeInfo.roofShape" name="selectRoofShape"
                                    #selectRoofShape="ngModel">
                                    <option value=""></option>
                                    <option *ngFor="let item of leadDetails.homeInfo.lstRoofShape"
                                        [ngValue]="item.lookupId">
                                        {{item.lookupValue}}
                                    </option>
                                </select>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblSolarPanel">Solar Panel</label>
                                <select class="form-control home-width-select" id="selectSolarPanel"
                                    [(ngModel)]="leadDetails.homeInfo.solarPanel" name="selectSolarPanel"
                                    #selectSolarPanel="ngModel">
                                    <option value=""></option>
                                    <option *ngFor="let item of leadDetails.homeInfo.lstSolarPanel"
                                        [ngValue]="item.lookupId">
                                        {{item.lookupValue}}
                                    </option>
                                </select>
                            </div>
                            <div class="col p-0 pb-3">
                                <label class="input-field-label" for="lblWaterSensor">Water Sensor</label>
                                <select class="form-control home-width-select" id="selectWaterSensor"
                                    [(ngModel)]="leadDetails.homeInfo.waterSensor" name="selectWaterSensor"
                                    #selectWaterSensor="ngModel">
                                    <option value=""></option>
                                    <option *ngFor="let item of leadDetails.homeInfo.lstWaterSensor"
                                        [ngValue]="item.lookupId">
                                        {{item.lookupValue}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="bg-white d-sm-block mb-5">
        <div class="container-xl">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn ww-button" aria-label="Go Back to Previous Screen" awPreviousStep
                        tabindex="-1">
                        <i>
                            <fa-icon icon="chevron-left"></fa-icon> <fa-icon icon="chevron-left"></fa-icon>
                        </i>
                        Previous
                    </button>
                </div>
                <div class="col text-right">
                    <button type="submit" class="btn ww-button" aria-label="Go to Next Step" [disabled]="isLoading">
                        Next <i>
                            <fa-icon icon="chevron-right"></fa-icon><fa-icon icon="chevron-right"></fa-icon>
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <app-model-popup (savePageDetails)="savePageDetails()"></app-model-popup>
</form>
<!-- //END OF FORM -->