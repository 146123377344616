import { Component, OnInit } from "@angular/core";
import { FormGroup, NgForm } from '@angular/forms';


@Component({
  selector: "ww-about-yourself",
  templateUrl: "./about-yourself.component.html",
  styleUrls: ["./about-yourself.component.css"],
})
export class AboutYourselfComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  textOptInChange(event:any) {}

  onClickBack(){

  }

  onSubmit(form: any) {
    
  }
}

