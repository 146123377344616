import { Component, OnInit, OnDestroy } from '@angular/core';
import { Lead } from '../../model/lead';
import { Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { Constants } from '../../Utilities/Constants/constants';
import { DataService } from '../../service/data.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-print-format',
  templateUrl: './print-format.component.html',
  styleUrls: ['./print-format.component.css'],
  providers: [DecimalPipe]
})
export class PrintFormatComponent implements OnInit {

  leadDetails: Lead;
  PrintableVersionImage: string;
  constructor(private router: Router, 
    public commonService: CommonService, 
    public constantObj: Constants,
    public dataService: DataService,
    public decimalPipe: DecimalPipe,) { }

  ngOnInit() {
    if(window.localStorage.getItem('leadData')!=null && window.localStorage.getItem('leadData')!=undefined)
      this.leadDetails = JSON.parse(window.localStorage.getItem('leadData'));
    this.PrintableVersionImage = this.constantObj.PrintableVersionImage;
  }

  ngOnDestroy()
  {
    this.dataService.leadDetails = this.leadDetails;
  }
  formatDeductibles(obj) {
    if (obj < 50)
      return obj + '%'
    else
      return '&#36;' + obj+'.00';
  }
  getHomeType()
  {
    //return "Primary Residance";
    return this.leadDetails.homeInfo.lstHomeType.find(o=>o.lookupId==this.leadDetails.homeInfo.homeType).lookupValue;
  }
  getCoverage(value)
  {
    return '&#36;'+ value+'.00';
  } 
  closeWindow()
  {
    window.localStorage.clear();
    window.close();
  } 
}
