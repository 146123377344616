<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-event" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Event Insurance</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  E V E N T  -->

<section class="">
  <div class="container pt-3 pb-5" id="a-bullet-list">
    <div class="row pt-2">
      <div class="col">
        <h2 class="col text-center">Why Event Insurance?</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-5">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
    <div class="row text-right">
      <div class="col-md-6 pb-4">
        <img src="assets/img/page-body-img/shutterstock_147933833.png" style="width: 100%;"
          alt="Couple on wedding day" />
      </div>
      <div class="col-md-6 text-left">
        <p>
          Special events should be happy times, full of celebration! But so many things can go wrong and leave you
          financially responsible. Event insurance can protect you from this risk. It’s often required by special event
          venues and it is inexpensive insurance (Starting at $75) to help you focus on the fun of your event—not the
          risks.
        </p>

      </div>
    </div>


  </div>
</section>

<!-- //  W H Y  E V E N T  -->



<section id="whats-included">
  <div class="container pt-5 pb-5">
    <div class="row">
      <div class="col">
        <h2 class="col text-center">WHAT'S INCLUDED IN EVENT INSURANCE?</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-3">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
        <br><br>
        <p>Westwood partners with Markel Specialty to provide event insurance. Here’s what is included:</p>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-12">
        <ul class="pl-0 text-center">
          <li>Property damage</li>
          <li>Optional cancellation coverage</li>
          <li>Optional host liquor liability</li>
          <li>Vendor bankruptcy</li>
          <li>Extreme weather</li>
          <li>Military deployment</li>
          <li>Accident/illness of the event host or immediate family member</li>
          <li>Bodily Injury</li>
        </ul>
      </div>
    </div>

    <!-- O N L I N E   Q U O T E -->


    <!-- // O N L I N E   Q U O T E -->


  </div>
</section>

<!-- A B O U T  M A R K E L   -->

<section class="about-markel">
  <div class="containerpb-5" id="a-bullet-list">
    <div class="row pt-5">
      <div class="col">
        <h2 class="col text-center">About Markel</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-5">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
    <div class="row justify-content-center pl-3">
      <div class="col-md-6 col-xs-5">
        <p>Markel North American Group is a group of subsidiaries of Markel Corporation, a Fortune 500 company. Coverage
          is provided by one or more of the insurance companies in the Markel North American Group, and policyholder
          services are provided by the underwriting manager, Markel Service Incorporated, a nationally licensed
          insurance producer (NIPR number 27585). <br><br>

          Markel Service Incorporated’s division, Markel Specialty, offers both commercial and personal lines products.
          Insurance carrier, coverage, dividends and services availability may vary by state. Markel Service
          Incorporated has provided referral compensation to Westwood Insurance Agency, LLC for product placement.</p>
        <br><br>

      </div>
    </div>
  </div>
</section>

<!-- //  A B O U T  M A R K E L  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-2">
  <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->