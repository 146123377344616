<!-- <app-request-an-insurance-quote mainHeading="QUOTE REQUEST" subHeading="false"></app-request-an-insurance-quote> -->
<!-- START OF FORM -->
<form (ngSubmit)="onSubmit(underWriting)" #underWriting="ngForm">
    <section class="mb-3">
        <div class="container-xl" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <img src="assets/img/PRELOADER.gif" class="preloader"
                *ngIf="isLoading || (this.dataService.isDataSaveInProgress$ | async)" />
            <div class="row mb-3">
                <div class="col-12 mb-3">
                    <h2 class="page-header-text" *ngIf="this.dataService.leadDetails.isServiceUnavailable==false">At The
                        Property</h2>
                </div>
                <div class="col-12">
                    <h3 class="section-header-text">Property Updates</h3>
                    <label>Have you updated your heating system, plumbing, roof or wiring? <span
                        class="text-danger">*</span></label>
                    <p class="light-gray">Leave blank unless your home is more than 15 years old</p>
                </div>
                <div class="col-12 margin-bottom-25">
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                        [ngClass]="supplementalInfo.haveRenovation ? 'ww-radio-button-selected' : 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radRenovationYes"
                                [(ngModel)]="supplementalInfo.haveRenovation" [value]="true"
                                (click)="supplementalInfo.haveRenovation ? (UnCheck(template,'renovations')):supplementalInfo.haveRenovation=true"
                                #haveRenovation="ngModel" name="radRenovation" ngDefaultControl
                                class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.haveRenovation ? 'ww-radio-button-selected' : 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.haveRenovation === false ? 'ww-radio-button-selected' : 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radRenovationNo"
                                [(ngModel)]="supplementalInfo.haveRenovation" [value]="false"
                                (click)="supplementalInfo.haveRenovation ? (UnCheck(template,'renovations')):supplementalInfo.haveRenovation=false"
                                #haveRenovation="ngModel" name="radRenovation" ngDefaultControl
                                class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.haveRenovation === false ? 'ww-radio-button-selected' : 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.haveRenovation === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>

                    <div *ngIf="supplementalInfo.haveRenovation">
                        <div class="d-flex align-items-center mt-md-0 mb-2">
                            <h3 class="mb-0">Renovations</h3>
                            <p class="text-muted text-right flex-grow-1 mb-0">
                                <button type="button" class="btn ww-button"
                                    (click)="openRenovationModal(myModalTemplate, null, 'Add', 0)">
                                    Add Renovation
                                </button>
                            </p>
                        </div>
                        <div class="card-body p-0 desktop-table d-none d-sm-block">
                            <table class="table">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Renovation Type</th>
                                        <th scope="col">Extent</th>
                                        <th scope="col">Year Completed</th>
                                        <th scope="col">Details</th>
                                        <th scope="col" style="width: 12%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="supplementalInfo.renovations.length <= 0" name='NoRenovationFound'
                                        ngDefaultControl
                                        [required]="supplementalInfo.haveRenovation && supplementalInfo.renovations.length <= 0"
                                        [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                                        [ngClass]="{ 'error': (underWriting)}">
                                        <td colspan="5">
                                            <p class="mb-0"
                                                *ngIf="underWriting.submitted && supplementalInfo.haveRenovation && supplementalInfo.renovations.length <= 0">
                                                <label class="error-message">No renovation found. Please add a renovation.</label>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let renovation of supplementalInfo.renovations; let i = index">
                                        <td>{{getRenovationTypeValue(renovation.type)}}</td>
                                        <td>{{getRenovationExtentTypeValue(renovation.extentType)}}</td>
                                        <td>{{renovation.yearCompleted}}</td>
                                        <td>{{renovation.details}}</td>
                                        <td>
                                            <a class="cursor-pointer"
                                                (click)="openRenovationModal(myModalTemplate, renovation,'Edit', i)">Edit</a>
                                            <span class="sep">|</span>
                                            <a class="text-danger cursor-pointer"
                                                (click)="deleteRenovation(i)">Delete</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card card-body mobile-table d-sm-none"
                            *ngIf="underWriting.submitted && supplementalInfo.haveRenovation && supplementalInfo.renovations.length <= 0"
                            name='NoRenovationFound' ngDefaultControl
                            [required]="supplementalInfo.haveRenovation && supplementalInfo.renovations.length <= 0"
                            [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                            [ngClass]="{ 'error': (underWriting.submitted)}">
                            <p class="mb-0"
                                *ngIf="underWriting.submitted && supplementalInfo.haveRenovation && supplementalInfo.renovations.length <= 0">
                                No renovation found. Please add a renovation.
                            </p>
                        </div>
                        <div class="card mobile-table d-sm-none"
                            *ngIf="supplementalInfo.haveRenovation && supplementalInfo.renovations.length > 0">
                            <div class="card-body border-bottom"
                                *ngFor="let renovation of supplementalInfo.renovations; let i = index">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="pl-0" scope="row">Type</th>
                                            <td>{{getRenovationTypeValue(renovation.type)}}</td>
                                        </tr>
                                        <tr>
                                            <th class="pl-0" scope="row">Extent</th>
                                            <td>{{getRenovationExtentTypeValue(renovation.extentType)}}</td>
                                        </tr>
                                        <tr>
                                            <th class="pl-0" scope="row">Year Completed</th>
                                            <td>{{renovation.yearCompleted}}</td>
                                        </tr>
                                        <tr>
                                            <th class="pl-0" scope="row">Details</th>
                                            <td>{{renovation.details}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> -->
                                <a class="cursor-pointer"
                                    (click)="openRenovationModal(myModalTemplate, renovation,'Edit', i)">Edit</a> <span
                                    class="sep">|</span>
                                <a class="cursor-pointer text-danger" (click)="deleteRenovation(i)">Delete</a>
                            </div>
                        </div>

                        <!-- RenovationModal -->
                        <ng-template #myModalTemplate>
                            <div class="custom-modal-container">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <form class="renovationForm" (ngSubmit)="onSubmitRenovation(renovationForm)"
                                                #renovationForm="ngForm">
                                                <div class="ww-modal-header">
                                                    <div class="row">
                                                        <div class="col-11">
                                                            <p>{{editMode == true ? 'Edit a Renovation': 'Add Renovation'}}</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <button type="button" class="close" aria-label="Close"
                                                                (click)="closeRenovationModal()">
                                                                <span aria-hidden="true"
                                                                    style="font-size: 30px;">&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                        <label>Type of Renovation <span
                                                                class="required-span">*</span></label>
                                                        <select class="form-control" [(ngModel)]="renovation.type"
                                                            required #selectRenovationType="ngModel"
                                                            name="selectRenovationType">
                                                            <option selected value=""></option>
                                                            <option
                                                                *ngFor="let renovationType of supplementalInfo.lstRenovationTypes"
                                                                [value]="renovationType.lookupId">
                                                                {{renovationType.lookupValue}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="selectRenovationType.touched && selectRenovationType?.status=='INVALID'">
                                                            <label class="error-message">Please select a valid Type of Renovation.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                        <label>Extent of Renovation<span
                                                                class="required-span">*</span></label>
                                                        <select class="form-control" [(ngModel)]="renovation.extentType"
                                                            name="selectRenovationExtentTypes" required
                                                            #selectRenovationExtentTypes="ngModel">
                                                            <option selected value=""></option>
                                                            <option
                                                                *ngFor="let renovationExtentTypes of supplementalInfo.lstRenovationExtentTypes"
                                                                [value]="renovationExtentTypes.lookupId">
                                                                {{renovationExtentTypes.lookupValue}}
                                                            </option>
                                                        </select>
                                                        <div *ngIf="selectRenovationExtentTypes.touched && selectRenovationExtentTypes?.status=='INVALID'">
                                                            <label class="error-message">Please select a valid Renovation Extent Type.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6 col-lg-8 pb-3 small-d-card-ptb">
                                                        <label>Details (max 50 characters)</label>
                                                        <input class="form-control" type="text" name="renovationDetail"
                                                            maxlength="50" [(ngModel)]="renovation.details" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-6 col-lg-4 pb-3 small-d-card-ptb">
                                                        <label>Year completed <span
                                                                class="required-span">*</span></label>
                                                        <input class="form-control" type="text"
                                                            name="renovationYearCompleted" required maxlength="4"
                                                            OnlyNumber [max]="maxRenovationYear"
                                                            (blur)="checkRenovationYear($event)"
                                                            #renovationYearCompleted="ngModel"
                                                            [(ngModel)]="renovation.yearCompleted" />
                                                        <div *ngIf="renovationYearCompleted.touched && (renovationYearCompleted?.status=='INVALID' || renovation.yearCompleted === '' || convertToNum(renovation.yearCompleted) > maxRenovationYear)">
                                                            <label class="error-message">Please enter valid Renovation Year.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex justify-content-between">
                                                    <button type="button" class="btn btn-secondary ml-3"
                                                        (click)="closeRenovationModal()">Cancel</button>
                                                    <button type="submit" class="btn ww-button mr-3"
                                                        [disabled]="renovationForm.invalid || renovation.yearCompleted === '' || convertToNum(renovation.yearCompleted) > maxRenovationYear"
                                                        (click)="AddRenovation()">{{editMode == true ? 'Edit Renovation':
                                                        'Add Renovation'}}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container-xl" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row mb-3">
                <div class="col-12">
                    <h3 class="section-header-text">Activity at the Property</h3>
                    <label>Will the home be vacant for more than 30 days? <span class="text-danger">*</span></label>
                </div>
                <div class="col-12 mb-3">
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.isVacant ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radVacantYes" [(ngModel)]="supplementalInfo.isVacant"
                                [value]="true" (click)="supplementalInfo.isVacant=true;" #isVacant="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isVacant ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.isVacant === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radVacantNo" [(ngModel)]="supplementalInfo.isVacant"
                                [value]="false" (click)="supplementalInfo.isVacant=false;" #isVacant="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isVacant === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.isVacant === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                </div>
                <div class="col-12">
                    <label>Will there be any business conducted on premises? <span class="text-danger">*</span></label>
                </div>
                <div class="col-12">
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.isBusinessOnPremise ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radBusinessOnPremYes"
                                [(ngModel)]="supplementalInfo.isBusinessOnPremise" [value]="true"
                                (click)="supplementalInfo.isBusinessOnPremise=true;" #isBusinessOnPremise="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isBusinessOnPremise ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.isBusinessOnPremise === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radBusinessOnPremNo"
                                [(ngModel)]="supplementalInfo.isBusinessOnPremise" [value]="false"
                                (click)="supplementalInfo.isBusinessOnPremise=false;" #isBusinessOnPremise="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isBusinessOnPremise === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.isBusinessOnPremise === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container-xl" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row mb-3">
                <div class="col-12">
                    <h3 class="section-header-text">Recreation at the Property</h3>
                </div>
                <div class="col-12 col-md-4 col-lg-3 mb-3">
                    <label>Swimming Pool Type <span class="text-danger">*</span></label>
                    <select class="form-control" required [(ngModel)]="supplementalInfo.poolType" #poolType="ngModel"
                        name="ddlPolTypes" (change)="onSwimmingPoolTypeChange()">
                        <option selected value=""></option>
                        <option *ngFor="let item of supplementalInfo.lstPoolTypes" [value]="item.lookupId">
                            {{item.lookupValue}}
                        </option>
                    </select>
                    <label *ngIf="(poolType.touched || underWriting.submitted) && poolType.hasError('required') "
                        class="error-message text-nowrap">Please provide a valid type of swimming pool.</label>

                </div>
                <div class="col-12 mb-2"
                    *ngIf="supplementalInfo.poolType !=null && supplementalInfo.poolType !=='a81' && supplementalInfo.poolType !==''">
                    <div class="div-left-border-5 mb-2">
                        <label>Do you have a diving board? <span class="text-danger">*</span></label>
                        <div class="flex">
                            <label class="btn ww-radio-button mr-2"
                                [ngClass]="supplementalInfo.haveSlideDivingBoard ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                                <input type="radio" name="options" id="haveSlideDivingBoardYes"
                                    [(ngModel)]="supplementalInfo.haveSlideDivingBoard" [value]="true"
                                    (click)="supplementalInfo.haveSlideDivingBoard=true;"
                                    #haveSlideDivingBoard="ngModel" name="radRenovation" ngDefaultControl
                                    class="btn ww-radio-button"
                                    [ngClass]="supplementalInfo.haveSlideDivingBoard === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                            </label>
                            <label class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.haveSlideDivingBoard === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                                <input type="radio" name="options" id="haveSlideDivingBoardNo"
                                    [(ngModel)]="supplementalInfo.haveSlideDivingBoard" [value]="false"
                                    (click)="supplementalInfo.haveSlideDivingBoard=false;"
                                    #haveSlideDivingBoard="ngModel" name="radRenovation" ngDefaultControl
                                    class="btn ww-radio-button"
                                    [ngClass]="supplementalInfo.haveSlideDivingBoard === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                            </label>
                        </div>
                        <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.haveSlideDivingBoard === null">
                            <label class="error-message">Please provide a valid value.</label>
                        </div>
                    </div>
                    <div class="div-left-border-5 mb-2" *ngIf="supplementalInfo.poolType !=null && supplementalInfo.poolType !=='a81' && supplementalInfo.poolType !==''">
                        <label>Is the pool fenced or caged? <span class="text-danger">*</span></label>
                        <div class="flex">
                            <label class="btn ww-radio-button mr-2"
                                [ngClass]="supplementalInfo.isPoolFence ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                                <input type="radio" name="options" id="radIsPoolFencedYes"
                                    [(ngModel)]="supplementalInfo.isPoolFence" [value]="true"
                                    (click)="supplementalInfo.isPoolFence=true;" #isPoolFence="ngModel"
                                    name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                    [ngClass]="supplementalInfo.isPoolFence ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                            </label>
                            <label class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isPoolFence === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                                <input type="radio" name="options" id="radIsPoolFencedNo"
                                    [(ngModel)]="supplementalInfo.isPoolFence" [value]="false"
                                    (click)="supplementalInfo.isPoolFence=false;" #isPoolFence="ngModel"
                                    name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                    [ngClass]="supplementalInfo.isPoolFence === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                            </label>
                        </div>
                        <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.isPoolFence === null">
                            <label class="error-message">Please provide a valid value.</label>
                        </div>
                    </div>

                    <div class="div-left-border-5" *ngIf="supplementalInfo.isPoolFence">
                        <label>Is the fence/cage locked or has no entry points? <span class="text-danger">*</span></label>
                        <div class="flex">
                            <label class="btn ww-radio-button mr-2"
                                [ngClass]="supplementalInfo.locked ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                                <input type="radio" name="options" id="radIsLockedYes"
                                    [(ngModel)]="supplementalInfo.locked" [value]="true"
                                    (click)="supplementalInfo.locked=true;" #locked="ngModel" name="radRenovation"
                                    ngDefaultControl class="btn ww-radio-button"
                                    [ngClass]="supplementalInfo.locked ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                            </label>
                            <label class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.locked === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                                <input type="radio" name="options" id="radIsLockedNo"
                                    [(ngModel)]="supplementalInfo.locked" [value]="false"
                                    (click)="supplementalInfo.locked=false;" #locked="ngModel" name="radRenovation"
                                    ngDefaultControl class="btn ww-radio-button"
                                    [ngClass]="supplementalInfo.locked === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                            </label>
                        </div>
                        <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.locked === null">
                            <label class="error-message">Please provide a valid value.</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 mb-3">
                    <label>Is there a trampoline on your property? <span class="text-danger">*</span></label>
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.haveTrampoline ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radHaveTrampolineYes"
                                [(ngModel)]="supplementalInfo.haveTrampoline" [value]="true"
                                (click)="supplementalInfo.haveTrampoline=true;" #haveTrampoline="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.haveTrampoline ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.haveTrampoline === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radHaveTrampolineNo"
                                [(ngModel)]="supplementalInfo.haveTrampoline" [value]="false"
                                (click)="supplementalInfo.haveTrampoline=false;" #haveTrampoline="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.haveTrampoline === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.haveTrampoline === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                </div>
                <div class="div-left-border-5 mb-2" *ngIf="supplementalInfo.haveTrampoline">
                    <label>Is the trampoline fenced? <span class="text-danger">*</span></label>
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                        [ngClass]="supplementalInfo.isTrampolineFenced ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radIsTrampolineFencedYes"
                                [(ngModel)]="supplementalInfo.isTrampolineFenced" [value]="true"
                                (click)="supplementalInfo.isTrampolineFenced=true;" #isTrampolineFenced="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isTrampolineFenced ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.isTrampolineFenced === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radIsTrampolineFencedNo"
                                [(ngModel)]="supplementalInfo.isTrampolineFenced" [value]="false"
                                (click)="supplementalInfo.isTrampolineFenced=false;" #isTrampolineFenced="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.isTrampolineFenced === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.isTrampolineFenced === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                </div>

                <div class="col-12">
                    <label>Do you have any ATVs, 4-wheelers or dirt bikes that are used on or off premises? <span class="text-danger">*</span></label>
                    <!-- rvs to be added at the backend -->
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.rVs ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radRvsYes" [(ngModel)]="supplementalInfo.rVs"
                                [value]="true" (click)="supplementalInfo.rVs=true;" #rvs="ngModel" name="radRenovation"
                                ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.rVs ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.rVs === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="options" id="radRvsNo" [(ngModel)]="supplementalInfo.rVs"
                                [value]="false" (click)="supplementalInfo.rVs=false;" #rvs="ngModel"
                                name="radRenovation" ngDefaultControl class="btn ww-radio-button"
                                [ngClass]="supplementalInfo.rVs === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.rVs === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container-xl" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <div class="row">
                <div class="col-12">
                    <h3>Pets & Animals</h3>
                </div>
                <div class="col-12 margin-bottom-25"
                    *ngIf="!(leadDetails.customerAddress.state === 'NV' || leadDetails.customerAddress.state === 'NY')">
                    <label>Do you or any household member have a dog? <span class="text-danger">*</span></label>
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.haveDogs ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="radhaveDogs" id="radhaveDogs"
                                [(ngModel)]="supplementalInfo.haveDogs" [value]="true"
                                (click)="supplementalInfo.haveDogs ? (UnCheck(template,'dogs')) : supplementalInfo.haveDogs = true"
                                #haveDogs="ngModel" ngDefaultControl
                                [ngClass]="supplementalInfo.haveDogs ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.haveDogs === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="radhaveDogs" id="radhaveDogs"
                                [(ngModel)]="supplementalInfo.haveDogs" [value]="false"
                                (click)="supplementalInfo.haveDogs ? (UnCheck(template,'dogs')) : supplementalInfo.haveDogs = false"
                                [ngClass]="supplementalInfo.haveDogs === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.haveDogs === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>

                    <!-- Dog Section Start -->
                    <div *ngIf="supplementalInfo.haveDogs">
                        <div class="d-flex align-items-center mt-md-0 mb-2">
                            <h3 class="mb-0">Dogs</h3>
                            <p class="text-muted text-right flex-grow-1 mb-0">
                                <button type="button" class="btn ww-button"
                                    (click)="openDogsModal(dogModalTemplate, null, 'Add', 0)">
                                    Add Dog
                                </button>
                            </p>
                        </div>
                        <div class="card-body p-0 desktop-table d-none d-sm-block">
                            <table class="table">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Breed</th>
                                        <th scope="col">Mixed With</th>
                                        <th scope="col">Have Bite History</th>
                                        <th scope="col">Explanation</th>
                                        <th scope="col" style="width: 12%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="supplementalInfo.dogs.length <= 0" name='NoRenovationFound'
                                        ngDefaultControl
                                        [required]="supplementalInfo.haveDogs && supplementalInfo.dogs.length <= 0"
                                        [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                                        [ngClass]="{ 'error': (underWriting)}">
                                        <td colspan="5">
                                            <p class="mb-0"
                                                *ngIf="underWriting.submitted && supplementalInfo.haveDogs && supplementalInfo.dogs.length <= 0">
                                                <label class="error-message">No dogs found. Please add a dog.</label>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let dog of supplementalInfo.dogs; let i = index">
                                        <td>{{getDogBreedTypeValue(dog.breedID)}}</td>
                                        <td>{{getDogBreedTypeValue(dog.mixedWithBreedID)}}</td>
                                        <td>{{dog.haveBiteHistory ? 'Yes' : 'No'}}</td>
                                        <td class="break-all">{{dog.explanation}}</td>
                                        <td>
                                            <a class="cursor-pointer"
                                                (click)="openDogsModal(dogModalTemplate, dog,'Edit', i)">Edit</a>
                                            <span class="sep">|</span>
                                            <a class="text-danger cursor-pointer" (click)="deleteDog(i)">Delete</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card card-body mobile-table d-sm-none "
                            *ngIf="underWriting.submitted && supplementalInfo.haveDogs && supplementalInfo.dogs.length <= 0"
                            name='NoRenovationFound' ngDefaultControl
                            [required]="supplementalInfo.haveDogs && supplementalInfo.dogs.length <= 0"
                            [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                            [ngClass]="{ 'error': (underWriting.submitted)}">
                            <label class="error-message">No dogs found. Please add a dog.</label>
                        </div>
                        <div class="card mobile-table d-sm-none"
                            *ngIf="supplementalInfo.haveDogs && supplementalInfo.dogs.length > 0">
                            <div class="card-body border-bottom"
                                *ngFor="let dog of supplementalInfo.dogs; let i = index;">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="pl-0" scope="row">Breed</th>
                                            <td>{{getDogBreedTypeValue(dog.breedID)}}</td>
                                        </tr>
                                        <tr>
                                            <th class="pl-0" scope="row">Mixed With</th>
                                            <td>{{getDogBreedTypeValue(dog.mixedWithBreedID)}}</td>
                                        </tr>
                                        <tr>
                                            <th class="pl-0" scope="row">Have Bite History</th>
                                            <td>{{dog.haveBiteHistory ? 'Yes' : 'No'}}</td>
                                        </tr>
                                        <tr>
                                            <th class="pl-0" scope="row">Explanation</th>
                                            <td class="break-all">{{dog.explanation}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a class="cursor-pointer"
                                    (click)="openDogsModal(dogModalTemplate, dog,'Edit', i)">Edit</a> <span
                                    class="sep">|</span>
                                <a class="cursor-pointer text-danger" (click)="deleteDog(i)">Delete</a>
                            </div>
                        </div>
                        <!-- Dogs Modal -->
                        <ng-template #dogModalTemplate>
                            <div class="custom-modal-container">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <form class="addDogForm" (ngSubmit)="onSubmitAddDog(addDogForm)"
                                                #addDogForm="ngForm">
                                                <div class="ww-modal-header">
                                                    <div class="row">
                                                        <div class="col-11">
                                                            <p>{{editMode == true ? 'Edit Dog': 'Add Dog'}}</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <button type="button" class="close" aria-label="Close"
                                                                (click)="cancelDogUpdate()">
                                                                <span aria-hidden="true"
                                                                    style="font-size: 30px;">&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                        <label>Breed <span class="required-span">*</span></label>
                                                        <select class="form-control" [(ngModel)]="dog.breedID"
                                                            #selectDogBreedType="ngModel" name="selectDogBreedType"
                                                            required>
                                                            <option selected value=""></option>
                                                            <option
                                                                *ngFor="let breedType of supplementalInfo.lstBreedTypes"
                                                                [value]="breedType.lookupId">
                                                                {{breedType.lookupValue}}
                                                            </option>
                                                        </select>
                                                        <div
                                                            *ngIf="selectDogBreedType.touched && selectDogBreedType.status=='INVALID'">
                                                            <label class="error-message">Please select a valid Breed.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                        <label>Mixed With</label>
                                                        <select class="form-control" [(ngModel)]="dog.mixedWithBreedID"
                                                            name="selectMixedWithBreedID">
                                                            <option selected value=""></option>
                                                            <option
                                                                *ngFor="let breedType of supplementalInfo.lstBreedTypes"
                                                                [value]="breedType.lookupId">
                                                                {{breedType.lookupValue}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6 col-lg-4 pb-3 small-d-card-ptb">
                                                        <label>
                                                            <input type="checkbox" name="haveBiteHistory"
                                                                style="margin-top: 22%;"
                                                                [(ngModel)]="dog.haveBiteHistory" /> Have bite history
                                                        </label>
                                                    </div>
                                                    <div class="col-sm-12 col-md-6 col-lg-8 pb-3 small-d-card-ptb">
                                                        <label>Explanation</label>
                                                        <input class="form-control" type="text" name="biteExplanation"
                                                            [(ngModel)]="dog.explanation" [maxLength]="200" />
                                                    </div>
                                                </div>
                                                <div class="row d-flex justify-content-between">
                                                    <button type="button" class="btn btn-secondary ml-3"
                                                        (click)="cancelDogUpdate()">Cancel</button>
                                                    <button type="submit" class="btn ww-button mr-3"
                                                        [disabled]="addDogForm.invalid" (click)="AddDog()">{{editMode ==
                                                        true ? 'Edit Dog': 'Add Dog'}}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <!-- Dogs Modal End -->
                </div>
            </div>

            <!-- Other Pets Section Start -->
            <div class="row">
                <div class="col-12 margin-bottom-25">
                    <label>Do you or any household member own an animal that could be considered exotic or livestock, or
                        that has previously bitten or injured a person or another animal? <span class="text-danger">*</span></label>
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.haveOtherPets ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" id="haveOtherPetsYes" [value]="true"
                                [(ngModel)]="supplementalInfo.haveOtherPets"
                                (click)="supplementalInfo.haveOtherPets?(UnCheck(template,'otherPets')):supplementalInfo.haveOtherPets=true;"
                                #haveOtherPets="ngModel" name="radOtherPets" ngDefaultControl
                                [ngClass]="supplementalInfo.haveOtherPets ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.haveOtherPets === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" id="haveOtherPetsNo" [value]="false"
                                [(ngModel)]="supplementalInfo.haveOtherPets"
                                (click)="supplementalInfo.haveOtherPets?(UnCheck(template,'otherPets')):supplementalInfo.haveOtherPets=false;"
                                #haveOtherPets="ngModel" name="radOtherPets"
                                [ngClass]="supplementalInfo.haveOtherPets === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'"> No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.haveOtherPets === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                    <div *ngIf="supplementalInfo.haveOtherPets">
                        <div class="d-flex align-items-center mt-md-0 mb-2">
                            <h3 class="mb-0">Other Animals</h3>
                            <p class="text-muted text-right flex-grow-1 mb-0">
                                <button type="button" class="btn ww-button"
                                    (click)="openOtherPetsModal(otherPetsModalTemplate, null, 'Add', 0)">
                                    Add Animal
                                </button>
                            </p>
                        </div>
                        <div class="card-body p-0 desktop-table d-none d-sm-block">
                            <table class="table">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col" style="width: 12%;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="supplementalInfo.otherPets.length <= 0" name='NoRenovationFound'
                                        ngDefaultControl
                                        [required]="supplementalInfo.haveOtherPets && supplementalInfo.otherPets.length <= 0"
                                        [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                                        [ngClass]="{ 'error': (underWriting)}">
                                        <td colspan="5">
                                            <p class="mb-0"
                                                *ngIf="underWriting.submitted && supplementalInfo.haveOtherPets && supplementalInfo.otherPets.length <= 0">
                                                <label class="error-message">No other animals found. Please add an animal.</label>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let otherPet of supplementalInfo.otherPets; let i = index">
                                        <td class="break-all" style="width: 80%;">{{otherPet.description}}</td>
                                        <td>
                                            <a class="cursor-pointer"
                                                (click)="openOtherPetsModal(otherPetsModalTemplate, otherPet,'Edit', i)">Edit</a>
                                            <span class="sep">|</span>
                                            <a class="text-danger cursor-pointer" (click)="deleteOtherPet(i)">Delete</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div class="card card-body mobile-table d-sm-none"
                            *ngIf="underWriting.submitted && supplementalInfo.otherPets && supplementalInfo.otherPets.length <= 0"
                            name='NoRenovationFound' ngDefaultControl
                            [required]="supplementalInfo.haveOtherPets && supplementalInfo.otherPets.length <= 0"
                            [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                            [ngClass]="{ 'error': (underWriting.submitted)}">
                            <label class="error-message">No other animals found. Please add an animal.</label>
                        </div>
                        <div class="card mobile-table d-sm-none"
                            *ngIf="supplementalInfo.otherPets && supplementalInfo.otherPets.length > 0">
                            <div class="card-body border-bottom"
                                *ngFor="let otherPet of supplementalInfo.otherPets; let i = index;">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <th class="pl-0" scope="row">Description</th>
                                            <td class="break-all">{{otherPet.description}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a class="cursor-pointer"
                                    (click)="openOtherPetsModal(otherPetsModalTemplate, otherPet,'Edit', i)">Edit</a>
                                <span class="sep">|</span>
                                <a class="cursor-pointer text-danger" (click)="deleteOtherPet(i)">Delete</a>
                            </div>
                        </div>

                        <!-- Other Pets Modal -->
                        <ng-template #otherPetsModalTemplate>
                            <div class="custom-modal-container">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <form class="otherAnimalForm"
                                                (ngSubmit)="onSubmitAnimalForm(otherAnimalForm)"
                                                #otherAnimalForm="ngForm">
                                                <div class="ww-modal-header">
                                                    <div class="row">
                                                        <div class="col-11">
                                                            <p>{{editMode == true ? 'Edit Other Animals': 'Add Other Animals'}}</p>
                                                        </div>
                                                        <div class="col-1">
                                                            <button type="button" class="close" aria-label="Close"
                                                                (click)="cancelOtherPetUpdate()">
                                                                <span aria-hidden="true"
                                                                    style="font-size: 30px;">&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 pb-3 small-d-card-ptb">
                                                        <label>Description <span class="required-span">*</span></label>
                                                        <br />
                                                        <textarea class="form-control" rows="3"
                                                            name="otherPetDescription" id="otherPetDescription"
                                                            #otherPetDescription="ngModel" required
                                                            [(ngModel)]="otherPet.description"
                                                            [maxLength]="250"></textarea>
                                                        <div
                                                            *ngIf="otherPetDescription.touched && otherPetDescription?.status=='INVALID'">
                                                            <label class="error-message">Other Animal description is required.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row d-flex justify-content-between">
                                                    <button type="button" class="btn btn-secondary ml-3"
                                                        (click)="cancelOtherPetUpdate()">Cancel</button>
                                                    <button type="button" class="btn ww-button mr-3"
                                                        (click)="AddOtherPet()"
                                                        [disabled]="otherAnimalForm.invalid">{{editMode == true ? 'Edit Other Animals':
                                                        'Add Other Animals'}}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <!-- Other Pets Section Ends -->

            </div>

            <!-- Prior Insurance Information Section Start -->
            <div class="row mt-3 mb-2">
                <div class="col-12">
                    <h3 class="section-header-text">Prior Insurance Information</h3>
                </div>
                <div class="col-12 margin-bottom-25">
                    <label>Have you filed any claims in the past 5 years? <span class="text-danger">*</span></label>
                    <div class="flex">
                        <label class="btn ww-radio-button mr-2"
                            [ngClass]="supplementalInfo.haveClaims ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="haveClaims" id="haveClaimsYes"
                                [(ngModel)]="supplementalInfo.haveClaims" [value]="true"
                                (click)="supplementalInfo.haveClaims=true; setMaxDate();" #haveClaims="ngModel"
                                name="radhaveClaims" ngDefaultControl
                                [ngClass]="supplementalInfo.haveClaims ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            Yes
                        </label>
                        <label class="btn ww-radio-button"
                            [ngClass]="supplementalInfo.haveClaims === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            <input type="radio" name="haveClaims" id="haveClaimsNo"
                                [(ngModel)]="supplementalInfo.haveClaims" [value]="false"
                                (click)="UnCheck(template,'loss')" #haveClaims="ngModel" name="radhaveClaims"
                                ngDefaultControl 
                                [ngClass]="supplementalInfo.haveClaims === false ? 'btn ww-radio-button-selected': 'btn ww-radio-button'">
                            No
                        </label>
                    </div>
                    <div class="invalid-feedback" style="display: block;" *ngIf="underWriting.submitted && supplementalInfo.haveClaims === null">
                        <label class="error-message">Please provide a valid value.</label>
                    </div>
                </div>
                <div class="col-12" *ngIf="supplementalInfo.haveClaims">
                    <div class="d-flex align-items-center mt-md-0 pb-3">
                        <h3 class="mb-0">Claims</h3>
                        <p class="text-muted text-right flex-grow-1 mb-0">
                            <button type="button" class="btn ww-button"
                                (click)="openClaimModal(claimsModalTemplate, null, 'Add', 0)">
                                Add Claim
                            </button>
                        </p>
                    </div>
                    <div class="card-body p-0 pt-3 desktop-table d-none d-sm-block">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">Loss Date</th>
                                    <th scope="col">Cause of Loss</th>
                                    <th scope="col">Loss Amount($)</th>
                                    <th scope="col">Note</th>
                                    <th scope="col" style="width: 12%;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="supplementalInfo.lossHistories.length <= 0" name='NoRenovationFound'
                                    ngDefaultControl
                                    [required]="supplementalInfo.haveClaims && supplementalInfo.lossHistories.length <= 0"
                                    [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                                    [ngClass]="{ 'error': (underWriting)}">
                                    <td colspan="5">
                                        <p class="mb-0"
                                            *ngIf="underWriting.submitted && supplementalInfo.haveClaims && supplementalInfo.lossHistories.length <= 0">
                                            <label class="error-message">No claim found. Please add a claim.</label>
                                        </p>
                                    </td>
                                </tr>
                                <tr *ngFor="let loss of supplementalInfo.lossHistories; let i = index">
                                    <td scope="col">{{convertDateToMMDDYYYY(loss.date)}}</td>
                                    <td scope="col">{{getClaimTypeValue(loss.cause)}}</td>
                                    <td scope="col">{{loss.amountPaid}}</td>
                                    <td scope="col break-all">{{loss.note}}</td>
                                    <td>
                                        <a class="cursor-pointer"
                                            (click)="openClaimModal(claimsModalTemplate, loss,'Edit', i)">Edit</a>
                                        <span class="sep">|</span>
                                        <a class="text-danger cursor-pointer" (click)="deleteClaim(i)">Delete</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="card card-body mobile-table d-sm-none"
                        *ngIf="underWriting.submitted && supplementalInfo.haveClaims && supplementalInfo.lossHistories.length <= 0"
                        name='NoRenovationFound' ngDefaultControl
                        [required]="supplementalInfo.haveClaims && supplementalInfo.lossHistories.length <= 0"
                        [(ngModel)]="NoRenovationFound" #underwritingInforenovations="ngModel"
                        [ngClass]="{ 'error': (underWriting.submitted)}">
                        <label class="error-message">No claim found. Please add a claim.</label>
                    </div>
                    <div class="card mobile-table d-sm-none"
                        *ngIf="supplementalInfo.haveClaims && supplementalInfo.lossHistories.length > 0">
                        <div class="card-body border-bottom"
                            *ngFor="let loss of supplementalInfo.lossHistories; let i = index;">
                            <table class="table table-borderless mb-0">
                                <tbody>
                                    <tr>
                                        <th class="pl-0" scope="row">Loss Date</th>
                                        <td>{{convertDateToMMDDYYYY(loss.date)}}</td>
                                    </tr>
                                    <tr>
                                        <th class="pl-0" scope="row">Cause of Loss</th>
                                        <td>{{getClaimTypeValue(loss.cause)}}</td>
                                    </tr>
                                    <tr>
                                        <th class="pl-0" scope="row">Loss Amount($)</th>
                                        <td>{{loss.amountPaid}}</td>
                                    </tr>
                                    <tr>
                                        <th class="pl-0" scope="row">Note</th>
                                        <td class="break-all">{{loss.note}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <a class="cursor-pointer"
                                (click)="openClaimModal(claimsModalTemplate, loss,'Edit', i)">Edit</a> <span
                                class="sep">|</span>
                            <a class="cursor-pointer text-danger" (click)="deleteClaim(i)">Delete</a>
                        </div>
                    </div>

                    <!-- Claims Modal -->
                    <ng-template #claimsModalTemplate>
                        <div class="custom-modal-container">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <form class="lossHistoryForm" (ngSubmit)="onSubmitLossHistory(lossHistoryForm)"
                                            #lossHistoryForm="ngForm">
                                            <div class="ww-modal-header">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p>{{editMode == true ? 'Edit a Claim': 'Add a Claim'}}</p>
                                                    </div>
                                                    <div class="col-1">
                                                        <button type="button" class="close" aria-label="Close"
                                                            (click)="cancelClaimUpdate()">
                                                            <span aria-hidden="true"
                                                                style="font-size: 30px;">&times;</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                    <label>Loss Date <span class="required-span">*</span></label>
                                                    <div class="input-group" style="margin-bottom: 0px;">
                                                        <input class="form-control" placeholder="mm/dd/yyyy"
                                                            name="lossDate" #lossHistoryDateModel="ngModel"
                                                            [(ngModel)]="lossHistory.date" ngbDatepicker
                                                            [minDate]="ngbMinDate" [maxDate]="ngbMaxDate" type="text"
                                                            appMaskInputDirective [appMaskValue]="lossHistory.date"
                                                            dateValidator #lossHistoryDate="ngbDatepicker"
                                                            [ngModelOptions]="{ updateOn: 'change' }" required />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-outline-secondary calendar"
                                                                (click)="lossHistoryDate.toggle()"
                                                                type="button"></button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        *ngIf="(lossHistoryDateModel.touched && !lossHistoryDateModel.valid)||(lossHistoryForm.submitted && lossHistoryDateModel.invalid)">
                                                        <label class="error-message">Please provide a valid Loss Date.</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                    <label>Cause of Loss <span class="required-span">*</span></label>
                                                    <select class="form-control" [(ngModel)]="lossHistory.cause"
                                                        #selectCauseOfLossType="ngModel" required
                                                        name="selectCauseOfLossType" id="selectCauseOfLossType">
                                                        <option selected value=""></option>
                                                        <option *ngFor="let lossType of supplementalInfo.lstLossTypes"
                                                            [value]="lossType.lookupId">
                                                            {{lossType.lookupValue}}
                                                        </option>
                                                    </select>
                                                    <div
                                                        *ngIf="selectCauseOfLossType.touched && (lossHistory.cause === '' || selectCauseOfLossType.status=='INVALID')">
                                                        <label class="error-message">Cause of Loss required.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                    <label>Loss Amount ($) <span class="required-span">*</span></label>

                                                    <input required [(ngModel)]="lossHistory.amountPaid"
                                                        name="lossAmount" class="form-control"
                                                        [ngClass]="{ 'is-invalid': (lossHistoryForm.submitted && lossAmount.invalid) || (lossAmount.touched && !lossAmount.valid) }"
                                                        id="lossAmount" [ngModelOptions]="{ updateOn: 'change' }"
                                                        currencyMask autocomplete="off" (blur)="removeZero()" [options]="{ align: 'left',
                                                                 allowNegative: false,
                                                                 allowZero: false,
                                                                 decimal: '.',
                                                                 precision: 0,
                                                                 prefix: '$ ',
                                                                 suffix: '',
                                                                 thousands: ',',
                                                                 nullable: false,
                                                                 min: null,
                                                                 max: 9999999
                                                     }" #lossAmount="ngModel" />

                                                    <div *ngIf="lossAmount.touched && (lossHistory.amountPaid === '' || lossAmount.status === 'INVALID')">
                                                        <label class="error-message">Loss Amount is required.</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 pb-3 small-d-card-ptb">
                                                    <label>Note</label>
                                                    <input class="form-control" type="text" name="lossNote"
                                                        id="lossNote" [(ngModel)]="lossHistory.note" [maxLength]="50" />
                                                </div>
                                            </div>
                                            <div class="row d-flex justify-content-between">
                                                <button type="button" class="btn btn-secondary ml-3"
                                                    (click)="cancelClaimUpdate()">Cancel</button>
                                                <button type="submit" class="btn ww-button mr-3"
                                                    [disabled]="lossHistoryForm.invalid" (click)="AddClaim()">{{editMode
                                                    == true ? 'Edit Claim':
                                                    'Add Claim'}}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="container-xl">
                <div class="row justify-content-center">
                    <app-model-popup (savePageDetails)="savePageDetails()"></app-model-popup>
                    <div class="row">
                        <div class="col">
                            <ng-template #template>
                                <div class="modal-body text-center" data-backdrop="static" data-keyboard="false">
                                    <p>This will delete all the data from the grid. Do you want to continue?</p>
                                    <button type="button" class="btn btn-RTQ m-2" (click)="confirm()">Okay</button>
                                    <button type="button" class="btn btn-RTQ " (click)="decline()">Cancel</button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section class="bg-white d-sm-block mb-5">
        <div class="container-xl">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn ww-button" aria-label="Go Back to Previous Screen" awPreviousStep
                        tabindex="-1">
                        <i>
                            <fa-icon icon="chevron-left"></fa-icon> <fa-icon icon="chevron-left"></fa-icon>
                        </i>
                        Previous
                    </button>
                </div>
                <div class="col text-right">
                    <button type="submit" class="btn ww-button" aria-label="Go to Next Step"
                        [disabled]="(this.dataService.isDataSaveInProgress$ | async) ? true : null">
                        Next <i>
                            <fa-icon icon="chevron-right"></fa-icon><fa-icon icon="chevron-right"></fa-icon>
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </section>
</form>
<!-- //END OF FORM -->