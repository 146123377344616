import { Component, OnInit, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-auto',
  templateUrl: './auto.component.html',
  styleUrls: ['./auto.component.css']
})
export class AutoComponent implements OnInit, AfterViewInit {
  categoryID: number = 2;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'auto-header.7e94eff37706f37268a5.jpg',
    desktopHeaderText: 'Auto Insurance',
    desktopHeaderSubText: "You depend on your vehicle for so much! Getting the right protection is important. Westwood works with some of the nation's top auto insurance companies to find the best coverage and price for you.",
    mobileHeaderText: 'Auto Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx" target="_blank" class=" btn ww-button">Get a Quote</a>'
  });
  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

}
