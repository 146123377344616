import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ccpa',
  templateUrl: './ccpa.component.html',
  styleUrls: ['./ccpa.component.css']
})
export class CcpaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
