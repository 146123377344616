<!--  I N T R O   -->
<section id="intro-para">
    <div class="container text-center pt-5 pb-5">
        <div class="row pt-2">
            <div class="col">
                <h1 class="col">QUOTE REQUEST</h1>
            </div>
        </div>
        <div class="row pb-1 pl-3 justify-content-center">
            <div class="col-md-6">
                <img src="assets/img/orange-divider-transparent.png" /> <br><br>
                <p *ngIf="this.dataService.leadDetails.isServiceUnavailable==false">
                    {{this.dataService.leadDetails.errorMessage}}
                </p>
            </div>
        </div>
    </div>
</section>
<!-- //  I N T R O    -->

<form #contactForm="ngForm">
    <section id="rtq-form" class="pb-5">
        <div class="container">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="container text-center pt-3 pb-5">
                <div class="row justify-content-center">
                    <div class="col-md-9 col-xs-9" *ngIf="!this.dataService.leadDetails.isServiceUnavailable">
                        <span class="text-orange">
                            Thank you for your request. We will reach out to you shortly. Our normal business hours are
                            8 AM to 7
                            PM CST, Monday through Friday. You may also call us at <a
                                href="tel:{{commonService.formatPhoneNumber(leadDetails.salesAgent.phoneNumber)}}">{{leadDetails.salesAgent.phoneNumber}}</a>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-9 col-xs-9" *ngIf="this.dataService.leadDetails.isServiceUnavailable">
                        <span class="text-orange" [innerHTML]="dataService.leadDetails.errorMessage">

                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>