import { Component, OnInit, EventEmitter, Output } from '@angular/core';
declare var jQuery: any;
@Component({
  selector: 'app-model-pop-up-lender-request-message',
  templateUrl: './model-pop-up-lender-request-message.component.html',
  styleUrls: ['./model-pop-up-lender-request-message.component.css']
})
export class ModelPopUpLenderRequestMessageComponent implements OnInit {
  displayMsg:string =  "";
  @Output("parentFunYes") parentFunYes: EventEmitter<any> = new EventEmitter();
  @Output("parentFunNo") parentFunNo: EventEmitter<any> = new EventEmitter();
  @Output("parentFunOk") parentFunOk: EventEmitter<any> = new EventEmitter();
  constructor() { }
  showOk:boolean = false;
  ngOnInit() {
  }

  //To show function
  displayMessage(msg, showOkButton){ 
    this.displayMsg = msg; 
    if(showOkButton) {
      this.showOk = true;
    }
    jQuery('#lenderRequestModal').modal('show', {backdrop: 'static', keyboard: false});
  }

  continueButton(event){
    event.preventDefault(); 
  }
}
