import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { CommonService } from '../../service/common.service';
declare function breadcrumbShow(): any;
declare var jQuery: any;

@Component({
  selector: 'app-partner-api',
  templateUrl: './partner-api.component.html',
  styleUrls: ['./partner-api.component.css']
})
export class PartnerApiComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'partner-api-banner.jpg',
    desktopHeaderText: 'Partner API',
    desktopHeaderSubText: "Westwood's API allows builders and mortgage lenders to provide a seamless insurance program to their homebuyers.",
    mobileHeaderText: 'Partner API',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  (click)="requestDetails()" class=" btn banner-button" type="button">REQUEST DETAILS</a>',
    desktopHeaderButton: '<a  (click)="requestDetails()" class=" btn banner-button" type="button">REQUEST DETAILS</a>'
  });

  
  constructor(private elRef: ElementRef, public commonService: CommonService) { }

  ngOnInit() {
    if (!this.commonService.iMobBroswer()) {
      this.subPageBannerDetails.mobileHeaderButton = '';
    }else {
      this.subPageBannerDetails.desktopHeaderButton = ''; 
    }
  }

  ngAfterViewInit() {
    breadcrumbShow();
    // assume dynamic HTML was added before
    this.elRef.nativeElement.querySelector('a').addEventListener('click',
      this.requestDetails.bind(this));
  }

  requestDetails() {
    jQuery('#requestDetailsModal').modal('show', { backdrop: 'static', keyboard: false });

  }

}
