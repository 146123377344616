import { Injectable } from '@angular/core';
//import { debug } from 'util';


export enum formName {
  dogs, otherPets, renovation, claim
}
@Injectable({
  providedIn: 'root'
})
export class EditableGridService {

  currentEditFieldVal: string;
  dogBreedList: Array<any> = [
    { id: 1, breed: 'Akita', mixedBreed: 'Beagle', haveBiteHistory: true, explanation: 'he was good' },
    { id: 2, breed: 'Boxer', mixedBreed: 'Akita', haveBiteHistory: false, explanation: 'he was good2' },
    { id: 3, breed: 'Beagle', mixedBreed: 'Boxer', haveBiteHistory: true, explanation: 'he was good3' },

  ];
  otherPetsList: Array<any> = [
    { id: 1, description: 'test1' },
    { id: 2, description: 'test2' },
    { id: 3, description: 'test3' },

  ];
  renovationList: Array<any>= [
    { id: 1, renovationType : 'Heating', details: 'details 1', extent: 'Complete', yearCompleted: '2018' },
    { id: 2, renovationType : 'None', details: 'details 1', extent: 'Partial', yearCompleted: '2016' },
    { id: 3, renovationType : 'Plaumbing', details: 'details 1', extent: 'Complete', yearCompleted: '2016' },
 
  ]
  claimList: Array<any>=[
    { id: 1, lossDate : '01-10-2018', cause: 'cause 1', lossAmount: '$ 2018', note: 'note 1' },
    { id: 2, lossDate : '01-10-2018', cause: 'cause 2', lossAmount: '$ 2017', note: 'note 2' },
    { id: 3, lossDate : '01-10-2018', cause: 'cause 3', lossAmount: '$ 2016', note: 'note 3' },
  ]
  updateList(id: number, property: string, event: any) {
    // const currentEditFieldVal = event.target.textContent;
    // this.dogBreedList[id][property] = currentEditFieldVal;
  }

  remove(id: any, formVal: formName) {
    
    switch (formVal) {
      case 1: {
        this.dogBreedList.splice(id, 1);
        break;
      }
      case 2: {
        this.otherPetsList.splice(id, 1);
        break;
      }
      case 3: {
        this.renovationList.splice(id, 1);
        break;
      }
      case 4 as formName: {
        this.claimList.splice(id, 1);
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    
  }

  add(formVal : formName) {
    switch (formVal) {
      case 1: {
        this.dogBreedList.push({ id: this.dogBreedList.length + 1, breed: '', mixedBreed: '', haveBiteHistory: false, explanation: '' });
        break;
      }
      case 2: {
        this.otherPetsList.push({ id: this.otherPetsList.length + 1, description: ''});
        break;
      }
      case 3: {
        this.renovationList.push({ id: this.otherPetsList.length + 1, description: ''});
        break;
      }
      case 4 as formName: {
        this.claimList.push({ id: this.otherPetsList.length + 1, description: ''});
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    

  }

  trackEditValue(id: number, property: string, event: any) {
    // this.currentEditFieldVal = event.target.textContent;
  }

}

