import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants'; 
import { DataService } from 'src/app/service/data.service';
import { InsuranceLicensingService } from '../../service/insurance-licensing.service';
import { stateLicenseNumber } from '../../model/stateLicenseNumber'; 

declare var jQuery: any;
@Component({
  selector: 'app-service-area-map',
  templateUrl: './service-area-map.component.html',
  styleUrls: ['./service-area-map.component.css']
})
 

export class ServiceAreaMapComponent implements OnInit { 
  
	const: Constants;  
	InsurnaceDeatils:Array <stateLicenseNumber>=[];
  
	constructor(  
	  public commonService: CommonService, 
	  public constantObj: Constants,
	  public dataService: DataService,
	  public insuranceLicensingService: InsuranceLicensingService,  
	  ) {
	  this.const=constantObj;
	}
	ngOnInit() { 
		this.getServiceAreaMapValues();
		
	}
  
	getServiceAreaMapValues() {
   
		this.insuranceLicensingService.getInsuranceLicensingInfo().subscribe((data:Array<stateLicenseNumber>)=> { 
		this.InsurnaceDeatils = data; 
		this.maps(this.InsurnaceDeatils);
		
		},err => {
		  this.dataService.handleError(err);
		});
	} 

	ngOnDestroy() {
		this.InsurnaceDeatils = [];
	}

	AfterViewInit(){ 
		//Show map
		jQuery("#homeMap").find('#homeVectorMap').vectorMap({map: 'world_mill'}); 
	}

	//Function to configure map and set data
	maps(mapData){
		if(jQuery('#homeVectorMap').length){

			jQuery('#homeVectorMap').html('');

			//personal insurance
			var piMap = {
				map: 'us_lcc',
				backgroundColor: 'transparent',
				regionStyle: {
					initial: {
					fill: '#004c97'
					},
					hover: {
						fill: "#dc3545"
					}
				},
				onRegionTipShow: function(e, el, code){ 
					let markerValue = mapData.filter(function(item) {
						return item.shortState === code;
					  })[0]; 
					  
					let licenseNum = ''  
					if(markerValue){
						licenseNum = markerValue.pcLicenseNumber
					} 
					el.html(licenseNum);
				}
			}

			//surplus insurance
			var spMap = {
				map: 'us_lcc',
				backgroundColor: 'transparent',
				regionStyle: {
					initial: {
					fill: '#0091e4'
					},
					hover: {
						fill: "#dc3545"
					}
				},
				onRegionTipShow: function(e, el, code){
					let markerValue = mapData.filter(function(item) {
						return item.shortState === code;
					  })[0];
					let licenseNum = ''  
					if(markerValue){
						licenseNum = markerValue.surplusLicenseNumber
					} 
					el.html(licenseNum);
				}
			}

			jQuery('#homeVectorMap').vectorMap(piMap);

			//toggles
			jQuery('input[type=radio][name=mapRadio]').change(function(){
				jQuery('.mapCheckBox i').each(function(){ 
					jQuery(this).toggleClass('d-block d-none');
				});
				jQuery('#homeVectorMap').html('');
				jQuery('#homeVectorMap').vectorMap(eval(this.value));
			});

		}	
	}

}
