<!--  P R I V A C Y  P O L I C Y   -->


<section>
    <div class="container pt-5 pb-5">

        <div class="row pt-2">
            <div class="col">
                <h2 class="col">SSN - Privacy Policy</h2>
                <img class="pl-3" src="assets/img/orange-divider-transparent.png" /> <br><br>
            </div>
        </div>

        <div class="row pb-4 text-right">
            <div class="col-12">
                <a routerLink="/ccpa">California Consumer Privacy Act Notice</a><br>

                <a routerLink="/privacy-policy">Privacy Policy</a><br>

            </div>
        </div>

        <div class="row pb-2 pl-3">
            <div class="col-md-8 col-xs-9 pb-5 pt-5">

                <span>Social Security Number Privacy Protection Policy</span><br><br>

                <p>We maintain policies that protect the confidentiality of Personal Information, including Social
                    Security numbers, obtained from our customers and employees in the course of our regular business
                    functions. We are committed to protecting the Personal Information of our customers and employees
                    and have implemented physical, technical and administrative safeguards to protect such information.</p>
                
                <p>
                    Personal Information is information that is capable of being associated with an individual through
                    one or more identifiers including, but not limited to, a Social Security number, a driver's license
                    number, a state identification card number, an account number, a credit or debit card number, a
                    passport number, an alien registration number, or a health insurance identification number.
                    Personal Information does not include publicly available information that is lawfully made
                    available to the general public from federal, state or local government records or widely
                    distributed media.</p>
                <br>


                <ul>
                    <li>Our internal policies and procedures impose a number of standards to:
                        <ul>
                            <li>safeguard the confidentiality of Personal Information, including Social Security
                                numbers;</li>
                            <li>prohibit the unlawful disclosure of Social Security numbers, and limit access to Social
                                Security numbers.</li>
                        </ul>
                    </li>
                    <li>We will not request a Social Security number unless the number is needed to comply with federal
                        or state law, or where required to administer or enforce a transaction.</li>
                    <li>We will not use or share Social Security numbers or Personal Information with third parties
                        unless there is a legitimate business "need to know" in accordance with applicable laws and
                        regulations.</li>
                    <li>Records containing Social Security numbers must be stored, transmitted, and disposed of in
                        accordance with company information protection standards.
                    </li>
                    <li>Our employees may access Social Security numbers or Personal Information only as required by
                        their job duties.</li>
                </ul>

            </div>
        </div>

    </div>
</section>


<!-- //  P R I V A C Y  P O L I C Y    -->


