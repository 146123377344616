import { ReCaptcha } from './reCaptcha';
import { Injectable } from "@angular/core";

@Injectable()
export class Contactus {
    public firstName = ''; 
    public lastName: string; 
    public company: string;
    public email: string;
    public phoneNumber: number;
    public message: string;
    public emailTemplate: string;
    public errorMessage: string;
    public reCaptcha:ReCaptcha;    
    constructor() {
        this.firstName = ''; 
        this.lastName = ''; 
        this.company = '';
        this.email = '';
        this.phoneNumber = null;
        this.message = '';
        this.emailTemplate = '';
        this.errorMessage = '';
    }
}




