<div class="modal fade" id="lenderRequestModal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <span>Important Notice</span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.parentFunNo.emit()" *ngIf="!showOk">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"  *ngIf="showOk" (click)="this.parentFunOk.emit()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{ displayMsg }}</p>
            </div>
            <div class="modal-footer">
                <button class="angled-button-sm button-width-110 btn" *ngIf="!showOk" (click)="this.parentFunYes.emit()"><strong>Yes</strong> </button>
                <button class="angled-button-sm button-width-110 btn" aria-hidden="true" data-dismiss="modal" aria-label="Close" (click)="this.parentFunNo.emit()" *ngIf="!showOk"><strong>No</strong>
                </button>
                <button class="angled-button-sm button-width-110 btn" aria-hidden="true" data-dismiss="modal" aria-label="Close" *ngIf="showOk" (click)="this.parentFunOk.emit()"><strong>Ok</strong>
                </button>
            </div>
        </div>
    </div>
</div>