<!-- H O M E   H E R O   -->
<app-get-aquote-banner></app-get-aquote-banner>
<!-- // H O M E   H E R O  -->

<!-- I N S U R A N C E   P R O D U C T S  -->
<app-insurance-products></app-insurance-products>
<!-- // I N S U R A N C E   P R O D U C T S  -->
<!-- M A P  -->
<!-- <app-service-area-map></app-service-area-map> -->
<!-- //  M A P  -->



<!-- P A R T N E R   W I T H    U S -->

<section id="homePartner" class="mt-4">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-lg-5 my-5 my-lg-0 pr-0 pr-md-5 text-center text-lg-left" id="homePartnerLeft">
                <div id="homePartnerLeftShiv">
                    <h2 class="text-center text-lg-left">PARTNER WITH US</h2>
                    <div class="row pt-1">
                        <div class="col">
                            <img src="assets/img/orange-divider-graybg.png" />
                        </div>
                    </div>
                    <p class="py-4 text-left partner-WithUS">
                            Your perfect partner to close on time, every time. 
                        <br><br>
                        Westwood is a full-service independent agency with a nationwide footprint. Our innovative technology and seamless integration are why home builders, mortgage lenders and others have trusted Westwood for 70 years.
                    </p>
                    <a class="show-cursor a-text-decoration-none" routerLink="/partners">
                        <div class="angled-button-sm button-align-mob button-width-210">
                            <span>Learn More</span>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-7 d-none d-lg-block" id="homePartnerRight">
                <div class="row">
                        <div class="col">
                                <div class="video-image mt-5 mb-5">
                                        <img src="assets/img/video-icon.png" style="width: 100%; height:100%;">
                                        <div class="video-overlay">
                                            <a class="video-trigger" data-open="youtube-video" data-youtube-id="https://youtu.be/BcyOpyEdJfM" 
                                            aria-controls="youtube-video" aria-haspopup="true" tabindex="0"
                                            (click)="child.importantNotice($event, 'https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx')">
                                            <i class="fa fa-play-circle" 
                                            aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                <!-- <iframe class="ww-video" title="Westwood Agency Insurance overview"
                                    width="699" height="475" src="https://www.youtube.com/embed/Hob9CSqur80" 
                                    title="An Insurance Agency Built for the Housing Industry" frameborder="0" 
                                    poster="assets/img/partner-builder.jpg"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen>
                                </iframe> -->
                                <!-- <app-model-pop-up-other-domain></app-model-pop-up-other-domain> -->
                                <!-- <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx')"
             href=" #"></a>
                                <img src="assets/img/partner-mortgage-company.jpg" width="699" height="500"
                                >
                                <i class="fa fa-play-circle" aria-hidden="true"></i> -->
                        </div>
                    <!-- commented the below columns for WSTW-6236 -->
                    <!-- <div class="col-6 p-0">
                        <div class="card homePartnerCard">
                            <img src="assets/img/partner-builder.jpg" class="card-img" alt="Builder">
                            <div class="card-img-overlay d-flex align-items-center justify-content-center">
                                <a routerLink="/partners" class="stretched-link">Builder</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 p-0">
                        <div class="card homePartnerCard">
                            <img src="assets/img/partner-mortgage-company.jpg" class="card-img" alt="Mortgage Company">
                            <div class="card-img-overlay d-flex align-items-center justify-content-center">
                                <a routerLink="partners" class="stretched-link">Mortgage<br>Company</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 p-0">
                        <div class="card homePartnerCard">
                            <img src="assets/img/partner-property-manager.jpg" class="card-img" alt="Property Manager">
                            <div class="card-img-overlay d-flex align-items-center justify-content-center">
                                <a routerLink="/partners" class="stretched-link">Property<br>Manager</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 p-0">
                        <div class="card homePartnerCard">
                            <img src="assets/img/partner-other.jpg" class="card-img" alt="Other">
                            <div class="card-img-overlay d-flex align-items-center justify-content-center">
                                <a routerLink="/partners" class="stretched-link">Other</a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- // P A R T N E R   W I T H   U S -->


<!--  P A R T N E R   W I T H   U S  M O B I L E -->


<section id="homePartner-mobile">
    <div class="col d-md-block d-lg-none">
        <div class="row mb-3">
            <!-- <div class="col-3 pwu-builder d-flex align-items-center justify-content-center">
                <a routerLink="/partners" class="stretched-link">Builder</a>
            </div>
            <div class="col-3 pwu-mortgage-company d-flex align-items-center justify-content-center">
                <a routerLink="/partners" class="stretched-link">Mortgage <br>Company</a>
            </div>
            <div class="col-3 pwu-property-manager d-flex align-items-center justify-content-center">
                <a routerLink="/partners" class="stretched-link">Property <br>Manager</a>
            </div>
            <div class="col-3 pwu-other d-flex align-items-center justify-content-center">
                <a routerLink="/partners" class="stretched-link">Other</a>
            </div> -->
            <div class="col align-items-center justify-content-center mb-5">
                    <div class="video-image">
                            <img src="assets/img/video-icon.png" style="width: 100% !important;height: auto!important;" >
                            <div class="video-overlay">
                                <a class="video-trigger" data-open="youtube-video" data-youtube-id="https://youtu.be/BcyOpyEdJfM" 
                                aria-controls="youtube-video" aria-haspopup="true" tabindex="0"
                                (click)="child.importantNotice($event, 'https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx')">
                                <i class="fa fa-play-circle" 
                                aria-hidden="true"></i></a>
                            </div>
                        </div>
                        </div>
        </div>
    </div>
</section>

<!--  // P A R T N E R   W I T H   U S  M O B I L E -->

<!-- C U S T O M E R   T E S T I M O N I A L S  -->

<section id="customer-test" class="mb-5 pt-5 pb-4">
	<div class="container text-center">
		<div class="row justify-content-center">
			<div class="col-10">
				<div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="7500">
					<div class="carousel-inner">

						<div [ngClass]=" i == 0 ? 'carousel-item active':'carousel-item'"
							*ngFor="let testimonial of testomonialData; let i = index">
							<div class="pb-3">
								<img src="assets/img/quotes.png" alt="orange quote marks decor"><br>
							</div>
							{{ testimonial.description }}<br><br>
							<h6>{{ testimonial.givenBy }}, {{ testimonial.state }}</h6>
						</div>

					</div>
					<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true">
							<img src="assets/img/arrow-lft.png" alt="orange left arrow decor" id="left-arrow-image">
						</span>
						<span class="sr-only">Previous</span>
					</a>
					<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true">
							<img src="assets/img/arrow-rght.png" id="right-arrow-image" alt="orange left arrow decor">
						</span>
						<span class="sr-only">Next</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- // C U S T O M E R   T E S T I M O N I A L S  -->

<!-- G E T   A   Q U O T E -->
<div class="pt-4 mt-5">
    <app-get-aquote-small-banner></app-get-aquote-small-banner>
</div>

<!-- //  G E T   A   Q U O T E -->

<!-- H O M E   B L O G  -->
<section id="homeBlog" class="pt-4 mt-5">
    <app-knowledge-center></app-knowledge-center>
</section>
<!-- // H O M E   B L O G  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->

<!-- ALERT MESSAGE -->
<div class="div-alert-info">
    <div *ngFor="let alertInfo of alertInfoData; let i = index">
        <div class="mb-0 pb-0 pt-3 alert alert-dismissible fade show bottom-alert1" role="alert"
            *ngIf="alertInfo.pageName==='claims/report-a-claim'">
            <strong>
                <li class="pr-2 fa fa-info-circle li-icon"></li>
            </strong>
            {{alertInfo.heading}}
            <span><a routerLink="{{alertInfo.pageName | lowercase}}">Click here</a></span> to report a claim.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="mb-0 pb-0 pt-3 alert alert-dismissible fade show bottom-alert1" role="alert"
            *ngIf="alertInfo.pageName==='current-customers/lender-requests-changes'">
            <strong>
                <li class="pr-2 fa fa-info-circle li-icon"></li>
            </strong>
            {{alertInfo.heading}}
            <span><a routerLink="{{alertInfo.pageName | lowercase}}">Click Here&nbsp;&gt;&gt;</a></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="mb-0 pb-0 pt-3 alert alert-dismissible fade show bottom-alert1" role="alert"
            *ngIf="alertInfo.pageName!=='claims/report-a-claim' && alertInfo.pageName!=='current-customers/lender-requests-changes'">
            <strong>
                <li class="pr-2 fa fa-info-circle li-icon"></li>
            </strong>{{alertInfo.heading}} <span><a routerLink="{{alertInfo.pageName | lowercase}}">Learn
                    More&nbsp;&gt;&gt;</a></span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<!-- // ALERT MESSAGE -->
<!-- E X T E R N A L    S I T E    M O D A L   -->
<app-pop-up-video-container></app-pop-up-video-container>
<!-- E X T E R N A L    S I T E    M O D A L   -->