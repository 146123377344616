import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { Lead } from "../model/lead";
import { Observable, of } from "rxjs";
import { Controller } from "../Utilities/api/controller.config";
import { Action } from "../Utilities/api/action.config";
import { States } from "../model/states";

@Injectable({
  providedIn: "root",
})
export class CustInfoService {
  url: string;
  constructor(private dataService: DataService) {}
  /*Fetch Lead  Details by LeadID */
  getLeadInfo(data: Lead): Observable<Lead> {
    this.url = Controller.customerInfo + "/" + Action.getLeadInfo;
    return this.dataService.postData(data, this.url);
  }
  /*Create/Update Lead  Details by Lead Object */
  postLeadInfo(data: Lead): Observable<Lead> {
    this.url = Controller.customerInfo + "/" + Action.postCustInfo;
    return this.dataService.postData(data, this.url);
  }
  getStates(): Observable<Array<States>> {
    this.url = Controller.customerInfo + "/" + Action.getStates;
    return this.dataService.getAll(this.url);
  }
  getPhoneTypes(): Observable<Array<States>> {
    this.url = Controller.customerInfo + "/" + Action.getPhoneTypes;
    return this.dataService.getAll(this.url);
  }
}
