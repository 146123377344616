<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-bicycle" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Bicycle Insurance</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->

<!-- P  H O T  O   /  T E X  T  -->
<section>
  <div class="container  pt-3 pb-5">
    <div class="row">
      <div class="col">
        <h2 class="col text-center">Why Bicycle Insurance?</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-3">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
    <div class="row" id="a-bullet-list">
      <div class="col-md-6 pb-4">
        <img src="assets/img/page-body-img/shutterstock_471958082.png" style="width: 100%;" alt="Woman on bike" />
      </div>
      <div class="col-md-6 col-xs-12">
        <ul>
          <li>Homeowner, condo and renter insurance will not cover your bicycle for damage caused by a collision.</li>
          <li>Your home insurance also likely does not provide roadside assistance or temporary rental if your bicycle
            is damaged and needs to be repaired.</li>
          <li> Bicycle insurance provides many extra coverages for the bicycle enthusiast.</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- P  H O T  O   /  T E X  T  -->


<!-- W H A T S   I N C L U D E D   -->

<section id="whats-included">
  <div class="container pt-5 pb-5">
    <div class="row">
      <div class="col">
        <h2 class="col text-center">WHAT’S INCLUDED IN BICYCLE INSURANCE?</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-3">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
        <br><br>
        <p>Westwood partners with Markel Specialty to provide bicycle insurance. Your bicycle insurance pays claims for
          you and your bike when you’re at home or anyplace in the world for:</p>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-12 text-center">
        <ul class="pl-0">
          <li>Crash damage</li>
          <li>Theft coverage</li>
          <li>Theft away from home</li>
          <li>Vehicle contact protection</li>
          <li>Personal liability</li>
          <li>Replacement bicycle rental</li>
          <li>Roadside assistance</li>
          <li>Event fee reimbursement</li>
          <li>Cycling apparel</li>
          <li>Medical payments</li>
          <li>Racing coverage</li>
          <li>Spare parts</li>
          <li>Covered in transit</li>
          <li>Worldwide physical damage</li>
        </ul>
      </div>
    </div>

    <!-- O N L I N E   Q U O T E -->

 

    <!-- // O N L I N E   Q U O T E -->

  </div>
</section>

<!-- // W H A T S   I N C L U D E D  -->


<!-- A B O U T  M A R K E L   -->

<section class="about-markel">
  <div class="containerpb-5 bottom-border-element pb-5" id="a-bullet-list">
    <div class="row pt-5">
      <div class="col">
        <h2 class="col text-center">About Markel</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-5">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
    <div class="row justify-content-center p-2">
      <div class="col-md-6">
        <p>Markel North American Group is a group of subsidiaries of Markel Corporation, a Fortune 500 company. Coverage
          is provided by one or more of the insurance companies in the Markel North American Group, and policyholder
          services are provided by the underwriting manager, Markel Service Incorporated, a nationally licensed
          insurance producer (NIPR number 27585).
          <br><br>
          Markel Service Incorporated’s division, Markel Specialty, offers both commercial and personal lines products.
          Insurance carrier, coverage, dividends and services availability may vary by state. Markel Service
          Incorporated has provided referral compensation to Westwood Insurance Agency, LLC for product placement.
        </p>
        <br><br>
        <table>
          <tr>
            <th>Policy coverage</th>
            <th>Average homeowners policy</th>
            <th>Markel bicycle policy</th>
          </tr>
          <tr>
            <td>Insured at full value</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Crash damage</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Theft coverage</td>
            <td>Limited</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Theft away from home</td>
            <td>Limited</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Vehicle contact protection</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Personal liability</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Replacement bicycle rental</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Event fee reimbursement</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Cycling apparel</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Medical payments</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Racing coverage</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Spare parts insured</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Covered in transit</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Worldwide physical damage</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Roadside assistance</td>
            <td>Not likely</td>
            <td>Yes</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</section>

<!-- //  A B O U T  M A R K E L  -->
<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->
<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->