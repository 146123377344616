<!-- M A P  -->

<section id="homeMap" class="pt-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 vectorMapCon d-none d-lg-block">
                <div id="homeVectorMap" class="h-100 vectorMap"></div>
            </div>
            <div class="col-lg-4 py-5">
                <h2 class=" text-md-left">Our Licensed Service Areas</h2>
                <p class="py-4 map-text-full">
                    As one of the top 50 personal lines agencies in the United States, we bring quality service to
                    customers across the nation. Use the map to see license numbers where we’re licensed for Personal
                    Insurance and
                    Surplus Insurance.
                </p>
                <p class="py-4 map-text-less">
                    As one of the top 50 personal lines agencies in the United States, we bring quality service to
                    customers across the nation.
                </p>
                <div id="homeMapToggleCon" class="d-none d-lg-block">
                    <label class="mapToggle text-uppercase" id="piMapLabel">Personal Insurance
                        <input type="radio" checked="checked" name="mapRadio" data-map="piMap" value="piMap">
                        <span class="checkmark text-center mapCheckBox" id="piMapCheckbox"><i
                                class="fal fa-check d-block"></i></span>
                    </label>
                    <label class="mapToggle text-uppercase" id="spMapLabel">Surplus Insurance
                        <input type="radio" name="mapRadio" data-map="spMap" value="spMap">
                        <span class="checkmark text-center mapCheckBox" id="spMapCheckbox"><i
                                class="fal fa-check d-none"></i></span>
                    </label>
                </div>
                <!-- <div class="py-3 center-align-mob">
                    <a class="show-cursor" (click)="commonService.NavigateToPage('/about-us/insurance-licensing')">
                    <a class="show-cursor" (click)="statelicense= !statelicense">
                        <div class="angled-button-sm button-align-mob-center button-width-270">
                            <span>See all license numbers </span>
                        </div>
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</section>

<!-- S T A T E S -->
<section id="license-numbers-by-state">
    <div class="container top-border-element  mb-5 pb-5 pt-5 d-md-none">
        <div class="row">
            <div class="col">
                <h2 class="col text-center"> License Numbers by State</h2>
            </div>
        </div>

        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>


        <div class="row justify-content-center license-numbers">
            <div class="col-md-9 col-xs-9">

                <div class="row">
                    <input type="text" title="Enter your state" id="enter-state" onkeyup="myFunction()"
                        placeholder="Enter your state...">
                </div>

                <div class="row">
                    <table id="license-table">
                        <tr class="header">
                            <th style="width:30%;"><span>STATE</span></th>
                            <th style="width:35%;"><span>P&C LICENSE NUMBER</span></th>
                            <th style="width:35%;"><span>SURPLUS LICENSE NUMBER</span></th>
                        </tr>

                        <tr *ngFor="let InsurnaceDeatil of InsurnaceDeatils">
                            <td><span>{{InsurnaceDeatil.state}}</span></td>
                            <td>{{InsurnaceDeatil.pcLicenseNumber}}</td>
                            <td>{{InsurnaceDeatil.surplusLicenseNumber}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>