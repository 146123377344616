import { Injectable } from "@angular/core";

@Injectable()
export class UploadFile {
  public ReferenceNumber: string;
  public FileName: string;
  public PdfFileName: string;
  public Extension: string;
  public DocType: string;
  public LeadId: string;
  public EntryDate: string;
  public IsFromSource: string;
  public SourceType: string;
  public FolderName: string;
  public PolicyNumber: string;
  public isFromClientPortal: string;
  public PostedFile: string;

  constructor() {
    this.ReferenceNumber= "";
    this.FileName= "";
    this.PdfFileName= "";
    this.Extension= "";
    this.DocType= "";
    this.LeadId= "";
    this.EntryDate= "";
    this.IsFromSource= "";
    this.SourceType= "";
    this.FolderName= "";
    this.PolicyNumber= "";
    this.isFromClientPortal = "";
    this.PostedFile= "";
  }
}
