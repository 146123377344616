<section>
    <div class="container text-left pt-4">
        <div class="row pt-4">
            <div class="col">
                <div class="row pt-2">
                    <div class="col">
                        <h2 class="col text-left">LEGAL
                        </h2>
                    </div>
                </div>
                <div class="row justify-content-left pt-2 pb-4">
                    <div class="col-md-12">
                        <img class="ml-3" src="assets/img/orange-divider-transparent.png" alt="orange decor" />
                        <br><br>
                        <div class="col-md-12 legal-description">
                            <strong>
                                <p class="heading">Terms of Use Disclaimer</p>
                            </strong>
                            <strong>
                                <p class="sub-heading">This site is created, controlled and published by QBE.</p>
                            </strong>
                            <p class="pt-3">COPYRIGHT 2018 WESTWOOD INSURANCE AGENCY. ALL RIGHTS RESERVED.<br>Review all of the
                                following terms and conditions carefully. By using this Web site
                                (www.westwoodinsurance.com), you agree to comply with all terms and conditions for this
                                site as well as to comply with all applicable laws. QBE may modify these terms and
                                conditions at any time, without notice.</p>

                            <p class="pt-3">
                                Unless otherwise authorized by QBE, permission is granted to view, store, print,
                                reproduce, and distribute pages within this Web site solely for informational,
                                non-commercial purposes, provided that (a) no pages are modified, and (b) this page and
                                any notices in such pages regarding use or ownership are included with stored,
                                reproduced or distributed pages.
                            </p>
                            <strong>
                                <p class="sub-heading pt-3">Intellectual property and trademarks</p>
                            </strong>
                            <p class='pt-3'>All names, logos, trademarks, service marks, trade dress, graphics, designs, characters,
                                brand identifiers, and all other intellectual property appearing in this site, except
                                as otherwise noted, are owned or used under license by QBE or its subsidiaries. The use
                                or misuse of these marks or any other content on this site, except as provided in these
                                terms and conditions or in the site content, is strictly prohibited. All of the content
                                included in this Web site is subject to the copyright laws of the United States and
                                other applicable jurisdictions and QBE owns all the copyright rights associated with
                                this content. All rights reserved</p>
                            <p  class="pt-3">
                                Images displayed on this Web site are either the property of, or used with permission
                                by, QBE. The use of these images by you, or anyone else authorized by you, is
                                prohibited unless specifically permitted herein. Any unauthorized use of the images may
                                violate copyright laws, trademark laws, the laws of privacy and publicity, and
                                communications regulations and statutes.
                            </p>
                            <strong>
                                <p class="sub-heading pt-3">Links</p>
                            </strong>
                            <p class="pt-3">Some pages on www.westwoodinsurance.com contain links to other resources on the
                                Internet. Those links are provided as aids to help identify and locate other Internet
                                resources of interest. They are not intended to state or imply that QBE sponsors or is
                                affiliated or associated with the owners or publishers of such resources, or that QBE
                                is legally authorized to use any trade name, registered trademark, logo, legal or
                                official seal, or copyrighted symbol that may be reflected in the links. Therefore, the
                                decision to view any linked site is at the viewer's own risk.</p>
                            <strong>
                                <p class="sub-heading pt-3">Disclaimer</p>
                            </strong>
                            <p class="pt-3">
                                <span>CONTENT ON THIS WEB SITE IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER
                                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</span> Some
                                jurisdictions do not allow exclusion of the implied warranties, so the disclaimer above
                                may not apply to you in this respect.
                            </p>
                            <P class="pt-3">
                                The materials on this Web site are provided for informational purposes only. QBE does
                                not represent or warrant that any information in this Web site is correct, complete, or
                                up-to-date. QBE may change or delete information on this site without notice at any
                                time. Therefore, use of these materials is at the user's own risk.
                            </P>
                            <strong>
                                <p class="sub-heading pt-3">Information submitted by site visitors</p>
                            </strong>
                            <p class="pt-3">
                                Should any visitor of this Web site respond with information, including personal
                                information and feedback data such as questions, comments and suggestions regarding the
                                content of any portion of this publication, such information shall be deemed to be
                                non-confidential. Site visitors are encouraged to review QBE's Web Privacy Policy.
                            </p>

                            <strong>
                                <p class="sub-heading pt-3">Limitation of liability</p>
                            </strong>

                            <p class="pt-3">
                                <span>QBE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
                                PUNITIVE DAMAGES ARISING OUT OF ACCESS TO OR USE OF ANY CONTENT OF THIS SITE OR THE
                                CONTENT OF ANY SITE OR SITES LINKED TO THIS SITE.</span> In no event shall QBE's liability
                                exceed the total amount paid by you to QBE, if any, for accessing this site.
                            </p>

                            <strong>
                                <p class="sub-heading pt-3">Jurisdiction</p>
                            </strong>
                            <p class="pt-3">
                                This site is controlled and operated by QBE Americas Inc. from its Sun Prairie, WI, USA
                                location. Your viewing of any materials on this Web site constitutes your approval of
                                this Agreement and consent to jurisdiction in courts of competent jurisdiction in the
                                state of Delaware, without regard to conflict of law principals, to resolve any
                                interpretations or disputes arising from this Agreement and any conflicts that arise
                                from this site
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>