<section id="rtq-form" class="pb-5">
    <div class="container-xl" *ngIf="(dataService.isDataSaveInProgress$ | async) && dataService.isDisclaimerPending">
        <!-- <img src="assets/img/PRELOADER.gif" class="preloader" /> -->
        <!-- <div class="col-12 mb-3 text-center">
            <h2 style="text-decoration: none;" *ngIf="this.dataService.leadDetails.isServiceUnavailable==false">Quote
                Preparation</h2>
        </div> -->
        <div class="row justify-content-center" *ngIf="!this.dataService.leadDetails.isServiceUnavailable">
            <div class="col-md-7 col-xs-12">
                <div class="text-center">
                    <img src="assets/img/RTQ-WW-House-Loading-Loop.gif" style="width: 50%; margin-bottom: 10px;" />
                    <h4 style="font-weight: 600; color: #ff6900;">Give us a minute to find the policy <br />
                        that's right for you.</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="container-xl" *ngIf="(dataService.isDataSaveInProgress$ | async) === false && leadDetails.quoteResult.status && dataService.isDisclaimerPending && this.dataService.isDisclaimerRequired">
        <section>
            <div class="container-xl">
                <h4 class="text-center">{{leadDetails.disclosureInfo.title}}</h4>
                <div class="card-body text-justify mb-2" style="border: solid gray 1px;">
                    <p>{{leadDetails.disclosureInfo.text}}</p>
                </div>
                <div class="col-md-12 text-center">
                    <button class="btn ww-button" (click)="acceptDisclaimer()">Disclosure Read</button>
                </div>
            </div>
        </section>
    </div>
    <div class="container-xl"
        *ngIf="(isLoading === false || (dataService.isDataSaveInProgress$ | async) === false) && leadDetails.quoteResult.status && this.dataService.isDisclaimerRequired === false">
        <app-quote-result [mainWizard]="wizardStep"></app-quote-result>
    </div>

    <section *ngIf="(isLoading === false || (dataService.isDataSaveInProgress$ | async) === false) && leadDetails.quoteResult.status === false ">
        <div class="container-xl" *ngIf="dataService.leadDetails.isServiceUnavailable">
            <div class="row pt-2">
                <div class="col">
                    <p class="font-weight-bold header-blue mb-0 pb-0 text-sm-center p-2" [innerHTML]="dataService.leadDetails.errorMessage"></p>
                </div>
            </div>
        </div>
    </section>
</section>