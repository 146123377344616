import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { WizardComponent, MovingDirection } from "angular-archwizard";
import { CustomerInfoComponent } from "../customer-info/customer-info.component";
import { CreditAuthComponent } from "../credit-auth/credit-auth.component";
import { HomeInfoComponent } from "../home-info/home-info.component";
import { UnderwritingComponent } from "../underwriting/underwriting.component";
import { QuoteMessageComponent } from "../quote-message/quote-message.component";
import { Lead } from "src/app/model/lead";
import { DataService } from "src/app/service/data.service";

@Component({
  selector: "ww-quote-container",
  templateUrl: "./quote-container.component.html",
  styleUrls: ["./quote-container.component.css"],
})
export class QuoteContainerComponent implements OnInit, AfterViewInit {
  moveForward: boolean = false;
  @ViewChild("wizard") wizard: WizardComponent;
  @ViewChild(CustomerInfoComponent) custInfoChild: CustomerInfoComponent;
  @ViewChild(CreditAuthComponent) creditAuthChild: CreditAuthComponent;
  @ViewChild(HomeInfoComponent) homeInfoChild: HomeInfoComponent;
  @ViewChild(UnderwritingComponent) underwritingChild: UnderwritingComponent;
  @ViewChild(QuoteMessageComponent) quoteMessageChild: QuoteMessageComponent;
  custInfoResponse: boolean = false;
  leadDetails: Lead;

  constructor(
    public spinner: NgxSpinnerService,
    public dataService: DataService
  ) {
    this.leadDetails = new Lead();
  }

  ngOnInit(): void {
    // console.log('container1:', this.leadDetails.propertyUnderwriting.supplementalInformation);
  }
  
  ngAfterViewInit(): void {
    this.custInfoChild.leadDetails = this.leadDetails;
    // console.log('container2:', this.leadDetails.propertyUnderwriting.supplementalInformation);
  }

  updateLead(updatedLead: Lead) {
    this.leadDetails = updatedLead;
    if(this.leadDetails.customerAddress.state === 'CA' || this.leadDetails.customerAddress.state === 'MD') { 
      this.leadDetails.isAuthRequired = false;
    } else {
      this.leadDetails.isAuthRequired = true;
    }

    if (
      this.leadDetails.applicantDOB !== null &&
      this.leadDetails.applicantDOB !== "" &&
      this.leadDetails.applicantDOB.indexOf("/") == -1
    ) {
      this.leadDetails.applicantDOB = this.changeDateFormat(
        this.leadDetails.applicantDOB
      );
    }

    if (
      this.leadDetails.coApplicantDOB !== null &&
      this.leadDetails.coApplicantDOB !== "" &&
      this.leadDetails.coApplicantDOB.indexOf("/") == -1
    ) {
      this.leadDetails.coApplicantDOB = this.changeDateFormat(
        this.leadDetails.coApplicantDOB
      );
    }

    if (
      this.leadDetails.estCloseDate !== null &&
      this.leadDetails.estCloseDate !== "" &&
      this.leadDetails.estCloseDate.indexOf("/") == -1
    ) {
      this.leadDetails.estCloseDate = this.changeDateFormat(
        this.leadDetails.estCloseDate
      );
    }
    if (this.leadDetails.creditAuth.creditAuthorized?.toUpperCase() === "TRUE" || this.leadDetails.creditAuth.creditAuthorized?.toUpperCase() === "YES") {
      this.leadDetails.isCreditAuthorized = true;
    } else {
      this.leadDetails.isCreditAuthorized = false;
    }
  }

  changeDateFormat(inputDate: string) {
    let year = inputDate.slice(0, 4);
    let month = inputDate.slice(5, 7);
    let day = inputDate.slice(8, 10);
    return month + "/" + day + "/" + year;
  }

  canExitStep1: (MovingDirection) => boolean = (direction) => {
    switch (direction) {
      case MovingDirection.Forwards:
        return this.ValidateStepper(0);
      case MovingDirection.Backwards:
        return true;
    }
  };
  canExitStep2: (MovingDirection) => boolean = (direction) => {
    switch (direction) {
      case MovingDirection.Forwards:
        return this.ValidateStepper(1);
      case MovingDirection.Backwards:
        return true;
    }
  };
  canExitStep3: (MovingDirection) => boolean = (direction) => {
    switch (direction) {
      case MovingDirection.Forwards:
        return this.ValidateStepper(2);
      case MovingDirection.Backwards:
        return true;
    }
  };
  canExitStep4: (MovingDirection) => boolean = (direction) => {
    switch (direction) {
      case MovingDirection.Forwards:
        return this.ValidateStepper(3);
      case MovingDirection.Backwards:
        return true;
    }
  };
  canExitStep5: (MovingDirection) => boolean = (direction) => {
    switch (direction) {
      case MovingDirection.Forwards:
        return this.ValidateStepper(4);
      case MovingDirection.Backwards:
        return true;
    }
  };
  canExitStep6: (MovingDirection) => boolean = (direction) => {
    switch (direction) {
      case MovingDirection.Forwards:
        return this.ValidateStepper(5);
      case MovingDirection.Backwards:
        return true;
    }
  };

  ValidateStepper(stepperID): boolean {
    switch (stepperID) {
      case 0:
        if (this.custInfoChild != undefined) {
          return true;
          // return this.custInfoChild.onSubmit(this.custInfoChild.custForm);
        } else {
          return true;
        }
        break;
      case 1:
        return true;
        break;
      case 2:
        return true;
        break;
      case 3:
        return true;
        break;
      case 4:
        return true;
        break;
      case 5:
        return true;
        break;
    }
    //return true;
  }

  enterStep1() {
    return true;
  }
  enterStep2() {
    return true;
  }
  enterStep3() {
    return true;
  }
  enterStep4() {
    return true;
  }
  enterStep5() {
    return true;
  }
  enterStep6() {
    return true;
  }
}
