<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<div class="container-lg">

    <div class="row mt-4">
        <div class="col-12 text-center">
            <h2 class="color-black">OUR API PROMISE:</h2>
            <h3 class="h5">Easy to follow documentation for quick integration</h3>
        </div>
    </div>
    <div class="row text-center mb-5">
        <div class="col">
            <img src="assets/img/orange-divider.png" alt="">
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <!-- <h3 class="text-center mb-4 h5 color-blue">How Westwood’s API helps close nearly 75,000 homes each year
            </h3> -->
            <div id="intro-para" class="justify-content-center text-center">
                <p><span>
                        How Westwood’s API helps close over 80,000 homes each year
                    </span></p>
            </div>

            <div class="call-outs d-flex flex-row justify-content-center align-items-center">
                <div class="call-out one">
                    <div class="call-out-content">
                        <img src="assets/img/server-icon.svg" alt="Partner Platform">
                        <h4 class="h5">PARTNER <br>PLATFORM</h4>
                    </div><!-- .call-out .link -->
                </div><!-- .call-out -->
                <div class="api-arrows one">
                    <img src="assets/img/arrows.svg" alt="">
                </div>

                <div class="call-out two">
                    <div class="call-out-content">
                        <!-- <img src="assets/img/api-gateway.svg" alt="API Gateway"> -->
                        <svg version="1.1" id="api_gateway_svg" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 200"
                            style="enable-background:new 0 0 200 200;" xml:space="preserve">
                            <g>
                                <g>
                                    <g>
                                        <path d="M115.2,121.2c0-8.9,2.8-17.9,8.4-25c2.7-3.6,6-6.7,9.7-9.2c3.7-2.5,7.9-4.4,12.2-5.6c8.7-2.4,18.2-1.8,26.5,1.6
				c8.4,3.4,15.6,9.7,20.1,17.5c4.6,7.8,6.5,17.2,5.3,26.3c-0.2,1.1-0.3,2.3-0.6,3.4l-0.8,3.3c-0.4,1.1-0.8,2.1-1.1,3.2l-0.3,0.8
				l-0.4,0.8l-0.7,1.5c-2,4.1-4.7,7.8-7.9,11.1c-3.2,3.2-7,5.9-11.1,7.9c-4.1,2-8.5,3.5-13.1,4c-9.1,1.3-18.6-0.6-26.5-5.3l1.5,0
				c-9.5,5.7-20.5,9-31.6,9.5c-5.5,0.2-11.1-0.2-16.6-1.3c-5.4-1.1-10.8-2.9-15.8-5.4l1.7-0.1c-11.8,7.6-27.1,9.5-40.4,5
				c-6.7-2.2-12.8-5.9-18-10.8c-5.1-4.8-9.1-10.9-11.6-17.5c-2.5-6.6-3.6-13.8-3.1-20.8c0.5-7.1,2.7-14,6.2-20.2
				c3.5-6.2,8.5-11.4,14.3-15.5c5.9-4,12.6-6.7,19.6-7.9L39.3,74c5.1-12.3,14-22.9,25-30.2c11-7.3,24.2-11.4,37.4-11.2
				c13.2,0.1,26.2,4.2,37,11.5c10.8,7.4,19.3,17.9,24,30l-0.4,0.2c-2.7-5.8-6.2-11.2-10.3-16c-4.2-4.8-9-8.9-14.4-12.3
				c-10.6-6.8-23.3-10.3-35.8-10c-6.3,0.1-12.5,1.1-18.4,3c-5.9,1.9-11.6,4.7-16.7,8.2c-10.3,7-18.3,17.2-23,28.6L43.2,77l-1.3,0.2
				c-6.3,1.1-12.3,3.6-17.6,7.2c-5.2,3.7-9.6,8.5-12.8,14c-3.2,5.5-5.2,11.7-5.7,18.1c-0.5,6.4,0.4,12.9,2.6,18.9
				c2.2,6,5.7,11.6,10.4,16c4.6,4.5,10.2,7.9,16.3,10c6.1,2.1,12.7,2.8,19.1,2.1c6.4-0.7,12.7-2.9,18.2-6.3l0.9-0.5l0.9,0.4
				c4.7,2.4,9.7,4.1,14.9,5.2c5.2,1.1,10.5,1.6,15.8,1.4c10.6-0.3,21.1-3.3,30.2-8.7l0.8-0.4l0.7,0.4c7.3,4.4,16.2,6.2,24.7,5.1
				c4.3-0.4,8.4-1.8,12.3-3.6c3.9-1.9,7.5-4.3,10.5-7.4c3.1-3,5.6-6.6,7.6-10.4l0.7-1.5l0.4-0.7l0.3-0.8c0.4-1,0.8-2,1.1-3l0.8-3.1
				c0.3-1,0.4-2.1,0.6-3.2c1.2-8.6-0.4-17.5-4.7-25.1c-4.3-7.6-11.1-13.7-19.1-17.1c-8-3.4-17.2-4.1-25.7-1.8
				c-4.2,1.1-8.3,2.9-12,5.3c-3.6,2.4-6.9,5.5-9.6,8.9c-5.5,6.9-8.4,15.7-8.5,24.5H115.2z" />
                                    </g>
                                </g>
                                <g class="api-spin">
                                    <g>
                                        <g>
                                            <path d="M169.1,115.1c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.8-0.4l5.2-5.2l3.3,0.8c0.1,0.7,0.4,1.4,0.9,1.9
					c1.3,1.3,3.4,1.3,4.7,0c1.3-1.3,1.3-3.4,0-4.7c-1.3-1.3-3.4-1.3-4.7,0c-0.1,0.1-0.2,0.3-0.3,0.4l-3.9-1c-0.4-0.2-0.9,0-1.2,0.3
					l-5.7,5.7C168.6,113.8,168.6,114.6,169.1,115.1z" />
                                        </g>
                                        <g>
                                            <path d="M164.5,110c0.3,0,0.6-0.1,0.8-0.4l5.7-5.7c0.3-0.3,0.4-0.7,0.3-1.2l-1-3.9c0.1-0.1,0.3-0.2,0.4-0.3
					c1.3-1.3,1.3-3.4,0-4.7c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.4,0,4.7c0.6,0.6,1.2,0.9,1.9,0.9l0.9,3.3l-5.2,5.2
					c-0.5,0.5-0.5,1.3,0,1.7C163.9,109.9,164.2,110,164.5,110z" />
                                        </g>
                                        <g>
                                            <path d="M143.8,129.4c-0.5-0.5-1.2-0.5-1.7,0l-5.2,5.2l-3.3-0.8c-0.1-0.7-0.4-1.4-1-1.9c-1.3-1.3-3.4-1.3-4.7,0
					c-1.3,1.3-1.3,3.4,0,4.7c1.3,1.3,3.4,1.3,4.7,0c0.1-0.2,0.3-0.3,0.3-0.4l3.9,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.8-0.4
					l5.7-5.7C144.3,130.7,144.3,129.9,143.8,129.4z" />
                                        </g>
                                        <g>
                                            <path d="M147.6,134.9l-5.7,5.7c-0.3,0.3-0.4,0.8-0.3,1.2l1,3.9c-0.1,0.1-0.2,0.2-0.4,0.3c-1.3,1.3-1.3,3.4,0,4.7
					c1.3,1.3,3.4,1.3,4.7,0c1.3-1.3,1.3-3.4,0-4.7c-0.6-0.6-1.2-0.8-1.9-0.9l-0.8-3.3l5.2-5.2c0.5-0.5,0.5-1.2,0-1.7
					C148.8,134.4,148,134.4,147.6,134.9z" />
                                        </g>
                                        <g>
                                            <path d="M170.4,145.7l1-3.9c0.1-0.4,0-0.8-0.3-1.2l-5.7-5.7c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7l5.2,5.2l-0.9,3.3
					c-0.7,0.1-1.4,0.3-1.9,0.9c-1.3,1.3-1.3,3.4,0,4.7c1.3,1.3,3.4,1.3,4.7,0c1.3-1.3,1.3-3.4,0-4.7
					C170.6,145.8,170.5,145.7,170.4,145.7z" />
                                        </g>
                                        <g>
                                            <path d="M180.1,131.9c-0.6,0.6-0.8,1.2-0.9,1.9l-3.3,0.8l-5.2-5.2c-0.5-0.5-1.2-0.5-1.7,0s-0.5,1.2,0,1.7l5.7,5.7
					c0.3,0.3,0.6,0.4,0.9,0.4c0.1,0,0.2,0,0.3-0.1l3.9-1c0.1,0.1,0.2,0.2,0.3,0.4c1.3,1.3,3.4,1.3,4.7,0c1.3-1.3,1.3-3.4,0-4.7
					C183.6,130.6,181.5,130.6,180.1,131.9z" />
                                        </g>
                                        <g>
                                            <path d="M142.5,98.9l-1,3.9c-0.1,0.4,0,0.8,0.3,1.2l5.7,5.7c0.2,0.3,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.9-0.4
					c0.5-0.5,0.5-1.2,0-1.7l-5.2-5.1l0.8-3.3c0.7-0.1,1.5-0.4,2-0.9c1.3-1.3,1.3-3.4,0-4.7c-1.4-1.3-3.4-1.3-4.7,0
					c-1.3,1.3-1.3,3.4,0,4.7C142.3,98.7,142.4,98.8,142.5,98.9z" />
                                        </g>
                                        <g>
                                            <path d="M132.7,112.7c0.6-0.6,0.9-1.2,1-1.9l3.3-0.8l5.2,5.2c0.2,0.3,0.6,0.4,0.8,0.4c0.3,0,0.6-0.1,0.9-0.4
					c0.4-0.5,0.4-1.2,0-1.7l-5.7-5.7c-0.3-0.3-0.7-0.4-1.2-0.3l-3.9,1c-0.1-0.1-0.2-0.3-0.3-0.4c-1.3-1.3-3.4-1.3-4.7,0
					c-1.4,1.4-1.4,3.5-0.1,4.8C129.4,113.9,131.4,113.9,132.7,112.7z" />
                                        </g>
                                    </g>
                                    <g>
                                        <path
                                            d="M167.2,120.4L165,120c-0.2-0.7-0.3-0.8-0.6-1.5l1.3-1.8c0.3-0.5,0.3-1.1-0.2-1.6l-1.8-1.7c-0.4-0.4-1.1-0.5-1.5-0.2
                        l-1.8,1.4c-0.7-0.3-0.9-0.4-1.5-0.6l-0.2-2.4c-0.1-0.6-0.6-1.1-1.2-1.1H155c-0.6,0-1.1,0.4-1.2,1.1l-0.3,2.2
                        c-0.7,0.3-0.8,0.4-1.5,0.6l-1.8-1.3c-0.5-0.4-1.1-0.3-1.6,0.1l-1.7,1.8c-0.5,0.5-0.5,1.1-0.2,1.6l1.4,1.8
                        c-0.3,0.7-0.4,0.8-0.6,1.5l-2.3,0.3c-0.6,0.1-1.1,0.6-1,1.2v2.5c0,0.6,0.4,1.1,1.1,1.2l2.2,0.3c0.3,0.7,0.4,0.9,0.6,1.5l-1.4,1.8
                        c-0.3,0.5-0.3,1.1,0.1,1.5l1.8,1.8c0.4,0.4,1.1,0.5,1.6,0.1l1.8-1.3c0.7,0.3,0.8,0.4,1.5,0.6l0.3,2.2c0.1,0.6,0.6,1.1,1.2,1.1h2.5
                        c0.6,0,1.1-0.4,1.2-1l0.3-2.2c0.7-0.2,0.9-0.3,1.5-0.6l1.8,1.4c0.6,0.3,1.2,0.2,1.6-0.2l1.8-1.7c0.4-0.4,0.5-1.1,0.2-1.6l-1.4-1.8
                        c0.3-0.7,0.4-0.8,0.6-1.5l2.2-0.3c0.6-0.1,1.1-0.6,1.1-1.2v-2.5C168.3,120.9,167.8,120.4,167.2,120.4z M156.2,128.2
                        c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4C161.7,125.7,159.2,128.2,156.2,128.2z" />
                                    </g>

                                </g>
                            </g>
                        </svg>
                        <h4 class="h5">API <br>GATEWAY</h4>
                    </div><!-- .call-out .link -->
                </div><!-- .call-out -->
                <div class="api-arrows two">
                    <img src="assets/img/arrows.svg" alt="">
                </div>

                <div class="call-out three">
                    <div class="call-out-content">
                        <img src="assets/img/server-icon.svg" alt="Westwood Platform">
                        <h4 class="h5">WESTWOOD <br>PLATFORM</h4>
                    </div><!-- .call-out .link -->
                </div><!-- .call-out -->

            </div><!-- .call-outs -->

        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <img src="assets/img/api-divider.svg" class="api-divider mx-auto d-block mb-4" alt="">
        </div>
    </div>

    <div class="row" style="justify-content: center;">
        <div class="col-md-3">
            <div class="api-info">
                <div class="api-title d-flex justify-content-center align-items-center">
                    <img src="assets/img/check-mark.svg" class="display-inline h-auto" alt="Create a lead">
                    <h5 class="upper-lower-case">Create a lead</h5>
                </div>
                <div class="api-content text-center mt-3">
                    <img src="assets/img/create-a-lead.svg" alt="Create a lead">
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="api-info">
                <div class="api-title d-flex justify-content-center align-items-center">
                    <img src="assets/img/check-mark.svg" class="display-inline h-auto" alt="Send a quote">
                    <h5 class="upper-lower-case">Send a quote</h5>
                </div>
                <div class="api-content text-center mt-3">
                    <img src="assets/img/send-a-quote.svg" alt="Send a quote">
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="api-info">
                <div class="api-title d-flex justify-content-center align-items-center">
                    <img src="assets/img/check-mark.svg" class="display-inline h-auto" alt="Order a Policy">
                    <h5 class="upper-lower-case">Order a policy</h5>
                </div>
                <div class="api-content text-center mt-3">
                    <img src="assets/img/order-a-policy.svg" alt="Order a Policy">
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 pt-4 top-border-element">
        <div class="col-12 text-center d-none d-sm-block">
            <h2 class="my-4 color-black upper-lower-case">Together, let’s create a better<br> homebuying experience</h2>
        </div>
        <div class="col-12 text-center d-sm-none">
            <h2 class="my-4 color-black upper-lower-case">Together, let’s create a better homebuying experience</h2>
        </div>
    </div>
    <div class="row text-center">
        <div class="col">
            <img src="https://www.westwoodinsurance.com/assets/img/orange-divider.png" alt="">
        </div>
    </div>

    <div class="row no-gutters justify-content-center mt-4">
        <div class="col-12" id="partner-div">
            <div class="row no-gutters">
                <div class="col-6 col-md-3">
                    <div class="highlight-box highlight-secondary">
                        <div class="box-content">
                            <div
                                class="text-featured d-flex flex-column justify-content-center align-items-center align-self-center">
                                <span class="main">40<sup>+</sup></span>
                                <span class="secondary">Insurance Companies</span>
                            </div>
                            <p class="mb-1 mt-3 text-2">With whom we partner<br>
                                for the right coverage<br>
                                and price</p>
                        </div>
                        <div class="arrow-down"></div>
                        <div class="highlight-shadow"></div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="highlight-box ">
                        <div class="box-content">
                            <div
                                class="text-featured d-flex flex-column justify-content-center align-items-center align-self-center">
                                <span class="main">45</span>
                                <span class="secondary">Seconds</span>
                            </div>
                            <p class="mb-1 mt-3 text-2">To receive a <br>personalized quote</p>
                        </div>
                        <div class="arrow-down"></div>
                        <div class="highlight-shadow"></div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="highlight-box highlight-tertiary">
                        <div class="box-content">
                            <div
                                class="text-featured d-flex flex-column justify-content-center align-items-center align-self-center">
                                <span class="main">50</span>
                                <span class="secondary">States</span>
                            </div>
                            <p class="mb-1 mt-3 text-2">In which we’re<br>
                                licensed for<br>
                                nationwide coverage</p>
                        </div>
                        <div class="arrow-down"></div>
                        <div class="highlight-shadow"></div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="highlight-box highlight-quaternary">
                        <div class="box-content">
                            <div
                                class="text-featured d-flex flex-column justify-content-center align-items-center align-self-center">
                                <span class="main">9.6</span>
                                <span class="secondary">Customer Satisfaction</span>
                            </div>
                            <p class="mb-1 mt-3 text-2">To create brand<br>
                                promoters and referral<br>
                                business</p>
                        </div>
                        <div class="arrow-down"></div>
                        <div class="highlight-shadow"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-4 pt-4 top-border-element">
        <div class="col-12 text-center">
            <h2 class="my-4 color-black">Partner With Us</h2>
        </div>
    </div>
    <div class="row text-center">
        <div class="col">
            <img src="https://www.westwoodinsurance.com/assets/img/orange-divider.png" alt="">
        </div>
    </div>

    <div class="row pb-5">
        <div class="offset-sm-0 col-sm-6 offset-md-2 col-md-4">
            <div class="text-center">
                <img src="assets/img/partner-with-us.png" width="80" alt="Partner With Us">
                <p class="font-weight-bold pt-3"><a style="text-decoration: underline;" routerLink="/partners"
                        class="emailUs">LEARN MORE</a> about
                    the benefits<br> of partnering with Westwood</p>
            </div>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="text-center">
                <img src="assets/img/contact-us.png" width="80" alt="Contact us">
                <p class="font-weight-bold pt-3">Contact us at<br> <a href="mailto:Partner@Westwoodins.com"
                        class="emailUs"><span>Partner@WestwoodIns.com</span></a>
                </p>


            </div>
        </div>
    </div>

</div>

<!-- Pop up to request details -->
<app-pop-uprequest-details></app-pop-uprequest-details>