import { Component, OnInit,ViewChild, AfterViewInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants';
import { WebsiteAlertInfo } from 'src/app/model/websiteAlertInfo';
import { DataService } from 'src/app/service/data.service';
import { HomeService } from '../../service/home.service';
import { AboutUsService } from '../../service/about-us.service';
import { Testimonial } from '../../model/testimonial';
import { PopUpVideoContainerComponent } from '../../shared/pop-up-video-container/pop-up-video-container.component';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  const: Constants;  
  testomonialData: Array<Testimonial> = [];
  alertInfoData:Array <WebsiteAlertInfo>=[]; 
  constructor(  
    public commonService: CommonService, 
    public constantObj: Constants,
    public dataService: DataService,
    public homeService: HomeService, 
    public aboutUsService: AboutUsService,
    public router: Router
    ) {
    this.const=constantObj;
  }
  //@ViewChild(ModelPopUpOtherDomainComponent) child1: ModelPopUpOtherDomainComponent;
  @ViewChild(PopUpVideoContainerComponent) child: PopUpVideoContainerComponent;
  isLoading = false;

  ngOnInit() {  
    window.location.href = this.dataService.wpWebsiteUrl;
    // this.getTestimonial();
    // this.getWebsiteAlertInfo();  
  } 
  getTestimonial() {
    this.isLoading = true;
    this.aboutUsService.getTestimonial().subscribe((data: Array<Testimonial>) => {
      this.testomonialData = data;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
    });
  }

  getWebsiteAlertInfo() { 
    this.homeService.getWebsiteAlertInfo().subscribe((data:Array<WebsiteAlertInfo>)=> { 
      this.alertInfoData = data;  
    },err => { 
      this.dataService.handleError(err); 
    });
  }

}
