export class Address {
    public streetAddress: string;
    public line2: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public county: string;
    constructor() {
        this.streetAddress='';
        this.line2='';
        this.city='';
        this.state='';
        this.zipCode='';
        this.county='';
    }
}
