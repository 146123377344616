import { LoanInformation } from "./loanInformation";
import { SupplementalInformation } from "./supplementalInformation";

export class PropertyUnderwriting
{
    public loanInformation:LoanInformation;
    public supplementalInformation:SupplementalInformation;
    constructor()
    {
        this.loanInformation=new LoanInformation();
        this.supplementalInformation=new SupplementalInformation();
    }
}