<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-renters" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Renters Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none d-none">
    <div class="container pt-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-md-9 text-center col-xs-9">
                <p>
                    Whether you are renting an apartment, duplex or home, it is important to protect your personal
                    belongings. Your landlord’s policy provides protection only for the building itself and does not
                    include your possessions. <br><Br>
                    Westwood can help you find a renters insurance policy that fits your needs and price range.<span>
                        To make things even easier for you, we may be able to help with the cost of your deposit via a
                        surety bond. </span>
                </p>
                <span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
                    questions regarding Renters Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
            </div>
        </div>
    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- T I T L E  -->

<section>
    <div class="container pt-3 pb-5">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center"> Why Renters Insurance? </h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 pb-5">
                <img src="assets/img/page-body-img/shutterstock_1414418567.png" style="width: 100%;"
                    alt="Happy couple in new home" />
            </div>
            <div class="col">

                Never underestimate the value of your possessions &mdash; you’ll be surprised how much it would cost to
                replace them! It’s also important not to overlook your personal liability if a friend or guest is
                injured during a visit to your apartment or rental unit.
                <br><br>
                Therefore, it makes sense to purchase a Renters policy &mdash; and it’s more affordable than you may
                think! It’s a small investment for your peace of mind.
            </div>
        </div>
    </div>
</section>

<!-- // T I T L E   -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section class="bg-secondary" id="whats-included2">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">What’s included in Renters Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>

        <div class="row pt-2" id="a-bullet-list">
            <ul>
                <li>Replacement or repairs to personal belongings such as furniture, clothes, luggage, electronics and
                    more that are damaged or lost due to an event or circumstance covered by your policy.</li>
                <li>Medical expenses for guests who are injured at your rental unit.
                </li>
                <li>Financial help for legal representation in a lawsuit against you related to an injury or loss that
                    occurred in your rental unit.</li>
                <li>Additional living expenses, up to the policy limit, if your rental unit is not inhabitable due to a
                    covered event or circumstance.</li>
            </ul>
        </div>

        <!-- O N L I N E   Q U O T E -->



        <!-- // O N L I N E   Q U O T E -->
    </div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->


<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->