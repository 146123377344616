<!-- START OF FORM -->
<form (ngSubmit)="onSubmit(underWriting)" #underWriting="ngForm" autocomplete="off" *ngIf="leadDetails.quoteResult.status">
    <section id="rtq-form" class="pb-5">
        <div class="container-xl">
            <img src="assets/img/PRELOADER.gif" class="preloader"
                *ngIf="isLoading" />
            <div class="row justify-content-center">
                <div class="col-md-12 col-xs-12">
                    <h3 class="page-header-text">The Finish Line</h3>
                    <div class="card card-body bg-secondary" *ngIf="!isRenters">
                        <h3 *ngIf="!isRenters">Loan Information</h3>
                        <div class="row pt-1">
                            <div class="col">
                                <label for="lblLoanTransaction" class="fw-500">Are you in the process of completing a loan
                                    transaction?<span class="text-danger"> *</span></label>
                                <div>
                                    <section>
                                        <div>
                                            <label class="btn ww-radio-button mr-2"
                                                [ngClass]="{'ww-radio-button-selected': leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction}">
                                                <input type="radio" name="options" id="havingActiveTransaction"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction"
                                                    [value]="true"
                                                    [ngClass]="{'ww-radio-button-selected': leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction}"
                                                    (click)="resetForm(underWriting, 'Y');"
                                                    #havingActiveTransaction="ngModel" name="havingActiveTransaction"
                                                    ngDefaultControl class="btn ww-radio-button">Yes</label>
                                            <label class="btn ww-radio-button"
                                                [ngClass]="{'ww-radio-button-selected': !leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction}">
                                                <input type="radio" name="options" id="havingActiveTransaction"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction"
                                                    [value]="false"
                                                    [ngClass]="{'ww-radio-button-selected': !leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction}"
                                                    (click)="resetForm(underWriting, 'N');"
                                                    #havingActiveTransaction="ngModel" name="havingActiveTransaction"
                                                    ngDefaultControl class="btn ww-radio-button">No</label>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-7 col-lg-5">
                                                <label
                                                    for="lblLoanTransaction" class="fw-500">{{!leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction ? "What was the original loan amount on the \n home?": "What is the approximate loan amount on the \n home going to be?" }}<span class="text-danger"> *</span>
                                                </label>
                                                <input type="text" class="form-control loanWidth" name="txtLoanAmount"
                                                    (blur)="numberWithCommas($event)" (keypress)="_keyUp($event)" required
                                                    autocomplete="off"
                                                    [min]="0"
                                                    [max]="9999999"
                                                    currencyMask
                                                    [options]="{ align: 'left',
                                                                    allowNegative: false,
                                                                    allowZero: false,
                                                                    decimal: '.',
                                                                    precision: 0,
                                                                    prefix: '',
                                                                    suffix: '',
                                                                    thousands: ',',
                                                                    nullable: false,
                                                                    min: null,
                                                                    max: 9999999
                                                        }"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.amount"
                                                    #amount="ngModel">
                                                <label *ngIf="(amount.hasError('required') && (amount.touched || underWriting.submitted))"
                                                    class="error-message">Loan Amount is required.
                                                </label>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="mt-3">
                                        <h3 *ngIf="!isRenters && leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction"
                                            class="pt-2">Mortgage / Lender Information</h3>
                                        <p class="fw-500"
                                            *ngIf="leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction">
                                            We'll work directly with your lender when it comes time to issue your
                                            policy.</p>
                                        <div class="row">
                                            <div  class="col-sm-12 col-md-7 col-lg-5">
                                                <label for="lblLoanTransaction" class="fw-500">
                                                    {{!leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction
                                                    ? "What is the name of your mortgage company?": "Mortgage Company"
                                                    }}
                                                </label>
                                                <input type="text" class="form-control" name="txtmortgageCompany"
                                                    placeholder="Mortgage Company"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.mortgageName"
                                                    #mortgageName="ngModel" maxlength="36">
                                            </div>
                                        </div>
                                        <div class="row pt-3"
                                            *ngIf="leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction">
                                            <div class="col-sm-12 col-md-7 col-lg-5">
                                                <label class="fw-500" for="lblLoanOfficerName"> Loan Officer / Primary Contact</label>
                                                <input type="text" class="form-control loanWidth"
                                                    name="txtloanOfficerName" placeholder="Loan Officer Name"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.officerName"
                                                    #officerName="ngModel" maxlength="38">
                                            </div>
                                        </div>
                                        <div class="row pt-3"
                                            *ngIf="leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction">
                                            <div class="col-sm-12 col-md-7 col-lg-5">
                                                <label class="fw-500" for="lblLoanOfficerPhone"> Contact Phone Number </label>
                                                <input type="text" class="form-control loanWidth" (blur)="checkPhone($event)"
                                                    name="txtloanOfficerPhone" placeholder="(999)999-9999" mask="(000)000-0000"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.officerPhone"
                                                    #officerPhone="ngModel" maxlength="25">
                                            </div>
                                        </div>

                                        <div class="row pt-3"
                                            *ngIf="leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction">
                                            <div class="col-sm-12 col-md-7 col-lg-5">
                                                <label class="fw-500" for="lblLoanOfficerPhone">Contact Email</label>
                                                <input type="text" class="form-control loanWidth"
                                                    name="txtloanOfficerEmail"
                                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.officerEmail"
                                                    name="loanOfficerEmail" placeholder="Loan Officer's Email"
                                                    #loanProcessorEmail="ngModel" emailValidator maxlength="100">
                                                <label
                                                    *ngIf="(loanProcessorEmail.hasError('emailValidator') && loanProcessorEmail.touched )"
                                                    class="error-message text-nowrap">{{constantObj.invalidFormatErrorMessage}}
                                                </label>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-body bg-secondary mt-2">
                        
                        <h3>
                            <i class="text-success">
                                <fa-icon icon="leaf"></fa-icon>
                            </i>
                            Electronic Communications
                        </h3>
                        <p class="fw-500 text-justify">
                            Future privacy notices may be sent to me by electronic mail at the email address I have
                            provided
                            unless and until I notify Westwood otherwise. I would prefer to receive policy
                            documents, disclosures and other communications electronically from the insurance carrier
                            that issues my policy, and consent to Westwood communicating this preference to
                            the insurance carrier.
                        </p>
                        <div class="row">
                            <div  class="col-sm-12 col-md-2 col-lg-2 mt-auto mb-0">
                                <label class="fw-500">
                                    <input type="checkbox" [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.eddFlag" class="ChKHaveBiteHistory"
                                        name="eddFlag" [checked]="leadDetails.propertyUnderwriting.loanInformation.eddFlag"
                                        (ngModelChange)="hideorShowEDDEmail()" />
                                    I Agree
                                </label>
                            </div>
                            <div  class="col-sm-12 col-md-6 col-lg-4">
                                <label class="fw-500">Email <span class="text-danger" *ngIf="leadDetails.propertyUnderwriting.loanInformation.eddFlag"> *</span></label>
                                <select class="form-control" name="eddEmail" #eddEmail="ngModel" 
                                    [disabled]="!leadDetails.propertyUnderwriting.loanInformation.eddFlag" 
                                    [required]="leadDetails.propertyUnderwriting.loanInformation.eddFlag"  
                                    [(ngModel)]="leadDetails.propertyUnderwriting.loanInformation.eddEmail">
                                    <option [value]="leadDetails.email">{{leadDetails.email}}</option>
                                    <option [value]="leadDetails.coAppEmail">{{leadDetails.coAppEmail}}</option>
                                </select>
                                <label *ngIf="((underWriting.submitted && eddEmail.hasError('required')) || (eddEmail.touched && eddEmail.hasError('required')))"
                                    class="error-message text-nowrap">Please provide a valid email.</label>
                            </div>
                        </div>
                    </div>

                    <div class="card card-body bg-secondary mt-2">
                        <h3>Disclosure</h3>
                        <p class="text-justify fw-500">
                            Any person who knowingly and with intent to defraud any insurance company or
                            another person files an application for insurance or statement of claim
                            containing
                            any materially false information, or conceals for the purpose of misleading
                            information
                            concerning any fact material thereto, commits a fraudulent insurance act, which
                            is
                            a
                            crime and subjects the person to criminal and civil penalties.
                        </p>
                        <hr class="mt-1" />
                        <p class="text-justify mt-1">
                            <b>Applicants Statement:</b> <br />
                            I have read the above. I declare that the information provided is true, complete
                            and correct to the best of my knowledge and belief. This information is being offered to the
                            company
                            as an inducement to issue the policy for which I am applying, and is subject to
                            Westwood Insurance Agency’s 
                            <a (click)="navigateToUrl('privacy-policy')" class="cursor-pointer" target="_blank"><u>privacy policy</u></a>
                            available <u> <a (click)="navigateToUrl('privacy-policy')" class="cursor-pointer" target="_blank">here</a></u>.
                        </p>

                        <div class="row">
                            <div class="col-sm-12 col-md-2 col-lg-2 mt-auto mb-0">
                                <label>
                                    <input type="checkbox"
                                        [(ngModel)]="leadDetails.applicantAgreed"
                                        class="ChKHaveBiteHistory" name="eddFlag" [required]="true" #applicantAgreed="ngModel"
                                        [checked]="leadDetails.applicantAgreed"
                                        /> I Agree <span class="text-danger"> *</span>
                                </label>
                                <div class="error-message text-nowrap" style="display: block;" *ngIf="(underWriting.submitted || applicantAgreed.touched ) && (applicantAgreed.hasError('required'))">
                                    This is required.
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7 col-lg-5">
                                <label class="fw-500">Enter your full name for your signature <span class="text-danger"> *</span></label>
                                <input class="form-control"
                                    [required]="true"
                                    [(ngModel)]="leadDetails.fraudStatementApplicantName"
                                    name="fullNameForSignature" #fullNameForSignature="ngModel" />
                                <label
                                    *ngIf="((underWriting.submitted || fullNameForSignature.touched) && fullNameForSignature.hasError('required'))"
                                    class="error-message text-nowrap">Please provide a valid name.</label>
                            </div>
                        </div>
                        <div class="row pt-3 MNState"
                            *ngIf="leadDetails.isMailingAddressSame && leadDetails.customerAddress.state=='MN'">
                            <div class="col">
                                <p class="text-justify">
                                    <b>Notice to MN Applicants Only.</b>
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3 MNState"
                            *ngIf="leadDetails.isMailingAddressSame && leadDetails.customerAddress.state=='MN'">
                            <div class="col">
                                <p class="text-justify font-weight-bold">
                                    THE INSURER MAY ELECT TO CANCEL COVERAGE AT ANY TIME DURING THE FIRST 59 DAYS
                                    FOLLOWING ISSUANCE OF THE
                                    COVERAGE FOR ANY REASON WHICH IS NOT SPECIFICALLY PROHIBITED BY STATUTE.
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3 OHState"
                            *ngIf="leadDetails.isMailingAddressSame && leadDetails.customerAddress.state=='OH'">
                            <div class="col">
                                <p class="text-justify">
                                    <b> Notice to OH Applicants Only. </b>
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3 OHState"
                            *ngIf="leadDetails.isMailingAddressSame && leadDetails.customerAddress.state=='OH'">
                            <div class="col">
                                <p class="text-justify font-weight-bold">
                                    ANY PERSON WHO, WITH INTENT TO DEFRAUD OR KNOWING THAT HE IS FACILITATING A
                                    FRAUD AGAINST THE INSURER,
                                    SUBMITS AN APPLICATION OR FILES A CLAIM CONTAINING A FALSE OR DECEPTIVE
                                    STATEMENT IS GUILTY OF INSURANCE
                                    FRAUD.
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3 WAState"
                            *ngIf="leadDetails.isMailingAddressSame && leadDetails.customerAddress.state=='WA'">
                            <div class="col">
                                <p class="text-justify">
                                    <b> Notice to WA applicants only. </b>
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3 WAState"
                            *ngIf="leadDetails.isMailingAddressSame && leadDetails.customerAddress.state=='WA'">
                            <div class="col">
                                <p class="text-justify font-weight-bold">
                                    IT IS A CRIME TO KNOWINGLY PROVIDE FALSE, INCOMPLETE, OR MISLEADING INFORMATION
                                    TO AN INSURANCE COMPANY FOR
                                    THE PURPOSE OF DEFRAUDING THE COMPANY. PENALTIES INCLUDE IMPRISONMENT, FINES,
                                    AND DENIAL OF INSURANCE
                                    BENEFITS.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="p-2">
                        <p class="required-p">*These fields are required.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="bg-white d-sm-block mt-3 mb-5">
        <div class="container-xl">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn ww-button" aria-label="Go Back to Previous Screen" awPreviousStep tabindex="-1">
                        <i>
                            <fa-icon icon="chevron-left"></fa-icon><fa-icon icon="chevron-left"></fa-icon>
                        </i>
                        Previous
                    </button>
                </div>
                <div class="col text-right">
                    <button type="submit" class="btn ww-button" aria-label="Go to Next Step">
                        Next <i>
                            <fa-icon icon="chevron-right"></fa-icon><fa-icon icon="chevron-right"></fa-icon>
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <app-model-popup (savePageDetails)="savePageDetails()"></app-model-popup>
</form>
<!-- //END OF FORM -->