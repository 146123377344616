<!-- M O B I L E   M E N U   T R I G G E R -->
<div class="mh-head sticky-top d-xl-none container-fluid">
    <div class="row d-flex" style="align-items: center;" *ngIf="screenHeight > screenWidth">
        <div class="col-9 m-0 p-0 ml-3 my-3 pb-0 mb-2">
            <a  (click)="navigateToUrl('')" class="pb-0 pl-4">
                <img class="img-fluid" id="headerLogoMobile" data-toggle="modal" src="assets/img/logos/logoheader.svg"
                    style="height:68px; margin: 10px 0px; padding-left: 10px;">
            </a>
        </div>
        <div class="col p-1 pt-3">
            <a id="MobMenu" data-toggle="modal" data-target="#ww-mobile-menu" class="fa fa-bars mm-menu-bars pr-2 pl-4 pt-2"></a>
            <a data-target="#ww-mobile-menu" class="fa fa-close mm-menu-bars"></a>
        </div>
    </div>
    <div class="row d-flex" style="align-items: center;" *ngIf="screenHeight < screenWidth">
        <div class="col-1"></div>
        <div class="col-8 m-0 p-0 ml-3 my-2 pb-0 mb-2">
            <a  (click)="navigateToUrl('')" class="pb-0 pl-4">
                <img class="img-fluid" id="headerLogoMobile" data-toggle="modal" src="assets/img/logos/logoheader.svg"
                    style="height:70px; margin: 10px 0px; padding-left: 10px;">
            </a>
        </div>
        <div class="col p-0 pt-3">
            <a id="MobMenu" data-toggle="modal" data-target="#ww-mobile-menu" class="fa fa-bars mm-menu-bars pt-2"></a>
            <a data-target="#ww-mobile-menu" class="fa fa-close mm-menu-bars"></a>
        </div>
    </div>
</div>

<!-- //  M O B I L E   M E N U   T R I G G E R   -->


<!-- M O B I L E   M E N U  -->
<div class="modal col-12 col-md-6 offset-md-6" data-backdrop="false" id="ww-mobile-menu" tabindex="-1" role="dialog" style="background-color: #004c97;" (click)="handleMobileMenuClick($event)">
    <div class="container-xl ww-mobile-menu-container">
        <div class="row">
            <div style="width: 200px; object-fit: contain; margin-top: 40px; margin-left: 15px;">
                <a (click)="navigateToUrl('')" style="padding-right: 0.8rem;">
                    <img id="headerLogo" style="height: 100%; width: 100%;" src="https://stg.westwoodinsurance.com/wp-content/uploads/sites/2/2022/06/Westwood-Insurance-Logo_White_Black-Logo.png?w=500" alt="Westwood Insurance Logo">
                </a>
            </div>
            <div class="col text-right m-0 p-0 pt-2">
                <i class="far fa-times mr-1" data-dismiss="modal" aria-label="Close" style="font-size: 50px; font-weight: 600px;"></i>
            </div>
        </div>
        <div id="ww-mob-header" class="row mb-2 pt-1">
			<div class="col-sm-12 col-md-12 col-lg-12 pt-4">
				<ul id="topHeaderMobNav" class="nav flex-column">
					<li class="nav-item pb-2">
						<a class="header-mob-menu-item default-header-menu-item" (click)="navigateToUrl('about')">About &nbsp;</a>
                        <i (click)="collapseSideMenu('about')" [ngClass]="selectedMenu === 'about' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'about'">
							<ul class="nav flex-column">
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" 
										(click)="navigateToUrl('about/leadership-team/')">Leadership Team</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" (click)="navigateToUrl('careers/')">Careers</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" 
										(click)="navigateToUrl('about/resource-center/')">Resource Center</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="header-mob-menu-item default-header-menu-item" (click)="navigateToUrl('products')">Products &nbsp;</a>
                        <i (click)="collapseSideMenu('products')" [ngClass]="selectedMenu === 'products' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'products'">
							<ul class="nav flex-column">
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" 
										(click)="navigateToUrl('products/homeowners-insurance/')">Homeowners</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" 
										(click)="navigateToUrl('products/renters-insurance/')">Renters</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" 
										(click)="navigateToUrl('products/auto-insurance/')">Auto</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" 
										(click)="navigateToUrl('products/small-business-insurance/')">Small Business</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" (click)="navigateToUrl('products/')">All Products</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="header-mob-menu-item" (click)="selectMenuHead('customers')" [ngClass]="selectedMobileMenuHeaderCurrent === 'customers' ? 'selected-header-menu' : 'default-header-menu-item'">Customers</a> &nbsp;
                            <i (click)="collapseSideMenu('customers')" [ngClass]="selectedMenu === 'customers' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu === 'customers'">
							<ul class="nav flex-column">
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" (click)="navigateToUrl('claims/')">Claims</a>
								</li>
                                <li class="nav-item mob-head-nav-item">
                                    <a  routerLink="/current-customers/make-a-payment" (click)="selectSubMenu('make-a-payment')" [ngClass]="selectedSubMenu === 'make-a-payment' ? 'selected-header-sub-menu' : 'mob-header-sub-menu-item'">Make a payment</a>
                                </li>
                                <li class="nav-item mob-head-nav-item">
									<a routerLink='/current-customers/request-policy-changes' (click)="selectSubMenu('request-policy-change')" [ngClass]="selectedSubMenu === 'request-policy-change' ? 'selected-header-sub-menu' : 'mob-header-sub-menu-item'">Request Policy Changes</a>
								</li>
                                <li class="nav-item mob-head-nav-item">
                                    <a routerLink="/current-customers/my-policy-documents" (click)="selectSubMenu('my-policy-documents')" [ngClass]="selectedSubMenu === 'my-policy-documents' ? 'selected-header-sub-menu' : 'mob-header-sub-menu-item'">My Policy Documents</a>
                                </li>
                                <li class="nav-item mob-head-nav-item">
									<a routerLink="/current-customers/lender-requests-changes" (click)="selectSubMenu('lender-requests-changes')" [ngClass]="selectedSubMenu === 'lender-requests-changes' ? 'selected-header-sub-menu' : 'mob-header-sub-menu-item'">LENDER REQUESTS/CHANGES</a>
								</li>
                                <li class="nav-item mob-head-nav-item">
									<a routerLink="/current-customers/opt-in-for-text-messages" (click)="selectSubMenu('opt-in-for-text-messages')" [ngClass]="selectedSubMenu === 'opt-in-for-text-messages' ? 'selected-header-sub-menu' : 'mob-header-sub-menu-item'">Opt-in For Text Messages</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="header-mob-menu-item" (click)="selectMenuHead('partners')"  [ngClass]="selectedMobileMenuHeaderCurrent === 'partners' ? 'selected-header-menu' : 'default-header-menu-item'">Partners</a> &nbsp;
                            <i (click)="collapseSideMenu('partners')" [ngClass]="selectedMenu === 'partners' ? 'fas fa-caret-down fa-lg' : 'fas fa-caret-right fa-lg'"></i>
						<div @hideShow *ngIf="selectedMenu == 'partners'">
							<ul class="nav flex-column">
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" (click)="navigateToUrl('partners/')">Builders</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" (click)="navigateToUrl('partners-lenders/')">Lenders</a>
								</li>
								<li class="nav-item mob-head-nav-item">
									<a class="mob-header-sub-menu-item" style="color: #ffffff !important;" (click)="navigateToUrl('partners-api/')">API</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item pb-2">
						<a class="mob-head-nav-item header-mob-menu-item default-header-menu-item" (click)="navigateToUrl('news/')">News</a>
					</li>
					<li class="nav-item pb-2">
						<a class="mob-head-nav-item header-mob-menu-item default-header-menu-item" (click)="navigateToUrl('contact-us/')">Contact Us</a>
					</li>
				</ul>
			</div>
		</div>
        <div class="col-sm-12 col-md-12 col-lg-12 pt-4 m-0 p-0" style="padding-bottom: 60px !important;">
            <a class="btn ww-button-title" style="font-size: 18px; font-weight: 600; padding-top: 10px; padding-bottom: 10px; padding-left: 20px; padding-right: 20px; border-radius: 10px;" (click)="navigateToUrl('get-an-insurance-quote/')">Get A Quote</a>
        </div>
    </div>
</div>




<nav id="mmenu">
    <ul id="panel-menu">
        <li>
            <a routerLink="/">Home</a>
        </li>
        <li>
            <a routerLink="/about-us" class="pl-5">About</a>
            <ul>
                <li><a routerLink="/about-us/leadership-team">Leadership Team</a></li>
                <li><a routerLink="/about-us/insurance-licensing">Career</a></li>
                <li><a routerLink="/about-us/resource-center">Resource Center</a></li>
            </ul>
        </li>
        <li>
            <a routerLink="/products" class="pl-5">Products</a>
            <ul>
                <li><a routerLink="/products/homeowners">Homeowners</a></li>
                <li><a routerLink="/products/condo">Condo</a></li>
                <li><a routerLink="/products/renters">Renters</a></li>
                <li><a routerLink="/products/auto">Auto</a></li>
                <li><a routerLink="/products/flood">Flood</a></li>
                <li><a routerLink="/products/earthquake">Earthquake</a></li>
                <li><a routerLink="/products/umbrella">Umbrella</a></li>
                <li><a routerLink="/products/jewelry-and-valuables">Jewelry & Valuables</a></li>
                <li><a routerLink="/products/golf-cart">Golf Cart</a></li>
                <li><a routerLink="/products/motorcycle">Motorcycle</a></li>
                <li><a routerLink="/products/pet-insurance">Pet</a></li>
                <li><a routerLink="/products/travel-insurance">Travel</a></li>
                <li><a routerLink="/products/event">Event</a></li>
                <li><a routerLink="/products/recreational-vehicle-insurance">Recreational Vehicles</a></li>
                <li><a routerLink="/products/small-business">Small Business</a></li>
                <!-- <li><a routerLink="/products/mobile-phone-insurance">Phone</a></li>   -->
            </ul>
        </li>
        <li>
            <a routerLink="/current-customers" class="pl-5">Customers</a>
            <ul>
                <li><a routerLink="/current-customers/claims">Claims</a></li>
                <li><a routerLink="/current-customers/make-a-payment">Make a payment</a></li>
                <li><a routerLink="/current-customers/request-policy-changes">Request Policy Changes</a></li>
                <li><a routerLink="/current-customers/my-policy-documents">My Policy Documents</a></li>
                <li><a routerLink="/current-customers/lender-requests-changes">Lender Requests/Changes</a></li>
                <li><a routerLink="/current-customers/opt-in-for-text-messages">Opt-in For Text Messages</a></li>
            </ul>
        </li>
        <!-- <li>
                <a routerLink="/claims" class="pl-5">Claims</a>
				<ul>
					<li><a routerLink="/claims/report-a-claim">Report a claim</a></li>
					<li><a routerLink="/claims/claim-prevention">Prevent a claim</a></li>
					<li><a routerLink="/claims/minimize-your-loss">Minimize your loss</a></li>
				</ul>
			</li> -->
        <li><a routerLink="/partners" class="pl-5">Partners</a>

            <ul>
                <li><a routerLink="/partners/builders">Builders</a></li>
                <li><a routerLink="/partners/lenders">Lenders</a></li>
                <li><a routerLink="/partners/api">API</a></li>
            </ul>
        </li>
        <li><a routerLink="/news">News</a></li>
        <!-- <li><a routerLink="/contact-us">Contact</a></li> -->
        <li><a (click)="navigateToUrl('get-an-insurance-quote/')">Get A Quote</a></li>
    </ul>
</nav>

<!-- // M O B I L E   M E N U  -->


<!-- M E N U  -->

<section id="header" class="d-none d-xl-block sticky-top bg-white">
    <div class="container-xl" style="padding: 0px;">
        <nav class="navbar navbar-expand-lg col justify-content-center d-flex align-items-center">
            <a (click)="navigateToUrl('')" class="cursor-pointer" style="padding-right: 0.8rem;">
                <img id="headerLogo" src="assets/img/logos/logoheader.svg" alt="Westwood Insurance Logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse mb-2" id="navbarSupportedContent">
                <!-- <a routerLink="/" style="padding-right: 0.8rem;">
                    <img id="headerLogo" src="./assets/logoheader.svg" alt="Westwood Insurance Logo">
                </a> -->
                <ul id="mainNav" class="nav d-flex align-items-center">
                    <li class="nav-item dropdown position-static">
                        <a class="nav-link dropdown dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            (click)="navigateToUrl('about/')">
                            About
                            <span class="submenu-indicator">
                                <i aria-hidden="true" class="desktop-menu-icon fas fa-caret-down fa-lg"></i>
                            </span>
                        </a>
                        <div class="dropdown-menu-right dropdown-menu-container w-100" aria-labelledby="navbarDropdown">
                            <div class="dropdown-menu w-100 pb-4 pt-4">
                                <div class="row row-space">
                                    <div class="col-md-4 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('about/leadership-team/')">Leadership Team</a>
                                    </div>
                                    <div class="col-md-4 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('careers/')">CAREERS</a>
                                    </div>
                                    <div class="col-md-4">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('about/resource-center/')">Resource Center</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown position-static">
                        <a class="nav-link dropdown dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            (click)="navigateToUrl('products/')">
                            Products
                            <span class="submenu-indicator">
                                <i aria-hidden="true" class="desktop-menu-icon fas fa-caret-down fa-lg"></i>
                            </span>
                        </a>
                        <div class="dropdown-menu-right dropdown-menu-container w-100" aria-labelledby="navbarDropdown">
                            <div class="dropdown-menu w-100 pb-4 pt-4">
                                <div class="row">
                                    <div class="col-12 col-md-3 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/homeowners-insurance/')">Home</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/condo-insurance/')">Condo</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/renters-insurance/')">Renters</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/auto-insurance/')">Auto</a>
                                    </div>
                                    <div class="col-12 col-md-3 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/flood-insurance/')">Flood</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/earthquake-insurance/')">Earthquake</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/umbrella-insurance/')">Umbrella</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/small-business-insurance/')">SMALL
                                            BUSINESES</a>
                                    </div>
                                    <div class="col-12 col-md-3 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/jewelry-and-valuables-insurance/')">JEWELRY &
                                            VALUABLES</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/home-warranty-service-agreement/')">Home
                                            Warranty</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/recreational-vehicle-insurance/')">Recreational Vehicles</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/motorcycle-insurance/')">Motorcycle</a>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/pet-insurance/')">Pet</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/travel-insurance/')">Travel</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/event-insurance/')">Event</a>
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('products/')">All Products</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown position-static">
                        <a class="nav-link dropdown dropdown-toggle text-cursor" href="#" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Customers
                            <span class="submenu-indicator">
                                <i aria-hidden="true" class="desktop-menu-icon fas fa-caret-down fa-lg"></i>
                            </span>
                        </a>
                        <div class="dropdown-menu-right dropdown-menu-container w-100" aria-labelledby="navbarDropdown">
                            <div class="dropdown-menu w-100 pb-4 pt-4">
                                <div class="row row-space">
                                    <div class="col-12 col-md-4 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('claims')">Claims</a>
                                        <a class="dropdown-item" routerLink="/current-customers/make-a-payment">Make a
                                            payment</a>
                                    </div>
                                    <div class="col-12 col-md-4 dropdown-item-right-vertical">
                                        <a class="dropdown-item"
                                            routerLink='/current-customers/request-policy-changes'>Request
                                            Policy Changes</a>
                                        <a class="dropdown-item" routerLink="/current-customers/my-policy-documents">My
                                            Policy
                                            Documents</a>
                                    </div>
                                    <div class="col-12 col-md-4 my-auto">
                                        <a class="dropdown-item"
                                            routerLink="/current-customers/lender-requests-changes">LENDER REQUESTS /
                                            CHANGES</a>
                                        <a class="dropdown-item"
                                            routerLink="/current-customers/opt-in-for-text-messages">Opt-in For Text
                                            Messages</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown position-static">
                        <a class="nav-link dropdown dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            (click)="navigateToUrl('partners/')">
                            PARTNERS
                            <span class="submenu-indicator">
                                <i aria-hidden="true" class="desktop-menu-icon fas fa-caret-down fa-lg"></i>
                            </span>
                        </a>

                        <div class="dropdown-menu-right dropdown-menu-container w-100" aria-labelledby="navbarDropdown">
                            <div class="dropdown-menu w-100 pb-4 pt-4">
                                <div class="row row-space">
                                    <div class="col-12 col-md-4 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('partners/')">BUILDERS</a>
                                    </div>
                                    <div class="col-12 col-md-4 dropdown-item-right-vertical">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('partners-lenders/')">LENDERS</a>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <a class="dropdown-item cursor-pointer"
                                            (click)="navigateToUrl('partners-api/')">API</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown position-static">
                        <a class="nav-link dropdown dropdown-toggle cursor-pointer" (click)="navigateToUrl('news/')"
                            id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            News
                        </a>
                    </li>
                    <li class="nav-item dropdown position-static">
                        <a class="nav-link dropdown dropdown-toggle cursor-pointer" (click)="navigateToUrl('contact-us/')"
                            id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            CONTACT US
                        </a>
                    </li>
                    <li class="nav-item position-static" style="padding-left: 1rem;">
                        <a class="btn ww-button-title my-2 my-sm-0" (click)="navigateToUrl('get-an-insurance-quote/')">Get A Quote</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>

</section>

<!-- // M E N U  -->