<!-- H E A D E R   I M G   -->

<section id="header-img-contact-us" class="sub" *ngIf="false">
    <div class="container">
     <img  src="assets/img/PRELOADER.gif" class="preloader"  *ngIf="isLoading"/>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
          <h2 class="text-center sub-h2">Contact us</h2>
          <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
        </div>
      </div>
    </div>
  </section>
  
  <!-- //  H E A D E R   I M G   -->
  
  <!-- B R E A D C R U M B  -->
  
  <section class="container pt-3">
    <nav id="breadcrumb">
      <ol class="breadcrumb">
        <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
      </ol>
    </nav>
  </section>
  
  <!-- // B R E A D C R U M B  -->

  <!-- W H Y  H O M E   O W N E R S   I N S U R A N C E   -->

<section>
	<div class="container pt-2">
		<div class="row pt-2">
			<div class="col">
        <h2 class="col text-center">You're just one step away from your quote!</h2>
        <p class="col text-center sub-heading">Please verify your information below.</p>
			</div>
		</div>
		<!-- <div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>  -->
	</div>
</section>

<!-- // W H Y  H O M E   O W N E R S   I N S U R A N C E   -->
  
  
  <!--  F O R M   -->
  <form (ngSubmit)="onSubmit(customerSearchForm)" #customerSearchForm="ngForm">
    <section id="request-change-form" class="pb-5">
      <div class="container">
       <img  src="assets/img/PRELOADER.gif" class="preloader"  *ngIf="isLoading"/>
        <div class="row pt-3 justify-content-center">
          <div class="col-md-7 col-xs-12">
            
            <div class="form-div">
              <div class="row">
                  <div class="col-lg-4 col-xs-12 col-sm-12 mob-pt-3">
                      <label for="quoteNumber">Quote Number <span>*</span></label>
                      <i class="fa fa-question-circle help-icon-custom text-sm-right help date-helper-icon"></i>
                      <input type="text" class="form-control" 
                      pattern="[a-zA-Z0-9]*" 
                      [(ngModel)]="customerSearchDetail.quoteNumber" name="quoteNumber"
                        required #quoteNumber="ngModel" maxlength="17"/>
                      <label *ngIf="( (quoteNumber.hasError('required') ) && quoteNumber.touched)" class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                      <label *ngIf="( (quoteNumber.hasError('pattern') ) && quoteNumber.touched)" class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                  </div>
                <div class="col-lg-4 col-xs-12 col-sm-12">
                  <label for="lastName">Last Name <span>*</span></label>
                  
                  <input type="text" class="form-control" [(ngModel)]="customerSearchDetail.lastName" name="lastName"
                    required #lastName="ngModel" maxlength="17"/>
                  <label *ngIf="(lastName.hasError('required') && lastName.touched)" class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                </div>
               
                <div class="col-lg-4 col-xs-12 col-sm-12 mob-pt-3">
                  <label for="zipCode">Zip Code <span>*</span></label>
                  
                  <input type="text" class="form-control" [(ngModel)]="customerSearchDetail.zipCode" name="zipCode" minlength="5" maxlength="10" 
                  required #ZipCodes #zipCode="ngModel" ZipcodeValidator />
                  <label *ngIf="( ( zipCode.hasError('required')  ) && zipCode.touched) " class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                  <label *ngIf="(customerSearchDetail.zipCode!=undefined && customerSearchDetail.zipCode!='' && zipCode.invalid && zipCode.touched)"
                                        class="error-message text-nowrap"> {{constantsObj.invalidFormatErrorMessage}}</label>
                </div>
              </div> 
            </div>
           
  
            <div class="container pt-4 text-center">
              <div class="row">
                  <p class="required-p pl-3">*These fields are required</p>
              </div>
            </div> 
  
            <div class="container">
              <div class="row"> 
                <div class="row pl-3 text-center">
                  <div class="col pt-3  pr-5 pb-2 text-right" *ngIf="!this.commonService.isSafariBroswer()">
                    <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language" (success)="handleSuccess($event)" (load)="handleLoad()"
                      (expire)="handleExpire()" [theme]="theme" [type]="type" [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
                    </ngx-recaptcha2>
                  </div>
                </div>
                <div class="row pl-3 text-center">
                  <div class="col pt-3  pr-5 pb-2 text-right" *ngIf="false">
                    <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                      (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type" [badge]="badge" [ngModel]="recaptcha"
                      [ngModelOptions]="{ standalone: true }">
                    </ngx-invisible-recaptcha> 
                  </div>
                </div>
              </div>
              <label *ngIf="(captchaRequired && customerSearchForm.submitted)" class="error-message text-nowrap pl-2">{{constantsObj.requiredErrorMessage}}</label>
            </div>
  
            <div class="container">
              <div class="row">
                <div class="row pl-2 text-center">
                  <div class="col pr-5 pb-4">
                      <button type="submit" class="btn ww-button pl-2 button-width-160">Verify & Go</button> 
                  </div>
                </div>  
              </div>
              <div class="row pl-4 pb-4" *ngIf="customerSearchDetail.errormessage">
                <span id="messageError" class="required-span" style="text-transform: none;" >
                    {{ customerSearchDetail.errormessage }}
                </span>
            </div>
            </div> 

            <div class="container">
               <p class="helpingMsg"> If you don’t have your quote number, please call <a href="tel:18888225396" class="">888.822.5396</a>, Monday – Friday, from 7 AM to 7 PM CST to speak with an agent.</p>
            </div>
          </div> 
        </div>
      </div>
    </section>
  </form>
  <!-- // F O R M  -->  
  
  <!-- E X T E R N A L    S I T E    M O D A L   --> 
  
  <app-model-pop-up-other-domain></app-model-pop-up-other-domain> 
  
  <!-- E X T E R N A L    S I T E    M O D A L   -->
  
  