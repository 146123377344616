import {
  Component,
  OnInit,
  TemplateRef,
  AfterViewChecked,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Router } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDatepicker,
  NgbPopoverConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { Constants, loanType } from "../../Utilities/Constants/constants";
import { CommonService } from "../../service/common.service";
import { EditableGridService } from "../../Utilities/editableGrid/editable-grid.service";
import { DataService } from "../../service/data.service";
import { PropUnderwritingService } from "../../service/prop-underwriting.service";
import { Lead } from "../../model/lead";
import { PropertyUnderwriting } from "../../model/propertyUnderwriting";
import { SupplementalInformation } from "../../model/supplementalInformation";
import { NgModel, FormGroup } from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { RouterExtService } from "../../service/router-ext.service";
import { ModelPopupComponent } from "../../shared/model-popup/model-popup.component";
import { WizardComponent } from "angular-archwizard";
import { of } from "rxjs";
import { QuoteResultService } from "src/app/service/quote-result.service";
import { CustNoticeService } from "src/app/service/cust-notice.service";
import { Renovation } from "src/app/model/renovation";
import { LookUpValues } from "src/app/model/look-up-values";
import { DogTypes } from "src/app/model/dogTypes";
import { OtherPets } from "src/app/model/otherPets";
import { LossHistory } from "src/app/model/lossHistory";
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from "src/app/shared/ngb-components/ngb-datepicker-formatter";

declare var $: any;

@Component({
  selector: "app-underwriting",
  templateUrl: "./underwriting.component.html",
  styleUrls: ["./underwriting.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DecimalPipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class UnderwritingComponent implements OnInit {
  underWritingForm: FormGroup;
  lossHistoryForm: FormGroup;
  otherAnimalForm: FormGroup;
  addDogForm: FormGroup;
  renovationForm: FormGroup;

  LiveChatImage = "";
  formErrors: any = {};
  emptyDogsRowFlag: boolean = true;
  emptyOtherPetsRowFlag: boolean = true;
  emptyRennovationFlag: boolean = true;
  emptyClaimsFlag: boolean = true;
  errorInClaims: boolean = false;
  maxDate = new Date();
  hdnDog: any;
  hdnOtherPets: any;
  hdnRenovations: any;
  hdnLoss: any;
  dataForModal = "Hello from Component";
  modalRef!: BsModalRef;
  selectedRenovationType: string;
  selectRenovationExtentTypes: string;
  renovation: Renovation;
  NoRenovationFound: boolean = false;
  listRenovationsTypes: Array<LookUpValues>[] = [];
  editId: number = 0;
  editMode: boolean = false;
  renovationToEdit: Renovation;
  maxRenovationYear: Number = 0;

  //#region Dogs
  dog: DogTypes;
  dogToEditCopy: DogTypes;

  otherPet: OtherPets;
  otherPetToEditCopy: OtherPets;
  lossHistory: LossHistory;
  lossHistoryToEditCopy: LossHistory;
  //#endregion Dogs

  @ViewChild("myModalTemplate") myModalTemplate!: TemplateRef<any>;

  @ViewChild(ModelPopupComponent) popUp: ModelPopupComponent;
  @ViewChild("ErrorDivDog") elemErrorDivDog: ElementRef;
  @ViewChild("ErrorOtherPets") elemErrorOtherPets: ElementRef;
  @ViewChild("ErrorRenovations") elemErrorRenovations: ElementRef;
  @ViewChild("ErrorLoss") elemErrorLoss: ElementRef;
  @Input("mainWizard") public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();

  template: any;
  constructor(
    public router: Router,
    public commonService: CommonService,
    public editableGrid: EditableGridService,
    public dataService: DataService,
    public propService: PropUnderwritingService,
    private quoteService: QuoteResultService,
    // public leadDetails: Lead,
    private modalService: BsModalService,
    private routerExtService: RouterExtService,
    public constantsObj: Constants,
    public decimalPipe: DecimalPipe,
    public custNoticeService: CustNoticeService
  ) {
    this.LiveChatImage = constantsObj.LiveChatImage;
    this.ngbMinDate = { year: 1900, month: 1, day: 1 };
    this.ngbMaxDate = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    };
    this.maxRenovationYear = new Date().getFullYear();
  }
  bsModalRef!: BsModalRef;
  PropertyUnderwriting: PropertyUnderwriting;
  _propObj = new PropertyUnderwriting();
  supplementalInfo: SupplementalInformation;
  isLoading = false;
  isRenters: boolean = false;
  swimmingPoolNoneID: string;
  isIEOrEdge: boolean;
  message: string = "";
  height: number = 1300;

  showModalPR = false;

  ngbMaxDate: any;
  ngbMinDate: any;

  ngOnInit() {
    this.commonService.ScrollToRTQForm();
    this.commonService.SaveLeadHistory();
    this.dataService.leadHistory.pageNumber = 4;
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.PropertyUnderwriting = this.leadDetails.propertyUnderwriting;
    this.supplementalInfo = this.PropertyUnderwriting.supplementalInformation;
    // console.log('supplementalInfo:', this.supplementalInfo);
    this.isLoading = true;
    this.swimmingPoolNoneID = this.constantsObj.SwimmingPoolNone;
    if (this.CheckObject(this.supplementalInfo)) {
      this.propService.getPropUnderwritingInfo(this.leadDetails).subscribe(
        (data: Lead) => {
          this.leadDetails = data;
          this.dataService.leadHistory.preValue = JSON.parse(
            JSON.stringify(data)
          );
          this.PropertyUnderwriting = this.leadDetails.propertyUnderwriting;
          this.supplementalInfo =
          this.leadDetails.propertyUnderwriting.supplementalInformation;
          // console.log('supplementalInfo2:', this.leadDetails.propertyUnderwriting.supplementalInformation);
          this.formatCurrency(this.supplementalInfo.lossHistories);
          this.isLoading = false;
        },
        (err) => {
          this.dataService.handleError(err);
          this.isLoading = false;
        }
      );
    } else {
      //#set the visibility of Loan Information
      if (this.leadDetails.loanType == loanType.Refinance)
        this.isRenters = true;
      this.formatCurrency(this.supplementalInfo.lossHistories);
      this.isLoading = false;
      this.dataService.leadHistory.preValue = JSON.parse(
        JSON.stringify(this.dataService.leadDetails)
      );
    }
  }
  impersonateLead() {
    if (this.leadDetails == null) this.leadDetails = new Lead();
    this.leadDetails.leadID = "98704FF1-A856-46F6-ADA4-6AA34D07669B";
    this.leadDetails.loanType = loanType.Owner; //Refinanace or H04
  }

  checkRenovationYear(event: any) {
    if(event.target.value > new Date().getFullYear()) {
      return event.target.value = null;
    }
  }

  convertToNum(val: string): Number {
    return Number(val);
  }

  openModal(template: TemplateRef<any>, gridName: string) {
    if (gridName == "dogs") {
      if (
        this.supplementalInfo.dogs != null &&
        this.supplementalInfo.dogs.length > 0
      )
        if (
          !(
            this.supplementalInfo.dogs.length == 1 &&
            this.supplementalInfo.dogs[0].breedID == "" &&
            this.supplementalInfo.dogs[0].explanation == "" &&
            this.supplementalInfo.dogs[0].haveBiteHistory == false &&
            this.supplementalInfo.dogs[0].mixedWithBreedID == ""
          )
        ) {
          if (
            this.supplementalInfo.dogs.some(
              (obj) =>
                obj.breedID != "" ||
                obj.explanation != "" ||
                obj.haveBiteHistory != null ||
                obj.mixedWithBreedID != ""
            )
          ) {
            this.modalService.config.show = true;
            this.modalService.config.backdrop = "static";
            this.modalService.config.keyboard = false;
            this.modalRef = this.modalService.show(template, { class: "dogs" });
          }
        }
    }
    if (gridName == "otherPets") {
      if (
        this.supplementalInfo.otherPets != null &&
        this.supplementalInfo.otherPets.length > 0
      ) {
        if (
          this.supplementalInfo.otherPets.some((obj) => obj.description != "")
        ) {
          this.modalService.config.show = true;
          this.modalService.config.backdrop = "static";
          this.modalService.config.keyboard = false;
          this.modalRef = this.modalService.show(template, {
            class: "otherPets",
          });
        }
      }
    }
    if (gridName == "renovations") {
      if (
        this.supplementalInfo.renovations != null &&
        this.supplementalInfo.renovations.length > 0
      ) {
        if (
          this.supplementalInfo.renovations.some(
            (item) =>
              item.details != "" ||
              item.extentType != "" ||
              item.id != "" ||
              item.type != "" ||
              item.yearCompleted != ""
          )
        ) {
          this.modalService.config.show = true;
          this.modalService.config.backdrop = "static";
          this.modalService.config.keyboard = false;
          this.modalRef = this.modalService.show(template, {
            class: "renovations",
          });
        }
      }
    }

    if (gridName == "loss") {
      if (
        this.supplementalInfo.lossHistories != null &&
        this.supplementalInfo.lossHistories.length > 0
      ) {
        if (
          this.supplementalInfo.lossHistories.some(
            (item) =>
              item.amountPaid != "" ||
              item.cause != "" ||
              item.date != null ||
              item.id != "" ||
              item.note != ""
          )
        ) {
          this.modalService.config.show = true;
          this.modalService.config.backdrop = "static";
          this.modalService.config.keyboard = false;
          this.modalRef = this.modalService.show(template, { class: "loss" });
        }
      }
    }
  }

  confirm(): void {
    if (this.modalService.config.class == "dogs") {
      this.supplementalInfo.dogs = [];
      this.supplementalInfo.haveDogs = false;
    }
    if (this.modalService.config.class == "otherPets") {
      this.supplementalInfo.otherPets = [];
      this.supplementalInfo.haveOtherPets = false;
    }
    if (this.modalService.config.class == "renovations") {
      this.supplementalInfo.renovations = [];
      this.supplementalInfo.haveRenovation = false;
    }
    if (this.modalService.config.class == "loss") {
      this.supplementalInfo.lossHistories = [];
      this.supplementalInfo.haveClaims = false;
    }

    this.modalRef.hide();
  }
  //Pop up decline method
  decline(): void {
    if (this.modalService.config.class == "dogs") {
      this.supplementalInfo.haveDogs = true;
    }
    if (this.modalService.config.class == "otherPets") {
      this.supplementalInfo.haveOtherPets = true;
    }

    if (this.modalService.config.class == "renovations") {
      this.supplementalInfo.haveRenovation = true;
    }
    if (this.modalService.config.class == "loss") {
      this.supplementalInfo.haveClaims = true;
    }

    this.modalRef.hide();
  }
  UnCheck(template: any, formVal: string) {
    switch (formVal) {
      case "dogs": {
        this.supplementalInfo.haveDogs = false;
        this.openModal(template, "dogs");
        break;
      }
      case "otherPets": {
        this.supplementalInfo.haveOtherPets = false;
        this.openModal(template, "otherPets");
        break;
      }
      case "renovations": {
        this.supplementalInfo.haveRenovation = false;
        this.openModal(template, "renovations");
        break;
      }
      case "loss": {
        this.supplementalInfo.haveClaims = false;
        this.openModal(template, "loss");
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  toggleLocked() {
    if (this.supplementalInfo.locked == null)
      this.supplementalInfo.locked = true;
    else this.supplementalInfo.locked = !this.supplementalInfo.locked;
  }

  formatSupplementalInfo() {
    if (this.supplementalInfo.haveDogs == null)
      this.supplementalInfo.haveDogs = false;
    if (this.supplementalInfo.haveOtherPets == null)
      this.supplementalInfo.haveOtherPets = false;
    if (this.supplementalInfo.haveRenovation == null)
      this.supplementalInfo.haveRenovation = false;
    if (this.supplementalInfo.haveClaims == null)
      this.supplementalInfo.haveClaims = false;
    //Pool
    if (this.supplementalInfo.isPoolFence == null)
      this.supplementalInfo.isPoolFence = false;
    if (this.supplementalInfo.haveSlideDivingBoard == null)
      this.supplementalInfo.haveSlideDivingBoard = false;

    //Other
    if (this.supplementalInfo.haveTrampoline == null)
      this.supplementalInfo.haveTrampoline = false;
    if (this.supplementalInfo.isVacant == null)
      this.supplementalInfo.isVacant = false;
  }

  setHeight() {}
  onSubmit(underWritingForm) {
    this.leadDetails.quoteResult.status = false;
    this.dataService.leadHistory.noUpdate = underWritingForm["form"].pristine;
    this.formErrors = {};
    // this.formErrors = this.commonService.updateControls(underWritingForm);
    //#Will start the API call from the quote message: ie saving the details

    this.commonService.ScrollToRTQForm();
    // this.formatSupplementalInfo();

    this.isLoading = true;
    this.dataService.isDataSaveInProgress$ = of(true);
    if (!this.isUnderwritingFormValid(underWritingForm) || !underWritingForm.valid) {
      this.isLoading = false;
      this.dataService.isDataSaveInProgress$ = of(false);
      //this.wizardStep.goToPreviousStep();
      return;
    }

    // Rate the policy
    console.log("UND: LeadRating-Initiated");

    if (underWritingForm) {
      this.leadDetails.propertyUnderwriting.supplementalInformation = this.supplementalInfo;
      console.log('201-Routing to Next Screen');
      this.wizardStep.goToNextStep();
      console.log('202-Routed to Next Screen');
      this.propService
        .postPropUnderwritingInfo(this.leadDetails)
        .subscribe((data: Lead) => {
          console.log('101-Rating completed');
          console.log("UND: LeadRating-Completed");

          this.leadDetails = data;
          this.dataService.leadHistory.postValue = JSON.parse(
            JSON.stringify(data)
          );
          this.commonService.SaveLeadHistory();
          this.leadDetails.propertyUnderwriting = data.propertyUnderwriting;
          console.log("Lead rating Status: ", this.leadDetails.quoteResult);

          this.custNoticeService.getCustNoticeText(this.leadDetails).subscribe(
            (data: Lead) => {
              console.log("UND-GetDisclosure success: ", data.disclosureInfo);
              this.leadDetails.disclosureInfo = data.disclosureInfo;
              if( this.leadDetails.quoteResult.status) {
                if(this.leadDetails.disclosureInfo.text !== null) {
                  this.dataService.isDisclaimerRequired = true;
                } else {
                  this.dataService.isDisclaimerRequired = false;
                }
                // this.wizardStep.goToNextStep();
              } else {
                this.leadDetails = new Lead();
                this.dataService.leadDetails = new Lead();
                this.leadUpdateEvent.emit(new Lead());
              }
            },
            (err) => {
              console.log("UND-GetDisclosure failed: ", err);
              // this.isLoading = false;
              this.dataService.handleError(err);
            }
          );

          //If the quoteResult.status is TRUE, the rating is successful.
          if (this.leadDetails.quoteResult.status) {
            console.log("UND: Initiating GetLeadResultInfo");
            // this.isLoading = true;
            this.quoteService.getQuoteResultInfo(this.leadDetails).subscribe(
              (data: Lead) => {
                console.log('102-Getting Rating Details on successful rate');
                console.log(
                  "UND: Completed GetLeadResultInfo: ",
                  data.quoteResult
                );
                this.leadDetails.quoteResult = data.quoteResult;
                console.log(
                  "UND: GetLeadResultInfo completed. QuoteResult: ",
                  this.leadDetails.quoteResult
                );
                this.setHeight();
                this.formatQuoteResult();
                this.setOriginalQuote();
                this.dataService.leadDetails = this.leadDetails;

                this.isLoading = false;
                this.dataService.isDataSaveInProgress$ = of(false);
                this.leadUpdateEvent.emit(this.leadDetails);

                this.dataService.leadHistory.pageNumber = 5;
                this.dataService.leadHistory.preValue = JSON.parse(
                  JSON.stringify(this.leadDetails)
                );
                // this.wizardStep.goToNextStep();
              },
              (err) => {
                this.setHeight();
                this.isLoading = false;
                this.dataService.isDataSaveInProgress$ = of(false);
                this.dataService.handleError(err);
                this.leadDetails.quoteResult.quoteMessage = "failed";
                console.log("UND: GetLeadResultInfo Failed: ", err);
              }
            );
          } else {
            this.isLoading = false;
            this.dataService.isDataSaveInProgress$ = of(false);
            console.log("UND: LeadRating-Failed");
            this.setHeight();
            this.leadDetails = new Lead();
            this.dataService.leadDetails = new Lead();
            this.leadUpdateEvent.emit(new Lead());
            this.router.navigateByUrl('real-time-quote/additional-info');
            // this.wizardStep.goToNextStep();
            //this.getDummyQuote();
          }
          //quote result succedd

          //this.isLoading = false;


          return;
        }, (err) => {
          console.log(err);
          this.isLoading = false;
          this.dataService.isDataSaveInProgress$ = of(false);
          this.leadDetails.quoteResult.status = false;
          this.leadUpdateEvent.emit(this.leadDetails);
          this.dataService.handleError(err);
        });
    }
  }
  savePageDetails() {
    this.isLoading = true;
    this.leadDetails.contactMeButtonClicked = true;
    this.dataService.leadHistory.noUpdate = false;
    this.commonService.CreateLeadHistoryObj(4, this.leadDetails);
    this.propService.postPropUnderwritingInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.commonService.SaveLeadHistoryFromContactBtn();
        this.leadDetails.propertyUnderwriting = data.propertyUnderwriting;
        this.isLoading = false;
        // this.commonService.NavigateToPage("/real-time-quote/contact");
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }

  ngOnDestroy() {
    this.leadDetails.propertyUnderwriting.supplementalInformation =
      this.supplementalInfo;
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }

  CheckObject(supplementalInfo: SupplementalInformation) {
    if (
      supplementalInfo.lstPoolTypes.length == 0 ||
      supplementalInfo.lstBreedTypes.length == 0 ||
      supplementalInfo.lstRenovationTypes.length == 0 ||
      supplementalInfo.lstRenovationExtentTypes.length == 0 ||
      supplementalInfo.lstLossTypes.length == 0
    )
      return true;
    else return false;
  }
  ngAfterViewChecked() {
    this.height = $(".container").height();
    this.height = this.height + 150;
  }
  formatCurrency(lstLossHistory: any) {
    lstLossHistory.forEach((num1) => {
      var num = num1.amountPaid;
      num = num.toString().replace(/\$|\,/g, "");
      if (num == null || num == "") return;
      if (isNaN(num)) num = "0";
      var sign = num == (num = Math.abs(num));
      num = parseFloat(num).toFixed(2);
      var arrayAmount = num.split(".");
      var cents = arrayAmount[1];
      num = arrayAmount[0];
      for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        num =
          num.substring(0, num.length - (4 * i + 3)) +
          "," +
          num.substring(num.length - (4 * i + 3));
      var result = (sign ? "" : "-") + "$" + num + "." + cents;
      num1.amountPaid = result;
    });
  }
  hideDatePicker(dp) {
    dp.hide();
  }

  formatQuoteResult() {
    this.leadDetails.quoteResult.coverageAAmount = this.decimalPipe.transform(
      this.leadDetails.quoteResult.coverageAAmount,
      "1.0-0"
    );
    this.leadDetails.quoteResult.coverageCAmount = this.decimalPipe.transform(
      this.leadDetails.quoteResult.coverageCAmount,
      "1.0-0"
    );
    this.leadDetails.quoteResult.coverageEAmount = this.decimalPipe.transform(
      this.leadDetails.quoteResult.coverageEAmount,
      "1.0-0"
    );
    this.leadDetails.quoteResult.coverageFAmount = this.decimalPipe.transform(
      this.leadDetails.quoteResult.coverageFAmount,
      "1.0-0"
    );
    if (
      this.leadDetails.quoteResult.deductibles != null &&
      this.leadDetails.quoteResult.deductibles.length > 0
    ) {
      this.leadDetails.quoteResult.deductibles.forEach((element) => {
        element.amount = this.decimalPipe.transform(element.amount, "1.0-0");
      });
    }
  }

  setOriginalQuote() {
    this.leadDetails.quoteResult_Prev = JSON.parse(
      JSON.stringify(this.leadDetails.quoteResult)
    );
    // this.oldQuote = JSON.parse(JSON.stringify(this.leadDetails.quoteResult));
  }
  getOriginalQuote(QuoteResult) {
    this.leadDetails.quoteResult = JSON.parse(
      JSON.stringify(this.leadDetails.quoteResult_Prev)
    );
    QuoteResult["form"].pristine = true;
    // this.enableResetBtn = false;
    // this.leadDetails.quoteResult.applyNowBtnType = applyNowBtnType.DBSave;
    // this.applyQuote('apply', null, true);
  }

  onSwimmingPoolTypeChange() {
    if (
      this.supplementalInfo.poolType == "a81" ||
      this.supplementalInfo.poolType == ""
    ) {
      this.supplementalInfo.isPoolFence = false;
      this.supplementalInfo.locked = false;
      this.supplementalInfo.haveSlideDivingBoard = false;
    }
  }
  setMaxDate() {
    this.maxDate = new Date();
  }

  getRenovationTypeValue(key: string) {
    var value = "";
    this.supplementalInfo.lstRenovationTypes.map((ren) => {
      if (ren["lookupId"] === key) {
        value = ren["lookupValue"];
      }
    });
    return value;
  }

  getRenovationExtentTypeValue(key: string) {
    var value = "";
    this.supplementalInfo.lstRenovationExtentTypes.map((extentType) => {
      if (extentType["lookupId"] === key) {
        value = extentType["lookupValue"];
      }
    });
    return value;
  }

  findValueByLookupId(collectionName: string, key: string) {
    var value = "";
    switch (collectionName) {
      case "lstRenovationTypes": {
        this.supplementalInfo.lstRenovationTypes.map((extentType) => {
          if (extentType["lookupId"] === key) {
            value = extentType["lookupValue"];
          }
        });
        break;
      }
      case "lstRenovationExtentTypes": {
        this.supplementalInfo.lstRenovationExtentTypes.map((extentType) => {
          if (extentType["lookupId"] === key) {
            value = extentType["lookupValue"];
          }
        });
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    return value;
  }

  //#region Renovation

  openRenovationModal(
    template: TemplateRef<any>,
    renovation: Renovation,
    action: string,
    renovationIdToUpdate: number
  ) {
    if (this.listRenovationsTypes.length == 0) {
      this.listRenovationsTypes = {
        ...this.supplementalInfo.lstRenovationTypes,
      };
    }
    if (action === "Add") {
      this.renovation = new Renovation();
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    } else if (action === "Edit") {
      this.editMode = true;
      this.editId = renovationIdToUpdate;
      this.renovationToEdit = { ...renovation };
      this.renovation = renovation;
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    }
  }

  AddRenovation() {
    if (this.editMode) {
      if(Number(this.renovation.yearCompleted) > new Date().getFullYear()) {
        // console.log(this.renovationForm.controls);
        // this.renovationForm.controls['yearCompleted'].setErrors({'INVALID': true});
        return;
      }
      this.bsModalRef.hide();
      this.editMode = false;
    } else {
      if(Number(this.renovation.yearCompleted) > new Date().getFullYear()) {
        // console.log(this.renovationForm.controls);
        // this.renovationForm.controls['yearCompleted'].setErrors({'INVALID': true});
        return;
      }
      this.supplementalInfo.renovations.push(this.renovation);
      this.bsModalRef.hide();
    }
  }

  closeRenovationModal() {
    if (this.editMode) {
      this.supplementalInfo.renovations[this.editId] = this.renovationToEdit;
      this.editMode = false;
      this.renovationToEdit = new Renovation();
    }
    this.bsModalRef.hide();
  }
  deleteRenovation(renovationRowId: number) {
    this.supplementalInfo.renovations.splice(renovationRowId, 1);
  }
  //#endregion Renovation

  //#region  Dog
  getDogBreedTypeValue(key: string) {
    var value = "";
    this.supplementalInfo.lstBreedTypes.map((bt) => {
      if (bt["lookupId"] === key) {
        value = bt["lookupValue"];
      }
    });
    return value;
  }

  openDogsModal(
    template: TemplateRef<any>,
    dogToUpdate: DogTypes,
    action: string,
    dogIdToUpdate: number
  ) {
    if (action === "Add") {
      this.dog = new DogTypes();
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    } else if (action === "Edit") {
      this.editMode = true;
      this.editId = dogIdToUpdate;
      this.dogToEditCopy = { ...dogToUpdate };
      this.dog = dogToUpdate;
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    }
  }

  AddDog() {
    if (this.editMode) {
      this.bsModalRef.hide();
      this.editMode = false;
    } else {
      this.supplementalInfo.dogs.push(this.dog);
      this.bsModalRef.hide();
    }
  }

  cancelDogUpdate() {
    if (this.editMode) {
      this.supplementalInfo.dogs[this.editId] = this.dogToEditCopy;
      this.dogToEditCopy = new DogTypes();
      this.editMode = false;
    }
    this.bsModalRef.hide();
  }
  deleteDog(dogRowId: number) {
    this.supplementalInfo.dogs.splice(dogRowId, 1);
  }
  //#endregion Dog

  //#region  otherPets
  openOtherPetsModal(
    template: TemplateRef<any>,
    otherPetToUpdate: OtherPets,
    action: string,
    othetPetIdToUpdate: number
  ) {
    if (action === "Add") {
      this.otherPet = new OtherPets();
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    } else if (action === "Edit") {
      this.editMode = true;
      this.editId = othetPetIdToUpdate;
      this.otherPetToEditCopy = { ...otherPetToUpdate };
      this.otherPet = otherPetToUpdate;
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    }
  }

  AddOtherPet() {
    if (this.editMode) {
      this.bsModalRef.hide();
      this.editMode = false;
    } else {
      this.supplementalInfo.otherPets.push(this.otherPet);
      this.bsModalRef.hide();
    }
  }

  cancelOtherPetUpdate() {
    if (this.editMode) {
      this.supplementalInfo.otherPets[this.editId] = this.otherPetToEditCopy;
      this.otherPetToEditCopy = new OtherPets();
      this.editMode = false;
    }
    this.bsModalRef.hide();
  }
  deleteOtherPet(othetPetRowId: number) {
    this.supplementalInfo.otherPets.splice(othetPetRowId, 1);
  }
  //#endregion otherPets

  //#region Claims
  getClaimTypeValue(key: string) {
    var value = "";
    this.supplementalInfo.lstLossTypes.forEach((loss) => {
      if (loss["lookupId"] === key) {
        value = loss["lookupValue"];
      }
    });
    return value;
  }

  openClaimModal(
    template: TemplateRef<any>,
    lossHistoryToUpdate: LossHistory,
    action: string,
    lossHistoryIdToUpdate: number
  ) {
    if (action === "Add") {
      this.lossHistory = new LossHistory();
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    } else if (action === "Edit") {
      this.editMode = true;
      this.editId = lossHistoryIdToUpdate;
      this.lossHistoryToEditCopy = { ...lossHistoryToUpdate };
      this.lossHistory = lossHistoryToUpdate;
      this.bsModalRef = this.modalService.show(template, { class: 'modal-dialog-centered', animated: true});
    }
  }

  AddClaim() {
    // if (!this.isValidClaim(this.lossHistory)) {
    //   return;
    // }
    if (this.editMode) {
      this.bsModalRef.hide();
      this.editMode = false;
    } else {
      if (
        this.lossHistory === null ||
        this.lossHistory.date === null ||
        this.lossHistory.cause === "" ||
        this.lossHistory.amountPaid === ""
      ) {
        return;
      } else {
        this.supplementalInfo.lossHistories.push(this.lossHistory);
        this.bsModalRef.hide();
      }
    }
  }

  cancelClaimUpdate() {
    if (this.editMode) {
      this.supplementalInfo.lossHistories[this.editId] =
        this.lossHistoryToEditCopy;
      this.lossHistory = new LossHistory();
      this.editMode = false;
    }
    this.bsModalRef.hide();
  }
  deleteClaim(lossHistoryRowId: number) {
    this.supplementalInfo.lossHistories.splice(lossHistoryRowId, 1);
  }
  //#endregion Claims

  //#region object-Validation
  isValidClaim(lossHistory: LossHistory): boolean {
    if (lossHistory === null) {
      return false;
    }

    if (lossHistory.hasOwnProperty("date") && lossHistory.date === null) {
      return false;
    }

    if (lossHistory.hasOwnProperty("cause") && lossHistory.cause !== null) {
      return false;
    }

    if (
      lossHistory.hasOwnProperty("amountPaid") &&
      lossHistory.amountPaid !== null
    ) {
      return false;
    }
    return true;
  }

  onSubmitLossHistory(lossHistoryForm) {}

  onSubmitAnimalForm(otherAnimalForm) {}

  onSubmitAddDog(addDogForm) {}

  onSubmitRenovation(renovationForm) {}

  isUnderwritingFormValid(underWritingForm): boolean {
    if (
      this.supplementalInfo.haveDogs &&
      this.supplementalInfo.dogs.length == 0
    ) {
      return false;
    }
    if (
      this.supplementalInfo.haveClaims &&
      this.supplementalInfo.lossHistories.length == 0
    ) {
      return false;
    }
    if (
      this.supplementalInfo.haveOtherPets &&
      this.supplementalInfo.otherPets.length === 0
    ) {
      return false;
    }
    if (
      this.supplementalInfo.haveRenovation &&
      this.supplementalInfo.renovations.length === 0
    ) {
      return false;
    }
    return true;
  }

  convertDateToMMDDYYYY(inputDate: string) {
    var enteredDate = new Date(inputDate);
    let year = enteredDate.getFullYear();
    let month = enteredDate.getMonth() + 1;
    let day = enteredDate.getDate();

    var monthText = "";
    var dayText = "";

    if (month < 10) {
      monthText = "0" + month.toString();
    } else {
      monthText = month.toString();
    }

    if (day < 10) {
      dayText = "0" + day.toString();
    } else {
      dayText = day.toString();

    }

    return monthText + "/" + dayText + "/" + year;
  }
  //#endregion object-Validation

  removeZero() {
    if(this.lossHistory.amountPaid == '0'){ 
      this.lossHistory.amountPaid = null;
    } 
  }
}