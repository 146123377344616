<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-resource-center" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-6 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">Resource Center</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->

<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<!-- <section id="intro-para" >
	<div class="container text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-10 col-xs-10">
				<p><span>We’re committed to providing a superior experience to our customers.</span><br> In addition to
					quality products, we offer information, tools and resources to help you better understand your
					insurance needs and make sound, informed decisions. </p>
			</div>
		</div>
	</div>
</section> -->

<!-- // I N T R O   P A R A G R A P H  -->


<!-- R E S O U R C E S  -->
<section>
	<div id="resource-center-resources" class="container p-3">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center">Helpful resources</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<span>Floods</span><br>
				The Federal Emergency Management Agency (FEMA) website has information about the National Flood
				Insurance Program (NFIP). You can also view flood maps, estimate the cost of flood damage to your home,
				and learn what’s covered by flood insurance.
				<br>
				<a class="show-cursor" (click)="child.importantNotice($event,'http://www.floodsmart.gov')"
					target="_blank">VISIT »</a>
			</div>
		</div>
		<div class="row justify-content-center pt-4">
			<div class="col-md-9 col-xs-9">
				<span>Earthquakes</span><br>
				The United States Geological Survey (USGS) is a great resource for the latest earthquake information,
				earthquake preparedness, earthquake research, recent and significant past earthquakes, real-time
				earthquake maps, news releases and much more.
				<br>
				<a class="show-cursor" (click)="child.importantNotice($event,'http://www.usgs.gov')"
					target="_blank">VISIT »</a>
			</div>
		</div>
		<div class="row justify-content-center pt-4">
			<div class="col-md-9 col-xs-9">
				<span>Business & Home Safety</span><br>
				The Insurance Institute for Business & Home Safety (IBHS) website provides practical recommendations on
				how to prepare your home to reduce the risk of damage from many different natural hazards.
				<br>
				<a class="show-cursor" (click)="child.importantNotice($event,'https://ibhs.org/')" target="_blank">VISIT
					»</a>
			</div>
		</div>
		<div class="row justify-content-center pt-4">
			<div class="col-md-9 col-xs-9">
				<span>Highway Safety</span><br>
				The Insurance Institute for Highway Safety (IIHS) is an independent, nonprofit scientific and
				educational organization dedicated to minimizing risk, reducing losses, and preventing deaths, injuries
				and property damage from automobile crashes on the nation’s highways.
				<br>
				<a class="show-cursor" (click)="child.importantNotice($event,'https://www.iihs.org/')"
					target="_blank">VISIT »</a>
			</div>
		</div>
		<div class="row justify-content-center pt-4">
			<div class="col-md-9 col-xs-9">
				<span>Department of Insurance</span><br>
				The National Association of Insurance Commissioners (NAIC) educates the public about insurance and
				consumer protection issues. Click on the link to access the Department of Insurance information for all
				50 states.
				<br>
				<a class="show-cursor"
					(click)="child.importantNotice($event,'https://content.naic.org/state_web_map.htm')"
					target="_blank">VISIT »</a>
			</div>
		</div>
		<div class="row justify-content-center pt-4">
			<div class="col-md-9 col-xs-9">
				<span>Insurance Fraud</span><br>
				The National Insurance Crime Bureau (NICB) fights insurance fraud and crime by working with law
				enforcement agencies and representatives of the public through data analytics, investigations, training,
				legislative advocacy and public awareness.
				<br>
				<a class="show-cursor" (click)="child.importantNotice($event,'https://www.nicb.org/')"
					target="_blank">VISIT »</a>
			</div>
		</div>
		<div class="row justify-content-center pt-4">
			<div class="col-md-9 col-xs-9">
				<span>Google Translate</span><br>
				Need to translate information from our website? Google Translate is a convenient translating tool that
				allows you to interpret text from English to Spanish, French, German, Italian and many other languages.
				<br>
				<a class="show-cursor"
					(click)="child.importantNotice($event,'https://translate.google.com/#view=home&op=translate&sl=en&tl=es&text=Where%20is%20the%20property%20located')"
					target="_blank">VISIT »</a>
			</div>
		</div>
	</div>
</section>

<!-- //  R E S O U R C E S   -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->