import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-model-pop-up-other-domain',
  templateUrl: './model-pop-up-other-domain.component.html',
  styleUrls: ['./model-pop-up-other-domain.component.css']
})
export class ModelPopUpOtherDomainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  //To show function
  importantNotice(event, link){
    event.preventDefault();
    if(link !== '' ){  
      jQuery('#leavingModalLink').attr('href',link); 
      jQuery('#leavingModal').modal();
    }  
  }

  continueButton(event){
    event.preventDefault();
    jQuery('#leavingModal').modal('toggle');
    var url = jQuery('#leavingModalLink').attr('href'); 
    window.open(url, '_blank');
    
  }
}
