import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Texting } from '../model/texting';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';


@Injectable({
  providedIn: 'root'
})
export class OptInrentersService {  
  url: string;
  constructor(private dataService: DataService) { }
  /*Create OptinRenter */
  SaveOptinRenterChanges(data: Texting): Observable<Texting> {
    this.url = Controller.Texting + '/' + Action.OptinRenter;
    return this.dataService.postData(data, this.url);
  }

  /*Get all GetBusinessUnit */
  getBusinessUnit(): Observable<Array<Texting>> {
    this.url = Controller.Texting+ '/' + Action.GetBusinessUnitdetail;
    return this.dataService.getAll(this.url);
  }
}
