import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, ValidatorFn, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[dateValidatorForEstCloseDate][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidateForEstCloseDateDirective,
      multi: true
    }
  ]
})
export class DateValidateForEstCloseDateDirective implements Validator {
  validator: ValidatorFn;
  minDate = new Date();
  @Input('minDate') inpMinDate:any;
  //maxDate = new Date();
  day = this.minDate.getDay();
  month = this.minDate.getMonth();
  

  constructor() { 
    this.validator = this.dateValidatorForEstCloseDate();
    this.minDate.setFullYear(this.minDate.getFullYear());
  }
  validate(c: FormControl) {
    return this.validator(c);
  }

  dateValidatorForEstCloseDate(): ValidatorFn {
    return (c: FormControl) => {
      let val: any = c.value;
      this.inpMinDate.setHours(0,0,0,0);
      
      val = new Date(c.value);
      if (val == 'Invalid Date') {
        return {
          dateValidatorForEstCloseDate: {
            valid: false
          }
        };
      } else {
        if (c.value != null && c.value != '') {
          if (val >= this.inpMinDate)
            return null;
          else
            return {
              pastDateValidatorForEstCloseDate: {
                valid: false
              }
            };
        }
      }
    };
  }

}
