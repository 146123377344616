<!-- Start form -->
<form (ngSubmit)="onSubmit(optInRenters)" #optInRenters="ngForm">
    <section id="rtq-form" class="pb-5 pt-3">
        <div class="container">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <span class="pl-3">Opt In Renters Services</span>
                    <br>
                    <br>
                    <div class="form-div z-indexHeader">
                        <div class="row pt-3">
                            <div class="col">
                                <label for="policynumber">Renters <span>*</span></label>
                                <select class="form-control state-width" id="txtState" name="State"
                                    [ngModelOptions]="{ updateOn: 'blur' }" required
                                    [(ngModel)]="textingDetails.Renters" #Renters="ngModel">
                                    <option selected value="">Please Select Renter</option>
                                    <option [value]="businessUnitiD">{{businessUnitName}} </option>
                                </select>
                                <label *ngIf="(Renters.hasError('required') && Renters.touched) "
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-lg-6 col-xs-12 col-sm-12">
                                <label for="FirstName">First Name </label>
                                <input type="text" class="form-control" id="txtFirstName" placeholder="First Name"
                                    maxlength="17" [(ngModel)]="textingDetails.FirstName" name="FirstName"
                                    #firstName="ngModel" #firstNameDP />
                            </div>
                            <div class="col-lg-6 col-xs-12 col-sm-12 mob-pt-3">
                                <label for="LastName">Last Name </label>
                                <input type="text" class="form-control" id="txtLasttName" placeholder="Last Name"
                                    maxlength="20" [(ngModel)]="textingDetails.LastName" name="lastName"
                                    #lastName="ngModel" #lastNameDP />

                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col">
                                <label for="policynumber">Policy Number <span>*</span></label>
                                <input type="text" class="form-control" maxlength="25"
                                    [(ngModel)]="textingDetails.PolicyNumber" name="PolicyNumber"
                                    #PolicyNumber="ngModel" #PolicyNumberDP required />
                                <label *ngIf="(PolicyNumber.hasError('required') && lastName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col">
                                <label for="Number">Contact Number<span> *</span> </label>
                                <input type="text" class="form-control" id="txtPreferredPhone"
                                    placeholder="(999) 999-9999" minlength="10" mask="(000) 000-0000" required
                                    [(ngModel)]="textingDetails.ContactNumber" name="ContactNumber"
                                    #contactNumber="ngModel" />
                                <label *ngIf="(contactNumber.hasError('required') && contactNumber.touched )"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                <label *ngIf="(contactNumber.hasError('minlength') && contactNumber.touched) "
                                    class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                            </div>
                        </div>
                        <div class="row pt-5">
                            <div class="col">
                                <p>
                                    You will receive a text from 52046. Make sure you reply YES to join the program.
                                    Otherwise, you won't receive the messages.
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col">
                                <p>
                                    Message frequency varies by account. Message and data rates may apply according to
                                    your carrier text plan. You can unsubscribe at any time by replying STOP or get
                                    help by replying HELP. Your information will not be sold or used for any other
                                    purpose.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="request-change-form" class="pb-5">
        <div class="container">

            <div class="row justify-content-center">

                <div class="col-md-7 col-xs-12">
                    <div>
                        <p class="req-msg-note pb-3">*These fields are required</p>
                        <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="!this.commonService.isSafariBroswer()">
                                <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language"
                                    (success)="handleSuccess($event)" (load)="handleLoad()" (expire)="handleExpire()"
                                    [theme]="theme" [type]="type" [(ngModel)]="recaptcha"
                                    [ngModelOptions]="{ standalone: true }">
                                </ngx-recaptcha2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="false">
                                <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                                    (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type"
                                    [badge]="badge" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                </ngx-invisible-recaptcha>
                            </div>
                        </div>
                        <label *ngIf="(captchaRequired && optInRenters.submitted)"
                            class="error-message text-nowrap pl-2">{{constantsObj.requiredErrorMessage}}</label>

                        <div class="row pt-3">
                            <div class="col pr-5 pb-5">
                                <button class="btn pl-0" type="submit">
                                    <div class="angled-button-sm button-width-120">
                                        <span>Submit</span>
                                    </div>
                                </button>
                            </div>
                            <div class="col pr-5 pb-5">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>