<!-- <app-request-an-insurance-quote
 mainHeading="QUOTE REQUEST"
 subHeading="false"
 ></app-request-an-insurance-quote> -->
<!-- Start of form -->
<form (ngSubmit)="onSubmit(validateCustForm)" #validateCustForm="ngForm">
    <section id="rtq-form" class="pb-5" style="margin-top: 75px;">
        <div class="container">
           <img  src="assets/img/PRELOADER.gif" class="preloader"  *ngIf="isLoading"/>
            <div class="row justify-content-center">
                <div class="col-md-7 col-xs-12"> 
                    <h2 class="page-header-text">Welcome Back!</h2>
                    <p>You're just one stop away from your quote. Please verify your last name.</p>
                    <div class="form-div z-indexHeader">
                        <div class="row">
                            <div class="col">
                                <label for="lblLoanTransaction">Last Name
                                    <span>*</span>
                                </label>
                                <input type="text" class="form-control" id="txtLastName" placeholder="Last Name"
                                    maxlength="20" [ngModelOptions]="{ updateOn: 'blur' }" required [(ngModel)]="custDetails.lastName"
                                    name="LastName" #lastName="ngModel">
                                <label *ngIf="(lastName.hasError('required') && lastName.touched)" class="error-message text-nowrap">Last Name is required</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="request-change-form" class="pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <div>
                        <p class="req-msg-note pb-4">*These fields are required</p>
                        <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey"
                                    (ready)="handleReady()" (load)="handleLoad()" (success)="handleSuccess($event)"
                                    [type]="type" [badge]="badge" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                        </ngx-invisible-recaptcha>
                        <!-- <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="!this.commonService.isSafariBroswer()">
                                <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language" (success)="handleSuccess($event)"
                                    (load)="handleLoad()" (expire)="handleExpire()" [theme]="theme" [type]="type"
                                    [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                </ngx-recaptcha2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="false">
                                <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey"
                                    (ready)="handleReady()" (load)="handleLoad()" (success)="handleSuccess($event)"
                                    [type]="type" [badge]="badge" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                </ngx-invisible-recaptcha>
                            </div>
                        </div> -->
                        <label *ngIf="(captchaRequired && validateCustForm.submitted)" class="error-message text-nowrap pl-2">{{constantsObj.requiredErrorMessage}}</label>

                        <div class="row pb-2">
                            <div class="col text-center">
                                <button class="btn ww-button" type="submit" style="text-transform: uppercase;">Verify & Go</button>
                            </div>
                        </div>
                        <div class="row pl-3 pr-3" *ngIf="!custDetails.isEmailProspect">
                            <p id ="messageError" class="error-message" style="text-transform: none;" [innerHTML]="custDetails.message">
                            </p>
                        </div>
                        <div class="row pl-3 pr-3" *ngIf="this.dataService.leadDetails.isServiceUnavailable">
                            <p id ="messageError" class="error-message" [innerHTML]="dataService.leadDetails.errorMessage">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>
<!-- End of form -->