import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-aquote-banner',
  templateUrl: './get-aquote-banner.component.html',
  styleUrls: ['./get-aquote-banner.component.css']
})
export class GetAQuoteBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 
  
}
