import {
  Component,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
  ɵConsole,
  NgZone,
  HostListener,
} from "@angular/core";
import { CommonService } from "../service/common.service";
import { Router, NavigationEnd } from "@angular/router";
import { AnalyticsService } from "../service/analytics.service";
import { DataService } from "../service/data.service";
declare var jQuery: any;
declare function onLoad(): any;
declare function invokeMMenu(): any;
declare function homePartnersHack(): any;
declare var $: any;
@Component({
  selector: "app-public",
  templateUrl: "./public.component.html",
  styleUrls: ["./public.component.css"],
})
export class PublicComponent
  implements OnInit, AfterViewChecked, AfterViewInit
{
  public wpBaseUrl: string;
  constructor(
    private ngZone: NgZone,
    public commonService: CommonService,
    private router: Router,
    public dataService: DataService,
    private googleAnalytics: AnalyticsService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.LoadJQueryFN();
      }
    });
    window.onresize = (e) => {
      //ngZone.run will help to run change detection
      this.ngZone.run(() => {});
    };
    window.document.addEventListener("DOMContentLoaded", () => {});
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    homePartnersHack();
  }
  ngAfterViewChecked() {
    // $('.grecaptcha-badge').css('display','none');
  }
  ngOnInit() {
    this.googleAnalytics.init();
  }
  ngAfterViewInit() {
    this.LoadJQueryFN();
    // $('.grecaptcha-badge').remove()
  }
  LoadJQueryFN() {
    onLoad();
  }
}