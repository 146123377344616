<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-pet" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Pet Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->

<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->

<!-- W H Y   P E T   I N S U R A N C E  -->

<section id="why-pet-ins">
    <div class="container  why-pet-ins pt-3 pb-5">
        <div class="row pt-2">
            <div class="col pb-5 text-center">
                <h2 class="col">Why pet insurance?</h2>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row" id="a-bullet-list">
            <div class="col-md-6 col-xs-12 pb-5 pet-img">
                <img src="assets/img/page-body-img/pet-cat.png" style="width: 100%;" alt="Child with pet" />
            </div>
            <div class="col-md-6 col-xs-12">
                <ul>
                    <li>Every year more than one in three pets falls ill or is injured.</li>
                    <li>A visit to the pet emergency room can cost hundreds or even thousands of dollars.</li>
                    <li>Many people are not financially able to afford the high level of care that modern veterinary
                        medicine can provide to improve or extend their pets’ lives.</li>

                </ul>
            </div>
        </div>
    </div>
</section>

<!-- // W H Y   P E T   I N S U R A N C E  -->

<!-- W H A T S   I N C L U D E D   I N  P E T   I N S U R A N C E  -->

<section id="whats-included">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">What's Included in pet insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-12">
                <ul class="pl-0">
                    <li>Prescription drug coverage</li>
                    <li>Diagnostic testing and imaging (X-ray, ultrasound, MRI)</li>
                    <li>ER and specialist care</li>
                    <li>Surgery, hospitalization and nursing care</li>
                    <li>Treatment for breed-specific conditions</li>
                    <li>Prosthetic limbs and devices including mobility aids</li>
                    <li>Cancer treatment</li>
                    <li>Alternative therapies and rehabilitation</li>
                </ul>
            </div>
        </div>

        <!-- O N L I N E   Q U O T E -->

        

        <!-- // O N L I N E   Q U O T E -->


    </div>
</section>

<!-- // W H A T S   I N C L U D E D   I N  P E T   I N S U R A N C E  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section>

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->