import { ReCaptcha } from './reCaptcha';
import { Injectable } from "@angular/core";
@Injectable()
export class CustomerQuoteSearch {
    public zipCode: string;
    public lastName: string;
    public quoteNumber: string;  
    public errormessage: string;
    public redirectURL: string;
    public leadID: string;
    public reCaptcha:ReCaptcha;
    constructor() {
        this.zipCode =  '';
        this.lastName =  '';
        this.quoteNumber =  '';
        this.errormessage = '';
        this.redirectURL = '';
        this.leadID = '';
    }
}