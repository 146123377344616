import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PublicModule } from "./public/public.module";
import { PublicComponent } from "./public/public.component";
import { RcvDetailsComponent } from "./public/rcv-details/rcv-details.component";

export function publicModuleLoader() {
  return PublicModule;
}
const routes: Routes = [
  {
    path: "real-time-quote/rcv-details",
    component: RcvDetailsComponent,
    data: { title: "Real Time Quote - PROPERTY RECONSTRUCTION ESTIMATE" },
  },
  {
    path: "",
    component: PublicComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./public/public.module").then((m) => m.PublicModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
