<!-- H E A D E R   I M G   -->

<section id="header-img-phone-mobile-ins" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
				<h2 class="text-center sub-h2">Phone</h2>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section>

<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
	<div class="container intro-para-homeowners text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<p><span>How much do you depend on your phone?</span><br> Photos, documents, contacts, apps – even our wallets are
					on our phones. Nobody wants to, or frankly, can afford to be without their device.
					<br><br>


					Protect your phone, new or old, with dependable protection plans that let you select the right coverage and get
					repairs in as few as four hours.
				</p>  
				<br>
				<span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
					questions regarding Phone Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  C O N D O  I N S U R A N C E   -->

<section>
	<div class="container pt-3 pb-5">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center"> WHY PHONE INSURANCE? </h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line divider" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="assets/img/page-body-img/mobile-phone.jpg" style="width: 100%;" alt="Couple in apartment complex" />
			</div>
			<div class="col">
					Smartphones cost more than ever to replace with new devices. Plus, we’re keeping our phones longer than ever, which means they are more likely to need repairs. The average person holds on to his or her phone for 30-32 months, up from 18-20 months just a couple of years ago. <br><br>

					Protecting your smartphone is a smart financial decision!
			</div>
		</div>
	</div>
</section>

<!-- // W H Y  C O N D O   I N S U R A N C E   -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section class=" bg-secondary" id="whats-included2">
	<div class="container pt-5 pb-5">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">WHAT’S INCLUDED IN PHONE INSURANCE?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>

		<div class="row justify-content-center text-center pb-5">
			<div class="col-8">
					You can customize your protection plan. Select complete coverage or just what worries you most, including:
			</div>
		</div>

		<div class="row pt-2">
			<div class="text-center col-md-12 col-xs-12">
				<ul class="pl-0">
					<li>Cracked screens</li>
					<li>Mechanical breakdowns</li>
					<li>Lost or stolen</li>
					<li>Water damage</li> 
				</ul>
			</div>
		</div>

		<!-- O N L I N E   Q U O T E -->

		<section id="online-quote">
			<div class="container online-quote pt-3 mt-1">
				<div class="row">
					<div class="col text-center">
						<span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us at <a href="tel:18885436179">888.543.6179</a>
							for a quote or questions regarding Phone Insurance.</span> <br><br>
					</div>
				</div>
				<div class="row">
					<div class="col text-center">
						<a class="show-cursor a-text-decoration-none" (click)="child.importantNotice($event, 'https://www.hellolori.com/partners/westwood?utm_source=westwood&utm_medium=propcas&utm_campaign=westwood_launch')"
						 href="#">
							<div class="angled-button-sm button-align-mob-center button-width-240">
								<span>CLICK HERE FOR QUOTE</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		</section>

		<!-- // O N L I N E   Q U O T E -->
	</div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center></app-knowledge-center>
</section>


<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->