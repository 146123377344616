<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-claim-reporting" class="sub">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
        <h2 class="text-center sub-h2">Report a claim</h2>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->


<!-- <section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a [href]="wpBaseUrl" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section> -->


<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!-- S E L E C T  I N S U R A N C E -->
<section id="payment-contact-and-website">
  <div class="container pt-5 pb-5">
    <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
    <div class="row">
      <div class="col">
        <h2 class="text-center">Contact your Insurance Company </h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-3">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" />
        <br><br>
        <p>Click to go directly to their claims page</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-9 col-xs-9">
        <div class="row">
          <input type="text" id="enter-state" onkeyup="myFunction()" placeholder="Enter your insurance company…">
        </div>
        <div class="row">
          <table id="license-table">
            <tr class="header text-center">
              <th><span>INSURANCE COMPANY</span>
              </th>
              <th class="text-center" colspan="2" style="text-transform: uppercase; "><span>24 Hour Reporting Information</span>
              </th>
            </tr>
            <tr *ngFor="let ClaimPageInfo of ClaimPageInfos">
              <td class="text-left"><span>{{ ClaimPageInfo.bankName }}</span>
              </td>
              <td>
                  <ng-container *ngIf="ClaimPageInfo.claimContact">
                    <img src="assets/img/icons/cell2.png" width="20" class="mr-2" /><span>
                      <a href="tel:{{ ClaimPageInfo.claimContact }}">{{
                        ClaimPageInfo.claimContact }}</a></span>
                  </ng-container>
              </td>
              <td>
                  <ng-container *ngIf="ClaimPageInfo.claimUrl">
                    <img src="assets/img/icons/claims-icon.png" width="30" class="mr-2" />
                    <a class="table-col-link" (click)="child.importantNotice($event, ClaimPageInfo.claimUrl)"
                      href="{{ ClaimPageInfo.claimUrl }}" target="_blank">Claims Page</a>
                  </ng-container>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- // S E L E C T  I N S U R A N C E -->
<section>
  <div class="container text-center pt-3 pb-5">
    <div class="row justify-content-center">
      <div class="col-md-9 col-xs-9">
        <p>
          If you need additional assistance, our agents are always here to assist you. <br><br>
          <img src="assets/img/icons/cell-phone.png" width="30" />&nbsp;&nbsp; <span><a
              href="tel:18006765066">800.676.5066</a></span>
        </p>
      </div>
    </div>
  </div>
</section>


<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section> -->
<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->