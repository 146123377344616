<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-flood" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-6 col-md-7 col-sm-12 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">Flood Insurance</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" >
	<div class="container pt-3 pb-5" >
		<div class="row justify-content-center">
			<div class="col-md-9 text-center col-xs-9">
				
				<p><span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
						questions regarding Flood Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
				</p>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y   F L O O D   -->

<section>
	<div class="container pt-3 pb-5" id="a-bullet-list">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center">Why Flood Insurance?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-6 pb-5 pet-img">
				<img src="assets/img/page-body-img/shutterstock_705371167.png" style="width: 100%;"
					alt="Family in home before flood" />
			</div>
			<div class="col-md-6">
				<ul>
					<li>A homeowner or renter insurance policy does not cover flood damage.</li>
					<li>You don’t have to live near a body of water to get flooded—heavy rains are often the cause of
						flooding.</li>
					<li>Floods and flash floods happen in all 50 states.</li>
					<li>Flood insurance costs less than you think.</li>
					<li>Just an inch of water can cause more than $25,000 of damage to your property.</li>
					<li>If you don’t live in a designated flood zone, your rates will be lower.</li>

				</ul>
			</div>
		</div>
	</div>
</section>

<!-- //  W H Y   F L O O D    -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section id="whats-included">
	<div class="container pt-5 pb-5">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">What's Included in flood insurance?</h2>
			</div>
		</div>
		<div class="row text-center justify-content-center  pt-2 pb-3">
			<div class="col-9">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
				<br><br>
				A dwelling and personal property flood policy offers protection for damage or losses up to the limits
				spelled out in your personal flood policy. Coverage includes:
			</div>
		</div>
		<div class="row pt-4">
			<div class="col-md-6 col-xs-12 text-center">
				<ul class="pl-0">
					<span>Home coverage</span><br><br>
					<li>Your home and its foundation</li>
					<li>Electrical and plumbing systems</li>
					<li>Air conditioning, furnaces and water heaters</li>
					<li>Kitchen appliances</li>
					<li>Flooring</li>
					<li>Bookcases and cabinets</li>
					<li>Window blinds</li>
					<li>Debris removal</li>

				</ul>
			</div>
			<div class="col-md-6 col-xs-12 text-center">
				<ul class="pl-0">
					<span>Personal property</span><br><br>
					<li>Clothing, furniture and electronic equipment</li>
					<li>Window coverings</li>
					<li>Window AC units</li>
					<li>Portable microwaves and dishwashers</li>
					<li>Carpets not covered by your building policy</li>
					<li>Dishwasher, washer, dryer and microwave ovens</li>
					<li>Freezer and food spoilage</li>
					<li>Up to $2,500 in art, jewelry and furs</li>
				</ul>
			</div>
		</div>
	</div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->


<!-- N A T I O N A L  F L O O D  I N S  &  F E M A  -->

<section>
	<div class="container pt-5 pb-5 bottom-border-element">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center"> The National Flood Insurance Program and FEMA </h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row justify-content-center" id="a-bullet-list">
			<div class="col-md-9 col-xs-9">
				<p>The definition of a flood per the National Flood Insurance Program and FEMA is: <br><bR>
					A general and temporary condition of partial or complete inundation of 2 or more acres of normally
					dry land area or of 2 or more properties (at least one of which is the policyholder’s property)
					from: </p>
				<ul>
					<li>Overflow of inland or tidal waters </li>
					<li>Unusual and rapid accumulation or runoff of surface waters from any source </li>
					<li>Mudflow</li>
				</ul>
				<small>Source: <a (click)="child.importantNotice($event,'http://www.floodsmart.gov')"
						href="http://www.floodsmart.gov" target="_blank">floodsmart.gov</a> </small>
				<br>
			</div>
		</div>
	</div>
</section>

<!-- // N A T I O N A L  F L O O D  I N S  &  F E M A  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->