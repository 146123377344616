import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { CommonService } from '../../service/common.service';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
declare function onLoad(): any;
declare function breadcrumbShow(): any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit,AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'insurance-products.af455617f33c5a68ecbb.jpg',
    desktopHeaderText: 'Insurance Products <br> We Offer',
    desktopHeaderSubText: "Westwood partners with the country's leading insurance companies to offer a full line of products.",
    mobileHeaderText: 'Insurance Products We Offer',
    mobileHeaderSubText: ''
  });
  
  constructor(public commonService: CommonService) { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
    //onLoad();
  } 
  ngAfterViewInit()
  {
    breadcrumbShow();
  }
  
}
