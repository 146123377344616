import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ClaimPageInfo } from '../model/claimPageInfo';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class ReportAclaimService {

  url: string;
  constructor(private dataService: DataService) { } 
  
  /*Get Lead  Details  */
  getClaimInfo(): Observable<Array<ClaimPageInfo>> {
    this.url = Controller.CurrentCustomer + '/' + Action.getclaimPageInfo; 
    return this.dataService.getAll(this.url); 
  } 
}
