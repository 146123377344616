import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-umbrella',
  templateUrl: './umbrella.component.html',
  styleUrls: ['./umbrella.component.css']
})
export class UmbrellaComponent implements OnInit,AfterViewInit {
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'umbrella-header.835e156529d9b0e9a227.jpg',
    desktopHeaderText: 'Umbrella Insurance',
    desktopHeaderSubText: "Umbrella insurance kicks in when you've exhausted the coverage limits of your home and auto policies, and may apply to claims those policies don't cover. ",
    mobileHeaderText: 'Umbrella Insurance',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit()
  {
    breadcrumbShow();
  }
  
}
