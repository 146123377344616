import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RequestDetails } from '../../model/requestSetails';
import { CommonService } from '../../service/common.service';
import { Constants } from '../../Utilities/Constants/constants';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
import { ContactUsService } from '../../service/contact-us.service';
import { Router } from '@angular/router';
declare var $: any;
declare var grecaptcha: any;

@Component({
  selector: 'app-pop-uprequest-details',
  templateUrl: './pop-uprequest-details.component.html',
  styleUrls: ['./pop-uprequest-details.component.css']
})
export class PopUPRequestDetailsComponent implements OnInit {

  contactUsForm: FormGroup;
  showForm: boolean = true;
  showMessage: boolean = false;
  displayMessage: string = '';
  formErrors: any = {};
  requestDetails: any;
  isLoading = false;
  captchaRequired = true;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  recaptcha: string;
  badge = 'Bottomright';
  pageCode: string = '';
  public formTitle: string = '';
  constructor(
    private contactUsService: ContactUsService,
    public commonService: CommonService,
    public constantsObj: Constants,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string

  ) {

    this.pageCode = router.url;
    var isSafari = this.commonService.isSafariBroswer();
    if (isSafari) {
      this.captchaRequired = false;
      this.siteKey = siteKeyValueV3;
    }
    else {
      this.siteKey = siteKeyValue;
    }
    if (!this.commonService.isSafariBroswer()) {
      $('.grecaptcha-badge').remove()
    }
    else {
      $('.grecaptcha-badge').css('display', 'block !important');
      $('.grecaptcha-badge').css('visibility', 'visible !important');
    }
  }

  ngOnInit() {
    this.requestDetails = new RequestDetails();
    this.formErrors = {};
    if (this.pageCode === '/partners/builders') {
      this.requestDetails.pageCode = 'PB';
      this.formTitle = 'Contact Us';
    }
    if (this.pageCode === '/partners/lenders') {
      this.requestDetails.pageCode = 'PL';
      this.formTitle = 'Contact Us';
    }
    if (this.pageCode === '/partners/api') {
      this.requestDetails.pageCode = 'PA';
      this.formTitle = 'Request Details';
    }
  }

  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  handleSuccess(event) {

    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA

  //Submit form
  onSubmit(contactUsForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(contactUsForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(contactUsForm);
    if (!contactUsForm.valid) {
      this.commonService.focusFirstInvalidElement();
      return;
    }
    //Post form through api call
    if (contactUsForm.valid && !this.captchaRequired) {
      this.isLoading = true;
      if (this.commonService.isSafariBroswer()) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
          if (token != '') {
            this.requestDetails.reCaptcha = new ReCaptcha();
            this.requestDetails.reCaptcha.response = token;
            this.requestDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
            this.PostData();
          }
        });
      }
      else
        this.PostData();

    } else {
      this.commonService.updateControls(contactUsForm);
    }
  }

  OnClickOk() {
    window.location.reload();
  }

  PostData() {

    this.contactUsService.postPartnerAPipageInfo(this.requestDetails).subscribe((data: any) => {
      this.isLoading = false;
      // if (data) {
      //   alert(data);
      // } else {
      //   alert("Something went wrong, please try again!!");
      // }
      this.showForm = false;
      this.showMessage = true;
      this.displayMessage = data;

    }, err => {
      this.isLoading = false;
      //this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      window.location.reload()
    });



  }

}
