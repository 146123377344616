import { LookUpValues } from "./look-up-values";
import { AdditionalCoveragePremium } from "./additionalCoveragePremium";
import { Deductibles } from "./deductibles";
import { AdditionalCoverage } from "./additionalCoverage";
import { applyNowBtnType } from "../Utilities/Constants/constants";

export class QuoteResult {
    public coverageAAmount?: any;
    public coverageBAmount?: any;
    
    public coverageAprint?:any;
    public coverageBprint?:any;
    public coverageCprint?:any;
    public coverageDprint?:any;
    public coverageEprint?:any;
    public coverageFprint?:any;


    public coverageCAmount?: any;
    public coverageDAmount?: any;
    public coverageEAmount?: any;
    public coverageFAmount?: any;
    public deductibleAmount?: number;
    public quoteNumber: string;
    public rcvTotal?: number;
    public totalPremium?: number;
    public monthlyPremium?: number;
    public oltPD?: number;
    public earthquakeCoverageAmount?: number;
    public earthquakeDeductible?: number;
    public insuranceCompanyId: string;
    public insuranceCompanyName: string;
    public coverageAAmountOptions: Array<LookUpValues>;
    public coverageCAmountOptions: Array<LookUpValues>;
    public coverageEAmountOptions: Array<LookUpValues>;
    public coverageFAmountOptions: Array<LookUpValues>;
    public alternateQuotes: Array<AdditionalCoveragePremium>;
    public deductibles: Array<Deductibles>;
    public deductiblesOptions: Array<LookUpValues>;
    public alternateQuotesOptions: Array<LookUpValues>;
    public quoteMessage: string;
    public status: boolean;
    public basePremiumAmt: any;
    public otherCharges:any;
    public earthquakeCoverageAmt: any;
    public rateDate: string;
    public policyFee: string;
    public sTSurcharge: any;
    public ratingMethod: number;
    public insuranceScoreHit: string;
    public tierCode: string;
    public minimumPremiumAdjustment: string;
    public policyID: string;
    public xMLExportID: string;
    public rCVIDNumber: string;
    public rCVType: string;
    public additionalCoverages: Array<AdditionalCoverage>;
    public applyNowBtnType:applyNowBtnType;
    public alternateFactorIDSelected: string;
    public rcvHtml: string;
    public policyTexts:  Array<LookUpValues>;
    public hideBtn: boolean;
    public isSameQuote?: boolean;
    public isRCVRun:boolean;
    public quoteNotAcceptedTaskID: string;
    constructor() {
        this.isSameQuote=false;
    }
}


