import { Component, OnInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;

@Component({
  selector: 'app-partner-with-us',
  templateUrl: './partner-with-us.component.html',
  styleUrls: ['./partner-with-us.component.css']
})
export class PartnerWithUsComponent implements OnInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'pwu-header.72019eb0ffcb927093ce.jpg',
    desktopHeaderText: 'Partner with Us',
    desktopHeaderSubText: "The easiest way to improve the home buying experience.",
    mobileHeaderText: 'Partner with Us',
    mobileHeaderSubText: ''
  });

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }
}
