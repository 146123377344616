import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn, FormGroup, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[validateDuplicatePhone][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DuplicatePhoneNumberDirective,
    multi: true
  }]
}) 
export class DuplicatePhoneNumberDirective implements Validator {
  validator: ValidatorFn;
  formErrors = {};
  fieldName:string;
  @Input('phoneOneName') inpPhoneOneName: string;
  @Input('phoneTwoName') inpPhoneTwoName: string;
  

  constructor() {
    this.validator = this.validateDuplicatePhone();
  }
  validate(c: FormControl): { [key: string]: any } {
    
    return this.validator(c);
  }
  validateDuplicatePhone(): ValidatorFn {
    return (c: FormControl) => { 
      if (c.value == null || c.value == undefined || c.value == '' ) {
        if(
          c.parent.controls[this.inpPhoneTwoName] != undefined &&
          c.parent.controls[this.inpPhoneOneName] != undefined
          ){
            if (c.parent.controls[this.inpPhoneOneName].hasError('validateDuplicatePhone')) {
              delete c.parent.controls[this.inpPhoneOneName].errors['validateDuplicatePhone'];
              c.parent.controls[this.inpPhoneOneName].updateValueAndValidity();
            }
            if (c.parent.controls[this.inpPhoneTwoName].hasError('validateDuplicatePhone')) {
              delete c.parent.controls[this.inpPhoneTwoName].errors['validateDuplicatePhone'];
              c.parent.controls[this.inpPhoneTwoName].updateValueAndValidity();
            }
        }
        return null;
      }
      //gets the current field name
    this.fieldName= Object.keys(c.parent.controls).find(name => c === c.parent.controls[name]);
    if (c.parent.controls[this.inpPhoneTwoName] && c.parent.controls[this.inpPhoneOneName]) {
        if (c.parent.controls[this.inpPhoneTwoName].value != '' && c.parent.controls[this.inpPhoneOneName].value != ''
          && c.parent.controls[this.inpPhoneOneName].value != undefined
          && c.parent.controls[this.inpPhoneOneName].value != ''
          && c.parent.controls[this.inpPhoneTwoName].value != undefined
          && c.parent.controls[this.inpPhoneTwoName].value != '') {
          if (c.parent.controls[this.inpPhoneTwoName].value == c.parent.controls[this.inpPhoneOneName].value) {
            //
           if(this.fieldName==this.inpPhoneOneName)
           {            
            //add error for the alternateTypes
            //control.setErrors({backend: {someProp: "Invalid Data"}});
            c.parent.controls[this.inpPhoneTwoName].setErrors({'validateDuplicatePhone': true})
           }
           else
           {
            //add error for the phoneTypes
            c.parent.controls[this.inpPhoneOneName].setErrors({'validateDuplicatePhone': true})
           }
            return {
              validateDuplicatePhone: {
                valid: false
              }
            };
          }
          else{
            if (c.parent.controls[this.inpPhoneOneName].hasError('validateDuplicatePhone')) {
              delete c.parent.controls[this.inpPhoneOneName].errors['validateDuplicatePhone'];
              c.parent.controls[this.inpPhoneOneName].updateValueAndValidity();
            }
            if (c.parent.controls[this.inpPhoneTwoName].hasError('validateDuplicatePhone')) {
              delete c.parent.controls[this.inpPhoneTwoName].errors['validateDuplicatePhone'];
              c.parent.controls[this.inpPhoneTwoName].updateValueAndValidity();
            }
            // c.parent.controls[this.inpPhoneOneName].setErrors({'validateDuplicatePhone': false})
            // c.parent.controls[this.inpPhoneTwoName].setErrors({'validateDuplicatePhone': false})
          }
        }
      }
      return null;
    };
  }
}
