<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-ins-licensing" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">Insurance Licensing</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none">
	<div class="container text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<p>
					<span>We are licensed in all 50 states</span><br><br> Our Corporate Headquarters is located at: <br>
					<b>
						6320 Canoga Avenue, Suite 500 <br>
						Woodland Hills, CA 91367
					</b>
				</p>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->



<!-- MAP -->
<app-service-area-map class="d-none d-lg-block"></app-service-area-map>
<!-- MAP -->

<section id="intro-para" class="d-none d-lg-block">
	<div class="container text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<p>
					Our Corporate Headquarters is located at: <br><br>
					<span>
						6320 Canoga Avenue, Suite 500<br>
						Woodland Hills, CA 91367
					</span>
				</p>
			</div>
		</div>
	</div>
</section>

<!-- S T A T E S -->

<section id="license-numbers-by-state" class="d-lg-none">
	<div class="container top-border-element  mb-5 pb-5 pt-5">

		<div class="row">
			<div class="col">
				<h2 class="col text-center"> License Numbers by State</h2>
			</div>
		</div>

		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>


		<div class="row justify-content-center license-numbers">
			<div class="col-md-9 col-xs-9">

				<!-- <div class="row">
        		<input type="text" title="Enter your state" id="enter-state" onkeyup="myFunction()" placeholder="Enter your state..."> 
        	</div> -->

				<div class="row">
					<table id="license-table">
						<tr class="header">
							<th style="width:30%;"><span>STATE</span></th>
							<th style="width:35%;"><span>P&C LICENSE NUMBER</span></th>
							<th style="width:35%;"><span>SURPLUS LICENSE NUMBER</span></th>
						</tr>

						<tr *ngFor="let InsurnaceDeatil of InsurnaceDeatils">
							<td><span>{{InsurnaceDeatil.state}}</span></td>
							<td>{{InsurnaceDeatil.pcLicenseNumber}}</td>
							<td>{{InsurnaceDeatil.surplusLicenseNumber}}</td>
						</tr>


					</table>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- // S T A T E S  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center></app-knowledge-center>
</section>
<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->