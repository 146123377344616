import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare var jQuery: any;
declare function breadcrumbShow(): any;

@Component({
  selector: 'app-home-warranty-service-agreement',
  templateUrl: './home-warranty-service-agreement.component.html',
  styleUrls: ['./home-warranty-service-agreement.component.css']
})
export class HomeWarrantyServiceAgreementComponent implements OnInit, AfterViewInit {
  categoryID: number = 1;
  constructor() { }

  ngOnInit(): void {
  }
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'homewarrantyheader.jpg',
    desktopHeaderText: 'Home Warranty Service Agreement',
    desktopHeaderSubText: "Important home systems and appliances can break down from routine use. Protect your home and your budget from unexpected and expensive repairs or replacements.",
    mobileHeaderText: 'Home Warranty Service Agreement',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="https://www.2-10.com/get-a-quote-westwoodcx" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://www.2-10.com/get-a-quote-westwoodcx" target="_blank" class=" btn ww-button">Get a Quote</a>'
  });

  importantNotice(event, link) {
    event.preventDefault();
    if (link !== '') {
      jQuery('#leavingModalLink').attr('href', link);
      jQuery('#leavingModal').modal();
    }
  }
  ngAfterViewInit() {
    breadcrumbShow();
  }

}
