<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-jffa" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Jewelry & Valuables Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
    <div class="container pt-3 pb-5">

        <div class="row justify-content-center">
            <div class="col-md-9 text-center col-xs-9">
                <p>
                    <!-- <span>Most homeowner, condo and renter insurance policies limit the amount of coverage for
                        especially valuable items of personal property including jewelry, antiques, fine art, musical
                        equipment, firearms and collectibles.</span><br>
                    Loss or damage to these items is also subject to your
                    policy’s deductible, which could be substantial. You can protect your valuables for full
                    replacement value by asking your agent to "schedule" them on your homeowner, condo or renter policy
                    with a “scheduled personal property endorsement”.<br><br>-->
                    <span> 
                        <img src="assets/img/icons/cell-phone.png" width="30" alt="cellphone icon"> Call us for a
                        quote or questions regarding Jewelry and Valuables Insurance at <a
                            href="tel:18885436179">888.543.6179</a></span>

                </p>
            </div>
        </div>

    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  U M B R E L L A    -->

<section>
    <div class="container pt-3 pb-5" id="a-bullet-list">

        <div class="row pt-2 text-center">
            <div class="col pb-5 text-center">
                <h2 class="col">WHY SHOULD YOU CONSIDER SCHEDULING YOUR VALUABLES?</h2>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row" id="a-bullet-list">
            <div class="col-md-6 col-xs-12 pb-5 pet-img">
                <img src="assets/img/page-body-img/shutterstock_1369753250.png" style="width: 100%;" alt="Fine Art" />
            </div>
            <div class="col-md-6 col-xs-12">
                <ul>
                    <li>Your homeowner, condo or renter policy provides only limited coverage for lost or damaged high
                        value property.</li>
                    <li>If a scheduled item is lost, it may not be subject to your policy’s deductible or may have
                        a smaller deductible.</li>
                    <li>The policy covers all risks, including mysterious disappearance.</li>
                    <li>If you file a claim, your settlement is the amount for which you scheduled the item.</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<!-- //  W H Y  U M B R E L L A    -->

<!-- W H A T 'S   I N C L U D E D ... -->

<section id="whats-included" class="custom-class">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">WHAT’S INCLUDED IN JEWELRY AND VALUABLES INSURANCE?</h2>
            </div>
        </div>
        <div class="row text-center justify-content-center  pt-2 pb-3">
            <div class="col-9">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
                <br><br>
                A scheduled personal property endorsement provides coverage for your valuable items at their current
                replacement value--for an additional premium. The amount of coverage is usually determined by a recent
                appraisal or a receipt for a newly purchased item.<br><br>
                For jewelry, a certified gemologist or reputable jeweler can provide an appraisal for a fee. They will
                prepare a detailed written description of your jewelry including estimated replacement cost.
            </div>
        </div>
    </div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section>
<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->