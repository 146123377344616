import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs'; 
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';
import { stateLicenseNumber } from '../model/stateLicenseNumber';

@Injectable({
  providedIn: 'root'
})
export class InsuranceLicensingService { 
  url: string;
  constructor(private dataService: DataService) { } 
  
  /*Get Lead  Details  */
  getInsuranceLicensingInfo(): Observable<Array<stateLicenseNumber>> {
    this.url = Controller.WestwoodHome + '/' + Action.getInsuranceLicensingtInfo; 
    return this.dataService.getAll(this.url); 
  } 
}
