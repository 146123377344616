<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-pwu" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Partner with us</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->

<!-- //  H E A D E R   I M G   -->

<!-- H O M E   H E R O   -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>
<!-- // H O M E   H E R O  -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->

<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
  <div class="container text-center pt-3 pb-3">
    <div class="row justify-content-center">
      <div class="col-md-10 col-xs-9">
        <p>
          <span>Together, let’s create an easy, streamlined home closing experience for our team members and the
            customers we serve.</span>
        </p>
      </div>
    </div>
  </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->

<!-- F U L L - S E R V I C E   P R O V I D E R -->

<section>
  <div class="container  pt-2 pb-5">
    <div class="row pt-2" style="display: none;">
      <div class="col">
        <h2 class="col text-center">A full - service provider </h2>
      </div>
    </div>
    <!-- <div class="row pt-2">
      <div class="col">
        <h2 class="col text-center"> Our record of consistent performance has earned decades of trust from our partners
          and their customers. </h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-5">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-6 pb-3">
        <img src="assets/img/page-body-img/shutterstock_1473623489.png" style="width: 100%;"
          alt="House in building phase" />
      </div>
      <div class="col">
        <b>How it works</b><br> Westwood’s innovative technology seamlessly integrates with virtually all platforms to
        help companies across the entire home buying process deliver insurance coverage for consistently smooth, on-time
        closings.<br><br>

        Our quick and hassle-free insurance process is just one of the reasons why many of the largest home builders,
        mortgage companies and more have trusted Westwood for 70 years.

        <br><br>
        <span><img src="assets/img/icons/env.png" width="27" alt="Email icon" class="mr-2" />Contact us at <a
            href="mailto:partner@westwoodins.com" target="_top">partner@westwoodins.com</a> to learn more</span>

      </div>
    </div>
  </div>
</section>

<!-- // F U L L - S E R V I C E   P R O V I D E R -->


<!-- S T R A T E G I C   P A R T N E R S H I P S   -->

<section>
  <div class="container top-border-element pb-5 pt-5">
    <div class="row pt-2">
      <div class="col">
        <h2 class="col text-center"> BENEFITS OF PARTNERSHIP </h2>
      </div>
    </div>


    <div class="row text-center pt-2 pb-5">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>

    <div class="row prevent-cards" id="sp-bullet-list">

      <div class="col-md-6">
        <div class="row">
          <div class="col-2">
            <img src="assets/img/icons//house-icon.png" alt="House icon" width="70" />
          </div>
          <div class="col pwu-bl">
            <h6>New Home Builders</h6>
            <ul>
              <li>More control of the closing process</li>
              <li>Enhanced home buyer experience</li>
              <li>Additional revenue with easy-to-implement program</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-2">
            <img src="assets/img/icons//house-bank.png" alt="House bank icon" width="70" />
          </div>
          <div class="col  pwu-bl">
            <h6>Mortgage Companies</h6>
            <ul>
              <li>More borrowers qualified for their loan </li>
              <li>Fast and easy policy issuance </li>
              <li>Meet closing deadlines </li>
              <li>Increased processing efficiencies</li>
            </ul>
          </div>
        </div>
      </div>

    </div>


    <div class="row prevent-cards pt-4" id="sp-bullet-list">

      <div class="col-md-6">
        <div class="row">
          <div class="col-2">
            <img src="assets/img/icons//property.png" alt="Buildings icon" width="70" />
          </div>
          <div class="col  pwu-bl">
            <h6>Property Managers/Landlords </h6>
            <ul>
              <li>Tracking to ensure insurance is in place </li>
              <li>Landlord Liability Protection and Deposit Insurance availability </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-2">
            <img src="assets/img/icons/other.png" alt="people icon" width="70" />
          </div>
          <div class="col  pwu-bl">
            <h6>Other</h6>

            <ul>
              <li>Various programs tailored to your needs</li>
              <li>Generate additional revenue and maximize customer relationships</li>
              <li>Insurance capability in all 50 states</li>
            </ul>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-12 justify-content-center pt-2 pb-5">
      <p><span><a href="assets/westwood_partner_digital_platform.pdf" target="_blank">LEARN MORE</a></span> about
        Westwood’s fully digital insurance platform and how we’re delivering a better experience for your team and
        customers. </p>
      <p>
        Westwood is ranked in the top 30 P&C agencies in the US and backed by the strength and stability of QBE, a top
        20 global insurer and reinsurer.
      </p>
    </div>
  </div>
</section>

<!-- //  S T R A T E G I C   P A R T N E R S H I P S    -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->