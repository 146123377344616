<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-trav" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Travel Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y   T R A V E L   I N S U R A N C E  -->

<section id="why-trav-ins">
    <div class="container  why-trav-ins pt-3 pb-5">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center">Why travel insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-4">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row" id="a-bullet-list">
            <div class="col-md-6 pb-2" id="family-at-airport">
                <img src="assets/img/page-body-img/traveling.png" style="width: 100%;" alt="Family at Airpport" />
            </div>
            <div class="col-md-6">
                <p>
                    Going on a trip should be an exciting time, a time to create long-lasting memories, and not a time
                    to spend worrying about unexpected events you have little control over.
                    <br><br>
                    Delays and cancellations are all too common these days, and one thing that goes wrong can trigger a
                    domino effect on the rest of your plans.
                    <br><br>
                    Travel insurance will reimburse you for pre-paid and nonrefundable expenses if the trip is canceled
                    for a covered reason. It’s affordable peace-of-mind protection.
                </p>
            </div>
        </div>
    </div>
</section>

<!-- // W H Y   T R A V E L   I N S U R A N C E  -->


<!-- W H A T S   I N C L U D E D   I N   T R A V E L  I N S U R A N C E  -->

<section id="whats-included">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">What's Included in Travel insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
                <br><br>
                <p>Travel insurance covers these expenses: </p>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-12 text-center">
                <ul class="pl-0">
                    <li>Trip cancellation/interruption</li>
                    <li>Baggage loss/damage</li>
                    <li>Emergency medical/dental coverage</li>
                    <li>Travel delay</li>
                    <li>Rental car protection</li>
                    <li>Emergency medical transportation</li>
                    <li>Travel accident coverage</li>
                </ul>
            </div>
        </div>

        <!-- O N L I N E   Q U O T E -->

        

        <!-- // O N L I N E   Q U O T E -->
    </div>
</section>

<!-- // W H A T S   I N C L U D E D   I N   T R A V E L   I N S U R A N C E  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->