<div class="modal fade" id="leavingModal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <span>Important Notice</span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p><strong>You are leaving westwoodinsurance.com</strong></p>
                <p>By clicking <strong>Continue</strong> you acknowledge:</p>
                <p>You are leaving Westwood Insurance Agency’s website. You will be taken to a website that is not
                    affiliated with Westwood Insurance Agency and offers a different privacy policy and level of
                    security.</p>
                <p>Westwood Insurance Agency and its affiliates are not responsible for and do not guarantee or monitor
                    content or viewpoints expressed on this website.</p>
                <p>The third party is solely responsible for the content and offerings presented on its website.</p>
                <p>You can select the <strong>Decline</strong> button if you do not want to proceed to the unaffiliated
                    third party’s website, or if you do not want to acknowledge the items noted above.</p>
            </div>
            <div class="modal-footer">
                <a href="#" id="leavingModalLink" class="text-orange" target="_blank" (click)="this.continueButton($event)"><strong>Continue</strong> </a>
                <a href="#" class="text-blue" target="_blank" aria-hidden="true" data-dismiss="modal" aria-label="Close"><strong>Decline</strong>
                </a>
            </div>
        </div>
    </div>
</div>