import { Component, OnInit, Inject, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { Constants } from '../../Utilities/Constants/constants';
import { Contactus } from '../../model/contactus';
import { FormGroup } from '@angular/forms';
import { ContactUsService } from '../../service/contact-us.service';
import { DataService } from '../../service/data.service';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
import { CurrentCustomer } from '../../model/currentCustomer';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare var $: any;
declare var grecaptcha: any;
declare function breadcrumbShow(): any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, AfterViewChecked {
  contactUsForm: FormGroup;
  formErrors: any = {};
  isLoading = false;
  captchaRequired = true;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  recaptcha: string;
  badge = 'Bottomright';

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/contact-header.jpg',
    desktopHeaderText: "We're Here to Help",
    desktopHeaderSubText: "Send us a message or give us a call.",
    mobileHeaderText: 'Contact Us',
    mobileHeaderSubText: ''
  });

  constructor(
    public router: Router,
    public commonService: CommonService,
    public constantsObj: Constants,
    public contactUsDetails: Contactus,
    private route: ActivatedRoute,
    private contactUsService: ContactUsService,
    public dataService: DataService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string
  ) {
    var isSafari = this.commonService.isSafariBroswer();
    if (isSafari) {
      this.captchaRequired = false;
      this.siteKey = siteKeyValueV3;
    }
    else {
      this.siteKey = siteKeyValue;
    }
    if (!this.commonService.isSafariBroswer()) {
      $('.grecaptcha-badge').remove()
    }
    else {
      $('.grecaptcha-badge').css('display', 'block !important');
      $('.grecaptcha-badge').css('visibility', 'visible !important');
    }
  }

  ngOnInit() {
    this.contactUsDetails = new Contactus();
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  handleSuccess(event) {

    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA

  //Submit form
  onSubmit(contactUsForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(contactUsForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(contactUsForm);
    if (!contactUsForm.valid) {
      this.commonService.focusFirstInvalidElement();
      return;
    }
    //Post form through api call
    if (contactUsForm.valid) {
      this.isLoading = true;
      if (this.commonService.isSafariBroswer()) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
          if (token != '') {
            this.contactUsDetails.reCaptcha = new ReCaptcha();
            this.contactUsDetails.reCaptcha.response = token;
            this.contactUsDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
            this.PostData();
          }
        });
      }
      else
        this.PostData();

    } else {
      this.commonService.updateControls(contactUsForm);
    }
  }
  PostData() {
    this.contactUsService.postContactpageInfo(this.contactUsDetails).subscribe((data: any) => {
      this.isLoading = false;
      if (data) {
        alert(data);
      } else {
        alert("Something went wrong, please try again!");
      }
      window.location.reload()
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      window.location.reload()
    });

  }
  ngAfterViewChecked() {


  }

}
