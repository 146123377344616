import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-current-customers',
  templateUrl: './current-customers.component.html',
  styleUrls: ['./current-customers.component.css']
})
export class CurrentCustomersComponent implements OnInit ,  AfterViewInit{

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'currentcustomers-header.b5d84b2f87c1251dac5f.jpg',
    desktopHeaderText: 'Thank You for Being a <br> Westwood Customer',
    desktopHeaderSubText: "Whether you want to review your insurance needs, add coverage to your policies or get a new quote, we're here to help.",
    mobileHeaderText: 'Current Customers',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    breadcrumbShow();
  }
  

}
