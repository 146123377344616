import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { CustNoticeService } from "src/app/service/cust-notice.service";
import { CommonService } from "../../service/common.service";
import { Constants } from "../../Utilities/Constants/constants";
import { DataService } from "../../service/data.service";
import { Lead } from "../../model/lead";
import { User } from "src/app/model/user";
import { DomSanitizer } from "@angular/platform-browser";
import { PropUnderwritingService } from "../../service/prop-underwriting.service";
import { RouterExtService } from "src/app/service/router-ext.service";
import { WizardComponent } from "angular-archwizard";

@Component({
  selector: "app-quote-message",
  templateUrl: "./quote-message.component.html",
  styleUrls: ["./quote-message.component.css"],
})
export class QuoteMessageComponent implements OnInit {
  AgentPic = "";
  // leadDetails: Lead;
  LiveChatImage = "";
  isLoading = false;
  @Input('mainWizard') public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();
  constructor(
    public router: Router,
    public commonService: CommonService,
    public constantObj: Constants,
    private sanitizer: DomSanitizer,
    public custNoticeService: CustNoticeService,
    public dataService: DataService,
    public propService: PropUnderwritingService,
    private routerExtService: RouterExtService
  ) {
    this.LiveChatImage = constantObj.LiveChatImage;
  }
  photoURL() {
    if (
      this.leadDetails.salesAgent.salesAgentPhoto != null ||
      this.leadDetails.salesAgent.salesAgentPhoto != ""
    ) {
      return this.sanitizer.bypassSecurityTrustUrl(
        this.leadDetails.salesAgent.salesAgentPhoto
      );
    } else {
      return "";
    }
  }
  ngOnInit() {
    // this.commonService.SaveLeadHistory();
    this.commonService.ScrollToRTQForm();
    this.dataService.leadHistory.pageNumber = 5;
    this.isLoading = true;
    // this.leadDetails = this.dataService.leadDetails;
    if (this.leadDetails != null && this.leadDetails.salesAgent == undefined) {
      this.leadDetails.salesAgent = new User();
    }
    this.dataService.leadHistory.preValue = JSON.parse(
      JSON.stringify(this.leadDetails)
    );
    this.propService.postPropUnderwritingInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.leadDetails = data;
        this.dataService.leadHistory.postValue = JSON.parse(
          JSON.stringify(data)
        );
        this.commonService.SaveLeadHistory();
        this.leadDetails.propertyUnderwriting = data.propertyUnderwriting;
        this.isLoading = false;

        //#check whether to navigate to State Disclosure or Quote Result Page
        // if (this.leadDetails.isDisclosurePending  &&
        //   this.leadDetails.disclosureInfo.title != '' && this.leadDetails.disclosureInfo.text != '' && this.leadDetails.quoteResult.quoteMessage =='') {
        //   this.commonService.NavigateToPage('/real-time-quote/cust-notice');
        // }
        // else {
        //   this.commonService.NavigateToPage('/real-time-quote/quote-result');
        // }
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }

  acceptDisclaimer() {
    this.dataService.isDisclaimerPending = false;
    this.leadDetails.disclosureInfo.readDisclosure = true;
    this.custNoticeService
        .postDisclosureFlag(this.leadDetails)
        .subscribe((data: Lead) => {
          this.leadDetails = data;
          this.dataService.isDisclaimerRequired = false;
          this.isLoading = false;
          if(!this.leadDetails.quoteResult.status) {
            this.dataService.leadDetails = new Lead();
            this.leadUpdateEvent.emit(new Lead());
            this.wizardStep.goToNextStep();
          }
        }, err => {
          this.isLoading = false;
          this.dataService.handleError(err);
      });
    
  }

  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
  }
}
