// import { Injectable } from '@angular/core';
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor
// } from '@angular/common/http';
// import { Observable } from 'rxjs';

// @Injectable()
// export class UniversalInterceptorInterceptor implements HttpInterceptor {

//   constructor() {}

//   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//     return next.handle(request);
//   }
// }


import {
    HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse,
    HttpEvent
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from "rxjs/operators";
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class UniversalInterceptor implements HttpInterceptor {

    constructor(@Optional() @Inject(REQUEST) protected request: Request,
        private cookieService: CookieService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let serverReq: HttpRequest<any> = req;
        // if (this.request && req.url.indexOf('http') !== 0) {
        //   let newUrl = `${this.request.protocol}://${this.request.get('host')}`;
        //   debugger
        //   //let newUrl = 'http://localhost:3008/api/';
        //   if (!req.url.startsWith('/')) {
        //     newUrl += '/';
        //   }
        //   newUrl += req.url;
        //   serverReq = req.clone({url: newUrl});
        // }
        ///realtimequoteservice/api/
        //undefined

        if (req.method === "POST") {
            if (req.url.indexOf('Utility/SetCSRFToken') === -1) {
                serverReq = req.clone({
                    withCredentials: true,
                    setHeaders: {
                        'GUID-Token': this.cookieService.get('GUID-Token'),
                        'XSRF-TOKEN': this.cookieService.get('XSRF-TOKEN')
                    }
                });
            } else {
                serverReq = req.clone({
                    withCredentials: true,
                    setHeaders: {
                        'GUID-Token': this.cookieService.get('GUID-Token'),
                    }
                });
            }
        }

        // if (req.url.includes('undefined')) {
        //     let newUrl = req.url.replace('undefined', 'realtimequoteservice/api/');
        //     serverReq = req.clone({ url: newUrl });
        // }
        // pr log
        // console.log(serverReq);
        // pr log ends
        //return next.handle(serverReq);

        return next.handle(serverReq).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error(`HTTP Request for ${req.url} failed, trying direct filesystem access.`);
                try {
                    if (error instanceof HttpErrorResponse) {
                        const errResp = <HttpErrorResponse>error;
                        if (errResp.status == 401) {
                            this.router.navigate(['/unauthorized-access'], { skipLocationChange: false });
                            //this.commonService.NavigateToPage('/unauthorized-access');
                        }
                    }

                } catch (e) {
                    return throwError(e);
                }
            })
        );

        // return next.handle(serverReq).pipe(
        //     catchError(
        //         err =>
        //             new Observable<HttpEvent<any>>(observer => {
        //                 if (err instanceof HttpErrorResponse) {
        //                     const errResp = <HttpErrorResponse>err;
        //                     if (errResp.status == 401) {
        //                         this.router.navigate(['/unauthorized-access'], { skipLocationChange: false });
        //                         //this.commonService.NavigateToPage('/unauthorized-access');
        //                     }
        //                 }
        //             })
        //     )
        // );
    }
}