import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-insurance-products',
  templateUrl: './insurance-products.component.html',
  styleUrls: ['./insurance-products.component.css']
})
export class InsuranceProductsComponent implements OnInit {

  constructor(public commonService: CommonService ) { }

  ngOnInit() {
  }

}
