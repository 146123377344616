import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { of } from "rxjs";
import { WizardComponent } from "angular-archwizard";

import { Constants } from "./../../Utilities/Constants/constants";

import { Router } from "@angular/router";
import { CommonService } from "../../service/common.service";
import { DataService } from "../../service/data.service";
import { Lead } from "../../model/lead";
import { FormGroup } from "@angular/forms";

import { CreditAuth } from "../../model/credit-auth";
import { CreditAuthService } from "../../service/credit-auth.service";
import { RouterExtService } from "../../service/router-ext.service";
import { ModelPopupComponent } from "../../shared/model-popup/model-popup.component";
import { LeadHistory } from "../../model/leadHistory";
import { HomeInfoServiceService } from "src/app/service/home-info-service.service";
declare var $: any;

@Component({
  selector: "app-credit-auth",
  templateUrl: "./credit-auth.component.html",
  styleUrls: ["./credit-auth.component.css"],
})
export class CreditAuthComponent implements OnInit {
  creditAuthForm: FormGroup;
  LiveChatImage = "";
  AgentPic = "";
  formErrors: any = {};
  // leadDetails: Lead;
  isLoading = false;
  isExclude: boolean;
  hideSSN = false;
  @ViewChild(ModelPopupComponent) popUp: ModelPopupComponent;
  @Input("mainWizard") public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();
  leadCopy: Lead;

  constructor(
    public router: Router,
    public commonService: CommonService,
    public constantsObj: Constants,
    public dataService: DataService,
    public creditAuthService: CreditAuthService,
    private routerExtService: RouterExtService,
    public homeService: HomeInfoServiceService
  ) {
    this.LiveChatImage = constantsObj.LiveChatImage;
  }

  ngOnInit() {
    this.commonService.ScrollToRTQForm();
    this.commonService.SaveLeadHistory();
    this.dataService.leadHistory.pageNumber = 2;
    this.leadDetails = this.dataService.leadDetails;
    // if (this.leadDetails != null && this.leadDetails.creditAuth == undefined)
    //   this.leadDetails.creditAuth = new CreditAuth();
    // if (this.leadDetails.creditAuth.ssn == "") this.hideSSN = false;
    // else this.hideSSN = true;
  }
  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }
  savePageDetails() {
    this.isLoading = true;
    this.leadDetails.contactMeButtonClicked = true;
    this.hideSSN = true;
    this.commonService.CreateLeadHistoryObj(2, this.leadDetails);
    this.creditAuthService.postLeadInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.commonService.SaveLeadHistoryFromContactBtn();
        this.leadDetails = data;
        this.isLoading = false;
        // return true;
        // this.commonService.NavigateToPage('/real-time-quote/contact');
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
        return false;
      }
    );
  }
  onSubmit(creditAuthForm) {
    this.dataService.leadHistory.noUpdate = creditAuthForm["form"].pristine;
    this.formErrors = {};
    this.formErrors = this.commonService.updateControls(creditAuthForm);
    if (creditAuthForm.valid) {
      this.commonService.ScrollToRTQForm();
      this.hideSSN = true;
      this.isLoading = true;
      this.creditAuthService.postLeadInfo(this.leadDetails).subscribe(
        (data: Lead) => {
          this.leadDetails = data;
          // this.commonService.NavigateToPage('/real-time-quote/home-info');
          this.leadCopy = JSON.parse(
            JSON.stringify(this.dataService.leadHistory.preValue)
          );
          this.homeService
            .getHomeInfo(this.leadDetails)
            .subscribe((data: Lead) => {
              this.dataService.leadHistory.preValue = JSON.parse(
                JSON.stringify(this.leadCopy)
              );
              this.dataService.leadHistory.pageNumber = 99;
              this.dataService.leadHistory.noUpdate = false;
              this.commonService.SaveLeadHistory();
              this.dataService.leadHistory.preValue = JSON.parse(
                JSON.stringify(data)
              );
              this.dataService.leadHistory.pageNumber = 3;
              this.dataService.leadHistory.preValue.policyTypeID =
                this.dataService.leadDetails.policyTypeID;
              this.dataService.leadHistory.preValue.homeInfo.homeType =
                this.dataService.leadHistory.postValue.homeInfo.homeType;
              this.dataService.leadHistory.preValue.homeInfo.percentageFinished =
                this.dataService.leadHistory.postValue.homeInfo.percentageFinished;
              this.dataService.leadHistory.preValue.homeInfo.roofShape =
                this.dataService.leadHistory.postValue.homeInfo.roofShape;
              this.dataService.leadHistory.preValue.homeInfo.roofType =
                this.dataService.leadHistory.postValue.homeInfo.roofType;
              this.leadDetails.homeInfo = data.homeInfo;
              this.setDropdownValuesforHomeInfo();
              // this.setInformationMessage();
              this.leadUpdateEvent.emit(this.leadDetails);
              this.isLoading = false;
              this.wizardStep.goToNextStep();
            });
          return true;
        },
        (err) => {
          this.isLoading = false;
          this.dataService.handleError(err);
          return false;
        }
      );
    } else {
      if (!creditAuthForm.valid) {
        this.commonService.focusFirstInvalidElement();
        return false;
      }
    }
  }

  updateCreditAuthorization(IscreditAuthorized) {
    if (IscreditAuthorized.value) {
      this.leadDetails.creditAuth.creditAuthorized = "true";
    } else {
      this.leadDetails.creditAuth.creditAuthorized = "false";
    }
  }

  setDropdownValuesforHomeInfo() {
    if (this.leadDetails.homeInfo != null) {
      if (this.leadDetails.homeInfo.constructionType == null)
        this.leadDetails.homeInfo.constructionType = "";
    }
    if (this.leadDetails.homeInfo.homeType == null) {
      this.leadDetails.homeInfo.homeType = "";
    }
    if (this.leadDetails.homeInfo.propertyType == null) {
      this.leadDetails.homeInfo.propertyType = "";
    }
    if (this.leadDetails.homeInfo.garageType == null) {
      this.leadDetails.homeInfo.garageType = "";
    }

    if (this.leadDetails.homeInfo.foundationType == null) {
      this.leadDetails.homeInfo.foundationType = "";
    }
    if (this.leadDetails.homeInfo.roofType == null) {
      this.leadDetails.homeInfo.roofType = "";
    }
    if (this.leadDetails.homeInfo.unitNumber == null) {
      this.leadDetails.homeInfo.unitNumber = 0;
    }
    if (this.leadDetails.homeInfo.roofShape == null) {
      this.leadDetails.homeInfo.roofShape = "";
    }
  }
}