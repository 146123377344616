import { Component, OnInit, AfterViewChecked, AfterViewInit, ViewChild } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-condo',
  templateUrl: './condo.component.html',
  styleUrls: ['./condo.component.css']
})
export class CondoComponent implements OnInit, AfterViewInit {
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'condo-header.b7c1f5b9c38abe441523.jpg',
    desktopHeaderText: 'Condo and Townhome Insurance',
    desktopHeaderSubText: "Let a Westwood agent help you determine exactly what your condo association’s master insurance policy covers — and doesn’t cover — so you get the right protection.",
    mobileHeaderText: 'Condo and Townhome Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="/real-time-quote/cust-info" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="/real-time-quote/cust-info" target="_blank" class=" btn ww-button">Get a Quote</a>'
  });
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

}
