import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ArticlesMainView, ArticlesDetail, ArticlesDetailView } from '../model/article';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class NewsArticlesService {
  url: string;
  constructor(private dataService: DataService) { }
  /*Get alert messages Details  */
  getArticlesOnLoad(parameters: string): Observable<ArticlesMainView> {
    this.url = Controller.Article + '/' + Action.OnLoad + "?" + parameters;
    return this.dataService.getAll(this.url);
  }
  getArticlesOnSearch(parameters: string): Observable<ArticlesMainView> {
    this.url = Controller.Article + '/' + Action.OnSearch + "?" + parameters;
    return this.dataService.getAll(this.url);
  }
  getArticleDetailsOnLoad(articleTitle: string): Observable<ArticlesDetailView> {
    this.url = Controller.Article + '/' + Action.GetDetails + "?ArticleTitle=" + articleTitle;
    return this.dataService.getAll(this.url);
  }

  getArticleNo(title: any): Observable<ArticlesMainView> {
    this.url = Controller.Article + '/' + Action.GetArticleNo + "?title=" + title;
    return this.dataService.getAll(this.url);
  }
}
