<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->

<div class="container-lg">
    <div class="row ">
        <div class="col-12 text-center">
            <h2 class="">Close on time, every time</h2>
        </div>
    </div>
    <div class="row text-center">
        <div class="col">
            <img src="assets/img/orange-divider-transparent.png" alt="">
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <h3 class="h5 mb-4 font-weight-bold">Avoid last-minute surprises with a more transparent process</h3>
        </div>
    </div>

    <div class="row my-3 mx-md-5">
        <div class="col-12 col-md-6 col-lg-3">
            <div class="api-info flex-fill">
                <div class="api-title d-flex justify-content-start align-items-center">
                    <img src="assets/img/check-mark.svg" class="align-self-start h-auto" alt="Create a lead">
                    <h5>View insurance quotes</h5>
                </div>
                <div class="api-content mt-1" style="padding-left: 40px; display: block;">
                    instantly online
                </div>
            </div>


        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <div class="api-info flex-fill">
                <div class="api-title d-flex justify-content-start align-items-center">
                    <img src="assets/img/check-mark.svg" class="align-self-start h-auto" alt="Create a lead">
                    <h5>Prequalify customers</h5>
                </div>
                <div class="api-content mt-1" style="padding-left: 40px; display: block;">
                    and calculate DTI ratios
                </div>
            </div>


        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <div class="api-info flex-fill">
                <div class="api-title d-flex justify-content-start align-items-center">
                    <img src="assets/img/check-mark.svg" class="align-self-start h-auto" alt="Create a lead">
                    <h5>Request EOI</h5>
                </div>
                <div class="api-content mt-1" style="padding-left: 40px; display: block;">
                    with prompt turnaround
                </div>
            </div>


        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <div class="api-info flex-fill">
                <div class="api-title d-flex justify-content-start align-items-center">
                    <img src="assets/img/check-mark.svg" class="align-self-start h-auto" alt="Create a lead">
                    <h5>Process closing date changes</h5>
                </div>
                <div class="api-content mt-1" style="padding-left: 40px; display: block;">
                    in minutes
                </div>
            </div>
        </div>
    </div>

</div>
<section class="angle-bg">
    <div class="container-lg">
        <div class="row mt-3 mx-md-5 py-2">
            <div class="col-12 col-md-4">
                <h2 class="mt-4 mb-3 text-transform-none">Share more value</h2>
                <p class=" mb-4 custom-font-size"><span class="">Customers</span> are a few clicks and five
                    questions away from being covered</p>
                <ul class="fa-ul ml-4 custom-font-size">
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-blue"></fa-icon>
                        </span>Process takes minutes,<span class="text-size-inherit text-westwood-blue  "> not
                            hours</span>
                    </li>
                    <li class=" "><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-blue"></fa-icon>
                        </span>Customized quotes from leading insurers for <span
                            class="text-size-inherit text-westwood-blue ">maximum savings</span></li>
                    <li class=" "><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-blue"></fa-icon>
                        </span>Easy, <span
                                class="text-size-inherit text-westwood-blue ">online
                                experience</span> results in fewer customer questions</li>
                </ul>
            </div>
            <div class="col-12 col-md-4 align-self-center">
                <img src="assets/img/lender-partners.jpg" class="img-fluid" alt="Responsive image">
            </div>
            <div class="col-12 col-md-4">
                <h2 class="mt-4 mb-3 text-westwood-orange text-transform-none">Get more value</h2>
                <p class=" mb-4 font-weight-normal">Create a new <span
                        class=" text-westwood-orange custom-font-size">revenue
                        stream</span></p>
                <ul class="fa-ul ml-4 ">
                    <li class=""><span class="fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-orange"></fa-icon>
                        </span>Westwood's innovative technology <a routerLink='/partners/api'
                            class="text-westwood-orange" style="text-decoration: underline;"><span
                                class="text-size-inherit text-westwood-orange">seamlessly
                                integrates</span></a> with loan origination systems</li>
                    <li class=""><span class="fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-orange"></fa-icon>
                        </span><span class="text-size-inherit text-westwood-orange ">Proven model</span> gets you up and
                        running quickly</li>
                    <li class=""><span class="fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-orange"></fa-icon>
                        </span>Benefit from <span class="text-size-inherit text-westwood-orange ">fewer delayed
                            closings</span> </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="container-lg">
    <div class="row mt-0 pt-4">
        <div class="col-12 text-center">
            <h2 class="mt-4 text-transform-none">Benefit from Westwood's experience and national footprint</h2>
        </div>
    </div>
    <div class="row text-center">
        <div class="col">
            <img src="https://stg.westwoodinsurance.com/assets/img/orange-divider-transparent.png" alt="">
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <h3 class="h4 font-weight-bold">A top insurance agency licensed in all 50 states</h3>
        </div>
    </div>

    <div class="row my-1 mx-md-5 my-4">
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold ">
                Partners with over
            </div>
            <div class="partner-highlight works-with">
                40
            </div>
            <div class="partner-suffix font-weight-bold">
                insurance companies
            </div>
        </div>
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold">
                Works with some of the <span style="font-size: 115%;">largest</span>
            </div>
            <div class="partner-highlight nationwide" style="font-size: 26px; line-height: 38px; height: auto;">
                <!-- Important for spacing, the space should be inside the span, not next to the numbers -->
                nationwide
            </div>
            <div class="partner-suffix font-weight-bold">
                <span style="font-size: 115%;">lenders</span>
            </div>
        </div>
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold">
                Help close over
            </div>
            <div class="partner-highlight help-close">
                100K
            </div>
            <div class="partner-suffix font-weight-bold">
                homes each year 
            </div>
        </div>
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold pt-3"> Complies with </div>
            <div class="partner-highlight respa " style="font-size: 42px; line-height: 40px;"> RESPA </div>
            <div class="partner-suffix font-weight-bold"></div>
        </div>
    </div>
</div>

<div class="container-fluid bg-light">

    <section class="py-5 top-border-element">
        <div class="row text-center pt-2 pb-3">
            <div class="col"><img alt="orange line decor" src="assets/img/orange-divider-transparent.png"></div>
        </div>

        <div class="row">
            <div class="col">
                <h2 class="text-center text-transform-none">Insurance Company Partners</h2>
            </div>
        </div>
        <!-- C U S T O M E R   T E S T I M O N I A L S  -->

        <section id="customer-test" class="pt-3 pb-3">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col">
                        <div id="carouselExampleControls1" class="carousel slide" data-ride="carousel"
                            data-interval="3750">
                            <div class="carousel-inner">
                                <!-- First slide -->
                                <div class="carousel-item active">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/AIIC.jpg"
                                                        alt="American Integrity Insurance Group" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/AMIG.jpg" alt="American Modern" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/CHUBB.jpg" alt="Chubb" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/FirstAmerican_PropAndCas.png"
                                                        alt="First American Propety & Casualty Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/ForemostIG.jpg"
                                                        alt="Foremost Insurance Group" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- Second slide 
                                 
                                -->
                                <div class="carousel-item ">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/GeoVeraI.jpg" alt="GeoVera Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/homeowners-oa.png"
                                                        alt="Homeowners of American Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/MI-Authorized-Agent-Logo-Vertical-PRINT.jpg"
                                                        alt="Mercury Insurance Authorized Agent" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/NatGenPremier.jpg"
                                                        alt="NatGen Premier" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Progressive_Logo.png"
                                                        alt="Progressive Logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 3rd slide -->

                                <div class="carousel-item">
                                    <div class="row justify-content-center text-center logo-rows">

                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/QBE.png" alt="QBE" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/rli-logo.png"
                                                        alt="RLI Different Works" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/SafecoLMAC.png" alt="Safeco Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Selective-Insurance-RGB.png"
                                                        alt="Selective Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Stillwater_InsGrp_LOGO_vrt_Bld_clr.jpg"
                                                        alt="Stillwater Insurance Group" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- 4th slide -->
                                <div class="carousel-item">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Travelers-logo.gif"
                                                        alt="Travelers Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/TowerHillIns.jpg"
                                                        alt="Tower Hill Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/una-logo.png"
                                                        alt="Universal North America" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/State-Auto.png"
                                                        alt="State Auto Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/AH_GI_logo_hor_grn.jpg"
                                                        alt="Arrowhead Insurance" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- 5th Slide -->
                                <div class="carousel-item">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/clearcover_wordmark_rgb_logo.png"
                                                        alt="Clearcover Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Wright-flood.jpg"
                                                        alt="Wright Flood insurance" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev partner-landing-prev" href="#carouselExampleControls1"
                                role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true">
                                    <img src="assets/img/arrow-lft.png" alt="orange left arrow decor"
                                        id="left-arrow-image">
                                </span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next partner-landing-next" href="#carouselExampleControls1"
                                role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true">
                                    <img src="assets/img/arrow-rght.png" id="right-arrow-image"
                                        alt="orange left arrow decor">
                                </span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- // C U S T O M E R   T E S T I M O N I A L S  -->
    </section>
</div>

<!-- Pop up to request details -->
<app-pop-uprequest-details></app-pop-uprequest-details>