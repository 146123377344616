<!--  P R I V A C Y  P O L I C Y   -->


<section>
    <div class="container pt-5 pb-5">

      <div class="row pt-2">
        <div class="col">
          <h2 class="col">Privacy Policy</h2>       
          <img class="pl-3" src="assets/img/orange-divider-transparent.png" /> <br><br>
        </div>
      </div>

      <div class="row pb-4 text-right">
        <div class="col-12">         
          <a routerLink="/ssn">SSN Privacy Policy </a><br>
          <a routerLink="/ccpa">California Consumer Privacy Act Notice</a><br>
          
          <!-- <a href="http://wia.phgtestsite.com/GMV.html">General Mailed version </a><br> -->
          

          
        </div>
      </div>

      <div class="row pb-2 pl-3">
        <div class="col">
   
          <span>Westwood Insurance Agency Website Privacy Statement <br><br>
          
            Revised May, 2018<br>
            Effective as of October, 2001


          </span>
        </div>
      </div>

      <div class="col-md-8 col-xs-9 pb-5 pt-5">
        <span>Privacy and How it Affects You</span><br><br>
        <p>Your personal privacy is important to us. This Privacy Statement tells you about information Westwood Insurance Agency ("Westwood") gathers about you on our website, how the information is used, and who we share the information with.</p>
        
        <br>

        <span>Information We Collect</span><br><br>
          <p>The information we collect varies depending on the type of activity you are performing on the site and helps us personalize and continually improve your experience. Much of our website can be accessed without providing any personal information. On all pages that collect personal information, we specifically indicate what information is required in order to provide you with the product or service you have requested.</p>
      
      
        <span>Get a Quote</span><br><br>
        <p>Westwood offers a convenient method of obtaining quotes to assist you with your insurance needs. When you request a quote online, we need to collect personal information in order to complete your request. The personal information we collect varies depending upon the product or service for which you request a quote. Examples of information we may collect include your name, phone number, mailing address, email, occupation, date of birth, Social Security number, etc.</p> <br>
      
      
        <span>Current Customers Information Requests</span><br><br>
        <p>Current customers can request information via the Westwood website. Customers can ask a question about their policy, request evidence of insurance for loan closings, request a policy change, and opt-in to receive text messages. The personal information we collect varies depending upon the requested information. Examples of information we may collect include your policy number, name, address, phone number, email, etc.</p> <br>

        <span>Cookies</span><br><br>
        <p>Cookies are small pieces of information that web sites can store on your computer. At this time, this website does not use cookies.</p> <br>

        <span>How We Use Your Information</span><br><br>
        <p>Information collected through this website may be used to:</p>
        <ul>
          <li>Respond to questions or suggestions</li>
          <li>Fulfill your online requests and otherwise service your transaction</li>
          <li>Improve the quality of your visits to our site, and your overall experience</li>
          <li>Send offers or information about products and/or services that may interest you</li>
        </ul><br>

        <span>Who We Share Your Information With</span><br><br>
        <p>Within Westwood: Westwood employees may access customer information for business purposes only. We restrict access to your personal information to those employees who need to know such information so that they can provide services to you. Employees who have access to customer information are required to protect it and keep it confidential. We maintain physical, electronic and procedural safeguards that comply with state and federal law to guard your personal information. We may share your personal information with affiliated companies, if and when required by law.</p> <br>
        <p>With third parties: Westwood carefully chooses service providers to help us provide quality insurance services to you. We are careful to protect your privacy when we share information with them. We may share your personal information with third parties that perform functions on our behalf. <br><br>
          We require third parties to comply with strict standards regarding security and confidentiality of your information. They are not permitted to release your information or use it for their own purposes. Third parties are also not permitted to sell any customer information we share with them to any other party.<br><br>
          There may be times when Westwood is required by law to disclose information about you to nonaffiliated third parties. For example, we may disclose information in response to a subpoena. We may share information to help detect or prevent fraud. We may have to give information to law enforcement or governmental agencies. We also may share information if you give us written permission first.<br><br>
          Under no circumstances do we sell or share your information to or with any party outside of Westwood for purposes of independently selling their products or services to you.
        </p> <br>


        <span>How you can review recorded information about you</span><br><br>
        <p>You have the right to review recorded information about you in our files collected through this website. Write us at the address shown on this Privacy Statement if you want to know what information we have on file. We will need your complete name, address, and all your policy numbers. Tell us what information you would like to receive or view. We will act on your request within 30 days of receiving it. We will let you know the nature of the information about you in our files. We will tell you with whom we have shared this information in the past two years. We will also give you the name and address of any consumer reporting agency that prepared a report about you in our files. You can contact them to get a copy of that report.</p> <br>

        <span>If you disagree with our records</span><br><br>
        <p>Please let us know if you think any of our information is incorrect. Tell us what is wrong and why. You may ask us to correct, amend or delete it. Within 30 days of receiving your request, we will change your information in our files or inform you of our refusal to change such information.</p>
         
          <p>
          If we make any changes to your information, we will notify you of those changes. We will also notify the parties listed below of those changes.</p>
        
          <ul>
            <li>Any party that may have, in the past two years, been given such information.</li>
            <li>Any insurance-support organization that we have given the information to within the past seven years.</li>
            <li>Any insurance-support organization that gave us the information.</li>
            <li>Consumer Reporting Agencies (CRAs).</li>
          </ul>
        <p>
          If we do not make changes, we will give you the reasons why and inform you of your right to file a statement. Your statement should tell us what you think is the correct information. You should also tell us why you disagree with our refusal. Your statements will be kept in your file and given to anyone that reviews the information. If we need to disclose the disputed information, we will mark the matter(s) in dispute and include your statement(s).
        </p> <br>

        <span>Linking to Third Parties</span><br><br>
        <p>If you navigate to a site via a link from our website, we are not responsible for the content or availability of the linked site. Please be advised that we do not represent either the third party or you, if you enter into a transaction on the third party site. Further, the privacy and security policies of the linked site may differ from those practiced by us.</p> <br>

        <span>Security Safeguards</span><br><br>
        <p>We safeguard your nonpublic personal information in accordance with applicable state and federal regulations. Our policies restrict access to personal information about you to designated personnel who need to know that information to provide products or services to you or may be responsible for maintaining security practices.</p> <br>

        <span>Privacy Policy Changes</span><br><br>
        <p>We may update this Privacy Statement to reflect changes to our online information practices or to comply with applicable laws. Any material changes to our online information practices will be reflected in this Website Privacy Statement. We will indicate the date this Statement was last updated</p> <br>

        <span>Questions or Comments? Contact:</span><br><br>
        <p>Westwood Insurance Agency<bR>
          Attn: Customer Service Department<br>
          6320 Canoga Avenue, Suite 500<br>
          Woodland Hills, CA 91367<br>
          <a href="tel:18005725499" class="telNum"> 800.572.5499</a>
          </p> <br> 
        </div> 
    </div>
  </section> 

<!-- //  P R I V A C Y  P O L I C Y    -->

