<!-- <app-request-an-insurance-quote mainHeading="QUOTE REQUEST" subHeading="false"></app-request-an-insurance-quote> -->
<!-- <div class="text-center">
    <h3>Quote Request</h3>
</div> -->
<!-- Start of form -->
<form #QuoteResult="ngForm" ngForm>
    <section id="rtq-form" class="pb-5" *ngIf="leadDetails.quoteResult.quoteMessage==''">
        <div>
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <h2 class="page-header-text">Here is Your Quote!</h2>
            <div class="row justify-content-center">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="row pt-2">
                        <div class="col">
                            <label class="quote-result-label p-0">
                                Agent: {{this.leadDetails.salesAgent.firstName}}
                                {{this.leadDetails.salesAgent.lastName}},
                                {{leadDetails.salesAgent.phoneNumber}}
                            </label>
                            <label *ngIf="leadDetails.salesAgent.residenceState === 'CA'">, CA License #: {{leadDetails.salesAgent.licenseNo}}, Resident State: {{leadDetails.salesAgent.residenceState}}</label>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col">
                            <label class="quote-result-label p-0">
                                Property Address: {{this.leadDetails.customerAddress.streetAddress}},
                                {{this.leadDetails.customerAddress.city}},
                                {{this.leadDetails.customerAddress.state}},
                                {{this.leadDetails.customerAddress.zipCode}}
                            </label>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col">
                            <label class="quote-result-label p-0">
                                Please review your {{leadDetails.policyTypeName}} quote {{leadDetails.referenceID}}
                                from {{leadDetails.quoteResult.insuranceCompanyName}} and update the coverages, if
                                needed.
                            </label>
                        </div>
                    </div>
                    <div class="row pt-2" *ngIf="_IsReRateUnsucessfull">
                        <div class="col">
                            <span class="text-sm-center">
                                Unfortunately, that option is not available so we have to reset the coverages back
                                to the original quote. If you would like to discuss more options, please call us at
                                <a
                                    href="tel:{{commonService.formatPhoneNumber(leadDetails.salesAgent.phoneNumber)}}">{{leadDetails.salesAgent.phoneNumber}}</a>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-7 col-md-12 col-sm-12">
                            <div class="row pt-3">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label for="lblDwellingCoverage"
                                        class="quote-result-label text-nowrap coverageLabel text-right">Dwelling
                                        Coverage</label>
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <select class="form-control text-width-109" id="ddlDwellingCoverage"
                                        [disabled]="leadDetails.policyTypeName=='HO4'"
                                        [(ngModel)]="leadDetails.quoteResult.coverageAAmount" name="coverageA">
                                        <option *ngFor="let item of leadDetails.quoteResult.coverageAAmountOptions"
                                            [ngValue]="item.lookupValue">&#36;{{item.lookupValue}}</option>
                                    </select>
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon text-nowrap coverageLabel">
                                    <i class="fa fa-question-circle help cov-helper-icon helperCovA"></i>
                                    <a href="#" class="viewRCVLink text-nowrap ml-2" target="_blank"
                                        *ngIf='leadDetails.quoteResult.isRCVRun'
                                        routerLink="/real-time-quote/rcv-details" (click)="$event.stopPropagation()"
                                        [queryParams]="{ LeadID : leadDetails.leadID}" [routerLinkActive]=" ['active']"
                                        queryParamsHandling="merge">View RCV</a>
                                    <!-- <button (click)="openRCV()" class='viewRCVButton' *ngIf='leadDetails.quoteResult.isRCVRun  && this.commonService.isSafariBroswer()'>View
                                            RCV</button> -->
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label for="lblOtherStructures"
                                        class="quote-result-label text-nowrap coverageLabel">Other
                                        Structures</label>
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <input type="text" class="form-control padding-left-6 text-width-109"
                                        name="coverageB" disabled
                                        [ngModel]="leadDetails.quoteResult.coverageBAmount | currency:'USD':'symbol': '1.0-0'"
                                        (ngModelChange)="leadDetails.quoteResult.coverageBAmount=$event">
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon">
                                    <i class="fa fa-question-circle text-sm-right help cov-helper-icon helperCovB"></i>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label for="lblPersonalProperty"
                                        class="quote-result-label text-nowrap coverageLabel">Personal
                                        Property</label>
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <select class="form-control text-width-109" id="ddlPersonalProperty"
                                        [(ngModel)]="leadDetails.quoteResult.coverageCAmount" name="coverageC">
                                        <option *ngFor="let item of leadDetails.quoteResult.coverageCAmountOptions"
                                            [ngValue]="item.lookupValue">&#36;{{item.lookupValue}}</option>
                                    </select>
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon">
                                    <i class="fa fa-question-circle text-sm-right helperCovC cov-helper-icon "></i>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label for="lblAdditonalLivingExpenses"
                                        class="quote-result-label text-nowrap coverageLabel">Addtl
                                        Living Expenses</label>
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <input type="text" class="form-control padding-left-6  text-width-109 padding-quote"
                                        name="coverageD" disabled
                                        [ngModel]="leadDetails.quoteResult.coverageDAmount | currency:'USD':'symbol': '1.0-0'"
                                        (ngModelChange)="leadDetails.quoteResult.coverageDAmount=$event">
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon">
                                    <i class="fa fa-question-circle text-sm-right help cov-helper-icon helperCovD"></i>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label for="lblPersonalLiablitiy"
                                        class="quote-result-label text-nowrap coverageLabel">Personal
                                        Liability</label>
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <select class="form-control text-width-109" id="ddlPersonalLiability"
                                        [(ngModel)]="leadDetails.quoteResult.coverageEAmount" name="coverageE">
                                        <option *ngFor="let item of leadDetails.quoteResult.coverageEAmountOptions"
                                            [ngValue]="item.lookupValue">&#36;{{item.lookupValue}}</option>
                                    </select>
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon">
                                    <i class="fa fa-question-circle text-sm-right cov-helper-icon helperCovE"></i>
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label for="lblMedicalPayment"
                                        class="quote-result-label text-nowrap coverageLabel">Medical
                                        Payment</label>
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <select class="form-control text-width-109 " id="ddlPersonalLiability"
                                        [(ngModel)]="leadDetails.quoteResult.coverageFAmount" name="coverageF">
                                        <option *ngFor="let item of leadDetails.quoteResult.coverageFAmountOptions"
                                            [ngValue]="item.lookupValue">&#36;{{item.lookupValue}}</option>
                                    </select>
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon">
                                    <i class="fa fa-question-circle text-sm-right cov-helper-icon helperCovF"></i>
                                </div>
                            </div>
                            <div class="row pt-2"
                                *ngFor="let item of leadDetails.quoteResult.deductibles; let index = index;">
                                <div class="col-lg-6 col-md-5 col-sm-4 text-sm-right">
                                    <label class="quote-result-label text-nowrap coverageLabel">{{item.name}}
                                        Deductible
                                    </label>
                                    <br />
                                </div>
                                <div class="col col-8 col-md-4 col-lg-4">
                                    <select class="form-control text-width-109" id="ddlDeductibles"
                                        [(ngModel)]="item.amount" name="{{'Deductibles'+index}}">
                                        <option *ngFor="let item of item.lstOptions" [ngValue]="item.lookupValue"
                                            [innerHTML]="formatDeductibles(item.lookupValue)">
                                        </option>
                                    </select>
                                </div>
                                <div class="col col-4 col-md-3 col-lg-2 quote-result-label info-icon">
                                    <i class="fa fa-question-circle text-sm-right cov-helper-icon helperDeductibles"
                                        *ngIf="index==0"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-offset-1 col-lg-4 col-md-12 col-sm-12">
                            <div class="row pt-3">
                                <div class="col col-12">
                                    <label class="quote-result-label text-nowrap mobTextWrap coverageLabel"><b>Additional
                                            Info/Limitations/Exclusions:</b></label>
                                    <ul class="addInfo" *ngFor="let item of leadDetails.quoteResult.policyTexts">
                                        <li class="pl-0 text-left quote-result-label">{{item.lookupValue}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2" *ngIf="leadDetails.quoteResult.isSameQuote">
                        <div class="col col-12">
                            <p class="text-sm-center bold text-orange">
                                Coverage change(s) requested were not available and were set to closest amount
                                available to your request(s).
                            </p>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col col-md-3 col-lg-3 mt-3">
                            <div class="row">
                                <div class="col">
                                    <div class="card border border-light bg-coverage premium-width-135">
                                        <div class="card-body text-sm-center p-2">
                                            <p class="card-title header-black p-0">Annual <br /> Premium</p>
                                            <h4 class="card-text header-black m-0 p-0 font-weight-bold">
                                                &#36;{{leadDetails.quoteResult.totalPremium
                                                | number
                                                :'1.2-2'}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-md-3 col-lg-3 mt-3">
                            <div class="row">
                                <div class="col">
                                    <div class="card border border-light bg-coverage premium-width-135">
                                        <div class="card-body text-sm-center p-2">
                                            <p class="card-title header-black p-0">Monthly <br />Premium</p>
                                            <h4 class="card-text header-black m-0 p-0 font-weight-bold">
                                                &#36;{{leadDetails.quoteResult.monthlyPremium
                                                | number :
                                                '1.2-2'}}</h4>
                                        </div>
                                    </div>
                                    <p class="bg-transparent text-sm-center">Installment fees may apply</p>
                                </div>
                            </div>
                        </div>
                        <div class="col col-md-6 col-lg-6 mt-3">
                            <div class="row">
                                <div class="col col-12 col-md-2 col-lg-2">
                                </div>
                                <div class="col col-12 col-md-12 col-lg-10">
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="button" [ngClass]="isLoading && !QuoteResult.dirty ? 'btn-secondary btn no-Cursor w-100' : 'btn ww-button w-100'"
                                                [disabled]="DisableButton('Apply',QuoteResult.dirty) || isLoading"
                                                (click)="applyQuote('apply')">
                                                Apply Now
                                            </button>
                                        </div>
                                        <div class="col-6">
                                            <button type="button"
                                                [ngClass]="QuoteResult.dirty == false?'btn-secondary btn no-Cursor w-100':'btn ww-button w-100'"
                                                [disabled]="DisableButton('ReCalc',QuoteResult.dirty) || isLoading"
                                                (click)="updateQuote(QuoteResult)">
                                                Recalculate
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button type="button"
                                            [ngClass]="(QuoteResult.dirty== false && !this.enableResetBtn) || this.isSameOriginalQuote ?'btn-secondary btn my-1 no-Cursor w-100':'btn ww-button w-100 my-1'"
                                            [disabled]="DisableButton('Reset',QuoteResult.dirty) || isLoading"
                                            (click)="getOriginalQuote(QuoteResult)">
                                            Reset to Original Coverages
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pl-0 pr-0 pt-2"
                        *ngIf="leadDetails.quoteResult.alternateQuotes!=null && leadDetails.quoteResult.alternateQuotes?.length>0">
                        <div class="col text-center header-alternate-quote-darkorange">
                            <h4 class=" alternate-quote pt-2"><b>Alternate Quote</b></h4>
                        </div>
                    </div>
                    <!-- Alternate Quote Starts -->
                    <div *ngFor="let item of leadDetails.quoteResult.alternateQuotes">
                        <div class="row pl-0 pr-0 pt-2">
                            <div class="col col-sm-12">
                                <h4 class="header-black">{{item.factorName}}</h4>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col col-sm-6 col-md-4 col-lg-4 mt-3">
                                <div class="row">
                                    <div class="col">
                                        <div class="card border border-light bg-coverage premium-width-135">
                                            <div class="card-body text-sm-center p-2">
                                                <p class="card-title header-black p-0">Annual <br /> Premium</p>
                                                <h4 class="card-text header-black m-0 p-0 font-weight-bold">
                                                    &#36;{{item.totalPremium | number
                                                    : '1.2-2'}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-sm-6 col-md-4 col-lg-4 mt-3">
                                <div class="row">
                                    <div class="col">
                                        <div class="card border border-light bg-coverage premium-width-135">
                                            <div class="card-body text-sm-center p-2">
                                                <p class="card-title header-black p-0">Monthly <br />Premium</p>
                                                <h4 class="card-text header-black m-0 p-0 font-weight-bold">
                                                    &#36;{{item.totalPremium/12 |
                                                    number : '1.2-2'}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-sm-12 col-md-4 col-lg-4 mt-3 mobPremiumDiscount">
                                <div class="row col-sm-12  border quote-result-label">
                                    This is the discounted premium if you choose to purchase an auto policy with
                                    our Agency.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Alternate Quote Ends -->
                    <div class="row pt-2" *ngIf="leadDetails.quoteResult.quoteMessage==''">
                        <div class="col">
                            <p>
                                Disclaimer: This quote is based on information displayed. If anything is incorrect,
                                please notify us to adjust it and provide you with an updated rate. Acceptance is
                                subject to the insurance company’s normal underwriting review, possibly including
                                verification of insurance score and previous claims history. Insurance companies at
                                times take rate changes which could affect this quote prior to issuance. Westwood
                                Insurance Agency, Inc. is a Baldwin Risk Partner.
                            </p>
                        </div>
                    </div>

                    <section class="bg-white d-sm-block mb-5">
                        <div class="row">
                            <div class="col">
                                <button type="button" class="btn ww-button" aria-label="Go Back to Previous Screen"
                                    awPreviousStep tabindex="-1">
                                    <i>
                                        <fa-icon icon="chevron-left"></fa-icon><fa-icon icon="chevron-left"></fa-icon>
                                    </i>
                                    Previous
                                </button>
                            </div>
                            <div class="col text-right">
                                <button type="button" class="btn ww-button"
                                    (click)="storeData($event)">
                                    <i class="fas fa-print"></i> Printable Version
                                </button>
                            </div>
                        </div>
                    </section>
                    <app-model-popup (savePageDetails)="savePageDetails()"></app-model-popup>
                </div>
            </div>
        </div>
    </section>
</form>
<!-- End of form -->