import { Component, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { AnalyticsService } from './service/analytics.service';
declare var jQuery: any;
declare function onLoad(): any;
import { CommonService } from './service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewChecked {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private googleAnalytics: AnalyticsService,
    private commonService: CommonService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        /* Call api to set coockie */
        // console.log('App:SetCSRFToken');
        // Do not generate the CSRF Token when User is coming from Wordpress to get-a-quote route of Angular app.
        // The RTQLandingPage component will make a call before 
        if(ev.url.indexOf("get-a-quote") < 0) {
          this.commonService.SetCSRFToken();
        }
      }
    });
  }

  ngOnInit() {

    /* Set page title*/
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter((route) => route.outlet === 'primary')
      .mergeMap((route) => route.data)
      .subscribe((event) => {

        if (event['title'] !== 'News' || event['title'] !== 'Article') {
          this.titleService.setTitle(event['title']);
          if (event['metaContent'])
            this.metaService.updateTag({ name: 'description', content: event['metaContent'] });
        }
      }

      );

    this.googleAnalytics.init();

  }
  ngAfterViewChecked() {
    // alert('in')
  }
  changeOfRoutes() {
    //onLoad()
  }
}
