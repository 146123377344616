<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-claims" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 col-sm-5 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Claims</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<!-- <section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section> -->

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!-- I C O N S -->

<section class="container claims-icons pt-3">
  <div class="row claims-list">

    <div class="col-md-4 p-4 col-xs-12">
      <div class="row">
        <div class="col-md-4"><img src="assets/img/icons/claims-reporting.png" width="65"
            alt="Clipboard and Cell phone icon" /></div>
        <div class="col">
          <h5>Report a Claim</h5>
          <a routerLink="/claims/report-a-claim">Read More »</a>
        </div>
      </div>
    </div>

    <div class="col-md-4 p-4 col-xs-12">
      <div class="row">
        <div class="col-md-4"> <img src="assets/img/icons/claim-prevention.png" width="65" alt="Umbrella icon" /></div>
        <div class="col">
          <h5>Prevent A Claim</h5>
          <a routerLink="/claims/claim-prevention">Read More »</a>
        </div>
      </div>
    </div>

    <div class="col-md-4 p-4 col-xs-12">
      <div class="row">
        <div class="col-md-4"> <img src="assets/img/icons/controlling-losses.png" width="65"
            alt="Man putting out fire icon" /></div>
        <div class="col">
          <h5>Minimize Your Loss</h5>
          <a routerLink="/claims/minimize-your-loss">Read More »</a>
        </div>
      </div>
    </div>

  </div>
</section>

<!-- // I C O N S  -->


<!-- O N L I N E  C L A I M  -->

<section id="online-quote">
  <div class="container online-quote pb-4 mt-1">
    <div class="row">
      <div class="col text-center">
        <a class="show-cursor a-text-decoration-none" routerLink="/claims/report-a-claim" target="_blank">
          <button class="btn ww-button-title p-2 px-3">
            Report a claim
          </button>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- // O N L I N E   C L A I M -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>


<!-- // K N O W L E D G E   C E N T E R  -->
<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->