<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-earthquake" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Earthquake Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
    <div class="container text-center pt-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-md-9 col-xs-9">
                <!-- <p><span>Homeowner, condo and renter policies do not automatically cover losses to your home or
                        belongings in the event of an earthquake. </span><br> In most states, earthquake coverage is
                    available either as an endorsement on your homeowner or condo policy for an additional annual
                    premium, or as a separate earthquake policy. When you’re ready to add this protection to your
                    coverage, we can explain the different options available and help you get a competitive earthquake
                    insurance quote.</p>
                <br> -->
                <span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> If you live in a state
                    other than California, Oregon or Washington, please call us for a quote at <a
                        href="tel:18885436179"> 888.543.6179</a></span>
            </div>
        </div>
    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  E A R T H  Q U A K E   -->

<section>
    <div class="container pt-3 pb-5" id="a-bullet-list">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center">Why Earthquake Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 pb-5 text-right">
                <img src="assets/img/page-body-img/shutterstock_1319075927.png" style="width: 100%;"
                    alt="Destroyed furniture" />
            </div>
            <div class="col-md-6">
                <ul>
                    <li>While earthquakes are more prevalent in some areas, according to the Insurance Information
                        Institute, people in 42 states are at risk of earthquake damage to their homes and personal
                        property.<br><br></li>

                    <li>The potential damage to your home can be financially devastating.<br><br></li>

                    <li>If you have a mortgage on your property and you suffer a total loss, you are still liable to pay
                        your monthly mortgage payment.</li>

                </ul>
            </div>
        </div>
        <div class="row text-center pt-2 pb-3">
            <div class="col text-center">
                <p class="text-center">
                    To learn more about earthquakes, please visit the United States Geological Survey (USGS) at <a
                        class="show-cursor" (click)="child.importantNotice($event,'http://www.usgs.gov')" href="#"
                        target="_blank">usgs.gov</a>
                </p>
            </div>
        </div>
    </div>
</section>

<!-- //  W H Y  E A R T H  Q U A K E  -->


<!-- W H A T S   I N C L U D E D  -->

<section id="whats-included-bullet-list">
    <div class="container pt-5 pb-5">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center">What’s included in Earthquake Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-xs-12 pb-4">
                <div class="row">
                    <!-- <div class="col-3 text-right p-0">
                        <img src="assets/img/icons/icon-earthquake.png" alt="house after earthquake icon" />
                    </div> -->
                    <div class="col">
                        <span>Home and personal belongings protection</span><br>
                        This part of your policy Includes protection for the structure of your home and for your
                        personal belongings inside your home.
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div class="row">
                    <!-- <div class="col-3 text-right p-0">
                        <img src="assets/img/icons/icon-home.png" alt="house icon" />
                    </div> -->
                    <div class="col">
                        <span>Living expenses</span><br>
                        This part of your policy covers extra living expenses if your home is not habitable and you must
                        live elsewhere while your home is being repaired.
                    </div>
                </div>
            </div>
        </div>
        
        <!-- O N L I N E   Q U O T E -->

       
        <!-- // O N L I N E   Q U O T E -->
    </div>
</section>

<!-- // W H A T S  I N C L U D E D   -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->