import { Component, OnInit, AfterViewChecked, Input, AfterViewInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from '../../Utilities/Constants/constants';
import { Router } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-request-an-insurance-quote',
  templateUrl: './request-an-insurance-quote.component.html',
  styleUrls: ['./request-an-insurance-quote.component.css']
})
export class RequestAnInsuranceQuoteComponent implements OnInit,AfterViewChecked,AfterViewInit {
liveChatOn:boolean=false;
show:boolean=true;
  constructor( public commonService: CommonService,
  public constantsObj: Constants,private router: Router) { }
  @Input() mainHeading : string; 
  @Input() subHeading : string; 
  ngOnInit() {
    this.liveChatOn=false;
    //if(this.router.url=='/real-time-quote/quote-thank-you')
    //this.show=false;
   
  }
  ngAfterViewChecked()
  {
   
   
  }
  ngAfterViewInit()
  {
    this.commonService.loadLiveChat();
    if (jQuery("img[name='psILpZimage']").length > 0 && !this.liveChatOn) {
      this.liveChatOn=true;
      jQuery('.psmw_ILpZ').css('position','relative')
       //jQuery('.psmw_ILpZ').removeAttr('style')
       //jQuery('.psmtc_ILpZ').html('<div class="round hollow text-center"> <a href="#" id="addClass" onclick="psILpZow(); return false"><span class="glyphicon glyphicon-comment"></span><img name="psILpZimage" src='+'"'+this.constantsObj.LiveChatImage+'"'+'> Live chat here</a> <br><br> </div>')
     }
  }

  //Function to show/hide live chat
  showHideChatBox(){
    event.preventDefault();
    jQuery("#addClass").click(function (e) {
      e.preventDefault();
      jQuery("#addClass").attr('id','removeClass');
      jQuery('#qnimate').addClass('popup-box-on');
    });          
    jQuery("#removeClass").click(function () {
      jQuery("#removeClass").attr('id','addClass');
      jQuery('#qnimate').removeClass('popup-box-on');
    });
  } 
}
