import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-pet-insurance',
  templateUrl: './pet-insurance.component.html',
  styleUrls: ['./pet-insurance.component.css']
})
export class PetInsuranceComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'pet-header.062b581455e9f460619c.jpg',
    desktopHeaderText: 'Pet Insurance',
    desktopHeaderSubText: "Consider your pet part of the family? Pet insurance provides peace of mind knowing you can give your pet the best care possible without worrying about surprise bills.",
    mobileHeaderText: 'Pet Insurance',
    mobileHeaderSubText: '',
    //commented below as part of WSTW-5680
    // mobileHeaderButton: '<a  href="https://quote.embracepetinsurance.com/pet-information?brand=westwood" target="_blank" class=" btn banner-button">Get a Quote</a>',
    // desktopHeaderButton: '<a  href="https://quote.embracepetinsurance.com/pet-information?brand=westwood" target="_blank" class=" btn banner-button">Get a Quote</a>'
    //Added below as part of WSTW-5680
    mobileHeaderButton: '<a  href="https://quote.embracepetinsurance.com/pet-information?orgcode=80171849&brand=westwood&utm_source=westwood&utm_medium=partner&utm_campaign=website" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://quote.embracepetinsurance.com/pet-information?orgcode=80171849&brand=westwood&utm_source=westwood&utm_medium=partner&utm_campaign=website" target="_blank" class=" btn banner-button">Get a Quote</a>'
  });
  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

}
