import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { Action } from '../Utilities/api/action.config';
import { Controller } from '../Utilities/api/controller.config';
import { CustomerQuoteSearch } from '../model/customerQuoteSearch';

@Injectable({
  providedIn: 'root'
})
export class CustomerQuoteSearchService {

  url: string;
  constructor(private dataService: DataService) { } 
  
  /* Get Lead  Details */
  postMyQuote( data: CustomerQuoteSearch): Observable<Array<CustomerQuoteSearch>> { 
    this.url = Controller.MyQuote + '/' + Action.GetMyQuote; 
    return this.dataService.postData(data, this.url); 
  } 
}
