import { Directive, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';

declare var google: any;

@Directive({
  selector: '[appGoogleAutoCompletePlaces]'
})
export class GoogleAutoCompletePlacesDirective implements OnInit {
  @Output() onselect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(elRef: ElementRef) {
    // elRef will get a reference to the element where the directive is placed
    this.element = elRef.nativeElement;
  }

  getFormattedAddress(place) {
    // @params: place - Google Autocomplete place object
    // @returns: location_obj - An address object in human readable format
    const location_obj = {};
    // tslint:disable-next-line:forin
    for (const i in place.address_components) {
      const item = place.address_components[i];
      location_obj['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf('administrative_area_level_1') > -1) {
        location_obj['admin_area_l1'] = item['short_name'];
      } else if (item['types'].indexOf('administrative_area_level_2') > -1) {
        location_obj['admin_area_l2'] = item['short_name'];
        if(location_obj['admin_area_l2']!=null || location_obj['admin_area_l2']!=''){
          location_obj['admin_area_l2']=location_obj['admin_area_l2'].split(' County')[0];
        }
      } else if (item['types'].indexOf('street_number') > -1) {
        location_obj['street_number'] = item['short_name'];
      } else if (item['types'].indexOf('route') > -1) {
        location_obj['route'] = item['long_name'];
      } else if (item['types'].indexOf('country') > -1) {
        location_obj['country'] = item['long_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
        location_obj['postal_code'] = item['short_name'];
      }
    }
   const  city=this.getCity(place);
   location_obj['locality']=city;
  //  const  neighborhood=this.getNeighborhood(place);
  //   if(neighborhood!=undefined)
  //   {
  //     location_obj['locality']=neighborhood;
  //   }
  //   else{
  //     location_obj['locality']=city;
  //   }
    return location_obj;
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.element);
    autocomplete.setComponentRestrictions(
      {'country': ['us']});
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const locObject = this.getFormattedAddress(autocomplete.getPlace());
      // Emit the new address object for the updated place
      this.onselect.emit(locObject);
    });
  }
  getAddrComponent(place, componentTemplate) {
     	let result;
     	for (let i = 0; i < place.address_components.length; i++) {
     	const addressType = place.address_components[i].types[0];
     	if (componentTemplate[addressType]) {
     	result = place.address_components[i][componentTemplate[addressType]];
     	return result;
      }
    }
    return;
  }
  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }
  getNeighborhood(place) {
    const COMPONENT_TEMPLATE = { neighborhood: 'long_name' },
    city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

}

