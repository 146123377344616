<!-- K N O W L E D G E   C E N T E R  -->
<div class="container">
    <div class="row">
        <div class="col">
            <h2 class="text-center">Westwood News and Insights</h2>
        </div>
    </div>
    <div class="row text-center pt-2 pb-3">
        <div class="col">
            <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
        </div>
    </div>
    <div class="row pt-4" id="blogCon" >
            <div class="col-md-4" *ngFor="let item of data?.articlesMain; index as i;">
                <!-- added ngIf to restrict the article number displayed to 3; fix for WSTW-5641 -->
                    <img *ngIf="i<3" class="card-img-top" [src]="item?.image" alt="Article">
                    <div *ngIf="i<3" class="card homeBlogCard mb-4">
                        <div class="card-body p-0">
                            <h5 class="card-title text-uppercase pt-4 mb-2">{{ item?.heading }}</h5>
                            <p class="card-date mb-0">{{item?.articleDate | date: 'mediumDate'}}</p>
                            <p class="card-text mb-2">
                                {{ item?.summaryText }}</p> <a  (click)="navigateToUrl('/news/article/'+item?.title)"
                                class="card-link text-uppercase" target="_blank">Read More »</a>
                        </div>
                    </div>
            </div>
           
    </div>
    <div class="row">
        <div class="col text-center p-5">
            <a  
                class="show-cursor a-text-decoration-none"
                (click)="navigateToUrl('/news')"  
                target="_blank">
                <button  class="btn ww-button-title">
                    News and Insights
                </button>
            </a>
        </div>
    </div>
</div>


<!-- // K N O W  L E D G E   C E N T E R  -->