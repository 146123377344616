import { Address } from "./address";
import { Source } from "./source";
import { User } from "./user";
import { CreditAuth } from "./credit-auth";
import { LookUpValues } from "./look-up-values";
import { HomeInfo } from "./home-info";
import { QuoteResult } from "./quote-result";
import { LoanInformation } from "./loanInformation";
import { PropertyUnderwriting } from "./propertyUnderwriting";
import { DisclosureInfo } from "./disclosureInfo";
import { Tracking } from "./tracking";
import { ReCaptcha } from "./reCaptcha";
import { Injectable } from "@angular/core";
import { SupplementalInformation } from "./supplementalInformation";

@Injectable()
export class Lead {
  public leadID: string;
  public contactType: string;
  public referenceID: string;
  public isEmailProspect?: boolean;
  public isInit?: boolean;
  public isPreviousBtnDisabled?: boolean;
  public isIntoApplicationForm?: boolean;
  public contactMeButtonClicked?: boolean;
  public currentPageName: string;
  public pageNo: string;
  public customerAddress?: Address = new Address();
  public mailingAddress?: Address = new Address();
  public homePhoneType: string;
  public alternatePhoneType: string;
  // Personal Information
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public homePhone: string;
  public alternatePhone: string;
  public email: string;
  public loanType: string;
  public policyTypeID: string;
  public policyTypeName: string;
  public isMailingAddressSame: boolean;
  public coLastName: string;
  public coMiddleName: string;
  public coFirstName: string;
  public coAppPrimaryPhone: string;
  public coAppSecondaryPhone: string;
  public coAppPrimaryPhoneType: string;
  public coAppSecondaryPhoneType: string;
  public coAppEmail: string;
  public applicantDOB?: string;
  public estCloseDate?: string;
  public estQuoteEffDate?: Date;
  public coApplicantDOB?: string;
  public priAppOccupation?: string;
  public coAppOccupation?: string;
  public sourceInfo: Source;
  public salesAgent: User;
  public message: string;
  public isDuplicateLead: boolean;
  public isLoading: boolean;
  public isServiceUnavailable: boolean;
  public errorMessage: string;
  public creditAuth: CreditAuth = new CreditAuth();
  public homeInfo: HomeInfo = new HomeInfo();
  public quoteResult: QuoteResult = new QuoteResult();
  public quoteResult_Prev: QuoteResult = new QuoteResult();
  public oldCustomerAddress?: Address = new Address();
  public propertyUnderwriting: PropertyUnderwriting =
    new PropertyUnderwriting();
  public customerAddressChanged: boolean;
  public fraudCheck: string;
  public fraudStatementApplicantName: string;
  public disclosureInfo: DisclosureInfo = new DisclosureInfo();
  public isDisclosurePending: boolean;
  public trackingInfo: Tracking = new Tracking();
  public initIsDone: boolean;
  public reCaptcha: ReCaptcha;
  public eddAgencyRequiredFlag: boolean;
  public applicantAgreed: boolean;
  public applicantSSN: string;
  public supplementalInfo: SupplementalInformation;
  public isCreditAuthorized: boolean;
  public isAuthRequired: boolean;
  constructor() {
    this.eddAgencyRequiredFlag = false;
    this.fraudCheck = "";
    this.fraudStatementApplicantName = "";
    this.pageNo = "";
    this.leadID = "";
    this.referenceID = "";
    this.customerAddress = new Address();
    this.mailingAddress = new Address();
    this.oldCustomerAddress = new Address();
    this.customerAddressChanged = false;
    this.salesAgent = new User();
    this.sourceInfo = new Source();
    this.firstName = "";
    this.lastName = "";
    this.homePhone = "";
    this.alternatePhone = "";
    this.alternatePhoneType = "";
    this.homePhoneType = "";
    this.email = "";
    this.loanType = "";
    this.isMailingAddressSame = false;
    this.coLastName = "";
    this.coFirstName = "";
    this.applicantDOB = null;
    this.coApplicantDOB = null;
    this.estCloseDate = null;
    this.applicantSSN = "";
    this.message = "";
    this.isDuplicateLead = false;
    this.isLoading = false;
    this.isServiceUnavailable = false;
    this.errorMessage = "";
    this.isEmailProspect = false;
    this.isInit = null;
    this.policyTypeID = "";
    this.policyTypeName = "";
    this.isPreviousBtnDisabled = false;
    this.isIntoApplicationForm = null;
    this.contactMeButtonClicked = null;
    this.homeInfo = new HomeInfo();
    this.creditAuth = new CreditAuth();
    this.quoteResult = new QuoteResult();
    this.propertyUnderwriting = new PropertyUnderwriting();
    this.trackingInfo = new Tracking();
    this.supplementalInfo = new SupplementalInformation();
    this.isCreditAuthorized = false;
    this.reCaptcha = new ReCaptcha();
    this.isAuthRequired = true;
    this.applicantAgreed = false;
    this.coAppEmail = '';
  }
}