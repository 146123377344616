<!-- H O M E   H E R O   -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>
<!-- // H O M E   H E R O  -->


<!-- //  H E A D E R   I M G   -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->

<!-- I N T R O   P A R A G R A P H  -->

<!-- R E S O U R C E S  -->
<section>
    <div id="resource-center-resources" class="container p-3">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col ml-1">Meet Our Team</h2>
            </div>
        </div>
        <div class="row ml-2 pt-2 pb-1">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12">
                <div id="hoverTest" class="hasHover">
                    <ul class="grid pl-0 ml-0" id="accordion">
                        <li class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne"> <img class="image"
                                src="assets/img/leaders/alanumaly.jpg">
                            <div class="bottom-left">

                                <b>Alan Umaly</b><br>President
                            </div>
                            <div id="collapseOne" class="overlay collapse pb-0 mb-0" aria-labelledby="headingOne"
                                data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Alan Umaly</b><br>President</p>
                                <hr>

                                <p>
                                    Alan Umaly is President of Westwood Insurance Agency. In this role, Alan is
                                    responsible for the overall performance and management of the agency, including
                                    strategic direction, partnership development, sales, customer service, and
                                    operations. During his 30-year tenure with Westwood, he has driven substantial
                                    agency growth by leveraging homegrown talent, technology, and operational
                                    efficiencies, all focused on pursuing new insurance markets and distribution
                                    channels.
                                    <br><br>
                                    Under Alan's leadership, Westwood has consistently ranked in the top 50 of
                                    Insurance Journal's Top 100 P&C Agencies.
                                    <br><br>
                                    Alan attended the Fashion Institute of Design and Merchandising in Los Angeles,
                                    California, and De La Salle University in Manila, Philippines.

                                </p>

                            </div>
                        </li>
                        <li class="card-header" id="headingeight" data-toggle="collapse" data-target="#collapseeight"
                            aria-expanded="true" aria-controls="collapseeight">
                            <img class="image" src="assets/img/leaders/erichberke.jpg">
                            <div class="bottom-left"><b>Erich Berke</b><br>VP, Corporate Development & Strategy</div>
                            <div id="collapseeight" class="overlay collapse pb-0 mb-0 " aria-labelledby="headingeight"
                                data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Erich Berke</b><br>Vice President, Corporate Development & Strategy</p>

                                <hr>
                                <p>Erich Berke is Vice President, Corporate Development & Strategy at Westwood Insurance
                                    Agency. In this role, Erich is responsible for developing and enhancing Westwood's
                                    corporate strategy across the business units and supporting an overall focus on
                                    maximizing growth opportunities.
                                    <br><br>
                                    For nearly 25 years, Erich has been a financial leader in the insurance industry.
                                    Before joining Westwood, he served as Vice President, Financial Planning and
                                    Analysis at QBE North America. There he was responsible for improving results on a
                                    consistent, long-term basis, deploying the appropriate level of capital and
                                    investments in the business, and identifying and executing financial levers to drive
                                    results.
                                    <br><br>
                                    Erich also held leadership positions with Ace Group (Chubb) and Deloitte & Touche,
                                    LLP. He holds a bachelor's degree in finance from Duquesne University.
                                </p>

                            </div>
                        </li>

                        <li class="card-header" id="headingfour" data-toggle="collapse" data-target="#collapsefour"
                            aria-expanded="true" aria-controls="collapsefour">
                            <img class="image" src="assets/img/leaders/christiburkhardt.jpg">
                            <div class="bottom-left"><b>Christi Burkhardt</b><br>VP, National Sales & Growth</div>
                            <div id="collapsefour" class="overlay collapse pb-0 mb-0 " aria-labelledby="headingfour"
                                data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Christi Burkhardt</b><br>Vice President, National Sales & Growth</p>

                                <hr>
                                <p>Christi Burkhardt is Vice President, National Sales and Growth at Westwood Insurance
                                    Agency. In this role, Christi is responsible for new business production across the
                                    agency, including home, auto, flood, and umbrella. She oversees Westwood's licensed
                                    sales agents and sales support staff, and she works closely with clients in support
                                    of their sales goals.
                                    <br><br>
                                    Christi joined Westwood in 2005 and helped develop Westwood's cutting-edge
                                    technology to support a fully digital customer experience.
                                    <br><br>
                                    Christi has worked in the insurance industry for nearly 30 years. Before Westwood,
                                    Christi worked at GEICO. There, she led Customer Service and Quality Control and
                                    Training Teams, and she also partnered with IT on customer-focused technology
                                    implementation.
                                </p>

                            </div>
                        </li>
                        <li class="card-header" id="headingSeven" data-toggle="collapse" data-target="#collapseSeven"
                            aria-expanded="true" aria-controls="collapseSeven"> <img class="image"
                                src="assets/img/leaders/tomkriby.jpg">
                            <div class="bottom-left"><b>Tom Kriby</b><br>VP, Client Development & Partnerships</div>
                            <div id="collapseSeven" class="overlay collapse pb-0 mb-0 overlayLast"
                                aria-labelledby="headingSeven" data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Tom Kriby</b><br>Vice President, Client Development & Partnerships</p>
                                <hr>
                                <p>Tom Kriby is Vice President, Client Development and Partnerships at Westwood
                                    Insurance Agency. In this role, Tom is responsible for national account management
                                    and business development, including diversifying the business into new growth
                                    channels.
                                    <br><br>
                                    Tom has nearly 20 years of sales, actuarial, and risk management leadership
                                    experience. Before joining Westwood, Tom served as Assistant Vice President,
                                    National Programs for Universal North America, where he led teams tasked with
                                    driving profitable business and growth. Before Universal, Tom held roles with
                                    Benfield Inc., JPMorgan Chase, and Tower Hill Insurance Group.
                                    <br><br>
                                    Tom holds a bachelor's degree in business administration from the University of
                                    Florida.
                                </p>

                            </div>
                        </li>

                        <li class="card-header" id="headingsix" data-toggle="collapse" data-target="#collapsesix"
                            aria-expanded="true" aria-controls="collapsesix"> <img class="image"
                                src="assets/img/leaders/greglewis.jpg">
                            <div class="bottom-left"><b>Greg Lewis</b><br>VP, Innovation & Transformation</div>
                            <div id="collapsesix" class="overlay collapse pb-0 mb-0" aria-labelledby="headingsix"
                                data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Greg Lewis</b><br>Vice President, Innovation & Transformation</p>
                                <hr>
                                <p>Greg Lewis is Vice President, Innovation and Transformation at Westwood Insurance
                                    Agency. In this role, he is responsible for advanced technology solutions that
                                    enable business efficiencies and opportunities.
                                    <br><br>
                                    Greg has more than 30 years of experience in planning, developing, and implementing
                                    information technology solutions for the home builder and insurance industries.
                                    Prior to Westwood, Greg served as Chief Information Officer at PropertyPlus
                                    Insurance Agency, a startup owned by Progressive Insurance. He also led Centex
                                    Financial Services' IT divisions, including CTX Mortgage, Commerce Title, and
                                    Commerce Title Insurance Company.
                                </p>

                            </div>
                        </li>
                        <li class="card-header" id="headingfive" data-toggle="collapse" data-target="#collapsefive"
                            aria-expanded="true" aria-controls="collapsefive">
                            <img class="image" src="assets/img/leaders/gabrielsalazar.jpg">
                            <div class="bottom-left"><b>Gabriel Salazar</b><br>VP, Agency Support Services</div>
                            <div id="collapsefive" class="overlay collapse pb-0 mb-0" aria-labelledby="headingfive"
                                data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Gabriel Salazar</b><br>Vice President, Agency Support Services</p>
                                <hr>
                                <p>Gabriel Salazar is Vice President, Agency Support Services at Westwood Insurance
                                    Agency. In this role, Gabriel is responsible for agency portfolio management and
                                    incoming carrier data transactions, quality control, and training.
                                    <br><br>
                                    Gabriel joined Westwood in 2008. Previously, he served as Agency Portfolio Manager,
                                    where he managed all insurance company relationships and implemented strategies to
                                    improve agency retention. He also served as Central Processing Manager, where he
                                    oversaw a team responsible for processing all agency and carrier transactions,
                                    including cancellations, renewals, new business, and payment follow-up for new and
                                    existing customers.
                                    <br><br>
                                    Gabriel holds a bachelor's degree in finance from California State University,
                                    Northridge.
                                </p>
                            </div>
                        </li>
                        <li class="card-header" id="headingthree" data-toggle="collapse" data-target="#collapsethree"
                            aria-expanded="true" aria-controls="collapsethree"> <img class="image"
                                src="assets/img/leaders/ben.jpg">
                            <div class="bottom-left"><b>Ben Sokoll</b><br>VP, Customer Support</div>
                            <div id="collapsethree" class="overlay collapse pb-0 mb-0" aria-labelledby="headingthree"
                                data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Ben Sokoll</b><br>Vice President, Customer Support</p>
                                <hr>
                                <p>Ben Sokoll is Vice President, Customer Support at Westwood Insurance Agency. In this
                                    role, Ben oversees Westwood's licensed customer service and retention teams to
                                    ensure policyholders receive superior service.
                                    <br><br>
                                    Ben joined Westwood in 2014 as Assistant Vice President, Sales, where he partnered
                                    with the country's top insurance companies to provide a complete line of personal
                                    insurance products to customers.
                                    <br><br>
                                    Ben began his career in insurance in commercial oil and gas, then transitioned to
                                    personal lines with AAA Texas, where he led call center teams and managed brick and
                                    mortar stores. <br><br>Ben holds a bachelor's degree in business from the
                                    University of North Texas.
                                </p>


                            </div>
                        </li>
                        <li id="headingtwo" data-toggle="collapse" data-target="#collapsetwo" aria-expanded="true"
                            aria-controls="collapsetwo"> <img class="image" src="assets/img/leaders/amandataylor.jpg">
                            <div class="bottom-left"><b>Amanda Taylor</b><br>VP, Communications & Marketing</div>
                            <div id="collapsetwo" class="overlay collapse pb-0 mb-0 overlayLast"
                                aria-labelledby="headingtwo" data-parent="#accordion">

                                <button type="button" class="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <p><b>Amanda Taylor</b><br> Vice President, Communications & Marketing</p>
                                <hr>
                                <p>Amanda Taylor is Vice President, Communications and Marketing at Westwood Insurance
                                    Agency. In this role, Amanda is responsible for branding, the customer journey, and
                                    delivering an integrated marketing strategy across all channels. She is committed
                                    to sharing Westwood's story in a powerful and meaningful way. Amanda previously
                                    served as Vice President, Corporate Development for Westwood.
                                    <br><br>
                                    Amanda has nearly 20 years of experience in corporate communications, public
                                    relations, digital marketing, advertising, and event management. Prior to Westwood,
                                    Amanda worked at QBE and LendingTree.
                                    <br><br>
                                    Amanda holds a bachelor's degree in business from Wake Forest University and an MBA
                                    from Queens University of Charlotte.
                                </p>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- //  R E S O U R C E S   -->

<!-- // I N T R O   P A R A G R A P H  -->

<!-- G E T   A   Q U O T E -->
<div class="pt-5">
    <app-get-aquote-small-banner></app-get-aquote-small-banner>
</div>

<!-- //  G E T   A   Q U O T E -->
<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section>


<!-- // K N O W L E D G E   C E N T E R  -->
<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->