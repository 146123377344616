import { Injectable } from "@angular/core";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class Constants {
  maxDate: NgbDate;
  constructor(private calendar: NgbCalendar) {}

  public LiveChatImage =
    window["base-href"] + "/assets/img/icons/male-telemarketer.png";
  public AgentPhotoFromSource =
    "C:/QBE_Projects/Westwood/UI/Web/AdvTrac/Trunk/ATMS.Presentation/Shared/TemplatesAndLogos/UserPhotoLogos/AgentPhoto.png";
  public PrintableVersionImage =
    window["base-href"] + "/assets/img/logos/headerlogo.png";
  public HomeTypeSecondary_Vacation = "a69";
  public HomeTypeRental = "FC3818C125B811D1AD7F0055007657A3";
  public errorMessage =
    "Sorry. We are unable to process your request at this time. Please try again later or call us at  <a href='tel:@formatPhoneNumberForMobile' class='req-msg-note'>@phoneNumber</a>.<br/>Thank you.";
  public sourcePhoneNumber = "888.543.6179";
  public SwimmingPoolNone = "a81";
  public hfChatLink =
    "://image.providesupport.com/js/1tx8v9rxsa1vm0cq1ur9nctzbv/safe-standard.js?ps_h=fDEP&ps_t=";
  public requiredErrorMessage = "Required field";
  public invalidFormatErrorMessage = "Invalid format";
  public invalidFutureDate = "Date cannot be in the future";
  public invalidPastDate = "Date cannot be in the past";
  public selectOneErrorMessage = "Please select one";
  public phoneTypesCannotMatchErrorMessages = "Phone Types cannot match";
  public RCVDetailsURL = "";
  public RequiredRow = "One row is required";
  public GarageType_None = "4C21C312-D7BC-480E-8DB9-8F60C20057E5";
  public invalidConfirmPassword =
    "Confirm requestor email and requestor email should match";

  getDOBMaxDate() {
    let year = this.calendar.getToday().year - 18;
    return new NgbDate(year, 12, 31);

    //return this.calendar.getPrev(this.calendar.getToday(), 'y', 18);
  }
}
export enum loanType {
  Owner = "F249F81E953B454E971498673FDFABF1", //HO3
  Purchase = "a29", //Condo-HO6
  Refinance = "a28", //Renter-HO4
  None = "none",
}
export enum policyTypeID {
  HO4 = "EF99E1E5CE9611D0AD7F0055007657A3",
}
export enum applyNowBtnType {
  DBSave,
  Regular,
  AlternateQuote,
  ContactMe,
}
