import { Constants } from './../../Utilities/Constants/constants';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Router } from '@angular/router';
import { Lead } from '../../model/lead';
import { DataService } from '../../service/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/model/user';
import { ThankYouService } from 'src/app/service/thank-you.service';
import { AdditionalProduct } from 'src/app/model/additionalProduct';
import { Source } from 'src/app/model/source';
import { RouterExtService } from 'src/app/service/router-ext.service';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';

@Component({
  selector: 'app-quote-thank-you',
  templateUrl: './quote-thank-you.component.html',
  styleUrls: ['./quote-thank-you.component.css']
})
export class QuoteThankYouComponent implements OnInit {

  AgentPic = '';
  isLoading=false;
  LiveChatImage = '';
  additionalProducts: Array<AdditionalProduct>;
  salesAgent: User;
  autoUrl: string = '';
  @ViewChild(ModelPopUpOtherDomainComponent) child:ModelPopUpOtherDomainComponent;

  constructor(public router: Router,
     public commonService: CommonService, public constantObj: Constants,
    public leadDetails: Lead,
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    public thankYouService: ThankYouService,
    private routerExtService: RouterExtService) {
    this.LiveChatImage = constantObj.LiveChatImage;
  }

  ngOnInit() {
    
    //this.commonService.ScrollToRTQForm();
    this.commonService.SaveLeadHistory(); 
    this.isLoading=true;
    this.leadDetails = this.dataService.leadDetails;
    this.salesAgent = this.leadDetails.salesAgent;
    //this.getDummyLead();
    this.getAdditionalProducts();
    if (this.leadDetails != null && this.leadDetails.salesAgent==undefined) {
      this.leadDetails.salesAgent = new User;
    }
   
  }
  photoURL() {
    if (this.leadDetails.salesAgent.salesAgentPhoto != null || this.leadDetails.salesAgent.salesAgentPhoto != '') {
      return this.sanitizer.bypassSecurityTrustUrl(this.leadDetails.salesAgent.salesAgentPhoto);
    }
    else {
      return '';
    }
  }
  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }
  getAdditionalProducts() {
    this.thankYouService.getAdditionalProducts(this.leadDetails.sourceInfo.id).subscribe((data: any) => { 
      this.isLoading=false; 
      this.additionalProducts = data;
      this.additionalProducts.forEach((item) => {
        item.link=item.link.replace('{LeadID}',this.leadDetails.leadID);
        if(item.name === 'Auto Insurance') {
          this.autoUrl = item.link;
        }
    });
    //this.dataService.leadDetails = new Lead();
    this.leadDetails = new Lead();
    }, err => {
       this.isLoading=false;
      this.dataService.handleError(err);
    });
  }
  openInsuranceSite(link:string)
  {
    window.open(link, '_blank');
  }
  getDummyLead()
  {
    this.leadDetails=new Lead();
    if (this.leadDetails != null ) {
      this.leadDetails.salesAgent = new User;
      this.leadDetails.salesAgent.phoneNumber='888-822-5396 ext 213143';
      this.leadDetails.sourceInfo=new Source();
      this.leadDetails.sourceInfo.id='FBB9E7DA38A24ABE90EB1047E1C7C5D8'
      this.leadDetails.leadID = '3C992EF3-CE0C-4914-8096-1E140F8640C7';
    }

  }


}
