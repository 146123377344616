import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { Constants } from '../Utilities/Constants/constants';
import { Lead } from '../model/lead';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';
import { LeadHistory } from '../model/leadHistory';
import { DataService } from './data.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CsrfToken } from '../model/csrfToken';
import { CSRFTokenService } from '../service/csrftoken.service';
import { v4 as uuid } from 'uuid';
import { CookieService } from "ngx-cookie-service";
declare var $: any;
@Injectable({
  providedIn: 'root'
})


export class CommonService implements CanActivate {
  url: string;

  constructor(
    private router: Router,
    public contantsObj: Constants,
    public dataService: DataService,
    private csrfTokenService: CSRFTokenService,

    public cookieService: CookieService
  ) { }

  /* Method Get UUID  */
  getUuid() {
    this.cookieService.set('GUID-Token', uuid(), 1, '/');
  }

  /* Method to set CSRF token */
  SetCSRFToken(navigate = false) { 
    // Check either coockie is present or not
    if (!(this.cookieService.check('GUID-Token') &&
      this.cookieService.check('XSRF-TOKEN')
    )
    ) {
      this.getUuid();
      // Call web service
      this.csrfTokenService.getCsrfToken().subscribe((data: any) => {
        if (data) {
          if(navigate) {
            this.router.navigate(["real-time-quote/cust-info"]);
          }
        }
      }, err => {
        this.dataService.handleError(err); 
      });
    } else {
      if(navigate) {
        this.router.navigate(["real-time-quote/cust-info"]);
      }
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // This router instance will have the current URL.
    // ActivatedRouteSnapshot and RouterStateSnapshot in canActivate will contain the URL that the user is attempting to access.
    if (!this.router.navigated) {
      if (next.url.length > 0 && next.url[0].path.indexOf('Real-Time-Quote') >= 0)
        this.NavigateToPage('real-time-quote/cust-info')
      else
        this.NavigateToPage('/')
      return false;
    }
    return true;
  }

  NavigateToPage(pageName) {
    this.router.navigate([pageName], { skipLocationChange: false });
  }

  NavigateToPageWithParams(pageName, params) {
    this.router.navigate([pageName], { queryParams: params });
  }

  gotoRelatedArticle(title) {
    let currentUrl = this.router.url;
    
      this.router.navigate(['/news/article', { title: title }]).then(() => {
      this.router.navigate(['/news/article', title ]);
    });
  }
  // Validations on blur and on submit
  updateControls(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.controls[field];
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        // Otherwise validation color is not shown
        control.updateValueAndValidity({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.updateControls(control);
      }
    });
  }

  // IF not populated correctly - you could get aggregated FormGroup errors object
  getErrors(formGroup: FormGroup, errors: any = {}) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.controls[field];
      if (control instanceof FormControl) {
        errors[field] = control.errors;
      } else if (control instanceof FormGroup) {
        errors[field] = this.getErrors(control);
      }
    });
    return errors;
  }

  //Set focus to the first error element
  focusFirstInvalidElement() {
    let target;
    target = $('input[type=text].ng-invalid, input[type=email].ng-invalid, input[type=date].ng-invalid, select.ng-invalid, input[type=radio].ng-invalid').first();
    if (target && $(target).offset() != undefined) {
      $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow', () => {
        target.focus();
      });
    }
  }


  //Live chat
  loadLiveChat() {
    if (!this.iMobBroswer()) {
      var seILpZ = document.createElement("script"); seILpZ.type = "text/javascript";
      var seILpZs = (location.protocol.indexOf("https") == 0 ? "https" : "http") + this.contantsObj.hfChatLink +
        new Date().getTime() + "&LeadRefNumber=WestwoodWebSite";
      seILpZ.src = seILpZs;
      $('#sdILpZ').append(seILpZ);
      var noscript = '<noscript><div style="display: inline"><a href="http://www.providesupport.com?messenger=0yy8mwsqq7bhg191imei5n0c0a">Live Chat</a></div></noscript>';
      $('#sdILpZ').after(noscript);

    }
  }
  //fortmats to 3 digits before decimal and 1 digit after decimal
  formatCurrency(num1) {
    var num = num1.target.value;
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num))
      num = "";
    if (num == "") {
      return 0.0;
    }

    num = (parseFloat(num).toFixed(1));

    var arrayAmount = num.split(".");
    var cents = arrayAmount[1];
    num = arrayAmount[0];

    return parseFloat(num + '.' + cents);
  }
  //There is one more method in Data service 
  formatPhoneNumber(phoneNumber: string) {
    var result;
    var number;
    var ext;
    if (phoneNumber != undefined && phoneNumber != null && phoneNumber.trim() != '') {
      if (phoneNumber.indexOf('ext') > 0) {
        number = phoneNumber.substring(0, phoneNumber.trim().indexOf('ext'));
        result = number.trim();
        var withExt = phoneNumber.substring(phoneNumber.trim().indexOf('ext'), phoneNumber.trim().length);
        if (withExt != undefined && withExt != '' && withExt != null && withExt.split(' ').length > 1) {
          ext = withExt.split(' ')[1];
          result = number.trim() + ',0,' + ext.trim();
        }
      }
      else {
        result = phoneNumber.trim();;
      }
    }
    return result;
  }
  isSafariMobBroswer() {
    var status = false;
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
    if (isSafari != undefined && isSafari != null) {
      if (isSafari) {
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (!iOSSafari)
          isSafari = true;
        else
          isSafari = false;
      }
      status = isSafari;
    }
    return isSafari;
  }
  iMobBroswer() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  }
  isSafariBroswer() {
    var status = false;
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
    if (isSafari != undefined && isSafari != null) {
      status = isSafari;
    }
    return status;
  }
  /*Create/Update Lead  Details by Lead Object */
  postLeadHistoryInfo(data: LeadHistory): Observable<LeadHistory> {
    this.url = Controller.Utility + '/' + Action.SaveLeadHistory;
    return this.dataService.postData(data, this.url);
  }
  SaveLeadHistory() {
    if (!this.dataService.leadHistory.noUpdate) {
      this.dataService.leadHistory.postValue = JSON.parse(JSON.stringify(this.dataService.leadDetails));
      this.postLeadHistoryInfo(this.dataService.leadHistory)
        .subscribe((data: LeadHistory) => {
          this.dataService.leadHistory.noUpdate = true;
        }, err => {
        });
    }
  }
  SaveLeadHistoryFromContactBtn() {
    if (!this.dataService.leadHistory.noUpdate) {

      this.postLeadHistoryInfo(this.dataService.leadHistory)
        .subscribe((data: LeadHistory) => {
          this.dataService.leadHistory.noUpdate = true;
        }, err => {
        });
    }

  }
  CreateLeadHistoryObj(pageNumber, leadObject: Lead) {
    this.dataService.leadHistory.noUpdate = false;
    this.dataService.leadHistory.pageNumber = pageNumber;
    this.dataService.leadHistory.postValue = JSON.parse(JSON.stringify(leadObject));
  }
  //Scroll to RTQ form on click of next button
  ScrollToRTQForm() {
    let target;
    target = $('div, li,tr,input[type=text], input[type=email], input[type=search], input[type=date], select, input[type=radio]').first();
    if (target && $(target).offset() != undefined) {
      target.focus();
    }
    window.scroll(200,0);
    // $("html, body").animate({ scrollTop: $('#dvLiveChat').offset().top }, "slow");
  }
  EmptyLeadObject() {
    if (this.router.routerState.snapshot.url.toLowerCase().indexOf('real-time-quote') <= -1) {
      this.dataService.leadDetails = new Lead();
    }
  }
  ScrollToArticleDetails() {
    if ($('#articleGrid').offset() && $('#articleGrid').offset().top)
    $("html, body").animate({ scrollTop: $('#articleGrid').offset().top }, "slow");
  }
  ScrollToArticleDetailsHeader() {
    //if ($('#articleGrid').offset() && $('#articleGrid').offset().top)
    $(window).scrollTop(0);
    //$("html, body").animate({ scrollTop: $('#articleGrid').offset().top }, "slow");
  }
  FormatRadioButton(event, value) {
    $(event.srcElement.parentNode.parentElement).find('.radio-btn').removeClass('active')
    let temp = event.srcElement.parentNode;
    for (let i = 0; i < temp.childNodes.length; i++) {
      if (temp.childNodes[i].nodeName === 'BUTTON') {
        if (temp.childNodes[i].classList.contains('active')) {
          temp.childNodes[i].classList.remove('active');
        }
      }
    }
    event.srcElement.classList.add('active')
  }
}
