import { Component, OnInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { DataService } from 'src/app/service/data.service';
declare function breadcrumbShow(): any;

@Component({
  selector: 'app-get-aquote',
  templateUrl: './get-aquote.component.html',
  styleUrls: ['./get-aquote.component.css']
})
export class GetAQuoteComponent implements OnInit {
  public wpBaseUrl: string;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'gaq-header.e37de77079814f71bfe580d5ce4a08b8.jpg',
    desktopHeaderText: 'Get a Quote',
    desktopHeaderSubText: "No matter what type of insurance you need, we’ll help you find the policy that’s right for you.",
    mobileHeaderText: 'Get a Quote',
    mobileHeaderSubText: ''
  });
  constructor(public dataService: DataService) { 
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }
}
