import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn, FormGroup, AbstractControl } from '@angular/forms';
import { CommonService } from '../service/common.service';

@Directive({
  selector: '[validatePhoneTypes][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ValidatePhoneTypesDirective,
    multi: true
  }]
})
export class ValidatePhoneTypesDirective implements Validator {
  validator: ValidatorFn;
  formErrors = {};
  fieldName:string;
  constructor() {
    this.validator = this.validatePhoneTypes();
  }
  validate(c: FormControl): { [key: string]: any } {
    return this.validator(c);
  }
  validatePhoneTypes(): ValidatorFn {
    return (c: FormControl) => { 
      if (c.value == null || c.value == undefined || c.value == '0' || c.value=='') {
        return null;
      }
      //gets the current field name
    this.fieldName= Object.keys(c.parent.controls).find(name => c === c.parent.controls[name]);
    
      if (c.parent.controls["altPhoneTypes"] && c.parent.controls["phoneTypes"]) {
        if (c.parent.controls["altPhoneTypes"].value != '0' && c.parent.controls["phoneTypes"].value != '0'
          && c.parent.controls["phoneTypes"].value != undefined
          && c.parent.controls["phoneTypes"].value != ''
          && c.parent.controls["altPhoneTypes"].value != undefined
          && c.parent.controls["altPhoneTypes"].value != ''
          ) {
          if (c.parent.controls["altPhoneTypes"].value == c.parent.controls["phoneTypes"].value) {
            //
           if(this.fieldName=="phoneTypes")
           {            
            //add error for the alternateTypes
            //control.setErrors({backend: {someProp: "Invalid Data"}});
            c.parent.controls["altPhoneTypes"].setErrors({validatePhoneTypes:{'valid': false}});
           }
           else
           {
            //add error for the phoneTypes
            c.parent.controls["phoneTypes"].setErrors({validatePhoneTypes:{'valid': false}});
           }
            return {
              validatePhoneTypes: {
                valid: false
              }
            };
          }
          else{
            c.parent.controls["altPhoneTypes"].setErrors(null);
            c.parent.controls["phoneTypes"].setErrors(null);
          }
        }
      }
      if (c.parent.controls["appSecondaryPhoneType"] && c.parent.controls["appPrimaryPhoneType"]) {
        if (c.parent.controls["appSecondaryPhoneType"].value != '0' && c.parent.controls["appPrimaryPhoneType"].value != '0'
          && c.parent.controls["appPrimaryPhoneType"].value != undefined
          && c.parent.controls["appPrimaryPhoneType"].value != ''
          && c.parent.controls["appSecondaryPhoneType"].value != undefined
          && c.parent.controls["appSecondaryPhoneType"].value != ''
          ) {
          if (c.parent.controls["appSecondaryPhoneType"].value == c.parent.controls["appPrimaryPhoneType"].value) {
            //
           if(this.fieldName=="appPrimaryPhoneType")
           {            
            c.parent.controls["appSecondaryPhoneType"].setErrors({validatePhoneTypes:{'valid': false}});
           }
           else
           {
            c.parent.controls["appPrimaryPhoneType"].setErrors({validatePhoneTypes:{'valid': false}});
           }
            return {
              validatePhoneTypes: {
                valid: false
              }
            };
          }
          else{
            c.parent.controls["appSecondaryPhoneType"].setErrors(null);
            c.parent.controls["appPrimaryPhoneType"].setErrors(null);
          }
        }
      }
      if (c.parent.controls["coAppSecondaryPhoneType"] && c.parent.controls["coAppPrimaryPhoneType"]) {
        if (c.parent.controls["coAppSecondaryPhoneType"].value != '0' && c.parent.controls["coAppPrimaryPhoneType"].value != '0'
          && c.parent.controls["coAppPrimaryPhoneType"].value != undefined
          && c.parent.controls["coAppPrimaryPhoneType"].value != ''
          && c.parent.controls["coAppSecondaryPhoneType"].value != undefined
          && c.parent.controls["coAppSecondaryPhoneType"].value != ''
          ) {
          if (c.parent.controls["coAppSecondaryPhoneType"].value == c.parent.controls["coAppPrimaryPhoneType"].value) {
            //
           if(this.fieldName=="coAppPrimaryPhoneType")
           {            
            c.parent.controls["coAppSecondaryPhoneType"].setErrors({validatePhoneTypes:{'valid': false}});
           }
           else
           {
            c.parent.controls["coAppPrimaryPhoneType"].setErrors({validatePhoneTypes:{'valid': false}});
           }
            return {
              validatePhoneTypes: {
                valid: false
              }
            };
          }
          else{
            c.parent.controls["coAppSecondaryPhoneType"].setErrors(null);
            c.parent.controls["coAppPrimaryPhoneType"].setErrors(null);
          }
        }
      }
      return null;
    };
  }
}

