import { Component, OnInit } from '@angular/core';
import { NewsArticlesService } from '../../service/news-articles.service';
import { DataService } from '../../service/data.service';
import { Constants } from '../../Utilities/Constants/constants';
import { CommonService } from '../../service/common.service';
import { ArticlesDetail, ArticlesMainView, ArticlesDetailView } from '../../model/article';
import { Router, ActivatedRoute, ParamMap, NavigationExtras, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { StateService } from '../../service/state.service';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit {
  NextLabel:string="Next";
  PrevLabel:string="Previous"
  const: Constants;
  data: ArticlesDetailView;
  ArticleID: number;
  PageNumber: number;
  PageSize: number;
  Month: number;
  Year: number;
  CategoryID: number;
  SearchTerm: string;
  isSearchDataLoaded: boolean = false;
  isLoading: boolean = false;
  shareLink: string = 'https://stg.westwoodinsurance.com/news/article?id=4';
  shareLinkMail: string = '';
  customText: string = '';
  pageTitle: string = '';

  relatedPost: any = [];
  articleTitle: string = '';

  constructor(
    public titleService: Title,
    public router: Router,
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService,
    public articleService: NewsArticlesService,
    private route: ActivatedRoute,
    public stateService: StateService
  ) {
  }

  ngOnInit() {
      
    //Initialise state
    this.stateService.changeCategoryID(0);
    this.stateService.changeSearchTerm('');
    this.stateService.changeMonth(0);
    this.stateService.changeYear(0);

    this.articleTitle = this.route.snapshot.params.title;
    if (this.articleTitle) {
      this.shareLink = window.location.origin + '/news/article?id=' + this.ArticleID;
      //get Article name
      this.getArticleNo();
    } else {
      this.router.navigate(['/page-not-found'], { queryParams: {} });
    }

  }

  getArticleNo(): any {
    this.isLoading = true;
    let articleNo = 0;
    this.getArticleDetailsOnLoad();
    // this.articleService.getArticleNo(this.articleTitle).subscribe((data: ArticlesMainView) => { 
    //   if(data && data.articlesMain[0] && data.articlesMain[0].articleNo){
    //     articleNo = data.articlesMain[0].articleNo
    //     this.ArticleID = articleNo; 

    //     this.getArticleDetailsOnLoad();
    //   }else{
    //     this.router.navigate(['/page-not-found'], { queryParams: {  } });
    //   }


    // }, err => {
    //   this.router.navigate(['/page-not-found'], { queryParams: {  } });
    // });
  }

  dataForRelatedPost(direction = '') {
    this.relatedPost = [];
    if (this.data.articlesRelated) {

      if (direction === 'next') {
        this.data.articlesRelated.push(this.data.articlesRelated.shift());

      } else if (direction === 'pre') {
        this.data.articlesRelated.unshift(this.data.articlesRelated.pop());

      }

      if (this.commonService.iMobBroswer()) {
        this.relatedPost.push(JSON.parse(JSON.stringify(this.data.articlesRelated[0])));
      } else {
        let len = this.data.articlesRelated.length;
        //comented for WSTW-5641
        // for (let i = 0; i < 3; i++) {
        //   if (i < len)
        //     this.relatedPost.push(JSON.parse(JSON.stringify(this.data.articlesRelated[i])));
        // }
        if(this.data.articlesRelated.length>0){
          for (let i = 0; i < this.data.articlesRelated.length; i++) {
            if (i < len)
              this.relatedPost.push(JSON.parse(JSON.stringify(this.data.articlesRelated[i])));
          }
        }

      }
    }


  }


  getArticleDetailsOnLoad() {
    this.isLoading = true;
    this.articleService.getArticleDetailsOnLoad(this.articleTitle).subscribe((data: ArticlesDetailView) => {

      this.data = data;
      this.dataForRelatedPost();
      this.titleService.setTitle(this.data.articlesDetail[0].name);
      this.isLoading = false;
      this.commonService.ScrollToArticleDetails();
      //console.log(this.data);
    }, err => {
      this.dataService.handleError(err);
    });
  }
  refreshArticleGrid(type: string) {
    this.router.navigate(['/news']);

  }

  SearchTermChanged() {
    //if (this.SearchTerm == '') {
    //  //this.getArticlesOnLoad(); this.isSearchDataLoaded = false; this.isSearchDataLoaded = false;
    //}
  }
  getArticlesOnSearch() {

  }

  popupfacebook() {

    let url = 'http://www.facebook.com/sharer.php?u=' + this.shareLink + '&quote=' + this.customText;
    let newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  popuplinkedin() {
    // https://www.linkedin.com/shareArticle?mini=true&url={articleUrl}&title={articleTitle}&summary={articleSummary}&source={articleSource}

    this.shareLink = encodeURIComponent(this.shareLink);

    let url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + this.shareLink;
    let newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  displayDateForArticleCategory(){
    if (this.data.articlesDetail[0].categoryName=="News and Press"){
      return true;
    }
    return false;
  }

}
