<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-mpd" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10 col-sm-10 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">My Policy Documents</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<!-- <section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a [href]="wpBaseUrl" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section> -->

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!--  P A Y M E N T   L I N K S  -->


<section id="payment-contact-and-website">
    <div class="container pt-5 pb-5">

        <div class="row">
            <div class="col ">
                <h2 class="text-center">Find your Insurance Company </h2>
                
            </div>
        </div>

        <div class="row text-center pt-2 pb-3">
            <div class="col text-center justify-content-center">
                <img src="assets/img/orange-divider-transparent.png" /><br><br>
                <p>Simply find your insurance company below and click to go directly to their request information page.</p>
            </div>
        </div>

        <div class="row justify-content-center license-numbers">
            <div class="col-md-12 col-lg-9 col-xs-9">

                <div class="row">
                    <input type="text" id="enter-state" onkeyup="myFunction()"
                        placeholder="Enter your insurance company…">
                </div>

                <div class="row">
                    <table id="license-table">
                        <tr class="header">
                            <th class="width-60"><span>INSURANCE COMPANY</span></th>
                            <th class="width-20"><span>CONTACT</span></th>
                            <th class="width-20"><span>WEBSITE</span></th>
                        </tr>

                        <tr *ngFor="let PolicyPageInfo of PolicyPageInfos">
                            <td class="text-left"><span>{{ PolicyPageInfo.bankName}}</span></td>
                            <td>
                                <div class="row text-center">
                                    <div class="col-md-12" *ngIf="PolicyPageInfo.policyContact"><img
                                            src="assets/img/icons/cell2.png" width="15" style="margin-right: 10px;" /><span>
                                            <a href="tel:{{ PolicyPageInfo.policyContact }}">
                                                {{PolicyPageInfo.policyContact }}</a>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="text-center">
                                    <div class="col-md-12" *ngIf="PolicyPageInfo.policyurl">
                                        <i class="far fa-file"></i> &nbsp;
                                        <a class="table-col-link"
                                            (click)="child.importantNotice($event, PolicyPageInfo.policyurl)"
                                            href="{{ PolicyPageInfo.policyurl }}" target="_blank">Policy Page</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- //  P A Y M E N T   L I N K S  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->