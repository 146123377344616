import { Component, Input, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import * as $ from 'jquery'
declare var jQuery: any;

@Component({
  selector: 'app-sub-page-banner',
  templateUrl: './sub-page-banner.component.html',
  styleUrls: ['./sub-page-banner.component.css']
})
export class SubPageBannerComponent implements OnInit {
  bannerImage: string = '';
  @Input('subPageBannerDetails') subPageBannerDetails: SubPageBannerDetails = <SubPageBannerDetails>{};
  constructor() { }

  ngOnInit() {

    // $(document).ready(function () {
    //   for (var i = 1; i < 99999; i++)
    //     window.clearInterval(i);
    //   //clearInterval(heroInterval);
    // });

    if (this.subPageBannerDetails.bannerImage) {
      this.bannerImage = 'assets/img/' + this.subPageBannerDetails.bannerImage;
    }
    //console.log(this.subPageBannerDetails);
  }



}
