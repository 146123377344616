<!-- Start form -->
<form (ngSubmit)="onSubmit(unsubscribe)" #unsubscribe="ngForm">
    <section id="rtq-form" class="pb-5 pt-3">
        <div class="container">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row justify-content-center">
                <div class="col-md-7 col-xs-12 " *ngIf="!showData">
                    <div class="form-div z-indexHeader">
                        <div class="row pt-3">
                            <div class="col">
                                <span class="font-weight-bold service-error-message">
                                    {{apiMessage}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 col-xs-12" *ngIf="showData">
                    <span class="pl-3">Un-Subscribe</span>
                    <br>
                    <br>
                    <div class="form-div z-indexHeader">
                        <div class="row pt-3">
                            <div class="col">
                                <p>
                                    By Selecting 'Confirm' Below, your email address will be removed and you will not
                                    receive future emails regarding opting in to receive text messages. Please be aware
                                    that by selecting to opt-out of any future emails you will not be informed of
                                    Westwood’s text messaging option.
                                </p>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col">
                                <p>
                                    We respect your privacy and do not share customer information with any third
                                    parties. Your email address is used to provide you information regarding Westwood’s
                                    text messaging service. If you would like receive emails regarding the text
                                    messaging service from Westwood Insurance Agency Please click 'Cancel' below.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="request-change-form" class="pb-5" *ngIf="showData">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <div>
                        <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="!this.commonService.isSafariBroswer()">
                                <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language" (success)="handleSuccess($event)"
                                    (load)="handleLoad()" (expire)="handleExpire()" [theme]="theme" [type]="type"
                                    [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                </ngx-recaptcha2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="false">
                                <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                                    (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type" [badge]="badge"
                                    [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                </ngx-invisible-recaptcha>
                            </div>
                        </div>
                        <label *ngIf="(captchaRequired && unsubscribe.submitted)" class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>

                        <div class="row pt-3">
                            <div class="col pr-5 pb-5">
                                <button type="button" tabindex="-1" class="btn pl-0" (click)="onCancel()">
                                    <div class="angled-button-sm button-width-110">
                                        <span>Cancel</span>
                                    </div> 
                                </button>
                            </div>
                            <div class="col pb-5 pt-2"> 
                                <button class="btn angled-button-sm button-align-mob-next button-width-90"  type="submit"><span >Confirm</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>
<!-- End form -->