<!-- <section id="header-img-about-us" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-4 col-md-5 col-sm-6 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">About Us</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->

<!-- H O M E   H E R O   -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>
<!-- // H O M E   H E R O  -->


<!-- //  H E A D E R   I M G   -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->

<!-- I N T R O   P A R A G R A P H  -->

<!-- <section id="intro-para">
	<div class="container text-center pt-3 pb-3">
		<div class="row justify-content-center">
			<div class="col-md-10 col-xs-9">
				<p>
					<span>Westwood Insurance Agency is one of the largest personal lines agencies in the United
						States.</span><br> Since
					1952, we’ve helped nearly half a million people protect what matters most.

				</p>
			</div>
		</div>
	</div>
</section> -->

<!-- // I N T R O   P A R A G R A P H  -->

<!-- I N T R O   P A R A G R A P H  -->

<section id="">
	<div class="container  pt-3">
		<div class="row">

			<div class="col-md-12 col-xs-12">
				<div class="col-md-6 col-xs-12" style="float: left;">
					<p>
						<img class="img-responsive" src="assets/img/page-body-img/shutterstock_431846191.png"
							alt="Couple getting insurance information" style="width: 100%" />
					</p>
				</div>
				<p style="text-align: left;">
					Westwood is an independent insurance agency established in 1952. Licensed in all 50 states, we
					represent hundreds of
					insurance products offered by the country’s top insurance companies. That’s an advantage for our
					customers, who benefit from a wide selection of products and pricing. And, while insurance can be
					complicated, our agents are experts and are dedicated to making it easy for our customers to get
					exactly what is right for them.
					<br><br>

					Our commitment to innovation means that we are constantly improving the way we do business and
					expanding what we offer to our customers. We supplement our agent’s skills with specialized
					technology to make it fast and easy for customers to buy insurance, change or add to their coverage,
					make hassle-free claims and get information and other valuable resources for managing their
					property. It’s the Westwood promise: We’ll be there when you need us most!
					<br>
				</p>


			</div>
			<div class="col-md-12 col-xs-9 justify-content-center text-center pt-4">
				<p>
					<span>Please watch this video for more information about Westwood.</span>
				</p>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->

<!-- V I D E O -->

<section class="bg-secondary">
	<div class="container text-center pt-4 pb-5">
		<div class="row pt-4">
			<div class="col">
				<!-- <iframe class="ww-video" title="Westwood Agency Insurance overview" width="800" height="450"
					src="https://www.youtube.com/embed/t_vSyrUeB20" frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" rel=0
					allowfullscreen></iframe> -->
				<iframe class="ww-video" title="Westwood Agency Insurance overview"
					width="800" height="450" src="https://www.youtube.com/embed/dr4JjyjqJk0" 
					title="About Westwood Insurance Agency" frameborder="0" 
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
					allowfullscreen>
				</iframe>
			</div>
		</div>
	</div>
</section>

<!-- //  V I D E O    -->

<!-- N A T I O N A L   C A R R I E R   L O G O S  -->

<section id="natl-carrier-logos">
	<div class="container pt-3 pb-5">
		<div class="row pt-5">
			<div class="col">
				<h2 class="col text-center">SOME OF WESTWOOD’S INSURANCE COMPANY PARTNERS </h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange decor" />
			</div>
		</div>
		        <!-- partner section  -->

				<section id="customer-test" class="pt-3 pb-3">
					<div class="container text-center">
						<div class="row justify-content-center">
							<div class="col">
								<div id="carouselExampleControls" class="carousel slide" data-ride="carousel"
									data-interval="3750">
									<div class="carousel-inner">
										<!-- First slide -->
										<div class="carousel-item active">
											<div class="row justify-content-center text-center logo-rows">
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/AIIC.jpg"
																alt="American Integrity Insurance Group" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/AMIG.jpg" alt="American Modern" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/CHUBB.jpg" alt="Chubb" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/FirstAmerican_PropAndCas.png"
																alt="First American Propety & Casualty Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/ForemostIG.jpg"
																alt="Foremost Insurance Group" />
														</div>
													</div>
												</div>
		
											</div>
										</div>
										<!-- Second slide 
											 
											-->
										<div class="carousel-item ">
											<div class="row justify-content-center text-center logo-rows">
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/GeoVeraI.jpg" alt="GeoVera Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/homeowners-oa.png"
																alt="Homeowners of American Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/MI-Authorized-Agent-Logo-Vertical-PRINT.jpg"
																alt="Mercury Insurance Authorized Agent" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/NatGenPremier.jpg"
																alt="NatGen Premier" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/Progressive_Logo.png"
																alt="Progressive Logo" />
														</div>
													</div>
												</div>
											</div>
										</div>
		
										<!-- 3rd slide -->
		
										<div class="carousel-item">
											<div class="row justify-content-center text-center logo-rows">
		
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/QBE.png" alt="QBE" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/rli-logo.png"
																alt="RLI Different Works" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/SafecoLMAC.png" alt="Safeco Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/Selective-Insurance-RGB.png"
																alt="Selective Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/Stillwater_InsGrp_LOGO_vrt_Bld_clr.jpg"
																alt="Stillwater Insurance Group" />
														</div>
													</div>
												</div>
		
											</div>
										</div>
										<!-- 4th slide -->
										<div class="carousel-item">
											<div class="row justify-content-center text-center logo-rows">
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/Travelers-logo.gif"
																alt="Travelers Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/TowerHillIns.jpg"
																alt="Tower Hill Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/una-logo.png"
																alt="Universal North America" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/State-Auto.png"
																alt="State Auto Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/AH_GI_logo_hor_grn.jpg"
																alt="Arrowhead Insurance" />
														</div>
													</div>
												</div>
		
											</div>
										</div>
										<!-- 5th Slide -->
										<div class="carousel-item">
											<div class="row justify-content-center text-center logo-rows">
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/clearcover_wordmark_rgb_logo.png"
																alt="Clearcover Insurance" />
														</div>
													</div>
												</div>
												<div class="col-lg-2 col-md-2 col-sm-4">
													<div class="natl-logo">
														<div class="natl-logo-item">
															<img src="assets/img/logos/Wright-flood.jpg"
																alt="Wright Flood insurance" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<a class="carousel-control-prev partner-landing-prev" href="#carouselExampleControls"
										role="button" data-slide="prev">
										<span class="carousel-control-prev-icon" aria-hidden="true">
											<img src="assets/img/arrow-lft.png" alt="orange left arrow decor"
												id="left-arrow-image">
										</span>
										<span class="sr-only">Previous</span>
									</a>
									<a class="carousel-control-next partner-landing-next" href="#carouselExampleControls"
										role="button" data-slide="next">
										<span class="carousel-control-next-icon" aria-hidden="true">
											<img src="assets/img/arrow-rght.png" id="right-arrow-image"
												alt="orange left arrow decor">
										</span>
										<span class="sr-only">Next</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
		
				<!-- // partner section  -->
		<!-- <div class="row justify-content-center text-center logo-rows">
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/AIIC.jpg" alt="American Integrity Insurance Group" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/AMIG.jpg" alt="American Modern" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/CHUBB.jpg" alt="Chubb" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/FirstAmerican_PropAndCas.png"
							alt="First American Propety & Casualty Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/ForemostIG.jpg" alt="Foremost Insurance Group" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/GeoVeraI.jpg" alt="GeoVera Insurance" />
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-center align-items-center logo-rows">
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/homeowners-oa.png" alt="Homeowners of American Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/MI-Authorized-Agent-Logo-Vertical-PRINT.jpg"
							alt="Mercury Insurance Authorized Agent" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/NatGenPremier.jpg" alt="NatGen Premier" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/Progressive_Logo.png" alt="Progressive Logo" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/QBE.png" alt="QBE" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/rli-logo.png" alt="RLI Different Works" />
					</div>
				</div>
			</div>
		</div>
		<div class="row logo-rows">
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/SafecoLMAC.png" alt="Safeco Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/Selective-Insurance-RGB.png" alt="Selective Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/Stillwater_InsGrp_LOGO_vrt_Bld_clr.jpg"
							alt="Stillwater Insurance Group" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/Travelers-logo.gif" alt="Travelers Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/TowerHillIns.jpg" alt="Tower Hill Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/una-logo.png" alt="Universal North America" />
					</div>
				</div>
			</div>
		</div>

		<div class="row logo-rows">
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/State-Auto.png" alt="State Auto Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/AH_GI_logo_hor_grn.jpg" alt="Arrowhead Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/clearcover_wordmark_rgb_logo.png" alt="Clearcover Insurance" />
					</div>
				</div>
			</div>
			<div class="col-lg-2 col-md-2 col-sm-4">
				<div class="natl-logo">
					<div class="natl-logo-item">
						<img src="assets/img/logos/Wright-flood.jpg" alt="Wright Flood insurance" />
					</div>
				</div>
			</div>
		</div> -->
	</div>
</section>

<!-- //   N A T I O N A L    C A R R I E R   L O G O S   -->



<section class="bg-secondary">
	<div class="container text-center pt-4 pb-5">
		<div class="row pt-4">
			<div class="col">
				<div class="row pt-2">
					<div class="col">
						<h2 class="col text-center">BUILD A CAREER WITH WESTWOOD</h2>
					</div>
				</div>
				<div class="row justify-content-center text-center pt-2 pb-1">
					<div class="col-8">
						<img src="assets/img/orange-divider-transparent.png" alt="orange decor" /> <br><br>

						Join an insurance expert that’s more like a family and help us bring leading solutions and a
						caring touch
						to customers every day.
						Ready to become part of the team? Explore job opportunities, requirements, benefits and more.

						<br><br>

						<span *ngIf="false">Just click through to our parent company, QBE, and search “Westwood.”</span>

					</div>
				</div>
				<div class="row">
					<div class="col text-center ">
						<a class="show-cursor a-text-decoration-none"
							href="https://baldwinriskpartners.wd1.myworkdayjobs.com/Westwood-Insurance-Agency" target="_blank">
							<div class="angled-button-sm button-align-mob-center button-width-120">
								<span>Careers</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- G E T   A   Q U O T E -->
<div class="pt-5">
	<app-get-aquote-small-banner></app-get-aquote-small-banner>
</div>

<!-- //  G E T   A   Q U O T E -->
<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center></app-knowledge-center>
</section>


<!-- // K N O W L E D G E   C E N T E R  -->
<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->