import { Component, OnInit, OnDestroy, NgZone, ElementRef, ViewChild, Inject, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { DataService } from '../../service/data.service';
import { Constants } from '../../Utilities/Constants/constants';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Texting } from '../../model/texting';
import { OptInrentersService } from '../../service/opt-in-renters.service';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
declare var grecaptcha: any;
declare var $:any;
@Component({
  selector: 'app-opt-in-renters',
  templateUrl: './opt-in-renters.component.html',
  styleUrls: ['./opt-in-renters.component.css']
})
export class OptInRentersComponent implements OnInit,AfterViewChecked {
  formErrors: any = {};
  isLoading: boolean = false;
  requestPolicyChangeForm: FormGroup;
  captchaRequired = true;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  businessUnitName: '';
  businessUnitiD: '';
  recaptcha: string;
  badge = 'Bottomright';

  constructor(private router: Router, public commonService: CommonService,
    public dataService: DataService,
    private zone: NgZone,
    private route: ActivatedRoute,
    public optInrentersService: OptInrentersService,
    public textingDetails: Texting,
    public constantsObj: Constants,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string) {
    var isSafari = this.commonService.isSafariBroswer();
    if (isSafari) {
      this.captchaRequired = false;
      this.siteKey = siteKeyValueV3;
    }
    else {
      this.siteKey = siteKeyValue;
    }
    if (!this.commonService.isSafariBroswer()) {
      $('.grecaptcha-badge').remove()
    }
    else {
      $('.grecaptcha-badge').css('display', 'block !important');
      $('.grecaptcha-badge').css('visibility', 'visible !important');
    } 

  }

  ngOnInit() {
    this.textingDetails = new Texting();
    this.getBusinessUnit();
  }

  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  handleSuccess(event) {
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA
  getBusinessUnit() {
    this.isLoading = true;
    this.optInrentersService.getBusinessUnit().subscribe((data:  any)  =>  {
      if (data) { 
        this.businessUnitName = data.businessUnitName;
        this.businessUnitiD = data.businessUnitiD;
      }
      this.isLoading  =  false;
    }, err  =>  {
      this.isLoading  =  false;
      this.dataService.handleError(err);
    });
  }
  // Start Get list of renters

  onSubmit(optInRentersForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(optInRentersForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(optInRentersForm);
    if (!optInRentersForm.valid) {
      this.commonService.focusFirstInvalidElement();
      return;
    }
    if (optInRentersForm.valid && !this.captchaRequired) {
      if (this.commonService.isSafariBroswer()) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => { 
          if (token != '') {
          this.textingDetails.reCaptcha = new ReCaptcha();
          this.textingDetails.reCaptcha.response = token;
          this.textingDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
          this.postData();
        }
        });
      }
      else{
        this.postData();
      }
    
    }
  }
  postData()
  {
    this.captchaRequired = false;
    this.isLoading = true;
    this.optInrentersService.SaveOptinRenterChanges(this.textingDetails).subscribe((data: any) => {
      this.isLoading = false;
      if (data) {
        alert(data);
      }else{
        alert("Something went wrong, please try again!");
      }
      window.location.reload()
    }, err => {
      this.isLoading = false; 
      this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      window.location.reload()
    });
  }
  ngAfterViewChecked() {
   
  }
}
