import { Component, OnInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component'; 
@Component({
  selector: 'app-alert1-info',
  templateUrl: './alert1-info.component.html',
  styleUrls: ['./alert1-info.component.css']
})
export class Alert1InfoComponent implements OnInit {

  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child:ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

}
