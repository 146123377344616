import { DogTypes } from "./dogTypes";
import { OtherPets } from "./otherPets";
import { Renovation } from "./renovation";
import { LossHistory } from "./lossHistory";
import { LookUpValues } from "./look-up-values";

export class SupplementalInformation {
    public poolType: string;
    public isPoolFence: boolean;
    public haveSlideDivingBoard?: boolean;
    public haveTrampoline?: boolean;
    public isOwnerOccupied?: boolean;
    public isVacant?: boolean;
    public haveDogs?: boolean;
    public locked?: boolean;
    public haveOtherPets?: boolean;
    public haveRenovation?:boolean;
    public haveClaims?: boolean;
    public isBusinessOnPremise?: boolean;
    public haveDivingBoard?: boolean;
    public isTrampolineFenced?: boolean;
    public rVs?: boolean;
    public dogs: Array<DogTypes> = [];
    public otherPets: Array<OtherPets> = [];
    public renovations: Array<Renovation> = [];
    public lossHistories: Array<LossHistory> = [];
    public lstPoolTypes: Array<LookUpValues>[]=[];
    public lstBreedTypes: Array<LookUpValues>[]=[];
    public lstRenovationTypes: Array<LookUpValues>[]=[];
    public lstRenovationExtentTypes: Array<LookUpValues>[]=[];
    public lstLossTypes: Array<LookUpValues>[]=[];
    constructor()
    {
        this.poolType="";
        // this.isPoolFence=null;
        // this.haveSlideDivingBoard=null;
        // this.locked=null;
        // this.haveTrampoline=null;
        // this.isOwnerOccupied=null;
        // this.isVacant=null;
        // this.haveDogs=null;
        // this.haveOtherPets=null;
        // this.haveRenovation=null;
        // this.haveClaims=null;
    }
}