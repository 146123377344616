<section id="QuoteUnavailable" class="pb-5 mt-2">
    <div class="container">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center d-none d-lg-block">
            <img src="assets/img/RTQ-No-Quote-Loading.gif" style="width: 50%; margin-bottom: 10px;" />
            <h4 style="color: #004c97; font-weight: 600;">Thank you!</h4>
            <h4 style="color: #ff6900; font-weight: 600;">
                Based on the information you provided, <br />
                we need a little more time to put the <br />
                finishing touches on your quote. <br />
                You'll be hearing from one of our agents soon.
            </h4>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 text-center d-sm-block d-md-block d-lg-none">
            <img src="assets/img/RTQ-No-Quote-Loading.gif" style="width: 50%; margin-bottom: 10px;" />
            <h4 style="color: #004c97; font-weight: 600;">Thank you!</h4>
            <h4 style="color: #ff6900; font-weight: 600;">
                Based on the information you provided, we need a little more time to put the finishing touches on your quote. You'll be hearing from one of our agents soon.
            </h4>
        </div>
    </div>
</section>