<form (ngSubmit)="onSubmit(custForm)" autocomplete="off" #custForm="ngForm" class="rtq-form"
    (keydown.enter)="$event.preventDefault();onSubmit(custForm)">
    <section id="ServiceUnavailable" class="pb-5" *ngIf="this.dataService.leadDetails.isServiceUnavailable">
        <div class="container-xl">
            <div class="row justify-content-center col-md-12">
                <div class="col-md-7 col-xs-12">
                    <span id="messageError" class="text-uppercase service-error-message"
                        [innerHTML]="dataService.leadDetails.errorMessage"></span>
                </div>
            </div>
        </div>
    </section>
    <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
    <!-- A D D R E S S -->
    <div id="divCustInfo" class="container-xl" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
        <section id="applicant-info">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="page-header-text">Tell Us About Yourself</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3 class="section-header-text">Applicant Information</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label" for="appFirstName">First Name <span
                                class="text-danger">*</span></label>
                        <div class="input-group disabled">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="user"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input autofocus type="text" class="form-control form-control-lg" id="appFirstName"
                                name="appFirstName" maxLength="17"
                                aria-describedby="appFirstName" [(ngModel)]="leadDetails.firstName"
                                [ngClass]="{'is-invalid': ((custForm.submitted || appFirstName.touched) && (appFirstName.hasError('required')))}"
                                #appFirstName="ngModel" required />
                            <div class="invalid-feedback invalid-feedback-with-icon" style="display: block;"
                                *ngIf="((custForm.submitted || appFirstName.touched) && (appFirstName.hasError('required')))">
                                Please provide a valid First Name.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="appMiddleName">Middle Name</label>
                        <input type="text" class="form-control form-control-lg" id="appMiddleName"
                            [(ngModel)]="leadDetails.middleName" name="appMiddleName" maxLength="20">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="appLastName">Last Name <span
                                class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-lg" id="appLastName"
                            name="appLastName" maxLength="20" aria-describedby="appLastName" required
                            [(ngModel)]="leadDetails.lastName"
                            [ngClass]="{'is-invalid': ((custForm.submitted || appLastName.touched) && (appLastName.hasError('required')))}"
                            #appLastName="ngModel">
                        <div class="invalid-feedback" style="display: block;"
                            *ngIf="((custForm.submitted || appLastName.touched) && (appLastName.hasError('required')))">
                            Please provide a valid Last Name.
                        </div>
                    </div>
                </div>
            </div>

            <!-- APPLICANT MOBILE PHONE -->
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label" for="applicantPrimaryPhone">Primary Phone Number
                            <span class="text-danger">*</span></label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="phone"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-lg" id="applicantPrimaryPhone"
                                name="applicantPrimaryPhone" aria-describedby="applicantPrimaryPhone" minlength="10"
                                placeholder="(999)999-9999" [(ngModel)]="leadDetails.homePhone"
                                (blur)="checkPhone($event)"
                                validateDuplicatePhone [phoneOneName]="'applicantPrimaryPhone'" [phoneTwoName]="'appSecondaryPhone'"
                                [ngClass]="{'is-invalid': ((custForm.submitted || appPrimaryPhone.touched) && (appPrimaryPhone.hasError('required') || appPrimaryPhone.hasError('minlength') || appPrimaryPhone.hasError('validateDuplicatePhone') || appPrimaryPhone.hasError('validateDuplicatePhone')))}"
                                #appPrimaryPhone="ngModel" minlength="10" mask="(000)000-0000" required>
                            <div class="invalid-feedback  invalid-feedback-with-icon" style="display: block;"
                                *ngIf="(custForm.submitted || appPrimaryPhone.touched) && (appPrimaryPhone.hasError('required'))">
                                Please provide a valid Primary Phone.
                            </div>
                            <div class="invalid-feedback  invalid-feedback-with-icon" style="display: block;" 
                                *ngIf="appPrimaryPhone.hasError('validateDuplicatePhone') || appSecondaryPhone.hasError('validateDuplicatePhone')">
                                Phone should not be same.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="appPrimaryPhoneType">Primary Phone
                            Type
                            <span class="text-danger">*</span></label>
                        <select class="form-control form-control-lg" id="appPrimaryPhoneType" name="appPrimaryPhoneType"
                            [ngModelOptions]="{ updateOn: 'blur' }" #phoneTypes="ngModel"
                            [(ngModel)]="leadDetails.homePhoneType" required #appPrimaryPhoneType="ngModel"
                            validatePhoneTypes
                            [ngClass]="{ 'is-invalid':( ( custForm.submitted  || appPrimaryPhoneType.touched) && appPrimaryPhoneType.hasError('required')) }">
                            <option selected [ngValue]="''"></option>
                            <option *ngFor="let item of lstPhoneTypes" [value]="item.phoneValue">
                                {{item.phoneType}}</option>
                        </select>
                        <div class="invalid-feedback" style="display: block;"
                            *ngIf="(phoneTypes.hasError('required') || phoneTypes.hasError('validatePhoneTypes')) && phoneTypes.touched || ((leadDetails.homePhoneType == '' || leadDetails.homePhoneType=='0' || leadDetails.homePhoneType==null) && custForm.submitted)">
                            <div *ngIf="phoneTypes.hasError('required')">
                                Please provide a valid Primary Phone Type.
                            </div>
                            <div *ngIf="phoneTypes.hasError('validatePhoneTypes')">
                                Phone Type should not be same.
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- APPLICANT Secondary PHONE -->
            <div class="row">
                <div class="col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label" for="appSecondaryPhone">Secondary Phone
                            Number</label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="d-md-none">
                                        <fa-icon icon="phone-square"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-lg" id="appSecondaryPhone"
                                name="appSecondaryPhone" aria-describedby="appSecondaryPhone"
                                placeholder="(999)999-9999" mask="(000)000-0000"
                                (blur)="checkPhone($event)"
                                [(ngModel)]="leadDetails.alternatePhone" #appSecondaryPhone="ngModel" 
                                [required]="(leadDetails.alternatePhone != null && ( leadDetails.alternatePhoneType != null && leadDetails.alternatePhoneType != '') )"
                                validateDuplicatePhone [phoneOneName]="'applicantPrimaryPhone'" [phoneTwoName]="'appSecondaryPhone'"
                                [ngClass]="{'is-invalid': ((custForm.submitted || appSecondaryPhone.touched) && (appSecondaryPhone.hasError('required') || appSecondaryPhone.hasError('minlength') || appSecondaryPhone.hasError('validateDuplicatePhone') || appSecondaryPhone.hasError('validateDuplicatePhone')))}" minlength="10">
                            <div class="invalid-feedback" style="display: block;"
                                *ngIf="(custForm.submitted ||appSecondaryPhone.touched)">
                                <div *ngIf="appSecondaryPhone.hasError('required')">
                                    Please provide a valid secondary phone.
                                </div>
                                <div *ngIf="appPrimaryPhone.hasError('validateDuplicatePhone') || appSecondaryPhone.hasError('validateDuplicatePhone')">
                                    Phone should not be same.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="appSecondaryPhoneType" class="input-group-label ml-0">Secondary
                            Phone Type</label>

                        <select class="form-control form-control-lg" id="txtPhoneTypes" name="appSecondaryPhoneType"
                            [ngModelOptions]="{ updateOn: 'blur' }" [(ngModel)]="leadDetails.alternatePhoneType"
                            [required]="(leadDetails.alternatePhoneType != null && (leadDetails.alternatePhone != null && leadDetails.alternatePhone != ''))"
                            #appSecondaryPhoneType="ngModel" validatePhoneTypes>
                            <option selected [ngValue]="''"></option>
                            <option *ngFor="let item of lstPhoneTypes" [value]="item.phoneValue">
                                {{item.phoneType}}</option>
                        </select>
                        <div class="invalid-feedback" style="display: block;"
                            *ngIf="(custForm.submitted || appSecondaryPhoneType.touched )">
                            <div *ngIf="appSecondaryPhoneType.hasError('required')">
                                Please provide a valid secondary phone type.
                            </div>
                            <div *ngIf="appSecondaryPhoneType.hasError('validatePhoneTypes')">
                                Phone Type should not be same.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="input-group-label" for="applicant-email">Email <span
                                class="text-danger">*</span></label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="envelope"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="email" class="form-control form-control-lg" id="appEmail" name="appEmail"
                                aria-describedby="appEmail" required [(ngModel)]="leadDetails.email"
                                emailValidator
                                [ngClass]="{'is-invalid': ((custForm.submitted || appEmail.touched) && (appEmail.hasError('required') || appEmail.hasError('emailValidator')))}"
                                #appEmail="ngModel" />

                            <div class="invalid-feedback invalid-feedback-with-icon" style="display: block;"
                                *ngIf="(custForm.submitted || appEmail.touched)">
                                <div *ngIf="appEmail.hasError('required') || appEmail.hasError('emailValidator')">
                                    Please provide a valid email.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <label class="input-group-label" for="applicantDOB">Date of Birth <span
                                class="text-danger">*</span></label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="calendar-check"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input class="form-control form-control-lg" placeholder="mm/dd/yyyy" name="applicantDOB"
                                #applicantDOBModel="ngModel" [(ngModel)]="leadDetails.applicantDOB" ngbDatepicker
                                required [minDate]="ngbMinDate" [maxDate]="ngbMaxDate" type="text" appMaskInputDirective
                                [appMaskValue]="leadDetails.applicantDOB" dateValidator #applicantDOB="ngbDatepicker"
                                [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':(( custForm.submitted || applicantDOBModel.touched) &&
                                    ( applicantDOBModel.hasError('required')
                                        ||applicantDOBModel.hasError('maxDateValidator')
                                        || applicantDOBModel.hasError('dateValidator') 
                                        || applicantDOBModel?.status=='INVALID'))}" />
                            <div class="input-group-append" style="padding-left: 2px;">
                                <button class="btn btn-outline-secondary calendar" (click)="applicantDOB.toggle()"
                                    type="button"></button>
                            </div>

                            <div class="input-group-append">
                                <i class="fa fa-question-circle help helper-icon helperPrimaryAppDob"></i>
                                <!-- <button class="btn btn-link top-tip-icon" style="margin-left: 10px;" type="button"
                                    aria-label="Learn About Date Of Birth" [ngbPopover]="primaryAppDob"
                                    triggers="mouseenter:mouseleave" [popoverTitle]="primaryAppDobTitle"
                                    container="body"><i class="fas"><fa-icon
                                            style="color: #28445a !important; font-size: 20px; margin-left: -12px;"
                                            icon="question-circle"></fa-icon></i></button> -->
                            </div>
                            <!-- <ng-template #primaryAppDob>
                                <div [innerHTML]="toolTipMessage.dateOfBirth"></div>
                            </ng-template>
                            <ng-template #primaryAppDobTitle>Date of Birth:</ng-template> -->
                            <div class="invalid-feedback invalid-feedback-with-icon" style="display: block;" *ngIf="(custForm.submitted || applicantDOBModel.touched) &&
                                ( applicantDOBModel.hasError('required')
                                ||applicantDOBModel.hasError('maxDateValidator')
                                || applicantDOBModel.hasError('dateValidator') 
                                || applicantDOBModel?.status=='INVALID')">
                                Please provide a valid date of birth.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <label class="input-group-label" for="priAppOccupation">Occupation</label>
                    <div class="input-group">
                        <div class="input-group-prepend d-none d-sm-block">
                            <span class="input-group-text mt-2">
                                <i class="fas mt-1">
                                    <fa-icon icon="building"></fa-icon>
                                </i>
                            </span>
                        </div>
                        <select class="form-control form-control-lg" id="priAppOccupation" name="priAppOccupation"
                            [(ngModel)]="leadDetails.priAppOccupation">
                            <option selected="selected"></option>
                            <option *ngFor="let occupation of lstOccupation" [ngValue]="occupation.id">
                                {{ occupation.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center mt-3 pb-3">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="applicant-self" name="applicant-self">
                        <label class="custom-control-label" for="applicant-self">I am
                            self-employed</label>
                    </div>
                </div>
            </div>
            
        </section>
    </div>
    <div class=" bg-light pt-4 pb-4" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
        <div>
            <div class="container-xl">
            <div class="row">
                <div class="col-12">
                    <h3 class="section-header-text">Co-Applicant Information</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label" for="coapplicant-fname">Co-Applicant First
                            Name</label>
                        <div class="input-group disabled">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="user"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-lg"
                                id="coapplicant-fname" #applicantFname="ngModel"
                                aria-describedby="applicant-fname" [(ngModel)]="leadDetails.coFirstName"
                                name="coapplicantFname" maxlength="17" #coapplicantFname="ngModel"
                                [ngModelOptions]="{ updateOn: 'change' }">
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="coapplicant-mname">Co-Applicant
                            Middle Name</label>
                        <input type="text" class="form-control form-control-lg" id="coapplicant-mname"
                            name="coapplicant-mname" #coapplicantMname="ngModel" maxlength="20"
                            [(ngModel)]="leadDetails.coMiddleName"
                            [ngModelOptions]="{ updateOn: 'change' }">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="coapplicant-lname">Co-Applicant Last
                            Name</label>
                        <input type="text" class="form-control form-control-lg" id="coapplicant-lname"
                            name="coapplicant-lname" #coapplicantLname="ngModel" maxlength="20"
                            [(ngModel)]="leadDetails.coLastName"
                            [ngModelOptions]="{ updateOn: 'change' }">

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label" for="coAppPrimaryPhone">Primary Phone Number</label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="phone"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-lg" id="coAppPrimaryPhone"
                                name="coAppPrimaryPhone" aria-describedby="coAppPrimaryPhone"
                                placeholder="(999)999-9999" mask="(000)000-0000"
                                [(ngModel)]="leadDetails.coAppPrimaryPhone"
                                (blur)="checkPhone($event)"
                                [ngModelOptions]="{ updateOn: 'change' }" #coAppPrimaryPhone="ngModel"
                                minlength="10" 
                                [required]="leadDetails.coAppPrimaryPhoneType != null && leadDetails.coAppPrimaryPhoneType != ''"
                                validateDuplicatePhone [phoneOneName]="'coAppPrimaryPhone'" [phoneTwoName]="'coAppSecondaryPhone'"
                                [ngClass]="{ 'is-invalid': (( (custForm.submitted || coAppPrimaryPhone.touched ) &&
                                        ( coAppPrimaryPhone.hasError('required') || coAppPrimaryPhone.hasError('minlength') || coAppPrimaryPhone.hasError('validateDuplicatePhone') || coAppSecondaryPhone.hasError('validateDuplicatePhone') ) 
                                    )) }">
                            <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted || coAppPrimaryPhone.touched )">
                                <diV *ngIf="coAppPrimaryPhone.hasError('required') || coAppPrimaryPhone.hasError('minlength')">
                                    Please provide a valid co-applicant's primary phone type.
                                </diV>
                                <div
                                    *ngIf="coAppPrimaryPhone.hasError('validateDuplicatePhone') || coAppSecondaryPhone.hasError('validateDuplicatePhone')">
                                    Phone should not be same.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="coAppPrimaryPhoneType">Primary Phone
                            Type</label>
                        <select class="form-control form-control-lg" id="coAppPrimaryPhoneType"
                            name="coAppPrimaryPhoneType" #coAppPrimaryPhoneType="ngModel"
                            [(ngModel)]="leadDetails.coAppPrimaryPhoneType"
                            [required]="leadDetails.coAppPrimaryPhone != null && leadDetails.coAppPrimaryPhone != ''"
                            [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':( ( custForm.submitted  || coAppPrimaryPhoneType.touched) &&
                            (coAppPrimaryPhoneType.hasError('validatePhoneTypes'))) }"
                            validatePhoneTypes>
                                <option selected="selected" [ngValue]="''"></option>
                                <option [ngValue]="'CoApp Cellular'">Cell</option>
                                <option [ngValue]="'CoApp Home'">Home</option>
                                <option [ngValue]="'CoApp Work'">Work</option>
                        </select>
                        <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted || coAppPrimaryPhoneType.touched )                                  ">
                            <div *ngIf="coAppPrimaryPhoneType.hasError('validatePhoneTypes')">
                                Phone type should not be same.
                            </div>
                            <div *ngIf="coAppPrimaryPhoneType.hasError('required')">
                                Please provide a valid co-applicant's primary phone type.
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label" for="coAppSecondaryPhone">Secondary Phone
                            Number</label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="d-md-none">
                                        <fa-icon icon="phone-square"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="text" class="form-control form-control-lg"
                                id="coAppSecondaryPhone" name="coAppSecondaryPhone"
                                aria-describedby="coAppSecondaryPhone" placeholder="(999)999-9999"
                                (blur)="checkPhone($event)"
                                mask="(000)000-0000" [(ngModel)]="leadDetails.coAppSecondaryPhone"
                                [required]="(leadDetails.coAppSecondaryPhoneType != null && leadDetails.coAppSecondaryPhoneType != '')"
                                [ngModelOptions]="{ updateOn: 'change' }" #coAppSecondaryPhone="ngModel"
                                validateDuplicatePhone [phoneOneName]="'coAppPrimaryPhone'" [phoneTwoName]="'coAppSecondaryPhone'"
                                minlength="10" [ngClass]="{ 'is-invalid':
                                        (( (custForm.submitted || coAppSecondaryPhone.touched ) &&
                                        (coAppSecondaryPhone.hasError('minlength') || coAppSecondaryPhone.hasError('required') || coAppSecondaryPhone.hasError('validateDuplicatePhone') ) 
                                    )) }" [required]>
                            <div class="invalid-feedback" style="display: block;"
                                *ngIf="(custForm.submitted || coAppSecondaryPhone.touched )">
                                <div *ngIf="coAppSecondaryPhone.hasError('minlength') || coAppSecondaryPhone.hasError('required')">
                                    Please provide a valid co-applicant's secondary phone.
                                </div>
                                <div *ngIf="coAppPrimaryPhone.hasError('validateDuplicatePhone') || coAppSecondaryPhone.hasError('validateDuplicatePhone')">
                                    Phone should not be same.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                        <label class="input-group-label ml-0" for="coAppSecondaryPhoneType">Secondary
                            Phone Type</label>
                        <select class="form-control form-control-lg" id="coAppSecondaryPhoneType"
                            name="coAppSecondaryPhoneType" #coAppSecondaryPhoneType="ngModel"
                            [(ngModel)]="leadDetails.coAppSecondaryPhoneType"
                            [required]="leadDetails.coAppSecondaryPhone != null && leadDetails.coAppSecondaryPhone != ''"
                            [ngModelOptions]="{ updateOn: 'change' }" 
                            [ngClass]="{ 'is-invalid':( ( custForm.submitted  || coAppSecondaryPhoneType.touched) && (coAppSecondaryPhoneType.hasError('validatePhoneTypes') || coAppSecondaryPhoneType.hasError('required'))) }"
                            validatePhoneTypes>
                                <option selected="selected" [ngValue]="''"></option>
                                <option [ngValue]="'CoApp Cellular'">Cell</option>
                                <option [ngValue]="'CoApp Home'">Home</option>
                                <option [ngValue]="'CoApp Work'">Work</option>
                        </select>
                        <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted || coAppSecondaryPhoneType.touched )                                  ">
                            <div *ngIf="coAppPrimaryPhoneType.hasError('validatePhoneTypes') || coAppSecondaryPhoneType.hasError('validatePhoneTypes')">
                                Phone type should not be same.
                            </div>
                            <div *ngIf="coAppSecondaryPhoneType.hasError('required')">
                                Please provide a valid co-applicant's secondary phone type.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Co-Applicant EMAIL -->
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="form-group">
                        <label class="input-group-label" for="coapplicantEmail">Email </label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="envelope"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="email" class="form-control form-control-lg" id="coAppEmail"
                                name="coAppEmail" aria-describedby="coAppEmail" maxlength="40"
                                [(ngModel)]="leadDetails.coAppEmail" [ngClass]="{ 'is-invalid':( (custForm.submitted ||  coAppEmail.touched)
                                && 
                                coAppEmail.hasError('emailValidator')) }"
                                [ngModelOptions]="{ updateOn: 'change' }" emailValidator
                                #coAppEmail="ngModel">
                            <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted ||  coAppEmail.touched)">
                                <div *ngIf="coAppEmail.hasError('emailValidator')">Please provide a valid co-applicant's email.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Co-Applicant DOB -->
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="form-group">
                        <label class="input-group-label" for="coapplicantDOB">Date of Birth </label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="calendar-check"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <div>
                                <div class="input-group" style="margin-bottom: 0px;">
                                    <input class="form-control form-control-lg" placeholder="mm/dd/yyyy"
                                        name="coApplicantDOB" #coApplicantDOBModel="ngModel"
                                        [(ngModel)]="leadDetails.coApplicantDOB" ngbDatepicker
                                        [minDate]="ngbMinDate" [maxDate]="ngbMaxDate" type="text"
                                        appMaskInputDirective
                                        [appMaskValue]="leadDetails.coApplicantDOB" dateValidator
                                        #coApplicantDOB="ngbDatepicker"
                                        [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':(( custForm.submitted || coApplicantDOBModel.touched) &&
                                        ( coApplicantDOBModel.hasError('required')
                                        ||coApplicantDOBModel.hasError('maxDateValidator')
                                        || coApplicantDOBModel.hasError('dateValidator') 
                                        || coApplicantDOBModel?.status=='INVALID'))}">
                                    <div class="input-group-append" style="padding-left: 2px;">
                                        <button class="btn btn-outline-secondary calendar"
                                            (click)="coApplicantDOB.toggle()" type="button"></button>
                                    </div>
                                </div>

                            </div>
                            <div class="invalid-feedback" style="display: block;" *ngIf="( custForm.submitted || coApplicantDOBModel.touched) &&
                                    ( coApplicantDOBModel.hasError('maxDateValidator')
                                    || coApplicantDOBModel.hasError('dateValidator')
                                    || coApplicantDOBModel?.status=='INVALID')">
                                Please provide a co-applicant's valid date of birth.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">

                        <label class="input-group-label" for="secAppOccupation">Occupation</label>
                        <div class="input-group">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text mt-2">
                                    <i class="fas">
                                        <fa-icon icon="building"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <select class="form-control form-control-lg" id="secAppOccupation"
                                name="secAppOccupation" [(ngModel)]="leadDetails.coAppOccupation">
                                <option selected="selected"></option>
                                <option *ngFor="let occupation of lstOccupation"
                                    [ngValue]="occupation.id">
                                    {{ occupation.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="coapplicant-self"
                            name="coapplicant-self" />
                        <label class="custom-control-label" for="coapplicant-self">I am
                            self-employed</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    </div>

    <div class="container-xl mt-3" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
        <section id="property-address">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="section-header-text">Property Address</h3>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 col-lg-7">
                    <div class="form-group">
                        <label class="input-label" for="propartyAddress">Street Address <span
                                class="required-span">*</span></label>
                        <input type="search" class="form-control form-control-lg" id="propartyAddress"
                            name="propartyAddress" required
                            [(ngModel)]="leadDetails.customerAddress.streetAddress" maxlength="36"
                            appGoogleAutoCompletePlaces
                            (onselect)="SetPropertyAddressDetails($event); populateMailingAddress();"
                            (change)="populateMailingAddress();" autocomplete="search"
                            #propStreetAddress #propertyAdrress1="ngModel"
                            [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':
                            ((custForm.submitted || propertyAdrress1.touched )
                            && (propertyAdrress1.hasError('required')))} ">
                        <div class="invalid-feedback ml-0" style="display: block;" *ngIf="(custForm.submitted || propertyAdrress1.touched )
                            && (propertyAdrress1.hasError('required'))">
                            Please provide a valid street address.
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                        <label for="property-addr2">Apartment/Unit</label>
                        <input type="text" class="form-control form-control-lg" id="property-addr2"
                            name="property-addr2" #popertyAdrress2
                            [(ngModel)]="leadDetails.customerAddress.line2" maxlength="36">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-lg-5">
                    <div class="form-group">
                        <label class="input-label" for="propertyCity">City <span
                                class="required-span">*</span></label>
                        <input type="text" class="form-control form-control-lg" id="property-city"
                            name="propertyCity" maxlength="23" required #propStreetCity
                            [(ngModel)]="leadDetails.customerAddress.city"
                            #propertyCity="ngModel" [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':
                            ((custForm.submitted || propertyCity.touched )
                            && (propertyCity.hasError('required')))} ">
                        <div class="invalid-feedback ml-0" style="display: block;" *ngIf="(custForm.submitted || propertyCity.touched )
                                && (propertyCity.hasError('required'))">
                            Please provide a valid city.
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="form-group">
                        <label for="property-st">State <span class="required-span">*</span></label>
                        <select class="form-control form-control-lg" id="propartyState"
                            name="propartyState" required
                            (change)="onChangePropertyState(propStreetState)" #propStreetState
                            [(ngModel)]="leadDetails.customerAddress.state" #propertyState="ngModel"
                            [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':
                        ((custForm.submitted || propertyState.touched )
                        && (propertyState.hasError('required')))} ">
                            <option selected="selected" value=""></option>
                            <option *ngFor="let state of lstStates" [value]="state.stateName">
                                {{ state.stateName }}
                            </option>
                        </select>
                        <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted || propertyState.touched )
                            && (propertyState.hasError('required'))">
                            Please provide a valid state.
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="form-group">
                        <label for="propartyZip">Zip Code <span class="required-span">*</span></label>
                        <input type="text" class="form-control form-control-lg" name="propartyZip"
                            id="propartyZip" minlength="5" maxlength="10" #popertyZip ZipcodeValidator
                            #popertyZipModel="ngModel"
                            [ngClass]="{ 'is-invalid':(( custForm.submitted || popertyZipModel.touched) 
                        && (popertyZipModel.hasError('ZipcodeValidator') || popertyZipModel.hasError('required')))}"
                            [ngModelOptions]="{ updateOn: 'change' }" required
                            [(ngModel)]="leadDetails.customerAddress.zipCode">

                        <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted || popertyZipModel.touched) 
                            && (popertyZipModel.hasError('ZipcodeValidator') || popertyZipModel.invalid ||  popertyZipModel.hasError('required'))">
                            Please provide a valid zip code.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-12">
                    <label for="County">County</label>
                    <input type="text" #propCounty class="form-control form-control-lg"
                        id="txtPropertyCounty" #propCountys maxlength="19"
                        [(ngModel)]="leadDetails.customerAddress.county" name="County">
                </div>
            </div>




        </section>
    </div>


    <div class="container-xl mt-3 mb-3" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
        <section>
                <div class="row pt-3 pb-3" *ngIf="true">
                    <div class="col-sm-12 col-md-8 col-lg-6">
                        <label for="policynumber">Please select one <span
                                class="required-span">*</span></label>
                        <div class="form-check form-check-inline pb-2 w-100">
                            <button type="button" [(ngModel)]="leadDetails.loanType"
                                [value]="loanType.Owner"
                                (click)="this.commonService.FormatRadioButton($event,loanType.Owner);loanTypeUpdateValidation(loanType.Owner);"
                                #LoanType="ngModel" #loantype1 id="loanTypeOwner" name="LoanType" ngDefaultControl required
                                [ngClass]="{'ww-radio-button-selected': leadDetails.loanType==loanType.Owner}"
                                class="btn ww-radio-button text-left">I am the current owner of this property</button>
                        </div>
                        <div class="form-check form-check-inline pb-2 w-100">
                            <button type="button" [(ngModel)]="leadDetails.loanType"
                                [value]="loanType.Purchase" id="loanTypePurchase" name="LoanType" 
                                (click)="this.commonService.FormatRadioButton($event,loanType.Purchase);loanTypeUpdateValidation(loanType.Purchase);"
                                #LoanType="ngModel" ngDefaultControl required
                                [ngClass]="{'ww-radio-button-selected': leadDetails.loanType==loanType.Purchase}"
                                class="btn ww-radio-button text-left">I am planning to purchase this property</button>
                        </div>
                        <div class="form-check form-check-inline pb-2 w-100">
                            <button type="button" [(ngModel)]="leadDetails.loanType"
                                [value]="loanType.None" id="loanTypeNone" name="LoanType" 
                                (click)="this.commonService.FormatRadioButton($event,loanType.None);loanTypeUpdateValidation(loanType.None);"
                                #LoanType="ngModel" ngDefaultControl required
                                [ngClass]="{'ww-radio-button-selected': leadDetails.loanType==loanType.None}"
                                class="btn ww-radio-button text-left">I am planning to rent this property from someone else</button>
                        </div>
                        <label
                            *ngIf="(LoanType.hasError('required') && (LoanType.touched || custForm.submitted))"
                            class="text-nowrap" style="font-size: 0.875em; font-weight: 600; color: #dc3545;">{{constantsObj.selectOneErrorMessage}}</label>
                    </div>
                </div>
                <div class="row" *ngIf="!(leadDetails.loanType==loanType.Owner || leadDetails.loanType=='')" style="text-align: left;">
                    <div class="col-sm-12 col-md-5 col-lg-4">
                        <label for="lblEstCloseDate" class="required">
                            {{leadDetails.loanType==loanType.Purchase ? "What is your estimated close date?":(leadDetails.loanType==loanType.None?"What date would you like this quote to be effective?":"")}} <span class="required-span">*</span>
                        </label>
                        <div class="input-group disabled mr-0">
                            <div class="input-group-prepend d-none d-sm-block">
                                <span class="input-group-text">
                                    <i class="fas">
                                        <fa-icon icon="calendar-check"></fa-icon>
                                    </i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="MM/DD/YYYY"
                                name="EstCloseDate" #EstCloseDateModel="ngModel"
                                [(ngModel)]="leadDetails.estCloseDate"
                                (input)="formatDate($event, 'EstCloseDate')" id="txtEstCloseDate"
                                #EstCloseDate="ngbDatepicker" ngbDatepicker [minDate]="ngbMinEstCloseDate"
                                type="text" [required]="true"
                                [required]="leadDetails.loanType==loanType.Purchase || leadDetails.loanType==loanType.None">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar"
                                    (click)="EstCloseDate.toggle()" type="button"></button>
                            </div>
                            <div class="invalid-feedback invalid-feedback-with-icon" style="display: block;" *ngIf="(custForm.submitted || EstCloseDateModel.touched)">
                                <div *ngIf="EstCloseDateModel.hasError('required') && leadDetails.loanType == loanType.Purchase">
                                    Estimated loan close date is required.
                                </div>
                                <div *ngIf="EstCloseDateModel.hasError('required') && leadDetails.loanType == loanType.None">
                                    Estimated loan start date is required.
                                </div>
                                <div *ngIf="(EstCloseDateModel.touched && EstCloseDateModel?.status == 'INVALID' && EstCloseDateModel.value != null && leadDetails.loanType == loanType.Purchase)">
                                    Valid estimated close date is required.
                                </div>
                                <div *ngIf="(EstCloseDateModel.touched && EstCloseDateModel?.status == 'INVALID' && EstCloseDateModel.value != null)  && leadDetails.loanType == loanType.None">
                                    Valid estimated loan start date is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    </div>

    <div class=" bg-light pt-4 pb-3" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <div class="container-xl">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="section-header-text">Current Mailing Address</h3>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 col-md-12 col-lg-12">
                        <div class="form-group ml-0">
                            <div class="custom-control custom-switch pt-md-1 pt-lg-3">
                                <input type="checkbox" class="custom-control-input"
                                    id="propertyAddressSameAsMailing"
                                    (change)="copyPropertyAddressToMailing($event)"
                                    [(ngModel)]="leadDetails.isMailingAddressSame"
                                    aria-describedby="propertyAddressSameAsMailing"
                                    name="propertyAddressSameAsMailing">
                                <label class="custom-control-label" for="propertyAddressSameAsMailing">
                                    Is your current mailing address the same as the property address?
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-8 col-lg-7">
                        <div class="form-group">
                            <label class="input-label" for="mailingAddress">Street Address
                                <span class="required-span">*</span></label>
                            <input type="search" class="form-control form-control-lg" id="mailingAddress"
                                name="mailingAddress" autocomplete="search" #mailingAddress1
                                appGoogleAutoCompletePlaces (onselect)="SetMailingAddressDetails($event)"
                                appGoogleAutoCompletePlaces required
                                [(ngModel)]="leadDetails.mailingAddress.streetAddress" maxlength="36"
                                [disabled]="leadDetails.isMailingAddressSame" #mailingAddress1Mdl="ngModel"
                                [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid':
                                ((custForm.submitted || mailingAddress1Mdl.touched )
                                && (mailingAddress1Mdl.hasError('required')))} ">
                            <div class="invalid-feedback ml-0" style="display: block;" *ngIf="(custForm.submitted || mailingAddress1Mdl.touched )
                                && (mailingAddress1Mdl.hasError('required'))">
                                Please provide a valid street address.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <div class="form-group">
                            <label for="mailing-addr2">Apartment/Unit</label>
                            <input type="text" class="form-control form-control-lg" id="mailing-addr2"
                                name="mailing-addr2" #mailingAddress2
                                [disabled]="leadDetails.isMailingAddressSame"
                                [(ngModel)]="leadDetails.mailingAddress.line2" maxlength="36">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-5">
                        <div class="form-group">
                            <label class="input-label" for="mailing-city">City
                                <span class="required-span">*</span>
                            </label>
                            <input type="text" class="form-control form-control-lg" id="mailing-city"
                                name="mailing-city" #mailingCity required
                                [(ngModel)]="leadDetails.mailingAddress.city" maxlength="23"
                                #mailingCityMdl="ngModel" [ngModelOptions]="{ updateOn: 'change' }"
                                [disabled]="leadDetails.isMailingAddressSame" [ngClass]="{ 'is-invalid':((custForm.submitted || mailingCityMdl.touched )
                                    && (mailingCityMdl.hasError('required')))}">
                            <div class="invalid-feedback ml-0" style="display: block;" *ngIf="(custForm.submitted || mailingCityMdl.touched )
                                && (mailingCityMdl.hasError('required'))">
                                Please provide a valid city.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-6 col-md-3 col-lg-2">
                        <div class="form-group">
                            <label for="mailingState">State <span class="required-span">*</span></label>
                            <select class="form-control form-control-lg" id="mailingState" #propMailingState
                                name="mailingState" [(ngModel)]="leadDetails.mailingAddress.state" required
                                [disabled]="leadDetails.isMailingAddressSame" #mailingStateModel="ngModel"
                                [ngModelOptions]="{ updateOn: 'change' }" [ngClass]="{ 'is-invalid': ((custForm.submitted || mailingStateModel.touched )
                                    && (mailingStateModel.hasError('required'))) }">
                                <option selected="selected" value=""></option>
                                <option *ngFor="let state of lstStates" [value]="state.stateName">{{
                                    state.stateName }}</option>
                            </select>
                            <div class="invalid-feedback" style="display: block;" *ngIf="(custForm.submitted || mailingStateModel.touched )
                                && (mailingStateModel.hasError('required'))">
                                Please provide a valid state.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-6 col-md-3">
                        <div class="form-group">
                            <label for="mailing-zip">Zip Code <span class="required-span">*</span></label>
                            <input type="text" class="form-control form-control-lg" name="mailing-zip"
                                id="mailing-zip" #mailZipCode #mailingZipModel="ngModel" ZipcodeValidator
                                required="" [(ngModel)]="leadDetails.mailingAddress.zipCode"
                                [disabled]="leadDetails.isMailingAddressSame"
                                [ngClass]="{ 'is-invalid':(( custForm.submitted || mailingZipModel.touched)
                                                    && (mailingZipModel.hasError('ZipcodeValidator') || mailingZipModel.hasError('required') ))}"
                                [ngModelOptions]="{ updateOn: 'change' }">

                            <div class="invalid-feedback" style="display: block;"
                                *ngIf="( custForm.submitted || mailingZipModel.touched) && (mailingZipModel.hasError('ZipcodeValidator') || mailingZipModel.invalid || mailingZipModel.hasError('required')  )">
                                    Please provide a valid zip code.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
    </div>
    <div class="col pt-3 pr-5 pb-2 text-right" *ngIf="false">
        <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" [type]="type" [badge]="badge" [ngModel]="recaptcha"
            (ready)="handleReady()" (load)="handleLoad()" (success)="handleSuccess($event)"
            [ngModelOptions]="{ standalone: true }">
        </ngx-invisible-recaptcha>
    </div>
    <section class="container-xl mt-3">
        <div class="row pl-3 pt-3" *ngIf="leadDetails.isDuplicateLead">
            <span id="messageError" class="font-weight-bold service-error-message" [innerHTML]='leadDetails.message'>
            </span>
        </div>
    </section>
    <section>
        <div class="container-xl mt-2" *ngIf="!this.dataService.leadDetails.isServiceUnavailable">
            <div class="row">
                <p class="req-msg-note ml-3 required-p">*These fields are required</p>
            </div>
        </div>
    </section>
    <!-- <section id="request-change-form" class="pb-5">
        <div class="container" [hidden]="this.dataService.leadDetails.isServiceUnavailable">
            <div class="row justify-content-center">
                <div class="col-md-12 col-xs-12">
                    <div>
                        <div class="row"
                            *ngIf="!leadDetails.isEmailProspect && !this.dataService.leadDetails.isServiceUnavailable">
                            <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                                    (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type"
                                    [badge]="badge" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                </ngx-invisible-recaptcha>
                        </div>
                        <label *ngIf="(captchaRequired && custForm.submitted)"
                            class="required-p text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                        <div class="row pl-3" *ngIf="leadDetails.isDuplicateLead">
                            <span id="messageError" class="font-weight-bold service-error-message"
                                [innerHTML]='leadDetails.message'>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="bg-white d-sm-block mt-3 mb-5">
        <div class="container-xl">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn ww-button" aria-label="Go Back to Previous Screen"
                        (click)="GotoHomePage()" tabindex="-1">
                        <i>
                            <fa-icon icon="chevron-left"></fa-icon> <fa-icon icon="chevron-left"></fa-icon>
                        </i>
                        Previous
                    </button>
                </div>
                <div class="col text-right">
                    <button type="submit" class="btn ww-button pull-right mr-0" aria-label="Go to Next Step" [disabled]="isLoading">
                        Next <i>
                            <fa-icon icon="chevron-right"></fa-icon><fa-icon icon="chevron-right"></fa-icon>
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </section>
</form>