import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-earthquake',
  templateUrl: './earthquake.component.html',
  styleUrls: ['./earthquake.component.css']
})
export class EarthquakeComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'eq-header.png',
    desktopHeaderText: 'Earthquake Insurance',
    desktopHeaderSubText: "Most homeowners policies don't cover earthquake damage. Earthquake insurance provides coverage for your home and personal property, as well as temporary living arrangements.",
    mobileHeaderText: 'Earthquake Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="https://www.arrowheadexchange.com/ao/ao.jsp?control_prodcode=165039&control_prodkey=a8BJLqwqcYhTorlmAWVN36W1nc0%3D&policy_product=erqk&site=ao" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://www.arrowheadexchange.com/ao/ao.jsp?control_prodcode=165039&control_prodkey=a8BJLqwqcYhTorlmAWVN36W1nc0%3D&policy_product=erqk&site=ao" target="_blank" class=" btn ww-button">Get a Quote</a>'
  });
  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

}
