<section class="bg-secondary">
	<div class="container text-center pt-4">
		<div class="row pt-4">
			<div class="col">
				<div class="row pt-2">
					<div class="col">
						<h2 class="col text-center">Information for NY Policyholders
                            Affected by COVID-19
                        </h2>
					</div>
				</div>
				<div class="row justify-content-center pt-2 pb-4">
					<div class="col-md-8 col-lg-8 col-xs-8 col-sm-12">
						<img class="text-center" src="assets/img/orange-divider-transparent.png" alt="orange decor" /> <br><br>
                        <div class="col-md-12 text-left">
                            <p>
                                Dear Policyholder,<br /><br />
                                A recent Executive Order issued by Governor Cuomo, together with recent amendments to the insurance and banking regulations (the &#34;regulations&#34;) issued by the New York State Department of Financial Services (&#34;Department&#34;), extend grace periods and give you other rights under certain property/casualty insurance policies if you are an individual or small business and can demonstrate financial hardship as a result of the novel coronavirus (&#34;COVID 19&#34;) pandemic (&#34;affected policyholder&#34;).  These grace periods and rights are currently in effect but are temporary, though they may be extended further.  Please check the Department’s website at  <a class="text-break show-cursor" (click)="child.importantNotice($event, 'https://www.dfs.ny.gov/consumers/coronavirus')" href="#">https://www.dfs.ny.gov/consumers/coronavirus</a> for updates.
                            </p>
                            <p>
                                If you are an individual, generally, personal lines property/casualty insurance policies are covered by these amendments, including auto, homeowners and renters insurance.  If you are an individual and an affected policyholder, please contact your insurer or broker if you are uncertain whether your policy is covered.
                            </p>
                            <p>
                                If you are a small business, only certain types of commercial lines property/casualty insurance policies are covered by these amendments, generally including property, fire, commercial general liability, special multiperil, medical malpractice, workers compensation, commercial auto (including livery and other for-hire vehicles), and commercial umbrella insurance. A business qualifies as a &#34;small business&#34; if it is resident in New York State, is independently owned and operated, and employs 100 or fewer individuals.  If you are a small business and an affected policyholder, please contact your insurer or broker if you are uncertain whether your policy is covered.
                            </p>
    
                            <ul class="text-break">
                                <li>A copy of the Executive Order and regulations can be found at <a class="show-cursor" (click)="child.importantNotice($event, 'https://www.governor.ny.gov/news/no-20213-continuing-temporary-suspension-and-modification-laws-relating-disaster-emergency')" href="#" >https://www.governor.ny.gov/news/no-20213-continuing-temporary-suspension-and-modification-laws-relating-disaster-emergency</a> and <a class="show-cursor" (click)="child.importantNotice($event, 'https://www.dfs.ny.gov/system/files/documents/2020/03/re_consolidated_amend_pt_405_27a_27c_new_216_text.pdf')" href="#">https://www.dfs.ny.gov/system/files/documents/2020/03/re_consolidated_amend_pt_405_27a_27c_new_216_text.pdf</a>, respectively.</li>
                            </ul>
                            <p>
                                <b>Moratorium on Cancellation, Non-Renewal, and Conditional Renewal</b><br />
                                If you are an affected policyholder, there is a moratorium on your insurer cancelling, non-renewing, or conditionally renewing your property/casualty insurance policy for a period of 60 days.  If you do not make a timely premium payment and can demonstrate financial hardship as a result of the COVID-19 pandemic, your insurer may not impose any late fees relating to the premium payment or report you to a credit reporting agency or a debt collection agency regarding such premium payment.
                            </p>
                            <p>
                                <b>Catching up on Overdue Insurance Payments</b><br />
                                The regulations also require your insurer to permit you, as an affected policyholder, to pay the overdue premium over a 12-month period if you did not make a timely premium payment due to financial hardship as a result of the COVID-19 pandemic and can still demonstrate financial hardship as a result of the COVID-19 pandemic.  This also applies if the insurer sent you a nonpayment cancellation notice prior to March 29, 2020.
                            </p>
    
                            <p>
                                <b>Policies Financed by Premium Finance Agencies – Grace Period</b><br />
                                If your insurance policy has been financed through a premium finance agency, and you, as an affected policyholder, do not make an installment payment, the premium finance agency may not cancel your policy for a period of at least 60 days, including any contractual grace period, and subject to the safety and soundness of the premium finance agency.  In addition, if you do not make a timely installment payment to the premium finance agency, the premium finance agency must extend the due date for the installment payment by at least 60 days, may not impose any late fees relating to that installment payment, and may not report you to a credit reporting agency or a debt collection agency regarding that installment payment.
                            </p>
                            <p>
                                <b>How to Demonstrate Financial Hardship</b><br />
                                If you, as an affected policyholder, are unable to make a timely premium payment due to financial hardship as a result of the COVID-19 pandemic, you may submit to your insurer or premium finance agency, as applicable, a statement that you swear or affirm in writing under penalty of perjury that you are experiencing financial hardship as a result of the COVID-19 pandemic, which the insurer or premium finance agency, as applicable, shall accept as satisfactory proof.  Such statement is not required to be notarized. 
                            </p>
                            <p>
                                <b>Questions</b><br />
                                If you have any questions regarding your rights under the Executive Order or regulations, please contact your insurer or Westwood Insurance Agency at <a href="tel:18006765066." class="telNum"> 800.676.5066.</a> 
                            </p> 
                            <br/>  
                        </div> 
					</div>
				</div> 
			</div>
		</div>
	</div>
</section>

<!-- E X T E R N A L    S I T E    M O D A L   --> 

<app-model-pop-up-other-domain></app-model-pop-up-other-domain> 

<!-- E X T E R N A L    S I T E    M O D A L   -->