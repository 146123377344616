import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-minimize-your-loss',
  templateUrl: './minimize-your-loss.component.html',
  styleUrls: ['./minimize-your-loss.component.css']
})
export class MinimizeYourLossComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'MinimizeLoss.jpg',
    desktopHeaderText: 'Minimize Your Loss',
    desktopHeaderSubText: 'In many cases, you can help control the extent that your home and possessions are damaged and help protect your property from further damage.',
    mobileHeaderText: 'Minimize Your Loss',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    breadcrumbShow();
  }
}
