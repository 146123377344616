<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-homeowners" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">Homeowners Insurance</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none d-none">
	<div class="container intro-para-homeowners text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<p>
					<span> Protecting your home with Westwood homeowners insurance products is not just economical, but
						it’s quick, easy and convenient.</span><br>
					Your home is more than a structure &mdash; it’s a place where you feel safe and
					comfortable, and
					where you create lifetime memories with the people who matter most. And because your home may be one
					of your most
					significant assets, it’s essential to protect it and everything inside of it that you value.
					<br>
					<br>
					<span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
						questions regarding Homeowners Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
				</p>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  H O M E   O W N E R S   I N S U R A N C E   -->

<section>
	<div class="container pt-3 pb-5">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center">Why Homeowners Insurance?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="assets/img/page-body-img/shutterstock_795098068.jpg" style="width: 100%;"
					alt="Homeowners Insurance" />
			</div>
			<div class="col mt-2">
				Your home is vulnerable to many risks &mdash; from destructive storms, liability from injuries,
				vandalism and
				more. Homeowners coverage is critical to protect you and your home from the financial consequences of a
				devastating loss. <br><br>

				If you are financing your home, your lender will require that you obtain adequate insurance coverage to
				protect the investment. Even if you own your home outright, homeowners coverage provides the financial
				security to help you sleep soundly!
			</div>
		</div>
	</div>
</section>

<!-- // W H Y  H O M E   O W N E R S   I N S U R A N C E   -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section class="bg-secondary" id="whats-included2">
	<div class="container pt-5 pb-5">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">What’s included in Homeowners Insurance?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row pt-2 pr-3" id="a-bullet-list">
			<ul>
				<li>Coverage for damage to your home and other structures, such as a fence, detached garage or shed.
				</li>
				<li>Repairs or replacement of personal belongings due to theft or other covered perils.</li>
				<li>Reimbursement for living accommodations while your home is being repaired if your home is
					uninhabitable because it has been damaged or destroyed from a covered risk.</li>
				<li>Payment for legal representation or medical expenses related to an injury to a guest in your home.
				</li>
			</ul>
		</div>

		<!-- O N L I N E   Q U O T E -->



		<!-- // O N L I N E   Q U O T E -->

	</div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->

<!--  F U L L   C O V E R A G E  D E T A I L S  -->

<section id="see-full-coverage" class="pb-5">
	<div class="container pt-3 pb-5">

		<div class="row">
			<div class="col">
				<h2 class="col text-center">full coverage details</h2>
			</div>
		</div>

		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-md-12">
				<nav>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
						<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
							role="tab" aria-controls="nav-home" aria-selected="true">Dwelling</a>
						<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
							role="tab" aria-controls="nav-profile" aria-selected="false">Other Structures</a>
						<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact"
							role="tab" aria-controls="nav-contact" aria-selected="false">Personal Property</a>
						<a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab"
							aria-controls="nav-about" aria-selected="false">Additional Living Expenses</a>
						<a class="nav-item nav-link" id="nav-personal-lia-tab" data-toggle="tab"
							href="#nav-personal-lia" role="tab" aria-controls="nav-personal-lia"
							aria-selected="false">Personal Liability</a>
						<a class="nav-item nav-link" id="nav-med-pay-tab" data-toggle="tab" href="#nav-med-pay"
							role="tab" aria-controls="nav-med-pay" aria-selected="false">Medical Payments to Others</a>
						<a class="nav-item nav-link" id="nav-deduc-tab" data-toggle="tab" href="#nav-deduc" role="tab"
							aria-controls="nav-deduc" aria-selected="false">Deductibles</a>
					</div>
				</nav>
				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>This part of your homeowner’s policy covers the physical structure or dwelling if it
									is damaged or destroyed by a covered “general peril” which typically includes theft,
									fire, lightning, windstorm, snow, hail, rain, vandalism, malicious mischief and
									damage by a vehicle or aircraft. (Your policy will have a specific list of perils
									that apply to or are excluded from your coverage.)
								</li>
								<li>The recommended amount of coverage should equal the current cost to rebuild your
									home in your area. However, land value should not be included.
								</li>
								<li>Detached structures such as swimming pools, driveways, fences and sheds and not
									included in your dwelling coverage. (See the “Other Structures” section.)</li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>This part of your homeowner’s policy includes the covered structures that are not
									attached to the dwelling if they are damaged or destroyed by a “general peril”
									covered by the dwelling portion of the policy. Detached structures such as swimming
									pools, driveways, fences and sheds and not included in your dwelling coverage.
									(However, Florida does consider a pool to be part of the dwelling.)</li>
								<li>In most states, the recommended total amount of coverage for other structures is 10%
									of the dwelling coverage. (Florida coverage is typically 2%.)</li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>This part of your homeowner’s policy protects your personal belongings such as
									appliances, furniture, electronics, silver and china, books, artwork, clothing, lawn
									and garden equipment, toys and many other items. These items are protected from
									damage or loss from the same “covered perils” as your dwelling.</li>
								<li>Typically, the recommended total personal property coverage amount is 50% to 75% of
									your dwelling coverage.
								</li>
								<li> It’s important that your policy has a “replacement cost” endorsement, so your
									belongings are covered at the full replacement cost and not their current value,
									which may be less.</li>
								<li>Some personal belongings, such as jewelry, will need to be listed separately, or
									“scheduled” on your policy. Some of these items also may have replacement limits.
									However, you can purchase additional “<a class="show-cursor"
										routerLink="/products/jewelry-and-valuables">jewelry
										and valuables</a>”
									insurance for
									these
									items to fully protect them. </li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>This part of your homeowner’s policy covers living expenses if your home is
									uninhabitable because of damage by a covered peril such as a fire or storm. This may
									also include living expenses if you must leave your home because of a
									government-mandated evacuation for a covered peril. </li>
								<li>Reimbursable expenses include alternative housing and increased meal and laundry
									costs. The coverage amount ranges from 10% to 30% of your dwelling coverage or it
									may be a maximum time needed to make your home habitable. </li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-personal-lia" role="tabpanel"
						aria-labelledby="nav-personal-lia-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>This part of your homeowner’s policy covers liability claims against you that are
									the result of injury or negligence that takes place on or off the insured property.
									The maximum amount of coverage for these types of claims is included on your policy.
									In addition to this coverage, many homeowners purchase a personal “<a
										class="show-cursor" routerLink="/products/umbrella">umbrella
										policy</a>”
									to provide extra protection. Umbrella policy premiums can be as low as $150 annually
									for $1 million in coverage. </li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-med-pay" role="tabpanel" aria-labelledby="nav-med-pay-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>This part of your homeowner’s policy covers medical expenses for visitors or guests
									who are injured in an accident on your property. This does not include family
									members who live with you. </li>
								<li>The maximum amount of coverage for these expenses is included in your policy.
									Additional coverage may be available for an additional premium. </li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-deduc" role="tabpanel" aria-labelledby="nav-deduc-tab">
						The deductible is the amount you pay toward a settled claim. When your insurance company writes
						a check for the claim, it is decreased by the deductible amount shown on your policy.
						<br><br>
						Deductibles can be increased, resulting in a premium savings; or lowered, resulting in an
						additional premium charge.
						<br><br>
						Deductibles may be different for certain perils depending on the location of your property. For
						example, in coastal locations where windstorm is a threat, the windstorm deductible may be
						higher than the deductible for other covered perils. This can also apply to hurricane, tornado
						and earthquake perils in some states.
						<br><br>
						Everything you need to know about your deductible will be spelled out in your policy
						declaration. Be sure to read it!
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- // F U L L   C O V E R A G E  D E T A I L S  -->


<!-- O P T I O N A L   P O L I C I E S  -->

<section id="whats-included" class="bg-secondary">
	<div class="container pt-5 pb-5 ">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">Optional Policies</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row text-center justify-content-center pb-4">
			<div class="col-10 pb-2">
				There are additional endorsements or policies available for perils not covered on standard homeowners
				policies. If you have a risk you are unsure about, ask us! Here are some examples where we can help:
			</div>
		</div>
		<div class="row pt-2 pl-0">
			<div class="col-md-6 col-sm-12">
				<ul class="pl-0">
					<li>Earthquake</li>
					<li>Flood</li>
					<li>Personal Articles Floater (PAF)<br> Scheduled Personal Property</li>
					<li>Service Line</li>
					<li>Golf Cart</li>
				</ul>
			</div>
			<div class="col-md-6  col-sm-12">
				<ul class="pl-0">
					<li>Event</li>
					<li>Bicycle</li>
					<li>Pet</li>
					<li>Equipment Breakdown</li>
					
					<li>Umbrella</li>
				</ul>
			</div>
		</div>
	</div>
</section>

<!-- // O P T I O N A L   P O L I C I E S  -->


<!--  F R E Q U E N T L Y  A S K E D   Q U E S T I O N S   -->

<section>
	<div class="container pt-5 pb-5 bottom-border-element">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">Frequently Asked Questions</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
				<br><br>
				<span>Homeowners Insurance</span><br>
			</div>
		</div>
		<div class="row text-center p-2 pb-4">
			Insurance can be confusing. That’s why your Westwood agent and customer service team are so important. If
			you have a question – ask us!
			<br><br>
			Here are some of the most frequently-asked questions from our customers. The answers provide general
			information – each company defines their own specific coverage, terms, limitations and exclusions. Your
			policy includes the specifics.

		</div>

		<div class="row accordion" id="accordionExample">
			<div class="col-md-6 col-xs-12">

				<div class="card">
					<div class="card-header" id="heading5a">
						<h2 class="mb-0">
							<button aria-controls="collapse5a" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse5a" data-toggle="collapse" type="button">
								What is covered on a homeowners policy?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading5a" class="collapse" data-parent="#accordionExample" id="collapse5a">
						<div class="card-body">
							A homeowners policy is a package type of policy, which means that it includes protection
							for several types of possible losses called perils, including damage to your structure,
							your personal property, expenses for loss of the use of your home and liability or
							medical claims that may arise.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading5aa">
						<h2 class="mb-0">
							<button aria-controls="collapse5aa" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse5aa" data-toggle="collapse" type="button">
								What perils does my policy cover?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading5aa" class="collapse" data-parent="#accordionExample" id="collapse5aa">
						<div class="card-body">
							The standard homeowners policy covers fire, lightning, smoke, windstorm, hail, theft,
							vandalism, malicious mischief, explosion, aircraft and vehicle damage, riot and civil
							commotion, falling objects, accidental discharge of water or steam, and freezing of
							plumbing, heating and air conditioning systems.
							<br><br>
							Most policies today are HO3 and "All Risks" which means the structure is covered for
							anything not specifically excluded, and contents are covered for the main perils above.
							An HO5 would provide more coverage, and an HO2 or HO8 would prove less. Some normal
							exclusions are intentional acts, acts of war, animal activity, material and building
							defects, failure to maintain the home, flood, earthquake, and more. It is important to
							read your policy thoroughly and be familiar with what is and what is not covered.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="headingA">
						<h2 class="mb-0">
							<button aria-controls="collapseA" aria-expanded="true" class="btn btn-link"
								data-target="#collapseA" data-toggle="collapse" type="button">
								Why should I choose Westwood as my agent?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingA" class="collapse" data-parent="#accordionExample" id="collapseA">
						<div class="card-body">
							We do business with the top insurance companies in the country. Our highly-trained
							licensed agents will evaluate your situation and find the right policy with the best
							insurance company for you. We provide the personal touch to be sure you get what you
							need while using technology to make it easy and efficient.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="headingOne">
						<h2 class="mb-0">
							<button aria-controls="collapseOne" aria-expanded="true" class="btn btn-link"
								data-target="#collapseOne" data-toggle="collapse" type="button">
								How much should I insure my home for?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingOne" class="collapse" data-parent="#accordionExample" id="collapseOne">
						<div class="card-body">
							Your home should be covered for the estimated replacement cost. This is generally
							determined by the average cost contractors in your area would charge to rebuild your
							specific home. It’s a good idea to review this amount regularly with your Westwood
							agent, to be sure you are keeping up with housing trends in your area. Do not try to
							save money by insuring your home for less than replacement cost &mdash; your Westwood
							agent
							can help you find better ways to save.

						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="headingTwo">
						<h2 class="mb-0">
							<button aria-controls="collapseTwo" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapseTwo" data-toggle="collapse" type="button">
								What are "Separate" or "Other" Structures?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingTwo" class="collapse" data-parent="#accordionExample" id="collapseTwo">
						<div class="card-body">
							The standard homeowner policy provides coverage for "separate" or "other" structures
							which are not attached to the dwelling such as fences, sheds, pool, and detached
							garages. This is generally 10% of the dwelling amount but often can be increased if the
							replacement value for these structures is greater. This coverage cannot be removed from
							the policy except in Florida.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="headingThree">
						<h2 class="mb-0">
							<button aria-controls="collapseThree" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapseThree" data-toggle="collapse" type="button">
								How much contents coverage is adequate?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingThree" class="collapse" data-parent="#accordionExample"
						id="collapseThree">
						<div class="card-body">
							Coverage for your contents (personal property) is provided at a percentage of your
							dwelling amount. This generally ranges between 50% and 75% of your dwelling coverage.
							There are special limitations on certain personal property items such as jewelry, furs,
							watches, guns and silver.
							<br>
							Please check these limitations on your policy and be sure the amount is adequate in case
							of a loss. If not, a “personal articles schedule” can provide broader coverage for
							specified items at an additional premium. Or you could purchase a specialized insurance
							policy that will only cover these valuables. Your Westwood agent can discuss which
							option is best for you. It’s very important that your policy has a “replacement cost”
							endorsement so that you receive the full cost of lost or damaged personal property, not
							just the current value – which could be significantly less. Be sure you are getting this
							endorsement on any quote you are comparing.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="headingComm">
						<h2 class="mb-0">
							<button aria-controls="collapseComm" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapseComm" data-toggle="collapse" type="button">
								I am working from home. Is the space, furniture, tools and technology that I use
								while
								working covered under my homeowners policy if it is damaged in a covered peril?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingComm" class="collapse" data-parent="#accordionExample"
						id="collapseComm">
						<div class="card-body">
							If you are a legal employee of your employer, your employer may provide coverage for
							furniture, tools and technology that you use. Ask your employer about this, and if it is
							not covered, talk to your Westwood agent for more information.
							<br>
							If you are an independent contractor working from home, your homeowners policy probably
							will not cover the space, furniture, tools and technology you are using for your work.
							You may need (or your clients may require) a <a class="show-cursor"
								routerLink="/products/small-business">commercial
								policy</a>.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading4">
						<h2 class="mb-0">
							<button aria-controls="collapse4" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse4" data-toggle="collapse" type="button">
								What is loss of use/additional living expenses?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading4" class="collapse" data-parent="#accordionExample" id="collapse4">
						<div class="card-body">
							This part of your homeowners policy pays for increased costs of temporary living
							arrangements after a covered peril occurs. This includes items such as hotel and food
							expenses beyond your normal expenses for these items.
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header" id="heading5">
						<h2 class="mb-0">
							<button aria-controls="collapse5" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse5" data-toggle="collapse" type="button">
								What does personal liability cover?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading5" class="collapse" data-parent="#accordionExample" id="collapse5">
						<div class="card-body">
							This part of your homeowners policy protects you financially against claims resulting
							from bodily injury or property damage for which you may be legally liable. You are
							covered up to the liability limit stated in your policy for each occurrence. This
							excludes auto and business-related claims, as well as criminal activity.
						</div>
					</div>
				</div>

			</div>

			<div class="col-md-6 col-xs-12">

				<div class="card">
					<div class="card-header" id="heading6">
						<h2 class="mb-0">
							<button aria-controls="collapse6" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse6" data-toggle="collapse" type="button">
								What does medical payments cover?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading6" class="collapse" data-parent="#accordionExample" id="collapse6">
						<div class="card-body">
							This coverage pays for medical expenses of guests or others injured in your premises, up
							to the coverage limit.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading7">
						<h2 class="mb-0">
							<button aria-controls="collapse7" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse7" data-toggle="collapse" type="button">
								What are common optional coverages added to homeowners coverage?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading7" class="collapse" data-parent="#accordionExample" id="collapse7">
						<div class="card-body">
							Several optional coverages can be purchased to meet your insurance needs.
							<bR>
							<br>
							1. Flood Insurance coverage for damages due to floodwaters. <b>Flood damage is not
								covered
								by homeowners insurance.</b> A separate policy can be written to obtain coverage for
							floodwater damage.<br>
							<bR>

							2. Earthquake coverage for earthquake damages that exceed specified earthquake
							deductibles. This may be available as an endorsement on your homeowners policy or as a
							stand-alone policy.<br>
							<br>

							3. Excess Personal Liability coverage beyond the liability limits stated in your
							homeowners and auto policies. This is always recommended if you have a swimming pool,
							rental homes, more than one property and/or more than two cars.
							<br>
							<br>

							4. Personal Articles Schedule coverage above standard limitations for certain items
							(jewelry, furs, fine arts, silver, etc.). This may be available as an endorsement on
							your homeowners policy or as a stand-alone policy.
							<br>
							<br>

							5. Building Code Upgrade covers the extra cost required to bring your house "up to code"
							in the event of a covered loss (i.e., replacement of a wood shingle roof with a
							composition roof). Most policies include 10% at no additional charge but have options to
							increase to 25% or 50%.
							<br>
							<br>

							6. Loss Assessment Coverage pays for your share of the loss charged by a corporation or
							association of property owners (i.e., HOA). For example, a special assessment by your
							HOA for storm cleanup of common areas and streets. Most policies include $1,000 of
							coverage, but additional amounts can be added.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading8">
						<h2 class="mb-0">
							<button aria-controls="collapse8" aria-expanded="true" class="btn btn-link"
								data-target="#collapse8" data-toggle="collapse" type="button">
								How is the deductible applied?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading8" class="collapse" data-parent="#accordionExample" id="collapse8">
						<div class="card-body">
							The deductible is the co-insurance amount you pay toward a settled claim. The company
							will adjust and pay each claim for the full approved amount minus the deductible.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading9">
						<h2 class="mb-0">
							<button aria-controls="collapse9" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse9" data-toggle="collapse" type="button">
								How can I lower my premium?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading9" class="collapse" data-parent="#accordionExample" id="collapse9">
						<div class="card-body">
							Several things can help reduce your insurance premiums, including home alarm discounts,
							retired person discounts, optional coverages and having your automobile and homeowner
							insurance (and other policies!) with us. <b>You may be eligible for a multiple policy
								discount if you have both homeowners and auto insurance with Westwood, even if both
								policies are not with the same insurance company.</b> Please call us to discuss the
							best
							options for you.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading10">
						<h2 class="mb-0">
							<button aria-controls="collapse10" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse10" data-toggle="collapse" type="button">
								Can I keep my policy after I pay off my mortgage loan?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading10" class="collapse" data-parent="#accordionExample" id="collapse10">
						<div class="card-body">
							Yes. Please notify us immediately when you pay off your loan, so we can delete the
							lender from your policy.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading11">
						<h2 class="mb-0">
							<button aria-controls="collapse11" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse11" data-toggle="collapse" type="button">
								What should I do if my mortgage company changes?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading11" class="collapse" data-parent="#accordionExample" id="collapse11">
						<div class="card-body">
							Call our customer service team and we will change your policy to reflect the new
							mortgage company. This is especially important if your lender pays for your insurance
							policy through an escrow or impound account. A common reason policies cancel is due to
							non-payment because the renewal invoice was sent to the incorrect lender.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading12">
						<h2 class="mb-0">
							<button aria-controls="collapse12" aria-expanded="false"
								class="btn btn-link text-left collapsed" data-target="#collapse12"
								data-toggle="collapse" type="button">
								Is my personal property covered while I am moving from one home to another?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading12" class="collapse" data-parent="#accordionExample" id="collapse12">
						<div class="card-body">
							Your current homeowners policy extends coverage for 30 days after you leave your covered
							home. Personal property may be subject to limitations; please review your policy in
							advance of your move. Even when you’re not moving, your policy may provide personal
							property coverage away from your home – usually up to 10% of your personal contents
							coverage.
						</div>
					</div>
				</div>

				<div class="card">
					<div class="card-header" id="heading13">
						<h2 class="mb-0">
							<button aria-controls="collapse13" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse13" data-toggle="collapse" type="button">
								Can I transfer my current insurance policy to another property?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading13" class="collapse" data-parent="#accordionExample" id="collapse13">
						<div class="card-body">
							No. Your policy is not transferable. Each policy is issued to cover a specific property
							address which is shown on the "Declaration" page. When this property address changes, a
							new policy must be issued. We are more than happy to assist you with insuring any new
							property.
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>

<!-- //  F A Q   -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center [categoryID]="categoryID"></app-knowledge-center>
</section>
<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->