<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-umbrella" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Umbrella Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
    <div class="container pt-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-md-9 text-center col-xs-9">
                <!-- <p><span>Think of this coverage as, well, an umbrella.</span><br> It follows you around, broadly
                    protecting you from many unintentional mishaps. Your umbrella policy provides financial protection
                    if you accidentally injure someone or their property. It also helps with costs associated with a
                    liability lawsuit.
                    <br><br>
                    The more assets you have to protect, or the more likely there could be an accident on your property
                    or involving something you own, the more you need this coverage. If you have a teenage driver, a
                    swimming pool, multiple properties, or rental homes, give this coverage serious consideration! An
                    umbrella policy protects you beyond the coverage limits of your home and auto policies and can
                    protect you from life-ruining financial losses.
                </p>
                <br> -->
                <img src="assets/img/icons/cell-phone.png" width="30" alt="cellphone icon"><span> Call us for a quote or
                    questions regarding Umbrella Insurance at <a href="tel:18885436179">888.543.6179</a> </span>
            </div>
        </div>
    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  U M B R E L L A    -->

<section>
    <div class="container pt-3 pb-5" id="a-bullet-list">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center">Why Umbrella Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-xs-6">
                <img src="assets/img/page-body-img/shutterstock_379852876.jpg" style="width: 100%;"
                    alt="Young man reading legal paperwork" />
            </div>
            <div class="col-md-6 col-xs-6">
                Americans are sued for both legitimate and bogus reasons—and the outcome is never guaranteed. All your
                assets, including your future earnings, can be at risk.<br><br>
                <span>What's included in umbrella insurance?</span><br>
                <ul id="a-bullet-list">
                    <li>Legal representation and court fees and other expenses associated with a lawsuit brought against
                        you for libel, slander, or other liability claims.</li>
                    <li>Liabilities not covered under your homeowners or auto policy.</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<!-- //  W H Y  U M B R E L L A    -->


<!-- S C E N A R I O S  -->

<section class="bg-secondary pb-5" id="whats-included2">
    <div class="container pt-5 ">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">COMMON UMBRELLA INSURANCE CLAIMS</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-4">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" /><br><br>
                Nobody likes to think these things could happen to them. But they happen to someone every day. If you
                have umbrella insurance, you have financial protection… just in case today’s your day:
            </div>
        </div>

        <div class="row justify-content-center pt-2 pr-3">
            <ul>
                <li><span class="text-uppercase">Texting and driving</span><br>
                    This is a leading cause of auto accidents. Even the best driver can get distracted and cause an
                    accident.</li>
                <li><span class="text-uppercase">Guests injury</span><br>
                    A homeowner hosts a party at home and a guest is injured on the premises.</li>
                <li><span class="text-uppercase">Dog bites</span><br>
                    Dog bites account for a large percentage of homeowner insurance liability claims. If you have a pet,
                    it is important to know what your home policy does and does not cover.</li>
                <li><span class="text-uppercase">Accidents on your propery</span><br>
                    Friends come over to swim in your pool and a guest accidentally drowns. The tenant of your rental
                    home falls down a flight of stairs and is permanently disabled due to a loose handrail.</li>
            </ul>
        </div>
    </div>
</section>

<!-- // S C E N A R I O S -->


<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->