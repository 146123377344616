<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-small-commercial" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-9 col-sm-9 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Small Business Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none d-none">
    <div class="container intro-para-homeowners text-center pt-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-md-9 col-xs-9 ">
                <p><span>When you own a small business, it’s important to protect<br> your investment and reputation.
                    </span><br> With so many kinds of small businesses – your company’s insurance needs won’t be
                    well-served
                    with a one-size-fits-all approach. At Westwood, we work with commercial insurance companies
                    specializing in small business. This allows us to provide not only business property insurance and
                    general liability, but many optional endorsements and package options tailored to your needs. </p>
                <br>
                <span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
                    questions regarding Small Business Insurance at<a href="tel:18884226813"> 888.543.6179</a></span>
            </div>
        </div>
    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  C O N D O  I N S U R A N C E   -->

<section>
    <div class="container pt-3 pb-5">
        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center"> WHY SMALL Business INSURANCE? </h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line divider" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <img src="assets/img/page-body-img/iStock-1171641618Florist.png" style="width: 100%;"
                    alt="Flower shop worker" />
            </div>
            <div class="col pt-mob">
                A property or liability loss can really affect a small business’s cash flow. Having proper insurance can
                help you sustain your business operations through a loss.
                <br><br>
                Traditionally, small businesses operated out of dedicated buildings. However, today many small
                businesses are based in the business owner’s home. Homeowners insurance will limit or exclude coverage
                for the parts of your home and property used for business. And depending on what you do, you may also be
                required to have business insurance.

            </div>
            
        </div>
       
    </div>
</section>

<!-- // W H Y  C O N D O   I N S U R A N C E   -->

<!-- W H A T 'S   I N C L U D E D ... -->

<section class="bg-secondary" id="whats-included2">
    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col">
                <h2 class="col text-center">What’s included in Small Business Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-3">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
        <!-- id="a-bullet-list" -->
        <div class="row pt-2 pr-3 justify-content-center pb-2 text-center">
            <p>Westwood partners with some of the largest global insurers to provide tailored coverages and
                industry-specific
                solutions. The following coverages are a sample of what’s available, but may not apply in every
                situation. </p>
            <ul>
                <li>Business Owner’s Policy (BOP) </li>
                <li>Business Property Insurance </li>
                <li>General Liability Insurance</li>
                <li>Workers Compensation</li>
                <li>Inland Marine </li>
                <li>Umbrella</li>
            </ul>
        </div>
        <!-- <section id="online-quote">
            <div class="container online-quote mt-1">
                <div class="row">
                    <div class="col text-center">
                        <a class="show-cursor a-text-decoration-none"
                            href="https://www.wh-app.io/westwood/westwood-home-page" target="_blank">
                            <div class="angled-button-sm button-align-mob-center button-width-240">
                                <span>CLICK HERE FOR QUOTE</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section> -->
        <div class="row justify-content-center pt-3">
            <span class="text-center"><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
                questions regarding Small Business Insurance at<a href="tel:18884226813"> 888.422.6813 </a></span>
    </div>
    </div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center [categoryID]="categoryID"></app-knowledge-center>
</section>
<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->