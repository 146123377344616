import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Lead } from '../model/lead';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';
import { UnSubscribe } from '../model/unSubscribe';

@Injectable({
  providedIn: 'root'
})
export class UnSubscribeService { 
  
  url: string;
  constructor(private dataService: DataService) { }
  /*Create/Update Lead  Details by Lead Object */
  UnsubscribeUser(data: UnSubscribe): Observable<UnSubscribe> {
    this.url = Controller.Texting + '/' + Action.GetUnsubscribeStatus;
    return this.dataService.postData(data, this.url);
  }
}
