<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-gaq" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Get a quote</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!-- H O M E  B U C K E T S -->

<section id="homeBuckets">
  <div class="container pb-5 pt-3" >

    <div class="row mt-3">
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-home.png" alt="house icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Home</p>
          <a class="stretched-link" routerLink="/real-time-quote/cust-info"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/condo.png" alt="condos icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Condo</p>
          <a class="stretched-link" routerLink="/real-time-quote/cust-info"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-renters.png" alt="key icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Renters </p>
          <a class="stretched-link" routerLink="/real-time-quote/cust-info"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-auto.png" alt="car icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Auto</p>
          <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx')"
             href="#"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-flood.png" alt="house flooding icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Flood</p>
          <a class="stretched-link" routerLink="/products/flood"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-earthquake.png" alt="house on cracked ground icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Earthquake </p>
          <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://www.arrowheadexchange.com/ao/ao.jsp?control_prodcode=165039&control_prodkey=a8BJLqwqcYhTorlmAWVN36W1nc0%3D&policy_product=erqk&site=ao')"
             href=" #"></a>
        </div>
      </div>

      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-umbrella.png" alt="umbrella over car and house icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Umbrella </p>
          <a class="stretched-link" routerLink="/products/umbrella"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/small-commercial-tile.png" alt="ring and painting icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Small Business</p>
          <!--<a class="stretched-link" routerLink="/products/small-business"></a>-->
          <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://www.wh-app.io/westwood/westwood-home-page')"
             href=" #"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-jewely-fine-art.png" alt="ring and painting icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Jewelry & Valuables</p>
          <a class="stretched-link" routerLink="/products/jewelry-and-valuables"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid-home-service" src="assets/img/icons/Home-warranty-icon-lrg-new.png" alt="home warranty icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Home Warranty</p>
          <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://www.2-10.com/get-a-quote-westwoodcx')"
             href=" #"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-golf-cart.png" alt="golf cart icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Golf Cart</p>
          <a class="stretched-link" routerLink="/products/golf-cart"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-motorcycle.png" alt="Motorcycle icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Motorcycle</p>
          <a class="stretched-link" routerLink="/products/motorcycle"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-pet.png" alt="dog icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Pet</p>
          <!-- commented as part of WSTW-5680 -->
          <!-- <a class="stretched-link"
        (click)="child.importantNotice($event, 'https://quote.embracepetinsurance.com/pet-information?brand=westwood')"
        href="#"></a> -->
          <!-- Added below link as part of WSTW-5680 -->
          <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://quote.embracepetinsurance.com/pet-information?orgcode=80171849&brand=westwood&utm_source=westwood&utm_medium=partner&utm_campaign=website')"
             href="#"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-travel.png" alt="Plane icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Travel </p>
          <a class="stretched-link" routerLink="/travel-insurance/travel-online-quote"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-event.png" alt="Wine glasses icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Event </p>
          <a class="stretched-link"
             (click)="child.importantNotice($event,'https://www.markelinsurance.com/event/westwood')" href="#"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-bicycle.png" alt="Bike Icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Bicycle </p>
          <a class="stretched-link"
             (click)="child.importantNotice($event, 'https://www.markelinsurance.com/bicycle/westwood')" href="#"></a>
        </div>
      </div>

      <div class="col-md-5ths col-12 homeProductBucketCon" *ngIf="false">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/phone-icon-new.PNG" alt="phone icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Phone</p>
          <a class="stretched-link" routerLink="/products/mobile-phone-insurance"></a>
        </div>
      </div>
    </div>

  </div>
</section>

<!-- //  H O M E B U C K E T S -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->