import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants';
import { ClaimPageInfo } from 'src/app/model/claimPageInfo';
import { DataService } from 'src/app/service/data.service';
import { ReportAclaimService } from '../../service/report-aclaim.service';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(wpBaseUrl: string): any;

@Component({
  selector: 'app-report-aclaim',
  templateUrl: './report-aclaim.component.html',
  styleUrls: ['./report-aclaim.component.css']
})
export class ReportAClaimComponent implements OnInit, AfterViewInit {
  public wpBaseUrl: string;
  const: Constants;
  ClaimPageInfos: Array<ClaimPageInfo> = [];


  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'ReportaClaim.jpg',
    desktopHeaderText: 'Reporting a Claim is Easy',
    desktopHeaderSubText: "Simply select your insurance company below and click on the link to go directly to their claims page.",
    mobileHeaderText: 'Report a Claim',
    mobileHeaderSubText: ''
  });

  constructor(
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService,
    public reportAclaimService: ReportAclaimService,
  ) {
    this.const = constantObj;
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }
  isLoading = false;
  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
    this.getClaimValues();
  }

  getClaimValues() {
    this.isLoading = true;
    this.reportAclaimService.getClaimInfo().subscribe((data: Array<ClaimPageInfo>) => {
      this.ClaimPageInfos = data;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
    });
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    breadcrumbShow(this.wpBaseUrl);
  }

}

