<!-- B R E A D C R U M B  -->

<section class="container pt-3" *ngIf="false">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
    <div class="container text-center pt-3 pb-5">
        <div class="row justify-content-center">
            <div class="col-md-9 col-xs-9">
                <span>Westwood has partnered with Allianz Travel, a world leader in the travel insurance industry. Their
                    large scale and strong financial standing
                    allows them to provide innovative products with worldwide coverage at very competitive rates.</span>
            </div>
        </div>
    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->

<!-- CALL IFRAME -->
<iframe class="iframe-format pl-5 pr-5" scrolling="no" [src]="this.iframeUrl"></iframe>
<!-- // CALL IFRAME -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->