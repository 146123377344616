import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants'; 
import { DataService } from 'src/app/service/data.service';
import { InsuranceLicensingService } from '../../service/insurance-licensing.service';
import { stateLicenseNumber } from '../../model/stateLicenseNumber';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-insurance-licensing',
  templateUrl: './insurance-licensing.component.html',
  styleUrls: ['./insurance-licensing.component.css']
})
export class InsuranceLicensingComponent implements OnInit,AfterViewInit { 
  
  const: Constants;  
  InsurnaceDeatils:Array <stateLicenseNumber>=[];
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'insurance-licensing.5b994f994a1082ffe155.jpg',
    desktopHeaderText: 'Insurance Licensing',
    desktopHeaderSubText: "We are licensed in all 50 states, so no matter where you go, we are here to serve you.",
    mobileHeaderText: 'Insurance Licensing',
    mobileHeaderSubText: ''
  });

  constructor(  
    public commonService: CommonService, 
    public constantObj: Constants,
    public dataService: DataService,
    public insuranceLicensingService: InsuranceLicensingService,  
    ) {
    this.const=constantObj;
  }

  ngOnInit() { 
       
    this.getInsuranceLicensingValues(); 
  }
  
  
  getInsuranceLicensingValues() {
   
    this.insuranceLicensingService.getInsuranceLicensingInfo().subscribe((data:Array<stateLicenseNumber>)=> { 
      this.InsurnaceDeatils = data;  
    },err => {
      this.dataService.handleError(err);
    });
  }

  ngOnDestroy() {
    
  }
  ngAfterViewInit()
  {
    breadcrumbShow();
  }
  

}
