import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-motorcycle',
  templateUrl: './motorcycle.component.html',
  styleUrls: ['./motorcycle.component.css']
})
export class MotorcycleComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/moto-header.jpg',
    desktopHeaderText: 'Motorcycle Insurance',
    desktopHeaderSubText: "We make protecting your ride quick and easy. Your Westwood agent will compare coverage and rates from the nation's top insurance companies to find the best option for you.",
    mobileHeaderText: 'Motorcycle Insurance',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    breadcrumbShow();
  }

}
