import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
declare var $;

@Component({
  selector: 'app-pop-up-video-container',
  templateUrl: './pop-up-video-container.component.html',
  styleUrls: ['./pop-up-video-container.component.css']
})
export class PopUpVideoContainerComponent implements OnInit {

  constructor() { 
    if($('.modal-body iframe').is(":hidden")){
      this.closeModalBox();
    }
  }

  ngOnInit() {
    if($('.modal-body iframe').is(":hidden")){
      this.closeModalBox();
    }
    
  }

  //To show function
  importantNotice(event, link){
    event.preventDefault();
      jQuery('#videoModal').modal();
  }

  closeModalBox(){
    $('.modal-body iframe').attr("src", '');

    $('.modal-body iframe').attr("src", 'https://www.youtube.com/embed/Hob9CSqur80?enablejsapi=1');
    //src="https://www.youtube.com/embed/Hob9CSqur80?enablejsapi=1" 
  }

  


}
