<!-- H O M E   H E R O   -->
<section id="homeHero" class="heroPadding">
	<div id="homeHeroBG" class="showSlider focal-point right-3 up-3"></div>
	<div id="homeHeroBG2" class="showSlider focal-point right-3 up-3"></div>
	<div class="container">
		<div class="row">
			<div class="col-lg-7 hero-para">
				<h1 class=" pb-2">helping protect what’s important <span style="text-transform: lowercase;">to</span>
					you</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
				<p class="pb-2 pt-2">
					Westwood works with dozens of the world's best insurance companies to offer you a personalized
					insurance portfolio tailored to your individual needs.
				</p>
			</div>
		</div>

		<div id="homeHeroSelectCon" class="row pl-3">
			<div class="col-lg-3 col-md-8 col-sm-6 px-0  d-md-flex align-items-stretch bg-white">
				<select id="homeHeroSelect">
					<option value="0" data-imagesrc="assets/img/icons/icon-home.png"
									data-description="/real-time-quote/cust-info" data-ExternalLink="false">
						Home
					</option>
					<option value="1" data-imagesrc="assets/img/icons/condo.png"
									data-description="/real-time-quote/cust-info" data-ExternalLink="false">
						Condo
					</option>
					<option value="2" data-imagesrc="assets/img/icons/icon-renters.png"
									data-description="/real-time-quote/cust-info" data-ExternalLink="false">
						Renters
					</option>
					<option value="99" data-imagesrc="assets/img/icons/auto-dropdown-icon.png"
									data-description="https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx"
									data-ExternalLink="true">
						Auto
					</option>
					<option value="4" data-imagesrc="assets/img/icons/icon-flood.png" data-description="/products/flood"
									data-ExternalLink="false">
						Flood
					</option>
					<option value="5" data-imagesrc="assets/img/icons/icon-earthquake.png"
									data-description="https://www.arrowheadexchange.com/ao/ao.jsp?control_prodcode=165039&control_prodkey=a8BJLqwqcYhTorlmAWVN36W1nc0%3D&policy_product=erqk&site=ao"
									data-ExternalLink="true">
						Earthquake
					</option>
					<option value="7" data-imagesrc="assets/img/icons/icon-umbrella.png"
									data-description="/products/umbrella" data-ExternalLink="false">
						Umbrella
					</option>
					<option value="16" data-imagesrc="assets/img/icons/small-commercial.png"
									data-description="https://www.wh-app.io/westwood/westwood-home-page" data-ExternalLink="true">
						Small Business
					</option>
					<option value="8" data-imagesrc="assets/img/icons/icon-jewely-fine-art.png"
									data-description="/products/jewelry-and-valuables" data-ExternalLink="false">
						Jewelry & Valuables
					</option>
					<option value="9" data-imagesrc="assets/img/icons/Home-warranty-icon-lrg-new.png"
									data-description="https://www.2-10.com/get-a-quote-westwoodcx"
									data-ExternalLink="true">
						Home Warranty
					</option>
					<option value="10" data-imagesrc="assets/img/icons/icon-golf-cart.png"
									data-description="/products/golf-cart" data-ExternalLink="false">
						Golf Cart
					</option>
					<option value="11" data-imagesrc="assets/img/icons/icon-motorcycle.png"
									data-description="/products/motorcycle" data-ExternalLink="false">
						Motorcycle
					</option>
					<!-- commented as part of WSTW-5680 -->
					<!-- <option value="11" data-imagesrc="assets/img/icons/icon-pet.png"
		data-description="https://quote.embracepetinsurance.com/pet-information?brand=westwood"
		data-ExternalLink="true">Pet </option> -->
					<!-- Added as part of WSTW-5680 -->
					<option value="12" data-imagesrc="assets/img/icons/icon-pet.png"
									data-description="https://quote.embracepetinsurance.com/pet-information?orgcode=80171849&brand=westwood&utm_source=westwood&utm_medium=partner&utm_campaign=website"
									data-ExternalLink="true">
						Pet
					</option>
					<option value="13" data-imagesrc="assets/img/icons/icon-travel.png"
									data-description="/travel-insurance/travel-online-quote" data-ExternalLink="false">
						Travel
					</option>
					<option value="14" data-imagesrc="assets/img/icons/icon-event.png"
									data-description="https://www.markelinsurance.com/event/westwood" data-ExternalLink="true">
						Event
					</option>
					<option value="15" data-imagesrc="assets/img/icons/icon-bicycle.png"
									data-description="https://www.markelinsurance.com/bicycle/westwood" data-ExternalLink="true">
						Bicycle
					</option>

					<option value="17" data-imagesrc="assets/img/icons/phone-icon-new.PNG"
									data-description="/products/mobile-phone-insurance" data-ExternalLink="true" *ngIf="false">
						Phone
					</option>
				</select>
			</div>
			<div id="pro-gaq"
				class="col-lg-3 col-md-4 col-sm-6 text-right pr-4 d-flex align-items-stretch align-self-center">
				<a class="text-uppercase d-block text-center">Get A Quote</a>
			</div>
		</div>
	</div>
</section>

<!-- // H O M E   H E R O  -->