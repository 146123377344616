import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { CsrfToken } from '../model/csrfToken';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class CSRFTokenService { 
  url: string;
  constructor(private dataService: DataService) { }   
  /*Get CSRF token  */
  getCsrfToken(): Observable<Array<CsrfToken>> {
    this.url = Controller.Utility + '/' + Action.SetCSRFToken; 
    return this.dataService.postData({}, this.url); 
  }
}
