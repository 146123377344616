import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/service/data.service";

@Component({
  selector: "app-get-aquote-small-banner",
  templateUrl: "./get-aquote-small-banner.component.html",
  styleUrls: ["./get-aquote-small-banner.component.css"],
})
export class GetAQuoteSmallBannerComponent implements OnInit {
  public wpBaseUrl: string;
  public smallBusinessUrl: string;
  public floodUrl: string;
  public umbrellaUrl: string;
  public jewelryUrl: string;
  public golfcartUrl: string;
  public motorcycleUrl: string;
  public recreationalVehiclesUrl:string;

  constructor(public dataService: DataService) {
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
    this.smallBusinessUrl = this.wpBaseUrl + "products/small-business-insurance";
    this.floodUrl = this.wpBaseUrl + "products/flood-insurance";
    this.umbrellaUrl = this.wpBaseUrl + "products/umbrella-insurance";
    this.jewelryUrl = this.wpBaseUrl + "products/jewelry-and-valuables-insurance";
    this.golfcartUrl = this.wpBaseUrl + "products/golf-cart-insurance";
    this.motorcycleUrl = this.wpBaseUrl + "products/motorcycle-insurance";
    this.recreationalVehiclesUrl = this.wpBaseUrl + "products/recreational-vehicle-insurance";

  }

  ngOnInit() {}
}
