import { Component, OnInit, AfterViewChecked, NgZone, Pipe } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { Constants } from 'src/app/Utilities/Constants/constants';
import { DataService } from 'src/app/service/data.service';
//import { debug } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Controller } from 'src/app/Utilities/api/controller.config';
import { Action } from 'src/app/Utilities/api/action.config';
import { QuoteResultService } from 'src/app/service/quote-result.service';
declare var $: any;

@Component({
  selector: 'app-rcv-details',
  templateUrl: './rcv-details.component.html',
  styleUrls: ['./rcv-details.component.css']
})
export class RcvDetailsComponent implements OnInit {

  URL: string; 
  isLoading: boolean;
  domSanitizer: any;
  LeadID: string;
  constructor(private router: Router,
    public constantObj: Constants,
    public dataService: DataService,
    private sanitizer: DomSanitizer,
    public quoteService: QuoteResultService,
    private zone: NgZone,
    public leadDetails: Lead, private activatedRoute: ActivatedRoute,
    public commonService: CommonService) { this.isLoading = true; }

  ngOnInit() {
    this.isLoading = true;
    this.activatedRoute.queryParams.subscribe(params => {
      this.leadDetails = new Lead();
      this.leadDetails.leadID = params['leadid'];
      this.leadDetails.quoteResult.rcvHtml='';
      this.getRCVHtml();
    });
  }

  ngAfterViewChecked() {
    if(!this.commonService.isSafariBroswer())
    $('.grecaptcha-badge').remove();
    else
     $('.grecaptcha-badge').css('display', 'block');
    //Customize the RCV css
    $('#dvRCVhtml span').css('color', 'black');
    $('#dvRCVhtml p.MsoNormal').css('color', 'black');
    $('#dvRCVhtml span').css('font-weight', '100');
    $('#dvRCVhtml p.MsoNormal').css('font-weight', '100');
    // $('#dvRCVhtml span').css('color', 'black');
    // $('#dvRCVhtml p.MsoNormal').css('color', 'black');
    // $('#dvRCVhtml span').css('color', 'black');
    // $('#dvRCVhtml p.MsoNormal').css('color', 'black');
  }
  getRCVHtml() {
    this.quoteService.GetRCVHtml(this.leadDetails).subscribe((data: Lead) => {
      this.leadDetails.quoteResult = data.quoteResult;
      this.dataService.leadDetails=data;
      this.dataService.leadDetails.quoteResult.quoteMessage='';
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      this.leadDetails.quoteResult.quoteMessage = 'failed';
    });

  }
 


}
