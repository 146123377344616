import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/service/data.service";

@Component({
  selector: "app-rating-failed",
  templateUrl: "./rating-failed.component.html",
  styleUrls: ["./rating-failed.component.css"],
})
export class RatingFailedComponent implements OnInit {
  constructor(public dataService: DataService) {}

  ngOnInit(): void {}
}
