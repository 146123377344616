import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-jewelry-and-valuables',
  templateUrl: './jewelry-and-valuables.component.html',
  styleUrls: ['./jewelry-and-valuables.component.css']
})
export class JewelryAndValuablesComponent implements OnInit,AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'jewl-header.1910b3ec07b80cdb6d4b.jpg',
    desktopHeaderText: 'Jewelry and Valuables Insurance',
    desktopHeaderSubText: "Most homeowners policies limit coverage on certain valuable items. Get extra protection for jewelry, electronics, fine art, musical equipment, collectibles and more.",
    mobileHeaderText: 'Jewelry and Valuables Insurance',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit()
  {
    breadcrumbShow();
  }

}
