import { Component, OnInit } from '@angular/core';
import { NewsArticlesService } from '../../service/news-articles.service';
import { Constants } from '../../Utilities/Constants/constants';
import { CommonService } from '../../service/common.service';
import { DataService } from '../../service/data.service';
import { ArticlesMainView } from '../../model/article';
import { Router, NavigationStart, ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StateService, State } from '../../service/state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare var  $;
@Component({
  selector: 'app-news-articles',
  templateUrl: './news-articles.component.html',
  styleUrls: ['./news-articles.component.css']
})
export class NewsArticlesComponent implements OnInit {
  scrollArticle: boolean = false;
  const: Constants;
  data: ArticlesMainView;
  PageNumber: number;
  PrevLabel:string="Previous";
  NextLabel:string="Next";
  PageSize: number;
  Month: number;
  Year: number;
  public CategoryID: number;
  public SearchTerm: string;
  isSearchDataLoaded: boolean = false;
  isLoading: boolean = false;
  content: string = 'Dummy Angular highlight text';
  pageTitle: string = '';
  CategoryId$: Observable<number>;
  state$: Observable<State>;
  constructor(
    private titleService: Title,
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService,
    public articleService: NewsArticlesService,
    public router: Router,
    private route: ActivatedRoute,
    public stateService: StateService
  ) {
    //this.CategoryId$ = this.stateService.state.pipe(map(state => state.CategoryID));
    this.state$ = this.stateService.state;
   
    this.titleService.setTitle("News");
    this.PageSize = 6;
    this.PageNumber = 1;
    if (!this.SearchTerm) {
      this.state$.subscribe(params => {
        if (params['CategoryID'] !== 0 && params['CategoryID'] !== undefined && params['CategoryID'] !== null) {
          this.CategoryID = params['CategoryID'];
          this.getArticlesOnLoad();
        } else if (params['SearchTerm'] !== '' && params['SearchTerm'] !== undefined && params['SearchTerm'] !== null) {
          this.SearchTerm = params['SearchTerm'];
          this.getArticlesOnSearch();
        } else if ((params['Month'] !== 0 && params['Month'] !== undefined && params['Month'] !== null) && (params['Year'] !== 0 && params['Year'] !== undefined && params['Year'] !== null)) {

          this.Month = params['Month'];
          this.Year = params['Year'];
          this.getArticlesOnLoad();
        } else {
          this.getArticlesOnLoad();
        }
        
      });
    }
    
  }

  ngOnInit() {

    
  }
  paginationPageChanged(event){
    this.PageNumber = event;
    this.navigateToPageTop();
    //window.scrollTo(0,0);
  //   $([document.documentElement, document.body]).animate({
  //     scrollTop: $(".news-heading").offset().top
  // }, 1000);
    //window.location.hash = '#top';
  }
  reloadNews(){
    this.titleService.setTitle("News");
    this.PageSize = 6;
    this.PageNumber = 1;
    this.CategoryID = 0;
    this.SearchTerm = '';
    this.Month = 0;
    this.Year = 0;
    this.getArticlesOnLoad();

  }
  getArticlesOnLoad() {
    this.isLoading = true;
    let params = '';
    params = this.formatParameter();
    this.articleService.getArticlesOnLoad(params).subscribe((data: ArticlesMainView) => {
      this.data = data;
      //set page title for category
      if (this.CategoryID && this.CategoryID > 0) {
        this.data.articlesCategory.forEach(element => {
          if (element.categoryID === this.CategoryID.toString()) {
            this.pageTitle = element.categoryName;
          }
        });
      } else {
        if ((this.Month && this.Month) > 0 && (this.Year && this.Month > 0)) {
          const date = new Date(this.Year, this.Month - 1, 1);
          const month = date.toLocaleString('default', { month: 'long' });
          this.pageTitle = month + ' ' + this.Year;
        }
      }
      if (((this.CategoryID && this.CategoryID > 0) && ((this.Month && this.Month) > 0 && (this.Year && this.Month > 0) && this.SearchTerm !== ''))) {
        this.titleService.setTitle('News');
      } else {
        //set title
        if (this.pageTitle !== '' && this.pageTitle !== null) {
          this.titleService.setTitle(this.pageTitle + ' | News');
        }
      }

      this.isLoading = false;
      if(this.scrollArticle){
        this.navigateToPageTop();
        //this.commonService.ScrollToArticleDetails();//commeneted for WSTW-5641
      }else{
        this.navigateToPageTop();
        //commeneted below for WSTW-5641
        //this.commonService.ScrollToArticleDetailsHeader();
      }
    }, err => {
      this.dataService.handleError(err);
    });
  }
  //PageNumber=1&PageSize=10&Month=03&year=2021
  formatParameter(): string {
    let params = '';
    if (this.PageNumber && this.PageNumber > 0)
      params = "PageNumber=" + this.PageNumber;
    if (this.PageSize && this.PageSize > 0)
      {params = params + "&" + "PageSize=" + this.PageSize;}
    
    params=params + "&IsfromNewPage=1";

    if (this.Month && this.Month > 0)
      params = params + "&" + "Month=" + this.Month;
    if (this.Year && this.Month > 0)
      params = params + "&" + "Year=" + this.Year;
    if (this.CategoryID && this.CategoryID > 0)
      params = params + "&" + "CategoryID=" + this.CategoryID;
    
    return params;
  }

  navigateToPageTop(){
    window.scrollTo(0,0);
  }
  formatParameterForSearch(): string {
    let params = '';
    if (this.PageNumber && this.PageNumber > 0)
      params = "PageNumber=" + this.PageNumber;
    if (this.PageSize && this.PageSize > 0)
      params = params + "&" + "PageSize=" + this.PageSize;
    if (this.SearchTerm)
      params = params + "&" + "SearchTerm=" + this.SearchTerm;
    return params;
  }
  getArticlesOnSearch() {
    this.CategoryID = null;
    this.Month = null;
    this.Year = null;
    this.isLoading = true;
    this.isSearchDataLoaded = false;
    let params = '';
    params = this.formatParameterForSearch();
    this.articleService.getArticlesOnSearch(params).subscribe((data: ArticlesMainView) => {
      this.data = data;
      this.pageTitle = 'You searched for ' + this.SearchTerm;
      if (this.SearchTerm == '') {
        this.titleService.setTitle('News');
      } else {
        this.titleService.setTitle(this.pageTitle);
      }
      this.isSearchDataLoaded = true;
      this.isLoading = false;
      if(this.scrollArticle){
        this.commonService.ScrollToArticleDetails();
      }
    }, err => {
      this.dataService.handleError(err);
    });
  }
  refreshArticleGrid() {
    if (this.SearchTerm)
      this.getArticlesOnSearch();
    else {
      this.getArticlesOnLoad(); this.isSearchDataLoaded = false;
    }
  }
  SearchTermChanged() {
    if (this.SearchTerm == '') {
      this.myMethodChangingQueryParams();
      this.titleService.setTitle('News');
      this.getArticlesOnLoad(); this.isSearchDataLoaded = false; this.isSearchDataLoaded = false;
    }
  }

  myMethodChangingQueryParams(name: string = '') {
    const queryParams: Params = {};
    this.router.navigate(
      [],
      {
        relativeTo: this.route, 
        queryParams: queryParams
        // remove to replace all query params by provided
      });
  }
}

