export class Source {
    public name: string;
    public id: string;
    public phoneNumber: string;
    public code: string;
    public typeId:string;
    constructor() {
        this.name = '';
        this.id = '';
        this.phoneNumber = '';
        this.code='';
        this.typeId='';
    }
}