import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CurrentCustomer } from '../model/currentCustomer';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class LenderRequestChangeService {

  url: string;
  constructor(private dataService: DataService) { }
  /*Create request policy change  Details by Lead Object */
  SaveLenderRequestChange(data: CurrentCustomer): Observable<CurrentCustomer> {
    this.url = Controller.CurrentCustomer + '/' + Action.LenderRequestChange;
    return this.dataService.postData(data, this.url);
  }
}
