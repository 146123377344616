import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LenderChanges } from '../model/lenderChanges';
import { States } from '../model/states';
import { UploadFile } from '../model/uploadFiles';
import { Action } from '../Utilities/api/action.config';
import { Controller } from '../Utilities/api/controller.config';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class LeadChangeService {
  url: string;
  constructor(private dataService: DataService) { }

  getStates(): Observable<Array<States>> {
    this.url = Controller.lenderChange + '/' + Action.getStates;
    return this.dataService.getAll(this.url);
  }
  //getLeadInfo(): Observable<Array<LenderChanges>> {
  //  this.url = Controller.lenderChange + '/' + Action.lenderInfo;
  //  return this.dataService.getAll(this.url);
  //}
  /*Fetch Lead  Details by LeadID */
  getLeadInfo(leadId: string): Observable<LenderChanges> {
    this.url = Controller.lenderChange + '/' + Action.lenderInfo;
    return this.dataService.getSingle(leadId, this.url);
  }
  SaveLenderChange(data: LenderChanges): Observable<LenderChanges> {
    this.url = Controller.lenderChange + '/' + Action.lenderSave;
    return this.dataService.postData(data, this.url);
  }
  FileSave(data: any): Observable<UploadFile> {
    this.url = Controller.lenderChange + '/' + Action.lenderFile;
    return this.dataService.postData(data, this.url);
  }
  getLeadInfoWithTrackingID(leadId: string, trackingID:string): Observable<LenderChanges> {
    this.url = Controller.lenderChange + '/' + Action.lenderInfo;
    return this.dataService.getSingle(leadId + '/'+trackingID, this.url);
  }
}
