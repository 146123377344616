
import { Component, OnInit, OnDestroy, NgZone, ElementRef, ViewChild, Inject, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { DataService } from '../../service/data.service';
import { Constants } from '../../Utilities/Constants/constants';
import { RequestPolicyChangesService } from '../../service/request-policy-changes.service';
import { FormGroup } from '@angular/forms';
import { CurrentCustomer } from '../../model/currentCustomer';
import { CustInfoService } from '../../service/cust-info.service';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare var grecaptcha: any;
declare function breadcrumbShow(wpBaseUrl: string): any;
declare var $: any;
declare function facIconOne(): any;
@Component({
  selector: 'app-request-policy-changes',
  templateUrl: './request-policy-changes.component.html',
  styleUrls: ['./request-policy-changes.component.css']
})
export class RequestPolicyChangesComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public wpBaseUrl: string;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/rpc-header.jpg',
    desktopHeaderText: 'Request Policy Changes',
    desktopHeaderSubText: "Need to make a change? Simply fill out our online form below.",
    mobileHeaderText: 'Policy Changes',
    mobileHeaderSubText: ''
  });
  formErrors: any = {};
  isLoading: boolean = false;
  requestPolicyChangeForm: FormGroup;
  captchaRequired = true;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  recaptcha: string;
  badge = 'Bottomright';
  lstStates = [
    {
      stateName: ''
    }
  ];

  @ViewChild('propStreetAddress') propStreetAddress: ElementRef;
  @ViewChild('line2') line: ElementRef;
  @ViewChild('cities') city: ElementRef;
  @ViewChild('states') state: ElementRef;
  @ViewChild('postalCodes') postalCode: ElementRef;

  constructor(private router: Router, public commonService: CommonService,
    public dataService: DataService,
    private zone: NgZone,
    private route: ActivatedRoute,
    public requestPolicyChangesService: RequestPolicyChangesService,
    public requestPolicyDetails: CurrentCustomer,
    private custInfoService: CustInfoService,
    public constantsObj: Constants,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string) {
    var isSafari = this.commonService.isSafariBroswer();

    // if (isSafari) {
    //   this.captchaRequired = false;
    //   this.siteKey = siteKeyValueV3;
    // }
    // else {
    //   this.siteKey = siteKeyValue;
    // }
    this.captchaRequired = true;
    this.siteKey = siteKeyValueV3;

    if (!this.commonService.isSafariBroswer()) {
      $('.grecaptcha-badge').remove()
    }
    else {
      $('.grecaptcha-badge').css('display', 'block !important');
      $('.grecaptcha-badge').css('visibility', 'visible !important');
    }
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  ngOnInit() {
    this.requestPolicyDetails = new CurrentCustomer();
    this.getStates();
  }

  OnDestroy() {

  }

  //For sercah icon in address line one
  changingAddressLineOne() {
    facIconOne();
  }

  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  handleSuccess(event) {
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA


  onSubmit(requestPolicyChangeForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(requestPolicyChangeForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(requestPolicyChangeForm);

    if (!requestPolicyChangeForm.valid) {
      this.commonService.focusFirstInvalidElement();
      return;
    }

    // call api to post form  
    if (requestPolicyChangeForm.valid && !this.captchaRequired) {
      this.isLoading = true;
      if (this.commonService.isSafariBroswer()) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
          if (token != '') {
            this.requestPolicyDetails.reCaptcha = new ReCaptcha();
            this.requestPolicyDetails.reCaptcha.response = token;
            this.requestPolicyDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
            this.postData();
          }
        });
      }
      else {
        this.postData();
      }

    } else {

    }
  }
  postData() {
    this.requestPolicyChangesService.SaveRequestPolicyChanges(this.requestPolicyDetails).subscribe((data: any) => {
      this.isLoading = false;
      if (data) {
        alert(data);
      } else {
        alert("Something went wrong, please try again!");
      }
      window.location.reload()
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      window.location.reload()
    });
  }
  getStates() {
    this.custInfoService.getStates().subscribe((data: any) => {
      this.lstStates = data.lstStates;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
    });
  }

  SetPropertyAddressDetails(event) {
    this.zone.run(() => {
      if (event.street_number != undefined && event.route != undefined) {
        this.requestPolicyDetails.addressLine1 = event.street_number + ' ' + event.route;
      }
      else if (event.route != undefined) {
        this.requestPolicyDetails.addressLine1 = event.route;
      }
      else if (event.street_number != undefined) {
        this.requestPolicyDetails.addressLine1 = event.street_number;
      }

      this.requestPolicyDetails.addressLine2 = '';
      this.requestPolicyDetails.city = event.locality;
      this.requestPolicyDetails.state = event.admin_area_l1;
      this.requestPolicyDetails.postalCode = event.postal_code || '';

      this.propStreetAddress.nativeElement.value = this.requestPolicyDetails.addressLine1;
      this.line.nativeElement.value = this.requestPolicyDetails.addressLine2;
      this.state.nativeElement.value = this.requestPolicyDetails.state;
      this.city.nativeElement.value = this.requestPolicyDetails.city;
      this.postalCode.nativeElement.value = this.requestPolicyDetails.postalCode;

    });
  }
  ngAfterViewInit() {
    breadcrumbShow(this.wpBaseUrl);
  }
  ngAfterViewChecked() {

  }

}
