<section id="get-a-quote-ins" class="mb-0">
	<div class="container">
		<div id="get-a-quote-select" class="row justify-content-center">
			<div class="col-lg-4 col-md-6 col-sm-8 px-0 bg-white">
				<select id="smallBannerHeroSelect">
					<option value="0" data-imagesrc="assets/img/icons/icon-home.png"
						data-description="/real-time-quote/cust-info">
						Home
					</option>
					<option value="1" data-imagesrc="assets/img/icons/condo.png"
						data-description="/real-time-quote/cust-info">
						Condo
					</option>
					<option value="2" data-imagesrc="assets/img/icons/icon-renters.png"
						data-description="/real-time-quote/cust-info">
						Renters
					</option>
					<option value="3" data-imagesrc="assets/img/icons/auto-dropdown-icon.png"
						data-description="https://www.agentinsure.com/compare/auto-insurance-home-insurance/westwoodinsurance/quote.aspx">
						Auto
					</option>
					<option value="4" data-imagesrc="assets/img/icons/icon-flood.png"
						[attr.data-description]="floodUrl">
						Flood
					</option>
					<option value="5" data-imagesrc="assets/img/icons/icon-earthquake.png"
						data-description="https://www.arrowheadexchange.com/ao/ao.jsp?control_prodcode=165039&control_prodkey=a8BJLqwqcYhTorlmAWVN36W1nc0%3D&policy_product=erqk&site=ao">
						Earthquake
					</option>
					<option value="7" data-imagesrc="assets/img/icons/icon-umbrella.png"
						[attr.data-description]="umbrellaUrl">
						Umbrella
					</option>
					<option value="16" data-imagesrc="assets/img/icons/small-commercial.png"
						[attr.data-description]="smallBusinessUrl">
						Small Business
					</option>
					<option value="8" data-imagesrc="assets/img/icons/icon-jewely-fine-art.png"
						[attr.data-description]="jewelryUrl">
						Jewelry & Valuables
					</option>
					<!-- added home warranty option for WSTW-5938 -->
					<option value="9" data-imagesrc="assets/img/icons/Home-warranty-icon-lrg-new.png"
						data-description="https://www.2-10.com/get-a-quote-westwoodcx">
						Home Warranty
					</option>
					<option value="10" data-imagesrc="assets/img/icons/icon-golf-cart.png"
						[attr.data-description]="golfcartUrl">
						Golf Cart
					</option>
					<option value="11" data-imagesrc="assets/img/icons/icon-motorcycle.png"
						[attr.data-description]="motorcycleUrl">
						Motorcycle
					</option>
					<!-- Commented as part of WSTW-5680 -->
					<!-- <option value="11" data-imagesrc="assets/img/icons/icon-pet.png"
		data-description="https://quote.embracepetinsurance.com/pet-information?brand=westwood">Pet </option> -->
					<!-- Added as part of WSTW-5680 -->
					<option value="12" data-imagesrc="assets/img/icons/icon-pet.png"
						data-description="https://quote.embracepetinsurance.com/pet-information?orgcode=80171849&brand=westwood&utm_source=westwood&utm_medium=partner&utm_campaign=website">
						Pet
					</option>
					<option value="13" data-imagesrc="assets/img/icons/icon-travel.png"
						data-description="/travel-insurance/travel-online-quote">
						Travel
					</option>
					<option value="14" data-imagesrc="assets/img/icons/icon-event.png"
						data-description="https://www.markelinsurance.com/event/westwood">
						Event
					</option>
					<option value="15" data-imagesrc="assets/img/icons/icon-recretional-vehicle.png"
						[attr.data-description]="recreationalVehiclesUrl">
						Recreational Vehicles
					</option>

					<option value="17" data-imagesrc="assets/img/icons/phone-icon-new.PNG"
						data-description="/products/mobile-phone-insurance" *ngIf="false">
						Phone
					</option>
				</select>
			</div>
			<!-- <a id="pro-gaq" class="btn ww-button-title p-3 ml-2" href="#">Get A Quotesss</a> -->
			<div id="pro-gaq" class="ml-1 d-none d-none d-lg-block" style="width: 170px;">
				<a class="d-block text-center">Get A Quote</a>
			</div>

			<div id="pro-gaq" class="ml-1 d-lg-none d-xl-none" style="width: 170px;">
				<a class="d-block text-center">Get A Quote</a>
			</div>
		</div>
	</div>
</section>