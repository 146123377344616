<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-homeowners" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">Homeowners Insurance</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none d-none">
	<div class="container intro-para-homeowners text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<p>
					<span> Protecting your home with Westwood homeowners insurance products is not just economical, but
						it’s quick, easy and convenient.</span><br>
					Your home is more than a structure &mdash; it’s a place where you feel safe and
					comfortable, and
					where you create lifetime memories with the people who matter most. And because your home may be one
					of your most
					significant assets, it’s essential to protect it and everything inside of it that you value.
					<br>
					<br>
					<span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
						questions regarding Homeowners Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
				</p>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  H O M E   O W N E R S   I N S U R A N C E   -->

<section>
	<div class="container pt-3 pb-5">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center">WHY A HOME WARRANTY SERVICE AGREEMENT?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="assets/img/page-body-img/dryer-fix.jpg" style="width: 100%;padding-bottom: 20px;"
					alt="Homeowners Insurance" />
			</div>
			<div class="col mt-2">
                Breakdowns can be costly, taking up lots of time and money. When your furnace, A/C, water heater, or one of your household appliances breaks down from regular use, your homeowners insurance won’t cover it.
                <br><br>
                A home warranty service agreement can help quickly repair or replace your home appliances and systems under covered breakdowns, and you won’t be left with surprise bills.
            
            </div>
		</div>
	</div>
</section>

<!-- // W H Y  H O M E   O W N E R S   I N S U R A N C E   -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section id="whats-included-home-warranty">
	<div class="container pt-5 pb-5">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">WHAT’S INCLUDED IN A HOME WARRANTY SERVICE AGREEMENT?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
        </div>
        <div class="row justify-content-center pt-2 pb-3">
                <div class="col-md-12">
                        Generally, it covers major systems like HVAC, plumbing, and electrical and 
                        includes appliances such as refrigerators, ovens, and dishwashers. Coverage 
                        can vary based on the plan and options you choose.
                </div>
        </div>
		<div class="row justify-content-center pt-2 pb-3">
			<div class="col-md-12 tabbedViewInGraySection">
				<nav>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
						<a class="nav-item nav-link active" id="nav-kitchen-tab" data-toggle="tab" href="#nav-kitchen"
							role="tab" aria-controls="nav-kitchen" aria-selected="true">Simply Kitchen</a>
						<a class="nav-item nav-link" id="nav-completeHome-tab" data-toggle="tab" href="#nav-completeHome"
							role="tab" aria-controls="nav-completeHome" aria-selected="false">Complete Home</a>
						<a class="nav-item nav-link" id="nav-pinnacleHome-tab" data-toggle="tab" href="#nav-pinnacleHome"
							role="tab" aria-controls="nav-pinnacleHome" aria-selected="false">Pinnacle Home</a>
					</div>
				</nav>
				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-kitchen" role="tabpanel" aria-labelledby="nav-kitchen-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>Simply Kitchen protects your kitchen appliances, like your dishwasher and refrigerator, at our most affordable price.
								</li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-completeHome" role="tabpanel" aria-labelledby="nav-completeHome-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>Complete Home gives you even more protection, combining system and appliance coverage.</li>
							</ul>
						</div>
					</div>
					<div class="tab-pane fade" id="nav-pinnacleHome" role="tabpanel" aria-labelledby="nav-pinnacleHome-tab">
						<div id="a-bullet-list2">
							<ul>
								<li>Pinnacle Home adds Supreme, which maximizes protection for your home’s kitchen appliances, 
                                    washer and dryer, heating and cooling, plumbing, and electrical.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- O N L I N E   Q U O T E -->



		<!-- // O N L I N E   Q U O T E -->

	</div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->


<!-- // F U L L   C O V E R A G E  D E T A I L S  -->


<!-- O P T I O N A L   P O L I C I E S  -->

<section id="whats-included" class="bg-secondary" *ngIf="false">
	<div class="container pt-5 pb-5 ">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">Optional Policies</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row text-center justify-content-center pb-4">
			<div class="col-10 pb-2">
				There are additional endorsements or policies available for perils not covered on standard homeowners
				policies. If you have a risk you are unsure about, ask us! Here are some examples where we can help:
			</div>
		</div>
		<div class="row pt-2 pl-0">
			<div class="col-md-6 col-sm-12">
				<ul class="pl-0">
					<li>Earthquake</li>
					<li>Flood</li>
					<li>Personal Articles Floater (PAF)<br> Scheduled Personal Property</li>
					<li>Service Line</li>
					<li>Golf Cart</li>
				</ul>
			</div>
			<div class="col-md-6  col-sm-12">
				<ul class="pl-0">
					<li>Event</li>
					<li>Bicycle</li>
					<li>Pet</li>
					<li>Equipment Breakdown</li>
					
					<li>Umbrella</li>
				</ul>
			</div>
		</div>
	</div>
</section>

<!-- // O P T I O N A L   P O L I C I E S  -->


<!--  F R E Q U E N T L Y  A S K E D   Q U E S T I O N S   -->

<section>
	<div class="container pt-5 pb-5 bottom-border-element">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">Frequently Asked Questions</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3" *ngIf="false">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
				<br><br>
				<span>Homeowners Insurance</span><br>
			</div>
		</div>
		<div class="row text-center p-2 pb-4" *ngif="false">
			Insurance can be confusing. That’s why your Westwood agent and customer service team are so important. If
			you have a question – ask us!
			<br><br>
			Here are some of the most frequently-asked questions from our customers. The answers provide general
			information – each company defines their own specific coverage, terms, limitations and exclusions. Your
			policy includes the specifics.

		</div>

		<div class="row accordion" id="accordionExample">
			<div class="col-md-6 col-xs-12">

				<div class="card">
					<div class="card-header" id="heading5a">
						<h2 class="mb-0">
							<button aria-controls="collapse5a" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse5a" data-toggle="collapse" type="button">
                                What is a home warranty service agreement?
                                <span *ngIf="false" class="expandSelection">+</span>
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading5a" class="collapse" data-parent="#accordionExample" id="collapse5a">
						<div class="card-body">
							It’s an annual contract that can help reduce the costs to repair or replace covered systems and appliances that break down from normal wear and tear.
						</div>
					</div>
				</div>

				

				<div class="card">
					<div class="card-header" id="headingA">
						<h2 class="mb-0">
							<button aria-controls="collapseA" aria-expanded="true" class="btn btn-link"
								data-target="#collapseA" data-toggle="collapse" type="button">
								How much does a home warranty service agreement cost?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingA" class="collapse" data-parent="#accordionExample" id="collapseA">
						<div class="card-body">
                            Rates typically range between $300 and $900 a year, a fraction of what it often costs to 
                            repair or replace breakdowns out of pocket. Prices will vary based on the plan and options you 
                            choose. <a href="https://www.2-10.com/get-a-quote-westwoodcx" target="_blank">Get your free, no-obligation quote today.</a>
						</div>
					</div>
				</div>

				

				<div class="card">
					<div class="card-header" id="headingTwo">
						<h2 class="mb-0">
							<button aria-controls="collapseTwo" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapseTwo" data-toggle="collapse" type="button">
								When can I start using my home warranty service agreement?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingTwo" class="collapse" data-parent="#accordionExample" id="collapseTwo">
						<div class="card-body">
							You can begin requesting service 30 days after purchase.
						</div>
					</div>
				</div>

			</div>

			<div class="col-md-6 col-xs-12">
                <div class="card">
					<div class="card-header" id="heading5aa">
						<h2 class="mb-0">
							<button aria-controls="collapse5aa" aria-expanded="false" class="btn btn-link collapsed"
								data-target="#collapse5aa" data-toggle="collapse" type="button">
								What does a home warranty service agreement cover?
							</button>
						</h2>
					</div>
					<div aria-labelledby="heading5aa" class="collapse" data-parent="#accordionExample" id="collapse5aa">
						<div class="card-body">
                            Your specific coverage will vary depending upon the plan you choose. However, a home warranty service 
                            agreement can protect essential systems in your home, including plumbing and HVAC, and cover 
                            household appliances, like washers and dryers.
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header" id="headingOne">
						<h2 class="mb-0">
							<button aria-controls="collapseOne" aria-expanded="true" class="btn btn-link"
								data-target="#collapseOne" data-toggle="collapse" type="button">
								Can older homes, systems, and appliances get coverage?
							</button>
						</h2>
					</div>
					<div aria-labelledby="headingOne" class="collapse" data-parent="#accordionExample" id="collapseOne">
						<div class="card-body">
                            Coverage is available for homes, systems, and appliances of any age, 
                            as long as they are in 
                            good working order when the coverage term begins.

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>

<section id="about-warranty" class="bg-secondary">
	<div class="container pt-5 pb-5 ">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">ABOUT 2-10 HOME BUYERS WARRANTY</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<!--<div class="row text-center justify-content-center pb-4">
			<div class="col-10 pb-2">
                
			</div>
		</div>-->
		<div class="row pt-2 pr-3" >
				<div class="col mt-2">
				For more than 40 years, 2-10 Home Buyers Warranty (2-10 HBW) has covered more than 
                6 million new and preowned homes. As the industry leader, 1 in 6 homes in the US 
                is covered by a 2-10 HBW Structural Warranty.
                <br><br>
                2-10 HBW provides coverage for the home’s structure, and important systems and 
                appliances—such as heating and cooling systems, water heaters, and refrigerators—to 
                help reduce financial risks. 2-10 HBW works with home builders, real estate 
                professionals, and service contractors to improve the quality of housing and 
                the experience of home ownership nationwide.
                <br><br>
                Whether you build it, own it, buy it, or sell it, 2-10 HBW’s suite of solutions 
                and services can support your success.
					
				</div>
				
		</div>
	</div>
</section>

<!-- //  F A Q   -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center [categoryID]="categoryID"></app-knowledge-center>
</section>
<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->
