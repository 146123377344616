<section class="bg-secondary">
	<div class="container text-center pt-4 pb-5">
		<div class="row pt-4">
			<div class="col">
				<div class="row pt-2">
					<div class="col">
						<h2 class="col text-center text-uppercase">Westwood’s Response to
							COVID-19
						</h2>
					</div>
				</div>
				<div class="row justify-content-center pt-2">
					<div class="col-md-8 col-lg-8 col-xs-8 col-sm-12">
						<img class="text-center" src="assets/img/orange-divider-transparent.png" alt="orange decor" />
						<br><br>
						<h3 class="font-weight">We’re here to help</h3>
						<div class="col-md-12 text-left pt-3">
							<p class="text-center">Many things have changed, but Westwood’s commitment to being there
								for both our employees and customers hasn’t. We remain fully operational and ready to
								help you. Like many businesses, we’ve transitioned most employees to remote working
								environments. You may experience a longer than usual wait time when you call or email
								us. Rest assured, we’re working hard to respond as quickly as possible. Thanks for your
								patience and allowing Westwood to help protect what matters most.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>