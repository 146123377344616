import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var jQuery: any;
@Component({
  selector: 'app-model-pop-up-lender-change-message',
  templateUrl: './model-pop-up-lender-change-message.component.html',
  styleUrls: ['./model-pop-up-lender-change-message.component.css']
})
export class ModelPopUpLenderChangeMessageComponent implements OnInit {
  displayMsg: string = "";
  @Output("parentFunYes") parentFunYes: EventEmitter<any> = new EventEmitter();
  @Output("parentFunNo") parentFunNo: EventEmitter<any> = new EventEmitter();
  @Output("parentFunOk") parentFunOk: EventEmitter<any> = new EventEmitter();
  constructor(private modalService: NgbModal) {
  
  }
  showOk: boolean = false;

  ngOnInit() {
  }

  //To show function
  displayMessage(msg, showOkButton) {
    this.displayMsg = msg;
    if (showOkButton) {
      this.showOk = true;
    }
    //jQuery('#lenderChangeModal').modal('show', { backdrop: 'static', keyboard: false });
    jQuery('#lenderChangeModal').modal({
      backdrop: 'static',
      keyboard: true,
      show: true
    });
  }
  hideModal() {
    jQuery('#lenderChangeModal').modal('hide');
  }

  continueButton(event) {
    event.preventDefault();
  }
}
