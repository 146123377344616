<div class="modal fade" id="videoModal">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:0px">
                <!-- <span>Important Notice</span> -->
                <button id="closeModal" type="button" class="close" 
                data-dismiss="modal" aria-label="Close"
                (click)="closeModalBox()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div class="video-image">
                <img src="assets/img/partner-mortgage-company.jpg" width="699" height="500">
                <div class="video-overlay">
                    <a class="video-trigger" data-open="youtube-video" data-youtube-id="https://youtu.be/BcyOpyEdJfM" 
                    aria-controls="youtube-video" aria-haspopup="true" tabindex="0"><i class="fa fa-play-circle" 
                    aria-hidden="true"></i></a>
                    <i class="fa fa-play-circle" aria-hidden="true"></i>
                </div>
            </div> -->
            <div class="row">
                <div class="col">
                    <div class="modal-body" style="padding:0px">
                        <iframe class="ww-video" title="Westwood Agency Insurance overview"
                                            width="100%" height="600px" 
                                            src="https://www.youtube.com/embed/Hob9CSqur80?enablejsapi=1" 
                                            title="An Insurance Agency Built for the Housing Industry" frameborder="0" 
                                            poster="assets/img/partner-builder.jpg"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowfullscreen>
                                        </iframe>
                        
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <a href="#" id="leavingModalLink" class="text-orange" target="_blank" (click)="this.continueButton($event)"><strong>Continue</strong> </a>
                <a href="#" class="text-blue" target="_blank" aria-hidden="true" data-dismiss="modal" aria-label="Close"><strong>Decline</strong>
                </a>
            </div> -->
        </div>
    </div>
</div>
