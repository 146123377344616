<section class="news-article">
  <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
  <div class="container pt-5 loader-container" *ngIf="isLoading"></div>
  <div class="container pt-5" *ngIf="!isLoading">
    <div class="row pt-2 pl-3" id="articleGrid">
      <div class="col-md-4 bottom-border-element-article pl-0">
        <h2 class="text-left"><a [routerLink]="['/news']">News and Insights</a></h2>
      </div> 
    </div>
    
    <div class="row pb-4">
        <!-- <div class="col-md-9 pl-3 pt-3 font-cust">
           
        </div> -->
      <div class="col-xl-9 col-sm-12 pt-3">
        <!-- Start List Of articles -->
        <div class="row">
          <div class="card mx-3 shadow rounded no-border-top">
               
                  <h2 class="card-title cust-font pl-3 pt-3">{{this.data?.articlesDetail[0].heading}}</h2>
                  <!-- commented for WSTW-5641 -->
                  <!-- <p class="font-cust pl-3"><span *ngIf="this.data?.articlesDetail[0].author">By {{ this.data?.articlesDetail[0].author }} |</span> {{this.data?.articlesDetail[0].description}} |
                  {{this.data?.articlesDetail[0].articleDate | date: 'mediumDate'}} </p> -->
                   <!--added for WSTW-5641 -->
                   <p class="font-cust pl-3"><span *ngIf="this.data?.articlesDetail[0].author">By {{ this.data?.articlesDetail[0].author }} |</span> {{this.data?.articlesDetail[0].description}}
                    <span *ngIf="displayDateForArticleCategory()" class="span-article-date">
                        | {{this.data?.articlesDetail[0].articleDate | date: 'mediumDate'}}</span>
                  </p>
             
              
                <img 
                class="card-img-top" 
                [src]="this.data?.articlesDetail[0].image" 
                alt="Card image cap" 
              />
              
           
            <div class="card-body">
              
              
              <ul class="list-unstyled pl-3" *ngFor="let item of data?.articlesDetail; index as i; ">
                <li class="pt-2">
                  <h5 class="card-title font-cust" *ngIf="item?.contentHeader">{{item?.contentHeader}}</h5>
                  <p class="card-text font-cust" [innerHtml]="item?.contentText">       
                  </p>
                </li>
              </ul>

              <div class="card-footer bg-white">
                <ul class="nav nav-pills card-header-pills">
                  <li class="nav-item">
                    <span class='social nav-link share-link-cust'><i class="fad fa-share-alt"></i></span>
                  </li>
                  <li class="nav-item">
                    <a (click)="popupfacebook()" class="social nav-link social-link-cut" target="_blank"><i class="fab fa-facebook-f"></i></a>
                  </li>

                  <li class="nav-item">
                    <a (click)="popuplinkedin()" class="social nav-link social-link-cut" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                  <!-- <li class="nav-item">
                      <a href="mailto:?subject=I wanted you to see this site&amp;body=Check out this site {{ shareLink }}."
                      title="Share by Email" class="social nav-link" target="_blank">
                        <i class="fas fa-envelope"></i>
                      </a>
                    </li> -->
                  <li class="nav-item">
                    <a href="mailto:?subject=Check out this article from Westwood Insurance Agency&amp;body=I think you’ll find this article interesting {{ shareLink }}."
                      title="Share by Email" class="social nav-link social-link-cut" target="_blank">
                      <i class="fas fa-envelope"></i>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- End List Of articles -->
      </div>
      <!-- Start Related post -->
    

    <div class="row  pb-4 d-flex justify-content-center pl-3 pt-5" *ngIf="this.commonService.iMobBroswer() && this?.data?.articlesRelated?.length>0">  
          
        <div class="col-md-12 ">
          <h2 class="text-left elated-post-heading">Related Posts</h2>
        </div>
         
        
        <!-- Start List Of articles -->
        <div 
          class=" " 
          [ngClass]="{'col-md-12 ': this.commonService.iMobBroswer(), 'row ': !this.commonService.iMobBroswer()}"
        > 
        <!-- <div class="col-md-12"> -->
            <div class="card my-3 mx-3 shadow rounded item justify-content-sm-center card-custom"  *ngFor="let item of this?.relatedPost | paginate: { itemsPerPage: 3, currentPage: PageNumber }; index as i;">
              <img class="card-img-top"  [src]="item?.image" alt="
                                    Card image cap" />
              <div class="card-body">
                <h5 class="card-title" [innerHtml]="item?.heading"></h5>
               
                <p class="card-text font-cust" [innerHtml]="item?.summaryText">
                   
                </p>
                <a  (click)="this.commonService.gotoRelatedArticle(item?.title)"  class="read-more btn">Read more</a>
              </div>
            </div>
          
          
        
        <!-- End List Of articles -->

        <!-- <div class="row col-md-11 text-center pt-3" *ngIf="this?.data?.articlesRelated?.length >3 "> -->
          <div class="row col-md-11 text-center pt-3" *ngIf="false">
            <div class="col pr-5 pb-5">
                <button type="button" tabindex="-1" class="btn pl-0 button-custom" (click)="this.dataForRelatedPost('pre');">
                    <div class="angled-button-sm angled-button-sm button-width-110 button-align-mob-previous previous-left">
                        <span>Previous</span>
                    </div> 
                </button>
            </div>
            <div class="col pb-5 pt-2 "> 
                <button class="btn angled-button-sm button-align-mob-next button-width-90 button-custom"  type="button" (click)="this.dataForRelatedPost('next');"><span >Next</span></button>
            </div>
           
        </div> 
       </div>  
    </div>
    <!-- End Related post -->
    <!-- Start show categories and archives for desktop -->
    <div class="col-xl-3 mt-3 bg-white col-sm-12 custom-heigth" *ngIf="!this.commonService.iMobBroswer()"
    >
        <div class="row">
          <div class="input-group col-md-12 pt-3">
            <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search" id="example-search-input"
              name="SearchTerm" [(ngModel)]="SearchTerm" (change)="SearchTermChanged()">
            <span class="input-group-append">
              <button class="btn btn-outline-secondary border-left-0 border" type="button" (click)="this.stateService.changeCategoryID(0);
              this.stateService.changeSearchTerm(SearchTerm);
              this.stateService.changeMonth(0);
              this.stateService.changeYear(0);
              refreshArticleGrid('search')">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
        
        <h5 class="pb-2 pt-4" ><img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> <a [routerLink]="['/news']" class="black-color"> Categories</a></h5>
        <!-- Start show categories -->
        <ul class="list-group list-group-flush" 
             
         *ngFor="let item of data?.articlesCategory; index as i;">
          <li class="list-group-item font-weight-bold">

            <a href="#" class="text-dark" (click)="$event.preventDefault();this.stateService.changeCategoryID(item.categoryID);this.stateService.changeSearchTerm('');
            this.stateService.changeMonth(0);
            this.stateService.changeYear(0);
            refreshArticleGrid('category')">{{item.categoryName}}</a>
          </li>
        </ul>
        <!-- End show categories -->
        <!-- Setting display=false for WSTW-5641 -->
        <div *ngIf="false">
          <h5 class="pt-5 pb-2 archives"  ><img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> <a [routerLink]="['/news']"> Archives</a></h5>
          <!-- Start show Archives -->
          <ul class="list-group list-group-flush"  
           *ngFor="let item of data?.articlesArchives; index as i;">
            <li class="list-group-item">
              <a href="#" class="text-dark p-2" (click)="$event.preventDefault();
              this.stateService.changeCategoryID(0);
              this.stateService.changeSearchTerm('');
              this.stateService.changeMonth(item.month);
              this.stateService.changeYear(item.year);
              refreshArticleGrid('archives')">{{item.archives}}</a>
            </li>
          </ul>
          <!-- End show Archives -->
      </div>
    </div>
    <!-- End show categories and archives for desktop -->
    <!-- Start show categories and archives for mobile -->
    <div class="col-xl-3 mt-3 bg-white col-sm-12 " *ngIf="this.commonService.iMobBroswer()">
        <div class="row">
          <div class="input-group col-md-12 pt-3">
            <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search" id="example-search-input"
              name="SearchTerm" [(ngModel)]="SearchTerm" (change)="SearchTermChanged()">
            <span class="input-group-append">
              <button class="btn btn-outline-secondary border-left-0 border" type="button" (click)="this.stateService.changeCategoryID(0);
              this.stateService.changeSearchTerm(SearchTerm);
              this.stateService.changeMonth(0);
              this.stateService.changeYear(0);
              refreshArticleGrid('search')">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </div>
        <h5 class="pb-2 pt-4" ><img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> <a [routerLink]="['/news']" class="black-color"> Categories</a><a class="pl-3 black-color" data-toggle="collapse" href="#collapseCategories" role="button" aria-expanded="false" aria-controls="collapseCategories">
            <i class="fas fa-angle-double-down"></i>
          </a></h5>
        <!-- Start show categories -->
        <ul class="list-group list-group-flush collapse"  id="collapseCategories" *ngFor="let item of data?.articlesCategory; index as i;">
          <li class="list-group-item font-weight-bold">

            <a href="#" class="text-dark" (click)="$event.preventDefault();this.stateService.changeCategoryID(item.categoryID);this.stateService.changeSearchTerm('');
            this.stateService.changeMonth(0);
            this.stateService.changeYear(0);
            refreshArticleGrid('category')">{{item.categoryName}}</a>
          </li>
        </ul>
        <!-- End show categories -->
        <!-- Setting display=false for WSTW-5641 -->
        <div *ngIf="false">
          <h5 class="pt-5 pb-2  archives"><img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> <a [routerLink]="['/news']"> Archives</a><a class="pl-5 " data-toggle="collapse" href="#collapseArchives" role="button" aria-expanded="false" aria-controls="collapseArchives">
              <i class="fas fa-angle-double-down"></i>
            </a></h5>
          <!-- Start show Archives -->
          <ul class="list-group list-group-flush collapse"  id="collapseArchives" *ngFor="let item of data?.articlesArchives; index as i;">
            <li class="list-group-item">
              <a href="#" class="text-dark p-2" (click)="$event.preventDefault();
              this.stateService.changeCategoryID(0);
              this.stateService.changeSearchTerm('');
              this.stateService.changeMonth(item.month);
              this.stateService.changeYear(item.year);
              refreshArticleGrid('archives')">{{item.archives}}</a>
            </li>
          </ul>
          <!-- End show Archives -->
      </div>
    </div>    
    <!-- End show categories and archives for mobile -->
      
    </div>
    <!-- Start Related post desktop-->
    

    <div class="row  pb-4 " *ngIf="!this.commonService.iMobBroswer() && this?.data?.articlesRelated?.length>0">  
          
        <div class="col-md-12 ">
          <h2 class="text-left elated-post-heading">Related Posts</h2>
        </div>
         
        
        <!-- Start List Of articles -->
        <div 
          class="col-md-12 pl-0 pr-0" 
        > 
        <div class="row ml-0">
            <div class="card my-3 mx-3 shadow rounded item justify-content-sm-center card-custom"  *ngFor="let item of this?.relatedPost | paginate: { itemsPerPage: 3, currentPage: PageNumber }; index as i;">
              <img class="card-img-top"  [src]="item?.image" alt="
                                    Card image cap" />
              <div class="card-body">
                <h5 class="card-title" [innerHtml]="item?.heading"></h5>
               
                <p class="card-text font-cust" [innerHtml]="item?.summaryText">
                   
                </p>
                <a (click)="this.commonService.gotoRelatedArticle(item?.title)" class="read-more btn">Read more</a>
              </div>
            </div>
          
          </div> 
        
        <!-- End List Of articles -->
        
        <!-- <div class="row col-md-11 text-center pt-3" *ngIf="this?.data?.articlesRelated?.length >3 "> -->
          <div class="row col-md-11 text-center pt-3" *ngIf="false">
            <div class="col pr-5 pb-5 ">
                <button type="button" tabindex="-1" class="btn pl-0 button-custom" (click)="this.dataForRelatedPost('pre');">
                    <div class="angled-button-sm angled-button-sm button-width-110 button-align-mob-previous previous-left">
                        <span>Previous</span>
                    </div> 
                </button>
            </div>
            <div class="col pb-5 pt-2 "> 
                <button class="btn angled-button-sm button-align-mob-next button-width-90 button-custom"  type="button" (click)="this.dataForRelatedPost('next');"><span >Next</span></button>
            </div>
           
        </div> 
       </div>  
    </div>
    <!-- End Related post desktop-->
    <!-- Start news home -->
    <div class="row">
        <div 
        class="col pb-5 pt-2 pl-5"  
        [ngClass]="{'d-flex justify-content-center ': this.commonService.iMobBroswer()}">
        <a class="btn news-home" [routerLink]="['/news']">NEWS HOME</a>
      </div>

      <div 
              *ngIf="this?.data?.articlesRelated?.length > 3"
              class="col-xl-9 col-sm-12 mob-pt-3 custom-pagination" 
              [ngClass]="{'d-flex justify-content-center ': this.commonService.iMobBroswer()}">
              <pagination-controls 
              (pageChange)="PageNumber = $event"
              [previousLabel]="PrevLabel"
              [nextLabel]="NextLabel"

                [maxSize]="0"
              ></pagination-controls>
            </div>
    </div>
    <!-- End news home -->
  </div>
</section> 


  

    



