<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-golf" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Golf Cart Insurance</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
    <div class="container text-center pt-3 pb-3">
        <div class="row justify-content-center">
            <div class="col-md-9 col-xs-9">
                <p>
                    <span><img src="assets/img/icons/cell-phone.png" alt="" width="30"> Call us for a quote or
                        questions
                        regarding Golf Cart Insurance at <a href="tel:18005406964">800.540.6964</a>
                        <br><br></span>
                </p>
            </div>
        </div>
    </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y   G O L F  -->

<section class="bg-secondary">
    <div class="container pt-5 pb-5" id="a-bullet-list">

        <div class="row pt-2">
            <div class="col">
                <h2 class="col text-center">Why Golf Cart Insurance?</h2>
            </div>
        </div>
        <div class="row text-center pt-2 pb-5">
            <div class="col">
                <img src="assets/img/orange-divider-transparent.png" /><br><br>
                Golf cart insurance coverage is required in many states. If you live in a golf community, your
                Homeowners Association or golf club may require you to have golf cart insurance.
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 pb-5">
                <img src="assets/img/page-body-img/shutterstock_666696949.png" style="width: 100%;"
                    alt="Three men on the golf course" />
            </div>
            <div class="col-md-6">
                <span>What’s included in Golf Cart Insurance? </span>
                <ul>
                    <li>Financial protection if you’re liable because you injured someone or damaged their property
                        while driving your golf cart.</li>
                    <li>Coverage for your court costs and legal fees up to your policy’s limits.</li>
                    <li>Coverage for medical costs for you or a passenger injured while riding or driving the golf
                        cart.</li>
                    <li>Repairs or replacement expenses if there is damage to your golf cart because of an accident,
                        collision, fire or act of nature.</li>

                </ul>
            </div>
        </div>


    </div>
</section>

<!-- //  W H Y  G O L F  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->