import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-flood',
  templateUrl: './flood.component.html',
  styleUrls: ['./flood.component.css']
})
export class FloodComponent implements OnInit,AfterViewInit {
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'flood-header.1b026ed553b45c76a4ad.jpg',
    desktopHeaderText: 'Flood Insurance',
    desktopHeaderSubText: "Flooding can happen anywhere at any time - even outside high-risk areas. Most homeowners policies don't cover flood damage, leaving your home and belongings at risk.",
    mobileHeaderText: 'Flood Insurance',
    mobileHeaderSubText: ''
  });
  constructor() { }
  @ViewChild(ModelPopUpOtherDomainComponent) child:ModelPopUpOtherDomainComponent;
  ngOnInit() {
  }
  ngAfterViewInit()
  {
    breadcrumbShow();
  }
  


}
