export class LoanInformation {
    public havingActiveTransaction?: boolean;
    public amount: string;
    public officerName: string;
    public officerPhone: string;
    public mortgageName: string;
    public officerEmail: string;
    public loanProcessorName: string;
    public loanProcessorPhoneNumber: string;
    public loanProcessorEmail: string;
    public eddFlag?:boolean;
    public eddEmail:string;

    constructor() {
        this.havingActiveTransaction = false;
        this.amount='';
        this.eddEmail='';
        this.eddFlag=true;
        this.officerName='';
        this.officerPhone='';
        this.mortgageName='';
        this.officerEmail='';
        this.loanProcessorName='';
        this.loanProcessorPhoneNumber='';
        this.loanProcessorEmail='';

    }
}