import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants';
import { PaymentPageInfo } from 'src/app/model/paymentPageInfo';
import { DataService } from 'src/app/service/data.service';
import { MakeApaymentService } from '../../service/make-apayment.service';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(wpBaseUrl: string): any;

@Component({
  selector: 'app-make-apayment',
  templateUrl: './make-apayment.component.html',
  styleUrls: ['./make-apayment.component.css']
})
export class MakeAPaymentComponent implements OnInit, AfterViewInit {
  const: Constants;
  public wpBaseUrl: string;
  PaymentPageInfos: Array<PaymentPageInfo> = [];
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/makepay-header.jpg',
    desktopHeaderText: 'Make a Payment',
    desktopHeaderSubText: "Making a payment is quick and easy.",
    mobileHeaderText: 'Make a Payment',
    mobileHeaderSubText: ''
  });

  constructor(
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService,
    public makeApaymentService: MakeApaymentService,
  ) {
    this.const = constantObj;
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  isLoading = false;
  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
    this.getMakeAPaymentValues();
  }

  getMakeAPaymentValues() {
    this.isLoading = true;
    this.makeApaymentService.getMakeAPaymentInfo().subscribe((data: Array<PaymentPageInfo>) => {
      this.PaymentPageInfos = data;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
    });
  }

  ngOnDestroy() {

  }
  ngAfterViewInit() {
    breadcrumbShow(this.wpBaseUrl);
  }


}
