import { Component, OnInit, HostListener } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { DataService } from "src/app/service/data.service";

declare var $: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
  animations: [
    trigger("hideShow", [
      transition("void => *", [
        style({ opacity: 0 }),
        animate("500ms ease-in", style({ opacity: 1 })),
      ]),
      transition("* => void", [
        animate("500ms ease-out", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FooterComponent implements OnInit {
  selectedMenu: string;
  public wpBaseUrl: string;
  public legalUrl: string;
  public privacyPolicyUrl: string;
  public selectedMobileMenuFooterCurrent: string = "";
  selectedSubMenu: string;
  screenHeight: number;
  screenWidth: number;

  constructor(public dataService: DataService) {
    this.selectedMenu = "";
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
    this.legalUrl = this.wpBaseUrl + "legal";
    this.privacyPolicyUrl = this.wpBaseUrl + "privacy-policy";
    this.getScreenSize();
  }

  ngOnInit() {
    // console.log('Footer: ngOnInit')
  }

  collapseSideMenu(menuId) {
    if (this.selectedMenu === menuId) {
      this.selectedMenu = "";
    } else {
      this.selectedMenu = menuId;
    }
  }

  navigateToUrl(url: string) {
    this.selectedMenu = "";
    window.location.href = this.wpBaseUrl + url;
  }

  selectSubMenu(subMenuItem: string) {
    event.stopPropagation();
    $("#ww-mobile-menu").modal("hide");
    this.selectedSubMenu = subMenuItem;
    console.log("sub:", this.selectedSubMenu);
  }

  selectMenuHead(selectedHeadMenu: string) {
    this.selectedMobileMenuFooterCurrent = selectedHeadMenu;
  }

  getOrientation() {
    if (window.outerWidth > window.outerHeight) {
      return "landscape";
    }
    return "portrait";
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
}
