import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare var jQuery: any;
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-renters',
  templateUrl: './renters.component.html',
  styleUrls: ['./renters.component.css']
})
export class RentersComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'renters-header.80eeca240d84cb52433d.jpg',
    desktopHeaderText: 'Renters Insurance',
    desktopHeaderSubText: "When renting, your landlord’s insurance policy won't cover your personal belongings. Protect what's important to you with a renters policy that fits your needs and budget.",
    mobileHeaderText: 'Renters Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="/real-time-quote/cust-info" target="_blank" class=" btn banner-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="/real-time-quote/cust-info" target="_blank" class=" btn banner-button">Get a Quote</a>'

  });

  constructor() { }

  ngOnInit() {
  }
  importantNotice(event, link) {
    event.preventDefault();
    if (link !== '') {
      jQuery('#leavingModalLink').attr('href', link);
      jQuery('#leavingModal').modal();
    }
  }
  ngAfterViewInit() {
    breadcrumbShow();
  }


}
