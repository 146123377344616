import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxCurrencyModule } from "ngx-currency";
import { FaIconsModule } from '../fa-icons.module';
import { SharedComponent } from './shared.component';
import { KnowledgeCenterComponent } from './knowledge-center/knowledge-center.component';
import { GetAQuoteBannerComponent } from './get-aquote-banner/get-aquote-banner.component';
import { ServiceAreaMapComponent } from './service-area-map/service-area-map.component';
import { InsuranceProductsComponent } from './insurance-products/insurance-products.component';
import { GetAQuoteSmallBannerComponent } from './get-aquote-small-banner/get-aquote-small-banner.component';
import { RequestAnInsuranceQuoteComponent } from './request-an-insurance-quote/request-an-insurance-quote.component';
import { ModelPopupComponent } from './model-popup/model-popup.component';
import { BsModalService, ModalBackdropComponent, ModalModule } from 'ngx-bootstrap/modal';
import { ModelPopUpOtherDomainComponent } from './model-pop-up-other-domain/model-pop-up-other-domain.component';
import { ModelPopUpLenderRequestMessageComponent } from './model-pop-up-lender-request-message/model-pop-up-lender-request-message.component';
import { SubPageBannerComponent } from './sub-page-banner/sub-page-banner.component';
import { PopUPRequestDetailsComponent } from './pop-uprequest-details/pop-uprequest-details.component';
import { FormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { EmailValidatePopUpDirective } from '../directive/email-validate-pop-up.directive';
import { ModelPopUpLenderChangeMessageComponent } from './model-pop-up-lender-change-message/model-pop-up-lender-change-message.component';
import { PopUpVideoContainerComponent } from './pop-up-video-container/pop-up-video-container.component';
import { MaskInputDirectiveDirective } from '../../app/directive/mask-input-directive.directive';
import { DuplicatePhoneNumberDirective } from '../directive/duplicate-phone-number.directive';

//import { ExtraDivComponent } from './extra-div/extra-div.component';

@NgModule({
  declarations: [SharedComponent, KnowledgeCenterComponent, GetAQuoteBannerComponent, ServiceAreaMapComponent, InsuranceProductsComponent, GetAQuoteSmallBannerComponent, RequestAnInsuranceQuoteComponent, ModelPopupComponent, ModelPopUpOtherDomainComponent, ModelPopUpLenderRequestMessageComponent,ModelPopUpLenderChangeMessageComponent, SubPageBannerComponent, PopUPRequestDetailsComponent, EmailValidatePopUpDirective, ModelPopUpLenderChangeMessageComponent, PopUpVideoContainerComponent, MaskInputDirectiveDirective, DuplicatePhoneNumberDirective
    /*, ExtraDivComponent*/],
  imports: [
    CommonModule, ModalModule, FormsModule, NgxCaptchaModule, FaIconsModule, NgxCurrencyModule
  ],
  exports: [KnowledgeCenterComponent, GetAQuoteBannerComponent, ServiceAreaMapComponent, InsuranceProductsComponent, GetAQuoteSmallBannerComponent, RequestAnInsuranceQuoteComponent, ModelPopupComponent, ModelPopUpOtherDomainComponent, ModelPopUpLenderRequestMessageComponent,ModelPopUpLenderChangeMessageComponent, SubPageBannerComponent, PopUPRequestDetailsComponent,PopUpVideoContainerComponent, MaskInputDirectiveDirective, DuplicatePhoneNumberDirective],
  providers: [BsModalService, EmailValidatePopUpDirective,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "captchaSiteKeyV3" },
  ],
  entryComponents: [
    ModalBackdropComponent
  ]
})
export class SharedModule { }
