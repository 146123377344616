import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
declare function breadcrumbShow():any;
import { iFrameTravelOnline } from '../../service/config.service';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-travel-online-quote',
  templateUrl: './travel-online-quote.component.html',
  styleUrls: ['./travel-online-quote.component.css']
})
export class TravelOnlineQuoteComponent implements OnInit, AfterViewInit {

  iframeUrl : any;
  srcUrl : any;
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(iFrameTravelOnline) public iFrameTravelOnlineUrl?: string
    
  ) {  
    this.iframeUrl = this.iframeUrlLoad(iFrameTravelOnlineUrl); 
  } 
  
  iframeUrlLoad(url) { 
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  ngOnInit() {

  }

  ngAfterViewInit()
  {
    breadcrumbShow();
  } 

}
