import { Component, OnInit, AfterViewChecked, NgZone, Input } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants';
import { WestwoodHome } from 'src/app/model/westwoodHome';
import { DataService } from 'src/app/service/data.service';
import { KnowledgeCenterService } from '../../service/knowledge-center.service';
import { NewsArticlesService } from '../../service/news-articles.service';
import * as moment from 'moment';
import { ArticlesMainView } from '../../model/article';
declare var $: any;
@Component({
  selector: 'app-knowledge-center',
  templateUrl: './knowledge-center.component.html',
  styleUrls: ['./knowledge-center.component.css']
})
export class KnowledgeCenterComponent implements OnInit {
  @Input() categoryID : any;
  const: Constants;  
  Blogdata:Array <WestwoodHome>=[]; 
  data: ArticlesMainView;
  wpBaseUrl: string;
  constructor(  
    public commonService: CommonService, 
    public constantObj: Constants,
    public dataService: DataService,
    public knowledgeCenterService: KnowledgeCenterService,  
    public articleService: NewsArticlesService    
    ) {
    this.const=constantObj;
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }
  isLoading = false;

  ngOnInit() {  
    this.getArticlesOnLoad();  
  } 

  getArticlesOnLoad() {
    this.isLoading = true;
    let params = 'PageNumber=1&PageSize=3&IsfromNewPage=0';
    if(this.categoryID !== undefined && this.categoryID !== 0 && this.categoryID !== null){
      params = 'PageNumber=1&PageSize=3&IsfromNewPage=0&CategoryID='+this.categoryID;
    }

    
    
    this.articleService.getArticlesOnLoad(params).subscribe((data: ArticlesMainView) => {
      this.data = data; 
      
    }, err => {
      this.dataService.handleError(err);
    });
  }
    
  getBlogs() {
    this.isLoading = true; 
    this.knowledgeCenterService.getBlogData().subscribe((data:Array<WestwoodHome>)=> { 
      this.Blogdata = data;  
      this.isLoading = false;
      //set blog data 
      if(this.Blogdata){
        this.setBlogdata(JSON.parse(this.Blogdata['content']));
      }else{
        $('#blogCon').html('<h3 class="align-self-center">No data available.</h3>');
      } 
    },err => {
      this.isLoading = false; 
      this.dataService.handleError(err);
      $('#blogCon').html('<h3 class="align-self-center">No data available.</h3>');
    });
  }
  
  setBlogdata(blogData){
    let code = '';
    for(var i=0; i<3; i++){
      var thisPost = blogData['items'][i];
      const dateX = moment(thisPost['pubDate']);
      let formattedDate = dateX.format('LL');
      var content = thisPost['content'];
      //remove <p> tags
      content = content.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
      //remove links
      content = content.replace(/<a\b[^>]*>(.*?)<\/a>/i,"");

      //cut off at first <br>
      content = content.substring(0, content.indexOf('<'));
      code += '<div class="col-lg-4"><div class="card homeBlogCard mb-4"><img class="card-img-top" src="'+thisPost['thumbnail']+'" alt="'+thisPost['title']+'"><div class="card-body p-0"><h5 class="card-title text-uppercase pt-4 mb-2">'+thisPost['title']+'</h5> <p class="card-date mb-0">'+formattedDate +'</p> <p class="card-text mb-2">'+content+'...</p> <a href="'+thisPost['link']+'" class="card-link text-uppercase" target="_blank">Read More &raquo;</a> </div> </div> </div>'; 
    }
    $('#blogCon').html(code);
  }

  navigateToUrl(url: string) {
    window.location.href = this.wpBaseUrl + url;
  }

  ngOnDestroy() {
    
  } 
}
