<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-condominium" class="sub">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-6 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
				<h1 class="text-center sub-h2">Condo Insurance</h1>
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
	</div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
	<nav id="breadcrumb">
		<ol class="breadcrumb">
			<li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
		</ol>
	</nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para" class="d-lg-none d-none">
	<div class="container intro-para-homeowners text-center pt-3 pb-5">
		<div class="row justify-content-center">
			<div class="col-md-9 col-xs-9">
				<p><span>Condo and townhome living provides many amenities and lifestyle conveniences.</span> However,
					insurance coverage for your personal property and personal liability are probably not included in
					the amenities. A Westwood condo specialist can help you determine exactly what your condo
					association’s master policy covers (and doesn’t cover!) so that we can help you get the right
					protection.</p>
				<br>
				<span><img src="assets/img/icons/cell-phone.png" alt="cell icon" width="30"> Call us for a quote or
					questions regarding Condo Insurance at<a href="tel:18885436179"> 888.543.6179</a></span>
			</div>
		</div>
	</div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->


<!-- W H Y  C O N D O  I N S U R A N C E   -->

<section>
	<div class="container  pt-3 pb-5">
		<div class="row pt-2">
			<div class="col">
				<h2 class="col text-center"> Why Condo Insurance? </h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line divider" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="assets/img/page-body-img/shutterstock_364917668.jpg" style="width: 100%;"
					alt="Couple in apartment complex" />
			</div>
			<div class="col">
				Your homeowners association’s master policy typically limits coverage to the building exterior,
				walkways, landscapes and common areas. It does not cover your personal belongings and may not cover
				items such as countertops, fixtures and other installations within your unit – nor does it protect you
				from any liability claims against you.
				<br><br>
				The condo association policy also does not protect you from liability claims against you. So, this
				coverage can protect your bank account from the cost of repairing or replacing personal property or
				paying medical and legal fees if someone is hurt in your unit.

			</div>
		</div>
	</div>
</section>

<!-- // W H Y  C O N D O   I N S U R A N C E   -->


<!-- W H A T 'S   I N C L U D E D ... -->

<section class=" bg-secondary" id="whats-included2">
	<div class="container pt-5 pb-5">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">What’s included in Condo Insurance?</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>

		<div class="row pt-2" id="a-bullet-list">
			<ul>
				<li>Repairs to interior walls and built-in fixtures, bookcases and countertops.
				</li>
				<li>Replacement or repairs of personal property including furniture, appliances and clothing.</li>
				<li>Loss Assessment coverage, providing protection for situations where each owner of a shared property
					is held financially responsible for a portion of the cost of damage.</li>
				<li>Medical payments to others injured while in your condo, including payment for legal fees for a
					related claim.</li>
			</ul>
		</div>

		<!-- <div class="row pl-4 pt-4">
			<div class="col">
				<span>
					It’s important to review and understand what your homeowners association master policy covers and
					what it doesn’t
					cover. Once you determine the gaps, you can purchase optional coverage that may not be included in
					your standard
					Homeowners Policy to satisfy your specific needs.
				</span>
			</div>
		</div> -->


		<!-- O N L I N E   Q U O T E -->



		<!-- // O N L I N E   Q U O T E -->
	</div>
</section>

<!-- // W H A T 'S   I N C L U D E D ... -->

<!--  F U L L   C O V E R A G E  D E T A I L S  -->

<section id="see-full-coverage" class="pb-5">
	<div class="container top-border-element pt-5 pb-5">

		<div class="row">
			<div class="col">
				<h2 class="col text-center">full coverage details</h2>
			</div>
		</div>

		<div class="row text-center pt-2 pb-5">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-md-12">
				<nav>
					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
						<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
							role="tab" aria-controls="nav-home" aria-selected="true">Dwelling</a>

						<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact"
							role="tab" aria-controls="nav-contact" aria-selected="false">Personal Property</a>
						<a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab"
							aria-controls="nav-about" aria-selected="false">Additional Living Expenses</a>
						<a class="nav-item nav-link" id="nav-about-tab2" data-toggle="tab" href="#nav-about2" role="tab"
							aria-controls="nav-about2" aria-selected="false">Personal Liability</a>
						<a class="nav-item nav-link" id="nav-about-med-tab" data-toggle="tab" href="#nav-about-med"
							role="tab" aria-controls="nav-about-med" aria-selected="false">Medical Payments to
							Others</a>
						<a class="nav-item nav-link" id="nav-ded-tab" data-toggle="tab" href="#nav-ded" role="tab"
							aria-controls="nav-ded" aria-selected="false">Deductibles</a>
					</div>
				</nav>
				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
						<p>
							This part of your condo homeowner’s policy covers the physical additions and alternations
							that you have made in your condo if they are damaged or destroyed by a covered “general
							peril” which typically includes theft, fire, lightning, windstorm, snow, hail, rain,
							vandalism, malicious mischief and damage by a vehicle or aircraft. (Your policy will have a
							specific list of perils that apply to or are excluded from your coverage.)
							<br><br>
							The recommended amount of coverage should equal the current cost to rebuild the additions
							and alterations that you have made.

						</p>
					</div>

					<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
						<p>
							This part of your condo homeowner’s policy protects your personal belongings such as
							appliances, furniture, electronics, silver and china, books, artwork, clothing, lawn and
							garden equipment, toys and many other items. These items are protected from damage or loss
							from the same “covered perils” as your dwelling.
							<br><br>
							It’s important that your policy has a “replacement cost” endorsement, so your belongings are
							covered at the full replacement cost and not their current value, which may be less.
							<br><br>
							Some personal belongings, such as jewelry, will need to be listed separately, or “scheduled”
							on your policy. Some of these items also may have replacement limits. However, you can
							purchase additional “<a routerLink="/products/jewelry-and-valuables">jewelry and
								valuables</a>” insurance for these items to fully protect them.

						</p>
					</div>
					<div class="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
						<p>
							This part of your condo homeowner’s policy covers living expenses if your condo is
							uninhabitable because of damage by a covered peril such as a fire or storm. This may also
							include living expenses if you must leave your home because of a government-mandated
							evacuation for a covered peril.
							<br><br>
							Reimbursable expenses include alternative housing and increased meal and laundry costs. The
							coverage amount ranges from 10% to 30% of your dwelling coverage or it may be a maximum time
							needed to make your home habitable.

						</p>
					</div>
					<div class="tab-pane fade" id="nav-about2" role="tabpanel" aria-labelledby="nav-about-tab2">
						<p>
							This part of your condo homeowner’s policy covers liability claims against you that are the
							result of injury or negligence that takes place on or off the insured property. The maximum
							amount of coverage for these types of claims is included on your policy. In addition to this
							coverage, many homeowners purchase a personal “<a routerLink="/products/umbrella">umbrella
								policy</a>” to provide extra protection.
							Umbrella policy premiums can be as low as $150 annually for $1 million in coverage.
						</p>
					</div>
					<div class="tab-pane fade" id="nav-about-med" role="tabpanel" aria-labelledby="nav-about-med-tab">
						<p>
							This part of your condo homeowner’s policy covers medical expenses for visitors or guests
							who are injured in an accident on your property. This does not include family members who
							live with you.
							<br><br>
							The maximum amount of coverage for these expenses is included in your policy. Additional
							coverage may be available for an additional premium.

						</p>
					</div>
					<div class="tab-pane fade" id="nav-ded" role="tabpanel" aria-labelledby="nav-ded-tab">
						<p>
							The deductible is the amount you pay toward a settled claim. When your insurance company
							writes a check for the claim, it is decreased by the deductible amount shown on your policy.
							<br><br>
							Deductibles can be increased, resulting in a premium savings; or lowered, resulting in an
							additional premium charge.
							<br><br>
							Deductibles may be different for certain perils depending on the location of your property.
							For example, in coastal locations where windstorm is a threat, the windstorm deductible may
							be higher than the deductible for other covered perils. This can also apply to hurricane,
							tornado and earthquake perils in some states.
							<br><br>
							Everything you need to know about your deductible will be spelled out in your policy
							declaration. Be sure to read it!

						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- // S E E   F U L L   C O V E R A G E  -->


<!-- O P T I O N A L   P O L I C I E S  -->

<section id="whats-included">
	<div class="container pt-5 pb-5">
		<div class="row">
			<div class="col">
				<h2 class="col text-center">Optional Policies</h2>
			</div>
		</div>
		<div class="row text-center pt-2 pb-3">
			<div class="col">
				<img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
			</div>
		</div>
		<div class="row justify-content-center text-center pb-5">
			<div class="col-8">
				There are additional endorsements or policies available for perils not covered on standard condo
				policies. If you have a risk you are unsure about, ask us! Here are some examples where we can help:
			</div>
		</div>
		<div class="row pt-2">
			<div class="col-md-6 col-xs-12">
				<ul class="pl-0">
					<li>Earthquake</li>
					<li>Flood</li>
					<li>Service Line</li>
					<li>Golf Cart</li>
					<li>Personal Articles Floater (PAF)<br> Scheduled Personal Property</li>
				</ul>
			</div>
			<div class="col-md-6 col-xs-12">
				<ul class="pl-0">
					<li>Pet</li>
					<li>Event</li>
					<li>Bicycle</li>
					<li>Equipment Breakdown</li>
					<li>Umbrella</li>
					
				</ul>
			</div>
		</div>
	</div>
</section>

<!-- // O P T I O N A L   P O L I C I E S  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
	<app-knowledge-center></app-knowledge-center>
</section>


<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->