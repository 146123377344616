import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, AfterViewChecked, OnDestroy, NgZone, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { Constants } from '../../Utilities/Constants/constants';
import { Lead } from '../../model/lead';
import { FormGroup } from '@angular/forms';
import { BsLocaleService, defineLocale } from 'ngx-bootstrap';
import { DataService } from '../../service/data.service';
import { EmailProspectValidationService } from '../../service/email-prospect-validation.service';
import { captchaSiteKey, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { Address } from 'src/app/model/address';
import { RouterExtService } from 'src/app/service/router-ext.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';

declare var $: any;
declare var grecaptcha: any;

@Component({
  selector: 'app-validate-email-prospect',
  templateUrl: './validate-email-prospect.component.html',
  styleUrls: ['./validate-email-prospect.component.css']
})
export class ValidateEmailProspectComponent implements OnInit,AfterViewChecked {
  validateCustForm: FormGroup;
  LiveChatImage = '';
  formErrors: any = {};
  captchaRequired = false;
  captchaValid = false;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  isLoading: Boolean = false;
  recaptcha:string;
  badge='Bottomright';
  constructor(
    public router: Router,
    public commonService: CommonService,
    public constantsObj: Constants,
    public custDetails: Lead,
    private localeService: BsLocaleService,
    public dataService: DataService,
    private validateService: EmailProspectValidationService,
    private routerExtService: RouterExtService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
  ) {
    this.LiveChatImage = constantsObj.LiveChatImage;
    //this.siteKey=siteKeyValue;
    var isSafari = this.commonService.isSafariBroswer();
    //debugger;
    this.siteKey = siteKeyValueV3;

    // if (isSafari) {
    //   this.captchaRequired = false;
    //   this.siteKey = siteKeyValueV3;
    // }
    // else {
    //   this.siteKey = siteKeyValue;
    // }
    // if (!this.commonService.isSafariBroswer()) {
    //   $('.grecaptcha-badge').remove()
    // }
    // else {
    //   $('.grecaptcha-badge').css('display', 'block !important');
    //   $('.grecaptcha-badge').css('visibility', 'visible !important');
    // } 
  }

  ngOnInit() {
    this.custDetails = new Lead();
    this.getLeadRefNo();
  }
  ngOnDestroy() {
    this.dataService.leadDetails = this.custDetails;
  }
  onSubmit(validateCustForm) {
    this.isLoading = true;
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(validateCustForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(validateCustForm);
    if (!this.captchaRequired) {
      if (validateCustForm.valid) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
          this.invokeSaveService(validateCustForm, token);
        });
        // if (this.commonService.isSafariBroswer()) {
        //   this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {

        //     this.invokeSaveService(validateCustForm, token);
        //   });
        // }
        // else {
        //   this.invokeSaveService(validateCustForm);
        // }
      } else {
        // this.commonService.updateControls(validateCustForm);
        this.commonService.focusFirstInvalidElement();
        this.isLoading = false;
        return;

      }

    }
    else {

      this.commonService.focusFirstInvalidElement();
      this.isLoading = false;
      return;
    }
  }
  invokeSaveService(validateCustForm: FormGroup, token = '') {

    if (token != '') {
      this.custDetails.reCaptcha = new ReCaptcha();
      this.custDetails.reCaptcha.response = token;
      this.custDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
    }
    this.validateService
      .postLeadInfo(this.custDetails)
      .subscribe((data: Lead) => {
        this.custDetails = data;
        this.custDetails.customerAddress = new Address();
        this.custDetails.mailingAddress = new Address();
        if (this.custDetails.isEmailProspect) {
          this.dataService.leadDetails = this.custDetails;
          this.dataService.leadDetails.isInit = true;
          this.commonService.NavigateToPage('/real-time-quote/cust-info');
        }
        else {
          validateCustForm.reset();

          $([document.documentElement, document.body]).animate({
            scrollTop: $("#messageError").offset().top - 490
          });

        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.dataService.handleError(err);
      });
  }
  ngAfterViewChecked() {
   
  }
  getLeadRefNo() {
    this.route.queryParams.subscribe(params => {
      if (this.custDetails == null)
        this.custDetails = new Lead();
      if (params['Ref'] != undefined)
        this.custDetails.leadID = params['Ref'];
      else if (params['ref'] != undefined)
        this.custDetails.leadID = params['ref'];
    });
  }
  //For google re-CAPTCHA
  handleSuccess(event) {
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }

  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  //End of Google Re-CAPTCHA


}
