<!-- <app-request-an-insurance-quote mainHeading="QUOTE REQUEST" subHeading="false">
</app-request-an-insurance-quote> -->

<!-- A D D R E S S -->

<!-- A D D R E S S -->
<!-- C U S T O M E R   I N F O  -->
<form (ngSubmit)="onSubmit(creditAuthForm)" #creditAuthForm="ngForm" autocomplete="off">
    <section id="rtq-form" class="pb-2 mt-2">
        <div class="container-xl">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-11 ">
                    <div class="z-indexHeader">
                        <div>
                            <h2 class="page-header-text pb-0 mb-0">Use of Consumer Reports</h2>
                            <p class="screen-text-black">Allowing us to check your credit and loss history will ensure we're giving you the
                                most accurate quote. 
                                <b>This credit inquiry is considered a 'soft check' and will not affect your credit
                                    score.</b>
                            </p>
                            <p class="mt-2 mb-0 screen-text-black-bold">Important Notice and Disclosure</p>
                            <p class="screen-text-black">
                                As part of your application for insurance, consumer reports, including credit and
                                insurance loss history reports, may be ordered to help determine your eligibility
                                and premium. Westwood Insurance Agency may also obtain and use a credit-based insurance
                                score developed from information contained in those consumer reports. Westwood Insurance 
                                Agency may use a third party in connection with the development of your
                                insurance score. In addition, your consumer report may be requested and used to
                                determine the price you are charged at renewal should an insurance policy be issued
                                to you. Your credit report or insurance score will not be obtained or reviewed in
                                states where prohibited by law.
                            </p>
                        </div>
                        <div class="card bg-light mb-3">
                            <div class="card-body">
                                <h3 class="section-header-text">Authorization and Consent</h3>
                                <p class="pb-0 mb-0 screen-text-black">
                                    You understand that by checking "YES" below you are providing "written
                                    instructions" to Westwood
                                    Insurance Agency pursuant to the Fair Credit Reporting Act authorizing Westwood
                                    Insurance Agency
                                    to obtain your personal credit report or other information from a consumer
                                    reporting agency.
                                </p>
                                <p class="screen-text-black">
                                    You authorize Westwood Insurance Agency to obtain such information solely to
                                    assess your
                                    eligibility for insurance and the premium you may be charged if eligible.
                                </p>
                                <div class="flex-row mb-3">
                                    <input type="checkbox" name="authorizeCreditCheck" id="applicant-authorize" (change)="updateCreditAuthorization(IscreditAuthorized)"
                                            #IscreditAuthorized="ngModel" style="width: 40px; height: 30px;"
                                            [(ngModel)]="leadDetails.isCreditAuthorized"
                                            [ngModelOptions]="{ updateOn: 'change' }"
                                            [ngClass]="{'active': leadDetails.isCreditAuthorized}"
                                        />
                                        <label class="screen-text-black-bold" style="margin-left: 10px;">
                                            YES. I/We hereby authorize Westwood
                                            Insurance Agency to obtain and analyze personal credit and loss history
                                            for
                                            me/us from a
                                            credit reporting bureau for the purpose of underwriting insurance
                                            products
                                            for
                                            me/us.
                                        </label>
                                </div>

                                <div class="mt-3 col-sm-12 col-md-6 col-lg-5 pl-0"
                                    *ngIf="leadDetails.isCreditAuthorized && !dataService.hideSSN">
                                    <label class="screen-text-black-bold" for="name">
                                        Applicant Social Security Number (SSN)
                                    </label>
                                    <input type="text" class="form-control form-control-lg" id="applicantSSN"
                                        [(ngModel)]="leadDetails.creditAuth.ssn" mask="000-00-0000" minlength="9"
                                        name="applicantSSN" #applicantSSN="ngModel"
                                        [ngClass]="{ 'is-invalid':( ( creditAuthForm.submitted || applicantSSN.touched ) &&
                                        (applicantSSN.hasError('required') || applicantSSN.hasError('minlength') ) )  }"
                                        [required]=" ( creditAuthForm.submitted || applicantSSN.touched ) && (leadDetails.creditAuth.ssn.length >0 && leadDetails.creditAuth.ssn.length < 9)"
                                        [ngModelOptions]="{ updateOn: 'change' }">
                                    <label *ngIf="(applicantSSN.hasError('required') && applicantSSN.touched) "
                                        class="error-message text-nowrap">
                                        {{constantsObj.requiredErrorMessage}}
                                    </label>
                                </div>
                                <div class="mt-3 col-sm-12 col-md-5 col-lg-5 pl-0"
                                    *ngIf="leadDetails.creditAuth.creditAuthorized && leadDetails.creditAuth.ssn !=''  && leadDetails.creditAuth.ssn.length==9 && dataService.hideSSN">
                                    <label for="Social-security">Social security number<span> </span></label>
                                    <input type="text" disabled
                                        class="form-control form-control-sm ssn w-custom-SSN" value="SSN on file">
                                </div>
                            </div>
                        </div>

                        <div class="pt-3 mb-3 col-xs-12 col-md-8 col-lg-7 text-justify pl-0">
                            <label for="name">
                                Enter your name
                                here as the person completing this form
                                <span class="required-span">*</span>
                            </label>
                            <input type="text" autocomplete="creditAuthName" class="form-control" placeholder="Name"
                                maxlength="35" required [(ngModel)]="leadDetails.creditAuth.authorizedName"
                                name="authorizedName" #authorizedName="ngModel" />
                            <label *ngIf="(authorizedName.hasError('required') && authorizedName.touched) "
                                class="error-message text-nowrap">
                                {{constantsObj.requiredErrorMessage}}
                            </label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
                            <label class="ml-0 pl-0">See below for our Privacy Policy</label>
                        </div>
                        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 pl-0">
                            <p class="required-p">*These fields are required</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="bg-white d-sm-block mb-5">
        <div class="container-xl">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn ww-button" aria-label="Go Back to Previous Screen" awPreviousStep
                        tabindex="-1">
                        <i>
                            <fa-icon icon="chevron-left"></fa-icon> <fa-icon icon="chevron-left"></fa-icon>
                        </i>
                        Previous
                    </button>
                </div>
                <div class="col text-right">
                    <button type="submit" class="btn ww-button" aria-label="Go to Next Step" [disabled]="isLoading ? true : null">
                        Next <i>
                            <fa-icon icon="chevron-right"></fa-icon><fa-icon icon="chevron-right"></fa-icon>
                        </i>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <app-model-popup (savePageDetails)="savePageDetails()"></app-model-popup>
</form>
<!-- // C U S T O M E R   I N F O -->