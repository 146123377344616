<app-request-an-insurance-quote mainHeading="QUOTE REQUEST" subHeading="false"></app-request-an-insurance-quote>
<form (ngSubmit)="onSubmit(stateDisclosureForm)" #stateDisclosureForm="ngForm">
    <section id="rtq-form" class="pb-5">
        <div class="container">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <p *ngIf="this.dataService.leadDetails.isServiceUnavailable==false">
                        {{this.dataService.leadDetails.errorMessage}}
                    </p>
                    <span class="pl-3" *ngIf="this.dataService.leadDetails.isServiceUnavailable==false">Important
                        Notice to Our Customers</span>
                    <br>
                    <br>
                    <div class="form-div z-indexHeader">
                        <div class="row">
                            <div class="col text-center">
                                <p>
                                    <b>{{this.leadDetails.disclosureInfo.title}}</b>
                                </p>
                            </div>
                        </div>
                        <div class="row text-justify pl-2 pr-2">
                            <div class="col cust-notice-description">
                                <p>
                                    {{this.leadDetails.disclosureInfo.text}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center p-2">
                <button class="btn" type="submit">
                    <div class="angled-button-sm button-width-210">
                        <span>Disclosure Read</span>
                    </div>
                </button>
            </div>
        </div>
    </section>
</form>