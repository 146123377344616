
export class Controller {
    /* For the first Page*/
    static customerInfo: string = 'CustomerInfo';
    /* For the 2nd Page*/
    static creditAuth: string = 'CreditAuth';
    /* For the 3rd Page*/
    static homeInfo : string = 'HomeInfo';
    /* For the 4th Page*/
    static quoteResultInfo : string = 'QuoteResultInfo';
    /* For the 5th Page*/
    static propertyUnderwriting : string = 'PropUnderwriting';
    static fraudStaement:string='FraudStatement';
    /* For the email prospect validation Page*/
    static validateEmailProspect: string ='ValidateEmailProspect';
    /* For Please contact me*/
    static contactMe : string = 'ContactMe';
    // For Quote delivery
    static quoteDelivery: string='QuoteDelivery';
    //For thank you page
    static Utility:string='Utility';
    static WestwoodHome:string='WestwoodHome';
    static CurrentCustomer:string='CurrentCustomer';
    static ContactUs:string = 'ContactUs';
  static Texting: string = 'Texting';
  static WebsiteAlertInfo: string = 'WestwoodHome';
  static MyQuote: string = 'MyQuote';
  static Article: string = 'Article';
  static lenderChange: string = 'LenderChange';
}
