import { Component, OnInit, OnDestroy, NgZone, ElementRef, ViewChild, Inject, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { DataService } from '../../service/data.service';
import { Constants } from '../../Utilities/Constants/constants';
import { LenderRequestChangeService } from '../../service/lender-request-change.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CurrentCustomer } from '../../model/currentCustomer';
import { CustInfoService } from '../../service/cust-info.service';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModelPopUpLenderRequestMessageComponent } from '../../shared/model-pop-up-lender-request-message/model-pop-up-lender-request-message.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare var grecaptcha: any;
declare function breadcrumbShow(wpBaseUrl: string): any;
declare var $: any;
declare function facIconOne(): any;
declare function facIconTwo(): any;
@Component({
  selector: 'app-lender-requests-changes',
  templateUrl: './lender-requests-changes.component.html',
  styleUrls: ['./lender-requests-changes.component.css'],
  providers: [NgbPopoverConfig]
})
export class LenderRequestsChangesComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public wpBaseUrl: string;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/lrc-header.jpg',
    desktopHeaderText: 'Lender Requests',
    desktopHeaderSubText: "Lenders may use this page to request evidence of insurance and/or make changes to mortgagee information for current insurance policies.",
    mobileHeaderText: 'Lender Requests',
    mobileHeaderSubText: ''
  });
  confirmRequesterEmail: string;
  isOpen = false;
  maxDate = new Date();
  formErrors: any = {};
  isLoading: boolean = false;
  lenderRequestChangeChangeForm: FormGroup;
  captchaRequired = false;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  recaptcha: string;
  badge = 'Bottomright';
  lstStates = [
    {
      stateName: ''
    }
  ];

  noDisplayMsg: string = '';

  @ViewChild(ModelPopUpLenderRequestMessageComponent) childLenderRequest: ModelPopUpLenderRequestMessageComponent;

  @ViewChild('propStreetAddress') propStreetAddress: ElementRef;
  @ViewChild('line2') line: ElementRef;
  @ViewChild('cities') city: ElementRef;
  @ViewChild('states') state: ElementRef;
  @ViewChild('postalCodes') postalCode: ElementRef;

  @ViewChild('mortgageeAddressLine1') mortgageeAddressLine1: ElementRef;
  @ViewChild('mortgageeAddressLine2s') mortgageeAddressLine2: ElementRef;
  @ViewChild('mortgageeCites') mortgageeCity: ElementRef;
  @ViewChild('mortgageeStates') mortgageeState: ElementRef;
  @ViewChild('mortgageePostalCodes') mortgageePostalCode: ElementRef;

  constructor(private router: Router, public commonService: CommonService,
    public dataService: DataService,
    private configToolTip: NgbPopoverConfig,
    private zone: NgZone,
    private route: ActivatedRoute,
    public lenderRequestChangeService: LenderRequestChangeService,
    public lenderRequestChangeDetails: CurrentCustomer,
    public constantsObj: Constants,
    private custInfoService: CustInfoService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string) {
    var isSafari = this.commonService.isSafariBroswer();
    
    // if (true) {
    //   this.captchaRequired = false;
    // }
    // else {
    //   this.siteKey = siteKeyValue;
    // }
    this.captchaRequired = false;
    this.siteKey = siteKeyValueV3;
    // if (!this.commonService.isSafariBroswer()) {
    //   $('.grecaptcha-badge').remove()
    // }
    // else {
    //   $('.grecaptcha-badge').css('display', 'block !important');
    //   $('.grecaptcha-badge').css('visibility', 'visible !important');
    // }
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  ngOnInit() {

    //$("#replacementCostEst2).checked = true;
    // $('.help').tooltip({

    //   title: '<p><p class="text-center">Mortgage Change</p><p>Requested Updates May Include:</p><ul><li>Add new mortgagee clause</li><li>•	Edit existing mortgagee clause (name, address and/or loan number) </li><li>Replace existing mortgagee clause  </li></ul><p>Request Is Defined As:</p> <ul><li>Mutual client is refinancing their home and there is a new mortgagee clause</li> <li>The current mortgagee clause needs to be amended (name, address or loan number)</li></ul> <p>What Will Be Provided?</p> <ul><li>Updated current evidence of insurance (If applicable, renewal evidence of insurance) </li> <li>Payment confirmation (paid In full or balance due receipt)</li> <li>Extended replacement cost coverage % (If applicable) </li></ul><p>Evidence of Insurance</p><p>Requested details:</p><ul><li>Information from current/renewal policy term</li></ul><p>Request Is Defined As:</p><ul><li>Proof of insurance/declarations page</li></ul><p>What Will Be Provided?</p><ul><li>As is copy of current evidence of insurance (if applicable, renewal evidence of insurance) </li><li>Payment confirmation (paid In full or balance due receipt)</li><li>Extended replacement cost coverage % (if applicable)</li></ul>',
    //   html: true,
    //   placement: 'top'
    // });
    this.lenderRequestChangeDetails = new CurrentCustomer();
    this.lenderRequestChangeDetails.requestRCE = false;
    this.lenderRequestChangeDetails.status = "New";
    this.lenderRequestChangeDetails.subStatus = "";
    this.getStates();
  }

  OnDestroy() {

  }

  changingAddressLineOne() {
    facIconOne();
    facIconTwo();
  }


  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;

  }
  handleSuccess(event) {
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = false;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = false;
  }
  //End of Google Re-CAPTCHA 

  parentFunYes() {
    this.postData();
    //window.location.reload();
  }

  parentFunNo() {
    this.childLenderRequest.displayMessage(this.noDisplayMsg, true);
  }

  parentFunOk() {
    window.location.reload();
  }

  onSubmit(lenderRequestChangeChangeForm) {

    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(lenderRequestChangeChangeForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(lenderRequestChangeChangeForm);

    if (!lenderRequestChangeChangeForm.valid) {
      if (!(this.lenderRequestChangeDetails.contactWayMobilePhone || this.lenderRequestChangeDetails.contactWayWorkPhone || this.lenderRequestChangeDetails.contactWayEmail)) {
        this.commonService.focusFirstInvalidElement();
        return;
      }
    }

    // call api to post form  
    if (lenderRequestChangeChangeForm.valid && !this.captchaRequired) {
      this.isLoading = true;
      // if (this.commonService.isSafariBroswer()) {
      this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
        if (token != '') {
          this.lenderRequestChangeDetails.reCaptcha = new ReCaptcha();
          this.lenderRequestChangeDetails.reCaptcha.response = token;
          this.lenderRequestChangeDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
          this.postData();
        }
      });
    } else {

    }
  }
  postData() {
    this.lenderRequestChangeService.SaveLenderRequestChange(this.lenderRequestChangeDetails).subscribe((data: any) => {
      this.isLoading = false;
      this.noDisplayMsg = '';
      if (data) {
        this.lenderRequestChangeDetails.status = data.status;
        this.lenderRequestChangeDetails.subStatus = data.subStatus;

        //Status – Duplicate and SubStatus = New
        if (data.status === 'Duplicate' && data.subStatus === "New") {
          this.childLenderRequest.displayMessage(data.message, false);

          this.noDisplayMsg = 'We are working hard to process your original request. Submitting multiple requests may delay processing. Thank you for your patience.';
        } else
          //Status – Duplicate and SubStatus = Completed
          if (data.status === 'Duplicate' && data.subStatus === "Completed") {
            this.childLenderRequest.displayMessage(data.message, false);
            this.noDisplayMsg = 'Thank you. There is no need to submit anything further.';
          } else
            //Status – Duplicate and SubStatus = InProgress
            if (data.status === 'Duplicate' && data.subStatus === "InProgress") {
              this.childLenderRequest.displayMessage(data.message, true);
            } else
              //Status – Completed.
              if (data.status === 'Completed') {
                this.childLenderRequest.displayMessage(data.message, true);
              } else {
                this.childLenderRequest.displayMessage(data.message, true);
              }

      } else {
        alert("Something went wrong, please try again!");
      }
      //window.location.reload()
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      window.location.reload()
    });
  }
  getStates() {
    this.custInfoService.getStates().subscribe((data: any) => {
      this.lstStates = data.lstStates;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
    });
  }

  SetPropertyAddressDetails(event) {

    this.zone.run(() => {
      if (event.street_number != undefined && event.route != undefined) {
        this.lenderRequestChangeDetails.addressLine1 = event.street_number + ' ' + event.route;
      }
      else if (event.route != undefined) {
        this.lenderRequestChangeDetails.addressLine1 = event.route;
      }
      else if (event.street_number != undefined) {
        this.lenderRequestChangeDetails.addressLine1 = event.street_number;
      }

      this.lenderRequestChangeDetails.addressLine2 = '';
      this.lenderRequestChangeDetails.city = event.locality;
      this.lenderRequestChangeDetails.state = event.admin_area_l1;
      this.lenderRequestChangeDetails.postalCode = event.postal_code || '';

      this.propStreetAddress.nativeElement.value = this.lenderRequestChangeDetails.addressLine1;
      this.line.nativeElement.value = this.lenderRequestChangeDetails.addressLine2;
      this.state.nativeElement.value = this.lenderRequestChangeDetails.state;
      this.city.nativeElement.value = this.lenderRequestChangeDetails.city;
      this.postalCode.nativeElement.value = this.lenderRequestChangeDetails.postalCode;

    });
  }

  SetPropertyAddressDetailsMortgagee(event) {
    this.zone.run(() => {
      if (event.street_number != undefined && event.route != undefined) {
        this.lenderRequestChangeDetails.mortgageeAddressLine1 = event.street_number + ' ' + event.route;
      }
      else if (event.route != undefined) {
        this.lenderRequestChangeDetails.mortgageeAddressLine1 = event.route;
      }
      else if (event.street_number != undefined) {
        this.lenderRequestChangeDetails.mortgageeAddressLine1 = event.street_number;
      }

      this.lenderRequestChangeDetails.mortgageeAddressLine2 = '';
      this.lenderRequestChangeDetails.mortgageeCity = event.locality;
      this.lenderRequestChangeDetails.mortgageeState = event.admin_area_l1;
      this.lenderRequestChangeDetails.mortgageePostalCode = event.postal_code || '';

      this.mortgageeAddressLine1.nativeElement.value = this.lenderRequestChangeDetails.mortgageeAddressLine1;
      this.mortgageeAddressLine2.nativeElement.value = this.lenderRequestChangeDetails.mortgageeAddressLine2;
      this.mortgageeState.nativeElement.value = this.lenderRequestChangeDetails.mortgageeState;
      this.mortgageeCity.nativeElement.value = this.lenderRequestChangeDetails.mortgageeCity;
      this.mortgageePostalCode.nativeElement.value = this.lenderRequestChangeDetails.mortgageePostalCode;
    });
  }
  hideDatePicker(dp) {
    dp.hide();
  }
  ngAfterViewInit() {
    //this.lenderRequestChangeDetails.requestRCE = false;
    breadcrumbShow(this.wpBaseUrl);
  }
  ngAfterViewChecked() {
    //this.lenderRequestChangeDetails.requestRCE = false;
  }

}
