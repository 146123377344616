import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { WestwoodHome } from '../model/westwoodHome';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeCenterService {

  url: string;
  constructor(private dataService: DataService) { }  

  /*Get blog Details  */
  getBlogData(): Observable<Array<WestwoodHome>> {
    this.url = Controller.WestwoodHome + '/' + Action.Bloginfo; 
    return this.dataService.getAll(this.url); 
  } 
}
