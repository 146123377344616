import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit,AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/claims-header-f270603dc29a345f0ae7.jpg',
    desktopHeaderText: "We're Here to Help Before, During and After a Loss",
    desktopHeaderSubText: "If the unexpected happens, we are here to guide you every step of the way. We can help with questions about filing a claim, reducing out-of-pocket expenses, and getting back on your feet faster.",
    mobileHeaderText: 'Claims',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }

  
  ngAfterViewInit()
  {
    breadcrumbShow();
  }

}
