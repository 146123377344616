import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Texting } from '../model/texting';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class OptoutTextMsgesService {

  url: string;
  constructor(private dataService: DataService) { }
  /*Create Optout */
  SaveoptoutChanges(data: Texting): Observable<Texting> {
    this.url = Controller.Texting + '/' + Action.Optout;
    return this.dataService.postData(data, this.url);
  }
}
