import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants'; 
import { DataService } from 'src/app/service/data.service';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { faChevronRight, faLevelDownAlt, faChevronLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
declare function breadcrumbShow(): any;
declare var $;


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit, AfterViewInit {

  const: Constants;
  
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/about-us-header.jpg',
    desktopHeaderText: 'About Us',
    desktopHeaderSubText: "Westwood is one of the largest personal lines agencies in the United States. Since 1952, we’ve helped more than a million people protect what matters most.",
    mobileHeaderText: 'About Us',
    mobileHeaderSubText: ''
  });
  constructor(
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService  
  ) {
    this.const = constantObj;
  }
  isLoading = false;

  ngOnInit() {
    
  }

  

  ngAfterViewInit() {
    breadcrumbShow();
    //Run carousel
    $('.carousel').carousel()
  }

}
