import {
  Constants,
  loanType,
  policyTypeID,
} from "./../../Utilities/Constants/constants";
import {
  Component,
  OnInit,
  AfterViewChecked,
  Injectable,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { CommonService } from "../../service/common.service";
import { Lead } from "src/app/model/lead";
import { LoanInformation } from "src/app/model/loanInformation";
import { PropertyUnderwriting } from "src/app/model/propertyUnderwriting";
import { DataService } from "src/app/service/data.service";
import { FormGroup, NgModel } from "@angular/forms";
import { Router } from "@angular/router";
import { FraudStatementService } from "src/app/service/fraud-statement.service";
import { RouterExtService } from "src/app/service/router-ext.service";
import { ModelPopupComponent } from "../../shared/model-popup/model-popup.component";
import { WizardComponent } from "angular-archwizard";
declare var $: any;

@Component({
  selector: "app-loan-info",
  templateUrl: "./loan-info.component.html",
  styleUrls: ["./loan-info.component.css"],
})
export class LoanInfoComponent implements OnInit {
  underWritingForm: FormGroup;
  LiveChatImage = "";
  formErrors: any = {};
  labelString: string = "";
  errorInClaims: boolean = false;
  height: any;
  @ViewChild(ModelPopupComponent) popUp: ModelPopupComponent;
  @Input('mainWizard') public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();
  public wpBaseUrl: string;

  constructor(
    public router: Router,
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService,
    public loanService: FraudStatementService,
    // public leadDetails: Lead,
    private routerExtService: RouterExtService
  ) {
    this.LiveChatImage = constantObj.LiveChatImage;
    this.wpBaseUrl = this.dataService.wpWebsiteUrl
  }
  PropertyUnderwriting: PropertyUnderwriting;
  _propObj = new PropertyUnderwriting();
  loanDetails: LoanInformation;
  isLoading = false;
  isRenters: boolean = false;
  swimmingPoolNoneID: string;
  isIEOrEdge: boolean;
  isEddAgencyRequired: boolean = false;
  ngOnInit() {
    this.commonService.ScrollToRTQForm();
    // this.commonService.SaveLeadHistory();
    // this.dataService.leadHistory.pageNumber = 6;
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    // this.leadDetails = this.dataService.leadDetails;
    this.isEddAgencyRequired = this.leadDetails.eddAgencyRequiredFlag;
    this.isLoading = true;
    //#set the visibility of Loan Information
    if (this.leadDetails.policyTypeID == policyTypeID.HO4)
      this.isRenters = true;
    //#R51.0 set the default value for loan transaction radio button
    if (this.leadDetails.loanType == loanType.Owner)
      this.leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction =
        false;
    else if (this.leadDetails.loanType == loanType.Purchase)
      this.leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction =
        true;
    //#end of setting up  the default value for loan transaction radio button
    this.loanDetails = JSON.parse(
      JSON.stringify(this.leadDetails.propertyUnderwriting.loanInformation)
    );
    this.leadDetails.propertyUnderwriting.loanInformation.eddFlag = null;
    // this.dataService.leadHistory.preValue = JSON.parse(
    //   JSON.stringify(this.leadDetails)
    // );
    this.isLoading = false;

    if (this.loanDetails.eddEmail == null || this.loanDetails.eddEmail == "")
      this.loanDetails.eddEmail = this.leadDetails.email;
  }
  impersonateLead() {
    if (this.leadDetails == null) this.leadDetails = new Lead();
    this.leadDetails.leadID = "65601815-4F6B-4247-9ED6-71492E3B2F01";
    this.leadDetails.loanType = loanType.Purchase; //Refinanace or H04
  }
  hideorShowEDDEmail() {
    if (this.isEddAgencyRequired) {
      if (this.leadDetails.propertyUnderwriting.loanInformation.eddFlag == true) {
        if (
          this.leadDetails.propertyUnderwriting.loanInformation.eddEmail == "" ||
          this.leadDetails.propertyUnderwriting.loanInformation.eddEmail == null
        ) {
          this.leadDetails.propertyUnderwriting.loanInformation.eddEmail = this.leadDetails.email;
        }
      } else {
        this.leadDetails.propertyUnderwriting.loanInformation.eddEmail = "";
      }
    }
  }
  onSubmit(underWritingForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.updateControls(underWritingForm);
    this.isLoading = true;
    // this.leadDetails.propertyUnderwriting.loanInformation = this.loanDetails;
    // this.leadDetails.propertyUnderwriting.loanInformation.eddFlag = this.loanDetails.eddFlag;
    if (underWritingForm.valid) {
      this.commonService.ScrollToRTQForm();
      this.dataService.leadHistory.noUpdate = underWritingForm["form"].pristine;
      if (!this.leadDetails.eddAgencyRequiredFlag) {
        this.leadDetails.propertyUnderwriting.loanInformation.eddFlag = null;
      }
      this.loanService.postLeadInfo(this.leadDetails).subscribe(
        (data: Lead) => {
          this.leadDetails.propertyUnderwriting.loanInformation =
            data.propertyUnderwriting.loanInformation;
          this.isLoading = false;

          //this.leadDetails = new Lead();
          // this.dataService.leadDetails = new Lead();
          // this.leadUpdateEvent.emit(new Lead());

          this.commonService.NavigateToPage("/real-time-quote/quote-thank-you");
        },
        (err) => {
          this.isLoading = false;
          this.leadDetails = new Lead();
          this.leadUpdateEvent.emit(new Lead());
          this.dataService.handleError(err);
        }
      );
    } else {
      if (!underWritingForm.valid) {
        //this.setFormStatus(this.leadDetails.propertyUnderwriting.);
        this.commonService.focusFirstInvalidElement();
        this.isLoading = false;
        return;
      }
    }
  }
  savePageDetails() {
    this.isLoading = true;
    this.leadDetails.propertyUnderwriting.loanInformation = this.loanDetails;
    this.commonService.CreateLeadHistoryObj(6, this.leadDetails);
    this.loanService.postLeadInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.commonService.SaveLeadHistoryFromContactBtn();
        this.leadDetails.propertyUnderwriting = data.propertyUnderwriting;
        this.isLoading = false;
        this.commonService.NavigateToPage("/real-time-quote/contact");
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }

  ngOnDestroy() {
    this.leadDetails.propertyUnderwriting.loanInformation = this.loanDetails;
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }

  _keyUp(event: any) {
    var val = event.target.value.replace(/\$|\,/g, "");
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) ||
      val.length >= 8
    ) {
      event.preventDefault();
    }
    return true;
  }

  numberWithCommas(x) {
    if (x.target.value.toString() !== "" && x.target.value.toString() !== "$ 0") {
      x = x.target.value.toString().replace(/\$|\,/g, "");
      x = parseInt(x).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      this.loanDetails.amount = x;
    } else {
      x.target.value = '';
      this.leadDetails.propertyUnderwriting.loanInformation.amount = '';
    }
    return x;
  }
  resetForm(underWritingForm, option) {
    // this.leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction = !this.leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction;
    if(option === 'Y') {
      this.leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction = true;  
    } else {
      this.leadDetails.propertyUnderwriting.loanInformation.havingActiveTransaction = false;  
    }

    if (!this.loanDetails.havingActiveTransaction) {
      this.loanDetails.amount = null;
      this.loanDetails.officerName = "";
      this.loanDetails.officerEmail = "";
      this.loanDetails.officerPhone = "";
      this.loanDetails.loanProcessorEmail = "";
      this.loanDetails.loanProcessorName = "";
      this.loanDetails.loanProcessorPhoneNumber = "";
    } else {
      this.loanDetails.amount = null;
      this.loanDetails.mortgageName = "";
    }
    underWritingForm["control"].controls["txtLoanAmount"].reset();
  }
  ngAfterViewChecked() {
    this.height = $(".container").height();
    this.height = this.height + 30;
  }

  navigateToUrl(url: string) {
    window.location.href = this.wpBaseUrl + url;
  }

  checkPhone(event: any) {
    if(event.target.value === '(') {
      event.target.value = '';
    }
  }
}
