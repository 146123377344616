import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-resource-center',
  templateUrl: './resource-center.component.html',
  styleUrls: ['./resource-center.component.css']
})

export class ResourceCenterComponent implements OnInit, AfterViewInit {
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/resource-center-header.jpg',
    desktopHeaderText: 'Resource Center',
    desktopHeaderSubText: "Tools and resources to help you better understand your insurance needs and make sound, informed decisions.",
    mobileHeaderText: 'Resource Center',
    mobileHeaderSubText: ''
  });

  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {

  }

  ngAfterViewInit() {
    breadcrumbShow();
  }


}
