<!-- I N S U R A N C E   P R O D U C T S  -->
<section id="homeBuckets">
    <div class="container">
        <div class="row pt-4 mt-5">
            <div class="col">
                <h2 class="text-center">Insurance Products For All Your Needs</h2>
            </div>
        </div>
        <div class="row text-center">
            <div class="col">
                <img src="assets/img/orange-divider.png" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-5ths col-12 homeProductBucketCon">
                <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
                    <img class="img-fluid" src="assets/img/icon-home.png" alt="">
                    <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Home</p>
                    <a class="stretched-link show-cursor"
                        (click)="commonService.NavigateToPage('/products/homeowners')"></a>
                </div>
            </div>
            <div class="col-md-5ths col-12 homeProductBucketCon">
                <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
                    <img class="img-fluid" src="assets/img/icon-renters.png" alt="">
                    <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Renters</p>
                    <a class="stretched-link show-cursor"
                        (click)="commonService.NavigateToPage('/products/renters')"></a>
                </div>
            </div>
            <div class="col-md-5ths col-12 homeProductBucketCon">
                <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
                    <img class="img-fluid" src="assets/img/icon-auto.png" alt="">
                    <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Auto</p>
                    <a class="stretched-link show-cursor" (click)="commonService.NavigateToPage('/products/auto')"></a>
                </div>
            </div>
            <div class="col-md-5ths col-12 homeProductBucketCon">
                <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
                    <img class="img-fluid" src="assets/img/icon-flood.png" alt="">
                    <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Flood</p>
                    <a class="stretched-link show-cursor" (click)="commonService.NavigateToPage('/products/flood')"></a>
                </div>
            </div>
            <div class="col-md-5ths col-12 homeProductBucketCon">
                <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
                    <img class="img-fluid" src="assets/img/icons/small-commercial-tile.png" alt="">
                    <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Small Business</p>
                    <a class="stretched-link show-cursor"
                        (click)="commonService.NavigateToPage('/products/small-business')"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <a (click)="commonService.NavigateToPage('/products')" id="seeMoreProductsLink"
                    class=" p-3 text-uppercase functionLink" style="cursor: pointer;">See More Products +</a>
            </div>
        </div>
    </div>
</section>
<!-- // I N S U R A N C E   P R O D U C T S  -->