import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Observable } from 'rxjs'; 
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';
import { Contactus } from '../model/contactus';
import { RequestDetails } from '../model/requestSetails';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  url: string;
  constructor(private dataService: DataService) { } 
  
  /* Get Lead  Details */
  postContactpageInfo( data: Contactus): Observable<Array<Contactus>> {
   
    this.url = Controller.ContactUs + '/' + Action.sendMail; 
    return this.dataService.postData(data, this.url); 
  } 

   /* Get Lead  Details */
   postPartnerAPipageInfo( data: RequestDetails): Observable<Array<Contactus>> {
   
    this.url = Controller.ContactUs + '/' + Action.RequestDetails; 
    return this.dataService.postData(data, this.url); 
  } 
}
