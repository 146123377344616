import { Lead } from "./lead";

export class LeadHistory {
    public preValue: Lead = new Lead();
    public postValue:Lead=new Lead();
    public pageNumber:number;
    public noUpdate?:boolean;
    constructor() {
        this.preValue = new Lead();
    }
}