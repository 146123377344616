import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NgxCurrencyModule } from "ngx-currency";
import { AppRoutingModule } from "./app-routing.module";
import { NgxPaginationModule } from "ngx-pagination"; //WSTW-5641 to implement prev and next buttons
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { PublicModule } from "./public/public.module";
import { SharedModule } from "./shared/shared.module";
import { CustomMinDirective } from "./directive/custom-min-validator.directive";
import { ContactEmailValidatorDirective } from "./directive/contact-email-validator.directive";
import { HomeInfoDecimalDirective } from "./directive/only-decimal.directive";
import { DateValidateForEstCloseDateDirective } from "./directive/date-validate-for-est-close-date.directive";
import { ValidatePhoneTypesDirective } from "./directive/validate-phone-types.directive";
import { MyAutofocusDirective } from "./directive/my-autofocus.directive";
import { NoAutocompleteDirective } from "./directive/no-autocomplete.directive";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { Constants } from "./Utilities/Constants/constants";
import {
  ConfigFactory,
  ConfigService,
  API_BASE_URL,
  captchaSiteKey,
  homeURL,
  captchaSiteKeyV3,
  captchaSecretKeyV3,
  gAnalyticsKey,
} from "./service/config.service";
import { CommonService } from "./service/common.service";
import { Lead } from "./model/lead";
import { CurrentCustomer } from "./model/currentCustomer";
import { Texting } from "./model/texting";
import { Contactus } from "./model/contactus";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { RouterExtService } from "./service/router-ext.service";
import {
  BsModalService,
  ModalBackdropComponent,
  ModalModule,
} from "ngx-bootstrap/modal";
import { UrlSerializer } from "@angular/router";
import { LowerCaseUrlSerializer } from "./Utilities/lower-case-url-serializer";
///import { CookieService } from "ngx-cookie-service";
import { CSRFTokenService } from "./service/csrftoken.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "./service/interceptor.service";
import { CustomerQuoteSearch } from "./model/customerQuoteSearch";
import { CustomerQuoteSearchService } from "./service/customer-quote-search.service";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FaIconsModule } from "./fa-icons.module";
import { LenderChanges } from "./model/lenderChanges";
import { UploadFile } from "./model/uploadFiles";
import { WindowRefService } from "./service/window-ref.service";
import { MakeApaymentService } from "./service/make-apayment.service";
import { UniversalInterceptor } from "./service/universal-interceptor.interceptor";
export function configServiceFactory(configService: ConfigService): Function {
  return () => configService.load(window["base-href"] + "/assets/Config.json");
}
@NgModule({
  declarations: [
    AppComponent,
    ContactEmailValidatorDirective,
    HomeInfoDecimalDirective,
    MyAutofocusDirective,
    NoAutocompleteDirective,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    PublicModule,
    SharedModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxCurrencyModule,
    //FormsModule,
    NgbModule,
    BrowserAnimationsModule,
    NgbPaginationModule,
    BsDatepickerModule.forRoot(),
    RecaptchaV3Module,
    FontAwesomeModule,
  ],
  providers: [
    RouterExtService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    WindowRefService,
    MakeApaymentService,
    Contactus,
    CustomerQuoteSearch,
    CustomerQuoteSearchService,
    CSRFTokenService,
    CommonService,
    ///CookieService,
    Constants,
    Lead,
    CurrentCustomer,
    LenderChanges,
    UploadFile,
    Texting,
    CustomMinDirective,
    ConfigService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptorService,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [ConfigService],
      multi: true,
    },

    {
      provide: "APP_BASE_HREF",
      useValue: window["base-href"],
    },
    {
      provide: "CONFIGPATH",
      useValue: window["base-href"] + "/assets/Config.json",
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: getConfig,
    //   multi: true,
    //   deps: [ConfigService, 'CONFIGPATH']
    // },
    { provide: "APIURL-VAR", useValue: "API_BASE_URL" },
    { provide: "WEBURL-VAR", useValue: "WEB_BASE_URL" },

    { provide: "CAPTCHASITE-VAR", useValue: "captchaSiteKey" },
    {
      provide: captchaSiteKey,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "CAPTCHASITE-VAR"],
    },
    { provide: "CAPTCHASITEV3-VAR", useValue: "captchaSiteKeyV3" },
    {
      provide: captchaSiteKeyV3,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "CAPTCHASITEV3-VAR"],
    },
    {
      provide: homeURL,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "homeURL-VAR"],
    },
    { provide: "gAnalyticsKey-VAR", useValue: "gAnalyticsKey" },
    {
      provide: gAnalyticsKey,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIGPATH", "gAnalyticsKey-VAR"],
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "captchaSiteKeyV3" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
