<div role="main" class="mt-3">
    <aw-wizard [navBarLayout]="'large-filled-symbols'" [awNavigationMode] navigateBackward="allow"
        navigateForward="visited" #mainWizard>
        <aw-wizard-step stepTitle="About Yourself" stepId="1" [canExit]="canExitStep1" [canEnter]="enterStep1">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep.completed">1</span>
                <span *ngIf="wizardStep.completed">
                    <fa-icon icon="check"></fa-icon>
                </span>
            </ng-template>
            <app-customer-info [leadDetails]="leadDetails" (leadUpdateEvent)="updateLead($event)" [mainWizard]="mainWizard"></app-customer-info>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Authorization" [navigationSymbol]="{ symbol: '2' }" [canExit]="canExitStep2" [canEnter]="enterStep2" *ngIf="leadDetails.isAuthRequired">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep.completed">2</span>
                <span *ngIf="wizardStep.completed">
                    <fa-icon icon="check"></fa-icon>
                </span>
            </ng-template>
            <app-credit-auth [leadDetails]="leadDetails" (leadUpdateEvent)="updateLead($event)" [mainWizard]="mainWizard"></app-credit-auth>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="About Your Home" [navigationSymbol]="{ symbol: leadDetails.isAuthRequired ? '3' : '2' }" [canExit]="canExitStep3" [canEnter]="enterStep3">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep.completed">{{leadDetails.isAuthRequired ? 3 : 2}}</span>
                <span *ngIf="wizardStep.completed">
                    <fa-icon icon="check"></fa-icon>
                </span>
            </ng-template>
            <app-home-info [leadDetails]="leadDetails" (leadUpdateEvent)="updateLead($event)" [mainWizard]="mainWizard"></app-home-info>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="At the Property" [navigationSymbol]="{ symbol: leadDetails.isAuthRequired ? '4' : '3' }" [canExit]="canExitStep4" [canEnter]="enterStep4">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep.completed">{{leadDetails.isAuthRequired ? 4 : 3}}</span>
                <span *ngIf="wizardStep.completed">
                    <fa-icon icon="check"></fa-icon>
                </span>
            </ng-template>
            <app-underwriting [leadDetails]="leadDetails" (leadUpdateEvent)="updateLead($event)" [mainWizard]="mainWizard"></app-underwriting>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Your Quote" [navigationSymbol]="{ symbol: leadDetails.isAuthRequired ? '5' : '4' }" [canExit]="canExitStep5" [canEnter]="enterStep5">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep.completed">{{leadDetails.isAuthRequired ? 5 : 4}}</span>
                <span *ngIf="wizardStep.completed">
                    <fa-icon icon="check"></fa-icon>
                </span>
            </ng-template>
            <app-quote-message [leadDetails]="leadDetails" (leadUpdateEvent)="updateLead($event)" [mainWizard]="mainWizard"></app-quote-message>
        </aw-wizard-step>

        <aw-wizard-step stepTitle="Finish" [navigationSymbol]="{ symbol: leadDetails.isAuthRequired ? '6' : '5' }" [canExit]="canExitStep6" [canEnter]="enterStep6">
            <ng-template awWizardStepSymbol let-wizardStep="wizardStep">
                <span *ngIf="!wizardStep.completed">{{leadDetails.isAuthRequired ? 6 : 5}}</span>
                <span *ngIf="wizardStep.completed">
                    <fa-icon icon="check"></fa-icon>
                </span>
            </ng-template>
            <app-loan-info [leadDetails]="leadDetails" (leadUpdateEvent)="updateLead($event)" [mainWizard]="mainWizard"></app-loan-info>
        </aw-wizard-step>
    </aw-wizard>
</div>