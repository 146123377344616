import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;

@Component({
  selector: 'app-small-commercial',
  templateUrl: './small-commercial.component.html',
  styleUrls: ['./small-commercial.component.css']
})
export class SmallCommercialComponent implements OnInit, AfterViewInit {
  categoryID: number = 3;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/iStock-1003743968Restaurant.jpg',
    desktopHeaderText: 'Small Business Insurance',
    desktopHeaderSubText: "Your business isn't just like everyone else's, so why should your insurance be any different? Get a quote tailored to your needs and your industry.",
    mobileHeaderText: 'Small Business Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="https://www.wh-app.io/westwood/westwood-home-page" target="_blank" class=" btn banner-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://www.wh-app.io/westwood/westwood-home-page" target="_blank" class=" btn banner-button">Get a Quote</a>'
  });

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

}
