import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective {
  constructor(private el: ElementRef, private model:NgModel) {
  }
  //Register for KeyDown event
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this._keyUp(event);
  }
  //Register for Blur event
  @HostListener('blur', ['$event'])
  onBlur(event) {
    this.formatCurrency(event);
  }
  formatCurrency(num1) {   
    var num = num1.target.value;
    num = num.toString().replace(/\$|\,/g, '');
    if(num==null || num=="")
    return;
    if (isNaN(num))
      num = "0";
    var sign = (num == (num = Math.abs(num)));
    num = (parseFloat(num).toFixed(2));

    var arrayAmount = num.split(".");
    var cents = arrayAmount[1];
    num = arrayAmount[0];
    //num=parseInt(num).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num = num.substring(0, num.length - (4 * i + 3)) + ',' +
        num.substring(num.length - (4 * i + 3));
var result=(((sign) ? '' : '-') + '$' + num + '.' + cents);
        this.model.valueAccessor.writeValue(result); 
  }
  _keyUp(event: any) {
    var val = event.target.value.replace(/\$|\,/g, '');
    var keyCode=event.which;
    if(keyCode==9 || keyCode==8)
    return;
    // 46,8
    if (keyCode == 46) {
      if (event.key == ".") {
        if (val.indexOf(".") != -1) {
          event.preventDefault();
        }
      }
    }
    
    if (keyCode >= 96 && keyCode <= 105) {
      // Numpad keys
      keyCode -= 48;
  }
    var charStr = String.fromCharCode(keyCode);
    if (/[a-zA-Z]/i.test(charStr))
    {
      event.preventDefault();
    }
    if (!((keyCode >= 48 && keyCode <= 57) || keyCode != 46 || keyCode != 8)) {
      event.preventDefault();
    }
    if (val.indexOf(".") != -1) {
      if (val.split(".")[0].length >= 5 && val.split(".")[1].length >= 2) {
        event.preventDefault();
      }
    }
    else {
      if (val.length >= 5) {
        if (val.indexOf(".") >= 0 && event.key == ".")
          event.preventDefault();
        else if (event.key != "." && keyCode!=8) {
          event.preventDefault();
        }
      }
    }
  }
}
