import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants';
import { PolicyPageInfo } from 'src/app/model/policyPageInfo';
import { DataService } from 'src/app/service/data.service';
import { MyPolicyDocumentsService } from '../../service/my-policy-documents.service';
declare function breadcrumbShow(wpBaseUrl: string): any;
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';

@Component({
  selector: 'app-my-policy-documents',
  templateUrl: './my-policy-documents.component.html',
  styleUrls: ['./my-policy-documents.component.css']
})
export class MyPolicyDocumentsComponent implements OnInit, AfterViewInit {
  const: Constants;
  public wpBaseUrl: string;
  PolicyPageInfos: Array<PolicyPageInfo> = [];
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'mpd-header.86aa62c41a9c5868a7f1.jpg',
    desktopHeaderText: 'My Policy Documents',
    desktopHeaderSubText: "Getting a copy of your policy is easy!",
    mobileHeaderText: 'Get a Copy of Your Policy',
    mobileHeaderSubText: ''
  });

  constructor(
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService,
    public makeApaymentService: MyPolicyDocumentsService,
  ) {
    this.const = constantObj;
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {

    this.getMakeAPaymentValues();
  }


  getMakeAPaymentValues() {

    this.makeApaymentService.getMakeAPaymentInfo().subscribe((data: Array<PolicyPageInfo>) => {
      this.PolicyPageInfos = data;
    }, err => {
      this.dataService.handleError(err);
    });
  }

  ngOnDestroy() {

  }
  ngAfterViewInit() {
    breadcrumbShow(this.wpBaseUrl);
  }
}
