export class User {
    public userId: string;
    public firstName: string
    public lastName: string
    public emailAddress: string
    public faxNumber: string
    public phoneNumber: string
    public licenseNo: string
    public salesAgentPhoto : string;
    public residenceState: string;
    public isActive:boolean;
    constructor() {
        this.userId='';
        this.firstName='';
        this.lastName='';
        this.emailAddress='';
        this.faxNumber='';
        this.faxNumber='';
        this.phoneNumber='';
        this.licenseNo='';
        this.salesAgentPhoto = '';
        this.residenceState = '';
        this.isActive=false;
    }
}