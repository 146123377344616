<!-- <app-request-an-insurance-quote
 mainHeading="Thank you"
 subHeading="false"
 ></app-request-an-insurance-quote> -->
<!-- //  I N T R O    -->
<section id="rtq-form" class="pb-5">
    <div class="container-xl">
        <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
        <div class="row justify-content-center">
            <div class="col-md-12 col-xs-12" *ngIf="!this.dataService.leadDetails.isServiceUnavailable">
                <div class="z-indexHeader">
                    <div class="row pt-3 text-center">
                        <div class="col pt-2">
                            <p style="font-size: 18px;">Thank you for choosing Westwood Insurance Agency.</p>
                            <label class="input-group-label font-bold">Your agent will contact you shortly to address any
                                questions and</label> <br />
                            <label class="input-group-label font-bold">discuss the next steps. If you have immediate questions,
                                please</label> <br />
                            <label class="input-group-label font-bold">call
                                <a
                                    href="tel:{{commonService.formatPhoneNumber(salesAgent.phoneNumber)}}">{{salesAgent.phoneNumber}}.</a>
                            </label> <br />
                            <label class="input-group-label font-bold">Monday - Friday from 8 AM to 7 PM CST.</label> <br />
                        </div>
                    </div>
                    <div class="jumbotron divDarkBackground mt-2">
                        <img src="../../../assets/img/icon-auto-white.png" width="20%" alt="car-image" />
                        <h2 class="upper-case white-text">We also offer auto insurance</h2>
                        <h2 class="white-text text-transform-none">Click here to see how much you can save!</h2>
                        <a class="btn ww-button" target="_blank" (click)="child.importantNotice($event, autoUrl)">Get Started</a>
                    </div>

                    <div class="mt-2 text-center">
                        <h2 class="text-transform-none">As your full-service agency, we've got you covered.</h2>
                        <p>Westwood Insurance Agency partners with leading insurance companies to help protect what's
                            <br />
                            important to you. Click products below to learn more.
                        </p>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6 pt-2"
                            *ngFor="let prod of additionalProducts; let i = index">
                            <a class="show-cursor" (click)="child.importantNotice($event, prod.link)" href="#"
                                *ngIf="prod.fileURL!=''">
                                <img [src]="prod.fileURL" height="54px" width="258px"
                                    class="img-responsive addProduct" />
                            </a>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col" *ngIf="this.dataService.leadDetails.isServiceUnavailable">
                            <p class="font-weight-bold header-blue mb-0 pb-0 text-sm-center p-2"
                                [innerHTML]="dataService.leadDetails.errorMessage">
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->