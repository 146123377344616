<!-- H E A D E R   I M G   -->
<!-- <section id="header-img-products" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">INSURANCE PRODUCTS WE OFFER</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->

<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->
<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->
<!-- I N T R O   P A R A G R A P H  -->
<!-- <section id="intro-para" >
  <div class="container text-center pt-3 pb-5">
    <div class="row justify-content-center">
      <div class="col-md-9 col-xs-9">
        <p><span>You never know what tomorrow will bring. <br>Let’s work together to find a solution that’s
            right
            for you.</span><br> Westwood works with leading
          insurance companies to offer a full line of products ready to protect you financially from damage or
          loss of your property and possessions.
        </p>
      </div>
    </div>
  </div>
</section> -->
<!-- // I N T R O   P A R A G R A P H  -->
<!-- W H A T   I N S U R A N C E    P R O D U C T S ... -->

<section id="what-ins" class="d-none d-lg-block">
  <div class="container   what-ins pt-3 pb-5">

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/homeowners">
              <img src="assets/img/icons/icon-home.png" alt="Home Icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/homeowners">
              <h5>Home</h5>
            </a>
            <p>
              Protect your financial investment in your home and its contents.<br>
              <a routerLink="/products/homeowners">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/condo">
              <img src="assets/img/icons/condo.png" alt="Condo Icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/condo">
              <h5>Condo </h5>
            </a>
            <p>
              Cover your unit and personal property for damage, theft, liability and more.<br>
              <a routerLink="/products/condo">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/renters">
              <img src="assets/img/icons/icon-renters.png" alt="Renters Insurance Icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/renters">
              <h5>Renters </h5>
            </a>
            <p>
              Enjoy peace of mind knowing that your personal belonging are insured.<br>
              <a routerLink="/products/renters">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/auto">
              <img src="assets/img/icons/icon-auto.png" alt="Auto Insurance icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/auto">
              <h5>Auto </h5>
            </a>
            <p>
              Find affordable coverage tailored to your needs so you can drive with confidence.<br>
              <a routerLink="/products/auto">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/flood">
              <img src="assets/img/icons/icon-flood.png" alt="Flood Insurance Icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/flood">
              <h5>Flood </h5>
            </a>
            <p>
              Not included in homeowner insurance! Reduce the financial risk of damage or loss from this
              common threat.<br>
              <a routerLink="/products/flood">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/earthquake">
              <img src="assets/img/icons/icon-earthquake.png" alt="Earthquake Insurance Icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/earthquake">
              <h5>Earthquake </h5>
            </a>
            <p>
              Not included in homeowner insurance! Consider a separate earthquake policy if earthquakes are
              likely where you live.<br>
              <a routerLink="/products/earthquake">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/umbrella">
              <img src="assets/img/icons/icon-umbrella.png" alt="Umbrella Insurance Icon" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/umbrella">
              <h5>Umbrella </h5>
            </a>
            <p>
              Get additional protection for your assets from a liability lawsuit.<br>
              <a routerLink="/products/umbrella">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/small-business">
              <img src="assets/img/icons/small-commercial-tile.png" alt="Small commercial Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/small-business">
              <h5>Small Business</h5>
            </a>
            <p>
              Keep your investment and reputation sound with business insurance that protects against many
              potential risks.<br>
              <a routerLink="/products/small-business">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>

    </div>
    <!-- Added new for WSTW-5938 -->
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/jewelry-and-valuables">
              <img src="assets/img/icons/icon-jewely-fine-art.png" alt="Jewelry & Valuables" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/jewelry-and-valuables">
              <h5>Jewelry & Valuables</h5>
            </a>
            <p>
              Ensure protection for valuable items that may not be covered under your homeowner, condo or
              renter policy.<br>
              <a routerLink="/products/jewelry-and-valuables">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/home-warranty-service-agreement">
              <img src="assets/img/icons/Home-warranty-icon-lrg-new.png" style="width:80%" alt="Home Warranty" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/home-warranty-service-agreement">
              <h5>Home Warranty</h5>
            </a>
            
            <p>
              Protect major household systems and appliances from expensive repairs and
              replacement cost.<br>
              <a routerLink="/products/home-warranty-service-agreement">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/golf-cart">
              <img src="assets/img/icons/icon-golf-cart.png" alt="Golf Cart Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/golf-cart">
              <h5>Golf Cart </h5>
            </a>
            <p>
              Find the right coverage solution for the way you use your cart.<br>
              <a routerLink="/products/golf-cart">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/motorcycle">
              <img src="assets/img/icons/icon-motorcycle.png" alt="Motorcycle Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/motorcycle">
              <h5>Motorcycle </h5>
            </a>
            <p>
              Make protecting your ride and lowering your insurance rates fast and easy.<br>
              <a routerLink="/products/motorcycle">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/pet-insurance">
              <img src="assets/img/icons/icon-pet.png" alt="Pet Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/pet-insurance">
              <h5>Pet </h5>
            </a>
            <p>
              Give your pets the care they deserve while protecting your wallet from unforeseen bills.<br>
              <a routerLink="/products/pet-insurance">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/travel-insurance">
              <img src="assets/img/icons/icon-travel.png" alt="Travel Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/travel-insurance">
              <h5>Travel </h5>
            </a>
            <p>
              Protect your travel investment in case of an “oh-no!”.<br>
              <a routerLink="/products/travel-insurance">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/event">
              <img src="assets/img/icons/icon-event.png" alt="Event Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/event">
              <h5>Event </h5>
            </a>
            <p>
              Enjoy your event, knowing that your financial investment is protected from unforeseen
              problems.<br>
              <a routerLink="/products/event">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/bicycle">
              <img src="assets/img/icons/icon-bicycle.png" alt="Bicycle Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/bicycle">
              <h5>Bicycle </h5>
            </a>
            <p>
              Protect the value of your bicycle from loss or damage, whether you use it for transportation
              or recreation.<br>
              <a routerLink="/products/bicycle">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="false">
      <div class="col-md-6 col-xs-12">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/small-business">
              <img src="assets/img/icons/small-commercial-tile.png" alt="Small commercial Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/small-business">
              <h5>Small Business</h5>
            </a>
            <p>
              Keep your investment and reputation sound with business insurance that protects against many
              potential risks.<br>
              <a routerLink="/products/small-business">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-12" *ngIf="false">
        <div class="row pt-4">
          <div class="col-3">
            <a class="stretched-link show-cursor" routerLink="/products/mobile-phone-insurance">
              <img src="assets/img/icons/phone-icon-new.PNG" alt="Phone Insurance" />
            </a>
          </div>
          <div class="col-8">
            <a class="stretched-link show-cursor" routerLink="/products/mobile-phone-insurance">
              <h5>Phone </h5>
            </a>
            <p>
              Get protection for your favorite technology with a range of repair and replacement
              options.<br>
              <a routerLink="/products/mobile-phone-insurance">Read More &#187;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- I N S U R A N C E   P R O D U C T S Mobile -->
<section id="homeBuckets" class="d-lg-none">
  <div class="container">

    <div class="row mt-3">
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icon-home.png" alt="">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Home</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/homeowners')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icon-renters.png" alt="">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Renters</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/renters')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icon-auto.png" alt="">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Auto</p>
          <a class="stretched-link show-cursor" (click)="commonService.NavigateToPage('/products/auto')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icon-flood.png" alt="">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Flood</p>
          <a class="stretched-link show-cursor" (click)="commonService.NavigateToPage('/products/flood')"></a>
        </div>
      </div>


      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-earthquake.png" alt="Earthquake Insurance Icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Earthquake</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/earthquake')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-umbrella.png" alt="Umbrella Insurance Icon">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Umbrella</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/umbrella')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/small-commercial-tile.png" alt="">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Small Business</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/small-business')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-jewely-fine-art.png" alt="Jewelry & Valuables">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Jewelry & Valuables</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/jewelry-and-valuables')"></a>
        </div>
      </div>
      <!-- added for WSTW-5938 -->
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/Home-warranty-icon-lrg-new.png"
               style="width:80px;" alt="Home Warranty Service Agreement" />
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Home Warranty</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/home-warranty-service-agreement')"></a>
          <!--<a class="stretched-link"
         (click)="child.importantNotice($event, 'https://www.2-10.com/get-a-quote-westwoodcx')"
         href=" #"></a>-->
        </div>
      </div>
      <!-- end -->
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-golf-cart.png" alt="Golf Cart Insurance">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Golf Cart</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/golf-cart')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-motorcycle.png" alt="Motorcycle Insurance">
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Motorcycle</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/motorcycle')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-pet.png" alt="Pet Insurance" />
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Pet</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/pet-insurance')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-travel.png" alt="Travel Insurance" />
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Travel</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/travel-insurance')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-event.png" alt="Event Insurance" />
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Event</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/event')"></a>
        </div>
      </div>
      <div class="col-md-5ths col-12 homeProductBucketCon">
        <div class="homeProductBucket p-1 p-lg-4 m-2 text-center">
          <img class="img-fluid" src="assets/img/icons/icon-bicycle.png" alt="Bicycle Insurance" />
          <p class="text-center mb-0 text-uppercase pt-2 d-inline d-lg-block">Bicycle</p>
          <a class="stretched-link show-cursor"
             (click)="commonService.NavigateToPage('/products/bicycle')"></a>
        </div>
      </div>

    </div>

  </div>
</section>
<!-- // I N S U R A N C E   P R O D U C T S  -->
<!-- // W H A T   I N S U R A N C E    P R O D U C T S ... -->
<!-- G E T   A   Q U O T E -->

<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->
<!-- // G E T   A   Q U O T E -->
<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>
<!-- // K N O W L E D G E   C E N T E R  -->
<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->