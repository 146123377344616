import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare var jQuery: any;
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-homeowners',
  templateUrl: './homeowners.component.html',
  styleUrls: ['./homeowners.component.css']
})
export class HomeownersComponent implements OnInit, AfterViewInit {
  categoryID: number = 1;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'homeowners-header.59582441b91766043f44.jpg',
    desktopHeaderText: 'Homeowners Insurance',
    desktopHeaderSubText: "Your home is more than a structure — it’s a place where you create lifetime memories with the people who matter most. It’s essential to protect it and everything inside of it that you value.",
    mobileHeaderText: 'Homeowners Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a (click)="navigateToUrl("get-an-insurance-quote/") target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a(click)="navigateToUrl("get-an-insurance-quote/") target="_blank" class=" btn ww-button">Get a Quote</a>'
  });
  constructor() { }

  ngOnInit() {
  }

  importantNotice(event, link) {
    event.preventDefault();
    if (link !== '') {
      jQuery('#leavingModalLink').attr('href', link);
      jQuery('#leavingModal').modal();
    }
  }
  ngAfterViewInit() {
    breadcrumbShow();
  }

}
