import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-bicycle',
  templateUrl: './bicycle.component.html',
  styleUrls: ['./bicycle.component.css']
})
export class BicycleComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/bic-header.jpg',
    desktopHeaderText: 'Bicycle Insurance',
    desktopHeaderSubText: "Your homeowners policy may not provide enough coverage for a valuable bicycle. Protect yourself from loss or damage with a bike insurance policy. ",
    mobileHeaderText: 'Bicycle Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="https://www.markelinsurance.com/bicycle/westwood" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://www.markelinsurance.com/bicycle/westwood" target="_blank" class=" btn ww-button">Get a Quote</a>'
  });
  constructor() { }
  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {

    breadcrumbShow();
  }

}
