
export class Action {
    static postCustInfo = 'PostLeadData';
    static getStates = 'GetStates';
    static getHomeInfo = 'GetHomeData';    
    static getRecalcQuote='ReRate';
    static postQuoteResult='Apply';
    static getLeadInfo='GetLeadInfo';
    static saveContactMe='SaveContactMe';
    static saveQuoteDeliveryDetails='SaveQuoteDeliveryDetails';
    static getAdditionalProducts='GetAdditionalProductInfo';
    static createTaskAndNote='CreateTaskAndNote';
    static getPhoneTypes='GetPhoneTypes';
    static getRCVDetails='GetRCVDetails';
    static getCustNotice='GetImportantNoticeDetails';
    static SaveStateDisclosure='SaveStateDisclosure';
    static SaveLeadHistory='SaveLeadHistory';
    static SaveRequestPolicyChanges='SaveRequestPolicyChanges'; 
    /* For Request Policy Changes*/
    static RequestPolicyChange: string='RequestPolicyChange';  
    /* For Service area map on home page */
    static getServiceAreaMapInfo: string= 'getServiceAreaMapInfo';
    /* For Make a payment */
    static getPaymentPageInfo: string= 'GetPaymentPageInfo';
    /* For Insurance Licensing */
    static getInsuranceLicensingtInfo: string= 'GetStateLicenseNumberinfo';
    /* For Unsubscribe page */
    static UnSubscribe: string = 'GetStateLicenseNumberinfo';
    /* For Make a policy page*/
    static getPolicyPageInfo: string = 'GetPolicyPageInfo';
    /* For report a claim */
    static getclaimPageInfo: string = 'GetclaimPageInfo';
    /* For contact us form */
    static sendMail: string = 'SendMail';
    /* For OptinRenter */
    static OptinRenter: string = 'OptinRenter';
    static Optout: string = 'optout'; 
    static GetBusinessUnitdetail: string = 'GetBusinessUnitdetail';
    static LenderRequestChange: string = 'LenderRequestChange';
    static GetUnsubscribeStatus: string = 'GetUnsubscribeStatus';
    static Bloginfo: string = 'bloginfo';
    static GetTestimonial: string = 'GetTestimonial';
    static GetWebsiteAlertInfo: string = 'GetWebsiteAlertInfo';
    static SetCSRFToken: string = 'SetCSRFToken';
    static GetMyQuote: string = 'GetMyQuote';
  static OnLoad: string = 'OnLoad';
  static OnSearch: string = 'OnSearch';
  static GetDetails: string = 'GetDetails';
  static GetArticleNo: string = 'GetArticleNo';
  static RequestDetails: string = 'RequestDetails';
  static lenderInfo: string = 'LenderInfo';
  static lenderChange: string = 'LenderChange';
  static lenderSave: string = 'PostLeadData';
  static lenderFile: string = 'PostLeadFile';
}


