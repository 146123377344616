<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-12 col-xs-12">
            <TABLE id=Table1 height=453 cellSpacing=0 cellPadding=0 width=100% border=0>
                <TR>
                    <TD vAlign=top width=29 height=423>&nbsp;</TD>
                    <TD vAlign=top text-nowrap width=566 height=222> 
                        <FONT size=2>
                            <H1>
                                <FONT size=5>Terms of Use Disclaimer</FONT>
                            </H1>
                            <DIV class=widget id=ctl00_mainContentPlaceHolder_onlyDropZone_columnDisplay_ctl00_controlcolumn_ctl00_WidgetHost_dropcontainer>
                                <DIV id=ctl00_mainContentPlaceHolder_onlyDropZone_columnDisplay_ctl00_controlcolumn_ctl00_WidgetHost_updatepanel>
                                    <DIV class=content>
                                        <DIV id=ctl00_mainContentPlaceHolder_onlyDropZone_columnDisplay_ctl00_controlcolumn_ctl00_WidgetHost_WidgetHost_widget_CB>
                                            <H2>
                                                <FONT size=2>This site is created, controlled and published by
                                                    QBE.</FONT>
                                                <FONT size=3>
                                                </FONT>
                                            </H2>
                                            <P style="MARGIN-TOP: 0px; MARGIN-BOTTOM: 0px">
                                                <FONT size=2>COPYRIGHT 2011
                                                    WESTWOOD INSURANCE AGENCY. ALL RIGHTS RESERVED. <br>Review all of
                                                    the
                                                    following terms&nbsp;and conditions carefully. By using this Web
                                                    site
                                                    (westwoodinsurance.com), you agree to comply with all terms and
                                                    conditions
                                                    for this site as well as to comply with all applicable laws. QBE
                                                    may modify these terms&nbsp;and conditions at any time, without
                                                    notice. </FONT>
                                            </P>
                                            <P style="MARGIN-TOP: 0px; MARGIN-BOTTOM: 0px">&nbsp;</P>
                                            <P style="MARGIN-TOP: 0px; MARGIN-BOTTOM: 0px">
                                                <FONT size=2>Unless
                                                    otherwise authorized by QBE, permission is granted to view,
                                                    store, print, reproduce, and distribute pages within this Web site
                                                    solely
                                                    for informational, non-commercial purposes, provided that (a) no
                                                    pages are
                                                    modified, and (b) this page and any notices in such pages regarding
                                                    use or
                                                    ownership are included with stored, reproduced or distributed
                                                    pages.
                                                    <br>&nbsp;</FONT>
                                            </P>
                                            <H3>Intellectual property and trademarks</H3>
                                            <P>
                                                <FONT size=2>All names, logos, trademarks, service marks, trade dress,
                                                    graphics, designs, characters, brand identifiers, and all other
                                                    intellectual property appearing in this site, except as otherwise
                                                    noted,
                                                    are owned or used under license by QBE or its
                                                    subsidiaries. The use or misuse of these marks or any other content
                                                    on
                                                    this site, except as provided in these terms and conditions or in
                                                    the site
                                                    content, is strictly prohibited. All of the content included in
                                                    this Web
                                                    site is subject to the copyright laws of the United States and
                                                    other
                                                    applicable jurisdictions and QBE owns all the copyright
                                                    rights associated with this content. All rights reserved.</FONT>
                                            </P>
                                            <P>
                                                <FONT size=2>Images displayed on this Web site are either the property
                                                    of, or used with permission by, QBE. The use of these images
                                                    by you, or anyone else authorized by you, is prohibited unless
                                                    specifically permitted herein. Any unauthorized use of the images
                                                    may
                                                    violate copyright laws, trademark laws, the laws of privacy and
                                                    publicity,
                                                    and communications regulations and statutes.</FONT>
                                            </P>
                                            <H4>Links</H4>
                                            <P>
                                                <FONT size=2>Some pages on westwoodinsurance.com contain links to
                                                    other resources on the Internet. Those links are provided as aids
                                                    to help
                                                    identify and locate other Internet resources of interest. They are
                                                    not
                                                    intended to state or imply that QBE sponsors or is
                                                    affiliated or associated with the owners or publishers of such
                                                    resources,
                                                    or that QBE is legally authorized to use any trade name,
                                                    registered trademark, logo, legal or official seal, or copyrighted
                                                    symbol
                                                    that may be reflected in the links. Therefore, the decision to view
                                                    any
                                                    linked site is at the viewer's own risk.</FONT>
                                            </P>
                                            <H4>Disclaimer</H4>
                                            <P>
                                                <FONT size=2>CONTENT ON THIS WEB SITE IS PROVIDED "AS IS" WITHOUT
                                                    WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                                                    LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                                                    A
                                                    PARTICULAR PURPOSE, AND NON-INFRINGEMENT. Some jurisdictions do not
                                                    allow
                                                    exclusion of the implied warranties, so the disclaimer above may
                                                    not apply
                                                    to you in this respect. </FONT>
                                            </P>
                                            <P>
                                                <FONT size=2>The materials on this Web site are provided for
                                                    informational purposes only. QBE does not represent or
                                                    warrant that any information in this Web site is correct, complete,
                                                    or
                                                    up-to-date. QBE may change or delete information on this
                                                    site without notice at any time. Therefore, use of these materials
                                                    is at
                                                    the user's own risk.</FONT>
                                            </P>
                                            <H3>Information submitted by site visitors</H3>
                                            <P>
                                                <FONT size=2>Should any visitor of this Web site&nbsp;respond with
                                                    information, including personal information and feedback data such
                                                    as
                                                    questions, comments and suggestions regarding the content of any
                                                    portion
                                                    of this publication, such information shall be deemed to be
                                                    non-confidential.&nbsp; Site visitors are encouraged to review
                                                    QBE's <A title="Web Privacy Policy" href="Privacy.aspx">Web Privacy
                                                        Policy.</A></FONT>
                                            </P>
                                            <H3>Limitation of liability</H3>
                                            <P>
                                                <FONT size=2>QBE SHALL NOT BE LIABLE FOR ANY DIRECT,
                                                    INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                                                    ARISING
                                                    OUT OF ACCESS TO OR USE OF ANY CONTENT OF THIS SITE OR THE CONTENT
                                                    OF ANY
                                                    SITE OR SITES LINKED TO THIS SITE. In no event shall QBE's
                                                    liability exceed the total amount paid by you to QBE, if
                                                    any, for accessing this site.</FONT>
                                            </P>
                                            <H3>Jurisdiction</H3>
                                            <P>
                                                <FONT size=2>This site is controlled and operated by QBE
                                                    Corporation from its headquarters in Atlanta, Georgia, USA. Your
                                                    viewing
                                                    of any materials on this Web site constitutes your approval of this
                                                    Agreement and consent to jurisdiction in courts of competent
                                                    jurisdiction
                                                    in the state of Delaware, without regard to conflict of law
                                                    principals, to
                                                    resolve any interpretations or disputes arising from this Agreement
                                                    and
                                                    any conflicts that arise from this site</FONT>
                                            </P>
                                        </DIV>
                                    </DIV>
                                </DIV>
                            </DIV>
                            <P class=MsoNormal>&nbsp;</P>
                        </FONT>
                         
                    </TD>
                </TR>
            </TABLE>
        </div>
    </div>
</div>