<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-cur-cus" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Current Customers</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
  <div class="container pt-3 pb-5 bottom-border-element">
    
    <div class="row justify-content-center cc-icons  pt-3">
      <div class="col-md-6 col-xs-12 pr-5 pb-4 text-left cc-image-and-button">
        <img src="assets/img/page-body-img/shutterstock_1070766065.png" style="width: 100%;"
          alt="Man and Woman looking at computer with insurance agent" />
      </div>
      <div class="col-md-6 col-xs-12">
        

        <div class="row">
          <div class="col pt-2">
            <a routerLink="/current-customers/claims">
              <h5>Claims »</h5>
            </a>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col pt-3">
            <a routerLink="/current-customers/make-a-payment">
              <h5>Make a Payment »</h5>
            </a>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col pt-3">
            <a routerLink="/current-customers/request-policy-changes">
              <h5>Request Policy Changes »</h5>
            </a>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col pt-3">
            <a routerLink="/current-customers/my-policy-documents">
              <h5>My Policy Documents »</h5>
            </a>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col pt-3">
            <a routerLink="/current-customers/lender-requests-changes">
              <h5>Lender Requests/Changes »</h5>
            </a>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col pt-3">
            <a routerLink="/current-customers/opt-in-for-text-messages">
              <h5>Opt-in for Text Messages »</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- // I N T R O   P A R A G R A P H  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->