import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  AfterViewChecked,
  OnDestroy,
  NgZone,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { WizardComponent } from "angular-archwizard";

import { Constants, policyTypeID } from "./../../Utilities/Constants/constants";
import { TooltipMessage } from "../../Utilities/Constants/tooltipMessage";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { CommonService } from "../../service/common.service";
import { Lead } from "../../model/lead";
import { DataService } from "../../service/data.service";
import { HomeInfoServiceService } from "../../service/home-info-service.service";
import { RouterExtService } from "../../service/router-ext.service";
import { ModelPopupComponent } from "../../shared/model-popup/model-popup.component";
import { LeadHistory } from "../../model/leadHistory";
import { ddlItem } from "src/app/model/common.models";
import { debug } from "console";

declare var $: any;

@Component({
  selector: "app-home-info",
  templateUrl: "./home-info.component.html",
  styleUrls: ["./home-info.component.css"],
})
export class HomeInfoComponent implements OnInit {
  homeForm: FormGroup;
  LiveChatImage = "";
  formErrors: any = {};
  // leadDetails: Lead;
  keys: any[];
  yearBuild = new Date();
  minYearBuild = 1900;
  isLoading = false;
  const: Constants;
  maxYearBuild = new Date().getFullYear() + 1;
  isHO3: Boolean = false;
  isH04orDQdndRUN: boolean = false;
  isInavlidPropertyType: boolean = false;
  leadCopy: Lead;
  numberOfUnits: number = 1;
  emptyDDLItem = <ddlItem>{ lookupId: "", lookupValue: "", inactive: false };
  @ViewChild(ModelPopupComponent) popUp: ModelPopupComponent;
  @Input("mainWizard") public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();

  constructor(
    private router: Router,
    public commonService: CommonService,
    public constantObj: Constants,
    public toolTipMessage: TooltipMessage,
    public dataService: DataService,
    public homeService: HomeInfoServiceService,
    public homeDetails: Lead,
    public recaptchaV3Service: ReCaptchaV3Service,
    private routerExtService: RouterExtService
  ) {
    this.LiveChatImage = constantObj.LiveChatImage;
    this.const = constantObj;
  }

  ngOnInit() {
    this.leadCopy = JSON.parse(
      JSON.stringify(this.dataService.leadHistory.preValue)
    );
    this.commonService.ScrollToRTQForm();
    this.commonService.SaveLeadHistory();
    this.dataService.leadHistory.pageNumber = 3;
    this.leadDetails = this.dataService.leadDetails;
    this.leadUpdateEvent.emit(this.leadDetails);
    this.getDropDownValuesforHomeInfo();
  }
  setInformationMessage() {
    if (
      !this.leadDetails.homeInfo.inValidPropertyType &&
      !this.dataService.leadDetails.isServiceUnavailable &&
      this.leadDetails.policyTypeID != policyTypeID.HO4 &&
      !this.leadDetails.homeInfo.isDataQuickFailed
    ) {
      this.isHO3 = true;
      this.isH04orDQdndRUN = false;
      this.isInavlidPropertyType = false;
    } else if (
      !this.leadDetails.homeInfo.inValidPropertyType &&
      !this.dataService.leadDetails.isServiceUnavailable &&
      (this.leadDetails.policyTypeID == policyTypeID.HO4 ||
        this.leadDetails.homeInfo.isDataQuickFailed)
    ) {
      this.isH04orDQdndRUN = true;
      this.isHO3 = false;
      this.isInavlidPropertyType = false;
    } else if (this.leadDetails.homeInfo.inValidPropertyType) {
      this.isInavlidPropertyType = true;
      this.isHO3 = false;
      this.isH04orDQdndRUN = false;
      this.commonService.SaveLeadHistory();
      this.dataService.leadHistory.pageNumber = 6;
    }
  }
  setDropdownValuesforHomeInfo() {
    if (this.leadDetails.homeInfo != null) {
      if (this.leadDetails.homeInfo.constructionType == null)
        this.leadDetails.homeInfo.constructionType = "";
    }
    if (this.leadDetails.homeInfo.homeType == null) {
      this.leadDetails.homeInfo.homeType = "";
    }
    if (this.leadDetails.homeInfo.propertyType == null) {
      this.leadDetails.homeInfo.propertyType = "";
    }
    if (this.leadDetails.homeInfo.garageType == null) {
      this.leadDetails.homeInfo.garageType = "";
    }

    if (this.leadDetails.homeInfo.foundationType == null) {
      this.leadDetails.homeInfo.foundationType = "";
    }
    if (this.leadDetails.homeInfo.roofType == null) {
      this.leadDetails.homeInfo.roofType = "";
    }
    if (this.leadDetails.homeInfo.unitNumber == null) {
      this.leadDetails.homeInfo.unitNumber = 0;
    }
    if (this.leadDetails.homeInfo.roofShape == null) {
      this.leadDetails.homeInfo.roofShape = "";
    }
  }
  getDropDownValuesforHomeInfo() {
    this.leadDetails.homeInfo = this.dataService.leadDetails.homeInfo;
    this.homeService.getHomeInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.dataService.leadHistory.preValue = JSON.parse(
          JSON.stringify(this.leadCopy)
        );
        this.dataService.leadHistory.pageNumber = 99;
        this.dataService.leadHistory.noUpdate = false;
        this.commonService.SaveLeadHistory();
        this.dataService.leadHistory.preValue = JSON.parse(
          JSON.stringify(data)
        );
        this.dataService.leadHistory.pageNumber = 3;
        this.dataService.leadHistory.preValue.policyTypeID =
          this.dataService.leadDetails.policyTypeID;
        this.dataService.leadHistory.preValue.homeInfo.homeType =
          this.dataService.leadHistory.postValue.homeInfo.homeType;
        this.dataService.leadHistory.preValue.homeInfo.percentageFinished =
          this.dataService.leadHistory.postValue.homeInfo.percentageFinished;
        this.dataService.leadHistory.preValue.homeInfo.roofShape =
          this.dataService.leadHistory.postValue.homeInfo.roofShape;
        this.dataService.leadHistory.preValue.homeInfo.roofType =
          this.dataService.leadHistory.postValue.homeInfo.roofType;
        this.leadDetails.homeInfo = data.homeInfo;
        this.setDropdownValuesforHomeInfo();
        this.setInformationMessage();
      },
      (err) => {
        this.dataService.handleError(err);
      }
    );
  }

  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }

  SetFoundationType() {
    if (
      this.leadDetails.homeInfo.foundationType ==
      "3C6214293EC04BF283ECEA9AFB3D34A7"
    ) {
      this.leadDetails.homeInfo.percentageFinished = 100;
    }
  }

  savePageDetails() {
    this.isLoading = true;
    this.leadDetails.contactMeButtonClicked = true;
    this.commonService.CreateLeadHistoryObj(3, this.leadDetails);
    this.homeService.postHomeInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.commonService.SaveLeadHistoryFromContactBtn();
        this.leadDetails.homeInfo = data.homeInfo;
        this.isLoading = false;
        // this.commonService.NavigateToPage('/real-time-quote/contact');
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }

  trackByGarageSizeFn(index, option) {
    return option.value;
  }

  onSubmit(homeForm) {
    this.dataService.leadHistory.noUpdate = homeForm["form"].pristine;
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(homeForm, this.formErrors);
    this.formErrors = this.commonService.updateControls(homeForm);
    if (homeForm.valid) {
      this.commonService.ScrollToRTQForm();
      this.isLoading = true;
      if (
        this.leadDetails.homeInfo != null &&
        (this.leadDetails.homeInfo.homeType == this.const.HomeTypeRental ||
          this.leadDetails.homeInfo.homeType ==
            this.const.HomeTypeSecondary_Vacation)
      ) {
        this.isLoading = true;
        this.homeService.postHomeInfo(this.leadDetails).subscribe(
          (data: Lead) => {
            this.leadDetails.homeInfo = data.homeInfo;
            this.isLoading = false;
            this.setInformationMessage();
            this.leadUpdateEvent.emit(this.leadDetails);
            this.wizardStep.goToNextStep();
            return true;
          },
          (err) => {
            this.isLoading = false;
            this.dataService.handleError(err);
            return false;
          }
        );
      } else {
        this.isLoading = true;
        this.homeService.postHomeInfo(this.leadDetails).subscribe(
          (data: Lead) => {
            this.leadDetails = data;
            this.leadDetails.homeInfo = data.homeInfo;
            this.leadDetails.quoteResult = data.quoteResult;
            this.isLoading = false;
            this.dataService.leadDetails = this.leadDetails;
            this.dataService.leadHistory.postValue.policyTypeID =
              this.leadDetails.policyTypeID;
            if (
              this.dataService.leadHistory.postValue.policyTypeID !=
              this.dataService.leadHistory.preValue.policyTypeID
            )
              this.dataService.leadHistory.noUpdate = false;
            this.leadUpdateEvent.emit(this.leadDetails);
            this.isLoading = false;
            this.wizardStep.goToNextStep();
            // this.commonService.NavigateToPage('/real-time-quote/underwriting');
            return true;
          },
          (err) => {
            this.isLoading = false;
            this.dataService.handleError(err);
            return false;
          }
        );
      }
      return true;
    } else {
      if (!homeForm.valid) {
        this.commonService.focusFirstInvalidElement();
        return;
      }
    }
  }

  ngAfterViewChecked() {
    this.setTooltip();
  }

  setTooltip = function () {
    $('.helperSquareFootage').tooltip({ title: `<p class='text-left'>Square Footage: <br/> ${this.toolTipMessage.squareFootage} </p> `, html: true, placement: "top" });
    return '';
  }

  _keyUp(event: any) {
    var val = event.target.value.replace(/\$|\,/g, "");
    // 46,8
    if (event.which == 46) {
      if (event.key == ".") {
        if (val.indexOf(".") != -1) {
          event.preventDefault();
        }
      }
    }
    if (
      !(
        (event.which >= 48 && event.which <= 57) ||
        event.which != 46 ||
        event.which != 8
      )
    ) {
      event.preventDefault();
    }

    if (val.indexOf(".") != -1) {
      if (val.split(".")[0].length >= 3 && val.split(".")[1].length >= 1) {
        event.preventDefault();
      }
    } else {
      if (val.length >= 3) {
        if (val.indexOf(".") >= 0 && event.key == ".") event.preventDefault();
        else if (event.key != ".") {
          event.preventDefault();
        }
      }
    }
  }

  formatBedrooms(num1) {
    if (num1.target.value != null && num1.target.value != "")
      this.leadDetails.homeInfo.noOfBedrooms =
        this.commonService.formatCurrency(num1);
  }
  formatBathrooms(num1) {
    if (num1.target.value != null && num1.target.value != "")
      this.leadDetails.homeInfo.noOfBathRooms =
        this.commonService.formatCurrency(num1);
  }
  setGarageSize() {
    if (
      this.leadDetails.homeInfo.garageType == this.constantObj.GarageType_None
    ) {
      this.leadDetails.homeInfo.garageSize = null;
    }
  }
  DisableHomeTypeforHO4() {
    if (this.leadDetails.policyTypeID == policyTypeID.HO4) {
      return true;
    } else return false;
  }

  trackByItemId(index: number, item: ddlItem): string {
    return item.lookupId;
  }

  // onNumberInput(event: any): number {
  //   const enteredValue = event.target.value;
  //   const parsedValue = parseInt(enteredValue, 10);

  // debugger;
  //   if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 9) {
  //     const clampedValue = Math.min(Math.max(parsedValue, 1), 9);
  //     this.leadDetails.homeInfo.unitNumber = clampedValue;
  //     return clampedValue;
  //   } else {
  //     // Reset to the closest valid value
  //     if (parsedValue < 1) {
  //       this.leadDetails.homeInfo.unitNumber = 1;
  //     } else if (parsedValue > 9) {
  //       this.leadDetails.homeInfo.unitNumber = 1;
  //     } else {
  //       this.leadDetails.homeInfo.unitNumber = 1;
  //     }
  //     return 1;
  //   }
  // }

  onNumberInput(event: any) {
    const enteredValue = event.target.value;
    const parsedValue = parseInt(enteredValue, 10);

    if (!isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 9) {
      this.leadDetails.homeInfo.unitNumber = parsedValue;
    } else {
      this.leadDetails.homeInfo.unitNumber = 1;
    }
    return this.leadDetails.homeInfo.unitNumber;
  }
}
