<section class="news-article"  id="articleGrid">
  <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
  <div class="container pt-5 loader-container" *ngIf="isLoading"></div>
  <div class="container pt-5" *ngIf="!isLoading">
    <div class="row pt-2 pl-3">
      <div class="col-md-4 bottom-border-element-article pl-0">
        <h2 class="text-left"><a (click)="reloadNews()" class="ww-button-title ">News and Insights</a></h2>
      </div>
      <div class="col-md-5 pl-3" *ngIf="SearchTerm && isSearchDataLoaded">
        <b>{{data?.totalCount}} results for </b>
        <span>"{{SearchTerm}}"</span>
      </div>
    </div>
    <div class="row pt-2 pb-4">
      <div class="col-md-9">
        <!-- Start List Of articles -->
        <div class="row">
          <!-- <div class="card my-3 mx-3 shadow rounded no-border-top" style="width: 24rem;" *ngFor="let item of data?.articlesMain; index as i;"> -->
            <div class="card my-3 mx-3 shadow rounded no-border-top" style="width: 24rem;" *ngFor="let item of data?.articlesMain | paginate: { itemsPerPage: 8, currentPage: PageNumber }; index as i;">
            <img class="card-img-top" [src]="item?.image" alt="
                            Card image cap" />
            <div class="card-body">
                
              <h5 class="card-title"
              appHighlight
              [content]="item?.heading" 
              [searchTerm]="SearchTerm" 
              [caseSensitive]="false"

              >{{item?.heading}}</h5>
              <p class="card-text font-cust"
              appHighlight
              [content]="item?.summaryText" 
              [searchTerm]="SearchTerm" 
              [caseSensitive]="false"
              [innerHTML]="item?.summaryText"
              >
               
              </p>
              <button type="button" [routerLink]="['/news/article', item.title]"  class="read-more btn">Read more</button>
            </div>
          </div>
        </div>
        <!-- End List Of articles -->
        <!-- Start Pagination -->
        <div class="row pt-2 pb-3" *ngIf="data?.articlesMain.length > 0" >
            <div 
              class="col-xl-3 col-sm-12"  
              [ngClass]="{'d-flex justify-content-center ': this.commonService.iMobBroswer()}">
              <button class="btn news-home " (click)="reloadNews()" >NEWS HOME</button>
            </div>
            <div 
              *ngIf="data?.totalCount > 6"
              class="col-xl-9 col-sm-12 mob-pt-3 custom-pagination" 
              [ngClass]="{'d-flex justify-content-center ': this.commonService.iMobBroswer()}">
              <pagination-controls 
              (pageChange)="PageNumber = $event;paginationPageChanged($event) "
              [previousLabel]="PrevLabel"
              [nextLabel]="NextLabel"

                [maxSize]="5"
              ></pagination-controls>
              <!-- class="col-xl-9 col-sm-12 mob-pt-3 custom-pagination" -->
              <!-- <ngb-pagination 
                [(page)]="this.PageNumber" 
                [pageSize]="this.PageSize" 
                [directionLinks]="true" 
                [collectionSize]="data?.totalCount"  
                (pageChange)="refreshArticleGrid()" 
                [maxSize]="5">
              </ngb-pagination>  -->
            </div>
          </div>
        <!-- End Pagination -->
      </div>
      <!-- Start show categories and archives for desktop -->
      <div class="col-md-3 mt-3 bg-white custom-heigth" *ngIf="!this.commonService.iMobBroswer()">
          <div class="row">
            <div class="input-group col-md-12 pt-3">
              <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search" id="example-search-input" name="SearchTerm"
                     [(ngModel)]="SearchTerm" (keyup)="this.pageTitle = '';SearchTermChanged();"
                     (keyup.enter)="PageNumber=1;PageSize=6;this.pageTitle = '';this.scrollArticle = true;;getArticlesOnSearch();"
                     
              >
              <span class="input-group-append">
                <button class="btn btn-outline-secondary border-left-0 border" type="button" (click)="this.scrollArticle = true;PageNumber=1;PageSize=6;getArticlesOnSearch();">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
          <h5 class="pb-2 pt-4"><img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> <a (click)="reloadNews()" class="news-heading"> Categories</a></h5>
          <!-- Start show categories -->
          <ul class="list-group list-group-flush" *ngFor="let item of data?.articlesCategory; index as i;">
            <li class="list-group-item font-weight-bold">
              <a href="#" class="text-dark " 
              [ngClass]="{'active-cat-archi ': this.CategoryID == item.categoryID}"
              (click)="$event.preventDefault(); PageNumber=1;PageSize=6; this.scrollArticle = true;
              this.CategoryID=item.categoryID;this.Month=0;this.Year=0;this.SearchTerm='';pageTitle=item.categoryName;refreshArticleGrid();myMethodChangingQueryParams();">{{item.categoryName}}</a>
            </li>
          </ul>
          <!-- End show categories -->
          <!-- Setting display=false for WSTW-5641 -->
          <div *ngIf="false">
            <h5 class="pt-5 pb-2 archives" *ngIf="false">
                <img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> 
                <a (click)="reloadNews()" class="news-heading"> Archives</a></h5>
              <!-- Start show Archives -->
              <!-- Setting display=false for WSTW-5641 -->
              
              <ul class="list-group list-group-flush" *ngFor="let item of data?.articlesArchives; index as i;">
                <li class="list-group-item">
                  <a href="#" class="text-dark p-2" 
                  [ngClass]="{'active-cat-archi ': ((this.Year == item.year) && (this.Month == item.month)) }"
                  (click)="$event.preventDefault(); PageNumber=1;PageSize=6; this.scrollArticle = true; this.CategoryID=0;this.Month=item.month;this.Year=item.year;this.SearchTerm='';pageTitle=item.archives;refreshArticleGrid(); myMethodChangingQueryParams();">{{item.archives}}</a>
                </li>
              </ul>
              
              <!-- End show Archives -->
        </div>
        </div>
      <!-- Start show categories and archives for desktop -->
      <!-- Start show categories and archives for mobile -->
      <div class="col-md-3 mt-3 pb-4 bg-white" *ngIf="this.commonService.iMobBroswer()">
          <div class="row">
            <div class="input-group col-md-12 pt-3">
              <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search" id="example-search-input" name="SearchTerm"
                     [(ngModel)]="SearchTerm" (keyup)="this.pageTitle = '';SearchTermChanged();"
                     (keyup.enter)="PageNumber=1;PageSize=6;this.pageTitle = '';this.scrollArticle = true;;getArticlesOnSearch();"
                     
              >
              <span class="input-group-append">
                <button class="btn btn-outline-secondary border-left-0 border" type="button" (click)="this.scrollArticle = true;PageNumber=1;PageSize=6;getArticlesOnSearch();">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
          <h5 class="pb-2 pt-4"><img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> <a (click)="reloadNews()" class="news-heading"> Categories</a><a class="pl-3 black-color" data-toggle="collapse" href="#collapseCategories" role="button" aria-expanded="false" aria-controls="collapseCategories">
              <i class="fas fa-angle-double-down"></i>
            </a></h5>
          <!-- Start show categories -->
          <ul class="list-group list-group-flush collapse"  id="collapseCategories" *ngFor="let item of data?.articlesCategory; index as i;">
            <li class="list-group-item font-weight-bold">
              <a href="#" class="text-dark " 
              [ngClass]="{'active-cat-archi ': this.CategoryID == item.categoryID}"
              (click)="$event.preventDefault(); PageNumber=1;PageSize=6; this.scrollArticle = true;
              this.CategoryID=item.categoryID;this.Month=0;this.Year=0;this.SearchTerm='';pageTitle=item.categoryName;refreshArticleGrid();myMethodChangingQueryParams();">{{item.categoryName}}</a>
            </li>
          </ul>
          <!-- End show categories -->
          <!-- Setting display=false for WSTW-5641 -->
          <div *ngIf="false">
            <h5 class="pt-5 pb-2 archives" *ngIf="false">
                <img src="assets/img/icons/archivebox2.jpg"  alt="archivebox" class="pb-1"/> 
                <a (click)="reloadNews()" class="news-heading"> Archives</a></h5>
              <!-- Start show Archives -->
              <!-- Setting display=false for WSTW-5641 -->
              
              <ul class="list-group list-group-flush" *ngFor="let item of data?.articlesArchives; index as i;">
                <li class="list-group-item">
                  <a href="#" class="text-dark p-2" 
                  [ngClass]="{'active-cat-archi ': ((this.Year == item.year) && (this.Month == item.month)) }"
                  (click)="$event.preventDefault(); PageNumber=1;PageSize=6; this.scrollArticle = true; this.CategoryID=0;this.Month=item.month;this.Year=item.year;this.SearchTerm='';pageTitle=item.archives;refreshArticleGrid(); myMethodChangingQueryParams();">{{item.archives}}</a>
                </li>
              </ul>
              
              <!-- End show Archives -->
          </div>
        </div>
      <!-- End show categories and archives for mobile -->
     
    </div>
    <!-- <div class="row pt-2">
      <div class="col-md-9">
        <ngb-pagination [(page)]="this.PageNumber" [pageSize]="this.PageSize" [directionLinks]="true" [collectionSize]="data?.totalCount" [boundaryLinks]="true"
                        (pageChange)="refreshArticleGrid()" [maxSize]="5"></ngb-pagination>
      </div>
    </div> -->
  </div>
</section>


