import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-travel-insurance',
  templateUrl: './travel-insurance.component.html',
  styleUrls: ['./travel-insurance.component.css']
})
export class TravelInsuranceComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'travel-header.ed4eede9435ebf88d6fc.jpg',
    desktopHeaderText: 'Travel Insurance',
    desktopHeaderSubText: "From inclement weather and unexpected travel delays to lost luggage and medical emergencies, travel insurance can protect you financially.",
    mobileHeaderText: 'Travel Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="/travel-insurance/travel-online-quote" target="_blank" class=" btn banner-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="/travel-insurance/travel-online-quote" target="_blank" class=" btn banner-button">Get a Quote</a>'
  });
  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }
}
