import { ReCaptcha } from './reCaptcha';
import { Injectable } from "@angular/core";

@Injectable()
export class CurrentCustomer { 
    public message:string;
    public subStatus:string;
    public status:string; 
    public requestRCE:boolean;
    public requestType:string;
    public policyNumber: string; 
    public firstName: string; 
    public lastName: string; 
    public addressLine1: string; 
    public addressLine2: string; 
    public city: string; 
    public state: string; 
    public postalCode: string;  
    public contactNumber: string; 
    public emailAddress: string; 
    public requestMessage: string; 
    public contactWay: string; 
    public emailTemplate: string;  
    public reCaptcha: ReCaptcha; 
    public contactWayWorkPhone: string;
    public contactWayMobilePhone: string;
    public contactWayEmail: string; 
    public vesting: string;
    // public closeDate: string;
    public loanNumber: string;
    public loanPosition: string;
    public mortgageeFirstName: string;
    public mortgageeLastName: string;
    public mortgageeClause: string;
    public mortgageeAddressLine1: string;
    public mortgageeAddressLine2: string;
    public mortgageeCity: string;
    public mortgageeState: string;
    public mortgageePostalCode: string;
    public mortgageeImpounds: string;
    public requesterFirstName: string;
    public requesterLastName: string;
    public requesterEmail: string;
    public requesterinstructor: string;
    public requesterAdditionalNote: string;

    constructor() {
        this.policyNumber = ''; 
        this.firstName = ''; 
        this.lastName = ''; 
        this.addressLine1 = ''; 
        this.addressLine2 = ''; 
        this.city = ''; 
        this.state = ''; 
        this.postalCode = '';  
        this.contactNumber = ''; 
        this.emailAddress = ''; 
        this.requestMessage = ''; 
        this.contactWay = ''; 
        this.emailTemplate = '';  
        this.contactWayWorkPhone = '';
        this.contactWayMobilePhone = '';
        this.contactWayEmail = '';
        this.vesting = '';
       // this.closeDate = '';
        this.loanNumber = '';
        this.loanPosition = '';
        this.mortgageeFirstName = '';
        this.mortgageeLastName = '';
        this.mortgageeClause = '';
        this.mortgageeAddressLine1 = '';
        this.mortgageeAddressLine2 = '';
        this.mortgageeCity = '';
        this.mortgageeState = '';
        this.mortgageePostalCode = '';
        this.mortgageeImpounds = '';
        this.requesterFirstName = '';
        this.requesterLastName = '';
        this.requesterEmail = '';
        this.requesterinstructor = '';
        this.requesterAdditionalNote = '';
    }
}









