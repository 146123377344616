import { ReCaptcha } from './reCaptcha';

export class RequestDetails {
    public name = ''; 
    public email: string; 
    public company: string;
    public urlLink: string;
    public businessType: number;
    public message: string;
    public emailTemplate: string;
    public errorMessage: string;
    public reCaptcha:ReCaptcha; 
    public pageCode:string;
     
    constructor() {
        this.name = ''; 
        this.email = ''; 
        this.company = '';
        this.urlLink = '';
        this.businessType = null;
        this.message = '';
        this.emailTemplate = '';
        this.errorMessage = '';
        this.pageCode = '';
    }
}
 