import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser'; 
import { HttpClient } from '@angular/common/http';

declare function breadcrumbShow():any;
declare var $;
@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.css']
})
export class SiteMapComponent implements OnInit {
 

  title = 'read-xml-angular8';
  public xmlItems: any;
  constructor(public _http: HttpClient) { 
    
  }
 
  ngOnInit() {

  }

  ngAfterViewInit()
  {
    breadcrumbShow();
    //Run carousel
    $('.carousel').carousel()
  } 

}
