import { ReCaptcha } from './reCaptcha';
import { Injectable } from "@angular/core";

@Injectable()
export class Texting {  
    public PolicyNumber: string; 
    public FirstName: string; 
    public LastName: string;  
    public Renters: string; 
    public ContactNumber: string;  
    public ReCaptcha: string; 
    public businessUnitName: string; 
    public businessUnitiD: string;
    public reCaptcha:ReCaptcha;
    constructor() {
        this.PolicyNumber = ''; 
        this.FirstName = ''; 
        this.LastName = '';  
        this.Renters = '';  
        this.ContactNumber = '';  
        this.ReCaptcha = '';  
        this.businessUnitName = '';
        this.businessUnitiD = '';
    }
}

//http://usspewebwd0012.corp.qbe.com:60/V4WestwoodInsuranceAPI/api/Texting/optout
//http://usspewebwd0012.corp.qbe.com:60/V4WestwoodInsuranceAPI/api/Texting/OptinRenter