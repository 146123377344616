import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { faChevronRight, faLevelDownAlt, faChevronLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
declare function breadcrumbShow(): any;
declare var $;

@Component({
  selector: 'app-partner-landing',
  templateUrl: './partner-landing.component.html',
  styleUrls: ['./partner-landing.component.css']
})
export class PartnerLandingComponent implements OnInit, AfterViewInit {
  faChevronRight = faChevronRight;
  faLevelDownAlt = faLevelDownAlt;
  faChevronLeft = faChevronLeft;
  faCaretRight = faCaretRight;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'pwu-header.72019eb0ffcb927093ce.jpg',
    desktopHeaderText: 'Partner with Westwood',
    desktopHeaderSubText: "The easiest way to improve the home buying experience",
    mobileHeaderText: 'Partner with Westwood',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  class="btn banner-button mr-2" type="button" href="/partners/builders" >BUILDERS</a><a  href="/partners/lenders" class="btn banner-button" type="button">LENDERS</a>',
    desktopHeaderButton: '<a class="btn banner-button mr-2" type="button"  href="/partners/builders" >BUILDERS</a><a href="/partners/lenders" class="btn banner-button" type="button">LENDERS</a>'
  });
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
    //Run carousel
    $('.carousel').carousel();
  }

}
