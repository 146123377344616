import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';

declare function breadcrumbShow(): any;
@Component({
  selector: 'app-claim-prevention',
  templateUrl: './claim-prevention.component.html',
  styleUrls: ['./claim-prevention.component.css']
})
export class ClaimPreventionComponent implements OnInit, AfterViewInit {
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'PreventAClaim.jpg',
    desktopHeaderText: 'Prevent a Claim',
    desktopHeaderSubText: "We’ve seen a lot and have a few ideas to help you avoid claims altogether.",
    mobileHeaderText: 'Prevent a Claim',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    breadcrumbShow();
  }

}
