import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  NgZone,
  AfterViewChecked,
  Input,
  Output,
  EventEmitter,
  } from "@angular/core";
  import { Router, ActivatedRoute, RoutesRecognized } from "@angular/router";
  import {
    NgbCalendar,
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDateStruct,
    NgbDatepicker,
    NgbPopoverConfig,
  } from "@ng-bootstrap/ng-bootstrap";
  import { RecaptchaErrorParameters } from "ng-recaptcha";
  
  import { CommonService } from "../../service/common.service";
  import { Constants } from "../../Utilities/Constants/constants";
  import { Contactus } from "../../model/contactus";
  import { FormGroup, FormGroupDirective } from "@angular/forms";
  import { ContactUsService } from "../../service/contact-us.service";
  import { DataService } from "../../service/data.service";
  import { HomeInfoServiceService } from "src/app/service/home-info-service.service";
import { WizardComponent } from "angular-archwizard";
import {
  captchaSiteKey,
  homeURL,
  captchaSiteKeyV3,
  captchaSecretKeyV3,
} from "src/app/service/config.service";
import { ReCaptchaV3Service } from "ngx-captcha";
import { CustInfoService } from "../../service/cust-info.service";
import { Lead } from "../../model/lead";
import { Address } from "src/app/model/address";
import { Source } from "src/app/model/source";
import { ReCaptcha } from "src/app/model/reCaptcha";
import { LeadHistory } from "src/app/model/leadHistory";
import { ModelPopupComponent } from "../../shared/model-popup/model-popup.component";
import { MyAutofocusDirective } from "src/app/directive/my-autofocus.directive";
import { TooltipMessage } from "../../Utilities/Constants/tooltipMessage";

import {
  CustomAdapter,
  CustomDateParserFormatter,
} from "src/app/shared/ngb-components/ngb-datepicker-formatter";
import { filter, pairwise, retry } from "rxjs/operators";
import { Observable, of } from "rxjs";
declare var $: any;
declare var grecaptcha: any;
declare function facIconOne(): any;
declare function facIconTwo(): any;

enum loanType {
  Owner = "F249F81E953B454E971498673FDFABF1", //HO3
  Purchase = "a29", //Condo-HO6
  Refinance = "a28", //Renter-HO4
  None = "none",
}

@Component({
  selector: "app-customer-info",
  templateUrl: "./customer-info.component.html",
  styleUrls: ["./customer-info.component.css"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class CustomerInfoComponent implements OnInit, AfterViewChecked {
  isOpen = false;
  LiveChatImage = "";
  loanType = loanType;
  captchaRequired = true;
  maxDate = new Date();
  formErrors: any = {};
  dateValid = false;
  isLoading = true;
  homeURL = "";
  newLead = true;
  mailingAddressSame = false;
  isIntoApplicationForm = false;
  isHiddenContactMe = false;
  width: string = "717px";
  siteKey = "";
  theme = "Light";
  language = "en";
  size = "Normal";
  type = "Image";
  recaptcha: string;
  badge = "Bottomright";
  ngbMaxDate: any;
  ngbMinDate: any;
  ngbMinEstCloseDate: any;
  public captchaResponse = "";

  lstStates = [
    {
      stateName: "",
    },
  ];
  lstPhoneTypes = [
    {
      phoneType: "",
      phoneValue: "",
    },
  ];
  lstOccupation = [
    {
      id: "",
      name: "",
    },
  ];
  recaptchaType = 'Image';
  recaptchaBadge = 'Bottomright';
  
  @ViewChild("custForm") custForm: FormGroup;

  dp1AutoFocus: boolean;
  @ViewChild("EstCloseDateDP") EstCloseDateDP: ElementRef;
  @ViewChild("coApplicantDOBDP") coApplicantDOBDP: ElementRef;
  @ViewChild("ApplicantDOBDP") ApplicantDOBDP: ElementRef;
  @ViewChild("propCountys") propCounty: ElementRef;
  @ViewChild("propStreetAddress") propStreetAddress: ElementRef;
  @ViewChild("popertyAdrress2") popertyAdrress2: ElementRef;
  @ViewChild("propStreetCity") propStreetCity: ElementRef;
  @ViewChild("propStreetState") propStreetState: ElementRef;
  @ViewChild("popertyZip") popertyZip: ElementRef;
  @ViewChild("loantype1") loantype: ElementRef;
  @ViewChild("mailingAddress1") mailingAddress1: ElementRef;
  @ViewChild("mailingAddress2") mailingAddress2: ElementRef;
  @ViewChild("mailingCity") mailingCity: ElementRef;
  @ViewChild("propMailingState") propMailingState: ElementRef;
  @ViewChild("mailZipCode") mailingZipCode: ElementRef;
  @ViewChild(ModelPopupComponent) popUp: ModelPopupComponent;
  @ViewChild(WizardComponent) public wizard: WizardComponent;
  @Input("mainWizard") public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();

  constructor(
    public router: Router,
    public commonService: CommonService,
    public constantsObj: Constants,
    // public leadDetails: Lead,
    private route: ActivatedRoute,
    private contactUsService: ContactUsService,
    public dataService: DataService,
    private custInfoService: CustInfoService,
    public homeService: HomeInfoServiceService,
    private zone: NgZone,
    public toolTipMessage: TooltipMessage,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string
  ) {
    var isSafari = this.commonService.isSafariBroswer();
    if (isSafari) {
      this.captchaRequired = false;
      this.siteKey = siteKeyValueV3;
    } else {
      this.siteKey = siteKeyValueV3;
    }
    if (!this.commonService.isSafariBroswer()) {
      // $(".grecaptcha-badge").remove();
      $(".grecaptcha-badge").css("display", "block !important");
      $(".grecaptcha-badge").css("visibility", "visible !important");
    }
    this.LiveChatImage = constantsObj.LiveChatImage;
    this.homeURL = homesiteURL;
    this.ngbMaxDate = {
      year: new Date().getFullYear() - 18,
      month: 12,
      day: 31,
    };
    this.ngbMinDate = { year: 1900, month: 1, day: 1 };
    this.ngbMinEstCloseDate = { year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate() };

  }

  ngOnInit() {

    // this.reCaptchaV3Service.execute(
    //   this.siteKey,
    //   "homepage",
    //   (token) => {
    //     console.log('token generation');
    //     // this.leadDetails.reCaptcha.response = token;
    //   }
    // );
    this.commonService.ScrollToRTQForm();
    // $("html, body").animate({ scrollTop: $('#divCustInfo').offset().top }, "slow");
    // $(".help").tooltip({
    //   title: '<p class="text-justify">Possible Discounts</p>',
    //   html: true,
    //   placement: "top",
    // });
    this.getStates();
    //this.getPhoneTypes();
    this.dataService.leadHistory = new LeadHistory();
    this.dataService.leadHistory.preValue = JSON.parse(
      JSON.stringify(this.dataService.leadDetails)
    );
    this.getLeadInfo();
    this.processDate(this.leadDetails);
  }

  LoadChat(): any {
    var status = true;
    if (
      this.dataService != null &&
      this.dataService.leadDetails != null &&
      this.dataService.leadDetails.trackingInfo != null &&
      this.dataService.leadDetails.trackingInfo.currentPageName ==
        this.dataService.leadDetails.trackingInfo.previousPageName
    )
      status = false;
    return status;
  }
  GotoHomePage() {
    //Needs to changed based on the environment
    window.open(this.homeURL, "_parent");
  }

  clearEstCloseDate() {
    this.leadDetails.estCloseDate = null;
  }

  savePageDetails() {
    this.isLoading = true;
    this.setHourstoDate(this.leadDetails);
    this.commonService.CreateLeadHistoryObj(1, this.leadDetails);
    this.custInfoService.postLeadInfo(this.leadDetails).subscribe(
      (data: Lead) => {
        this.commonService.SaveLeadHistoryFromContactBtn();
        this.leadDetails = data;
        this.isLoading = false;
        // this.commonService.NavigateToPage("/real-time-quote/contact");
        this.leadUpdateEvent.emit(this.leadDetails);
        if(this.leadDetails.isAuthRequired) {
          this.wizardStep.goToNextStep();
        } else {
          this.wizardStep.goToStep(3);
        }
        return true;
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
        return false;
      }
    );
  }
  setHourstoDate(leadDetails: Lead) {

  }

  onSubmit(custForm) {
    const isAuthRequired = this.leadDetails.isAuthRequired;
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(custForm, this.formErrors);

    // this.formErrors = this.commonService.updateControls(custForm);
    if (!custForm.valid) {
      this.commonService.focusFirstInvalidElement();
      return false;
    }
    // this.commonService.ScrollToArticleDetailsHeader();
    this.commonService.ScrollToRTQForm();
    this.setHourstoDate(this.leadDetails);
    if (this.leadDetails.isEmailProspect) {
      this.captchaRequired = false;
      if (custForm.valid) {
        this.isLoading = true;
        this.custInfoService.postLeadInfo(this.leadDetails).subscribe(
          (data: Lead) => {
            this.leadDetails = data;
            this.isLoading = false;
            this.dataService.leadHistory.noUpdate = custForm["form"].pristine;
            console.log('CUST-001: ', this.leadDetails.homeInfo);
            console.log('CUST-001A: ', this.leadDetails.isAuthRequired);
            if(!isAuthRequired) {
              this.dataService.leadHistory.preValue = JSON.parse(JSON.stringify(this.leadDetails));
              this.leadDetails.homeInfo.isDataQuickRan = true;
              this.homeService.getHomeInfo(this.leadDetails).subscribe((data: Lead) => {
                console.log('CUST-002: ', data.homeInfo);
                this.dataService.leadHistory.pageNumber = 99;
                this.dataService.leadHistory.noUpdate = false;
                //this.commonService.SaveLeadHistory();
                this.dataService.leadHistory.preValue = JSON.parse(JSON.stringify(data));
                this.dataService.leadHistory.pageNumber = 3;
                this.dataService.leadHistory.preValue.policyTypeID = this.dataService.leadDetails.policyTypeID;
                this.dataService.leadHistory.preValue.homeInfo.homeType = this.dataService.leadHistory.postValue.homeInfo.homeType;
                this.dataService.leadHistory.preValue.homeInfo.percentageFinished = this.dataService.leadHistory.postValue.homeInfo.percentageFinished;
                this.dataService.leadHistory.preValue.homeInfo.roofShape = this.dataService.leadHistory.postValue.homeInfo.roofShape;
                this.dataService.leadHistory.preValue.homeInfo.roofType = this.dataService.leadHistory.postValue.homeInfo.roofType;
                this.leadDetails.homeInfo = data.homeInfo;
                console.log('CUST-003: ', this.leadDetails.homeInfo);
                this.setDropdownValuesforHomeInfo();
                // this.setInformationMessage();
                this.leadUpdateEvent.emit(this.leadDetails);
                this.isLoading = false;
                this.wizardStep.goToNextStep();
              });
            } else {
              this.isLoading = false;
              this.wizardStep.goToNextStep();
            }
            // this.commonService.NavigateToPage("/real-time-quote/credit-auth");
          },
          (err) => {
            this.isLoading = false;
            this.dataService.handleError(err);
          }
        );
      } else {
        this.commonService.updateControls(custForm);
        return false;
      }
    } else {
      if (custForm.valid) {
        this.dataService.leadHistory.pageNumber = 1;
        if (this.leadDetails.isIntoApplicationForm == true) {
          this.dataService.leadHistory.noUpdate = custForm["form"].pristine;
        } else this.dataService.leadHistory.noUpdate = true;
        this.invokeSaveLead();
      } else {
        this.commonService.updateControls(custForm);
      }
    }
  }
  invokeSaveLead() {
    this.isLoading = true;

    this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
      if (token != '') {
        // this.leadDetails.reCaptcha = new ReCaptcha();
        this.leadDetails.reCaptcha.response = token;
        this.custInfoService.postLeadInfo(this.leadDetails).subscribe(
          (data: Lead) => {
            this.leadDetails = data;
            if(this.leadDetails.customerAddress.state === 'CA' || this.leadDetails.customerAddress.state === 'MD') { 
              this.leadDetails.isAuthRequired = false;
            } else {
              this.leadDetails.isAuthRequired = true;
            }
            this.isLoading = false;
            if (!this.leadDetails.isDuplicateLead && this.leadDetails.message == "") {
              this.captchaRequired = true;
              this.leadUpdateEvent.emit(this.leadDetails);
              this.wizardStep.goToNextStep();
            } else {
              this.leadUpdateEvent.emit(this.leadDetails);
              $("html, body").animate(
                { scrollTop: $(document).height() - 800 },
                "slow"
              );
            }
          },
          (err) => {
            this.isLoading = false;
            this.dataService.handleError(err);
          }
        );
      }
    });
  }

  //For sercah icon in address line one
  changingAddressLineOne() {
    facIconTwo();
  }

  populateMailingAddress() {
    // facIconOne();
    // facIconTwo();
    if (this.leadDetails.isMailingAddressSame) {
      this.leadDetails.mailingAddress = JSON.parse(
        JSON.stringify(this.leadDetails.customerAddress)
      );
      $("#address-line-search-two").hide();
      this.mailingAddressSame = true;
    } else {
      $("#address-line-search-two").show();
      this.mailingAddressSame = false;
    }
  }
  clearData() {
    if (!this.leadDetails.isMailingAddressSame) {
      this.leadDetails.mailingAddress = new Address();
      // $("#address-line-search-two").show();
    }
  }

  getLeadInfo() {
    this.leadDetails = this.dataService.leadDetails;
    this.impersonateLead();
    if (
      this.dataService.leadDetails != null &&
      this.dataService.leadDetails.leadID != ""
    ) {
      this.isLoading = true;
      this.newLead = false;
      if (
        this.dataService.leadDetails.isInit &&
        this.dataService.leadDetails.isEmailProspect
      ) {
        this.FetchExistingLead();
        this.dataService.leadDetails.isInit = false;
      } else {
        //On previous btn from credit auth
        this.leadDetails = this.dataService.leadDetails;
        if (this.leadDetails.isMailingAddressSame) {
          this.mailingAddressSame = true;
        } else {
          this.mailingAddressSame = false;
        }
        this.isLoading = false;
        this.setNavigationFlag();
        $("#address-line-search-one").hide();
        $("#address-line-search-two").hide();
      }
    } else {
      this.isLoading = false;
      this.createNewLeadObj();
      this.newLead = true;
    }
  }

  hideDatePicker(dp) {
    dp.hide();
  }

  impersonateLead() {
    return; //need to uncomment after development
  }
  createNewLeadObj() {
    this.leadDetails = new Lead();
    this.dataService.leadDetails = new Lead();
    this.leadDetails.reCaptcha = new ReCaptcha();
    this.leadDetails.reCaptcha.secretKey = this.captchaSecretKeyV3;
    this.isLoading = false;
    this.setUpdaterCompanyCode();
    this.setNavigationFlag();
  }

  FetchExistingLead() {
    this.custInfoService.getLeadInfo(this.dataService.leadDetails).subscribe(
      (data: Lead) => {
        this.leadDetails = data;
        this.dataService.leadHistory = new LeadHistory();
        this.dataService.leadHistory.preValue = JSON.parse(
          JSON.stringify(data)
        );
        this.dataService.leadHistory.pageNumber = 1;
        this.processDate(data);
        this.isLoading = false;
        this.setUpdaterCompanyCode();
        this.setNavigationFlag();
        this.leadDetails.isPreviousBtnDisabled = true;
        $("#address-line-search-one").hide();
        $("#address-line-search-two").hide();
        if (data.isMailingAddressSame) {
          this.mailingAddressSame = true;
        } else {
          this.mailingAddressSame = false;
        }
        this.leadUpdateEvent.emit(this.leadDetails);
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }

  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }
  customDateParse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
      // return value;
    } else if (typeof value === "string" && value.indexOf("-") > -1) {
      const str = value.split("-");
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const date = Number(str[2].split(/[: T-]/)[0]);
      return new Date(year, month, date);
    } else if (typeof value === "string" && value === "") {
      return new Date();
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  processDate(data: Lead) {
  }

  getStates() {
    this.custInfoService.getStates().subscribe(
      (data: any) => {
        this.lstStates = data.lstStates;
        this.lstPhoneTypes = data.lstPhones;
        this.lstOccupation = data.listOccupation;
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }
  getPhoneTypes() {
    this.custInfoService.getPhoneTypes().subscribe(
      (data: any) => {
        this.lstPhoneTypes = data;
      },
      (err) => {
        this.isLoading = false;
        this.dataService.handleError(err);
      }
    );
  }

  SetMailingAddressDetails(event) {
    this.zone.run(() => {
            if (event.street_number != undefined && event.route != undefined)
        this.leadDetails.mailingAddress.streetAddress =
          event.street_number + " " + event.route;
      else if (event.route != undefined)
        this.leadDetails.mailingAddress.streetAddress = event.route;
      else if (event.street_number != undefined)
        this.leadDetails.mailingAddress.streetAddress = event.street_number;

      this.leadDetails.mailingAddress.line2 = "";
      this.leadDetails.mailingAddress.city = event.locality;
      this.leadDetails.mailingAddress.state = event.admin_area_l1;
      this.leadDetails.mailingAddress.county = event.admin_area_l2;
      this.leadDetails.mailingAddress.zipCode = event.postal_code || '';
      
      this.mailingAddress1.nativeElement.value = this.leadDetails.mailingAddress.streetAddress;
      this.mailingAddress2.nativeElement.value = this.leadDetails.mailingAddress.line2;
      this.propMailingState.nativeElement.value = this.leadDetails.mailingAddress.state;
      this.mailingCity.nativeElement.value = this.leadDetails.mailingAddress.city;
      this.mailingZipCode.nativeElement.value = this.leadDetails.mailingAddress.zipCode;
          });
  }

  onChangePropertyState(propertyState) {
    if(propertyState.value === 'CA' || propertyState.value === 'MD') { 
      this.leadDetails.isAuthRequired = false;
    } else {
      this.leadDetails.isAuthRequired = true;
    }
  }

  copyPropertyAddressToMailing(event: any) {
    if (event.target.checked) {
      this.leadDetails.isMailingAddressSame = true;
      this.populateMailingAddress();
    } else {
      this.leadDetails.isMailingAddressSame = false;
      this.clearData();
    }
  }

  SetPropertyAddressDetails(event) {
    this.zone.run(() => {
      if (event.street_number != undefined && event.route != undefined) {
        this.leadDetails.customerAddress.streetAddress =
          event.street_number + " " + event.route;
      } else if (event.route != undefined) {
        this.leadDetails.customerAddress.streetAddress = event.route;
      } else if (event.street_number != undefined) {
        this.leadDetails.customerAddress.streetAddress = event.street_number;
      }
      this.leadDetails.customerAddress.line2 = "";
      this.leadDetails.customerAddress.city = event.locality;
      this.leadDetails.customerAddress.state = event.admin_area_l1;
      this.leadDetails.customerAddress.county = event.admin_area_l2;
      this.leadDetails.customerAddress.zipCode = event.postal_code || '';

      this.propStreetAddress.nativeElement.value =
        this.leadDetails.customerAddress.streetAddress;
      this.popertyAdrress2.nativeElement.value =
        this.leadDetails.customerAddress.line2;
      this.propStreetState.nativeElement.value =
        this.leadDetails.customerAddress.state;
      this.propStreetCity.nativeElement.value =
        this.leadDetails.customerAddress.city;
      this.popertyZip.nativeElement.value =
        this.leadDetails.customerAddress.zipCode;
      // this.propCounty.nativeElement.value =
      //   this.leadDetails.customerAddress.county;

      if(this.leadDetails.customerAddress.state === 'CA' || this.leadDetails.customerAddress.state === 'MD') { 
        this.leadDetails.isAuthRequired = false;
      } else {
        this.leadDetails.isAuthRequired = true;
      }
      this.loantype.nativeElement.focus();
    });
  }

  checkPhone(event: any) {
    if(event.target.value === '(') {
      event.target.value = '';
    }
  }

  ngAfterViewChecked() {
    $("#angularBasicCaptcha_ReloadIcon").click(function () {});
    if (!this.commonService.isSafariBroswer()) {
       //$(".grecaptcha-badge").remove();
    } else {
      $(".grecaptcha-badge").css("display", "block !important");
      $(".grecaptcha-badge").css("visibility", "visible !important");
    }
    this.setTooltip();
  }

  setTooltip = function () {
    $('.helperPrimaryAppDob').tooltip({ title: `<p class='text-left'>Date of Birth: <br/> ${this.toolTipMessage.dateOfBirth} </p> `, html: true, placement: "top" });
    return '';
  }

  loanTypeUpdateValidation(value) {
    this.leadDetails.loanType = value;
    this.clearEstCloseDate();
  }

  setUpdaterCompanyCode() {
    this.route.queryParams.subscribe((params) => {
      this.leadDetails.sourceInfo = new Source();
      this.leadDetails.sourceInfo.code = params["code"];
      if (params["code"] != undefined)
        this.leadDetails.isPreviousBtnDisabled = true;
    });
  }
  setNavigationFlag() {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        if (e[0].urlAfterRedirects != "")
          this.leadDetails.isIntoApplicationForm = true;
        else this.leadDetails.isIntoApplicationForm = false;
      });
  }

  //For google re-CAPTCHA
  handleReady() {
    this.captchaRequired = false;
  }
  handleSuccess(event) {
    if (event != "") {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA
  handler(value: string): void {
    if (value == "EstCloseDateDP") {
      this.EstCloseDateDP.nativeElement.focus();
    } else if (value == "ApplicantDOBDP") {
      this.ApplicantDOBDP.nativeElement.focus();
    } else {
      this.coApplicantDOBDP.nativeElement.focus();
    }
  }
  ngAfterViewInit() {
    //     setTimeout(() => this.input.nativeElement.focus());
  }
  setContacteMeHidden() {
    this.isHiddenContactMe = false;
    if (this.leadDetails.isDuplicateLead) {
      this.isHiddenContactMe = true;
    } else if (
      this.leadDetails.leadID == "" ||
      this.leadDetails.leadID == null
    ) {
      this.isHiddenContactMe = true;
    }
    return this.isHiddenContactMe;
  }
  formatDate(event: any, dobControl) {
    const inputDate = event.target.value;
    const formattedDate = inputDate.replace(
      /(\d{2})(\d{2})(\d{4})/,
      "$1/$2/$3"
    );
    const isValidFormat = /^\d{8}$/.test(inputDate);

    if (isValidFormat) {
      this.leadDetails[dobControl] = formattedDate;
      this.custForm.controls[dobControl].setValue(formattedDate);
    }
  }

  public resolved(captchaResponse: string): void {
    const newResponse = captchaResponse
      ? `${captchaResponse.substr(0, 7)}...${captchaResponse.substr(-7)}`
      : captchaResponse;
    this.captchaResponse += `${JSON.stringify(newResponse)}\n`;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaResponse += `ERROR; error details (if any) have been logged to console\n`;
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  setDropdownValuesforHomeInfo() {
    console.log('set: ', this.leadDetails.homeInfo);
    if (this.leadDetails.homeInfo != null) {
      if (this.leadDetails.homeInfo.constructionType == null)
        this.leadDetails.homeInfo.constructionType = "";
    }
    if (this.leadDetails.homeInfo.homeType == null) {
      this.leadDetails.homeInfo.homeType = "";
    }
    if (this.leadDetails.homeInfo.propertyType == null) {
      this.leadDetails.homeInfo.propertyType = "";
    }
    if (this.leadDetails.homeInfo.garageType == null) {
      this.leadDetails.homeInfo.garageType = "";
    }

    if (this.leadDetails.homeInfo.foundationType == null) {
      this.leadDetails.homeInfo.foundationType = "";
    }
    if (this.leadDetails.homeInfo.roofType == null) {
      this.leadDetails.homeInfo.roofType = "";
    }
    if (this.leadDetails.homeInfo.unitNumber == null) {
      this.leadDetails.homeInfo.unitNumber = 0;
    }
    if (this.leadDetails.homeInfo.roofShape == null) {
      this.leadDetails.homeInfo.roofShape = "";
    }
  }
}