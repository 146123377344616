<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-contact-us" class="sub">
  <div class="container">
    <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-8 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Contact us</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!--  F O R M   -->
<form (ngSubmit)="onSubmit(contactUsForm)" #contactUsForm="ngForm">
  <section id="request-change-form" class="pb-5 pt-2">
    <div class="container">
      <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
      <div class="row pt-3 justify-content-center">
        <div class="col-md-7 col-xs-12">
          <span class="pl-3">Send Us a Message</span>
          <div class="form-div">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                <label for="FirstName">First Name <span>*</span></label>
                <input type="text" class="form-control" [(ngModel)]="contactUsDetails.firstName" name="firstName"
                  required #firstName="ngModel" maxlength="17" />
                <label *ngIf="(firstName.hasError('required') && firstName.touched)"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                <label for="LastName">Last Name <span>*</span></label>
                <input type="text" class="form-control" [(ngModel)]="contactUsDetails.lastName" name="lastName"
                  maxlength="20" required #lastName="ngModel" />
                <label *ngIf="(lastName.hasError('required') && lastName.touched)"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col mt-3">
                <label for="Email">Email Address <span>*</span></label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  [(ngModel)]="contactUsDetails.email" name="email" required #email="ngModel" emailValidator
                  maxlength="100">
                <label *ngIf="(email.hasError('required') && email.touched)" class="error-message text-nowrap">
                  {{constantsObj.requiredErrorMessage}}
                </label>
                <label *ngIf="(email.hasError('emailValidator') && email.touched && !email.hasError('required'))"
                  class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col mt-3">
                <label for="Company">Company</label>
                <input type="text" class="form-control" [(ngModel)]="contactUsDetails.company" name="company">
              </div>
            </div>
            <div class="row">
              <div class="col mt-3">
                <label for="Number">Phone Number <span>*</span></label>
                <input type="text" class="form-control" [(ngModel)]="contactUsDetails.phoneNumber"
                  id="txtPreferredPhone" placeholder="(999) 999-9999" minlength="10" mask="(000) 000-0000" required
                  name="phoneNumber" #phoneNumber="ngModel" />
                <label *ngIf="(phoneNumber.hasError('required') && phoneNumber.touched )"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                <label *ngIf="(phoneNumber.hasError('minlength') && phoneNumber.touched) "
                  class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>

                <label *ngIf="(phoneNumber.hasError('minlength') && phoneNumber.touched)"
                  class="error-message text-nowrap">
                  {{constantsObj.invalidFormatErrorMessage}}
                </label>

              </div>
            </div>
            <div class="row">
              <div class="col mt-3">
                <label for="Can-we-help">What can we help you with?</label>
                <textarea class="form-control" aria-label="With textarea" placeholder="Enter your message here..."
                  [(ngModel)]="contactUsDetails.message" name="message"></textarea>
              </div>
            </div>
          </div>

          <div class="container pt-4">
            <div class="row">
              <p class="req-msg-note pl-3">*These fields are required</p>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <!-- <div class="row pl-3 text-center">
                <div class="col pt-3  pr-5 pb-2 text-right" *ngIf="!this.commonService.isSafariBroswer()">
                  <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language" (success)="handleSuccess($event)"
                    (load)="handleLoad()" (expire)="handleExpire()" [theme]="theme" [type]="type"
                    [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
                  </ngx-recaptcha2>
                </div>
              </div> -->
              <div class="row pl-3 text-center">
                <div class="col pt-3  pr-5 pb-2 text-right" *ngIf="false">
                  <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                    (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type" [badge]="badge"
                    [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                  </ngx-invisible-recaptcha>

                </div>
              </div>
            </div>
            <label *ngIf="(captchaRequired && contactUsForm.submitted)"
              class="error-message text-nowrap pl-2">{{constantsObj.requiredErrorMessage}}</label>
          </div>

          <div class="container">
            <div class="row">
              <div class="row pl-2 text-center">
                <div class="col pr-5 pb-5">
                  <button type="submit" class="btn pl-2">
                    <div class="angled-button-sm button-width-110">
                      <span>Send</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12 pt-5 pl-4 side-bar">
          <div class="row">
            <div class="col-4 text-center">
              <img src="assets/img/icons/locator-pin.png" width="50" alt="locator pin icon" />
            </div>
            <div class="col-8 pl-0">
              Westwood Insurance Agency<br>
              6320 Canoga Avenue, Suite 500<br>
              Woodland Hills, CA 91367<br><br>
            </div>
          </div>
          <div class="row top-border-element pt-4">
            <div class="col-4 text-center">
              <img src="assets/img/icons/cell-large.png" width="35" alt="locator pin icon" />
            </div>
            <div class="col-8 pl-0">
              Current Customers<br>
              <a class="required-label" href="tel:800.676.5066">800.676.5066</a>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-4 text-center">
              <img src="assets/img/icons/cell-large.png" width="35" alt="locator pin icon" />
            </div>
            <div class="col-8 pl-0">
              New Customers<br>
              <a href="tel:18885436179" class="telNum">888.543.6179</a>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-4 text-center">
              <img src="assets/img/icons/env.png" width="50" alt="locator pin icon" />
            </div>
            <div class="col-8 pl-0">
              Email us<br>
              <a href="mailto:Contactus@Westwoodins.com">ContactUs@Westwoodins.com</a>
            </div>

          </div>
          <div class="row ml-1" style="padding-top: 6rem;">

            <span class="col-12">
              Connect with us
            </span>
            <div class="col-12 pt-3">
              <div class="row m-0 p-0 social">
                <div class="col-3 p-1">
                  <div class="social-icon-box">
                    <a href="https://www.linkedin.com/company/westwood-insurance-agency" class="social border-color social-icons" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <div class="social-icon-box">
                    <a href="https://www.youtube.com/@westwoodinsuranceagency" class="social border-color social-icons" target="_blank"><i class="fab fa-youtube"></i></a>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <div class="social-icon-box">
                    <a href="https://www.instagram.com/westwoodinsuranceagency" class="social border-color social-icons" target="_blank"><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <div class="social-icon-box">
                    <a href="https://www.facebook.com/WestwoodInsuranceAgency/" class="social border-color social-icons" target="_blank"><i class="fab fa-facebook-f"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
<!-- // F O R M  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section> -->


<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->