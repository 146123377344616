import { LookUpValues } from './look-up-values';

export class HomeInfo {
    public constructionType: string;
    public homeType: string;
    public propertyType: string;
    public garageType: string;
    public garageSize: string;
    public foundationType: string;
    public roofType: string;
    public roofShape: string;
    public solarPanel:string;
    public waterSensor:string;
    public unitNumber: number;
    public percentageFinished: number;
    public lstConstructionType: Array<LookUpValues>;
    public lstHomeType: Array<LookUpValues> =[];
    public lstPropertyType:  Array<LookUpValues> =[];
    public lstGarageType: Array<LookUpValues> =[];
    public lstGarageSize: Array<LookUpValues> =[];
    public lstFoundationType: Array<LookUpValues> =[];
    public lstRoofType: Array<LookUpValues> =[];
    public lstRoofShape: Array<LookUpValues> =[];
    public lstSolarPanel:Array<LookUpValues> =[];
    public lstWaterSensor:Array<LookUpValues> =[];
    public lstUnitNumber: Array<LookUpValues> =[];
    public lstPercentageFinished: Array<LookUpValues> =[];
    public squareFootage?:  number;
    public yearBuild?:  number;
    public noOfBedrooms?: number;
    public noOfBathRooms?:number ;
    public noOfStories?: Number;
    public noOfFirePlaces?:number;
    public isDataQuickRan: boolean;
    public  isDataQuickFailed: boolean;
    public inValidPropertyType:boolean;
    public message:string;
    constructor() {
        this.homeType=  '';
        this.propertyType=  '';
        this.garageType=  '';
        this.foundationType=  '';
        this.roofType=  '';
        this.roofShape=  '';
        this.constructionType=  '';
        this.garageSize=  "1";
        this.unitNumber=  1;
        this.lstHomeType=  new Array<LookUpValues>();
        this.lstPropertyType=  new  Array<LookUpValues>();
        this.lstGarageType=  new Array<LookUpValues>();
        this.lstFoundationType=  new Array<LookUpValues>();
        this.lstRoofType=  new Array<LookUpValues>();
        this.lstRoofShape=  new Array<LookUpValues>();
        this.lstConstructionType=  new Array<LookUpValues>();
        this.lstGarageSize=  new Array<LookUpValues>();
        this.lstUnitNumber=  new Array<LookUpValues>();
        this.squareFootage= null;
        this.yearBuild= null;
        this.noOfBedrooms = null;
        this.noOfBathRooms=null;
        this.noOfStories=null;
        this.noOfFirePlaces=null;
        this.isDataQuickRan=false;
        this.isDataQuickFailed=false;
        this.inValidPropertyType=false;
        this.message='';
    }
}
