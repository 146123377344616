import { Directive } from '@angular/core';
import { FormControl, Validator, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[contactEmailValidator][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ContactEmailValidatorDirective,
    multi: true
  }]
})
export class ContactEmailValidatorDirective implements Validator {
  validator: ValidatorFn;
  emailPattern=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor() { 
    this.validator = this.contactEmailValidator();
  }

  validate(c: FormControl): { [key: string]: any } {
    return this.validator(c);
  }

  contactEmailValidator(): ValidatorFn {
    return (c: FormControl) => {
      if (c.value == null) {
        return null;
      }
      if(c.parent.controls["contactType"])
      {
        if(c.parent.controls["contactType"].value!='3')
        {
          return null;
        }
      }
      if (!(this.emailPattern.test(c.value))) {
        return {
          contactEmailValidator: {
            valid: false
          }
        };
      }
      return null;
    };
  }
}
