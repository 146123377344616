import { Component, OnInit, TemplateRef, ViewChild, Input, Output, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'; 
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-model-popup',
  templateUrl: './model-popup.component.html',
  styleUrls: ['./model-popup.component.css']
})
export class ModelPopupComponent implements OnInit {

  //To invoke the method of parent component
  @Output() savePageDetails: EventEmitter<any> = new EventEmitter();
  constructor(private modalService: BsModalService) { }
  modalRef: BsModalRef;
  
  //To get the tenplate HTML in the .html page
  @ViewChild('template', { static:true }) public templateRef: TemplateRef<any>;
  @ViewChild('btnCallMe', {static:true}) btnCallMe:ElementRef;

  ngOnInit() {
    
  }
  confirm(event:any): void {
    this.savePageDetails.emit(event);
    this.modalRef.hide();
  }
  decline():void{
  this.modalRef.hide();
  }
  public openModal() {
    this.modalService.config.show=true;
    this.modalService.config.backdrop="static";
    this.modalService.config.keyboard=false;
    this.modalRef = this.modalService.show(this.templateRef);
  }

}
