import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ArchwizardModule } from "angular-archwizard";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { InsuranceLicensingComponent } from "./insurance-licensing/insurance-licensing.component";
import { ResourceCenterComponent } from "./resource-center/resource-center.component";
import { ContactUsComponent } from "../shared/contact-us/contact-us.component";
import { ProductsComponent } from "./products/products.component";
import { HomeownersComponent } from "./homeowners/homeowners.component";
import { CondoComponent } from "./condo/condo.component";
import { RentersComponent } from "./renters/renters.component";
import { AutoComponent } from "./auto/auto.component";
import { FloodComponent } from "./flood/flood.component";
import { EarthquakeComponent } from "./earthquake/earthquake.component";
import { UmbrellaComponent } from "./umbrella/umbrella.component";
import { JewelryAndValuablesComponent } from "./jewelry-and-valuables/jewelry-and-valuables.component";
import { GolfComponent } from "./golf/golf.component";
import { MotorcycleComponent } from "./motorcycle/motorcycle.component";
import { HomeWarrantyServiceAgreementComponent } from "./home-warranty-service-agreement/home-warranty-service-agreement.component";
import { PetInsuranceComponent } from "./pet-insurance/pet-insurance.component";
import { TravelInsuranceComponent } from "./travel-insurance/travel-insurance.component";
import { EventComponent } from "./event/event.component";
import { BicycleComponent } from "./bicycle/bicycle.component";
import { CurrentCustomersComponent } from "./current-customers/current-customers.component";
import { MakeAPaymentComponent } from "./make-apayment/make-apayment.component";
import { RequestPolicyChangesComponent } from "./request-policy-changes/request-policy-changes.component";
import { MyPolicyDocumentsComponent } from "./my-policy-documents/my-policy-documents.component";
import { LenderRequestsChangesComponent } from "./lender-requests-changes/lender-requests-changes.component";
import { LenderChangesComponent } from "./lender-changes/lender-changes.component";
import { OptInForTextMessagesComponent } from "./opt-in-for-text-messages/opt-in-for-text-messages.component";
import { ClaimsComponent } from "./claims/claims.component";
import { ReportAClaimComponent } from "./report-aclaim/report-aclaim.component";
import { ClaimPreventionComponent } from "./claim-prevention/claim-prevention.component";
import { MinimizeYourLossComponent } from "./minimize-your-loss/minimize-your-loss.component";
import { PartnerWithUsComponent } from "./partner-with-us/partner-with-us.component";
import { GetAQuoteComponent } from "./get-aquote/get-aquote.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { CustomerInfoComponent } from "./customer-info/customer-info.component";
import { CreditAuthComponent } from "./credit-auth/credit-auth.component";
import { CommonService } from "../service/common.service";
import { HomeInfoComponent } from "./home-info/home-info.component";
import { UnderwritingComponent } from "./underwriting/underwriting.component";
import { QuoteMessageComponent } from "./quote-message/quote-message.component";
import { QuoteResultComponent } from "./quote-result/quote-result.component";
import { CustNoticeComponent } from "./cust-notice/cust-notice.component";
import { PrintFormatComponent } from "./print-format/print-format.component";
import { RcvDetailsComponent } from "./rcv-details/rcv-details.component";
import { LoanInfoComponent } from "./loan-info/loan-info.component";
import { QuoteThankYouComponent } from "./quote-thank-you/quote-thank-you.component";
import { ContactMeComponent } from "./contact-me/contact-me.component";
import { LegalComponent } from "./legal/legal.component";
import { UnSubscribeComponent } from "./un-subscribe/un-subscribe.component";
import { OptoutTextMsgesComponent } from "./optout-text-msges/optout-text-msges.component";
import { OptInRentersComponent } from "./opt-in-renters/opt-in-renters.component";
import { ValidateEmailProspectComponent } from "./validate-email-prospect/validate-email-prospect.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { SsnComponent } from "./ssn/ssn.component";
import { CcpaComponent } from "./ccpa/ccpa.component";
import { SmallCommercialComponent } from "./small-commercial/small-commercial.component";
import { TravelOnlineQuoteComponent } from "./travel-online-quote/travel-online-quote.component";
import { Alert1InfoComponent } from "./alert1-info/alert1-info.component";
import { Alert2InfoComponent } from "./alert2-info/alert2-info.component";
import { LegalHomeComponent } from "./legal-home/legal-home.component";
import { UnauthorisedAccessComponent } from "./unauthorised-access/unauthorised-access.component";
import { MobilePhoneInsuranceComponent } from "./mobile-phone-insurance/mobile-phone-insurance.component";
import { MyquoteComponent } from "./myquote/myquote.component";
import { NewsArticlesComponent } from "./news-articles/news-articles.component";
import { ArticleDetailComponent } from "./article-detail/article-detail.component";
import { SiteMapComponent } from "./site-map/site-map.component";
import { PartnerApiComponent } from "./partner-api/partner-api.component";
import { PartnerLandingComponent } from "./partner-landing/partner-landing.component";
import { PartnerBuilderComponent } from "./partner-builder/partner-builder.component";
import { PartnerLenderComponent } from "./partner-lender/partner-lender.component";
import { LeadershipComponent } from "./leadership/leadership.component";
import { QuoteContainerComponent } from "./quote-container/quote-container.component";
import { RatingFailedComponent } from "./rating-failed/rating-failed.component";
import { RtqLandingPageComponent } from "./rtq-landing-page/rtq-landing-page.component";

const routes: Routes = [
  // { path: 'site-map', component: SiteMapComponent, data: { title: 'Site Map' } },
  // { path: 'news/article/:title', component: ArticleDetailComponent, data: { title: 'Article' } },
  // { path: 'news', component: NewsArticlesComponent, data: { title: 'News' } },
  { path: "myquote", component: MyquoteComponent, data: { title: "My Quote" } },
  {
    path: "unsubscribe/:id",
    component: UnSubscribeComponent,
    data: { title: "Unsubscribe" },
  },
  // { path: 'opt-out-text-msges', component: OptoutTextMsgesComponent, data: { title: 'Opt Out Text Msges' } },
  //{ path: 'partner-api', component: PartnerApiComponent, data: { title: 'Partner API' } },
  // { path: 'opt-in-renters', component: OptInRentersComponent, data: { title: 'Opt In Renters' } },
  // { path: 'about-us', component: AboutUsComponent, data: { title: 'About Westwood Insurance Agency | Westwood Insurance', metaContent: 'Westwood Insurance Agency is one of the largest personal lines agencies in the United States. Learn how we’ve helped protect what matters most since 1952.' } },
  // { path: 'about-us/resource-center', component: ResourceCenterComponent, data: { title: 'Insurance Resource Center | Westwood Insurance', metaContent: 'In addition to quality products, Westwood Insurance Agency offers information, tools and resources to help you better understand your insurance needs.' } },
  // { path: 'about-us/insurance-licensing', component: InsuranceLicensingComponent, data: { title: 'Insurance Licensing | Westwood Insurance', metaContent: 'Westwood Insurance Agency is licensed in all 50 states and the District of Columbia. No matter where you live, we are here to serve you.' } },
  // { path: 'about-us/leadership-team', component: LeadershipComponent, data: { title: 'Meet Our Team' } },
  // { path: 'contact-us', component: ContactUsComponent, data: { title: "Contact Us: We're Here To Help | Westwood Insurance", metaContent: 'Contact Westwood Insurance Agency by online message, phone or email.' } },
  // { path: 'products', component: ProductsComponent, data: { title: 'Home Insurance & More: Products | Westwood Insurance', metaContent: "Homeowners, renters, auto, small business & more. Westwood Insurance Agency works with leading insurance companies to offer a full line of products." } },
  // { path: 'products/homeowners', component: HomeownersComponent, data: { title: 'Homeowners Insurance: Online Quotes | Westwood Insurance', metaContent: "Protect your financial investment in your home and its contents. Get a homeowners quote with Westwood Insurance Agency." } },
  // { path: 'products/condo', component: CondoComponent, data: { title: 'Condo Insurance: Get a Condo Quote | Westwood Insurance', metaContent: "Cover your condo and personal property for damage, theft, liability and more. Westwood Insurance Agency can help you get a condo insurance quote." } },
  // { path: 'products/renters', component: RentersComponent, data: { title: 'Renters Insurance: Get a Renters Quote | Westwood Insurance', metaContent: "Enjoy peace of mind knowing that your personal belongings are insured. Get a renters insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/auto', component: AutoComponent, data: { title: 'Auto Insurance: Get an Auto Quote | Westwood Insurance', metaContent: "Find the best auto coverage and rate for you. Get an auto insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/flood', component: FloodComponent, data: { title: 'Flood Insurance: Get a Flood Quote | Westwood Insurance', metaContent: "Flood insurance is not included in homeowners insurance. It costs less than you think, don't take the risk. Call Westwood Insurance Agency for a quote." } },
  // { path: 'products/earthquake', component: EarthquakeComponent, data: { title: 'Earthquake Insurance: Get a Quote | Westwood Insurance', metaContent: "Earthquake coverage is not included in homeowners insurance. Get an earthquake insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/umbrella', component: UmbrellaComponent, data: { title: 'Umbrella Insurance: Get a Quote | Westwood Insurance', metaContent: "Get additional protection for your assets from a liability lawsuit with umbrella insurance. Call Westwood Insurance Agency for a quote." } },
  // { path: 'products/jewelry-and-valuables', component: JewelryAndValuablesComponent, data: { title: 'Jewelry and Valuables Insurance: Quotes | Westwood Insurance', metaContent: "Protect valuables that may not be covered by your homeowners, condo or renters policy. Call Westwood Insurance Agency for a jewelry & valuables insurance quote." } },
  // { path: 'products/golf-cart', component: GolfComponent, data: { title: 'Golf Cart Insurance: Get a Quote | Westwood Insurance', metaContent: "Find the right coverage solution for the way you use your golf cart. Call Westwood Insurance Agency for a golf cart insurance quote." } },
  // { path: 'products/motorcycle', component: MotorcycleComponent, data: { title: 'Motorcycle Insurance: Get a Quote | Westwood Insurance', metaContent: "Find the best policy and rate for your motorcycle and riding style. Call Westwood Insurance Agency for a motorcycle insurance quote." } },
  // { path: 'products/pet-insurance', component: PetInsuranceComponent, data: { title: 'Pet Insurance: Get a Pet Quote | Westwood Insurance', metaContent: "Give your pets the care they deserve while protecting your wallet from unforeseen bills. Get a pet insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/travel-insurance', component: TravelInsuranceComponent, data: { title: 'Travel Insurance: Get a Travel Quote | Westwood Insurance', metaContent: "Protect your travel investment. Get a travel insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/event', component: EventComponent, data: { title: 'Event Insurance: Get an Event Quote | Westwood Insurance', metaContent: "Enjoy your event, knowing that your financial investment is protected. Get an event insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/bicycle', component: BicycleComponent, data: { title: 'Bicycle Insurance: Get a Bicycle Quote | Westwood Insurance', metaContent: "Protect  your bicycle from loss or damage, whether you use it for transportation or recreation. Get a bicycle insurance quote with Westwood Insurance Agency." } },
  // { path: 'products/small-business', component: SmallCommercialComponent, data: { title: 'Small Business Insurance: Get a Quote | Westwood Insurance', metaContent: "Keep your investment sound with business insurance. Get a quote with Westwood Insurance Agency." } },
  // { path: 'products/home-warranty-service-agreement', component: HomeWarrantyServiceAgreementComponent, data: { title: 'Home Warranty Service Agreement: Get a Quote | Westwood Insurance', metaContent: "Protect major household systems and appliances from expensive repairs and replacement cost. Get a quote with Westwood Insurance Agency." } },
  // { path: 'products/mobile-phone-insurance', component: MobilePhoneInsuranceComponent, data: { title: 'Mobile Phone Insurance' } },

  // { path: 'current-customers', component: CurrentCustomersComponent, data: { title: 'Current Customers | Westwood Insurance', metaContent: "Thank you for being a Westwood Insurance Agency customer! We’re here to help you with your insurance needs, and our goal is to earn your business for life." } },
  {
    path: "current-customers/make-a-payment",
    component: MakeAPaymentComponent,
    data: {
      title: "Make a Payment | Westwood Insurance",
      metaContent:
        "Making a payment is easy with Westwood Insurance Agency. Click the link next to your insurance company to go to the company’s payment information page.",
    },
  },
  {
    path: "current-customers/request-policy-changes",
    component: RequestPolicyChangesComponent,
    data: {
      title: "Request Policy Changes | Westwood Insurance",
      metaContent: "Request a policy change with Westwood Insurance Agency.",
    },
  },
  {
    path: "current-customers/my-policy-documents",
    component: MyPolicyDocumentsComponent,
    data: {
      title: "My Policy Documents | Westwood Insurance Agency",
      metaContent:
        "Getting a copy of your policy is easy with Westwood Insurance Agency!",
    },
  },
  {
    path: "current-customers/lender-requests-changes",
    component: LenderRequestsChangesComponent,
    data: {
      title: "Lender Requests & Changes | Westwood Insurance",
      metaContent:
        "Lenders can request evidence of insurance and/or make changes to mortgagee information for current insurance policies with Westwood Insurance Agency.",
    },
  },
  {
    path: "update-loan",
    component: LenderChangesComponent,
    data: {
      title: "Lender Changes | Westwood Insurance",
      metaContent:
        "Lenders can request evidence of insurance and/or make changes to mortgagee information for current insurance policies with Westwood Insurance Agency.",
    },
  },
  {
    path: "current-customers/opt-in-for-text-messages",
    component: OptInForTextMessagesComponent,
    data: {
      title: "Opt-in for Text Messages | Westwood Insurance Agency",
      metaContent:
        "Get your information via text from Westwood Insurance Agency!",
    },
  },
  // { path: 'current-customers/claims', component: ClaimsComponent, data: { title: 'Claims | Westwood Insurance', metaContent: "Westwood Insurance Agency is here to guide you every step of the way through the claims process." } },
  // { path: 'claims', component: ClaimsComponent, data: { title: 'Claims | Westwood Insurance', metaContent: "Westwood Insurance Agency is here to guide you every step of the way through the claims process." } },
  {
    path: "claims/report-a-claim",
    component: ReportAClaimComponent,
    data: { title: "Report a Claim" },
  },
  // { path: 'claims/claim-prevention', component: ClaimPreventionComponent, data: { title: 'Prevent a Claim' } },
  // { path: 'claims/minimize-your-loss', component: MinimizeYourLossComponent, data: { title: 'Minimize Your Loss' } },
  // { path: 'partners', component: PartnerLandingComponent, data: { title: 'Partner With Us | Westwood Insurance', metaContent: 'See what makes Westwood Insurance Agency the perfect partner to close on time, every time.' } },
  // { path: 'partner', component: PartnerLandingComponent, data: { title: 'Partner With Us | Westwood Insurance', metaContent: 'See what makes Westwood Insurance Agency the perfect partner to close on time, every time.' } },
  // { path: 'partner-with-us', component: PartnerLandingComponent, data: { title: 'Partner With Us | Westwood Insurance', metaContent: 'See what makes Westwood Insurance Agency the perfect partner to close on time, every time.' } },
  {
    path: "travel-insurance/travel-online-quote",
    component: TravelOnlineQuoteComponent,
    data: { title: "Travel Online Quote" },
  },
  {
    path: "get-a-quote",
    component: RtqLandingPageComponent,
    data: {
      title: "Insurance Quotes | Westwood Insurance",
      metaContent:
        "No matter what type of insurance you need, Westwood Insurance Agency will help you find the policy that’s right for you.",
    },
  },
  // { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  // { path: 'ssn', component: SsnComponent, data: { title: 'Privacy Policy SSN' } },
  // { path: 'ccpa', component: CcpaComponent, data: { title: 'Privacy Policy CCPA' } },
  // { path: 'alert1-info', component: Alert1InfoComponent, data: { title: 'Westwood Insurance Agency' } },
  // { path: 'alert2-info', component: Alert2InfoComponent, data: { title: 'Westwood Insurance Agency' } },
  // { path: 'legal', component: LegalHomeComponent, data: { title: 'Legal' } },
  {
    path: "real-time-quote/cust-info",
    component: QuoteContainerComponent,
    data: { title: "Real-Time-Quote" },
  },
  {
    path: "real-time-quote/additional-info",
    component: RatingFailedComponent,
    data: { title: "Real-Time-Quote" },
  },
  {
    path: "real-time-quote/quote-request",
    component: QuoteContainerComponent,
    data: { title: "Real-Time-Quote" },
  },

  // { path: 'partners/builders', component: PartnerBuilderComponent, data: { title: 'Partner with Builder' } },
  // { path: 'partners/lenders', component: PartnerLenderComponent, data: { title: 'Partner with Lender' } },
  // { path: 'partners/api', component: PartnerApiComponent, data: { title: 'Partner API' } },

  {
    path: "unauthorized-access",
    component: UnauthorisedAccessComponent,
    data: { title: "Unauthorized Access" },
  },
  // {
  //   path: 'real-time-quote/credit-auth',
  //   canActivate: [CommonService],
  //   component: CreditAuthComponent, data: { title: 'Real Time Quote' }
  // },
  // {
  //   path: 'real-time-quote/home-info',
  //   canActivate: [CommonService],
  //   component: HomeInfoComponent, data: { title: 'Real Time Quote' }
  // },
  // {
  //   path: 'real-time-quote/underwriting',
  //   canActivate: [CommonService],
  //   component: UnderwritingComponent, data: { title: 'Real Time Quote' }
  // },
  // { path: 'real-time-quote/legal', component: LegalComponent },
  // {
  //   path: 'real-time-quote/quote-message',
  //   canActivate: [CommonService],
  //   component: QuoteMessageComponent, data: { title: 'Real Time Quote' }
  // },
  // {
  //   path: 'real-time-quote/quote-result',
  //   canActivate: [CommonService],
  //   component: QuoteResultComponent, data: { title: 'Real Time Quote' }
  // },
  // {
  //   path: 'real-time-quote/cust-notice',
  //   canActivate: [CommonService],
  //   component: CustNoticeComponent, data: { title: 'Real Time Quote' }
  // },
  {
    path: "real-time-quote/quote-result/print",
    component: PrintFormatComponent,
    data: { title: "Real Time Quote" },
  },
  // {
  //   path: 'real-time-quote/loan-info',
  //   canActivate: [CommonService],
  //   component: LoanInfoComponent, data: { title: 'Real Time Quote' }
  // },
  {
    /*real-time-quote/quote-thank-you is being used in request-an-insurance-quote\request-an-insurance-quote.component.ts*/
    path: "real-time-quote/quote-thank-you",
    canActivate: [CommonService],
    component: QuoteThankYouComponent,
    data: { title: "Real Time Quote" },
  },
  {
    path: "real-time-quote/contact",
    canActivate: [CommonService],
    component: ContactMeComponent,
    data: { title: "Real Time Quote" },
  },
  {
    path: "get-home-quote",
    canActivate: [CommonService],
    component: RtqLandingPageComponent,
    data: { title: "Real Time Quote" },
  },
  {
    path: "real-time-quote/validate-email-prospect",
    component: ValidateEmailProspectComponent,
    data: { title: "Real Time Quote" },
  },
  {
    path: "",
    component: HomeComponent,
    data: {
      title: "Insurance Products For All Your Needs  Westwood Insurance",
      metaContent:
        "Westwood works with dozens of the world's best insurance companies to offer a personalized insurance portfolio tailored to your specific needs. Get a quote now.",
    },
    pathMatch: "full",
  },
  { path: "", redirectTo: "/real-time-quote/cust-info", pathMatch: "full" },
  {
    path: "page-not-found",
    component: PageNotFoundComponent,
    data: { title: "Page Not Found" },
  },
  { path: "**", redirectTo: "", data: { title: "Page Not Found" } },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ArchwizardModule, FontAwesomeModule],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
