import { Directive, Input } from '@angular/core';
import {
  FormControl,
  Validator,
  ValidatorFn,
  NG_VALIDATORS
} from '@angular/forms';
//import { debug } from 'util';

@Directive({
  selector: '[dateValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidatorDirective,
      multi: true
    }
  ]
})
export class DateValidatorDirective implements Validator {
  @Input('maxDate') inpMaxDate:any;
  validator: ValidatorFn;
  minDate = new Date('01/01/1900');

  constructor() {
    this.validator = this.dateValidator();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }

  dateValidator(): ValidatorFn {
    return (c: FormControl) => {
      let val: any = c.value;
      val = new Date(c.value);

      if (val == 'Invalid Date') {
        return {
          dateValidator: {
            valid: false
          }
        };
      } else {
        if (c.value != null && c.value != '') {
          const tempMaxDate = this.inpMaxDate.month + '/' + this.inpMaxDate.day + '/' + this.inpMaxDate.year;
          const minDate = new Date(this.minDate);
          const maxDate = new Date(tempMaxDate);
          const dateSelected = new Date(c.value);
          if (dateSelected <= maxDate && dateSelected >= minDate) {
            return null;
          }
          else if(val<=this.minDate)
          return {
            dateValidator: {
              valid: false
            }}
            else
          return {
            maxDateValidator: {
              valid: false
            }
          };
        }
      }
    };
  }
}
