import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Input,
  HostListener,
} from "@angular/core";

import * as moment from "moment";
@Directive({
  selector: "[appMaskInputDirective]",
})
export class MaskInputDirectiveDirective {
  @Input() appMaskValue: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {}

  @HostListener("window:keydown", ["$event"])
  handleKeyDown(event: KeyboardEvent) {
    if (this.appMaskValue) {
      var date = this.appMaskValue.split("/");
      if (date.length == 3) {
        if (date[0].length == 1) date[0] = "0" + date[0];
        if (date[1].length == 1) date[1] = "0" + date[1];
        this.appMaskValue = date[0] + "/" + date[1] + "/" + date[2];
      }
    }
    if (
      this.appMaskValue &&
      (this.appMaskValue.length === 2 || this.appMaskValue.length === 5) &&
      event.key !== "Backspace"
    ) {
      this.renderer.setProperty(
        this.elRef.nativeElement,
        "value",
        this.appMaskValue.toString() + "/"
      );
    }
  }
}
