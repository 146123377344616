<!-- H E A D E R   I M G   -->

<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- H E A D E R   I M G   -->
<!-- B R E A D C R U M B  -->

<!-- <section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section> -->

<!-- // B R E A D C R U M B  -->
<form (ngSubmit)="onSubmit(lenderChangeForm)" #lenderChangeForm="ngForm">
  <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
  <div *ngIf="!showThankyou">
    <section id="request-change-form" class="pb-2">
      <div class="container">

        <div class="row pt-3 justify-content-center">
          <div class="col-md-7 col-xs-12">
            <span>New Lender Information</span>
            <br>
            <br>

            <div id="request-change-form" class="form-div pl-0">
              <div class="row">
                <div class="col">
                  <label for="policynumber">
                    Would you like to upload the notification you received from your lender or manually enter the information?
                    <span data-toggle="collapse" href="#helpText" role="button" aria-expanded="false"
                          aria-controls="personal">
                      <i class="fa fa-question-circle help-icon-custom text-sm-right help date-helper-icon text-dark" (click)="onClickManual('help')"></i>
                    </span>
                  </label>
                  <br>
                  <br>
                  <div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="UploadFile" [disabled]="isLoading"
                             id="UploadFileOne" value="1" required
                             [(ngModel)]="lenderChangeDetails.upload" (click)="onClickManual('manual')"
                             [checked]="true"
                             #UploadFile="ngModel">
                      <label class="form-check-label" for="UploadFileOne"> Upload the lender notification </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="UploadFile" [disabled]="isLoading"
                             id="UploadFileTwo" value="2" required
                             [(ngModel)]="lenderChangeDetails.upload" (click)="onClickManual('manual')"
                             #UploadFile="ngModel">
                      <label class="form-check-label" for="UploadFileTwo"> Manually enter the information</label>
                    </div>
                    <!--<br />
                    <br />-->
                    <!--<br />-->
                    <section id="request-change-form" class="">
                      <div class="container">
                        <div class="justify-content-center">
                          <div>
                            <div class="">
                              <div class="row pt-1">
                                <div id="block" *ngIf="(lenderChangeDetails.upload === '1')">
                                  <!--<div class="card-body bg-light document-upload-section" [hidden]="( (lenderChangeDetails.upload === '2') )">-->
                                  <br />
                                  <div class="document-upload-section">
                                    <div class="col-5 col-sm-4">
                                      <input type="file" id="fileUpload" name="fileUpload" class="file-upload" (change)="uploadFileEvt($event)">
                                      <br />
                                      <br />

                                      <!--<button type="button" id="uploadFile" class="btn btn-block btn-outline-primary" (click)="uploadClick()">Upload</button>
                                      <br />
                                      <br />-->
                                    </div>
                                    <div *ngIf="(fileNameShow)">
                                      <span class="filename col-5 col-sm-4" [(ngModel)]="fileName" [innerHTML]="fileName"></span>
                                      <br /><label class="delete col-5 col-sm-4" (click)="removeFile($event)"><u>Delete</u> </label>
                                    </div>

                                  </div>
                                  <label *ngIf="(fileName=='') "
                                         class="error-message">{{invalidFileErrorMessage}}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <!-- Start help text -->
                  <div class="collapse multi-collapse w-100" id="helpText">
                    <div class="card card-body mb-2 pt-0 pb-0 pl-3 pr-3">
                      <div class="row grid-divider">
                        <div class="col-sm-12 col-md-4 col-lg-12 my-1">
                          <!-- <span class="span-tool-tip">Loan Position</span>-->
                          <br>
                          <p>
                            You should have received written notification from your original lender and/or your new lender about your lender change. You may upload that document to provide the information required to change the mortgagee clause on your insurance policy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End help text -->
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>


    <!--<div [hidden]="( (lenderChangeDetails.upload === '1') )" *ngIf="(lenderChangeDetails.upload === '1')">-->
    <div *ngIf="(lenderChangeDetails.upload === '2')">
      <section id="request-change-form" class="pb-2">
        <div class="container">

          <div class="row pt-1 justify-content-center">
            <div id="lc-form" class="col-md-7 col-xs-12">
              <span class="pl-3">Customer Information</span>
              <br>
              <div id="request-change-form" class="form-div">
                <div class="row pt-1">
                  <div class="col-8">
                    <label for="policynumber">Policy Number <span>*</span></label>
                    <input type="text" autocomplete="off" class="form-control" maxlength="25"
                           [(ngModel)]="lenderChangeDetails.policyNumber" name="PolicyNumber"
                           #PolicyNumber="ngModel" #PolicyNumberDP required />
                    <label *ngIf="(PolicyNumber.hasError('required') && PolicyNumber.touched) "
                           class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <label for="FirstName">Customer First Name <span>*</span></label>
                    <input type="text" class="form-control" id="firstName" placeholder="First Name"
                           maxlength="17" required [(ngModel)]="lenderChangeDetails.firstName"
                           name="FirstName" #firstName="ngModel" #firstNameDP />
                    <label *ngIf="(firstName.hasError('required') && firstName.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                  <div class="col-lg-6 col-xs-12 col-sm-12 mob-pt-3">
                    <label for="LastName">Customer Last Name <span>*</span></label>
                    <input type="text" class="form-control" id="lastName" placeholder="Last Name"
                           maxlength="20" required [(ngModel)]="lenderChangeDetails.lastName"
                           name="LastName" #lastName="ngModel" #lastNameDP />
                    <label *ngIf="(lastName.hasError('required') && lastName.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                </div>

                <div class="row pt-3">
                  <div class="col">
                    <label for="addressLine1">Property Location Address Line 1 <span>*</span></label>
                    <input type="text" class="form-control address-line-one address" #propStreetAddress
                           name="AddressLine1" placeholder="&nbsp; &nbsp; Property Location Address Line 1"
                           required appGoogleAutoCompletePlaces (onselect)="SetPropertyAddressDetails($event);"
                           [(ngModel)]="lenderChangeDetails.addressLine1" maxlength="36"
                           id="address-line-one" #propStreetAddressV="ngModel"
                           (ngModelChange)="changingAddressLineOne()" />
                    <i class="far fa-search address-line-search-icon" aria-hidden="true"
                       id="address-line-search-one"
                       [ngClass]="  (propStreetAddressV.hasError('required') && propStreetAddressV.touched)  ? 'far fa-search address-line-search-icon-req' : 'far fa-search address-line-search-icon'"></i>
                    <label *ngIf="(propStreetAddressV.hasError('required') && propStreetAddressV.touched) "
                           class="error-message text-nowrap">
                      {{constantsObj.requiredErrorMessage}}
                    </label>
                  </div>
                </div>

                <div class="row pt-3">
                  <div class="col">
                    <label for="Address2">Property Location Address Line 2</label>
                    <input type="email" class="form-control address" id="txtPropertyLine2"
                           aria-describedby="emailHelp" name="AddressLine2" #line2
                           placeholder="Property Location Address Line 2"
                           [(ngModel)]="lenderChangeDetails.addressLine2" maxlength="36" />
                  </div>
                </div>

                <div class="row pt-3">
                  <div class="col-lg-12 col-xs-12 col-sm-12 ">
                    <label for="City">Property Location City <span>*</span></label>
                    <input type="text" class="form-control" id="txtCity"
                           placeholder="Property Location City" required #cities
                           [(ngModel)]="lenderChangeDetails.city" name="City" #city="ngModel"
                           maxlength="23">
                    <label *ngIf="(city.hasError('required') && city.touched)"
                           class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                </div>
                <div class="row ">

                  <div class="col-lg-5 col-xs-12 col-sm-12 pt-3">
                    <label for="state">Property Location State <span>*</span></label>
                    <select class="form-control" id="txtState" name="State"
                            required
                            [(ngModel)]="lenderChangeDetails.state" #state="ngModel" #states>
                      <option selected value=""></option>
                      <option *ngFor="let item of lstStates" [value]="item.stateName">
                        {{item.stateName}}
                      </option>
                    </select>
                    <label *ngIf="(state.hasError('required') && state.touched) "
                           class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                  <div class="col-lg-5 col-xs-12 col-sm-12 pt-3">
                    <label for="PostalCode">Postal Code <span>*</span></label>
                    <input type="text" class="form-control" id="txtPostalCode" placeholder="Postal Code"
                           name="PostalCode" minlength="5" maxlength="10" required #postalCodes
                           [(ngModel)]="lenderChangeDetails.postalCode" #postalCode="ngModel"
                           ZipcodeValidator />
                    <label *ngIf="(postalCode.hasError('required') && postalCode.touched) "
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                    <label *ngIf="(lenderChangeDetails.postalCode!='' && lenderChangeDetails.postalCode!=null && postalCode.invalid && postalCode.touched)"
                           class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="request-change-form" class="pb-2">

        <div class="container">
          <div id="request-change-form" class="row pt-3 justify-content-center">
            <div id="lc-form" class="col-md-7 col-xs-12">
              <span class="pl-3">New Lender Information</span>
              <br>
              <br>
              <div class="form-div">
                <div class="row">
                  <div class="col-6">
                    <label for="policynumber">Loan Number <span>*</span></label>
                    <input type="text" class="form-control" id="loanNumber"
                           [ngModelOptions]="{ updateOn: 'blur' }" required
                           [(ngModel)]="lenderChangeDetails.loanNumber" name="LoanNumber"
                           #LoanNumber="ngModel" maxlength="16">
                    <label *ngIf="(LoanNumber.hasError('required') && LoanNumber.touched) "
                           class="error-message text-nowrap">
                      {{constantsObj.requiredErrorMessage}}
                    </label>
                  </div>
                  <div class="col">
                    <label for="policynumber">Loan Position <span>*</span></label>
                    <span data-toggle="collapse" href="#loanPosition" role="button" aria-expanded="false"
                          aria-controls="personal">
                      &nbsp;<i class="fa fa-question-circle help-icon-custom text-sm-right help date-helper-icon text-dark"></i>
                    </span>
                    <div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="LoanPosition"
                               id="LoanPositionOne" value="1" required
                               (click)="lenderChangeDetails.mortgageeImpounds = ''"
                               [(ngModel)]="lenderChangeDetails.loanPosition"
                               #LoanPosition="ngModel">
                        <label class="form-check-label" for="LoanPositionOne">1</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="LoanPosition"
                               id="LoanPositionTwo" value="2" required
                               (click)="lenderChangeDetails.mortgageeImpounds = 'No'"
                               [(ngModel)]="lenderChangeDetails.loanPosition"
                               #LoanPosition="ngModel">
                        <label class="form-check-label" for="LoanPositionTwo">2</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="LoanPosition"
                               id="LoanPosition3" value="3" required
                               (click)="lenderChangeDetails.mortgageeImpounds = 'No'"
                               [(ngModel)]="lenderChangeDetails.loanPosition"
                               #LoanPosition="ngModel">
                        <label class="form-check-label" for="LoanPosition3">3</label>
                      </div>
                    </div>
                    <label *ngIf="LoanPosition.hasError('required') && LoanPosition.touched"
                           class="error-message text-nowrap">{{constantsObj.selectOneErrorMessage}}</label>

                    <!-- Start help text -->
                    <div class="collapse multi-collapse w-100" id="loanPosition">
                      <div class="card card-body mb-2 pt-0 pb-0 pl-3 pr-3">
                        <div class="row grid-divider">
                          <div class="col-sm-12 col-md-6 col-lg-12 my-1">

                            <br>
                            <p>
                              Some homeowners have multiple loans for a single property. Select ‘1’ if this is your only loan, or lien, on your property.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End help text -->
                  </div>

                </div>
                <div class="row pt-3">
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <label for="FirstName">Mortgagee Name Line 1 <span>*</span></label>
                    <span data-toggle="collapse" href="#mortgageeName" role="button" aria-expanded="false"
                          aria-controls="personal">
                      &nbsp;<i class="fa fa-question-circle help-icon-custom text-sm-right help date-helper-icon text-dark"></i>
                    </span>
                    <input type="text" class="form-control" id="txtFirstNameMortagegee" maxlength="80"
                           required [(ngModel)]="lenderChangeDetails.mortgageeFirstName"
                           name="MortgageeFirstName" #MortgageeFirstName="ngModel" #MortgageeFirstNameDP />
                    <label *ngIf="(MortgageeFirstName.hasError('required') && MortgageeFirstName.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                    <!-- Start help text -->
                    <div class="collapse multi-collapse w-100" id="mortgageeName">
                      <div class="card card-body mb-2 pt-0 pb-0 pl-3 pr-3">
                        <div class="row grid-divider">
                          <div class="col-sm-12 col-md-6 col-lg-12 my-1">

                            <br>
                            <p>
                              The name of your new lender as it appears on provided documentation.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End help text -->
                  </div>
                  <div class="col-lg-6 col-xs-12 col-sm-12 mob-pt-3 " *ngIf="false">
                    <label for="LastName">Mortgagee Last Name <span>*</span></label>
                    <input type="text" class="form-control" placeholder="Last Name"
                           id="mortgageeLastNameText" maxlength="80" required
                           [(ngModel)]="lenderChangeDetails.mortgageeLastName" name="MortgageeLastName"
                           #MortgageeLastName="ngModel" #MortgageeLastNameDP />
                    <label *ngIf="(MortgageeLastName.hasError('required') && MortgageeLastName.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">
                        Mortgagee Name Line 2
                      </label>
                      <input type="text" class="form-control" id="MortgageeClauseText" maxlength="80"
                             [(ngModel)]="lenderChangeDetails.mortgageeClause" name="MortgageeClause"
                             #MortgageeClause="ngModel" #MortgageeClauseDP />
                      <label *ngIf="(MortgageeClause.hasError('required') && MortgageeClause.touched)"
                             class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                    </div>
                  </div>
                </div>
                <div class="row ">
                  <div class="col">
                    <label for="MortgageeAddressLine1">Mortgagee Address Line 1 <span>*</span></label>
                    <input type="text" class="form-control" id="address-line-two" #mortgageeAddressLine1
                           name="MortgageeAddressLine1" placeholder="&nbsp; &nbsp; Mortgagee Address Line 1"
                           class="form-control  search-icon address address-line-one" required
                           appGoogleAutoCompletePlaces (onselect)="SetPropertyAddressDetailsMortgagee($event);"
                           [(ngModel)]="lenderChangeDetails.mortgageeAddressLine1" maxlength="36"
                           #mortgageeAddressLine1V="ngModel" (ngModelChange)="changingAddressLineOne()">
                    <i class="far fa-search address-line-search-icon" aria-hidden="true"
                      id="address-line-search-two"
                      [ngClass]="  (mortgageeAddressLine1V.hasError('required') && mortgageeAddressLine1V.touched)  ? 'far fa-search address-line-search-icon-req' : 'far fa-search address-line-search-icon'"></i>
                    <label *ngIf="(mortgageeAddressLine1V.hasError('required') && mortgageeAddressLine1V.touched) "
                      class="error-message text-nowrap">
                      {{constantsObj.requiredErrorMessage}}
                    </label>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col">
                    <label for="Address2">Mortgagee Address Line 2</label>
                    <input type="email" class="form-control" id="mortgageeAddressLine2"
                           aria-describedby="emailHelp" name="MortgageeAddressLine2" #mortgageeAddressLine2
                           placeholder="Mortgagee Address Line 2"
                           [(ngModel)]="lenderChangeDetails.mortgageeAddressLine2" maxlength="36" />
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-lg-12 col-xs-12 col-sm-12 ">
                    <label for="MortgageeCity">City <span>*</span></label>
                    <input type="text" class="form-control" id="mortgageeCity" placeholder="City"
                           #mortgageeCites required #cities
                           [(ngModel)]="lenderChangeDetails.mortgageeCity" name="MortgageeCity"
                           #mortgageeCity="ngModel" maxlength="23">
                    <label *ngIf="(mortgageeCity.hasError('required') && mortgageeCity.touched)"
                           class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                  </div>

                </div>
                <div class="row pt-3">

                  <div class="col-lg-5 col-xs-12 col-sm-12 ">
                    <label for="mortgageeState">State <span>*</span></label>

                    <select class="form-control" id="mortgageeState" name="mortgageeState"
                            required
                            [(ngModel)]="lenderChangeDetails.mortgageeState" #mortgageeState="ngModel"
                            #mortgageeStates>
                      <option selected value=""> </option>
                      <option *ngFor="let item of lstStates" [value]="item.stateName">
                        {{item.stateName}}
                      </option>
                    </select>

                    <label *ngIf="(mortgageeState.hasError('required') && mortgageeState.touched) "
                           class="error-message">{{constantsObj.requiredErrorMessage}}</label>

                  </div>

                  <div class="col-lg-5 col-xs-12 col-sm-12 mob-pt-3">
                    <label for="MortgageePostalCode">Postal Code <span>*</span></label>
                    <input type="text" class="form-control" id="MortgageePostalCode"
                           placeholder="Postal Code" name="MortgageePostalCode" minlength="5" maxlength="10"
                           required #mortgageePostalCodes
                           [(ngModel)]="lenderChangeDetails.mortgageePostalCode"
                           #mortgageePostalCode="ngModel" ZipcodeValidator />
                    <label *ngIf="(mortgageePostalCode.hasError('required') && mortgageePostalCode.touched) "
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                    <label *ngIf="(lenderChangeDetails.mortgageePostalCode!='' && lenderChangeDetails.mortgageePostalCode!=null && mortgageePostalCode.invalid && mortgageePostalCode.touched)"
                           class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                  </div>
                  <!--testing the fix-->
                </div>
                <div class="row pt-4">
                  <div class="col">
                    <label for="policynumber">
                      Impound/Escrow?
                      <span>*</span>
                    </label>
                    <span data-toggle="collapse" href="#impound" role="button" aria-expanded="false"
                          aria-controls="personal">
                      &nbsp;<i class="fa fa-question-circle help-icon-custom text-sm-right help date-helper-icon text-dark"></i>
                    </span>
                    <div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="MortgageeImpounds"
                               id="mortgageeImpoundsOne" value="Yes" name="MortgageeImpounds"
                               maxlength="20" required
                               [disabled]="( (lenderChangeDetails.loanPosition === '2' || lenderChangeDetails.loanPosition === '3') )"
                               [(ngModel)]="lenderChangeDetails.mortgageeImpounds"
                               #MortgageeImpounds="ngModel">
                        <label class="form-check-label" for="mortgageeImpoundsOne">Yes</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="MortgageeImpounds"
                               id="mortgageeImpounds2" value="No" name="MortgageeImpounds" required
                               [(ngModel)]="lenderChangeDetails.mortgageeImpounds"
                               #MortgageeImpounds="ngModel">
                        <label class="form-check-label" for="mortgageeImpounds2">No</label>
                      </div>
                    </div>
                    <label *ngIf="MortgageeImpounds.hasError('required') && MortgageeImpounds.touched"
                           class="error-message text-nowrap">{{constantsObj.selectOneErrorMessage}}</label>
                  </div>
                  <!-- Start help text -->
                  <div class="collapse multi-collapse w-100" id="impound">
                    <div class="card card-body mb-2 pt-0 pb-0 pl-3 pr-3">
                      <div class="row grid-divider">
                        <div class="col-sm-12 col-md-6 col-lg-12 my-1 ml-4 mt-3">
                          <p>
                            Select ‘Yes’ if your homeowners insurance is paid by your lender.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End help text -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="(lenderChangeDetails.upload === '1') || (lenderChangeDetails.upload === '2')">
      <section id="request-change-form" class="pb-2">
        <div class="container">
          <div class="row pt-1 justify-content-center">
            <div id="lc-form" class="col-md-7 col-xs-12">
              <span class="pl-3">Requestor Information</span>
              <br />
              <div class="form-div">
                <div class="row pt-3">
                  <div class="col-lg-6 col-xs-12 col-sm-12">
                    <label for="FirstName">Requestor First Name <span>*</span></label>
                    <input type="text" class="form-control" id="txtFirstNameRequester"
                           placeholder="First Name" maxlength="17" required
                           [(ngModel)]="lenderChangeDetails.requesterFirstName"
                           name="RequesterFirstName" #RequesterFirstName="ngModel" #RequesterFirstNameDP />
                    <label *ngIf="(RequesterFirstName.hasError('required') && RequesterFirstName.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                  <div class="col-lg-6 col-xs-12 col-sm-12 mob-pt-3 ">
                    <label for="LastName">Requestor Last Name <span>*</span></label>
                    <input type="text" placeholder="Last Name" class="form-control"
                           id="RequesterLastNameText" maxlength="20" required
                           [(ngModel)]="lenderChangeDetails.requesterLastName" name="RequesterLastName"
                           #RequesterLastName="ngModel" #RequesterLastNameDP />
                    <label *ngIf="(RequesterLastName.hasError('required') && RequesterLastName.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col">
                    <label for="Email">Requestor Email <span>*</span></label>
                    <input type="email" class="form-control" id="txtEmail" placeholder="Requestor Email" required
                           [(ngModel)]="lenderChangeDetails.requesterEmail" name="RequesterEmail"
                           #RequesterEmail="ngModel" emailValidator maxlength="100">
                    <label *ngIf="(RequesterEmail.hasError('required') && RequesterEmail.touched)"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                    <label *ngIf="(RequesterEmail.hasError('emailValidator') && RequesterEmail.touched && !RequesterEmail.hasError('required'))"
                           class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                  </div>
                </div>
                <!-- Start Confirm Requestor Email -->
                <div class="row pt-3">
                  <div class="col">
                    <label for="Email">Confirm Requestor Email <span>*</span></label>
                    <input type="email"
                           class="form-control"
                           id="txtEmail"
                           placeholder="Confirm Requestor Email"
                           required
                           [disabled]="lenderChangeDetails.requesterEmail === '' ||lenderChangeDetails.requesterEmail === null "
                           [(ngModel)]="confirmRequesterEmail" name="ConfirmRequesterEmail"
                           #ConfirmRequesterEmail="ngModel"
                           emailValidator maxlength="100"
                           compare-password="RequesterEmail"
                           onCopy="return false"
                           onDrag="return false"
                           onDrop="return false"
                           onPaste="return false"
                           autocomplete="new-ConfirmEmail">
                    <label *ngIf="(ConfirmRequesterEmail.hasError('required') && (ConfirmRequesterEmail.touched || lenderChangeForm.submitted))"
                           class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                    <label *ngIf="(ConfirmRequesterEmail.hasError('emailValidator') && (ConfirmRequesterEmail.touched || lenderChangeForm.submitted) && !ConfirmRequesterEmail.hasError('required') )"
                           class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                    <label *ngIf="(ConfirmRequesterEmail.hasError('compare') && (ConfirmRequesterEmail.touched || lenderChangeForm.submitted) && !ConfirmRequesterEmail.hasError('required') && !ConfirmRequesterEmail.hasError('emailValidator'))"
                           class="error-message text-nowrap">{{constantsObj.invalidConfirmPassword}}</label>

                  </div>
                </div>
                <!-- End Confirm Requestor Email -->


              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="request-change-form" class="pb-2">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-7 col-xs-12">
              <span class="error-message text-nowrap" style="text-transform: none;">*These fields are required</span>
              <div>

                <div class="row">
                  <div class="col pr-5 text-right">
                    <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                                             (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type"
                                             [badge]="badge" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                    </ngx-invisible-recaptcha>
                  </div>

                  <!-- <div class="col pr-5 pb-2 text-right" *ngIf="!commonService.isSafariBroswer()">
                    <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language"
                                    (success)="handleSuccess($event)" (load)="handleLoad()" (expire)="handleExpire()"
                                    [theme]="theme" [type]="type" [(ngModel)]="recaptcha"
                                    [ngModelOptions]="{ standalone: true }">
                    </ngx-recaptcha2>
                  </div> -->
                </div>
                <!-- <div class="row">
                  <div class="col pr-5 pb-2 text-right" *ngIf="commonService.isSafariBroswer()">
                    <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()"
                                             (load)="handleLoad()" (success)="handleSuccess($event)" [type]="type"
                                             [badge]="badge" [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                    </ngx-invisible-recaptcha>
                  </div>
                </div> -->
                <label *ngIf="(captchaRequired && lenderChangeForm.submitted)"
                       class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                <div class="row pl-0 pt-3">
                  <div class="col pr-5 pb-5">
                    <button id="submit" type="submit" class="btn pl-0">
                      <div class="btn ww-button button-width-210">Submit Request</div>
                    </button>
                  </div>
                  <div class="col pr-5 pb-5">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="showThankyou">
    <div class="container">
      <div class="row justify-content-center col-md-12">
        <!-- <div class="col-md-3"></div> -->
        <div class="col-md-9 col-xs-12 p-4">
          <span id="messageError" class="service-error-message" [innerHtml]="dataMessage">

          </span>
        </div>
        <!-- <div class="col-md-3"></div> -->
      </div>
    </div>
  </div>
</form>

<app-model-pop-up-lender-change-message (parentFunYes)="parentFunYes()" (parentFunNo)="parentFunNo()"
                                        (parentFunOk)="parentFunOk()">
</app-model-pop-up-lender-change-message>

