import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { Constants } from 'src/app/Utilities/Constants/constants';
import { Lead } from 'src/app/model/lead';
import { DataService } from 'src/app/service/data.service';
import { CustNoticeService } from 'src/app/service/cust-notice.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-cust-notice',
  templateUrl: './cust-notice.component.html',
  styleUrls: ['./cust-notice.component.css']
})
export class CustNoticeComponent implements OnInit {

  formErrors: any = {};
  isLoading: boolean;
  stateDisclosureForm: FormGroup;
  constructor(public router: Router,
    public commonService: CommonService, public constantsObj: Constants,
    public leadDetails: Lead,
    public dataService: DataService,
    public custNoticeService: CustNoticeService) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.commonService.ScrollToRTQForm();
    this.leadDetails = this.dataService.leadDetails;
   // this.getImportantNoticeText();
    this.isLoading = false;
  }
  getImportantNoticeText() {
    this.leadDetails = new Lead();
    this.leadDetails.leadID ='0BFA00471655496E99E47C759B0508A9' //'E22C95A3-3A9D-4C09-8715-8067C29868FC'//'0BFA00471655496E99E47C759B0508A9';
    this.custNoticeService.getCustNoticeText(this.leadDetails).subscribe((data: Lead) => {
      this.isLoading = false
      this.leadDetails.disclosureInfo = data.disclosureInfo;
    }, err => {
      this.dataService.handleError(err);
    });
  }
  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }
  onSubmit(stateDisclosureForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.updateControls(stateDisclosureForm);
    if (stateDisclosureForm.valid) {
      this.isLoading = true;
      this.leadDetails.disclosureInfo.readDisclosure=true;
      this.custNoticeService
        .postDisclosureFlag(this.leadDetails)
        .subscribe((data: Lead) => {
          this.leadDetails = data;
          this.isLoading = false;
          this.commonService.NavigateToPage('/real-time-quote/quote-result');
        }, err => {
          this.isLoading = false;
          this.dataService.handleError(err);
        });
      return true;
    }
  } 
}
