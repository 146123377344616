<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-controlling-losses" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
        <h2 class="text-center sub-h2">Minimize your loss</h2>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->

<section id="intro-para">
  <div class="container text-center pt-3 pb-4">
    <div class="row justify-content-center">
      <div class="col-md-9 col-xs-9">
        <p >These tips can help get you back to normal as quickly as
          possible.
          And remember, we’ll work with you every step of the way.
        </p>
      
      </div>
    </div>
  </div>
</section>



<!-- // I N T R O   P A R A G R A P H  -->


<!-- T I P S  -->

<section class="control-loss">
  <div class="container top-border-element pb-5">
    <div class="row pt-5">
      <div class="col">
        <h2 class="col text-center">TIPS TO MINIMIZE DAMAGE OR LOSS TO YOUR HOME OR PROPERTY</h2>
      </div>
    </div>
    <div class="row text-center pt-2 pb-2">
      <div class="col">
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" /> <br><br>
        <p >Do not attempt any of the following actions unless they can be done safely.</p>
      </div>
    </div>
    <div class="row" id="a-bullet-list">

      <!-- left side -->

      <div class="col-md-6">
        <div class="row pt-3">
          <div class="col-md-2">
            <img src="assets/img/icons/fire-losses.png" alt="Burning building icon" width="65" />
          </div>
          <div class="col">
            <span>Fire Losses</span><br>
            For light to moderate fire damage:
            <ul>
              <li>Turn off the circuit breakers which control the damaged area.</li>
              <li>Pick up debris and set aside for the claims adjuster to inspect.</li>
              <li>Do not try to clean smoke or soot from your furniture, as professional cleaning is recommended.</li>
              <li>After a major fire, the home should be boarded up as securely as possible.</li>
            </ul>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-2"><img src="assets/img/icons/windstorm.png" alt="stormy cloud icon" width="65" /></div>
          <div class="col">
            <span>Windstorm or Rain Losses</span><br>
            <ul>
              <li>If your roof is seriously damaged or leaking badly, call a roofer or handyman to install a plastic
                tarp to cover the damaged area.</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- right side -->

      <div class="col-md-6">
        <div class="row pt-3">
          <div class="col-md-2"><img src="assets/img/icons/pipe.png" alt="leaky pipe icon" width="65" /></div>
          <div class="col">
            <span>Pipe Breaks with Flooding</span><br>
            <ul>
              <li>Turn off the main water supply.</li>
              <li>Remove wet carpeting, if possible.</li>
            </ul>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-2 pt-1"><img src="assets/img/icons/theft-loss.png" alt="broken window icon" width="60" />
          </div>
          <div class="col">
            <span>Theft Losses</span><br>
            <ul>
              <li>Notify police or the proper authority and obtain a case number.</li>
              <li>Collect receipts, warranty booklets or other documentation for the stolen items.</li>
            </ul>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-2"><img src="assets/img/icons/mvd.png" alt="delivery truck icon" width="75" /></div>
          <div class="col">
            <span>Motor Vehicle Damage</span><br>
            <ul>
              <li>If a vehicle has made an entry through a wall of your home, or if any part of the dwelling appears
                prone to collapse, you should not occupy the house.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- // T I P S  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>


<!-- // K N O W L E D G E   C E N T E R  -->

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->