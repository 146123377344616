<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-your-policy" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 col-sm-12 col-xs-8 text-center header-shape">
        <h1 class="text-center sub-h2">Request Policy Changes</h1>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>



<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<!-- <section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a [href]="wpBaseUrl" class="bc-home cursor-pointer">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section> -->


<!-- // B R E A D C R U M B  -->

<!--  F O R M   -->
<form (ngSubmit)="onSubmit(requestPolicyChangeForm)" #requestPolicyChangeForm="ngForm">
  <section id="request-change-form" class="pb-5">
    <div class="container">
      <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
      <div class="row pt-5 justify-content-center">
        <div class="col-md-7">
          <span>Request a change</span> <br><br>
          <div class="form-div">
            <div class="row">
              <div class="col-8">
                <label for="policynumber">Policy Number <span class="optional-required-msg">(*optional if name & address
                    provided)</span></label>
                  <input type="text" class="form-control" maxlength="25" [(ngModel)]="requestPolicyDetails.policyNumber"
                    name="PolicyNumber" #PolicyNumber="ngModel" #PolicyNumberDP />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-xs-12 col-sm-12 pt-3">
                <label for="FirstName">Customer First Name <span>*</span></label>
                <input type="text" class="form-control" id="txtFirstName" placeholder="First Name" maxlength="17"
                  required [(ngModel)]="requestPolicyDetails.firstName" name="FirstName" #firstName="ngModel"
                  #firstNameDP />
                <label *ngIf="(firstName.hasError('required') && firstName.touched)"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
              </div>
              <div class="col-lg-6 col-xs-12 col-sm-12 pt-3">
                <label for="LastName">Customer Last Name <span>*</span></label>
                <input type="text" class="form-control" id="txtLasttName" placeholder="Last Name" maxlength="20"
                  required [(ngModel)]="requestPolicyDetails.lastName" name="lastName" #lastName="ngModel"
                  #lastNameDP />
                <label *ngIf="(lastName.hasError('required') && lastName.touched)"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
              </div>
            </div>

            <div class="row pt-3">
              <div class="col">
                <label for="addressLine1">Address Line 1 <span>*</span></label>
                <input type="text" class="form-control" #propStreetAddress name="AddressLine1"
                  placeholder="&nbsp; &nbsp; Address Line 1" class="form-control  search-icon address address-line-one"
                  id="address-line-one" appGoogleAutoCompletePlaces (onselect)="SetPropertyAddressDetails($event);"
                  [(ngModel)]="requestPolicyDetails.addressLine1" maxlength="36" #addressLine1="ngModel" required
                  (ngModelChange)="changingAddressLineOne()">
                <i class="far fa-search address-line-search-icon" aria-hidden="true" id="address-line-search-one"
                  [ngClass]="  (addressLine1.hasError('required') && addressLine1.touched)  ? 'far fa-search address-line-search-icon-req' : 'far fa-search address-line-search-icon'"></i>
              </div>
            </div>
            <label *ngIf="addressLine1.hasError('required') && addressLine1.touched"
              class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>

            <div class="row pt-3">
              <div class="col">
                <label for="Address2">Address Line 2</label>
                <input type="email" class="form-control address" id="txtPropertyLine2" aria-describedby="emailHelp"
                  name="AddressLine2" #line2 placeholder="Address Line 2"
                  [(ngModel)]="requestPolicyDetails.addressLine2" maxlength="36" />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-xs-12 col-sm-12 pt-3">
                <label for="City">City <span>*</span></label>
                <input type="text" class="form-control" id="txtCity" placeholder="City" required #cities
                  [(ngModel)]="requestPolicyDetails.city" name="City" #city="ngModel" maxlength="23">
                <label *ngIf="(city.hasError('required') && city.touched)"
                  class="error-message">{{constantsObj.requiredErrorMessage}}</label>
              </div>
              <div class="col-lg-3 col-xs-12 col-sm-12 pt-3">
                <label for="state">State <span>*</span></label>
                <select class="form-control" id="txtState" name="State" [(ngModel)]="requestPolicyDetails.state"
                  #state="ngModel" #states required>
                  <option selected value=""></option>
                  <option *ngFor="let item of lstStates" [value]="item.stateName">{{item.stateName}}</option>
                </select>
                <label *ngIf="(state.hasError('required') && state.touched)"
                  class="error-message">{{constantsObj.requiredErrorMessage}}</label>
              </div>

              <div class="col-lg-5 col-xs-12 col-sm-12 pt-3">
                <label for="postalCode">Postal Code <span>*</span></label>
                <input type="text" class="form-control" id="txtPostalCode" placeholder="Postal Code" name="PostalCode"
                  minlength="5" maxlength="10" required #postalCodes [(ngModel)]="requestPolicyDetails.postalCode"
                  #postalCode="ngModel" ZipcodeValidator />
                <label *ngIf="(postalCode.hasError('required') && postalCode.touched) "
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                <label *ngIf="(requestPolicyDetails.postalCode!='' && postalCode.invalid && postalCode.touched)"
                  class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
              </div>

            </div>

            <div class="row">
              <div class="col col-sm-12 col-md-12 col-lg-6 pt-3">
                <label for="Number">Best Contact Number<span> *</span> </label>
                <input type="text" class="form-control" id="txtPreferredPhone" placeholder="(999) 999-9999"
                  minlength="10" mask="(000) 000-0000" required [(ngModel)]="requestPolicyDetails.contactNumber"
                  name="ContactNumber" #contactNumber="ngModel" />
                <label *ngIf="(contactNumber.hasError('required') && contactNumber.touched )"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                <label *ngIf="(contactNumber.hasError('minlength') && contactNumber.touched) "
                  class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12 col-md-12 col-lg-6 pt-3">
                <label for="EmailAddress">Email Address<span> *</span></label>
                <input type="text" class="form-control" id="txtEmail" placeholder="Email"
                  [(ngModel)]="requestPolicyDetails.emailAddress" name="EmailAddress" #email="ngModel" emailValidator
                  required maxlength="100">
                <label *ngIf="(email.hasError('required') && email.touched )"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                <label *ngIf="(email.hasError('emailValidator') && email.touched && !email.hasError('required'))"
                  class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
              </div>
            </div>

            <div class="row">
              <div class="col col-sm-12 col-md-12 col-lg-12 pt-3">
                <label for="EmailAddress">Request <span>*</span></label>
                <textarea class="form-control" aria-label="With textarea" placeholder="Enter your message here..."
                  id="txtRequestMessage" maxlength="600" required [(ngModel)]="requestPolicyDetails.requestMessage"
                  name="RequestMessage" #requestMessage="ngModel" #requestMessageDP></textarea>
                <label *ngIf="(requestMessage.hasError('required') && requestMessage.touched)"
                  class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
              </div>
            </div>
          </div>

          <div class="mb-2 pt-3">
            <div class="second-form form-div">
              <div class="row">
                <div class="col">
                  <label for="EmailAddress">How do you want us to contact you? <span>*</span></label><br>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="phoneContactWay" value="Phone" name="ContactWay"
                      required [(ngModel)]="requestPolicyDetails.contactWay" #ContactWay="ngModel">
                    <label class="form-check-label" for="phoneContactWay">Phone</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="emailContactWay" value="Email" name="ContactWay"
                      required [(ngModel)]="requestPolicyDetails.contactWay" #ContactWay="ngModel">
                    <label class="form-check-label" for="emailContactWay">Email</label>
                  </div>
                </div>
              </div>
              <label *ngIf="ContactWay.hasError('required') && ContactWay.touched"
                class="error-message text-nowrap">{{constantsObj.selectOneErrorMessage}}</label>
            </div>
          </div>

          <!-- REQUIRE NOTE, CAPTCHA AND SUBMIT -->
          <div class="row pt-3">
            <div class="col-12">
              <span class="required-form-span">*These fields are required</span>
              <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()" (load)="handleLoad()"
                (success)="handleSuccess($event)" [type]="type" [badge]="badge" [ngModel]="recaptcha"
                [ngModelOptions]="{ standalone: true }">
              </ngx-invisible-recaptcha>
            </div>
            <!-- <p class="req-msg-note pt-4">*These fields are required</p>
            <div class="pt-3  pr-5 pb-2 text-right" *ngIf="!this.commonService.isSafariBroswer()">
              <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language" (success)="handleSuccess($event)"
                (load)="handleLoad()" (expire)="handleExpire()" [theme]="theme" [type]="type" [(ngModel)]="recaptcha"
                [ngModelOptions]="{ standalone: true }">
              </ngx-recaptcha2>
            </div>
            <div class="col pt-3 pr-5 pb-2 text-right" *ngIf="false">
              <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()" (load)="handleLoad()"
                (success)="handleSuccess($event)" [type]="type" [badge]="badge" [ngModel]="recaptcha"
                [ngModelOptions]="{ standalone: true }">
              </ngx-invisible-recaptcha>
            </div> -->
            <div class="col-12">
              <label *ngIf="(captchaRequired && requestPolicyChangeForm.submitted)"
                class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
            </div>
            <div class="col-12 pb-5">
              <button type="submit" class="btn pl-0">
                <div class="btn ww-button button-width-210">Submit Request</div>
              </button>
            </div>
          </div>
          <!-- //REQUIRE NOTE, CAPTCHA AND SUBMIT -->
        </div>
      </div>
    </div>
  </section>
</form>
<!-- // F O R M  -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section> -->

<!-- // K N O W L E D G E   C E N T E R  -->
<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->