import { Component, OnInit, Inject, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { Constants } from '../../Utilities/Constants/constants';
import { CustomerQuoteSearch } from '../../model/customerQuoteSearch';
import { FormGroup } from '@angular/forms'; 
import { DataService } from '../../service/data.service';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { ReCaptcha } from '../../model/reCaptcha';
import { CustomerQuoteSearchService } from '../../service/customer-quote-search.service';
import { DOCUMENT } from '@angular/common';

declare var $: any;
declare var grecaptcha: any;
declare function breadcrumbShow():any;

@Component({
  selector: 'app-myquote',
  templateUrl: './myquote.component.html',
  styleUrls: ['./myquote.component.css']
})
export class MyquoteComponent implements OnInit {

  contactUsForm: FormGroup;
  formErrors: any = {};
  isLoading = false;
  captchaRequired = true;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  recaptcha:string;
	badge='Bottomright';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public commonService: CommonService,
    public constantsObj: Constants,
    public customerSearchDetail: CustomerQuoteSearch,
    private route: ActivatedRoute, 
    private CustomerQuoteSearchService: CustomerQuoteSearchService,
    public dataService: DataService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string
  ) {
    var isSafari=this.commonService.isSafariBroswer();
    if(isSafari)
    {
      this.captchaRequired=false;
      this.siteKey=siteKeyValueV3;
    }
    else
    {
      this.siteKey=siteKeyValue;
    }
    if (!this.commonService.isSafariBroswer()) {
      $('.grecaptcha-badge').remove()
    }
    else {
      $('.grecaptcha-badge').css('display', 'block !important');
      $('.grecaptcha-badge').css('visibility', 'visible !important');
    } 
   }

  ngOnInit() {
    this.customerSearchDetail = new CustomerQuoteSearch(); 
    $('.help').tooltip({
      title: "<p >Your quote number is a unique identifier assigned to your specific quote, sent to you via email and/or letter.</p>",
      html: true,
      placement: 'top'
    });

   

  }

  ngAfterViewInit()
  {
    breadcrumbShow();
  }

  //For google re-CAPTCHA
  handleReady()
  {
    this.captchaRequired=false;
   
  }
  handleSuccess(event) {
    
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA
  
  //Submit form
  onSubmit(customerSearchForm) {
    this.formErrors = {};
    this.formErrors = this.commonService.getErrors(customerSearchForm, this.formErrors);
    this.formErrors=this.commonService.updateControls(customerSearchForm);
     if(!customerSearchForm.valid){
       this.commonService.focusFirstInvalidElement();
         return;
    }
    //Post form through api call
    if (customerSearchForm.valid && !this.captchaRequired) {
      this.isLoading = true;
      if (this.commonService.isSafariBroswer()) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => { 
          if (token != '') { 
          this.customerSearchDetail.reCaptcha = new ReCaptcha();
          this.customerSearchDetail.reCaptcha.response = token;
          this.customerSearchDetail.reCaptcha.secretKey = this.captchaSecretKeyV3;
          this.PostData(customerSearchForm);
        }
        });
      }
      else
      this.PostData(customerSearchForm);
     
    } else {
      this.commonService.updateControls(customerSearchForm);
    }
  } 
  PostData(customerSearchForm) {
    this.customerSearchDetail.errormessage = '';
    this.customerSearchDetail.leadID = '';
    this.CustomerQuoteSearchService.postMyQuote(this.customerSearchDetail).subscribe((data: any) => {
      this.customerSearchDetail = data;
      if (this.customerSearchDetail.errormessage === '') { 
        //this.router.navigate(["/"]).then(result => { window.location.href = this.customerSearchDetail.redirectURL; });
        //window.location.replace();
        window.location.href = this.customerSearchDetail.redirectURL;
      } else {
        this.isLoading = false; 
        customerSearchForm.reset();
        grecaptcha.reset();
        if(this.commonService.iMobBroswer()){
          $("html, body").animate({ scrollTop: $(document).height()-1400 }, "slow");
        }else{
          $("html, body").animate({ scrollTop: $(document).height()-1000 }, "slow");
        } 
      } 
      
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      alert("Something went wrong, please try again!");
      window.location.reload()
    });

  }
  ngAfterViewChecked() {
   
   
  }


}
