import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-rtq-landing-page",
  templateUrl: "./rtq-landing-page.component.html",
  styleUrls: ["./rtq-landing-page.component.css"],
})
export class RtqLandingPageComponent implements OnInit {
  constructor(private commonService: CommonService, private cookieService: CookieService, private router: Router) {}

  ngOnInit(): void {
    
    if(this.cookieService.check('GUID-Token') && this.cookieService.check('XSRF-TOKEN')) {
      console.log('RCAV.');
      this.router.navigate(["real-time-quote/cust-info"]);
    } else {
      this.commonService.SetCSRFToken(true);
    }
    // setTimeout(() => {
    //   this.router.navigate(["real-time-quote/cust-info"]);
    // }, 2000);
  }
}
