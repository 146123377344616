import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const INITIAL_STATE: State = {
  CategoryID: 0,
  SearchTerm: '',
  Month: 0,
  Year: 0
};

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _state = new BehaviorSubject<State>(INITIAL_STATE);

  state = this._state.asObservable(); 

  changeCategoryID(newCategoryID: number) {
    const oldState = this._state.getValue()
    this._state.next({ ...oldState, CategoryID: newCategoryID });
  }
  changeSearchTerm(newSearchTerm: string) {
    const oldState = this._state.getValue()
    this._state.next({ ...oldState, SearchTerm: newSearchTerm });
  }
  changeMonth(newMonth: number) {
    const oldState = this._state.getValue()
    this._state.next({ ...oldState, Month: newMonth });
  }
  changeYear(newYear: number) {
    const oldState = this._state.getValue()
    this._state.next({ ...oldState, Year: newYear });
  }
}

export interface State {
  CategoryID: number;
  SearchTerm: string;
  Month:number;
  Year:number;
}