import { Component, OnInit, AfterViewInit, Injectable, HostListener } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
} from "@angular/animations";
import { CommonService } from "../../service/common.service";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { WEB_BASE_URL } from "../../service/config.service";
import { DataService } from "src/app/service/data.service";
declare var $: any;
declare var Mmenu: any;
declare var window: any;
declare var document: any;
declare function loadMobileMenu(): any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  animations: [
    trigger("hideShow", [
      transition("in => out", [
        group([
          animate(
            "400ms ease-in-out",
            style({
              opacity: "0",
            })
          ),
          animate(
            "600ms ease-in-out",
            style({
              "max-height": "0px",
            })
          ),
          animate(
            "700ms ease-in-out",
            style({
              visibility: "hidden",
            })
          ),
        ]),
      ]),
      transition("out => in", [
        group([
          animate(
            "400ms ease-in-out",
            style({
              visibility: "visible",
            })
          ),
          animate(
            "600ms ease-in-out",
            style({
              "max-height": "500px",
            })
          ),
          animate(
            "800ms ease-in-out",
            style({
              opacity: "1",
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  selectedMenu: string;
  selectedSubMenu: string;
  public wpBaseUrl: string;
  selectedMobileMenuHeader: string;
  selectedMobileMenuHeaderCurrent: string = '';
  screenHeight: number;
  screenWidth: number;
  
  constructor(
    public commonService: CommonService,
    public dataService: DataService,
    public router: Router
  ) {
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
    this.selectedMobileMenuHeader = "";
    this.getScreenSize();
  }

  ngOnInit() {
    // console.log("wpWebsiteUrl:", this.wpBaseUrl);
  }

  collapseMobileMenu(menuId) {
    if (this.selectedMobileMenuHeader === menuId) {
      this.selectedMobileMenuHeader = "";
    } else {
      this.selectedMobileMenuHeader = menuId;
    }
  }

  navigateToUrl(url: string) {
    // console.log('redirectingTo:', this.wpBaseUrl + url);
    window.location.href = this.wpBaseUrl + url;
  }

  ngAfterViewInit() {
    if (this.commonService.iMobBroswer()) {
      $(document).ready(function () {
        // Fire the plugin
        const menu = new Mmenu("#mmenu", {
          extensions: [
            "position-bottom",
            "fullscreen",
            "theme-black",
            "border-full",
          ],

          navbars: [
            // {
            // height 	: 2
            // },
            {
              content: ["prev", "title"],
            },
            {
              position: "bottom",
              content: [
                '<a href="tel:18885436179" class="telNum"><i class="fas fa-phone"></i></a>',
                '<a href="mailto:Contactus@Westwoodins.com" class="emailUs pl-5"><i class="fas fa-envelope"></i></a>',
                "<a href='" +
                  window.location.origin +
                  "/get-a-quote'>Get A Quote</a>",
              ],
            },
          ],
        });
        // Get the API
        const api = menu.API;
        api.bind("openPanel:start", (panel) => {
          // console.log("Started opening panel: " + panel.id);
        });
        api.bind("openPanel:finish", (panel) => {
          // console.log("Finished opening panel: " + panel.id);
        });
        api.bind("setSelected:before", (panel) => {
          // console.log("Started opening panel: " + panel.id);
        });
        api.bind("setSelected:after", (panel) => {
          api.closeAllPanels();
          api.close();
          // console.log("Finished opening panel: " + panel.id);
        });

        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (!isIE11) {
          $(".mh-head.Sticky").mhead({
            scroll: {
              hide: 200,
            },
          });

          $(".mh-head:not(.Sticky)").mhead({
            scroll: false,
          });
        }
      });
      //loadMobileMenu();
    }
  }

  collapseSideMenu(menuId) {
    if (this.selectedMenu === menuId) {
      this.selectedMenu = "";
    } else {
      this.selectedMenu = menuId;
    }
  }

  selectSubMenu(subMenuItem: string) {
    event.stopPropagation();
    $('#ww-mobile-menu').modal('hide');
    this.selectedSubMenu = subMenuItem;
  }

  handleMobileMenuClick(event: Event) {
    event.stopPropagation();
    $('#ww-mobile-menu').modal('show');
  }

  selectMenuHead(selectedHeadMenu: string) {
    this.selectedMobileMenuHeaderCurrent = selectedHeadMenu;
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
}
