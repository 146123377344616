<div class="modal fade" id="requestDetailsModal" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:0.5rem 1rem;">
                <span>{{ this.formTitle }}</span>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="OnClickOk()"
                    *ngIf="showMessage">
                    <span style="font-size: 2rem;color: #dc3545;" aria-hidden="true">&times;</span>
                </button>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" *ngIf="showForm">
                    <span style="font-size: 2rem;color: #dc3545;" aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body">
                <!--  F O R M   -->
                <form (ngSubmit)="onSubmit(contactUsForm)" #contactUsForm="ngForm" *ngIf="showForm">
                    <section id="request-change-form">
                        <div class="container">
                            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
                            <div class="row justify-content-center">
                                <div class="col-md-12 col-xs-12">

                                    <div class="form-div">
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="requestDetails.name" name="name" required
                                                        #name="ngModel" maxlength="150" />
                                                    <label for="name">Name <span>*</span></label>
                                                </div>

                                                <label *ngIf="(name.hasError('required') && name.touched)"
                                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                            </div>

                                        </div>
                                        <div class="row pt-2">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" [(ngModel)]="requestDetails.email"
                                                        name="email" required #email="ngModel" emailValidator
                                                        maxlength="150">
                                                    <label for="Email">Email <span>*</span></label>
                                                </div>
                                                <label *ngIf="(email.hasError('required') && email.touched)"
                                                    class="error-message text-nowrap">
                                                    {{constantsObj.requiredErrorMessage}}
                                                </label>
                                                <label
                                                    *ngIf="(email.hasError('emailValidator') && email.touched && !email.hasError('required'))"
                                                    class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row pt-2">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="requestDetails.company" name="company" required
                                                        #company="ngModel" maxlength="150" />
                                                    <label for="Company">Company <span>*</span></label>
                                                </div>
                                                <label *ngIf="(company.hasError('required') && company.touched)"
                                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                            </div>
                                        </div>
                                        <!-- <div class="row pt-2">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="requestDetails.urlLink" name="urlLink"
                                                        maxlength="150">
                                                    <label for="urlLink">URL</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pt-2">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="requestDetails.businessType" name="businessType"
                                                        maxlength="150">
                                                    <label for="businessType">Business Type</label>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="row pt-2">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <textarea class="form-control" aria-label="With textarea"
                                                        [(ngModel)]="requestDetails.message" name="message"
                                                        maxlength="1500"></textarea>
                                                    <label for="message">Message</label>
                                                </div>
                                                <!-- <label for="message">Message</label>
                                                <textarea class="form-control" aria-label="With textarea"
                                                    [(ngModel)]="requestDetails.message" name="message"
                                                    maxlength="1500"></textarea> -->
                                            </div>
                                        </div>

                                        <!-- Added for WSTW-5968 -->
                                        <div class="row pt-2">
                                            <div class="col">
                                                <div class="form-label-group">
                                                    <textarea class="form-control" aria-label="With textarea"
                                                        [(ngModel)]="requestDetails.howdidyouhear" name="howdidyouhear"
                                                        maxlength="255"></textarea>
                                                    <label for="howdidyouhear">How did you hear about us?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end -->
                                    </div>


                                    <div class="container pt-2">
                                        <div class="row">
                                            <p class="req-msg-note pl-3">*These fields are required</p>
                                        </div>
                                    </div>

                                    <div class="container">
                                        <div class="row">
                                            <div class="row pl-3 text-center">
                                                <div class="col pr-5 pb-2 text-right"
                                                    *ngIf="!this.commonService.isSafariBroswer()">
                                                    <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language"
                                                        (success)="handleSuccess($event)" (load)="handleLoad()"
                                                        (expire)="handleExpire()" [theme]="theme" [type]="type"
                                                        [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                                    </ngx-recaptcha2>
                                                </div>
                                            </div>
                                            <div class="row pl-3 text-center">
                                                <div class="col pr-5 pb-2 text-right" *ngIf="false">
                                                    <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey"
                                                        (ready)="handleReady()" (load)="handleLoad()"
                                                        (success)="handleSuccess($event)" [type]="type" [badge]="badge"
                                                        [ngModel]="recaptcha" [ngModelOptions]="{ standalone: true }">
                                                    </ngx-invisible-recaptcha>

                                                </div>
                                            </div>
                                        </div>
                                        <label *ngIf="(captchaRequired && contactUsForm.submitted)"
                                            class="error-message text-nowrap pl-2">{{constantsObj.requiredErrorMessage}}</label>
                                    </div>

                                    <div class="container">
                                        <div class="row">
                                            <div class="row pl-2 text-center">
                                                <div class="col pr-5">
                                                    <button type="submit" class="btn pl-2">
                                                        <div class="angled-button-sm button-width-110">
                                                            <span>Submit</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
                <!-- // F O R M  -->
                <p *ngIf="showMessage">{{displayMessage}}</p>
            </div>
            <div class="modal-footer" *ngIf="false">
                <!-- <div class="modal-footer" *ngIf="showMessage"> -->

                <button class="angled-button-sm button-width-110 btn banner-button" aria-hidden="true"
                    data-dismiss="modal" (click)="OnClickOk()" aria-label="Close">OK
                </button>

            </div>
        </div>
    </div>
</div>