import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { Constants } from './../../Utilities/Constants/constants'; 
import { DataService } from 'src/app/service/data.service';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
declare var $;
@Component({
  selector: 'app-leadership',
  templateUrl: './leadership.component.html',
  styleUrls: ['./leadership.component.css']
})
export class LeadershipComponent implements OnInit, AfterViewInit {

  const: Constants;
  
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/leadership-team-header.jpg',
    desktopHeaderText: 'Leadership Team',
    desktopHeaderSubText: "Meet the team committed to delivering a superior experience for clients, customers and colleagues.",
    mobileHeaderText: 'Leadership Team',
    mobileHeaderSubText: ''
  });
  constructor(
    public commonService: CommonService,
    public constantObj: Constants,
    public dataService: DataService  
  ) {
    this.const = constantObj;
  }
  isLoading = false;

  ngOnInit() {
    
  }

  

  ngAfterViewInit() {
    breadcrumbShow();
    
  }

}

