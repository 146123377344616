<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-lender-requests" class="sub">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9 col-md-12 col-sm-12 col-xs-8 text-center header-shape">
                <h1 class="text-center sub-h2">Lender Requests/Changes</h1>
                <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
            </div>
        </div>
    </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- //  H E A D E R   I M G   -->



<!-- B R E A D C R U M B  -->

<!-- <section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a [href]="wpBaseUrl" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section> -->

<!-- // B R E A D C R U M B  -->

<!-- R E Q U E S T  E V I D E N C E   -->
<form (ngSubmit)="onSubmit(requestPolicyChangeForm)" #requestPolicyChangeForm="ngForm">
    <section id="request-change-form" class="pb-2">
        <div class="container">
            <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
            <div class="row pt-5 justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <p class="lender-text">
                        If you are a lender needing a <b>new policy</b> issued, please email <a
                            href="mailto:insuranceteam@westwoodins.com">insuranceteam@westwoodins.com</a>.
                    </p>
                    <p class="lender-text">
                        <!-- Due to historically low interest rates, we are experiencing higher than normal volume. Thank you
                        in advance for your 
                        patience.--> Please do not submit multiple requests as this could delay processing.
                    </p>
                    <!-- <span class="pl-3">Request Evidence of Insurance</span> -->
                    <br>
                    <div class="form-div">
                        <div class="row">
                            <div class="col-8">
                                <label for="typeOfRequest">Type of Request <span>*</span></label>

                                <span data-toggle="collapse" href="#personal" role="button" aria-expanded="false"
                                    aria-controls="personal">
                                    <i
                                        class="fa fa-question-circle help-icon-custom text-sm-right help date-helper-icon text-dark"></i>
                                </span>
                                <select class="form-control" id="txttypeOfRequest" name="typeOfRequest" required
                                    [(ngModel)]="lenderRequestChangeDetails.requestType" #typeOfRequest="ngModel"
                                    #typeOfRequests>
                                    <option selected value=""></option>
                                    <option value="R">Mortgage Change</option>
                                    <option value="E">Evidence of Insurance</option>
                                </select>
                                <label *ngIf="(typeOfRequest.hasError('required') && typeOfRequest.touched) "
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>


                            </div>
                            <!-- Start help text -->
                            <div class="collapse multi-collapse w-100" id="personal">
                                <div class="card card-body mb-2">
                                    <div class="row grid-divider">
                                        <div class="col-sm-12 col-md-6 col-lg-6 my-1">
                                            <span class="span-tool-tip">Mortgage Change</span>
                                            <br>

                                            <p>Request may include:</p>
                                            <ul>
                                                <li>Add new mortgagee clause</li>
                                                <li>Edit existing mortgagee clause (name, address and/or loan number)
                                                </li>
                                                <li>Replace existing mortgagee clause</li>
                                            </ul>
                                            <p>Request is defined as:</p>
                                            <ul>
                                                <li>Mutual client is refinancing his/her home and there is a new
                                                    mortgagee clause</li>
                                                <li>The current mortgagee clause needs to be changed (name, address
                                                    and/or loan number)</li>
                                            </ul>
                                            <p>What will be provided?</p>
                                            <ul>
                                                <li>Updated current evidence of insurance (if applicable, renewal
                                                    evidence of insurance)</li>
                                                <li>Payment confirmation (paid in full or balance due receipt)</li>
                                                <li>Extended replacement cost coverage percentage (if applicable)
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6 my-1">
                                            <span class="span-tool-tip">Evidence of Insurance</span><br>
                                            <p>Request details:</p>
                                            <ul>
                                                <li>Information from current/renewal policy term</li>
                                            </ul>
                                            <p>Request is defined as:
                                            </p>
                                            <ul>
                                                <li>Proof of insurance/declarations page
                                                </li>
                                            </ul>
                                            <p>What will be provided?</p>
                                            <ul>
                                                <li>As is copy of current evidence of insurance (if applicable, renewal
                                                    evidence of insurance) </li>
                                                <li>Payment confirmation (paid in full or balance due receipt)</li>
                                                <li>Extended replacement cost coverage percentage (if applicable)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End help text -->
                        </div>

                        <div class="row pt-3">
                            <div class="col-8">
                                <label for="policynumber">Policy Number <span>*</span></label>
                                <input type="text" autocomplete="off" class="form-control" maxlength="25"
                                    [(ngModel)]="lenderRequestChangeDetails.policyNumber" name="PolicyNumber"
                                    #PolicyNumber="ngModel" #PolicyNumberDP required />
                                <label *ngIf="(PolicyNumber.hasError('required') && PolicyNumber.touched) "
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-xs-12 col-sm-12 pt-3">
                                <label for="FirstName">Customer First Name <span>*</span></label>
                                <input type="text" class="form-control" id="firstName" placeholder="First Name"
                                    maxlength="17" required [(ngModel)]="lenderRequestChangeDetails.firstName"
                                    name="FirstName" #firstName="ngModel" #firstNameDP />
                                <label *ngIf="(firstName.hasError('required') && firstName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col-lg-6 col-xs-12 col-sm-12 pt-3">
                                <label for="LastName">Customer Last Name <span>*</span></label>
                                <input type="text" class="form-control" id="lastName" placeholder="Last Name"
                                    maxlength="20" required [(ngModel)]="lenderRequestChangeDetails.lastName"
                                    name="LastName" #lastName="ngModel" #lastNameDP />
                                <label *ngIf="(lastName.hasError('required') && lastName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                        </div>

                        <div class="row pt-3">
                            <div class="col">
                                <label for="vestingText">Vesting </label>
                                <select class="form-control" [(ngModel)]="lenderRequestChangeDetails.vesting"
                                    name="Vesting" #Vesting="ngModel" id="vestingText">
                                    <option selected value=""></option>
                                    <option value="A married man">A married man</option>
                                    <option value="A married man as his sole and separate property">A married man as his
                                        sole and separate property</option>
                                    <option value="A married woman">A married woman</option>
                                    <option value="A married woman as her sole and separate property">A married woman as
                                        her sole and separate property</option>
                                    <option value="A single man">A single man</option>
                                    <option value="A single man, all as joint tenants">A single man, all as joint
                                        tenants</option>
                                    <option value="A single woman">A single woman</option>
                                    <option value="A single woman, all as joint tenants">A single woman, all as joint
                                        tenants</option>
                                    <option value="A widow">A widow</option>
                                    <option value="A widower">A widower</option>
                                    <option value="An unmarried man">An unmarried man</option>
                                    <option value="An unmarried woman">An unmarried woman</option>
                                    <option value="As her sole and separate property">As her sole and separate property
                                    </option>
                                    <option value="As his sole and separate property">As his sole and separate property
                                    </option>
                                    <option value="Husband and wife">Husband and wife</option>
                                    <option value="Husband and wife as community property">Husband and wife as community
                                        property</option>
                                    <option value="Husband and wife as community property with right of survivorship">
                                        Husband and wife as community property with right of survivorship</option>
                                    <option value="Husband and wife as joint tenants">Husband and wife as joint tenants
                                    </option>
                                    <option value="Husband and wife as tenants in common">Husband and wife as tenants in
                                        common</option>
                                    <option value="Wife and husband">Wife and husband</option>
                                    <option value="Wife and husband as community property">Wife and husband as community
                                        property</option>
                                    <option value="Wife and husband as community property with right of survivorship">
                                        Wife and husband as community property with right of survivorship</option>
                                    <option value="Wife and husband as joint tenants">Wife and husband as joint tenants
                                    </option>
                                    <option value="Wife and husband as tenants in common">Wife and husband as tenants in
                                        common</option>
                                </select>
                                <label *ngIf="(Vesting.hasError('required') && Vesting.touched)"
                                    class="error-message text-nowrap"> {{ constantsObj.requiredErrorMessage }}</label>

                            </div>
                        </div>

                        <div class="row pt-3">
                            <div class="col">
                                <label for="addressLine1">Property Location Address Line 1 <span>*</span></label>
                                <input type="text" class="form-control address-line-one address" #propStreetAddress
                                    name="AddressLine1" placeholder="&nbsp; &nbsp; Property Location Address Line 1"
                                    required appGoogleAutoCompletePlaces (onselect)="SetPropertyAddressDetails($event);"
                                    [(ngModel)]="lenderRequestChangeDetails.addressLine1" maxlength="36"
                                    id="address-line-one" #propStreetAddressV="ngModel"
                                    (ngModelChange)="changingAddressLineOne()" />
                                <i class="far fa-search address-line-search-icon" aria-hidden="true"
                                    id="address-line-search-one"
                                    [ngClass]="  (propStreetAddressV.hasError('required') && propStreetAddressV.touched)  ? 'far fa-search address-line-search-icon-req' : 'far fa-search address-line-search-icon-req'"></i>
                            </div>
                        </div>
                        <label *ngIf="(propStreetAddressV.hasError('required') && propStreetAddressV.touched) "
                            class="error-message text-nowrap">
                            {{constantsObj.requiredErrorMessage}}
                        </label>

                        <div class="row pt-3">
                            <div class="col">
                                <label for="Address2">Property Location Address Line 2</label>
                                <input type="email" class="form-control address" id="txtPropertyLine2"
                                    aria-describedby="emailHelp" name="AddressLine2" #line2
                                    placeholder="Property Location Address Line 2"
                                    [(ngModel)]="lenderRequestChangeDetails.addressLine2" maxlength="36" />
                            </div>
                        </div>

                        <div class="row pt-3">
                            <div class="col-lg-12 col-xs-12 col-sm-12 ">
                                <label for="City">Property Location City <span>*</span></label>
                                <input type="text" class="form-control" id="txtCity"
                                    placeholder="Property Location City" required #cities
                                    [(ngModel)]="lenderRequestChangeDetails.city" name="City" #city="ngModel"
                                    maxlength="23">
                                <label *ngIf="(city.hasError('required') && city.touched)"
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                        </div>
                        <div class="row ">

                            <div class="col-lg-5 col-xs-12 col-sm-12 pt-3">
                                <label for="state">Property Location State <span>*</span></label>
                                <select class="form-control" id="txtState" name="State"
                                    [(ngModel)]="lenderRequestChangeDetails.state" #state="ngModel" #states required>
                                    <option selected value=""></option>
                                    <option *ngFor="let item of lstStates" [value]="item.stateName">{{item.stateName}}
                                    </option>
                                </select>
                                <label *ngIf="(state.hasError('required') && state.touched) "
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col-lg-5 col-xs-12 col-sm-12 pt-3">
                                <label for="PostalCode">Postal Code <span>*</span></label>
                                <input type="text" class="form-control" id="txtPostalCode" placeholder="Postal Code"
                                    name="PostalCode" minlength="5" maxlength="10" required #postalCodes
                                    [(ngModel)]="lenderRequestChangeDetails.postalCode" #postalCode="ngModel"
                                    ZipcodeValidator />
                                <label *ngIf="(postalCode.hasError('required') && postalCode.touched) "
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                <label
                                    *ngIf="(lenderRequestChangeDetails.postalCode!='' && postalCode.invalid && postalCode.touched)"
                                    class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                            </div>
                        </div>


                        <!-- <div class="row pt-4">
                            <div class="col-8">
                                <label for="Number"> Close Date ( mm/dd/yyyy )<span> *</span> </label>
                                <input type="text" #EstCloseDateDP class="form-control" id="txtEstCloseDate"
                                    placeholder="MM/DD/YYYY" [minDate]="maxDate" name="EstCloseDate" [(ngModel)]="lenderRequestChangeDetails.closeDate"
                                    bsDatepicker #dp1="bsDatepicker" [outsideClick]="true" [isOpen]="isOpen"
                                    (keydown.Tab)="hideDatePicker(dp1)"  #CloseDate="ngModel"
                                    required [bsConfig]="{ showWeekNumbers:false, dateInputFormat: 'MM/DD/YYYY' }"
                                    dateValidatorForCloseDate />
                                <label *ngIf="(CloseDate.hasError('required') && (CloseDate.touched || requestPolicyChangeForm.submitted))"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                <label *ngIf="(CloseDate.hasError('dateValidatorForCloseDate') && (CloseDate.touched || requestPolicyChangeForm.submitted))"
                                    class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                                <label *ngIf="(CloseDate.hasError('pastDateValidatorForCloseDate') && CloseDate.touched)"
                                    class="error-message text-nowrap">{{constantsObj.invalidPastDate}}</label>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- // R E Q U E S T  E V I D E N C E  -->



    <!-- M O R T A G E E   I N F O  -->

    <section id="request-change-form" class="pb-2" *ngIf="lenderRequestChangeDetails.requestType == 'R'">

        <div class="container">
            <div class="row pt-3 justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <span class="pl-3">Mortgagee Information</span>
                    <br>
                    <br>
                    <div class="form-div">
                        <div class="row">
                            <div class="col-6">
                                <label for="policynumber">Loan Number <span>*</span></label>
                                <input type="text" class="form-control" id="loanNumber"
                                    [ngModelOptions]="{ updateOn: 'blur' }" required
                                    [(ngModel)]="lenderRequestChangeDetails.loanNumber" name="LoanNumber"
                                    #LoanNumber="ngModel" maxlength="16">
                                <label *ngIf="(LoanNumber.hasError('required') && LoanNumber.touched) "
                                    class="error-message text-nowrap">
                                    {{constantsObj.requiredErrorMessage}}
                                </label>
                            </div>
                            <div class="col">
                                <label for="policynumber">Loan Position <span>*</span></label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="LoanPosition"
                                            id="LoanPositionOne" value="1" name="LoanPosition" required
                                            (click)="lenderRequestChangeDetails.mortgageeImpounds = ''"
                                            [(ngModel)]="lenderRequestChangeDetails.loanPosition"
                                            #LoanPosition="ngModel">
                                        <label class="form-check-label" for="LoanPositionOne">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="LoanPosition"
                                            id="LoanPositionTwo" value="2" name="LoanPosition" required
                                            (click)="lenderRequestChangeDetails.mortgageeImpounds = 'No'"
                                            [(ngModel)]="lenderRequestChangeDetails.loanPosition"
                                            #LoanPosition="ngModel">
                                        <label class="form-check-label" for="LoanPositionTwo">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="LoanPosition"
                                            id="LoanPosition3" value="3" name="LoanPosition" required
                                            (click)="lenderRequestChangeDetails.mortgageeImpounds = 'No'"
                                            [(ngModel)]="lenderRequestChangeDetails.loanPosition"
                                            #LoanPosition="ngModel">
                                        <label class="form-check-label" for="LoanPosition3">3</label>
                                    </div>
                                </div>
                                <label *ngIf="LoanPosition.hasError('required') && LoanPosition.touched"
                                    class="error-message text-nowrap">{{constantsObj.selectOneErrorMessage}}</label>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-lg-6 col-xs-12 col-sm-12">
                                <label for="FirstName">Mortgagee Name Line 1 <span>*</span></label>
                                <input type="text" class="form-control" id="txtFirstNameMortagegee" maxlength="80"
                                    required [(ngModel)]="lenderRequestChangeDetails.mortgageeFirstName"
                                    name="MortgageeFirstName" #MortgageeFirstName="ngModel" #MortgageeFirstNameDP />
                                <label *ngIf="(MortgageeFirstName.hasError('required') && MortgageeFirstName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col-lg-6 col-xs-12 col-sm-12 mob-pt-3 " *ngIf="false">
                                <label for="LastName">Mortgagee Last Name <span>*</span></label>
                                <input type="text" class="form-control" placeholder="Last Name"
                                    id="mortgageeLastNameText" maxlength="80" required
                                    [(ngModel)]="lenderRequestChangeDetails.mortgageeLastName" name="MortgageeLastName"
                                    #MortgageeLastName="ngModel" #MortgageeLastNameDP />
                                <label *ngIf="(MortgageeLastName.hasError('required') && MortgageeLastName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Mortgagee Name Line 2
                                    </label>
                                    <input type="text" class="form-control" id="MortgageeClauseText" maxlength="80"
                                        [(ngModel)]="lenderRequestChangeDetails.mortgageeClause" name="MortgageeClause"
                                        #MortgageeClause="ngModel" #MortgageeClauseDP />
                                    <label *ngIf="(MortgageeClause.hasError('required') && MortgageeClause.touched)"
                                        class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col">
                                <label for="MortgageeAddressLine1">Mortgagee Address Line 1<span>*</span></label>
                                <input type="text" class="form-control" id="address-line-two" #mortgageeAddressLine1
                                    name="MortgageeAddressLine1" placeholder="&nbsp; &nbsp; Mortgagee Address Line 1"
                                    class="form-control  search-icon address address-line-one" required
                                    appGoogleAutoCompletePlaces (onselect)="SetPropertyAddressDetailsMortgagee($event);"
                                    [(ngModel)]="lenderRequestChangeDetails.mortgageeAddressLine1" maxlength="36"
                                    #mortgageeAddressLine1V="ngModel" (ngModelChange)="changingAddressLineOne()">
                                <i class="far fa-search address-line-search-icon" aria-hidden="true"
                                    id="address-line-search-two"
                                    [ngClass]="  (mortgageeAddressLine1V.hasError('required') && mortgageeAddressLine1V.touched)  ? 'far fa-search address-line-search-icon-req' : 'far fa-search address-line-search-icon'"></i>
                            </div>
                        </div>
                        <label
                            *ngIf="(mortgageeAddressLine1V.hasError('required') && mortgageeAddressLine1V.touched) "
                            class="error-message text-nowrap">
                            {{constantsObj.requiredErrorMessage}}
                        </label>
                        <div class="row pt-3">
                            <div class="col">
                                <label for="Address2">Mortgagee Address Line 2</label>
                                <input type="email" class="form-control" id="mortgageeAddressLine2"
                                    aria-describedby="emailHelp" name="MortgageeAddressLine2" #mortgageeAddressLine2s
                                    placeholder="Mortgagee Address Line 2"
                                    [(ngModel)]="lenderRequestChangeDetails.mortgageeAddressLine2" maxlength="36" />
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-lg-12 col-xs-12 col-sm-12 ">
                                <label for="MortgageeCity">City <span>*</span></label>
                                <input type="text" class="form-control" id="mortgageeCity" placeholder="City"
                                    #mortgageeCites required #cities
                                    [(ngModel)]="lenderRequestChangeDetails.mortgageeCity" name="MortgageeCity"
                                    #mortgageeCity="ngModel" maxlength="23">
                                <label *ngIf="(mortgageeCity.hasError('required') && mortgageeCity.touched)"
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                            </div>

                        </div>
                        <div class="row pt-3">

                            <div class="col-lg-5 col-xs-12 col-sm-12 ">
                                <label for="mortgageeState">State <span>*</span></label>
                                <select class="form-control" id="mortgageeState" name="mortgageeState" required
                                    [(ngModel)]="lenderRequestChangeDetails.mortgageeState" #mortgageeState="ngModel"
                                    #mortgageeStates>
                                    <option *ngFor="let item of lstStates" [value]="item.stateName">{{item.stateName}}
                                    </option>
                                </select>
                                <label *ngIf="(mortgageeState.hasError('required') && mortgageeState.touched) "
                                    class="error-message">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col-lg-5 col-xs-12 col-sm-12 mob-pt-3">
                                <label for="MortgageePostalCode">Postal Code <span>*</span></label>
                                <input type="text" class="form-control" id="MortgageePostalCode"
                                    placeholder="Postal Code" name="MortgageePostalCode" minlength="5" maxlength="10"
                                    required #mortgageePostalCodes
                                    [(ngModel)]="lenderRequestChangeDetails.mortgageePostalCode"
                                    #mortgageePostalCode="ngModel" ZipcodeValidator />
                                <label
                                    *ngIf="(mortgageePostalCode.hasError('required') && mortgageePostalCode.touched) "
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                <label
                                    *ngIf="(lenderRequestChangeDetails.mortgageePostalCode!='' && mortgageePostalCode.invalid && mortgageePostalCode.touched)"
                                    class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                            </div>

                        </div>
                        <div class="row pt-4">
                            <div class="col">
                                <label for="policynumber">Impound/Escrow? (The Lender is responsible for paying)
                                    <span>*</span></label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="MortgageeImpounds"
                                            id="mortgageeImpoundsOne" value="Yes" name="MortgageeImpounds"
                                            maxlength="20" required
                                            [disabled]="( (lenderRequestChangeDetails.loanPosition === '2' || lenderRequestChangeDetails.loanPosition === '3') )"
                                            [(ngModel)]
                                            ="lenderRequestChangeDetails.mortgageeImpounds"
                                            #MortgageeImpounds="ngModel">
                                        <label class="form-check-label" for="mortgageeImpoundsOne">Yes</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="MortgageeImpounds"
                                            id="mortgageeImpounds2" value="No" name="MortgageeImpounds" required
                                            
                                            [(ngModel)]="lenderRequestChangeDetails.mortgageeImpounds"
                                            #MortgageeImpounds="ngModel">
                                        <label class="form-check-label" for="mortgageeImpounds2">No</label>
                                    </div>
                                </div>
                                <label *ngIf="MortgageeImpounds.hasError('required') && MortgageeImpounds.touched"
                                    class="error-message text-nowrap">{{constantsObj.selectOneErrorMessage}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- //  M O R T A G E E   I N F O -->

    <!-- R E Q U E S T O R    I N F O R M A T I O N   -->

    <section id="request-change-form" class="pb-2">
        <div class="container">
            <div class="row pt-3 justify-content-center">
                <div class="col-md-7 col-xs-12">
                    <span class="pl-3">Requestor Information</span>
                    <br>
                    <br>
                    <div class="form-div">
                        <div class="row">
                            <div class="col-lg-6 col-xs-12 col-sm-12 pt-3">
                                <label for="FirstName">Requestor First Name <span>*</span></label>
                                <input type="text" class="form-control" id="txtFirstNameRequester"
                                    placeholder="First Name" maxlength="17" required
                                    [(ngModel)]="lenderRequestChangeDetails.requesterFirstName"
                                    name="RequesterFirstName" #RequesterFirstName="ngModel" #RequesterFirstNameDP />
                                <label *ngIf="(RequesterFirstName.hasError('required') && RequesterFirstName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                            <div class="col-lg-6 col-xs-12 col-sm-12 pt-3">
                                <label for="LastName">Requestor Last Name <span>*</span></label>
                                <input type="text" placeholder="Last Name" class="form-control"
                                    id="RequesterLastNameText" maxlength="20" required
                                    [(ngModel)]="lenderRequestChangeDetails.requesterLastName" name="RequesterLastName"
                                    #RequesterLastName="ngModel" #RequesterLastNameDP />
                                <label *ngIf="(RequesterLastName.hasError('required') && RequesterLastName.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col">
                                <label for="Email">Requestor Email <span>*</span></label>
                                <input type="email" class="form-control" id="txtEmail" placeholder="Requestor Email" required
                                    [(ngModel)]="lenderRequestChangeDetails.requesterEmail" name="RequesterEmail"
                                    #RequesterEmail="ngModel" emailValidator maxlength="100">
                                <label *ngIf="(RequesterEmail.hasError('required') && RequesterEmail.touched)"
                                    class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                <label
                                    *ngIf="(RequesterEmail.hasError('emailValidator') && RequesterEmail.touched && !RequesterEmail.hasError('required'))"
                                    class="error-message text-nowrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                            </div>
                        </div>
                        <!-- Start Confirm Requestor Email -->
                        <div class="row pt-3">
                            <div class="col">
                                <label for="Email">Confirm Requestor Email <span>*</span></label>
                                <input 
                                    type="email" 
                                    class="form-control" 
                                    id="txtEmail" 
                                    placeholder="Confirm Requestor Email" 
                                    required
                                    [disabled] = "lenderRequestChangeDetails.requesterEmail === '' ||lenderRequestChangeDetails.requesterEmail === null "
                                    [(ngModel)]="confirmRequesterEmail" name="ConfirmRequesterEmail"
                                    #ConfirmRequesterEmail="ngModel" 
                                    emailValidator maxlength="100"
                                    compare-password="RequesterEmail"
                                    onCopy="return false" 
                                    onDrag="return false" 
                                    onDrop="return false" 
                                    onPaste="return false" 
                                    autocomplete=off
                                    >
                                <label *ngIf="(ConfirmRequesterEmail.hasError('required') && (ConfirmRequesterEmail.touched || requestPolicyChangeForm.submitted))"
                                    class="error-message text-wrap">{{constantsObj.requiredErrorMessage}}</label>
                                    <label
                                    *ngIf="(ConfirmRequesterEmail.hasError('emailValidator') && (ConfirmRequesterEmail.touched || requestPolicyChangeForm.submitted) && !ConfirmRequesterEmail.hasError('required') )"
                                    class="error-message text-wrap">{{constantsObj.invalidFormatErrorMessage}}</label>
                                    <label
                                    *ngIf="(ConfirmRequesterEmail.hasError('compare') && (ConfirmRequesterEmail.touched || requestPolicyChangeForm.submitted) && !ConfirmRequesterEmail.hasError('required') && !ConfirmRequesterEmail.hasError('emailValidator'))"
                                    class="error-message text-wrap">{{constantsObj.invalidConfirmPassword}}</label>
                                
                            </div>
                        </div>
                        <!-- End Confirm Requestor Email -->

                        <div class="row pt-3" *ngIf="false">
                            <div class="col">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Instructions <span>*</span>
                                    </label>
                                    <textarea class="form-control" id="Requesterinstructor" required
                                        [(ngModel)]="lenderRequestChangeDetails.requesterinstructor"
                                        name="Requesterinstructor" #Requesterinstructor="ngModel" rows="3"></textarea>
                                    <label
                                        *ngIf="(Requesterinstructor.hasError('required') && Requesterinstructor.touched)"
                                        class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section id="request-change-form" class="pb-2 pt-2">
        <div class="container">
            <div class="row justify-content-center ml-2">
                <div class="col-md-7 col-xs-12">
                    <p class="error-message text-nowrap">*These fields are required</p>
                    <div>
                        <!-- <div class="row">
                            <div class="col pr-5 pb-2 text-right" *ngIf="false">
                                <ngx-recaptcha2 [siteKey]="siteKey" [size]="size" [hl]="language"
                                    (success)="handleSuccess($event)" (load)="handleLoad()" (expire)="handleExpire()"
                                    [theme]="theme" [type]="type" [(ngModel)]="recaptcha"
                                    [ngModelOptions]="{ standalone: true }">
                                </ngx-recaptcha2>
                            </div>
                        </div> -->
                        <div class="row">
                            <ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (ready)="handleReady()" (load)="handleLoad()"
                                (success)="handleSuccess($event)" [type]="type" [badge]="badge" [ngModel]="recaptcha"
                                [ngModelOptions]="{ standalone: true }">
                            </ngx-invisible-recaptcha>
                            <!-- <div class="col pr-5 pb-2 text-right" *ngIf="false">
                            </div> -->
                        </div>
                        <label *ngIf="(captchaRequired && requestPolicyChangeForm.submitted)"
                            class="error-message text-nowrap">{{constantsObj.requiredErrorMessage}}</label>

                        <div class="row pl-0">
                            <div class="col pr-5 pb-5">
                                <button type="submit" class="btn pl-0">
                                    <div class="btn ww-button button-width-210">Submit Request</div>
                                </button>
                            </div>
                            <div class="col pr-5 pb-5">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>

<!-- //  R E Q U E S T O R    I N F O R M A T I O N   -->

<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>

<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<!-- <section id="homeBlog" class="pt-5">
    <app-knowledge-center></app-knowledge-center>
</section> -->
<!-- // K N O W L E D G E   C E N T E R  -->
<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-lender-request-message (parentFunYes)="parentFunYes()" (parentFunNo)="parentFunNo()"
    (parentFunOk)="parentFunOk()">
</app-model-pop-up-lender-request-message>