<div class="rcv-div">
    <div class="col-md-12">
    <a routerLink="/" class="pl-1">
        <img class="img-fluid" id="headerLogoMobile" src="assets/img/logos/headerlogo.png" style="height:75px;">
    </a>
    <img src="assets/img/PRELOADER.gif" class="preloader" *ngIf="isLoading" />
    <div [innerHTML]="leadDetails.quoteResult.rcvHtml | noSanitize" id="dvRCVhtml" class="">
    </div>

    <div class="form-group row" *ngIf="this.dataService.leadDetails.isServiceUnavailable">
            <p class="font-weight-bold header-blue mb-0 pb-0 text-sm-center p-2" [innerHTML]="dataService.leadDetails.errorMessage">
            </p>
        </div>
    </div>
</div>