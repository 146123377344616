import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow():any;
@Component({
  selector: 'app-golf',
  templateUrl: './golf.component.html',
  styleUrls: ['./golf.component.css']
})
export class GolfComponent implements OnInit,AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'golf-cart-header.4c0637e013a48de94088.jpg',
    desktopHeaderText: 'Golf Cart Insurance',
    desktopHeaderSubText: "Most homeowners policies provide very limited coverage for golf carts — or no coverage at all. Let Westwood help you find the right coverage at the right price.",
    mobileHeaderText: 'Golf Cart Insurance',
    mobileHeaderSubText: ''
  });
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    breadcrumbShow();
  }
  
  
}
