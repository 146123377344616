import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faInfoCircle, faEnvelope, faPhone, faHashtag, faComment, faPhoneSquare, faEnvelopeSquare, faExternalLinkAlt,
  faUser, faCalendarCheck, faBuilding, faChevronLeft, faChevronRight
  , faQuestionCircle, faLeaf, faCheck, faPrint, faEye, faTint, faCheckCircle, faUserPlus, faUndo, faGraduationCap, faArrowLeft, faEdit, faFileUpload
  , faRss, faDownload, faPlusCircle, faFilePdf, faFileWord
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebookF, faTwitter, faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  schemas: []
})
export class FaIconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faInfoCircle, faEnvelope, faPhone, faHashtag, faComment, faPhoneSquare, faEnvelopeSquare, faExternalLinkAlt, faUser, faCalendarCheck,
      faBuilding, faChevronLeft, faChevronRight, faArrowLeft
      , faQuestionCircle, faLeaf, faCheck, faPrint, faEye, faTint, faCheckCircle, faUserPlus, faUndo, faGraduationCap, faEdit, faFileUpload
      , faFacebookF, faTwitter, faLinkedinIn, faRss, faDownload, faPlusCircle, faFilePdf,faFileWord
    );
  }
}