import { Component, OnInit, AfterViewChecked, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { Constants, applyNowBtnType } from '../../Utilities/Constants/constants';
import { DataService } from 'src/app/service/data.service';
import { Lead } from 'src/app/model/lead';
import { QuoteResult } from 'src/app/model/quote-result';
import { QuoteResultService } from 'src/app/service/quote-result.service';
import { DecimalPipe } from '@angular/common';
import { User } from 'src/app/model/user';
import { FormGroup } from '@angular/forms';
import { RouterExtService } from 'src/app/service/router-ext.service';
import { ModelPopupComponent } from '../../shared/model-popup/model-popup.component';
import { Quote } from '@angular/compiler';
import { WizardComponent } from 'angular-archwizard';
import { of } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-quote-result',
  templateUrl: './quote-result.component.html',
  styleUrls: ['./quote-result.component.css'],
  providers: [DecimalPipe]
})
export class QuoteResultComponent implements OnInit {
  QuoteResult: FormGroup;
  LiveChatImage = '';
  isLoading = false;
  enableResetBtn = false;
  orginalQuoteCopy = new QuoteResult();
  _quoteResult: QuoteResult = new QuoteResult();
  _IsReRateUnsucessfull: boolean = false;
  formErrors: {};
  isApplyNowActive: boolean = false;
  isReCalcActive: boolean = false;
  isResetActive: boolean = false;
  height: any;
  PreserveQuote: QuoteResult
  isSameOriginalQuote: boolean = false;
  oldQuote = new QuoteResult();
  changedProperties = [];
  @ViewChild(ModelPopupComponent) popUp: ModelPopupComponent;
  @Input("mainWizard") public wizardStep: WizardComponent;
  @Input("leadDetails") leadDetails: Lead;
  @Output() leadUpdateEvent = new EventEmitter<Lead>();

  constructor(private router: Router,
    public constantsObj: Constants,
    public dataService: DataService,
    // public leadDetails: Lead,
    public quoteService: QuoteResultService,
    public decimalPipe: DecimalPipe,
    public commonService: CommonService,
    private routerExtService: RouterExtService) {
    this.LiveChatImage = constantsObj.LiveChatImage;

  }
  setHeight() {

  }

  ngOnInit() {
    //this.commonService.ScrollToRTQForm();
    this.isLoading = true;
    this.leadDetails = this.dataService.leadDetails;
    if (this.leadDetails.quoteResult.status) {

      this.quoteService.getQuoteResultInfo(this.leadDetails).subscribe((data: Lead) => {
        this.leadDetails.quoteResult = data.quoteResult;
        this.setHeight();
        this.formatQuoteResult();
        this.setOriginalQuote();
        this.isLoading = false;
        this.dataService.leadHistory.pageNumber = 5;
        this.dataService.leadHistory.preValue = JSON.parse(JSON.stringify(this.leadDetails));

      }, err => {
        this.setHeight();
        this.isLoading = false;
        this.dataService.handleError(err);
        this.leadDetails.quoteResult.quoteMessage = 'failed';
      });
    }
    else {
      this.isLoading = false;
      this.setHeight();
      //this.getDummyQuote();
    }
  }
  formatQuoteResult() {
    this.leadDetails.quoteResult.coverageAAmount = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageAAmount, '1.0-0')
    this.leadDetails.quoteResult.coverageCAmount = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageCAmount, '1.0-0')
    this.leadDetails.quoteResult.coverageEAmount = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageEAmount, '1.0-0')
    this.leadDetails.quoteResult.coverageFAmount = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageFAmount, '1.0-0')
    if (this.leadDetails.quoteResult.deductibles != null && this.leadDetails.quoteResult.deductibles.length > 0) {
      this.leadDetails.quoteResult.deductibles.forEach((element) => {
        element.amount = this.decimalPipe.transform(element.amount, '1.0-0')
      });
    }
  }
  setOriginalQuote() {
    this.leadDetails.quoteResult_Prev = JSON.parse(JSON.stringify(this.leadDetails.quoteResult));
    this.oldQuote = JSON.parse(JSON.stringify(this.leadDetails.quoteResult));
  }
  getOriginalQuote(QuoteResult) {
    this.leadDetails.quoteResult = JSON.parse(JSON.stringify(this.leadDetails.quoteResult_Prev));
    QuoteResult['form'].pristine = true;
    this.enableResetBtn = false;
    this.leadDetails.quoteResult.applyNowBtnType = applyNowBtnType.DBSave;
    this.applyQuote('apply', null, true);
  }
  ngOnDestroy() {
    this.dataService.leadDetails = this.leadDetails;
    this.commonService.EmptyLeadObject();
  }
  DisableButton(Name: string, isCoveragesChanged: boolean) {
    var isDisabled = true;
    switch (Name) {
      case 'Apply':
        if (!isCoveragesChanged)
          isDisabled = false;
        this.isApplyNowActive = !isDisabled;
        break;
      case 'ReCalc':
        isDisabled = this.isApplyNowActive == true ? true : false;
        this.isReCalcActive = !isDisabled;
        break;
      case 'Reset':
        if (!this.isSameOriginalQuote) {
          if (isCoveragesChanged || this.enableResetBtn)
            isDisabled = false;
        }
        this.isResetActive = !isDisabled;
        break;
    }
    return isDisabled;
  }
  savePageDetails() {
    this.isLoading = true;
    this.commonService.CreateLeadHistoryObj(5, this.leadDetails);
    this.applyQuote('contactMe');
    this.commonService.SaveLeadHistoryFromContactBtn();
  }
  ngAfterViewChecked() {
    this.height = $('.container').height();
    this.height = this.height + 30;
    this.loadTooltip();
  }
  loadTooltip = function () {
    $('.helperCovA').tooltip({ title: "<p class='text-left'>The physical structure or dwelling up to the limit shown on your quote/policy. <br/> This coverage should equal the replacement cost to rebuild a new home in the event of a total loss. <br/>This includes materials and labor and is based on averages in your specific location. <br/>For condo coverage this is additions and alterations and coverage needs vary based on complex bylaws. <br/> <b>NOTE</b>: Land value is not included in this valuation of your dwelling. <br/> Not applicable on Renters insurance. (HO4)</p> ", html: true, placement: "top" });
    $('.helperCovB').tooltip({ title: "<p class='text-left'>Structures on the property, which are not attached to the dwelling, up to the limit shown on your quote/policy. These may include swimming pools, driveways, fences, sheds, etc. <br/> Coverage is a percentage of your Dwelling Coverage and cannot be removed. Not applicable on Condo or Renters insurance. (HO6, HO4) </p> ", html: true, placement: "top" });
    $('.helperCovC').tooltip({ title: "<p class='text-left'>Personal belongings owned by you and located on the residence premises, up to the limit shown on your quote/policy.<br/> It includes real property such as appliances, furniture, clothing, dinnerware, cookware, audio-visual equipment, lawn care equipment, and many other items. </p> ", html: true, placement: "top" });
    $('.helperCovD').tooltip({ title: "<p class='text-left'>Additional costs incurred due to displacement in the event of a covered loss. This may include hotel, apartment, rental home, as well as meals, increased laundry and other related costs. </p>", html: true, placement: "top" });
    $('.helperCovE').tooltip({ title: "<p class='text-left'>Liability coverage up to the limit shown on your quote/policy, when you are legally obligated. These claims result from injury or negligence on or off the insured property.<br/> Additional coverage may be available for an additional premium. </p> ", html: true, placement: "top" });
    $('.helperCovF').tooltip({ title: "<p class='text-left'>Medical payments, up to the limit shown on your quote/policy, to individuals/guests injured on your property regardless of negligence. Coverage is not provided for family members living within the household.<br/> Additional coverage may be available for an additional premium.  </p> ", html: true, placement: "top" });
    $('.helperDeductibles').tooltip({ title: "<p class='text-left'>The deductible is the co-insurance amount you pay towards a settled claim. Deductibles can in some cases be increased, resulting in a premium savings, or lowered, resulting in an additional premium charge.<br/> Deductibles may be different for certain perils depending on the location of your property. </p> ", html: true, placement: "top" });
    return '';
  }
  twoDecimal(yourNumber: number) {
    return yourNumber.toFixed(2);
  }
  updateQuote(QuoteResult) {
    this.isLoading = true;
    QuoteResult['form'].pristine = true;
    this._IsReRateUnsucessfull = false;
    this.getUpdatedCoverages(QuoteResult);
    this.PreserveQuote = JSON.parse(JSON.stringify(this.leadDetails.quoteResult_Prev));
    this.quoteService.RecalcQuote(this.leadDetails).subscribe((data: Lead) => {
      this.enableResetBtn = true;
      this.leadDetails = data;
      if (this.leadDetails.quoteResult.status) {
        this.leadDetails = data;
        this.leadDetails.quoteResult = data.quoteResult;
        this.setHeight();
        this.formatQuoteResult();
        this.compareUpdatedCoverages(this.leadDetails.quoteResult, this.PreserveQuote);
        this.isLoading = false;
        this.oldQuote = JSON.parse(JSON.stringify(this.leadDetails.quoteResult));
      }
      else {
        //#R48.0 There is a chance that re-rate may fail then display the old quote 
        this.leadDetails = data;
        this.leadDetails.quoteResult = this.leadDetails.quoteResult_Prev;
        this.oldQuote = JSON.parse(JSON.stringify(this.leadDetails.quoteResult));
        this.formatQuoteResult();
        this.setHeight();
        this.leadDetails.quoteResult.status = true;
        this.leadDetails.quoteResult.quoteMessage = '';
        this._IsReRateUnsucessfull = true;
        this.isSameOriginalQuote = true;
        this.setHeight();
        //this.setOriginalQuote();
        this.isLoading = false;
      }
      this.leadDetails.quoteResult_Prev = this.PreserveQuote;
    }, err => {
      this.setHeight();
      this.isLoading = false;
      this.dataService.handleError(err);
      this.leadDetails.quoteResult.quoteMessage = 'failed';
    });
    //return false;
  }
  getDummyQuote() {
    this._IsReRateUnsucessfull = true;
    this.dataService.leadDetails = new Lead();
    this.leadDetails = this.dataService.leadDetails;
    this.leadDetails.leadID = '41887334-0750-4293-8EAC-B6376E7D8D7D'
    this.leadDetails.quoteResult = new QuoteResult();
    this.leadDetails.quoteResult.quoteMessage = ''
    this.leadDetails.quoteResult.status = true;
    this.leadDetails.salesAgent = new User();
    this.leadDetails.salesAgent.lastName = 'Shaik';
    this.leadDetails.salesAgent.firstName = 'Rabia';
    this.leadDetails.salesAgent.phoneNumber = this.constantsObj.sourcePhoneNumber;
    this.leadDetails.customerAddress.streetAddress = '564 Shadow Lane';
    this.leadDetails.customerAddress.line2 = '';
    this.leadDetails.customerAddress.city = 'Simi Valley';
    this.leadDetails.customerAddress.state = 'CA';
    this.leadDetails.customerAddress.zipCode = '93065';
    this.dataService.leadDetails.isServiceUnavailable = false;
    this.leadDetails.quoteResult.isRCVRun = true;
    this.leadDetails.quoteResult.coverageAAmount = '10000';
    this.leadDetails.quoteResult.coverageBAmount = '10000';
    this.leadDetails.quoteResult.coverageCAmount = '10000';
    this.leadDetails.quoteResult.coverageDAmount = '10000';
    this.leadDetails.quoteResult.coverageEAmount = '10000';
    this.leadDetails.quoteResult.coverageFAmount = '500';
    this.leadDetails.quoteResult.alternateQuotes = [
      {

        factorId: '1F24DCFB613942E9A69D4F2B7B9AAFEC', factorAmount: 0.00, factorApplied: null, factorPercent: 0, monthlyPremium: 120,

        totalPremium: 1200, factorName: 'Account Credit - Preferred Partner',

      },
      {
        factorId: '1F24DCFB613942E9A69D4F2B7B9AAFEC', factorAmount: 0.00, factorApplied: null, factorPercent: 0, monthlyPremium: 240,

        totalPremium: 2400, factorName: 'Account Credit - Flood'
      }
    ]
    this.leadDetails.quoteResult.rcvHtml = 'hey anjan';
    this.leadDetails.quoteResult.policyTexts = [
      { lookupId: '1', lookupValue: 'Replacement Cost on Contents' },
      { lookupId: '2', lookupValue: 'Ordinance or Law Coverage is Excluded' },
      { lookupId: '3', lookupValue: 'Sinkhole Endorsement Not Included but Catastrophic Ground Collapse Is Included' },
      { lookupId: '4', lookupValue: 'Screen Enclosure (Lanai) & Carport Coverage Excluded for Hurricane Damage' },
      { lookupId: '5', lookupValue: 'Limited Coverage for Animal Liability' },
    ]
    this.leadDetails.quoteResult.deductibles = [
      { amount: 42323, id: '', name: '', lstOptions: [{ lookupId: '', lookupValue: '300' }] }
    ]
    this.leadDetails.quoteResult.deductibles = [
      { amount: 42323, id: '', name: '', lstOptions: [{ lookupId: '', lookupValue: '456' }] }
    ]
    // this.leadDetails.leadID ='A0E96613-0F5C-4A3C-873A-D5EB195649C3';
    // this.formatQuoteResult();
    this.setOriginalQuote();
    this.isLoading = false;

  }
  storeData(event) {
    this.leadDetails.quoteResult.coverageAprint = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageAAmount.toString().replace(/,/g, ''), '1.0-0')
    this.leadDetails.quoteResult.coverageBprint = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageBAmount.toString().replace(/,/g, ''), '1.0-0')
    this.leadDetails.quoteResult.coverageCprint = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageCAmount.toString().replace(/,/g, ''), '1.0-0')
    this.leadDetails.quoteResult.coverageDprint = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageDAmount.toString().replace(/,/g, ''), '1.0-0')
    this.leadDetails.quoteResult.coverageEprint = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageEAmount.toString().replace(/,/g, ''), '1.0-0')
    this.leadDetails.quoteResult.coverageFprint = this.decimalPipe.transform(this.leadDetails.quoteResult.coverageFAmount.toString().replace(/,/g, ''), '1.0-0')

    window.localStorage.setItem('leadData', JSON.stringify(this.leadDetails));
    window.open( "/real-time-quote/quote-result/print" );
    //this.commonService.NavigateToPage('/real-time-quote/quote-result/print');
    //console.log(window.localStorage.getItem('leadData'))
  }

  formatDecimal($event) {
    this.leadDetails.quoteResult.coverageAAmount = parseFloat($event.replace(/,/g, ''));
  }
  formatDeductibles(obj) {
    if (obj < 50)
      return obj + '%'
    else
      return '&#36;' + obj
  }
  applyQuote(btnName: string, alternateFactorID: string = '', isReset: boolean = false) {
    this.commonService.ScrollToRTQForm();
    // this.dataService.leadHistory.preValue=JSON.parse(JSON.stringify(this.leadDetails));
    this.isLoading = true;
    if (btnName == 'apply' && !isReset) 
      this.leadDetails.quoteResult.applyNowBtnType = applyNowBtnType.Regular;
    else if (btnName == 'contactMe')
      this.leadDetails.quoteResult.applyNowBtnType = applyNowBtnType.ContactMe;
    else if (btnName == 'alternate') {
      this.leadDetails.quoteResult.applyNowBtnType = applyNowBtnType.AlternateQuote;
      this.leadDetails.quoteResult.alternateFactorIDSelected = alternateFactorID;
    }
    this.dataService.leadHistory.noUpdate = false;

    if (btnName == 'apply' && isReset) {
      this.formatQuoteResult();
      this.isLoading = false;
      this.leadUpdateEvent.emit(this.leadDetails);
    } else {
      this.quoteService.postQuoteResultInfo(this.leadDetails).subscribe((data: Lead) => {
        this.isLoading = false;
        if (!isReset) {
          if (btnName == 'apply' || btnName == 'alternate'){
            this.leadDetails = data;
            this.dataService.leadDetails = data;
            this.formatQuoteResult();
  
            this.leadUpdateEvent.emit(this.leadDetails);
            // this.commonService.NavigateToPage('/real-time-quote/loan-info');
            this.wizardStep.goToNextStep();
          }
          else if (btnName == 'contactMe')
            this.commonService.NavigateToPage('/real-time-quote/contact');
          this.leadDetails = data;
        }
        else {
         
        }
      }, err => {
        this.setHeight();
        this.isLoading = false;
        if (btnName != 'contactMe') {
          this.dataService.handleError(err);
          this.leadDetails.quoteResult.quoteMessage = 'failed';
        }
        else {
          this.commonService.NavigateToPage('/real-time-quote/contact');
        }
      });
    } 
  }
  getUpdatedCoverages(QuoteResult: FormGroup) {
    this.changedProperties = [];
    Object.keys(QuoteResult.controls).forEach((name) => {
      let currentControl = QuoteResult.controls[name];
      if (currentControl.dirty) {
        var obj = { name: name, value: currentControl.value };
        var item = name;
        switch (item) {
          case 'coverageA':
            if (currentControl.value != this.oldQuote.coverageAAmount)
              this.changedProperties.push(obj);
            break;
          case 'coverageB':
            if (currentControl.value != this.oldQuote.coverageBAmount)
              this.changedProperties.push(obj);
            break;
          case 'coverageC':
            if (currentControl.value != this.oldQuote.coverageCAmount)
              this.changedProperties.push(obj);
            break;
          case 'coverageD':
            if (currentControl.value != this.oldQuote.coverageDAmount)
              this.changedProperties.push(obj);
            break;
          case 'coverageE':
            if (currentControl.value != this.oldQuote.coverageEAmount)
              this.changedProperties.push(obj);
            break;
          case 'coverageF':
            if (currentControl.value != this.oldQuote.coverageFAmount)
              this.changedProperties.push(obj);
            break;
        }
        for (var i = 0; i < this.oldQuote.deductibles.length; i++) {
          switch (item) {
            case 'Deductibles' + i:
              if (currentControl.value != this.oldQuote.deductibles[i].amount)
                this.changedProperties.push(obj);
              break;
          }
        }
      }

    });

  }
  compareUpdatedCoverages(Quote: QuoteResult, OrgQuote: QuoteResult) {
    this.isSameOriginalQuote = false;
    Quote.isSameQuote = false;
    if (Quote != null && Quote.status) {
      if (
        Quote.totalPremium == OrgQuote.totalPremium
        &&
        Quote.insuranceCompanyId == OrgQuote.insuranceCompanyId
        &&
        Quote.coverageAAmount == OrgQuote.coverageAAmount
        &&
        Quote.coverageBAmount == OrgQuote.coverageBAmount
        &&
        Quote.coverageCAmount == OrgQuote.coverageCAmount
        &&
        Quote.coverageDAmount == OrgQuote.coverageDAmount
        &&
        Quote.coverageEAmount == OrgQuote.coverageEAmount
        &&
        Quote.coverageEAmount == OrgQuote.coverageEAmount
        &&
        Quote.deductibles.length == OrgQuote.deductibles.length
      ) {
        var count = 0;
        for (var i = 0; i < Quote.deductibles.length; i++) {
          OrgQuote.deductibles.forEach((item) => {
            if (item.id == Quote.deductibles[i].id && item.amount != Quote.deductibles[i].amount) {
              count = count + 1;
            }
          });
        }
        if (count <= 0) {
          this.isSameOriginalQuote = true;
          this.enableResetBtn = false;
          Quote.isSameQuote = true;
        } else {
          this.isSameOriginalQuote = false;
          this.enableResetBtn = true;
          Quote.isSameQuote = false;
        }
      }
      this.changedProperties.forEach((item) => {
        switch (item.name) {
          case 'coverageA':
            if (item.value != Quote.coverageAAmount)
              Quote.isSameQuote = true;
            break;
          case 'coverageB':
            if (item.value != Quote.coverageBAmount)
              Quote.isSameQuote = true;
            break;
          case 'coverageC':
            if (item.value != Quote.coverageCAmount)
              Quote.isSameQuote = true;
            break;
          case 'coverageD':
            if (item.value != Quote.coverageDAmount)
              Quote.isSameQuote = true;
            break;
          case 'coverageE':
            if (item.value != Quote.coverageEAmount)
              Quote.isSameQuote = true;
            break;
          case 'coverageF':
            if (item.value != Quote.coverageFAmount)
              Quote.isSameQuote = true;
            break;
        }
        for (var i = 0; i < Quote.deductibles.length; i++) {
          switch (item.name) {
            case 'Deductibles' + i:
              if (item.value != Quote.deductibles[i].amount)
                Quote.isSameQuote = true;
              break;
          }
        }
      });

    }

  }
  openRCV() {
    this.isLoading = true;
    var newWindow = window.open('', '_blank');
    newWindow.document.write('<div class="loading">Loading&#8230;</div>')
    newWindow.document.title = 'DWELLING REPLACEMENT COST VALUATION';
    this.quoteService.GetRCVHtml(this.leadDetails).subscribe((data: Lead) => {
      let QuoteResultInfo = data.quoteResult;
      newWindow.document.body.innerHTML = '';
      var HTML = QuoteResultInfo.rcvHtml;
      newWindow.document.write(HTML)
      newWindow.location;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.dataService.handleError(err);
      // this.leadDetails.quoteResult.quoteMessage = 'failed';
    });

  }


}
