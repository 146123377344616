<!-- H E A D E R   I M G   -->

<!-- <section id="header-img-claim-pre" class="sub">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-9 col-sm-12 col-xs-8 text-center header-shape">
        <h2 class="text-center sub-h2">Prevent a claim</h2>
        <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" />
      </div>
    </div>
  </div>
</section> -->
<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>


<!-- //  H E A D E R   I M G   -->


<!-- B R E A D C R U M B  -->

<section class="container pt-3">
  <nav id="breadcrumb">
    <ol class="breadcrumb">
      <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
    </ol>
  </nav>
</section>

<!-- // B R E A D C R U M B  -->


<!-- I N T R O   P A R A G R A P H  -->



<!-- // I N T R O   P A R A G R A P H  -->


<!--  P R E V E N T I O N    T I P S -->

<section class="container  pt-3">
  <div class="row"> 
    <div class="col">
      <h2 class="text-center">Tips to prevent a claim</h2>
    </div>
  </div>
  <div class="row text-center pt-2 pb-3">
    <div class="col">
      <img src="assets/img/orange-divider-transparent.png" alt="orange line decor" /><br><br>
    </div>
  </div>
  <div class="row pt-2 pb-5">
    <div class="col-md-6 pb-3">
      <img src="assets/img/page-body-img/shutterstock_687132550.png" style="width: 100%;" alt="alarm system" />
    </div>
    <div class="col" id="a-bullet-list">
      <ul>
        <li>Inspect your roof for needed repairs before the bad weather season for your area. </li>
        <li>Check your washing machine and dishwasher hoses regularly. Hoses can deteriorate after five years and are a
          common cause of flooding. If you’re going on a long vacation, turn off the water supply for these appliances.
        </li>
        <li>Check your automatic icemaker occasionally for leaks. Turn the icemaker off and empty the ice bin if you
          will be away from home for a while. </li>
        <li>Consider installing an alarm system. This can discourage an intruder and you also may receive a premium
          credit on your policy. </li>
        <li>Install water leak and freeze detectors to provide early notification and reduce potential damage. </li>
        <li>Turn off the house water supply before taking a prolonged vacation. </li>
        <li>Follow your local authorities’ guidance about securing your home if you are evacuating in advance of a fire
          or storm. </li>
      </ul>
    </div>
  </div>
</section>

<!-- //  P R E V E N T I O N    T I P S -->


<!-- P L A N   A H E A D  -->

<section class="bg-secondary">
  <div class="container plan-ahead pb-5 pt-5">
    <div class="row">
      <div class="col">
        <p class="text-center"><span>PLAN AHEAD</span></p>
      </div>
    </div>
    <div class="row text-center justify-content-center">
      <div class="col-md-9 col-xs-9">
        <p>
          Most policies not only cover the structure of your house but the contents as well. That’s why it’s important
          to make a detailed inventory of what’s inside your home. Recovering from an emergency event will be a lot
          easier if you:
        </p>
      </div>
    </div>

    <div class="row pt-4">
      <div class="col-md-6 pb-3 col-xs-12">
        <div class="row">
          <div class="col-1">
            <h1>1</h1>
          </div>
          <div class="col"> Catalog the principal contents of each room, including the date of purchase and purchase
            price. </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row">
          <div class="col-1">
            <h1>2</h1>
          </div>
          <div class="col"> Attach receipts for items to your list of contents. You can photograph the receipts with
            your phone if you are keeping an electronic file. </div>
        </div>
      </div>
    </div>



    <div class="row pt-4">
      <div class="col-md-6 col-xs-12">
        <div class="row">
          <div class="col-1">
            <h1>3</h1>
          </div>
          <div class="col"> Photograph items or take a video of each room. Don’t forget the closets – replacing bulging
            wardrobes can be costly! </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="row">
          <div class="col-1 ">
            <h1>4</h1>
          </div>
          <div class="col"> Keep your inventory and other records in an electronic file that you have access to when you
            are away from home.</div>
        </div>
      </div>
    </div>


  </div>
</section>

<!-- // P L A N   A H E A D  -->


<!-- G E T   A   Q U O T E -->
<app-get-aquote-small-banner></app-get-aquote-small-banner>
<!-- //  G E T   A   Q U O T E -->

<!-- K N O W L E D G E   C E N T E R  -->
<section id="homeBlog" class="pt-5">
  <app-knowledge-center></app-knowledge-center>
</section>

<!-- // K N O W L E D G E   C E N T E R  -->


<!-- E X T E R N A L    S I T E    M O D A L   -->

<app-model-pop-up-other-domain></app-model-pop-up-other-domain>

<!-- E X T E R N A L    S I T E    M O D A L   -->