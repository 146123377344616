import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHeaders } 
from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Constants } from '../Utilities/Constants/constants';
import { Action } from '../Utilities/api/action.config';
import { environment } from '../../../src/environments/environment';
import { Lead } from '../model/lead';
import { API_BASE_URL, WEB_BASE_URL } from './config.service';
import { LeadHistory } from '../model/leadHistory';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    apiURL: string;
    wpWebsiteUrl: string;
    _action: Action;
    leadDetails: Lead ;
    leadID: string;
    _error:string;
    phonNumber: string;
    constant: Constants;
    leadHistory:LeadHistory;
    isDisclaimerPending: boolean = true;
    isDisclaimerRequired: boolean = true;
    isRatingSuccess: boolean = false;
    hideSSN: boolean = false;
    private dataSaveSubject: Subject<any> = new Subject();
    isDataSaveInProgress$: Observable<boolean>;

    constructor(private http: HttpClient,
        public constantsObj: Constants, @Inject(API_BASE_URL)  public  baseUrl?:  string, @Inject(WEB_BASE_URL)  public  wordpressWebUrl?:  string) {
        if(this.leadDetails==undefined)this.leadDetails=new Lead();
        this.apiURL = baseUrl;
        this.constant=constantsObj;
        this.wpWebsiteUrl = wordpressWebUrl;
        this.isDataSaveInProgress$ = this.dataSaveSubject.asObservable();
    }
    public handleError(error: HttpErrorResponse) {
        if(this.leadDetails==undefined)this.leadDetails=new Lead();
        this.leadDetails.isServiceUnavailable=true;
        this.leadDetails.isLoading=false;
        if(this.leadDetails!=null && (this.leadDetails.sourceInfo==null ||this.leadDetails.sourceInfo!=null &&
          (this.leadDetails.sourceInfo.phoneNumber==null || this.leadDetails.sourceInfo.phoneNumber=='')))
        this.phonNumber=this.constantsObj.sourcePhoneNumber;
        else
        this.phonNumber=this.formatPhone(this.leadDetails.sourceInfo.phoneNumber);
        this.leadDetails.errorMessage=this.constantsObj.errorMessage.replace('@phoneNumber',this.phonNumber);
        this.leadDetails.errorMessage=this.leadDetails.errorMessage.replace('@formatPhoneNumberForMobile',this.formatPhoneNumber(this.phonNumber))
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          //console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
        //   console.error(
        //     `Backend returned code ${error.status}, ` +
        //     `body was: ${error.error}`);
        }
        
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      };

    public formatPhone(phoneValue)
    {
        // if (phoneValue != null && phoneValue != '' &&
        //     phoneValue.indexOf('(') == -1
        //     && phoneValue.indexOf(')') == -1
        // ) {
        //     phoneValue = "(" + phoneValue.slice(0, 3) + ") " + phoneValue.slice(3, 6) + "-" + phoneValue.slice(6, 10);
        // }
        // return phoneValue;
        var phoneNumber = phoneValue.replace(/\./g, '').replace(/\-/g, '');
        
        if(phoneNumber.length == 10) {
            return '(' + phoneNumber.slice(0,3) + ') ' + phoneNumber.slice(3,6) +'-' + phoneNumber.slice(6,10);
        } else {
            return '';
        }
    }
    //There is one more method in Common service
    formatPhoneNumber(phoneNumber: string) {
        var result;
        var number;
        var ext;
        if (phoneNumber != undefined && phoneNumber != null && phoneNumber.trim() != '') {
            if (phoneNumber.indexOf('ext') > 0) {
                number = phoneNumber.substring(0, phoneNumber.trim().indexOf('ext'));
                result = number.trim();
                var withExt = phoneNumber.substring(phoneNumber.trim().indexOf('ext'), phoneNumber.trim().length);
                if (withExt != undefined && withExt != '' && withExt != null && withExt.split(' ').length > 1) {
                    ext = withExt.split(' ')[1];
                    result = number.trim() + ',0,' + ext.trim();
                }
            }
            else {
                result = phoneNumber.trim();;
            }
        }
        return result;
    }
    public getSingle<T>(id: any, URL: string): Observable<T> {
        return this.http.get<T>(this.apiURL + URL + '/' + id);
    }
    public getAll<T>(URL: string): Observable<T> {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      headers.append("Accept", "text/html; charset=UTF-8");
        return this.http.get<T>(this.apiURL + URL, {
            headers: headers
          });
        
    }
    public postData<T>(data: any, URL: string): Observable<T> {
        return this.http.post<T>(this.apiURL + URL, data);
    }
    public postDataSync<T>(data: any, URL: string) {
        let xhr = new XMLHttpRequest()
        xhr.open("POST",this.apiURL + URL,false);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(JSON.stringify(data));
    }



}
@Injectable({
    providedIn: 'root'
})
export class CustomInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Content-Type')) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return next.handle(req);
    }
}