import { Injectable } from '@angular/core';
import { Lead } from '../model/lead';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { DataService } from './data.service';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class CustNoticeService {
  

  /*Create/Update Lead  Details by Lead Object */
  getCustNoticeText(data: Lead): Observable<Lead> {
    this.url = Controller.Utility + '/' + Action.getCustNotice;
    return this.dataService.postData(data, this.url);

  }
  /*Create/Update Lead  Details by Lead Object */
  postDisclosureFlag(data: Lead): Observable<Lead> {
    this.url = Controller.Utility + '/' + Action.SaveStateDisclosure;
    return this.dataService.postData(data, this.url);

  }
  url: string;
  constructor(private dataService: DataService) { }
}
