//import {map} 'rxjs/add/operator/map';
import { Injectable, InjectionToken } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { Http } from '@angular/http';
//import { Observable } from 'rxjs/Observable';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const WEB_BASE_URL = new InjectionToken<string>('WEB_BASE_URL');

export const iFrameOptInForTextMessages = new InjectionToken<string>('iFrameOptInForTextMessages');
export const iFrameTravelOnline = new InjectionToken<string>('iFrameTravelOnline');

export const captchaSiteKey = new InjectionToken<string>('captchaSiteKey');
export const captchaSiteKeyV3 = new InjectionToken<string>('captchaSiteKeyV3');
export const homeURL = new InjectionToken<string>('homeURL');
export const captchaSecretKeyV3 = new InjectionToken<string>('captchaSecretKeyV3');
export const gAnalyticsKey = new InjectionToken<string>('GAnalyticsKey');
var Data = null;

export function ConfigFactory(configService: ConfigService, file: string, property: string) {

	if (Data == null) {
		configService.loadJSON(file).subscribe((data: any) => {
			if (data) {
				Data = data;
				if (property)
					return Data[property];
			}
		});
		//Data = configService.loadJSON(file);
	} else
		return Data[property];
}

@Injectable()
export class ConfigService {

	public config: any;

	constructor(private http: HttpClientModule, private httpClient: HttpClient) {
	}

	load(url): Promise<any> {

		this.config = null;

		return this.httpClient.get(url)
			//.map((res: Response) => res.json())
			.toPromise()
			.then((data: any) => {
				Data = data;
				this.config = data;
				Promise.resolve();
			})
			.catch((err: any) => Promise.resolve());
	}

	loadJSON(filePath) {
		return this.getJSON(filePath);
		// this.getJSON(filePath).subscribe((data: any) => {
		// 	if (data) {
		// 		console.log(data);
		// 		return data;
		// 	}
		// });
		//return JSON.parse(JSON.stringify(json));

	}

	public getJSON(_jsonURL: string): Observable<any> {
		return this.httpClient.get(_jsonURL);
	}


}

