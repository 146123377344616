import { Component, OnInit,ViewChild,  AfterContentInit, AfterViewInit } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component'; 
declare function breadcrumbShow():any;

@Component({
  selector: 'app-mobile-phone-insurance',
  templateUrl: './mobile-phone-insurance.component.html',
  styleUrls: ['./mobile-phone-insurance.component.css']
})
export class MobilePhoneInsuranceComponent implements OnInit,AfterViewInit {
  @ViewChild(ModelPopUpOtherDomainComponent) child:ModelPopUpOtherDomainComponent;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    breadcrumbShow();
  }

}
