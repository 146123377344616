
<!-- 404  -->


<section class="bg-secondary pt-4">
    <div class="container pt-5 pb-5">

		<div class="row">
			<div class="col text-center">
				<img src="assets/img/icons/laptop.png" alt="error icon"> <br> <br>
			  <h2 class="text-center"> Page not found</h2>
			</div>
		</div>

		  
		<div class="row text-center pt-2 pb-3">
			<div class="col">
			  <img src="assets/img/orange-divider-transparent.png" /> <br><br>
			  <span>We are sorry, the page you requested cannot be found. <br>Please make a selection using our menu above.</span>
			</div>
		</div>
		
    </div>
  </section>


<!-- //  404   -->



