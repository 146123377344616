import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { faChevronRight, faLevelDownAlt, faChevronLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { CommonService } from '../../service/common.service';
declare function breadcrumbShow(): any;
declare var jQuery: any;
declare var $;

@Component({
  selector: 'app-partner-builder',
  templateUrl: './partner-builder.component.html',
  styleUrls: ['./partner-builder.component.css']
})
export class PartnerBuilderComponent implements OnInit, AfterViewInit {
  faChevronRight = faChevronRight;
  faLevelDownAlt = faLevelDownAlt;
  faChevronLeft = faChevronLeft;
  faCaretRight = faCaretRight;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'partner-builder-header.jpg',
    desktopHeaderText: 'Partner with Us',
    desktopHeaderSubText: "Add value to your customer relationships and benefit from fewer delayed closings",
    mobileHeaderText: 'Partner with Us',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  class="btn banner-button" type="button">CONTACT Us</a>',
    desktopHeaderButton: '<a class="btn banner-button" type="button">CONTACT Us</a>',
    topText: 'Builders'
  });
  constructor(
    private elRef: ElementRef,
    public commonService: CommonService

  ) {

  }

  ngOnInit() {

    if (!this.commonService.iMobBroswer()) {
      this.subPageBannerDetails.mobileHeaderButton = '';
    } else {
      this.subPageBannerDetails.desktopHeaderButton = '';
    }
  }
  ngAfterViewInit() {
    breadcrumbShow();
    // assume dynamic HTML was added before
    this.elRef.nativeElement.querySelector('a').addEventListener('click',
      this.requestDetails.bind(this));
    //Run carousel
    $('.carousel').carousel();
  }

  requestDetails() {
    jQuery('#requestDetailsModal').modal('show', { backdrop: 'static', keyboard: false });

  }


}
