import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ModelPopUpOtherDomainComponent } from '../../shared/model-pop-up-other-domain/model-pop-up-other-domain.component';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
declare function breadcrumbShow(): any;
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit, AfterViewInit {

  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'page-headers/event-header.jpg',
    desktopHeaderText: 'Event Insurance',
    desktopHeaderSubText: "Get total protection for your special day. From weddings and large celebrations to corporate events and non-profit functions, more venues are requiring event insurance. We've got you covered.",
    mobileHeaderText: 'Event Insurance',
    mobileHeaderSubText: '',
    mobileHeaderButton: '<a  href="https://www.markelinsurance.com/event/westwood" target="_blank" class=" btn ww-button">Get a Quote</a>',
    desktopHeaderButton: '<a  href="https://www.markelinsurance.com/event/westwood" target="_blank" class=" btn ww-button">Get a Quote</a>'
  });

  constructor() { }

  @ViewChild(ModelPopUpOtherDomainComponent) child: ModelPopUpOtherDomainComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    breadcrumbShow();
  }

}
