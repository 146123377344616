<main role="main">
  <form #personalInfoForm="ngForm" (ngSubmit)="onSubmit(personalInfoForm)" (keydown.enter)="onSubmit(personalInfoForm)">
    <section class="bg-white">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <h2 class="text-primary mb-0">Tell Us About Yourself</h2>
            <p></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3 class="mt-2 mb-3">Applicant Information</h3>
          </div>
        </div>
        <!-- Applicant Name -->
        <div class="row">
          <div class="col-md-4">
            <label class="input-group-label" for="appFirstName">First Name *</label>
            <div class="input-group disabled">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="user"></fa-icon>
                  </i>
                </span>
              </div>
              <input autofocus type="text" class="form-control form-control-lg" id="appFirstName" name="appFirstName"
                     maxLength="17" required>
              <div class="invalid-feedback" style="display: block;">
                <div>Please provide a valid First Name.</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="appMiddleName">Middle Name</label>
              <input type="text" class="form-control form-control-lg" id="appMiddleName" name="appMiddleName"
                     maxLength="20">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="appLastName">Last Name *</label>
              <input type="text" class="form-control form-control-lg" id="appLastName" name="appLastName"
                     maxLength="20" required aria-describedby="appLastName">
              <div class="invalid-feedback" style="display: block;">
                <div>Please provide a valid Last Name.</div>
              </div>
            </div>
          </div>
        </div>

        <!-- APPLICANT MOBILE PHONE -->
        <div class="row row-field-group">
          <div class="col-md-5 col-lg-4">

            <label class="input-group-label" for="applicantPrimaryPhone">Primary Phone Number *</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="phone"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg" id="applicantPrimaryPhone"
                     name="applicantPrimaryPhone" aria-describedby="applicantPrimaryPhone"
                     placeholder="(999)999-9999" minlength="10"
                     mask="(000)000-0000"
                     required>
              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid primary phone.
                </diV>
                <div>
                  Phone should not be same.
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="applicantPphoneType">Primary Phone Type *</label>
              <select class="form-control form-control-lg" id="applicantPphoneType" name="applicantPphoneType"
                      required>
                <option selected="selected" [ngValue]="''"></option>
                <option [ngValue]="'Cellular'">Cell</option>
                <option [ngValue]="'Home'">Home</option>
                <option [ngValue]="'Work'">Work</option>

              </select>
              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid Primary Phone Type.
                </diV>
                <div>
                  Phone type should not be same.
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-3 col-lg-4">
            <div class="form-group mb-md-0 mt-md-4">
              <div class="custom-control custom-switch pt-md-1 pt-lg-3">
                <input type="checkbox" class="custom-control-input" id="applicant-sms" name="applicant-sms"
                       aria-describedby="applicant-sms"
                       (change)="textOptInChange($event)" #appPrimaryOptIn>
                <label class="custom-control-label" for="applicant-sms">Opt-in for text messages</label>
              </div>
            </div>
          </div>
        </div>

        <!-- APPLICANT Secondary PHONE -->
        <div class="row">
          <div class="col-md-5 col-lg-4">
            <label class="input-group-label" for="applicantSecondaryPhone">Secondary Phone Number</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="d-md-none">
                    <fa-icon icon="phone-square"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg" id="applicantSecondaryPhone"
                     name="applicantSecondaryPhone" aria-describedby="applicantSecondaryPhone" placeholder="(999)999-9999"
                     minlength="10" mask="(000)000-0000">
              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid Secondary Phone.
                </diV>
                <div>
                  Phone should not be same.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="applicantSecPhoneType">Secondary Phone Type</label>
              <select class="form-control form-control-lg" id="applicantSecPhoneType" name="applicantSecPhoneType" validatePhoneTypes>
                <option selected="selected" [ngValue]="''"></option>
                <option [ngValue]="'Cellular'">Cell</option>
                <option [ngValue]="'Home'">Home</option>
                <option [ngValue]="'Work'">Work</option>
              </select>


              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid Secondary Phone type.
                </diV>
                <div>
                  Phone type should not be same.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-lg-4">
            <div class="form-group mb-md-0 mt-md-4">
              <div class="custom-control custom-switch pt-md-1 pt-lg-3">
                <input type="checkbox" class="custom-control-input" id="1applicantSecondaryOptIn"
                       name="1applicantSecondaryOptIn" aria-describedby="applicantSecondaryOptIn"
                       #appSecondaryOptIn>
                <label class="custom-control-label" for="1applicantSecondaryOptIn">
                  Opt-in for text
                  messages
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label class="input-group-label" for="applicant-email">Email *</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="envelope"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="email" class="form-control form-control-lg" id="applicantEmail" name="applicantEmail"
                     aria-describedby="applicantEmail" required>
              <div class="invalid-feedback" style="display: block;">
                <div>
                  Please provide a valid Email.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- APPLICANT DOB -->
        <div class="row">
          <div class="col-md-7 col-lg-5">
            <label class="input-group-label" for="applicantDOB">Date of Birth</label>
            <label>&nbsp;*</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="calendar-check"></fa-icon>
                  </i>
                </span>
              </div>
              <div class="date-format-custom">
                <div class="input-group" style="margin-bottom: 0px;">
                  <input class="form-control form-control-lg" placeholder="mm/dd/yyyy" name="applicantDOB">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" type="button"></button>
                  </div>

                  <div class="input-group-append">
                    <button class="btn btn-link" type="button" aria-label="Learn About Water Sensor" container="body">
                      <i class="fas"><fa-icon icon="question-circle"></fa-icon></i>
                    </button>
                  </div>
                  <ng-template #solarpanelTitle>Date of Birth:</ng-template>
                  <!-- End Help tool tip -->
                </div>

              </div>
              <div class="invalid-feedback" style="display: block;">
                Please provide a valid Date of Birth.
              </div>
            </div>

          </div>
        </div>

        <!-- APPLICANT OCCUPATION -->
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <label class="input-group-label" for="applicant-occupation">Occupation</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="building"></fa-icon>
                  </i>
                </span>
              </div>
              <select class="form-control form-control-lg" id="applicant-occupation" name="applicant-occupation">
                <option selected="selected"></option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 d-flex align-items-center">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="applicant-self" name="applicant-self">
              <label class="custom-control-label" for="applicant-self">I am self-employed</label>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mt-2 mb-3">Co-Applicant Information</h3>
          </div>
        </div>
        <!-- Co-Applicant Name -->
        <div class="row">
          <div class="col-md-4">
            <label class="input-group-label" for="coapplicant-fname">Co-Applicant First Name</label>
            <div class="input-group disabled">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="user"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg" id="coapplicant-fname" aria-describedby="applicant-fname" name="coapplicantFname"
                     maxlength="17">
            </div>

          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="coapplicant-mname">Co-Applicant Middle Name</label>
              <input type="text" class="form-control form-control-lg" id="coapplicant-mname" name="coapplicant-mname"
                     maxlength="20">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="coapplicant-lname">Co-Applicant Last Name</label>
              <input type="text" class="form-control form-control-lg" id="coapplicant-lname" name="coapplicant-lname"
                     maxlength="20"
                     >

            </div>
          </div>
        </div>

        <!-- Co-Applicant MOBILE PHONE -->
        <div class="row row-field-group">
          <div class="col-md-5 col-lg-4">

            <label class="input-group-label" for="coApplicantPrimaryPhone">Primary Phone Number</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="phone"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg" id="coApplicantPrimaryPhone"
                     name="coApplicantPrimaryPhone" aria-describedby="coApplicantPrimaryPhone" placeholder="(999)999-9999"
                     mask="(000)000-0000"
                     >

              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid co-applicant’s Primary
                  Phone.
                </diV>
                <div>
                  Phone should not be same.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="coapplicantPrimPhoneType">Primary Phone Type</label>
              <select class="form-control form-control-lg" id="coapplicantPrimPhoneType" name="coapplicantPrimPhoneType">
                <option selected="selected" [ngValue]="''"></option>
                <option [ngValue]="'coapp cellular'">Cell</option>
                <option [ngValue]="'coapp Home'">Home</option>
                <option [ngValue]="'CoApp Work'">Work</option>

              </select>
              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid Co-Applicant's Primary Phone Type.
                </diV>
                <div >
                  Phone type should not be same.
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-3 col-lg-4">
            <div class="form-group mb-md-0 mt-md-4">
              <div class="custom-control custom-switch pt-md-1 pt-lg-3">
                <input type="checkbox" class="custom-control-input" id="2coapplicant-sms" name="2coapplicant-sms"
                       aria-describedby="coapplicant-sms" #coAppPrimaryOptIn>
                <label class="custom-control-label" for="2coapplicant-sms">
                  Opt-in for text
                  messages
                </label>
              </div>

            </div>
          </div>
        </div>

        <!-- Co-Applicant Secondary PHONE -->
        <div class="row">
          <div class="col-md-5 col-lg-4">

            <label class="input-group-label" for="coapplicantSecondaryPhone">Secondary Phone Number</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="d-md-none">
                    <fa-icon icon="phone-square"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="text" class="form-control form-control-lg" id="coapplicantSecondaryPhone"
                     name="coapplicantSecondaryPhone" aria-describedby="coapplicantSecondaryPhone"
                     placeholder="(999)999-9999" mask="(000)000-0000">
              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid Co-Applicant's Secondary Phone.
                </diV>
                <div>
                  Phone should not be same.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="coapplicantSecPhoneType">Secondary Phone Type</label>
              <select class="form-control form-control-lg" id="coapplicantSecPhoneType" name="coapplicantSecPhoneType">
                <option selected="selected" [ngValue]="''"></option>
                <option [ngValue]="'coapp cellular'">Cell</option>
                <option [ngValue]="'coapp Home'">Home</option>
                <option [ngValue]="'CoApp Work'">Work</option>

              </select>
              <div class="invalid-feedback" style="display: block;">
                <diV>
                  Please provide a valid Co-Applicant's Secondary Phone Type.
                </diV>
                <div>
                  Phone type should not be same.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-lg-4">
            <div class="form-group mb-md-0 mt-md-4">
              <div class="custom-control custom-switch pt-md-1 pt-lg-3">
                <input type="checkbox" class="custom-control-input" id="12coapplicantSecondaryOptIn"
                       name="12coapplicantSecondaryOptIn" aria-describedby="coapplicantSecondaryOptIn">
                <label class="custom-control-label" for="12coapplicantSecondaryOptIn">
                  Opt-in for text messages
                </label>
              </div>

            </div>
          </div>
        </div>

        <!-- Co-Applicant EMAIL -->
        <div class="row">
          <div class="col-md-6">
            <label class="input-group-label" for="coapplicantEmail">Email </label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="envelope"></fa-icon>
                  </i>
                </span>
              </div>
              <input type="email" class="form-control form-control-lg" id="coapplicantEmail" name="coapplicantEmail"
                     aria-describedby="coapplicantEmail" maxlength="40">
              <div class="invalid-feedback" style="display: block;">
                <div>
                  Please provide a valid Co-Applicant's Email.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Co-Applicant DOB -->
        <div class="row">
          <div class="col-md-7 col-lg-5">
            <label class="input-group-label" for="coapplicantDOB">Date of Birth </label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="calendar-check"></fa-icon>
                  </i>
                </span>
              </div>
              <div>
                <div class="input-group" style="margin-bottom: 0px;">
                  <input type="text" class="form-control form-control-lg" placeholder="mm/dd/yyyy" id="coapplicantDOB"
                         name="coapplicantDOB">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" type="button"></button>
                  </div>
                </div>

              </div>
              <div class="invalid-feedback" style="display: block;">
                Please provide a Co-Applicant's valid Date of Birth.
              </div>
            </div>

          </div>
        </div>

        <!-- Co-Applicant OCCUPATION -->
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <label class="input-group-label" for="coapplicant-occupation">Occupation</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas">
                    <fa-icon icon="building"></fa-icon>
                  </i>
                </span>
              </div>
              <select class="form-control form-control-lg" id="coapplicant-occupation" name="coapplicant-occupation">
                <option selected="selected" value=""></option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 d-flex align-items-center">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="coapplicant-self" name="coapplicant-self" />
              <label class="custom-control-label" for="coapplicant-self">I am self-employed</label>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mt-2 mb-3">Property Address</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <div class="form-group">
              <label for="property-addr">Street Address *</label>
              <input type="search" class="form-control form-control-lg" id="property-addr" name="property-addr" required>
              <div class="invalid-feedback" style="display: block;">
                Please provide A valid Street Address.
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label for="property-addr2">Apartment/Unit</label>
              <input type="text" class="form-control form-control-lg" id="property-addr2" name="property-addr2"
                     #popertyAdrress2 maxlength="36">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-5">
            <div class="form-group">
              <label for="property-city">City *</label>
              <input type="text" class="form-control form-control-lg"
                     id="property-city" name="property-city" #popertyCity maxlength="23" required>
              <div class="invalid-feedback" style="display: block;">
                Please provide A valid City.
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div class="form-group">
              <label for="property-st">State *</label>
              <select class="form-control form-control-lg"
                      id="property-st" name="property-st" required #popertyState>
                <option selected="selected" value=""></option>
              </select>
              <div class="invalid-feedback" style="display: block;">
                Please provide A valid State.
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="form-group">

              <label for="property-zip">Zip Code *</label>
              <input type="text" class="form-control form-control-lg"
                     name="property-zip" id="property-Zip" minlength="5" maxlength="10" #popertyZip ZipcodeValidator>
              <div class="invalid-feedback" style="display: block;">
                Please provide a valid Zip Code.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mt-2 mb-3">Current Mailing Address</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <div class="form-group">
              <div class="custom-control custom-switch pt-md-1 pt-lg-3">
                <input type="checkbox" class="custom-control-input" id="propertyAddressSameAsMailing">
                <label class="custom-control-label" for="propertyAddressSameAsMailing">
                  Is your current mailing address
                  the same as the property address?
                </label>

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 col-lg-7">
            <div class="form-group">
              <label for="mailing-addr">Street Address </label>
              <input type="search" class="form-control form-control-lg" id="mailing-addr" name="mailing-addr"
                     autocomplete="search" #mailingAddress1>
              <div class="invalid-feedback" style="display: block;">
                Please provide A valid Mailing Street Address.
              </div>

            </div>
          </div>
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label for="mailing-addr2">Apartment/Unit</label>
              <input type="text" class="form-control form-control-lg" id="mailing-addr2" name="mailing-addr2"
                     #mailingAddress2 maxlength="36">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-5">
            <div class="form-group">
              <label for="mailing-city">City </label>
              <input type="text" class="form-control form-control-lg" id="mailing-city" name="mailing-city" #mailingCity>
              <div class="invalid-feedback" style="display: block;">
                Please provide A valid Mailing City.
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div class="form-group">
              <label for="mailing-st">State </label>
              <select class="form-control form-control-lg" id="mailing-st" name="mailing-st" #mailingState>
                <option selected="selected" value=""></option>
              </select>
              <div class="invalid-feedback" style="display: block;">
                Please provide A valid Mailing State.
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3">
            <div class="form-group">
              <label for="mailing-zip">Zip Code </label>
              <input type="text" class="form-control form-control-lg" name="mailing-zip" id="mailing-zip" #mailingZip>

              <div class="invalid-feedback" style="display: block;">
                Please
                provide a valid Zip Code.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Desktop/Tablet Next/Back -->
    <section class="bg-light d-none d-sm-block">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <!-- Left button -->
              <div class="quote-back">
                <button type="button" class="btn btn-light btn-lg" (click)="onClickBack()" tabindex="-1">
                  <i>
                    <fa-icon icon="chevron-left"></fa-icon>
                  </i>
                  Back
                </button>
              </div>
              <!-- Centered button Desktop/PC/LAPTOP -->
              <div class="quote-next">
                <button type="submit" class="btn btn-cta btn-lg btn-min-max text-white"
                        [awGoToStep]="{stepIndex: 2}">
                  Next
                  <i>
                    <fa-icon icon="chevron-right"></fa-icon>
                  </i>
                </button>
              </div>
              <!-- Blank Div to Flex Center items correctly. -->
              <div class="btn-flex-align-helper"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Mobile Next/Back-->
    <section class="mobile-stepper fixed-bottom d-sm-none">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex align-items-center">
              <!-- Left button -->
              <div class="quote-back">
                <button type="button" class="btn btn-light" (click)="onClickBack()" tabindex="-1">
                  <i>
                    <fa-icon icon="chevron-left"></fa-icon>
                  </i>
                  Back
                </button>
              </div>

              <div class="quote-dots flex-fill">
                <div class="dots d-flex flex-row justify-content-center">
                  <div class="dot active"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>

              <!-- Right button -->
              <div class="quote-next">
                <button type="submit" class="btn btn-cta text-white m-0"
                        [awGoToStep]="{stepIndex: 2}">
                  Next <i>
                    <fa-icon icon="chevron-right"></fa-icon>
                  </i>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</main>
