import { Component, OnInit, Inject } from '@angular/core';
import { iFrameOptInForTextMessages } from '../../service/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SubPageBannerDetails } from '../../model/sub-page-banner-details';
import { DataService } from 'src/app/service/data.service';
declare function breadcrumbShow(wpBaseUrl: string): any;

@Component({
  selector: 'app-opt-in-for-text-messages',
  templateUrl: './opt-in-for-text-messages.component.html',
  styleUrls: ['./opt-in-for-text-messages.component.css']
})
export class OptInForTextMessagesComponent implements OnInit {
  iframeUrl: any;
  srcUrl: any;
  public wpBaseUrl: string;
  subPageBannerDetails: SubPageBannerDetails = Object.assign(new SubPageBannerDetails(), {
    bannerImage: 'text_optin_header.jpg',
    desktopHeaderText: 'Opt in for Text Messages',
    desktopHeaderSubText: "If you like getting your info via text, this is for you!",
    mobileHeaderText: 'Opt in for Text Messages',
    mobileHeaderSubText: ''
  });
  constructor(
    private sanitizer: DomSanitizer, private dataService: DataService,
    @Inject(iFrameOptInForTextMessages) public iFrameOptInForTextMessagesUrl?: string

  ) {
    this.iframeUrl = this.iframeUrlLoad(iFrameOptInForTextMessagesUrl);
    this.wpBaseUrl = this.dataService.wpWebsiteUrl;
  }

  iframeUrlLoad(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    breadcrumbShow(this.wpBaseUrl);
  }
}
