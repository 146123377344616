import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Lead } from '../model/lead';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class ContactMeService {
  url: string;
  constructor(private dataService: DataService) { }
  /*Create/Update Lead  Details by Lead Object */
  saveContactMe(data: Lead): Observable<Lead> {
    this.url = Controller.contactMe + '/' + Action.saveContactMe;
    return this.dataService.postData(data, this.url);
  }
}
