<app-sub-page-banner [subPageBannerDetails]="this.subPageBannerDetails"></app-sub-page-banner>

<!-- B R E A D C R U M B  -->

<section class="container pt-3">
    <nav id="breadcrumb">
        <ol class="breadcrumb">
            <li><a routerLink="/" class="bc-home">Home</a>&nbsp;&nbsp;» </li>
        </ol>
    </nav>
</section>

<!-- // B R E A D C R U M B  -->

<div class="container-lg">

    <div class="row mt-3">
        <div class="col-12">
            <section id="customer-test" class="mb-2 pb-4">
                <div class="container text-center">
                    <div class="row justify-content-center">
                        <div class="col-9">
                            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel"
                                data-interval="3750">
                                <div class="carousel-inner">

                                    <div class="carousel-item active">
                                        <h3 class="text-center text-transform-none h5 font-weight-bold">Close on
                                            time,<br class="d-sm-none">
                                            every time</h3>
                                    </div>

                                    <!-- <div class="carousel-item ">
                                        <h3 class="text-center text-transform-none h5 font-weight-bold">Close on time</h3>
                                    </div> -->
                                    <div class="carousel-item">
                                        <h3 class="text-center text-transform-none h5 font-weight-bold">Improve
                                            customer satisfaction</h3>
                                    </div>
                                    <div class="carousel-item">
                                        <h3 class="text-center text-transform-none h5 font-weight-bold">Streamline
                                            the<br class="d-sm-none">
                                            closing process
                                        </h3>
                                    </div>
                                    <div class="carousel-item">
                                        <h3 class="text-center text-transform-none h5 font-weight-bold">Create a new<br
                                                class="d-sm-none">
                                            revenue stream</h3>
                                    </div>
                                </div>
                                <a class="carousel-control-prev carousel-control-prev-cust"
                                    href="#carouselExampleControls" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" style="font-size: 1.5rem;"
                                        aria-hidden="true">

                                        <fa-icon [icon]="faChevronLeft" class="text-orange"></fa-icon>
                                    </span>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next carousel-control-next-cust"
                                    href="#carouselExampleControls" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon" style="font-size: 1.5rem;"
                                        aria-hidden="true">
                                        <fa-icon [icon]="faChevronRight" class="text-orange"></fa-icon>

                                    </span>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>


<section class="angle-bg">
    <div class="container-lg">
        <div class="row mt-sm-3 mx-md-4 py-2">
            <div class="col-12 col-md-4">
                <h2 class="mb-3 mt-sm-4 text-transform-none">Easy for you</h2>
                <p class=" mb-2 custom-font-size"><span class="">Quick</span> implementation</p>
                <ul class="fa-ul ml-4 custom-font-size">
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-blue"></fa-icon>
                        </span>Connected in <span class="text-size-inherit text-westwood-blue ">less
                            than a week</span>
                    </li>
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-blue"></fa-icon>
                        </span>Several <a routerLink='/partners/api' style="text-decoration: underline;"><span
                                class="text-size-inherit text-westwood-blue ">integration
                                options</span></a>
                    </li>
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-blue"></fa-icon>
                        </span><span class="text-size-inherit text-westwood-blue ">Proven
                            model</span> for builders large, small, and everywhere in between</li>
                </ul>
            </div>
            <div class="col-12 col-md-4 align-self-center">
                <img src="assets/img/builder-partnership.jpg" class="img-fluid" alt="Responsive image">
            </div>
            <div class="col-12 col-md-4">
                <h2 class="mt-4 mb-3 text-westwood-orange text-transform-none">Easy for your customers</h2>
                <p class=" mb-2 custom-font-size"><span class=" text-westwood-orange">Seamless</span> experience
                    integrated into
                    the closing process</p>
                <ul class="fa-ul ml-4 custom-font-size">
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-orange"></fa-icon>
                        </span>Fully digital process that takes <span
                            class="text-size-inherit text-westwood-orange ">minutes,
                            not hours</span>
                    </li>
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-orange"></fa-icon>
                        </span>Customized quotes from leading insurers for <span
                            class="text-size-inherit text-westwood-orange ">maximum
                            savings</span>
                    </li>
                    <li class=""><span class=" fa-li">
                            <fa-icon [icon]="faCaretRight" class="text-westwood-orange"></fa-icon>
                        </span><span class="text-size-inherit text-westwood-orange ">Evidence of
                            insurance</span> sent directly to the lender</li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="container-lg">
    <div class="row mt-0 pt-4">
        <div class="col-12 text-center">
            <h2 class="mt-4 text-transform-none">Benefit from Westwood's experience and national footprint</h2>
        </div>
    </div>
    <div class="row text-center">
        <div class="col">
            <img src="assets/img/orange-divider-transparent.png" alt="">
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12 text-center">
            <h3 class="h5 font-weight-bold">A top insurance agency licensed in all 50 states</h3>
        </div>
    </div>

    <div class="row my-1 mx-auto my-4" >
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold "> Partners with over
            </div>
            <div class="partner-highlight works-with">
                40
            </div>
            <div class="partner-suffix font-weight-bold">
               insurance companies
            </div>
        </div>
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold">
                Works with
            </div>
            <div class="partner-highlight partners-with">
                <!-- Important for spacing, the space should be inside the span, not next to the numbers -->
                7<span style="font-size: 65%;"> of the <br>top </span>10
            </div>
            <div class="partner-suffix1 font-weight-bold">
                home builders
            </div>
        </div>
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix1 font-weight-bold">
                Helps close over
            </div>
            <div  class="partner-highlight help-close" >
                100K
            </div>
            <div class="partner-suffix1 font-weight-bold">
                homes each year
            </div>
        </div>
        <div class="col-6 col-md-3 p-3 p-md-4 partner-callout mt-md-4 text-center">
            <div class="partner-prefix font-weight-bold pt-3">
                Complies with
            </div>
            <div class="partner-highlight respa">
                RESPA
            </div>
            <div class="partner-suffix font-weight-bold">

            </div>
        </div>
    </div>
</div>


<div class="container-fluid bg-light">

    <section class="py-5 top-border-element">
        <div class="row text-center pt-2 pb-3">
            <div class="col"><img alt="orange line decor" src="assets/img/orange-divider-transparent.png"></div>
        </div>

        <div class="row">
            <div class="col">
                <h2 class="text-center text-transform-none">Insurance Company Partners</h2>
            </div>
        </div>
        <!-- C U S T O M E R   T E S T I M O N I A L S  -->

        <section id="customer-test" class="pt-3 pb-3">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col">
                        <div id="carouselExampleControls1" class="carousel slide" data-ride="carousel"
                            data-interval="3750">
                            <div class="carousel-inner">
                                <!-- First slide -->
                                <div class="carousel-item active">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/AIIC.jpg"
                                                        alt="American Integrity Insurance Group" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/AMIG.jpg" alt="American Modern" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/CHUBB.jpg" alt="Chubb" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/FirstAmerican_PropAndCas.png"
                                                        alt="First American Propety & Casualty Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/ForemostIG.jpg"
                                                        alt="Foremost Insurance Group" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- Second slide 
                                 
                                -->
                                <div class="carousel-item ">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/GeoVeraI.jpg" alt="GeoVera Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/homeowners-oa.png"
                                                        alt="Homeowners of American Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/MI-Authorized-Agent-Logo-Vertical-PRINT.jpg"
                                                        alt="Mercury Insurance Authorized Agent" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/NatGenPremier.jpg"
                                                        alt="NatGen Premier" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Progressive_Logo.png"
                                                        alt="Progressive Logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 3rd slide -->

                                <div class="carousel-item">
                                    <div class="row justify-content-center text-center logo-rows">

                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/QBE.png" alt="QBE" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/rli-logo.png"
                                                        alt="RLI Different Works" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/SafecoLMAC.png" alt="Safeco Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Selective-Insurance-RGB.png"
                                                        alt="Selective Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Stillwater_InsGrp_LOGO_vrt_Bld_clr.jpg"
                                                        alt="Stillwater Insurance Group" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- 4th slide -->
                                <div class="carousel-item">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Travelers-logo.gif"
                                                        alt="Travelers Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/TowerHillIns.jpg"
                                                        alt="Tower Hill Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/una-logo.png"
                                                        alt="Universal North America" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/State-Auto.png"
                                                        alt="State Auto Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/AH_GI_logo_hor_grn.jpg"
                                                        alt="Arrowhead Insurance" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- 5th Slide -->
                                <div class="carousel-item">
                                    <div class="row justify-content-center text-center logo-rows">
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/clearcover_wordmark_rgb_logo.png"
                                                        alt="Clearcover Insurance" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-4">
                                            <div class="natl-logo">
                                                <div class="natl-logo-item">
                                                    <img src="assets/img/logos/Wright-flood.jpg"
                                                        alt="Wright Flood insurance" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev partner-landing-prev" href="#carouselExampleControls1"
                                role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true">
                                    <img src="assets/img/arrow-lft.png" alt="orange left arrow decor"
                                        id="left-arrow-image">
                                </span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next partner-landing-next" href="#carouselExampleControls1"
                                role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true">
                                    <img src="assets/img/arrow-rght.png" id="right-arrow-image"
                                        alt="orange left arrow decor">
                                </span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- // C U S T O M E R   T E S T I M O N I A L S  -->
    </section>
</div>
<!-- Pop up to request details -->
<app-pop-uprequest-details></app-pop-uprequest-details>