import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { DataService } from './data.service';
import { Tracking } from '../model/tracking';

 /** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router : Router,
    public dataService: DataService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if(this.dataService.leadDetails!=null && this.dataService.leadDetails !=undefined)
        {
          if(this.dataService.leadDetails.trackingInfo==undefined || this.dataService.leadDetails.trackingInfo ==null)
          {
          this.dataService.leadDetails.trackingInfo=new Tracking();
          }
          this.dataService.leadDetails.trackingInfo.currentPageName=this.currentUrl;
          this.dataService.leadDetails.trackingInfo.previousPageName= this.previousUrl;
        }
      
      };
    });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }    
}
