
<!--  P R I V A C Y  P O L I C Y  TOP  -->

<section>
    <div class="container pt-5 pb-5">

        <div class="row">
            <div class="col">
                <h2 class="col">California Consumer Privacy Act</h2>       
                <img class="pl-3" src="assets/img/orange-divider-transparent.png" />
            </div>
        </div>
        
        <div class="row pb-4 text-right">
            <div class="col-12">

                <a routerLink="/ssn">SSN Privacy Policy </a> <br>
                <a routerLink="/privacy-policy">Privacy Policy</a><br>
      
            </div>
        </div>

        <div class="row pl-3">
            <div class="col">
                <span>
                    Westwood Insurance Agency Privacy Notice for California Residents <br>
                    Effective Date: 1/1/2020 <br>
                </span>
            </div>
        </div>

    </div>
</section>

<!-- //  P R I V A C Y  P O L I C Y  TOP  -->


<!--  P R I V A C Y  P O L I C Y  MAIN  -->

<section>
<div class="container pb-5">

    <div class="row pl-3">
        <div class="col-md-8 col-xs-9 pt-2">
     
                <p>This <strong>Privacy Notice for California Residents</strong> supplements the information contained in the Privacy Statement of Westwood Insurance Agency located at <a routerLink="/privacy-policy">Westwoodinsurance.com/Privacy-Policy</a>, and applies solely to all visitors, users, and others who reside in the State of California (“consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (the “CCPA”) and any defined terms in the CCPA have the same meaning when used in this notice.</p>
                <br>
                <p>
                    <strong>Note: The majority (if not all) of the information collected about you by Westwood Insurance Agency will fall into the exclusion for sector-specific privacy laws (specifically GLBA and HIPAA). In such cases, the general Privacy Notice found at <a routerLink="/privacy-policy">Westwoodinsurance.com/Privacy-Policy</a>, applies. 
                    <br><br>To the extent Westwood Insurance Agency collects information about you which is not subject to exception or exclusion under the CCPA, the following Privacy Notice applies. 
                    </strong>
                </p>

                <p>
                    Details of the information we collect, our use of the information collected, how we share information, and your rights and choices are provided below.  If you would like to exercise the access, data portability, and deletion rights described herein, please submit a verifiable consumer request to us by either:</p>
                    <ul>
                        <li>Calling us at <strong><a href="tel:18667847775" class="telNum"> 866.784.7775</a></strong></li>
                        <li>Filling out our CCPA Request Webform located at <a href="https://ccpa.qbena.com/ccpa/" target="_blank">ccpa.qbena.com/ccpa</a></li>
                    </ul>
                 <br>

                
            <span>Information We Collect</span><br><br> 
                <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (“<strong>personal information</strong>”). In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:</p>
        </div>
    </div>

    <div class="row pl-3">
        <div class="col-md-8 col-xs-9 pb-2 pl-0 accordion" id="accordionExample">

            <div class="card pl-0">
                <div class="card-header p-0" id="headingOne">
                    <h2 class="mb-0">
                        <button aria-controls="collapseOne" aria-expanded="true" class="btn btn-link" data-target="#collapseOne" data-toggle="collapse" type="button">
                            A.	Identifiers
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="headingOne" class="collapse" data-parent="#accordionExample" id="collapseOne">
                    <div class="card-body">
                        A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver’s license number, passport number or other similar identifiers<br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading2">
                    <h2 class="mb-0">
                        <button aria-controls="collapse2" aria-expanded="true" class="btn btn-link" data-target="#collapse2" data-toggle="collapse" type="button">
                            B.	Personal information categories listed in the California Customer Records statute (Cal. Civ. Code 1798.80(e))
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading2" class="collapse" data-parent="#accordionExample" id="collapse2">
                    <div class="card-body">
                        A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, drivers license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, or health insurance information. Some personal information included in this category may overlap with other categories.<br><br>
                        Collected: Yes 
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading3">
                    <h2 class="mb-0">
                        <button aria-controls="collapse3" aria-expanded="true" class="btn btn-link" data-target="#collapse3" data-toggle="collapse" type="button">
                            C.	Protected classification characteristics under California or Federal law
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading3" class="collapse" data-parent="#accordionExample" id="collapse3">
                    <div class="card-body">
                        Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information) <br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading4">
                    <h2 class="mb-0">
                        <button aria-controls="collapse4" aria-expanded="true" class="btn btn-link" data-target="#collapse4" data-toggle="collapse" type="button">
                            D.	Commercial Information
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading4" class="collapse" data-parent="#accordionExample" id="collapse4">
                    <div class="card-body">
                        Records of personal property, products or services purchased, obtained, or considered or the purchasing or consuming histories or tendencies<br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            
            <div class="card pl-0">
                <div class="card-header p-0" id="heading5">
                    <h2 class="mb-0">
                        <button aria-controls="collapse5" aria-expanded="true" class="btn btn-link" data-target="#collapse5" data-toggle="collapse" type="button">
                            E.	Biometric Information
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading5" class="collapse" data-parent="#accordionExample" id="collapse5">
                    <div class="card-body">
                        Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data<br><br>
                        Collected: No
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading5">
                    <h2 class="mb-0">
                        <button aria-controls="collapse6" aria-expanded="true" class="btn btn-link" data-target="#collapse6" data-toggle="collapse" type="button">
                            F.	Internet or other similar network activity
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading6" class="collapse" data-parent="#accordionExample" id="collapse6">
                    <div class="card-body">
                        Browsing history, search history, information on a consumer’s interaction with website, application or advertisement<bR><br>
                            Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading7">
                    <h2 class="mb-0">
                        <button aria-controls="collapse7" aria-expanded="true" class="btn btn-link" data-target="#collapse7" data-toggle="collapse" type="button">
                            G.	Geolocation data
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading7" class="collapse" data-parent="#accordionExample" id="collapse7">
                    <div class="card-body">
                        Physical location or movements <br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading8">
                    <h2 class="mb-0">
                        <button aria-controls="collapse8" aria-expanded="true" class="btn btn-link" data-target="#collapse8" data-toggle="collapse" type="button">
                            H.	Sensory Information
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading8" class="collapse" data-parent="#accordionExample" id="collapse8">
                    <div class="card-body">
                        Audio, electronic, visual, thermal, olfactory or similar information <br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading9">
                    <h2 class="mb-0">
                        <button aria-controls="collapse9" aria-expanded="true" class="btn btn-link" data-target="#collapse9" data-toggle="collapse" type="button">
                            I.	Professional or employment related information
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading9" class="collapse" data-parent="#accordionExample" id="collapse9">
                    <div class="card-body">
                        Current or past job history or performance evaluations <br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading10">
                    <h2 class="mb-0">
                        <button aria-controls="collapse10" aria-expanded="true" class="btn btn-link" data-target="#collapse10" data-toggle="collapse" type="button">
                            J.	Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g. 34 C.F.R. Part 99))
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading10" class="collapse" data-parent="#accordionExample" id="collapse10">
                    <div class="card-body">
                        Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records<br><br>
                        Collected: Yes
                    </div>
                </div>
            </div>

            <div class="card pl-0">
                <div class="card-header p-0" id="heading11">
                    <h2 class="mb-0">
                        <button aria-controls="collapse11" aria-expanded="true" class="btn btn-link" data-target="#collapse11" data-toggle="collapse" type="button">
                            K.	Inferences drawn from other personal information
                        </button>
                    </h2>
                </div>
                <div aria-labelledby="heading11" class="collapse" data-parent="#accordionExample" id="collapse11">
                    <div class="card-body">
                        Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes <br><br>
                        Collected: No
                    </div>
                </div>
            </div>

        </div>
    </div>  

</div>
</section>

<!--  // P R I V A C Y  P O L I C Y  MAIN  -->

<!--  P R I V A C Y  P O L I C Y  continued -->

<section>
<div class="container pb-5">

    <div class="row pl-3">
        <div class="col col-md-8 col-xs-9 ">
            <span>Personal Information does not include:</span><br><br>
               <ul>
                   <li>Publicly available information from government records.</li>
                   <li>Deidentified or aggregated consumer information.</li>
                   <li>Information excluded from the CCPA’s scope, like: 
                       <ul>
                           <li>Health or medical information covered by the Health Insurance Portability Act of 1996 (“HIPAA”) and the California Confidential of Medical Information Act (“CMIA”) or clinical trial data.</li>
                            <li>Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (“FCRA”) the Gramm-Leach-Bliley Act (“GLBA”) or the California Financial Information Privacy Act (“FIPA”) and the Drivers Privacy Protection Act of 1994.</li>
                        </ul>
                   </li>
               </ul>
               <br>
               <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
                    <ul>
                        <li>Directly from our customers or their agents. For example, from the documents that our customers provide to use related to the products and or services for which they have engaged us.</li>
                        <li>Indirectly from our customers or their agents. For example, through information we collect from our customers in the course of providing products and or services to them.</li>
                        <li>Directly and indirectly from activity on our websites. For example, from submission through our website portal or website usage details collected automatically.</li>
                        <li>From our Service Providers and third parties that interact with us in connection with the products and services we provide. For example, a third-party administrator that provides claims services to customers on our behalf.</li>
                    </ul>
               

            <span>Use of Personal Information</span> <bR><br>
            <p>We may use or disclose the personal information we collect for one or more of the following business purposes:</p>
            <ul>
                <li>To fulfill or meet the reason for which the information was provided.</li>
                <li>	To provide you with information, products or services that you request from us.</li>
                <li>	To provide you with email alerts and other notices concerning our products or services.</li>
                <li>	To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collections.</li>
                <li>	To improve our website and present its contents to you.</li>
                <li>	For testing, research, analysis, and product development.</li>
                <li>	As necessary or appropriate to protect the rights, property or safety of us, our customers and others.</li>
                <li>	To respond to law enforcement requests as required by applicable law, court order or governmental regulations.</li>
                <li>	As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
                <li>	To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all our assets, whether as a going concern or a part of a bankruptcy, liquidation, or similar proceeding, in which personal information held by us is among the assets transferred.</li>
            </ul>
            <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
            <br>

            <span>Sharing Personal Information</span> <br><br>
            <p>We may disclose your personal information to a third-party for a business purpose. When we disclose
                personal information for a business purpose, we enter into a contract that describes the purposes and
                require the recipient to both keep that personal information confidential and not use it for any purpose
                except performing the contract.<br><br>
                In the preceding (12) months, we have disclosed the following categories of personal information for a business purpose:
            </p>
            <p>
                Category A:	Identifiers<br>
                Category B:	Personal information categories listed in the California Customer Records statute (Cal. Civ. Code 1798.80(e))<bR>
                Category C:	Protected classification characteristics under California or Federal law<br>
                Category D:	Commercial Information<br>
                Category G:	Geolocation data<br>
                Category H:	Sensory Information<br>
                Category I:	Professional or employment-related information<br>
                Category J:	Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section)
            </p>

            <p>
                We disclose your personal information for a business purpose to the following categories of third parties: </p>
                <ul>
                    <li>Our affiliates</li>
                    <li>Service Providers</li>
                    <li>Third parties to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you</li>
                </ul>
            <p>    
                <strong>IN THE PRECEDING TWELVE (12) MONTHS, WE HAVE NOT SOLD ANY PERSONAL INFORMATION.</strong>
            </p> <br>

            <span>Your Rights and Choices</span><br><br>
            <p>
                The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.<br><br>
                <strong style="font-style: italic;">Access to Specific Information and Data Portability Rights</strong> <br><br>
                You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you: </p>
                <ul>
                    <li>	The categories of personal information we collected about you.</li>
                    <li>	The categories of sources for the personal information we collected about you.</li>
                    <li>	Our business or commercial purpose for collecting or selling that personal information.</li>
                    <li>	The categories of third parties with whom we share that personal information.</li>
                    <li>	The specific pieces of personal information we collected about you (also called a data portability request).</li>
                    <li>	If we sold or disclosed your personal information for a business purpose, two separate lists disclosing: 
                        <ul>
                            <li>sales, identifying the personal information categories that each category of recipient purchased; </li>
                            <li>and disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained</li>
                        </ul>
                    </li>
                </ul>
            <p>  
                <strong style='font-style: italic;'>Deletion Request Rights </strong> <br><br>
                You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
                <br><br>
                We may deny your deletion request if retaining the information is necessary for us or our service providers to: <br> <br>
                1. Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.
                <br><br>2.	Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
                <br><br>3.	Debug products to identify and repair errors that impair existing intended functionality.
                <br><br>4.	Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
                <br><br>5.	Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).
                <br> <br>6.	Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.
                <br> <br>7.	Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.
                <br> <br>8.	Comply with a legal obligation.
                <br> <br>9.	Make other internal and lawful uses of that information that are compatible with the context in which you provided it.

                <br><br>

            </p>
            <span>Exercising Access, Data Portability, and Deletion Rights</span> <br> <br>
            <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:</p>
            <ul>
                <li>Calling us at <strong><a href="tel:18667847775" class="telNum"> 866.784.7775</a></strong></li>
                <li>Filling out our CCPA Request Webform located at <a href="https://ccpa.qbena.com/ccpa/" target="_blank">ccpa.qbena.com/ccpa</a></li>
            </ul>

            <br>
            <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. <br><br>
                You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
                <ul>
                    <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
                    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                </ul>
            

            <p>
                We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.  Making a verifiable consumer request does not require you to create an account with us.  We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.
            </p>
            <br>
            <span>Response Timing and Format</span> <br><br>
            <p>
                We endeavor to respond to a verifiable consumer request within 45 days of its receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance. <br><br>
We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.

            </p> <br>
            <span>Non-Discrimination</span> <br><br>
            <p>
                We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:  </p>
                <ul>
                    <li>	Deny you goods or services. </li>
                    <li>	Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
                    <li>	Provide you a different level or quality of goods or services.</li>
                    <li>	Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                </ul>
           
<br>
            <span>Changes to Our Privacy Notice </span> <br><br>
            <p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will notify you by email or through a notice on our website homepage.</p>
            <br>
            <span>Contact Information</span> <br><br>
            <p>If you have any questions or comments about this notice, our Privacy Statement, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:</p>
            <br>
            <span>
                Westwood Insurance Agency <br>
                Attn: Privacy Official<br>
                Corporate Legal Department<br>
                One QBE Way<br>
                Sun Prairie, WI 53596<br>
                <a href="tel:18667847775" class="telNum"> 866.784.7775</a> 
            </span>
        </div>
    </div> 
</div>
</section>

<!--  // P R I V A C Y  P O L I C Y  continued  -->


