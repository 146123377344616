import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidatorFn, FormGroup, AbstractControl } from '@angular/forms';
import { CommonService } from '../service/common.service';
//import { debug } from 'util';

@Directive({
  selector: '[ZipcodeValidator][ngModel]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ZipcodeValidatorDirective,
    multi: true
  }]
})
export class ZipcodeValidatorDirective implements Validator {
  validator: ValidatorFn;
  formErrors = {};
  fieldName:string;
  pattern=/^\d{5}(?:[-\s]\d{4})?$/;
  constructor() {
    this.validator = this.ZipcodeValidator();
  }
  validate(c: FormControl): { [key: string]: any } {
    return this.validator(c);
  }
  ZipcodeValidator(): ValidatorFn {
    return (c: FormControl) => { 
      if (c.value == null || c.value == undefined || c.value == '0' || c.value=='') {
        return null;
      }
      else{
        if (!(this.pattern.test(c.value))) {
          return {
            emailValidator: {
              valid: false
            }
          };
        }
      }
      return null;
    }
     
    };
  }


