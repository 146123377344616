import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Lead } from '../model/lead';
import { Observable } from 'rxjs';
import { Controller } from '../Utilities/api/controller.config';
import { Action } from '../Utilities/api/action.config';

@Injectable({
  providedIn: 'root'
})
export class QuoteResultService {

  url: string;
  constructor(private dataService: DataService) { }
  
  
  /*Create/Update Lead  Details by Lead Object */
  getQuoteResultInfo(data: Lead): Observable<Lead> {
    this.url = Controller.quoteResultInfo + '/' + Action.getLeadInfo;
    return this.dataService.postData(data, this.url);

  }
  /*Recalc Quote  by Lead Object */
  RecalcQuote(data: Lead): Observable<Lead> {
    this.url = Controller.quoteResultInfo + '/' + Action.getRecalcQuote;
    return this.dataService.postData(data, this.url);

  }
  /*Create/Update Lead  Details by Lead Object */
  postQuoteResultInfo(data: Lead): Observable<Lead> {
    this.url = Controller.quoteResultInfo + '/' + Action.postQuoteResult;
    return this.dataService.postData(data, this.url);

  }
  createTaskAndNote(data:Lead):Observable<Lead> {
    this.url = Controller.quoteResultInfo + '/' + Action.createTaskAndNote;
    return this.dataService.postData(data, this.url);
  }
  createTaskAndNoteSync(data: Lead) {

    this.url = Controller.quoteResultInfo + '/' + Action.createTaskAndNote;
    this.dataService.postDataSync(data, this.url);
  }
  /*Get RCV HTML */
  GetRCVHtml(data: Lead): Observable<Lead> {
    this.url = Controller.quoteResultInfo + '/' + Action.getRCVDetails;
    return this.dataService.postData(data, this.url);

  }
}
