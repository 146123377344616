import { Component, OnInit, OnDestroy, NgZone, ElementRef, ViewChild, Inject, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { DataService } from '../../service/data.service';
import { Constants } from '../../Utilities/Constants/constants';
import { UnSubscribeService } from '../../service/un-subscribe.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CustInfoService } from '../../service/cust-info.service';
import { captchaSiteKey, homeURL, captchaSiteKeyV3, captchaSecretKeyV3 } from 'src/app/service/config.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { UnSubscribe } from '../../model/unSubscribe';
import { ReCaptcha } from '../../model/reCaptcha';
declare var grecaptcha: any;
declare var $:any;
@Component({
  selector: 'app-un-subscribe',
  templateUrl: './un-subscribe.component.html',
  styleUrls: ['./un-subscribe.component.css']
})
export class UnSubscribeComponent implements OnInit,AfterViewChecked {
  formErrors: any = {};
  isLoading: boolean = false; 
  unsubscribeForm: FormGroup;
  formData:UnSubscribe;
  captchaRequired = true;
  siteKey = '';
  theme = 'Light';
  language = 'en';
  size = 'Normal';
  type = 'Image';
  recaptcha:string;
	badge='Bottomright';
  showData = true;
  apiMessage ='';
  constructor(private router: Router, public commonService: CommonService,
    public dataService: DataService,
    private zone: NgZone,
    private route: ActivatedRoute,
    public UnSubscribeServiceService: UnSubscribeService,  
    public constantsObj: Constants,
    private reCaptchaV3Service: ReCaptchaV3Service,
    @Inject(captchaSiteKey) public siteKeyValue?: string,
    @Inject(captchaSiteKeyV3) public siteKeyValueV3?: string,
    @Inject(captchaSecretKeyV3) public captchaSecretKeyV3?: string,
    @Inject(homeURL) public homesiteURL?: string) {
    var isSafari = this.commonService.isSafariBroswer();
    if (isSafari) {
      this.captchaRequired = false;
      this.siteKey = siteKeyValueV3;
    }
    else {
      this.siteKey = siteKeyValue;
    } 
    if (!this.commonService.isSafariBroswer()) {
      $('.grecaptcha-badge').remove()
    }
    else {
      $('.grecaptcha-badge').css('display', 'block !important');
      $('.grecaptcha-badge').css('visibility', 'visible !important');
    } 

  }

  //For google re-CAPTCHA
  handleReady()
  {
    this.captchaRequired=false;
   
  }
  handleSuccess(event) {
    if (event != '') {
      this.captchaRequired = false;
    }
  }
  handleLoad() {
    this.captchaRequired = true;
  }
  handleExpire() {
    grecaptcha.reset();
    this.captchaRequired = true;
  }
  //End of Google Re-CAPTCHA
  
  onSubmit(unsubscribeForm) { 
    
    if (this.captchaRequired) { 
      this.commonService.focusFirstInvalidElement();
      return; 
    }
    if (!this.captchaRequired) { 
      this.formData=new UnSubscribe();
      this.formData.unSubscribeId=this.route.snapshot.params.id;       
      //Client side get the captcha Token V3
      if (this.commonService.isSafariBroswer()) {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => { 
          if (token != '') {
           this.formData.reCaptcha = new ReCaptcha();
           this.formData.reCaptcha.response = token;
           this.formData.reCaptcha.secretKey = this.captchaSecretKeyV3;
           this.SubmitData();
        }
        });
      }
      else
      this.SubmitData();
      
    }
  } 
  SubmitData()
  {
     //Call api
     this.isLoading = true;
     this.UnSubscribeServiceService.UnsubscribeUser(this.formData).subscribe((data: any) => {  
       this.isLoading = false;
       this.showData = false; 
       window.scroll(0,0);
       if(data && data.message !== undefined){
         this.apiMessage = data.message;
         alert(this.apiMessage);
       }else{
         this.apiMessage ='Something went wrong, please try again!';
       } 
       
     }, err => {
       this.isLoading = false; 
       this.dataService.handleError(err);
       window.scroll(0,0);
       this.apiMessage ='Something went wrong, please try again!'; 
     });
  }
  onCancel(){
    this.showData = false;
    window.scroll(0,0);
    this.apiMessage ='Your request was cancelled. You may return to the email you received to view information about text messages. If you are experiencing difficulties or need assistance from one of our insurance agents, please call us at 800-676-5066.'; 
  }
  ngOnInit() {
  }
  ngAfterViewChecked() {
    
  }

}
