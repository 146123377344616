<div class="modal fade" id="lenderChangeModal">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.parentFunNo.emit()" *ngIf="!showOk">
          <span aria-hidden="true">&times;</span>
        </button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" *ngIf="showOk" (click)="this.parentFunOk.emit()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ displayMsg }}</p>
      </div>
      <div class="modal-footer">
        <button class="angled-button-sm button-width-110 btn" *ngIf="!showOk" (click)="hideModal();this.parentFunYes.emit()">YES </button>
        <button class="angled-button-sm button-width-110 btn" aria-hidden="true" data-dismiss="modal" aria-label="Close" (click)="hideModal();this.parentFunNo.emit()" *ngIf="!showOk">
          NO
        </button>

        <button class="angled-button-sm button-width-110 btn" aria-hidden="true" data-dismiss="modal" aria-label="Close" *ngIf="showOk" (click)="this.parentFunOk.emit()">
          CLOSE
        </button>
      </div>
    </div>
  </div>
</div>
