<div class="container">
        <div class="row pt-2">
            <div class="col col col-sm-6">
    
            </div>
            <div class="col col-sm-6">
                <div class="col-md-6 float-sm-right">
                    <button printSectionId="print-section" useExistingCss="true" class="btn ww-button float-sm-right margin-10px"
                        ngxPrint target="_blank" styleSheetStyle="../../styles.css" [printStyle]="{'td.printLabel':{'font-size':'10px','width': '80%'},
                  'td.printValue':{'font-size':'10px'},
                  'td.printSubHeading':{'font-size':'11px'},
                  'table.marginleft70':{'margin-left':'70px'},
                  'table.margintop-30':{'margin-top':'30px'},
                  'table.tablefont':{'font-family':'Verdana'},
                'td.printSubHeading2':{'font-size':'10px'},
              'td.printDisclaimer':{'font-size':'10px'},
              'heading':{'font-size':'11px'},
            'td.printLabel1':{'font-size':'10px','width':'65%'}}">
    
                        <i class="fa fa-print pr-2"></i>Print Now</button>
                </div>
                
                <div class="col-md-6 pt-mob float-sm-right">
                    <button class="btn ww-button float-sm-right margin-10px" (click)="closeWindow()">
                        <i class="fa fa-ban pr-2"></i>Close
                    </button>
                </div>
    
            </div>
        </div>
    
        <div class="row printPanel">
            <div class="col col-sm-12">
                <div class="row">
                    <div class="col col-sm-12">
                        <hr class="hrline">
                    </div>
                </div>
                <div class="row">
                    <label class="col col-sm-12 form-check-label printSubHeading">
                        <b><u>Customer Information</u></b>
                    </label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Last Name</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.lastName">{{leadDetails.lastName}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">First Name</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.firstName">{{leadDetails.firstName}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printValue">Mail Street</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.mailingAddress.streetAddress">{{leadDetails.mailingAddress.streetAddress}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printValue">Mail City</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.mailingAddress.city">{{leadDetails.mailingAddress.city}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Mail State</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.mailingAddress.state">{{leadDetails.mailingAddress.state}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Mail Zip</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.mailingAddress.zipCode">{{leadDetails.mailingAddress.zipCode}}</label>
                </div>
    
                <div class="row">
                    <label class="col  col-sm-12 form-check-label printSubHeading">
                        <b><u>Property Information</u></b>
                    </label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Property Street</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.customerAddress.streetAddress">{{leadDetails.customerAddress.streetAddress}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Property City</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.customerAddress.city">{{leadDetails.customerAddress.city}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printValue">Property State</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.customerAddress.state">{{leadDetails.customerAddress.state}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Property Zip</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.customerAddress.zipCode">{{leadDetails.customerAddress.zipCode}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Property Year Built</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.homeInfo.yearBuild">{{leadDetails.homeInfo.yearBuild}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Property Square Footage</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.homeInfo.squareFootage">{{leadDetails.homeInfo.squareFootage}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Home Type</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getHomeType()">
                    </label>
                </div>
                <div class="row">
                    <label class="  col-sm-12 form-check-label printSubHeading">
                        <b><u>Quote Information</u></b>
                    </label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Quote Reference Number</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.referenceID">{{leadDetails.referenceID}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Insurance Company</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.quoteResult.insuranceCompanyName">{{leadDetails.quoteResult.insuranceCompanyName}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Policy Type</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="leadDetails.policyTypeName">{{leadDetails.policyTypeName}}</label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Dwelling Coverage</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getCoverage(leadDetails.quoteResult.coverageAprint)"></label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Other Structures</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getCoverage(leadDetails.quoteResult.coverageBprint)">
                    </label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Personal Property</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getCoverage(leadDetails.quoteResult.coverageCprint)"></label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Additional Living Expenses</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getCoverage(leadDetails.quoteResult.coverageDprint)"></label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Personal Liability</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getCoverage(leadDetails.quoteResult.coverageEprint)"></label>
                </div>
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel">Medical Payement</label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="getCoverage(leadDetails.quoteResult.coverageFprint)"></label>
                </div>
                <div class="row">
                    <label class="  col-sm-12 form-check-label printSubHeading2"><b><u>Deductibles</u></b></label>
                </div>
                <div class="row" *ngFor="let item of leadDetails.quoteResult.deductibles; let id = index">
                    <label class="col col-sm-6 form-check-label printLabel">
                        {{item.name }} Deductible
                    </label>
                    <label class="col col-sm-6 form-check-label printValue" [innerHTML]="formatDeductibles(item.amount)"></label>
                </div>
                <br />
                <div class="row">
                    <label class="col col-sm-6 form-check-label printLabel"><b><u>Annual Premium</u></b></label>
                    <label class="col col-sm-6 form-check-label printValue"><b>&#36;{{leadDetails.quoteResult.totalPremium
                            | number
                            :'1.2-2'}}</b></label>
                </div>
                <br />
                <div class="row" *ngFor="let item of leadDetails.quoteResult.alternateQuotes">
                    <label class="col col-sm-6 form-check-label printLabel">{{item.factorName}}</label>
                    <label class="col col-sm-6 form-check-label printValue">&#36;{{item.totalPremium | number : '1.2-2'}}</label>
                </div>
                <div class="row">
                    <label class="  col-sm-12 form-check-label printSubHeading">
                        <b><u>Additional Informations</u></b>
                    </label>
                </div>
                <div class="row">
                    <div class="col  col-sm-12 printValue">
                        <ul class="additional" *ngFor="let item of leadDetails.quoteResult.policyTexts">
                            <li class="additional">{{item.lookupValue}}</li>
                        </ul>
                    </div>
                </div>
                <br />
                <div class="row">
                    <label class="col  col-sm-6 printLabel"><b>Producer</b>&nbsp;&nbsp;&nbsp;{{leadDetails.salesAgent.firstName}}
                        &nbsp;{{leadDetails.salesAgent.lastName}}</label>
                    <label class="col  col-sm-6 printLabel"><b>Phone</b>&nbsp;&nbsp;&nbsp;{{leadDetails.salesAgent.phoneNumber}}</label>
    
                </div>
                <div class="row">
                    <div class="  col-sm-12">
                        <hr class="hrline">
                    </div>
                </div>
                <div class="row">
                    <label class="  col-sm-12 printDisclaimer">
                        Disclaimer: This quote is based on information displayed. If anything is incorrect, please notify
                        us to adjust
                        it and provide you with an updated rate. Acceptance is subject to the insurance company’s normal
                        underwriting
                        review, possibly including verification of insurance score and previous claims history. Insurance
                        companies at
                        times take rate changes which could affect this quote prior to issuance.
                    </label>
                </div>
                <br />
                <div class="row">
                    <label class="  col-sm-12 printDisclaimer" ForeColor="Black" Font-Bold="True">
                        Westwood Insurance Agency, Inc. is a Baldwin Risk Partner.
                    </label>
                </div>
            </div>
    
        </div>
    
        <table>
            <colgroup>
                <div class="printContent" id="print-section">
                    <table width="100%">
                        <tr style="text-align:left">
                            <td>
                                <label class="heading">
                                    Insurance Quote
                                </label>
                            </td>
                        </tr>
                    </table>
                    <table width="100%" class="marginleft70 margintop-30">
    
                        <tr style="clear:both">
                            <td>
                                <img [src]="PrintableVersionImage" class="img-responsive" />
                            </td>
                        </tr>
                        <tr style="clear:both">
                            <td>
                                <hr class="hrline" />
                            </td>
                        </tr>
                    </table>
                    <table class="marginleft70 tablefont">
                        <tr>
                            <td class="printSubHeading" colspan="2">
                                <b><u>Customer Information</u></b>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Last Name
                            </td>
    
                            <td class="printValue">
                                <label ID="LastName">{{leadDetails.lastName}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                First Name
                            </td>
    
                            <td class="printValue">
                                <label ID="FirstName">{{leadDetails.firstName}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Mail Street
                            </td>
    
                            <td class="printValue">
                                <label ID="MailingAddress1">{{leadDetails.mailingAddress.streetAddress}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Mail City
                            </td>
    
                            <td class="printValue">
                                <label ID="MailingCity">{{leadDetails.mailingAddress.city}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Mail State
                            </td>
    
                            <td class="printValue">
                                <label ID="MailingState">{{leadDetails.mailingAddress.state}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Mail Zip
                            </td>
    
                            <td class="printValue">
                                <label ID="MailingZip">{{leadDetails.mailingAddress.zipCode}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printSubHeading" colspan="2">
                                <b><u>Property Information</u></b>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Property Street
                            </td>
    
                            <td class="printValue">
                                <label ID="PropertyAddress1">{{leadDetails.customerAddress.streetAddress}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Property City
                            </td>
    
                            <td class="printValue">
                                <label ID="PropertyCity">{{leadDetails.customerAddress.city}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Property State
                            </td>
    
                            <td class="printValue">
                                <label ID="PropertyState">{{leadDetails.customerAddress.state}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Property Zip
                            </td>
    
                            <td class="printValue">
                                <label ID="PropertyZip">{{leadDetails.customerAddress.zipCode}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Property Year Built
                            </td>
    
                            <td class="printValue">
                                <label ID="YearBuilt" [innerHTML]="leadDetails.homeInfo.yearBuild">{{leadDetails.homeInfo.yearBuild}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Property Square Footage
                            </td>
    
                            <td class="printValue">
                                <label ID="SquareFootage">{{leadDetails.homeInfo.squareFootage}}
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Home Type
                            </td>
    
                            <td class="printValue">
                                <label ID="HomeType" [innerHTML]="getHomeType()">
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printSubHeading" colspan="2">
                                <b><u>Quote Information</u></b>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Quote Reference Number
                            </td>
    
                            <td class="printValue">
                                <label ID="ReferenceNumber">{{leadDetails.referenceID}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Insurance Company
                            </td>
    
                            <td class="printValue">
                                <label ID="InsuranceCompany">{{leadDetails.quoteResult.insuranceCompanyName}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Policy Type
                            </td>
    
                            <td class="printValue">
                                <label ID="PolicyType">{{leadDetails.policyTypeName}}
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Dwelling Coverage
                            </td>
    
                            <td class="printValue">
                                <label ID="DwellingCoverage">&#36;{{leadDetails.quoteResult.coverageAprint}}.00
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Other Structures
                            </td>
    
                            <td class="printValue">
                                <label ID="OtherStructure">&#36;{{leadDetails.quoteResult.coverageBprint}}.00
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Personal Property
                            </td>
    
                            <td class="printValue">
                                <label ID="PersonalProperty">
                                    &#36;{{leadDetails.quoteResult.coverageCprint}}.00
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Additional Living Expenses
                            </td>
    
                            <td class="printValue">
                                <label ID="AdditionalLivingExpense">&#36;{{leadDetails.quoteResult.coverageDprint}}.00
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                Personal Liability
                            </td>
    
                            <td class="printValue">
                                <label ID="PersonalLiability">&#36;{{leadDetails.quoteResult.coverageEprint}}.00
    
                                </label>
                            </td>
                        </tr>
    
                        <tr>
                            <td class="printLabel">
                                Medical Payment
                            </td>
    
                            <td class="printValue">
                                <label ID="MedicalPayment">&#36;{{leadDetails.quoteResult.coverageFprint}}.00
    
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="printSubHeading2">
                                <b> Deductibles</b>
                            </td>
                        </tr>
                        <tr *ngFor="let item of leadDetails.quoteResult.deductibles; let id = index">
                            <td class="printLabel">{{item.name}} Deductible
                            </td>
    
                            <td class="printValue">
                                <label [innerHTML]="formatDeductibles(item.amount)"></label>
                            </td>
    
                        </tr>
                        <tr>
                            <td colspan="2">
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel">
                                <b>Annual Premium</b>
                            </td>
    
                            <td class="printValue">
                                <label ID="AnnualPremium"><b>&#36;{{leadDetails.quoteResult.totalPremium}}</b></label>
                            </td>
                        </tr>
                    </table>
                    <table class="marginleft70 tablefont">
                        <tr *ngFor="let item of leadDetails.quoteResult.alternateQuotes">
                            <td class="printLabel">
                                <label [innerHTML]="item.factorName">{{item.factorName}}</label>
                            </td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td><label></label></td>
                            <td class="printValue">
                                <label>&#36;{{item.totalPremium | number : '1.2-2'}}</label>
                            </td>
                        </tr>
    
                    </table>
                    <table class="marginleft70">
                        <tr>
                            <td colspan="2" class="printSubHeading"><b><u>Additional Informations</u></b>
                            </td>
                        </tr>
                        <tr>
                            <td class="printValue" colspan="2">
                                <ul class="additional" *ngFor="let item of leadDetails.quoteResult.policyTexts">
                                    <li class="additional">{{item.lookupValue}}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                            </td>
                        </tr>
                        <tr>
                            <td class="printLabel1">
                                <strong>Producer</strong>&nbsp;&nbsp;
                                <label ID="Producer">{{leadDetails.salesAgent.firstName}}&nbsp;
                                    {{leadDetails.salesAgent.lastName}}
    
                                </label>
                            </td>
                            <td class="printValue">
                                <strong>Phone</strong>&nbsp;&nbsp;
                                <label ID="Phone">{{leadDetails.salesAgent.phoneNumber}}
    
                                </label>
                            </td>
                        </tr>
                        <tr>
    
                        </tr>
                        <tr>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <hr class="hrline" />
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="printDisclaimer">
                                <b>
                                    Disclaimer: This quote is based on information displayed. If anything is incorrect,
                                    please notify us to
                                    adjust it and provide you with an updated rate. Acceptance is subject to the insurance
                                    company’s normal
                                    underwriting review, possibly including verification of insurance score and previous
                                    claims history.
                                    Insurance companies at times take rate changes which could affect this quote prior to
                                    issuance.
                                    <br />
                                    <br />
                                    <label ID="lblOwnership" ForeColor="Black" Font-Bold="True">Westwood Insurance Agency, Inc. is a Baldwin Risk Partner.</label><br>
                                </b>
                            </td>
                        </tr>
                    </table>
                </div>
            </colgroup>
        </table>
    </div>